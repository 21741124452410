<div class="wrapper">
  <mat-button-toggle-group
      #group="matButtonToggleGroup"
      name="viewer"
      [value]="selectedView"
      (change)="viewerChange(group.value)">
    <mat-button-toggle value="2d">2D</mat-button-toggle>
    <mat-button-toggle value="3d">3D</mat-button-toggle>
  </mat-button-toggle-group>
  <ng-template #containerMap></ng-template>
</div>
