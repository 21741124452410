import { RectangularBase } from './RectangularBase';
import { SvgHelper } from './SvgHelper';

export class Ellipse extends RectangularBase {
  private ellipse: SVGEllipseElement;

  constructor() {
    super();
    this.type = 'Ellipse';
  }

  static draw = (): RectangularBase => {
    const ellipse = new Ellipse();
    ellipse.setup();

    return ellipse;
  };

  protected resize(x: number, y: number): void {
    super.resize(x, y);
    this.ellipse.setAttribute('cx', (this.width / 2).toString());
    this.ellipse.setAttribute('cy', (this.height / 2).toString());
    this.ellipse.setAttribute('rx', (this.width / 2).toString());
    this.ellipse.setAttribute('ry', (this.height / 2).toString());
  }

  protected setup(): void {
    this.height = this.width; // circle by default
    super.setup();

    this.ellipse = SvgHelper.createEllipse(this.width, this.height);
    this.addToRenderVisual(this.ellipse);

    SvgHelper.setAttributes(this.visual, [['class', 'drawing ellipse']]);
  }
}
