<div class="task-popup" [class.dark]="this.theme && this.theme.includes('dark')">
  <h2>{{ (data.action === 'update' ? 'TASKS.ACTION.UPDATE_TASK' : 'TASKS.ACTION.CREATE_TASK') | translate }}</h2>
  <form [formGroup]="taskForm">
    <!-- Resource(s) Section -->
    <div class="flex-column">
      <span>{{ "TASKS.RELATED_ANNOTATIONS" | translate }}</span>
      <div class="grid-view">
          <div class="map selection" (click)="toggleAnnotationsSelection()">
            <mat-icon fontSet="material-icons-outlined" class="icon-add">
              add
            </mat-icon>
          </div>
          <div
            class="map"
            *ngFor="let resource of task.linkedResources"
            [ngClass]="'_'+resource.resource?._id">
            <div class="map-buttons" *ngIf="task.linkedResources?.length > 1">
              <button mat-icon-button (click)="deleteResource(resource)">
                <mat-icon>delete</mat-icon>
              </button>
              <!-- <button mat-icon-button (click)="redirectToResource(resource)">
                <mat-icon>zoom_out_map</mat-icon>
              </button> -->
            </div>
          </div>
      </div>

      <div *ngIf="showCustomAnnotations">
        <!-- Display custom annotations if applicable -->
        <mat-chip-list>
          <mat-chip *ngFor="let customAnnotation of customAnnotations" selectable="true" removable="true">
            {{ customAnnotation }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <!-- General Information Section -->
    <div class="general-info">
      <mat-form-field appearance="outline">
        <mat-label>{{ "FILE.TITLE" | translate }}</mat-label>
        <input matInput formControlName="title" required />
        <mat-error *ngIf="isInvalidAndTouched('title')">{{ getErrorMessage('title') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "TASKS.ASSIGNEE_EMAIL" | translate }}</mat-label>
        <input matInput type="email" formControlName="assigneeEmail" required />
        <mat-error *ngIf="isInvalidAndTouched('assigneeEmail')">{{ getErrorMessage('assigneeEmail') }}</mat-error>
      </mat-form-field>
      <div class="flex-row">
        <mat-form-field appearance="outline">
          <mat-label>{{ "TASKS.LEVEL_OF_URGENCY" | translate }}</mat-label>
          <mat-select formControlName="urgency" required>
            <!-- Replace with actual options -->
            <mat-option *ngFor="let urgency of urgencies" [value]="urgency">{{ ('TASKS.URGENCY.'+(urgency | uppercase)) | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="isInvalidAndTouched('urgency')">{{ getErrorMessage('urgency') }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'TASKS.STATUS.TITLE' | translate }}</mat-label>
          <mat-select formControlName="status" required>
            <!-- Replace with actual options -->
            <mat-option *ngFor="let status of statuses" [value]="status">{{ ('TASKS.STATUS.'+(status | uppercase)) | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="isInvalidAndTouched('status')">{{ getErrorMessage('status') }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ "TASKS.DEADLINE" | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="deadline" required />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="isInvalidAndTouched('deadline')">{{ getErrorMessage('deadline') }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "PDF-EDITOR.DESCRIPTION" | translate }}</mat-label>
        <textarea matInput formControlName="description" maxlength="500" rows="5"></textarea>
        <mat-hint>{{ description.value?.length || 0 }}/500</mat-hint>
      </mat-form-field>
    </div>

    <!-- Action Buttons -->
    <div class="actions">
      <button mat-button type="button" (click)="onCancel()">{{ "CANCEL" | translate }}</button>
      <button mat-raised-button
      color="accent" (click)="data.action === 'update' ? updateTask() : createTask()">
        {{ (data.action === 'update' ? 'SAVE' : 'CREATE') | translate }}
      </button>
    </div>
  </form>
</div>
