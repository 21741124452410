<div class="pv-site-container">
  <div class="top-section">
    <div #map class="map">
      <div class="map-overlay">
        <div class="button" (click)="navigateToFullInspectionMode()">
          Switch to full inspection mode
        </div>
      </div>
    </div>

    <div class="stats-sidebar">
      <div class="stat-card box">
        <h3>{{ siteStats?.totalModules | number }}</h3>
        <p>PV Modules</p>
      </div>

      <div class="stat-card  box">
        <h3>{{ siteStats?.capacity }}</h3>
        <p>Total capacity</p>
      </div>

      <div class="stat-card  box">
        <h3>{{ siteStats?.identifiedModules }}</h3>
        <p>Identified PV Modules</p>
      </div>

      <div class="diagnosis-title">Power Diagnosis</div>

      <div class="stat-card box">
        <h3>{{ siteStats?.modulesWithAnomalies }}</h3>
        <p>Modules with Anomalies</p>
      </div>

      <div class="stat-card box">
        <h3>{{ siteStats?.affectedModulesPercent }}%</h3>
        <p>Affected modules</p>
      </div>

      <div class="stat-card box">
        <h3>{{ siteStats?.affectedPerformance }}</h3>
        <p>Affected output performance</p>
      </div>

      <div class="stat-card box">
        <h3>{{ siteStats?.affectedPerformancePercent }}%</h3>
        <p>Performance affected</p>
      </div>
    </div>
  </div>

  <div class="charts-and-details">
    <div class="anomalies-section">
      <h4>Visual and Thermal Anomalies found</h4>
      <div class="charts">
        <div class="chart box">
          <span class="box-subtitle">Distribution by type</span>
          <canvas #typeChart></canvas>
        </div>

        <div class="chart box">
          <span class="box-subtitle">Distribution by severity</span>
          <canvas #severityChart></canvas>
        </div>
      </div>
    </div>

    <div class="details-container">
      <h4>Overview details</h4>
      <div class="details-grid box">
        <div class="details-section">
          <span class="box-subtitle">Weather Details</span>
          <div class="details-infos">
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="weather"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Cloud Cover</p>
                <strong>{{ siteStats?.weather?.condition }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="wind"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Wind</p>
                <strong>{{ siteStats?.weather?.wind }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="thermometer"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Temperature</p>
                <strong>{{ siteStats?.weather?.temperature }} °C</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="humidity"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Humidity</p>
                <strong>{{ siteStats?.weather?.humidity }}%</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="details-section">
          <span class="box-subtitle">Inspection Details</span>
          <div class="details-infos">
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="drone"></mat-icon>
              </div>
              <div class="detail-value">
                <p>UAS</p>
                <strong>{{ siteStats?.inspection?.uas }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="camera"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Imaging System</p>
                <strong>{{ siteStats?.inspection?.imagingSystem }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="chart-up"></mat-icon>
              </div>
              <div class="detail-value">
                <p>PPA Rate</p>
                <strong>{{ siteStats?.inspection?.ppaRate }}</strong>
              </div>
            </div>
            <div class="detail">
              <div class="detail-icon">
                <mat-icon svgIcon="solar-panel"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Sunlight Hours</p>
                <strong>{{ siteStats?.inspection?.sunlightHours }}</strong>
              </div>
            </div>
            <div class="detail full">
              <div class="detail-icon">
                <mat-icon svgIcon="calendar-dots"></mat-icon>
              </div>
              <div class="detail-value">
                <p>Inspected on</p>
                <strong>{{ siteStats?.inspection?.period }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="anomaly-table">
    <table mat-table [dataSource]="tableRows" class="mat-table" multiTemplateDataRows>

      <!-- Anomaly Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Anomalies</th>
        <td mat-cell *matCellDef="let row">{{ getAnnotationTypeLabel(row.type) }}</td>
        <td mat-footer-cell *matFooterCellDef>Total</td>
      </ng-container>

      <!-- Count Column -->
      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef>Count</th>
        <td mat-cell *matCellDef="let row">{{ row.count }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.count }}</td>
      </ng-container>

      <!-- Model Types Column -->
      <ng-container matColumnDef="modelTypes">
        <th mat-header-cell *matHeaderCellDef>Model Types</th>
        <td mat-cell *matCellDef="let row">{{ row.modelTypes }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.modelTypes }}</td>
      </ng-container>

      <!-- DC (kW) Column -->
      <ng-container matColumnDef="dcKw">
        <th mat-header-cell *matHeaderCellDef>DC (kW)</th>
        <td mat-cell *matCellDef="let row">{{ row.dcKw }}</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.dcKw }}</td>
      </ng-container>

      <!-- DC (%) Column -->
      <ng-container matColumnDef="dcPercent">
        <th mat-header-cell *matHeaderCellDef>DC (%)</th>
        <td mat-cell *matCellDef="let row">{{ row.dcPercent }}%</td>
        <td mat-footer-cell *matFooterCellDef>{{ tableTotals.dcPercent }}%</td>
      </ng-container>

      <!-- Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>
