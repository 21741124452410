import { Position3D } from './../viewer.model';
import { FileModel } from './../../files/file/file.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { UnitsUtils } from 'geo-three';
import * as THREE from "three";
import { SidebarService } from '../../files/file/sidebar/sidebar.service';


@Injectable({
  providedIn: 'root'
})
export class Viewer3dService {
  action$: BehaviorSubject<string> = new BehaviorSubject(null);
  data3d$: BehaviorSubject<any> = new BehaviorSubject(null);
  fileModel$: BehaviorSubject<FileModel> = new BehaviorSubject(null);
  panoID$: BehaviorSubject<string> = new BehaviorSubject(null);
  tmpHeight$: BehaviorSubject<number> = new BehaviorSubject(null);
  tmpPosition$: BehaviorSubject<Position3D> = new BehaviorSubject(null);
  tmpRotation$: BehaviorSubject<Position3D> = new BehaviorSubject(null);
  tmpScale$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
    private sidebarService: SidebarService
  ) { }

  createCamera(aspectRatio: number): THREE.PerspectiveCamera {
    const camera = new THREE.PerspectiveCamera(
      35,
      aspectRatio,
      1,
      1e12
    )
    camera.position.x = 1575.553361160415;
    camera.position.y = 1317.4872715319211;
    camera.position.z = 2057.496625036524;
    return camera
  }

  createControl(camera: THREE.Camera, orbitOverlay: HTMLDivElement): OrbitControls {
    const controls = new OrbitControls(camera, orbitOverlay);
    controls.enableZoom = true;
    controls.enablePan = true;
    controls.update();
    return controls
  }

  createPointerSphere(scene): THREE.Mesh {
    const geometry = new THREE.SphereGeometry( 0.25, 16, 16 );
    const material = new THREE.MeshBasicMaterial( { color: 0xffff00, opacity: 0.3, transparent: true } );
    const sphere = new THREE.Mesh( geometry, material );
    sphere.name = "pointerSphere"
    scene.add( sphere );
    return sphere
  }

  createScene(): THREE.Scene {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xd4d4d8)
    return scene
  }

  createWaterPlane(scene, position, height) {
    const geometry = new THREE.PlaneGeometry( 100000, 100000 );
    const material = new THREE.MeshBasicMaterial( {color: 0xd4f1f9, opacity: 0.8, side: THREE.DoubleSide, transparent: true } );
    const plane = new THREE.Mesh( geometry, material );
    plane.rotation.x = -Math.PI / 2;
    //plane.position.set(1123900, height, -6981650)
    plane.position.set(position.x, height, position.z)
    plane.name = "waterPlane"
    scene.add( plane );
    return plane
  }

  getAspectRatio(canvas: HTMLCanvasElement) {
    return canvas.clientWidth / canvas.clientHeight;
  }

  loadDracoModel(url: string, scene: THREE.Scene, dracoLoader: any):void {
    dracoLoader.load(url, ( gltf: GLTF ) => {
      const material = new THREE.MeshStandardMaterial( { color: 0x606060 } );
      const mesh = new THREE.Mesh( gltf, material );
      scene.add( mesh );
    } );
  }

  loadGltfModel(url: string, scene: THREE.Scene, loaderGLTF: GLTFLoader) {
    loaderGLTF.load(url, (gltf: GLTF) => {
      const model = gltf.scene.children[0];
      var box = new THREE.Box3().setFromObject(model);
      box.getCenter(model.position); // this re-sets the mesh position
      model.position.multiplyScalar(-1);
      scene.add(model);
      this.data3d$.getValue().push(gltf.scene)
    });
  }

  makeTiles3dObj(fileModel: FileModel): FileModel {
    if (!fileModel.tiles3d) {
      fileModel.tiles3d = {};
    }
    return fileModel
  }

  setUpdate(fileModel, values): void {
    const viewerFileModel = fileModel
    const fileUpdates: FileModel = {};
    Object.assign(fileUpdates, { _id: viewerFileModel._id });
    fileModel.modified = new Date();
    Object.assign(fileUpdates, values);

    this.sidebarService.updateOne(fileUpdates, viewerFileModel);
  }

  // mouse(Down/Up)Interaction is needed to detect a single click while OrbitControls is used in the Scene
  mouseDownInteraction(event, clock: THREE.Clock, camera: THREE.Camera): [number, THREE.Vector3] {
    if( event && event.button === 0){
      return [clock.elapsedTime, camera.position.clone()]
    }
    return [null, null];
  }

  mouseMove( event, canvas ): THREE.Vector2 {
    // calculate pointer position in normalized device coordinates
    const pointer = new THREE.Vector2
    pointer.x = (event.layerX / canvas.clientWidth ) * 2 - 1;
    pointer.y = - ( event.layerY / canvas.clientHeight ) * 2 + 1;
    return pointer;
  }

  mouseUpInteraction(event, clock, camera, lastClockTime, lastCameraPosition): boolean {
    if(event.button === 0){
      return (
        camera.position.x === lastCameraPosition.x &&
        camera.position.y === lastCameraPosition.y &&
        camera.position.z === lastCameraPosition.z &&
        clock.elapsedTime - lastClockTime < 0.1
      );
    }
  }


  sphericalToDatums(x: number, y: number): any {
    return UnitsUtils.sphericalToDatums(x, y);
  }

  datumsToSpherical(latitude: number, longitude: number): THREE.Vector2 {
    return UnitsUtils.datumsToSpherical(latitude, longitude);
  }
}
