import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class StorageService {
  // check if running im browser
  private storage = isPlatformBrowser(this.platformId) ? window.localStorage : undefined;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  getItem(key: string): any {
    if (this.storage) {
      try {
        return JSON.parse(this.storage.getItem(key) || null);
      } catch (e) {
        return null;
      }
    }
  }

  removeItem(key: string): boolean {
    if (this.storage) {
      try {
        this.storage.removeItem(key);

        return true;
      } catch (e) {
        return false;
      }
    }
  }

  setItem(key: string, value: any): boolean {
    if (this.storage) {
      try {
        this.storage.setItem(key, JSON.stringify(value));

        return true;
      } catch (e) {
        // Safari will return an error when Private Browsing
        return false;
      }
    }
  }
}
