import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { GasoidcAuthenticationService } from './gasoidc-authentication.service';
import { Login } from '../login/login.model';
import { LoginStateService } from '../login/login-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gasoidc-authentication',
  templateUrl: './gasoidc-authentication.component.html',
  styleUrls: ['./gasoidc-authentication.component.scss']
})
export class GasoidcAuthenticationComponent implements OnInit {

  public fetching: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private gasoidcAuthenticationService: GasoidcAuthenticationService,
    private dialogService: DialogService,
    private loginStateService: LoginStateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const state = params['state'];
      const code = params['code'];

      if (state && code) {
        this.gasoidcAuthenticationService.authenticateGasoidc(code,state).subscribe(
          response => {
            this.fetching = false;
            if (response['data'] && response['data']['token']) {
              const login = new Login(response['data']['token']);
              this.loginStateService.saveLogin(login);
              this.router.navigateByUrl(`/orders`);
            } else {
              this.dialogService.showDialog('AUTH.LOGIN_FAILED', null, 'AUTH.NO_TOKEN');
            }
          },
          error => {
            this.fetching = false;
            this.dialogService.showDialog('AUTH.LOGIN_FAILED', null, error.error.errors[0]);
          });
      } else {
        this.fetching = false;
        //this.dialogService.showDialog('AUTH.LOGIN_FAILED', null, null);
      }
    });
  }
}
