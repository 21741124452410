<h1 mat-dialog-title>{{'SHARING.SHARE_FILES' | translate}}</h1>
<p>{{'SHARING.SHARE_FILES_HELP' | translate}}</p>
<div mat-dialog-content>
  <mat-button-toggle-group *ngIf="!link" [(value)]="shareType" name="fontStyle" aria-label="Font Style">
    <mat-button-toggle value="link">{{'SHARING.TYPES.LINK' | translate}}</mat-button-toggle>
    <mat-button-toggle value="email">{{'SHARING.TYPES.EMAIL' | translate}}</mat-button-toggle>
  </mat-button-toggle-group>
  <div *ngIf="!link">
    <div *ngIf="shareType === 'email'">
      <p>{{'SHARING.EMAILS_FIELD.HEADING' | translate}}</p>
      <mat-form-field [style.width.%]="100">
        <mat-label>{{'SHARING.EMAILS_FIELD.LABEL' | translate}}</mat-label>
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let email of emails; let i = index" [selectable]="false" [removable]="true" (removed)="remove(i)">
            {{email}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            [placeholder]="'SHARING.EMAILS_FIELD.PLACEHOLDER' | translate"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
      <mat-form-field [style.width.%]="100">
        <mat-label>{{'SHARING.TEXT_FIELD.LABEL' | translate}}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"
          [(ngModel)]="text"
          [placeholder]="'SHARING.TEXT_FIELD.PLACEHOLDER' | translate"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="link">
    <mat-form-field [style.width.%]="100">
      <mat-label>{{'SHARING.LINK_FOR_SHARING' | translate}}</mat-label>
      <input matInput [ngModel]="link" [readonly]="true">
      <button mat-button matSuffix (click)="copy()">
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">{{'SHARING.CANCEL' | translate}}</button>
  <button *ngIf="!link" mat-button color="accent"
          (click)="share()"
          [disabled]="!emailsValid()">
    {{(shareType === 'link' ? 'SHARING.GENERATE_LINK' : 'SHARING.SEND_LINK') | translate}}
  </button>
  <button *ngIf="link" mat-button color="accent"
          (click)="copy()">
    {{'SHARING.COPY_LINK' | translate}}
  </button>
</div>
