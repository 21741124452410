import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../shared/i18n/language.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnDestroy, AfterViewInit {
  // Be sure to match with i18n translations
  accordions = [
    { key: 'A1', questions: [
      { key: 'Q1', action: true },
      { key: 'Q2' },
      { key: 'Q3' }
    ]},
    { key: 'A2', id: 'client', questions: [
      { key: 'Q1' },
      { key: 'Q2' },
      { key: 'Q3' },
      { key: 'Q4' },
      { key: 'Q5' },
      { key: 'Q6', action: true },
      { key: 'Q7' },
      { key: 'Q8' },
      { key: 'Q9' },
      { key: 'Q10' },
      { key: 'Q11' },
      { key: 'Q12' },
      { key: 'Q13', action: true },
      { key: 'Q14', action: true }
    ]},
    { key: 'A3', id: 'pilotGeneral', questions: [
      { key: 'Q1' },
      { key: 'Q2' },
      { key: 'Q3' },
      { key: 'Q4' },
      { key: 'Q5', action: true },
      { key: 'Q6', action: true },
      { key: 'Q7' },
      { key: 'Q8' }
    ]},
    { key: 'A4', id: 'pilotOrder', questions: [
      { key: 'Q1' },
      { key: 'Q2' },
      { key: 'Q3' },
      { key: 'Q4', action: true },
      { key: 'Q5' },
      { key: 'Q6', action: true },
      { key: 'Q7' },
      { key: 'Q8' },
      { key: 'Q9' },
      { key: 'Q10' },
      { key: 'Q11' },
      { key: 'Q12' },
      { key: 'Q13', action: true }
    ]},
    { key: 'A5', id: 'pilotTech', questions: [
      { key: 'Q1' },
      { key: 'Q2' },
      { key: 'Q3', action: true }
    ]},
    { key: 'A6', id: 'tech', questions: [
      { key: 'Q1', action: true },
      { key: 'Q2', action: true },
      { key: 'Q3', action: true },
      { key: 'Q4', action: true },
      { key: 'Q5', action: true },
      { key: 'Q6', action: true },
      { key: 'Q7' },
      { key: 'Q8', action: true },
      { key: 'Q9' },
      { key: 'Q10', action: true },
      { key: 'Q11', action: true }
    ]}
  ];
  @Input() dir: Direction;
  ngDestroy$ = new Subject();
  private fragment: string;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0); // On mobile portrait the list is not scrolled at the top, so we force it here
      setTimeout(() => window.scrollTo(0, 0), 300); // Scroll again after the browser toolbars are finished animating
    }

    this.languageService.dir$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(dir => this.dir = dir);
  }

  ngAfterViewInit(): void {
    setTimeout(() => { // set timeout because loading issue
      this.route.fragment.subscribe(fragment => {
        this.fragment = fragment;
        if (isPlatformBrowser(this.platformId)) {
          const element = document.querySelector(`#${this.fragment}`);
          if (element) {
            element.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
        }
      });
    }, 300);
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }
}
