import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from './../../shared/dialog/dialog.service';
import { Login } from './login.model';
import { LoginService } from './../login/login.service';
import { LoginStateService } from './login-state.service';
import { LoginOptionsDialogComponent } from './login-options-dialog/login-options-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit {

  credentials: {
    email?: string,
    password?: string
  } = {};
  fetching: boolean;
  isLoggedIn: boolean;
  ngDestroy$ = new Subject();
  returnUrl: string;
  showPassword = false;

  constructor(
      private dialogService: DialogService,
      private loginService: LoginService,
      private loginStateService: LoginStateService,
      @Inject(PLATFORM_ID) private platformId: Object,
      private route: ActivatedRoute,
      private router: Router,
      private dialog: MatDialog
  ) {
    this.loginStateService.login$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(login => {
      if (login) {
        this.isLoggedIn = login.isLoggedIn;
      }
    });
  }

  login(): void {
    this.fetching = true;
    this.loginService
      .login(this.credentials.email, this.credentials.password)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          this.fetching = false;
          if (response['data'] && response['data']['token']) {
            const login = new Login(response['data']['token'], response['data']['refreshToken']);
            this.loginStateService.saveLogin(login);

            this.returnUrl = this.returnUrl.replace('returnUrl=', ''); // Fix for cases when queryParams not properly read
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.dialogService.showDialog('AUTH.LOGIN_FAILED', null, 'AUTH.NO_TOKEN');
          }
        },
        error => {
          this.fetching = false;
          this.dialogService.showDialog('AUTH.LOGIN_FAILED', null, error.error.errors[0]);
        });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isLoggedIn) {
        this.loginService.logout();
      }
      // get return url from route parameters or default to '/orders'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/orders';

      // auto-login
      if (this.route.snapshot.queryParams['jwt']) {
        const login = new Login(this.route.snapshot.queryParams['jwt']);
        this.loginStateService.saveLogin(login);

        this.router.navigateByUrl(this.returnUrl);
      }
    }
  }

  toggleShowPassword(event?: MouseEvent): void {
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginOptionsDialogComponent, {
      width: '400px', // Adjust width as needed
      autoFocus: false // Optionally disable autofocus
    });

    // Handle dialog close if needed
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed');
    });
  }
}
