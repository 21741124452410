<form class="registration-form" [formGroup]="formGroup">

  <mat-form-field>
    <mat-label>{{ 'FULL_NAME' | translate }}</mat-label>
    <input
        autocomplete="name"
        matInput
        formControlName="name">
    <mat-hint>{{ 'GIVEN_NAME' | translate }} & {{ 'FAMILY_NAME' | translate }}</mat-hint>
    <mat-error *ngIf="name.errors?.required">{{ 'GIVEN_NAME' | translate }} & {{ 'FAMILY_NAME' | translate }}</mat-error>
    <mat-error *ngIf="name.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: name.errors?.maxlength?.requiredLength } }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'EMAIL' | translate }}</mat-label>
    <input
        autocomplete="email"
        formControlName="email"
        matInput
        type="email">
    <mat-error *ngIf="email.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: email.errors?.maxlength?.requiredLength } }}</mat-error>
    <mat-error *ngIf="email.errors?.email">{{ 'VALIDATION.INVALID_EMAIL' | translate }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
    <input
        autocomplete="new-password"
        formControlName="password"
        matInput
        type="{{ showPassword ? 'text' : 'password' }}">

    <button
        (click)="toggleShowPassword($event)"
        [disabled]="password.disabled"
        mat-icon-button
        matSuffix
        title="{{ (showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD') | translate }}"
        type="button">
      <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>

    <mat-error *ngIf="password.errors?.minlength">{{ 'VALIDATION.MIN_LENGTH' | translate: { count: password.errors?.minlength?.requiredLength } }}</mat-error>
    <mat-error *ngIf="password.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: password.errors?.maxlength?.requiredLength } }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'PHONE' | translate }} (optional)</mat-label>
    <input
        autocomplete="tel"
        formControlName="phone"
        matInput
        type="tel">
    <mat-error *ngIf="phone.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: phone.errors?.maxlength?.requiredLength } }}</mat-error>
  </mat-form-field>

  <re-captcha (resolved)="handleCorrectCaptcha($event)" [siteKey]="recaptchaKey"></re-captcha>

  <button
      color="primary"
      [disabled]="!formGroup.valid || !validCaptcha || fetching"
      (click)="register()"
      mat-raised-button
      type="submit">{{ 'REGISTER' | translate }}</button>

  <button
      [disabled]="fetching"
      mat-stroked-button
      routerLink="/login"
      type="button">{{ 'LOGIN' | translate }}</button>

</form>
