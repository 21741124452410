<div class="content" [class.dark]="this.theme && this.theme.includes('dark')">
  <button
    *ngIf="!portfolio"
    color="accent"
    class="portfolio-adder"
    mat-raised-button
    (click)="openPortfolioDialog()"
  >
    {{ 'PORTFOLIO.ADD_PORTFOLIO' | translate }}
  </button>
  <mat-card class="overview" *ngIf="portfolio">
    <mat-card-header>
      <mat-card-title>
        {{ 'PORTFOLIO.STATISTICAL_OVERVIEW' | translate }}
      </mat-card-title>
      <mat-card-actions>
        <button
          color="accent"
          class="site-button"
          mat-raised-button
          (click)="openSiteDialog()"
        >{{ 'PORTFOLIO.ADD_SITE' | translate }}</button>
      </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
      <div class="map">
        <h2>{{ 'PORTFOLIO.MAP_TITLE' | translate }}</h2>
        <span
          [class.active]="viewId === 'satellite'"
          (click)="switchView()"
          class="switcher material-icons-outlined"
        >
          layers
        </span>
        <google-map
          *ngIf="googleMapIsLoaded"
          [center]="{ lat , lng }"
          [height]="'100%'"
          [mapTypeId]="viewId"
          [options]="googleMapsOptions"
          [width]="'100%'"
          [zoom]="10">
        </google-map>
      </div>

      <div class="stats">
        <h2>{{ 'PORTFOLIO.STATISTIC_TITLE' | translate }}</h2>
        <div class="stat-content">
          <div class="average cell" *ngIf="stats">
            <span class="value">{{ !stats.averageScore? 0:stats.averageScore.toFixed(2) }}</span>
            <p>{{ 'PORTFOLIO.AVERAGE_SCORE' | translate }}</p>
          </div>
          <div class="sites cell"  *ngIf="stats">
            <span class="value">{{ stats.sitesStats.total }}</span>
            <p>{{ 'PORTFOLIO.SITES_COUNT' | translate }}</p>
          </div>
          <div class="critical cell"  *ngIf="stats">
            <span class="value">{{ stats.sitesStats.critical }}</span>
            <p>{{ 'PORTFOLIO.CRITICAL_COUNT' | translate }}</p>
          </div>
          <div class="warning cell"  *ngIf="stats">
            <span class="value">{{ stats.sitesStats.important }}</span>
            <p>{{ 'PORTFOLIO.WARN_COUNT' | translate }}</p>
          </div>
          <div class="good cell"  *ngIf="stats">
            <span class="value">{{ stats.sitesStats.minor }}</span>
            <p>{{ 'PORTFOLIO.GOOD_COUNT' | translate }}</p>
          </div>
          <div class="chart">
            <h3>{{ 'PORTFOLIO.DISTRIBUTION_TITLE' | translate }}</h3>
            <canvas #pie></canvas>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="sites" *ngIf="portfolio">
    <mat-card-header>
      <mat-card-title>
        {{ 'PORTFOLIO.SITES_OVERVIEW' | translate }}
      </mat-card-title>
<!--      TODO: Add later -->
<!--      <mat-card-actions>-->
<!--        <button-->
<!--          (click)="toggleListView(true)"-->
<!--          [color]="isListView ? 'accent' : undefined"-->
<!--          mat-raised-button-->
<!--        >{{ 'PORTFOLIO.LIST_VIEW' | translate }}</button>-->
<!--        <button-->
<!--          (click)="toggleListView(false)"-->
<!--          [color]="!isListView ? 'accent' : undefined"-->
<!--          mat-raised-button>{{ 'PORTFOLIO.MAP_VIEW' | translate }}</button>-->
<!--      </mat-card-actions>-->
    </mat-card-header>

    <mat-card-content>
      <table
        *ngIf="isListView"
        mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{ 'PORTFOLIO.LIST.NAME' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
<!--      TODO: Add later -->
<!--        <ng-container matColumnDef="type">-->
<!--          <th mat-header-cell *matHeaderCellDef> {{ 'PORTFOLIO.LIST.TYPE' | translate }} </th>-->
<!--          <td mat-cell *matCellDef="let element"> {{ labels[element.type] | translate }} </td>-->
<!--        </ng-container>-->

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> {{ 'PORTFOLIO.LIST.ADDRESS' | translate }} </th>
          <td mat-cell *matCellDef="let element" class="address"> {{ element.address }} </td>
        </ng-container>

        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef> {{ 'PORTFOLIO.LIST.SCORE' | translate }} </th>
          <td mat-cell *matCellDef="let element">
            <span class="condition" [style.background-color]="getColorOfSite(element._id)">
              {{ indicator(element._id) }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [routerLink]="['./', row._id]" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="cards" *ngIf="!isListView">
        <mat-card>
          <mat-card-content>
            <div class="image-container">
              <span class="condition">{{ 'PORTFOLIO.LIST.NAME' | translate }}</span>
              <img src="https://i.redd.it/0wgao2m0eva61.png" alt="cat">
            </div>

            <div class="info">
              <h3 class="title">Site name</h3>
              <div class="records">
                <p>
                  <span class="label">
                    <mat-icon class="material-icons-outlined">flag_circle</mat-icon>
                    Country:
                  </span>
                  <span class="value">Germany</span>
                </p>
                <p>
                  <span class="label">
                    <mat-icon class="material-icons-outlined">apartment</mat-icon>
                    City:
                  </span>
                  <span class="value">Germany</span>
                </p>
                <p>
                  <span class="label">
                    <mat-icon class="material-icons-outlined">pin_drop</mat-icon>
                    Zip code:
                  </span>
                  <span class="value">Germany</span>
                </p>
                <p>
                  <span class="label">
                    <mat-icon class="material-icons-outlined">signpost</mat-icon>
                    Street:
                  </span>
                  <span class="value">Germany</span>
                </p>
                <p>
                  <span class="label">
                    <mat-icon class="material-icons-outlined">widgets</mat-icon>
                    Type:
                  </span>
                  <span class="value">Germany</span>
                </p>
                <p>
                  <span class="label">
                    <mat-icon class="material-icons-outlined">update</mat-icon>
                    Last updates:
                  </span>
                  <span class="value">Germany</span>
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-spinner></app-spinner>
