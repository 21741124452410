import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

import { LanguageService } from './../i18n/language.service';
import { ThemeModule } from './../theme/theme.module';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  availableLanguages: Array<string> = this.languageService.availableLanguages;
  currentLanguage: string = this.languageService.getLanguage();
  dir: Direction;
  uiLanguage: string;

  constructor(private languageService: LanguageService) {
    this.uiLanguage = this.languageService.getLanguage();
    this.languageService.dir$.next(this.uiLanguage === 'ar' ? 'rtl' : 'ltr');
    this.languageService.dir$.subscribe(dir => this.dir = dir);
  }
  
  getYear(): number {
    return new Date().getFullYear()
  }

  setLanguage(locale: string): void {
    this.languageService.setLanguage(locale);
    this.currentLanguage = locale;
    this.languageService.dir$.next(locale === 'ar' ? 'rtl' : 'ltr');
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ThemeModule,
    TranslateModule.forChild()
  ],
  exports: [FooterComponent],
  declarations: [FooterComponent]
})
export class FooterModule {}
