import { Annotation } from './sidebar/annotations/annotation.model';
// eslint-disable-next-line import/no-unresolved
import { Point } from 'geojson';
import { Comment } from './comment.model';
import { Permission } from './permission.model';
import { Hotspot } from './../../../shared/pano/hotspot.model';
import { View } from './../../../shared/pano/view.model';
import { Viewer, Position3D, View3d } from './../../viewer/viewer.model';
import { TowerModel } from './sidebar/analytics-tower/tower.model';
import { Measurement } from './sidebar/measurements/measurement.model';

// We name it FileModel to not interfere with generic File type
  /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class FileModel {
  _id?: string | object;
  annotations?: Array<Annotation>;
  measurements?: Array<Measurement>
  card?: {
    name?: string;
    thumbnail?: string;
  };
  created?: Date | any;
  dem?: {
    max?: number;
    min?: number;
  }
  duration?: number;
  fileURL?: string;
  folderID?: string | object;
  geo?: Point;
  height?: number;
  iconURL?: string;
  inherit?: boolean;
  inspection?: {
    fileID?: number;
    orthomosaic?: string;
    summary?: string;
    tiles3d?: string;
    imageCones?: {
      imagesFolder?: string;
      position?: Position3D;
      scale?: number;
    }
  }
  isFolder?: boolean;
  mimeType?: string;
  modified?: Date | any | boolean;
  name?: string;
  orderID?: number;
  pano?: {
    compass?: boolean;
    compassCorrection?: number;
    customXML?: string;
    hotspots?: Array<Hotspot>;
    littlePlanet?: boolean;
    title?: string;
    view?: View;};
  pattern?: string;
  size?: number;
  tags?: Array<string>;
  taken?: Date | any;
  tower?: TowerModel;
  tiles3d?: {
    position?: Position3D;
    rotation?: Position3D;
    scale?: number;
    tilesetURL?: string;
    view3d?: View3d;
    maximumScreenSpaceError?: number;
  }
  thumbnailURL?: string;
  viewer?: Viewer;
  width?: number;
  public?: boolean;
  downloadable?: boolean;
  hidden?:boolean
  dsmFileModelId?: string;
  dsmFileModel?: FileModel;
  // fields related to ortho inspection mode
  orthoInspectionMode?: {
    imagesFolderId?: string;
    mapLayers?: FileModel[];
  }
  
  // Computed read-only fields
  comments?: Array<Comment>;
  iconLink?: string;
  meta?: object;
  yaw?: number;
  relativeAltitude?:number;
  pitch?:number;
  annotationStats?:{
    advisory?:number; 
    high?:number; 
    low?:number; 
    medium?:number; 
    total?:number; 
    urgent?:number; 
    unknown?:number; 
  }
  typesStats?:any;
  parents?: Array<FileModel>;
  path?: string;
  webContentLink?: string;
  webViewLink?: string;
  thumbnailLink?: string;

  // Helper fields
  dragging?: boolean;
  selected?: boolean;

  // Matching Wide, Thermal and zoomed images New Attributes 
  master?: string;
  hasZoomed?: boolean;
  thermalSubFileModel?: FileModel;
  zoomedSubFileModel?: FileModel;
  type?: string;

  // Helpers to align Thermal image on top of Wide image
  scalingFactor?: number;
  xTrans?: number;
  yTrans?: number;

}

export class FilterUpdate {
  filter: object;
  update: Update;
}

export class Update {
  $currentDate?: FileModel;
  $set?: FileModel;
  $unset?: FileModel;
}

export class PresignResponse {
  getURL?: string;
  key?: string;
  putURL?: string;
  putURLs?: Array<string>;
}

export class ZipRequest {
  bucket: string;
  destKey: string;
  fileIDs: Array<object>; // file & folder IDs
  folderID?: object;
  orderID?: number;
}

export interface TemplateFolder {
  userId: string;
  userName: string;
  clientId: number;
  orderId: number;
  orderName: string;
  orderAddress: string;
  root: TemplateFolderEntry;
}

export interface TemplateFolderEntry {
  name: string;
  subs: TemplateFolderEntry[];
}
