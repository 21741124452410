export interface folderCategory {
  category: string;
  names: string[];
}

export const folderCategories = {
  'de': [
    {
      category: 'Bearbeitete Aufnahmen',
      names: ['Bodenaufnahmen Foto', 'Bodenaufnahmen Panorama', 'Bodenaufnahmen Video', 'Luftaufnahmen Foto', 'Luftaufnahmen Panorama', 'Luftaufnahmen Video'],
    },
    {
      category: 'Rohaufnahmen',
      names: ['Rohaufnahmen Foto', 'Rohaufnahmen Panorama', 'Rohaufnahmen Video'],
    }
  ],
  'en': [
    {
      category: 'Edited recordings',
      names: ['Aerial photo', 'Aerial panorama', 'Aerial video', 'Ground shots photo', 'Ground shots panorama', 'Ground shots video'],
    },
    {
      category: 'Raw images',
      names: ['Raw shots photo', 'Raw shots panorama', 'Raw shots video'],
    }
  ]
};

export const folderCategoryFilter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().includes(filterValue));
};

export function folderCategoryFilterGroup(value: string, lang: string): folderCategory[] {
  if (value && typeof value === 'string') {
    return folderCategories[lang]
      .map(group => ({category: group.category, names: folderCategoryFilter(group.names, value)}))
      .filter(group => group.names.length > 0);
  }

  return folderCategories[lang];
}