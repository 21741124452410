import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';

import { Login } from './login.model';
import {User, UserRole} from '../users/models/user.model';
import { UsersService } from './../users/users.service';
import { SseServiceService } from './../../shared/sse/sse-service.service';
import { StorageService } from './../../shared/helpers/storage.service';
import { environment } from './../../../environments/environment';
import {FileModel} from "../files/file/file.model";

@Injectable({
  providedIn: 'root'
})
export class LoginStateService implements OnDestroy {

  loggedUser$: BehaviorSubject<User>;
  login$: BehaviorSubject<Login>;
  ngDestroy$ = new Subject();

  private loggedOut = new Login(null, null);

  constructor(
      private http: HttpClient,
      private storageService: StorageService,
      private usersService: UsersService,
      private sseService: SseServiceService
  ) {
    this.login$ = new BehaviorSubject(this.loggedOut);
    this.loggedUser$ = new BehaviorSubject(null);

    const login = this.getLogin();
    if (login.isLoggedIn) {
      this.login$.next(login);

      this.sseService.connectSSE(login);

      Promise.resolve(undefined).then(() => {
        const storedUser = this.storageService.getItem('loggedUser');
        if (storedUser) {
          this.loggedUser$.next(storedUser);
        }

        // Get the logged user data when the page is refreshed and save it in the local storage
        this.usersService.findOne(login._id)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(res => {
          this.loggedUser$.next(res.data);
          this.storageService.setItem('loggedUser', res.data);
        });
      });
    }
  }

  getRefreshToken(): string {
    return this.getLogin().refreshToken;
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      return this.http.post<any>(`${environment.apiPath}refreshToken`, { refreshToken })
      .pipe(
        takeUntil(this.ngDestroy$),
        tap((response: any) => {
          const newLogin = new Login(response['data']['token'], refreshToken);
          this.saveLogin(newLogin);
        })
      );
    } else {
      return throwError('RefreshToken not available');
    }

  }

  removeLogin(): void {
    this.login$.next(this.loggedOut);
    this.storageService.removeItem('login');
    this.storageService.removeItem('loggedUser');
    this.loggedUser$.next(null);
  }

  saveLogin(login: Login): void {
    this.sseService.connectSSE(login);

    this.login$.next(login);
    this.storageService.setItem('login', login);
    // Get the logged user data after login or register
    this.usersService.findOne(login._id)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(res => {
      this.loggedUser$.next(res.data);
    });
  }

  private getLogin(): Login {
    const login = this.storageService.getItem('login');

    return login && login.token ? new Login(login.token, login.refreshToken) : this.loggedOut;
  }

}
