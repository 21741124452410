import { Component } from '@angular/core';

import { DialogService } from './../../shared/dialog/dialog.service';
import { ForgotPasswordService } from './forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  email: string;
  fetching: boolean;
  successMessage: string;

  constructor(
    private dialogService: DialogService,
    private forgotPasswordService: ForgotPasswordService
  ) {

  }

  requestReset(): void {
    this.fetching = true;
    this.forgotPasswordService
    .forgotPassword(this.email)
    .subscribe(success => {
      this.fetching = false;
      this.successMessage = 'PASSWORD_RESET_SUCCESS_MESSAGE';
    },
    error => {
      this.fetching = false;
      this.dialogService.showDialog('AUTH.PASSWORD_RESET_FAILED', error.status, error.url, error.error);
    });
  }

  resetForm(): void {
    this.successMessage = undefined;
  }

}
