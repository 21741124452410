import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
  selectedFileDisplayedName: string = '';
  selectedFileFullName: string = '';

  constructor() {}

  transform(data: any): string {
    if (data.length > 25) {
      const matches = /_(\d+)(?!.*\d).*/.exec(data);
      if (matches) {
        this.selectedFileDisplayedName = "..." + matches[0];
      } else {
        const parts = data.split('_');
        this.selectedFileDisplayedName = "..." + parts[parts.length - 1];
      }
      return this.selectedFileDisplayedName
    } 
    return data
    
  }
}