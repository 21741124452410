import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Inspection3dService {

  constructor() { }
  selectedAntenna$: BehaviorSubject<string> = new BehaviorSubject('');
  visibleAntenna$: BehaviorSubject<any> = new BehaviorSubject({});
  dataStructure$: BehaviorSubject<any> = new BehaviorSubject(
    {
      "base_altitude": 705.2309332993913,
      "base_center": [
        965348.965610757353716,
        6217456.304373800754547
      ],
      "base_width": 3.13137086505113,
      "bearing": 86.71715474720197,
      "concrete_height": 0.3101442219015098,
      "epsg_code": 32632,
      "lightning_rod_height": 2.225623305447698,
      "nLegs": 4,
      "top_altitude": 734.3712917408636,
      "top_appurtenance_height": 0.9860085870403736,
      "top_steel_elevation": 29.140358441472358,
      "top_width": 1.571731017783578,
      "tower_tilt_x": 0.03234686066572403,
      "tower_tilt_y": -0.12133874054265438,
      "tower_vertical_segments": [
          {
              "altitude_bottom": 723.2857769400212,
              "altitude_top": 734.3712917408636,
              "height": 11.085514800842475,
              "width_bottom": 1.5742342519745947,
              "top_width": 1.571731017783578
          },
          {
              "altitude_bottom": 705.2309332993913,
              "altitude_top": 723.2857769400212,
              "height": 18.356464392784915,
              "width_bottom": 3.13137086505113,
              "top_width": 1.5846315611127024
          }
      ],
      "type": "lattice"
    }
  )
  dataMounts$: BehaviorSubject<Array<any>> = new BehaviorSubject(
    [
      {
        "name": "LegA-1",
        "elevation": 28.05,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegA",
        "height": 4.78,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": 111.37,
        "absoluteAzimuth": 111.37,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965350.867874756339006,
                  "y": 6217456.937735943123698,
                  "z": 735.363052982781
              },
              "endPosition": {
                  "x": 965350.875641907798126,
                  "y": 6217456.977062410674989,
                  "z": 730.5879855354133
              }
            }
        ]
      },
      {
        "name": "LegA-2",
        "elevation": 28.04,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegA",
        "height": 4.78,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": -106.76,
        "absoluteAzimuth": 253.24,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965350.904835284114815,
                  "y": 6217455.618178623728454,
                  "z": 735.360278677748
              },
              "endPosition": {
                  "x": 965350.929037170028314,
                  "y": 6217455.609472558833659,
                  "z": 730.5830881435843
              }
            }
        ]
      },
      {
        "name": "LegB-1",
        "elevation": 28.04,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegB",
        "height": 4.78,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": 111.71,
        "absoluteAzimuth": 231.71,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965349.742995845153928,
                  "y": 6217454.313894673250616,
                  "z": 735.3601578957632
              },
              "endPosition": {
                  "x": 965349.774236291996203,
                  "y": 6217454.314165127463639,
                  "z": 730.5831709890855
              }
            }
        ]
      },
      {
        "name": "LegB-2",
        "elevation": 28.04,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegB",
        "height": 4.78,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": -110.64,
        "absoluteAzimuth": 9.36,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965348.403666699305177,
                  "y": 6217454.248849997520447,
                  "z": 735.3600963151357
              },
              "endPosition": {
                  "x": 965348.423307189252228,
                  "y": 6217454.225769598260522,
                  "z": 730.5848731994075
              }
            }
        ]
      },
      {
        "name": "LegC-1",
        "elevation": 28.04,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegC",
        "height": 4.78,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": 112.39,
        "absoluteAzimuth": 352.39,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965347.103336251922883,
                  "y": 6217455.465068749152124,
                  "z": 735.359656988358
              },
              "endPosition": {
                  "x": 965347.077006031293422,
                  "y": 6217455.453104184009135,
                  "z": 730.5828855792854
              }
            }
        ]
      },
      {
        "name": "LegC-2",
        "elevation": 28.04,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegC",
        "height": 4.77,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": -108.05,
        "absoluteAzimuth": 131.95,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965347.048739987192675,
                  "y": 6217456.757500416599214,
                  "z": 735.3606748319464
              },
              "endPosition": {
                  "x": 965347.052371781086549,
                  "y": 6217456.793841776438057,
                  "z": 730.5869231289103
              }
            }
        ]
      },
      {
        "name": "LegD-1",
        "elevation": 28.05,
        "type": "Standoff (2 arms)",
        "model": null,
        "manufacturer": null,
        "location": "LegD",
        "height": 4.77,
        "width": 0,
        "horizontalOffset": 0,
        "standoffDistance": 0,
        "relativeAzimuth": 110.83,
        "absoluteAzimuth": 0,
        "faceRotation": 0,
        "horizontalPipes": [],
        "verticalPipes": [
            {
              "startPosition": {
                  "x": 965348.239957832954824,
                  "y": 6217458.053044924996793,
                  "z": 735.3639004031915
              },
              "endPosition": {
                  "x": 965348.230008986187167,
                  "y": 6217458.115117045603693,
                  "z": 730.5917225589177
              }
            }
        ]
      }
    ]
  );
  dataAntenna$: BehaviorSubject<Array<any>> = new BehaviorSubject(
    [
      {
        "name": "Antenna 1",
        "model": "7752",
        "manufacturer": "ALLGON",
        "type": "Antenna",
        "height": 79.133901,
        "width": 11.023628,
        "depth": 5.118113,
        "weight": null,
        "elevation": 6.59481,
        "face": "LegA",
        "azimuth": 91,
        "tilt": 0.5,
        "lateralOffset": 0,
        "verticalOffset": 4.338899149885362,
        "squareInchArea": 872.3399999999999,
        "position": {
            "x": 965351.281507246089168,
            "y": 6217455.613375931046903,
            "z": 734.0941153368732
        }
      },
      {
        "name": "Antenna 2",
        "model": "7752",
        "manufacturer": "ALLGON",
        "type": "Antenna",
        "height": 79.133901,
        "width": 11.023628,
        "depth": 5.118113,
        "weight": null,
        "elevation": 6.59481,
        "face": "LegA",
        "azimuth": 3,
        "tilt": 0.5,
        "lateralOffset": 0,
        "verticalOffset": 4.359123731183283,
        "squareInchArea": 872.3399999999999,
        "position": {
            "x": 965350.875991408829577,
            "y": 6217457.319045221321285,
            "z": 734.0941153368732
        }
      },
      {
        "name": "Antenna 3",
        "model": "PCS-03320-0D",
        "manufacturer": "ANDREW",
        "type": "Antenna",
        "height": 79.133901,
        "width": 15.354339000000001,
        "depth": 7.086618,
        "weight": null,
        "elevation": 6.59481,
        "face": "LegC",
        "azimuth": 360,
        "tilt": 0.5,
        "lateralOffset": 0,
        "verticalOffset": 4.574230009628298,
        "squareInchArea": 1215.0449999999998,
        "position": {
            "x": 965347.056517873187549,
            "y": 6217457.071663995496929,
            "z": 734.1679562524882
        }
      },
      {
        "name": "Antenna 4",
        "model": "PCS-03320-0D",
        "manufacturer": "ANDREW",
        "type": "Antenna",
        "height": 79.133901,
        "width": 15.354339000000001,
        "depth": 7.086618,
        "weight": null,
        "elevation": 6.59481,
        "face": "LegC",
        "azimuth": 177,
        "tilt": 0.5,
        "lateralOffset": 0,
        "verticalOffset": 4.569921354596526,
        "squareInchArea": 1215.0449999999998,
        "position": {
            "x": 965347.117131228093058,
            "y": 6217455.18574719723314,
            "z": 734.1641153419127
        }
      },
      {
        "name": "Antenna 5",
        "model": "7752",
        "manufacturer": "ALLGON",
        "type": "Antenna",
        "height": 79.133901,
        "width": 11.023628,
        "depth": 5.118113,
        "weight": null,
        "elevation": 6.59481,
        "face": "LegB",
        "azimuth": 183,
        "tilt": 0.5,
        "lateralOffset": 0,
        "verticalOffset": 4.303459934675079,
        "squareInchArea": 872.3399999999999,
        "position": {
            "x": 965348.42178753414657,
            "y": 6217453.882445001602173,
            "z": 734.0641153377882
        }
      },
      {
        "name": "Antenna 6",
        "model": "PCS-03320-0D",
        "manufacturer": "ANDREW",
        "type": "Antenna",
        "height": 79.133901,
        "width": 15.354339000000001,
        "depth": 7.086618,
        "weight": null,
        "elevation": 6.59481,
        "face": "LegB",
        "azimuth": 93,
        "tilt": 0.5,
        "lateralOffset": 0,
        "verticalOffset": 4.503011384686805,
        "squareInchArea": 1215.0449999999998,
        "position": {
            "x": 965350.032254242799245,
            "y": 6217454.32078006118536,
            "z": 734.1641153368732
        }
      }
    ]
  );
  dataRRU$: BehaviorSubject<Array<any>> = new BehaviorSubject(
    [
      {
        "name": "RRU 1",
        "model": "TMA2117F00V1-1",
        "manufacturer": "KAELUS",
        "type": "RRU",
        "height": 12.598432,
        "width": 11.81103,
        "depth": 5.118113,
        "weight": null,
        "elevation": 1.04992,
        "face": "LegC",
        "azimuth": 159.5,
        "tilt": 0,
        "lateralOffset": 0,
        "verticalOffset": 5.414687966548447,
        "squareInchArea": 148.8,
        "position": {
          "x": 965347.170485526323318,
          "y": 6217456.490128476008773,
          "z": 734.5971153371941
        }
      },
      {
        "name": "RRU 2",
        "model": "TMA2117F00V1-1",
        "manufacturer": "KAELUS",
        "type": "RRU",
        "height": 12.598432,
        "width": 11.81103,
        "depth": 5.118113,
        "weight": null,
        "elevation": 1.04992,
        "face": "LegC",
        "azimuth": 2,
        "tilt": -0.5,
        "lateralOffset": 0,
        "verticalOffset": 5.291741338061984,
        "squareInchArea": 148.8,
        "position": {
          "x": 965347.102900086669251,
          "y": 6217455.771720009402931,
          "z": 734.5391153368732
        }
      },
      {
        "name": "RRU 3",
        "model": "TMA2117F00V1-1",
        "manufacturer": "KAELUS",
        "type": "RRU",
        "height": 12.598432,
        "width": 11.81103,
        "depth": 5.118113,
        "weight": null,
        "elevation": 1.04992,
        "face": "LegB",
        "azimuth": 276.5,
        "tilt": -2,
        "lateralOffset": 0,
        "verticalOffset": 5.48731139394244,
        "squareInchArea": 148.8,
        "position": {
          "x": 965349.437533941818401,
          "y": 6217454.346973086707294,
          "z": 734.6215153396942
        }
      }
    ]
  )
  dataMicroWave$: BehaviorSubject<Array<any>> = new BehaviorSubject(
    [
      {
        "name": "Micro Wave 1",
        "model": "SSD2-52A",
        "manufacturer": "GABRIEL ELECTRONICS",
        "type": "Micro_Wave",
        "height": 25.984266,
        "width": 25.984266,
        "depth": 9.448824,
        "weight": null,
        "elevation": 2.1654600000000004,
        "face": "LegA",
        "azimuth": 50.5,
        "tilt": 0,
        "lateralOffset": 0,
        "verticalOffset": -2.498166257736663,
        "squareInchArea": 675.1800000000001,
        "position": {
          "x": 965350.70393159170635,
          "y": 6217457.422135006636381,
          "z": 732.1791153402503
        }
      },
      {
        "name": "Micro Wave 2",
        "model": "SSD2-52A",
        "manufacturer": "GABRIEL ELECTRONICS",
        "type": "Micro_Wave",
        "height": 25.984266,
        "width": 25.984266,
        "depth": 9.448824,
        "weight": null,
        "elevation": 2.1654600000000004,
        "face": "LegA",
        "azimuth": 94,
        "tilt": -0.5,
        "lateralOffset": 0,
        "verticalOffset": -1.7965708461507128,
        "squareInchArea": 675.1800000000001,
        "position": {
          "x": 965351.031681524755433,
          "y": 6217455.140754977241158,
          "z": 732.45
        }
      }
    ]
  );
}
