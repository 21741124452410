<mat-nav-list>

  <!-- Login -->
  <a
      *ngIf="!login?.isLoggedIn"
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'login', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>account_circle</mat-icon>
    <p mat-line>{{ 'LOGIN' | translate }}</p>
  </a>

  <!-- User -->
  <a
      *ngIf="login?.isLoggedIn && !loggedUser?.provider"
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      queryParamsHandling="merge"
      routerLinkActive="active"
      [routerLink]="['user', login._id]">

    <mat-icon
        *ngIf="!loggedUser?.avatarIconLink"
        mat-list-icon>account_circle</mat-icon>

    <img
        *ngIf="loggedUser?.avatarIconLink"
        alt="{{ loggedUser.name }} {{ loggedUser.surname }}"
        class="avatar-img"
        draggable="false"
        mat-list-icon
        src="{{ loggedUser.avatarIconLink }}">

    <p mat-line>{{ loggedUser?.name }} {{ loggedUser?.surname }}</p>
  </a>

  <!-- Sites -->
  <!-- ToDo: Rename for now until multiple portfolios are implemented FF-4749 -->
  <a
    *ngIf="login?.isLoggedIn && loggedUser?.accountType === accountType.CLIENT"
    (click)="closeSidenavNavigation()"
    draggable="false"
    mat-list-item
    [routerLink]="[{ outlets: { primary: 'portfolio', detail: null }}]"
    routerLinkActive="active">
    <mat-icon mat-list-icon svgIcon="portfolio"></mat-icon>
    <p mat-line>{{ 'PORTFOLIO.SITES_COUNT' | translate }}</p>
  </a>

  <!-- Tasks: Kanban Board -->
  <a
    *ngIf="login?.isLoggedIn"
    (click)="closeSidenavNavigation()"
    draggable="false"
    mat-list-item
    [routerLink]="[{ outlets: { primary: 'tasks', detail: null }}]" [queryParams]="{ groupby: 'orderID' }"
    routerLinkActive="active">
    <mat-icon mat-list-icon svgIcon="tasks"></mat-icon>
    <p mat-line>{{ 'TASKS.TASKS' | translate }}</p>
  </a>

  <!-- Orders -->
  <a
    *ngIf="login?.isLoggedIn"
    (click)="closeSidenavNavigation()"
    draggable="false"
    mat-list-item
    [routerLink]="[{ outlets: { primary: 'orders', detail: null }}]"
    routerLinkActive="active">
    <mat-icon mat-list-icon>description</mat-icon>
    <p mat-line>{{ 'ORDERS.TITLE' | translate }}</p>
  </a>

  <!-- Files -->
  <a
    *ngIf="login?.isLoggedIn"
    (click)="closeSidenavNavigation()"
    draggable="false"
    mat-list-item
    [routerLink]="[{ outlets: { primary: 'files', detail: null }}]"
    routerLinkActive="active">
    <mat-icon mat-list-icon>folder</mat-icon>
    <p mat-line>{{ 'FILES.TITLE' | translate }}</p>
  </a>

  <!-- Users -->
  <a
      *ngIf="login?.isLoggedIn && [accountType.ADMIN, accountType.SUPERADMIN].includes(this.login?.accountType)"
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'users', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>people</mat-icon>
    <p mat-line>{{ 'USERS.TITLE' | translate }}</p>
  </a>

  <!-- Map -->
  <a
      *ngIf="login?.isLoggedIn"
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'maps', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>map</mat-icon>
    <p mat-line>{{ 'MAPS.TITLE' | translate }}</p>
  </a>

  <!-- Background Tasks -->
  <a
      *ngIf="login?.isLoggedIn"
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'tasklist', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>task</mat-icon>
    <p mat-line>{{ 'TASKLIST.TITLE' | translate }}</p>
  </a>

</mat-nav-list>

<app-upload *ngIf="login?.isLoggedIn" [dir]="dir"></app-upload>

<div class="fill-remaining"></div>

<mat-nav-list>
  <a
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'blog', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>rss_feed</mat-icon>
    <p mat-line>{{ 'POSTS.TITLE' | translate }}</p>
  </a>

  <a
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'faq', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>question_answer</mat-icon>
    <p mat-line>{{ 'FAQ.TITLE' | translate }}</p>
  </a>

  <a
      href="https://fairfleet360.com/legal/imprint"
      draggable="false"
      mat-list-item
      rel="noopener"
      target="_blank">
    <mat-icon mat-list-icon>business</mat-icon>
    <p mat-line>{{ 'IMPRINT' | translate }}</p>
  </a>

  <a
      href="https://fairfleet360.com/legal/privacy"
      draggable="false"
      mat-list-item
      rel="noopener"
      target="_blank">
    <mat-icon mat-list-icon>policy</mat-icon>
    <p mat-line>{{ 'PRIVACY' | translate }}</p>
  </a>


  <a
      *ngIf="login?.isLoggedIn"
      (click)="closeSidenavNavigation()"
      draggable="false"
      mat-list-item
      [routerLink]="[{ outlets: { primary: 'login', detail: null }}]"
      routerLinkActive="active">
    <mat-icon mat-list-icon>logout</mat-icon>
    <p mat-line>{{ 'LOGOUT' | translate }}</p>
  </a>
</mat-nav-list>

<app-footer></app-footer>
