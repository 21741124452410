import { BehaviorSubject } from 'rxjs';
import { Direction, Directionality } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { availableLanguages, defaultUiLanguage } from './../../config';
import { StorageService } from './../helpers/storage.service';

@Injectable()
export class LanguageService {

  availableLanguages: Array<string> = availableLanguages;
  dir$: BehaviorSubject<Direction>;

  constructor(
      private directionality: Directionality,
      private storageService: StorageService,
      private translate: TranslateService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(defaultUiLanguage);
    // adding available localization languages
    translate.addLangs(availableLanguages);

    const storedLanguage = this.storageService.getItem('language') || '';
    const browserLanguage = translate.getBrowserLang();

    if (!storedLanguage && availableLanguages.indexOf(browserLanguage) !== -1) {
      // use language saved by currentUser
      translate.use(translate.getBrowserLang());
    } else if (!storedLanguage && availableLanguages.indexOf(browserLanguage) === -1) {
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use(defaultUiLanguage);
    } else {
      // use browser language
      translate.use(storedLanguage);
    }

    this.dir$ = new BehaviorSubject(this.directionality.value);
  }

  getLanguage(): string {
    return this.translate.currentLang;
  }

  setLanguage(locale: string): void {
    this.translate.use(locale);
    this.storageService.setItem('language', locale);
  }
}
