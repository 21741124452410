<div class="page" [class.dark]="this.theme && this.theme.includes('dark')" *ngIf="!fetching">
  <div class="center-content" *ngIf="groupedTasks?.length === 0 && showOnboardingGuide">
    <img class="center-img" src="/assets/images/Area.gif" alt="Guide GIF">
  </div>
  <div class="dark-overlay" *ngIf="groupedTasks?.length === 0 && showOnboardingGuide" (click)="closeOnboardingGuide()">
  </div>
  <div>
    <div class="action-bar">
      <div class="left">
        <!-- <mat-form-field appearance="outline">
          <mat-label>{{ "TASKS.FILTER_BY" | translate }}</mat-label>
          <mat-select [(ngModel)]="filterBy" (selectionChange)="onFilterByChange($event.value)">
            <mat-option value="task">{{ "TASKS.TASK" | translate }}</mat-option>
            <mat-option value="assignee">{{ "TASKS.ASSIGNEE" | translate }}</mat-option>
            <mat-option value="creator">{{ "TASKS.CREATOR" | translate }}</mat-option>
            <mat-option value="urgency">{{ "TASKS.LEVEL_OF_URGENCY" | translate }}</mat-option>
            <mat-option value="order">{{ "SIDEBAR.SETTINGS.INFO.ORDER_ID" | translate }}</mat-option>
            <mat-option value="site">{{ "SITE.SITE" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="search-bar">
          <mat-label>{{ 'SEARCH' | translate }}</mat-label>
          <mat-icon matSuffix>search</mat-icon>
          <input matInput [(ngModel)]="searchQuery" (input)="onSearchQueryChange($event.target.value)" placeholder="{{ 'ORDERS.SEARCH_HELP' | translate }}">
        </mat-form-field> -->
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ "TASKS.GROUP_BY" | translate }}</mat-label>
        <mat-select [(ngModel)]="groupBy" (selectionChange)="updateUrl()">
          <!-- <mat-option value="site">{{ "SITE.SITE" | translate }}</mat-option> -->
          <mat-option value="orderID">{{ "ORDER.TITLE" | translate }}</mat-option>
          <mat-option value="creator">{{ "TASKS.CREATOR" | translate }}</mat-option>
          <mat-option value="assignee">{{ "TASKS.ASSIGNEE" | translate }}</mat-option>
          <mat-option value="deadline">{{ "TASKS.DEADLINE" | translate }}</mat-option>
          <!-- <mat-option value="creationDate">{{ "TASKS.CREATION_DATE" | translate }}</mat-option> -->
          <mat-option value="urgency">{{ "TASKS.LEVEL_OF_URGENCY" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tasks-board" cdkDropListGroup>
      <div class="tasks-column" *ngFor="let status of ['to_do', 'in_progress', 'review', 'done']; let i = index"
        cdkDropList [cdkDropListData]="tasksByStatus[status]" [class.collapsed]="!isColumnExpanded(i)"
        (click)="toggleColumn(i)">
        <div class="tasks-column-header">
          <div class="left">
            <mat-icon svgIcon="{{status}}">
            </mat-icon>
            <span *ngIf="isColumnExpanded(i)">
              {{ ('TASKS.STATUS.'+(status | uppercase)) | translate }}
            </span>
          </div>
          <mat-icon *ngIf="isColumnExpanded(i)">keyboard_arrow_left</mat-icon>
        </div>
      </div>
    </div>
    <div class="groupBy" *ngIf="showOnboardingGuide === true">
      <div class="tasks-board">
        <div class="tasks-column" *ngFor="let status of ['to_do', 'in_progress', 'review', 'done']; let i = index"
          [class.hide-column]="isColumnHidden(i)">
          <div class="tasks-column-body empty " *ngIf="isColumnExpanded(i)">
          </div>
        </div>
      </div>
    </div>
    <div class="groupBy" *ngFor="let group of groupedTasks">
      <span class="groupBy-header">{{groupBy | titlecase }}
        {{groupBy==='order'? 'FF':''}}{{groupBy==='deadline'? (group.key | date): group.key}}
      </span>
      <div class="tasks-board" cdkDropListGroup>
        <div class="tasks-column" *ngFor="let status of ['to_do', 'in_progress', 'review', 'done']; let i = index"
          cdkDropList [cdkDropListData]="group.tasksByStatus[status]"
          (cdkDropListDropped)="drop($event, status, group.key)" [class.collapsed]="!isColumnExpanded(i)">
          <div class="tasks-column-body">
            <div class="wrapper-task-item" *ngIf="isColumnExpanded(i)">
              <div *ngFor="let task of group.tasksByStatus[status]; let i = index" class="task-item" cdkDrag
                [cdkDragData]="task" [@dragDrop] (mousedown)="onMouseDown(taskComponent)"
                (cdkDragStarted)="onDragStarted()" (click)="openTaskPopup(task)">
                <app-task #taskComponent [task]="task"></app-task>
                <!-- Placeholder for the item being dragged -->
                <ng-container *cdkDragPlaceholder>
                  <div class="task-placeholder"></div>
                </ng-container>
                <!-- Capture the preview element -->
                <ng-container *cdkDragPreview>
                  <div class="task-preview" #dragPreview>
                    <app-task [task]="task"></app-task>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
