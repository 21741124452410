<div
  id="orders"
  cdkScrollable
  class="orders"
  tabindex="0">
  <div class="header-row">
    <div class="left-side">
      <mat-form-field class="search-input" appearance="outline">
        <mat-label>{{ 'SEARCH' | translate }}</mat-label>
        <mat-icon matSuffix>search</mat-icon>
        <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'ORDERS.SEARCH_HELP'| translate }}">
      </mat-form-field>
    </div>
    <button mat-raised-button
    *ngIf="[accountType.ADMIN,accountType.SUPERADMIN,accountType.CLIENT].includes(loggedUser?.accountType)"
    color="accent"
    class="primary-button"
    onclick="location.href='https://fairfleet360.com/map-selection'">
      <mat-icon class="primary-button-icon">add</mat-icon>
      {{ 'ORDERS.CREATE_ORDER' | translate }}
    </button>
  </div>

  <div class="order-stats" *ngIf="stats">
    <div class="orders-wrapper">
      <a [class.dark-order-stat]="theme && theme.includes('dark')" class="order-stat"
        (click)="selectStatusGroupForFilter(statGroup)"
        *ngFor="let statGroup of groupedStats"
        [ngStyle]="{
          'box-shadow': 'inset 0px -4px 0px 0px ' + ordersService.getOrderColor(statGroup),
          'background-color': statGroup === selectedStatusGroup ? (theme?.includes('dark') ? '#383838' : 'rgba(41, 121, 255, 0.08)')  : (theme?.includes('dark') ? '#525252' : 'white'),
          'color': statGroup === selectedStatusGroup ? ordersService.getOrderColor(statGroup) : (theme?.includes('dark') ? '#b9b9b9' : '#4c4c4c')
        }"
        [ngClass]="{'is-selected': statGroup === filterStatus, 'selected-status-group': statGroup === selectedStatusGroup}">
        <div class="order-stat-header">{{'ORDERS.GROUP_' + statGroup.toUpperCase() | translate | uppercase}}</div>
        <div class="order-stat-second-line">
          <mat-icon class="order-stat-icon"
          [ngStyle]="{
            'opacity': statGroup === selectedStatusGroup ? 1 : '',
            'color': statGroup === selectedStatusGroup ? ordersService.getOrderColor(statGroup) : ''
          }"
          >{{ordersService.getStatusIcon(statGroup)}}</mat-icon>
          <div class="order-stat-value">{{stats[statGroup]? stats[statGroup] : '0'}}</div>
        </div>
      </a>
    </div>
  </div>

  <div class="table-container" cdkScrollable>

    <mat-table
      #table
      [dataSource]="loadedData"
      matSort
      matSortDisableClear
      [matSortActive]="'legacyId'"
      [matSortDirection]="'desc'"
      [ngClass]="'c' + displayedColumns.length"
      [trackBy]="trackByIndex">

      <ng-container matColumnDef="legacyId">
        <mat-header-cell *matHeaderCellDef mat-sort-header="legacyId" class="x2" dir="auto">{{ 'ORDER.ID' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="x2" dir="auto">
          <span
            queryParamsHandling="merge"
            [routerLink]="[{ outlets: { detail: ['orders', row.legacyId]}}]"
            [queryParams]="{'pilot': loggedUser.accountType === 'pilot' ? '1' : ''}"
            tabindex="-1"
            title="{{ 'INFO' | translate }}">
            FF{{ row.legacyId }}
          </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ 'PAGINATOR_RANGE' | translate: {
          start: 1,
          end: loadedItemsCount,
          total: totalItemsCount
          } }}
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef class="x2" dir="auto">{{ 'ORDERS.ACCESS_LEVEL' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="x2" dir="auto">
          <span tabindex="-1" title="role">
            {{ 'USER.ROLE.' + row.role | translate }}
          </span>

        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <mat-header-cell *matHeaderCellDef class="x4" dir="auto">{{ 'ORDERS.EXPIRES_IN' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="x4" dir="auto">
            <!-- is an admin OR is the owner client without a subscription -->
            <span *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(loggedUser.accountType) || (loggedUser.accountType === accountType.CLIENT && row.clientId === loggedUser._id && !row.hasSubscription)" tabindex="-1" title="Expires in">
              {{ row.dateSetToVerify | addDays:90 | expireIn | translate }}
            </span>
            <!-- is the owner client with a subscription -->
            <span *ngIf="loggedUser.accountType === accountType.CLIENT && row.clientId === loggedUser._id && row.hasSubscription" tabindex="-1" title="Expires in">
              {{ 'ORDERS.LIFE_TIME_ACCESS' | translate }}
            </span>
            <!-- is a collaborator -->
            <span *ngIf="loggedUser.accountType === accountType.CLIENT && row.clientId !== loggedUser._id" tabindex="-1" title="Expires in">
              {{ row.hasAccessUntil | expireIn | translate }}
            </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef class="x4" dir="auto">{{ 'ORDER.PROJECT_NAME' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row" class="x4" dir="auto">
          <span
            queryParamsHandling="merge"
            tabindex="-1"
            title="{{'ORDER.PROJECT_NAME' | translate}}">
            {{ row.projectName }}
          </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef class="x4" dir="auto">{{ 'ORDER.ADDRESS' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row" class="x4" dir="auto">
          <span
            queryParamsHandling="merge"
            tabindex="-1"
            title="{{ 'ADDRESS' | translate }}">
            {{ row.address }}
          </span>
        </mat-cell>

        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef class="x4" dir="auto">{{ 'ORDER.STATUS' | translate }}</mat-header-cell>

        <mat-cell *matCellDef="let row" class="x4" dir="auto">
          <div class="status-chip">
            <mat-chip-list>
              <mat-chip class="mat-chip-content" [ngStyle]="{'background-color': ordersService.getOrderColor(ordersService.getOrderGroup(row.status))}"
              (click)=" handleLinkClick(row, $event) "
              queryParamsHandling="merge">
                <span
                  class="mat-chip-value"
                  tabindex="-1"
                  title="{{ 'ORDER.STATUS' | translate }}">
                  {{ getOrderStatusLabel(row) | translate | uppercase }}
                </span>
                <mat-icon *ngIf="!disabled(row)" class="mat-chip-icon" >arrow_forward</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>

        </mat-cell>

        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row [class.dark-background]="theme && theme.includes('dark')" class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row (click)="handleRowClick(row)" [class.selected]="selectedId === row.legacyId" #matRow *matRowDef="let row; let i = index; columns: displayedColumns;" [id]="row.legacyId" class="pointer"></mat-row>
      <mat-footer-row [class.dark-background]="theme && theme.includes('dark')" class="table-footer" *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
    </mat-table>

  </div>
</div>


<!-- Context menu -->
<div
  class="context-menu-trigger"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="menu"
  [matMenuTriggerData]="{ id: 'contextMenuTrigger', user: null }">
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-order="order">
    <button (click)="showDetails(order)" mat-menu-item>
      <mat-icon aria-label="info">info</mat-icon>
      <span>{{ 'INFO' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
