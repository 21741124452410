<h2 mat-dialog-title>{{ 'FILES.BENTLEY_GETEQUIPMENT' | translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" novalidate>
    <mat-form-field>
      <mat-label>{{ 'ORDER.BENTLEY_PROJECT_ID' | translate }}</mat-label>
      <input
          formControlName="bentleyProjectID"
          (keyup.enter)="dialogRef.close({bentleyProjectID: bentleyProjectID.value})"
          matInput>
      <mat-icon matSuffix>shop</mat-icon>
      <mat-error *ngIf="bentleyProjectID.errors?.pattern">{{ 'VALIDATION.USE_SAFE_CHARACTERS' | translate }}</mat-error>
      <mat-error *ngIf="bentleyProjectID.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: bentleyProjectID.errors?.maxlength?.requiredLength } }}</mat-error>
      <mat-error *ngIf="bentleyProjectID.errors?.minlength">{{ 'VALIDATION.MIN_LENGTH' | translate: { count: bentleyProjectID.errors?.minlength?.requiredLength } }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      type="button">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      #create
      color="accent"
      [disabled]="!formGroup.valid"
      [mat-dialog-close]="{ bentleyProjectID: bentleyProjectID.value }"
      mat-raised-button
      type="button">{{ 'OK' | translate | uppercase }}</button>
</div>
