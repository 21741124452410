import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData } from 'chart.js';
import { FlightStats } from '../../pages/users/models/interfaces/stats.interfaces';
import { ThemeService } from '../theme/theme.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-flight-stats',
  templateUrl: './flight-stats.component.html',
  styleUrls: ['./flight-stats.component.scss']
})
export class FlightStatsComponent implements AfterViewInit, OnInit, OnDestroy {
  public inputData: FlightStats;
  private maxIndex: number = 0;
  @Input()
  public set inputDataSetter(inputData: FlightStats) {
    this.inputData = inputData;
    if (!inputData) return;
    let maxValue = 0;
    this.inputData.monthsFlight
      .forEach((value, index)=> {
        if (maxValue < value) {
          maxValue = value
          this.maxIndex = index;
        }
      })

    this.updateChart();
  }

  public pieChart!: Chart;
  public chart!: Chart;
  private destroy$ = new Subject<void>();

  public data: ChartData<'line', number[], string> = {
    labels: [],
    datasets: [{
      label: 'Data Points',
      borderWidth: 2,
      data: [],
      borderColor: 'blue',
      pointRadius: 0,
      tension: 0.4,
      fill: true
    }]
  };

  public config: ChartConfiguration<'line', number[], string> = {
    type: 'line',
    data: this.data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          grid: {
            display: false
          },
          title: {
            display: false,
          },
          ticks: {
            includeBounds: false,
            maxRotation: 0,
            align: 'inner',
            color: (ctx) => ctx.index === this.maxIndex ? '#2979FF' : 'inherit',
            callback: (tickValue, index, ticks) => {
              if (ticks.length > 2) {
                return index === 0 || index === ticks.length - 1 ? '' : this.data.labels[index];
              }
              return this.data.labels[index];
            }
          },
        },
        y: {
          display: false
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    }
  }

  public dataPie: ChartData<'pie', number[], string> = {
    labels: ['Blue', 'Gray'],
    datasets: [{
      data: [],
      backgroundColor: ['#2979FF', '#F2F2F2'],
      borderWidth: 0
    }]
  };

  public configPie: ChartConfiguration<'pie', number[], string> = {
    type: 'pie',
    data: this.dataPie,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
        datalabels: {
          display: false
        }
      }
    },
  };

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('pie') pie: ElementRef<HTMLCanvasElement>;

  constructor(
    private readonly theme: ThemeService,
    private readonly translate: TranslateService
  ) { }

  public ngOnInit(): void {
    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        this.data.labels = this.inputData.mothsLabels
          .map(label => this.translate.instant(label));
        this.chart.update();
      })

    this.theme.changed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        if (!this.chart) return;
        this.data.datasets[0].backgroundColor = this.getGradientFill();
        this.config.options.scales.x.ticks.color = (ctx) =>
          ctx.tick.label === 'April' ? '#2979FF' :  this.theme.isDarkMode ? 'white' : 'inherit';
        this.chart.update();
      })
  }

  public getGradientFill(): CanvasGradient {
    const ctx = this.canvas.nativeElement.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 120);
    gradient.addColorStop(0, 'rgb(0,31,255)');
    gradient.addColorStop(0.8, this.theme.isDarkMode ? '#424242' : 'rgba(255,255,255,0)');
    return gradient;
  }

  public ngAfterViewInit(): void {
    this.updateChart();
  }

  private updateChart(): void {
    if (!this.canvas?.nativeElement || !this.inputData) return;

    this.data.labels = this.inputData.mothsLabels
      .map(label => this.translate.instant(label));
    this.data.datasets[0].data = this.inputData.monthsFlight;
    this.data.datasets[0].backgroundColor = this.getGradientFill();
    this.dataPie.datasets[0].data = [this.inputData.pieData, 100 - this.inputData.pieData];

    if (this.chart) {
      this.chart.update();
      this.pieChart.update();
    } else {
      this.chart = new Chart(this.canvas.nativeElement, this.config);
      this.pieChart = new Chart(this.pie.nativeElement, this.configPie);
    }
  }


  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
