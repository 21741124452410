import * as HttpStatus from 'http-status-codes';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { DialogComponent } from './dialog.component';

@Injectable()
export class DialogService {

  constructor(
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  showDialog(
      title?: string,
      statusCode?: number,
      message?: string,
      error?: any,
      confirm?: boolean,
      inform?: boolean
  ): boolean | Observable<boolean> {
    let status: string;

    if (statusCode === 0) {
      error = null;
      message = 'ERRORS.SOMETHING_WENT_WRONG';
      status = null;
      if (isPlatformBrowser(this.platformId)) {
        if (navigator.onLine) {
          message = 'ERRORS.OFFLINE';
        }
      }
    } else if (statusCode === 401) {
      error = null;
      status = null;
      message = 'ERRORS.UNAUTHORIZED';
    } else if (statusCode >= 100 && statusCode < 600) {
      status = `${statusCode} (${HttpStatus.getReasonPhrase(statusCode)})`;
    } else if (statusCode) {
      status = String(statusCode);
    }

    // Don't show dialog if another is already opened
    if (this.dialog.openDialogs.length) {
      return false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        confirm,
        error,
        inform,
        message,
        status,
        title
      }
    });

    if (confirm || inform) {
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

}
