import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, HostBinding, HostListener, Inject, Input, NgZone,
  OnDestroy, PLATFORM_ID, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkScrollable, ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import { debounceTime, skipWhile, takeUntil } from 'rxjs/operators';
import { Direction } from '@angular/cdk/bidi';
import { isPlatformBrowser } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';

import { ActiveHeader } from './../../shared/header/header.interface';
import { Column } from './../../shared/interfaces/column.interface';
import { DialogService } from './../../shared/dialog/dialog.service';
import { HeaderService } from './../../shared/header/header.service';
import { LoginStateService } from './../login/login-state.service';
import { popAnimation } from './../../shared/animations/pop.animation';
import { AccountType, User } from './models/user.model';
import { searchToFilter, userSearchFilter } from './../../shared/helpers/data-helpers';
import { SidenavDetailService } from './../../shared/sidenav/sidenav-detail/sidenav-detail.service';
import { StorageService } from './../../shared/helpers/storage.service';
import { UsersService } from './users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [popAnimation]
})
export class UsersComponent implements AfterViewInit, OnDestroy, ActiveHeader {
  activeDetailID = '';
  columns: Array<Column> = [
    { label: 'AVATAR_CHECKBOX', mayNotBeHidden: true, value: 'avatar-checkbox' },
    { label: 'ID', value: '_id' },
    { label: 'NAME', value: 'name' },
    { label: 'EMAIL', value: 'email' },
    { label: 'PHONE', value: 'phone' },
    { label: 'ADDRESS', value: 'address' },
    { label: 'USER.ACCOUNT_TYPE', value: 'accountType' },
    { label: 'ACTIVE', value: 'active' },
    { label: 'CREATED', value: 'created' },
    { label: 'MODIFIED', value: 'modified' },
    { label: 'MORE', mayNotBeHidden: true, value: 'more' }
  ];
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuTrigger: MatMenuTrigger;
  dataSource: Array<User> = []; 
  @Input() dir: Direction;
  displayedColumns = ['avatar-checkbox', 'name', 'email', 'phone', 'address', 'accountType', 'active', 'modified', 'more'];
  fetching = true; // Initiate as true, so that the empty-status doesn't show
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isSmall = false;
  isXSmall = false;
  lastIndex = -1;
  lastSelected = 0;
  login = this.loginStateService.login$.value;
  @ViewChildren('matRow', { read: ViewContainerRef }) matRows: QueryList<ViewContainerRef>;
  @ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;
  navigationStart: NavigationStart;
  nextPage: EventEmitter<any> = new EventEmitter();
  ngDestroy$ = new Subject();
  pageSize = 50;
  cursor = 0;
  lastCountItemReceived = 50;
  refreshed: EventEmitter<any> = new EventEmitter();
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject(null);
  selectedCount = 0;
  @ViewChild(MatSort) sort: MatSort;
  subscription: Subscription;
  @ViewChild('table') table: MatTable<Element>;
  totalItems = 0;
  totalLimit = 10000; // When totalItems reaches this totalLimit, then we add the + sign, eg: "1 - 50 of 10000+"
  accountType = AccountType;
  viewMode: 'list' = 'list'; // we need this to show/hide the table Columns

  constructor(
      // private applicationRef: ApplicationRef,
      private breakpointObserver: BreakpointObserver,
      private dialogService: DialogService,
      private headerService: HeaderService,
      private loginStateService: LoginStateService,
      private ngZone: NgZone,
      private platform: Platform,
      @Inject(PLATFORM_ID) private platformId: Object,
      private route: ActivatedRoute,
      private router: Router,
      private scrollDispatcher: ScrollDispatcher,
      private sidenavDetailService: SidenavDetailService,
      private storageService: StorageService,
      private translate: TranslateService,
      private usersService: UsersService,
      private viewportRuler: ViewportRuler
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0); // On mobile portrait the list is not scrolled at the top, so we force it here
      setTimeout(() => window.scrollTo(0, 0), 300); // Scroll again after the browser toolbars are finished animating
    }

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ])
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
      this.isXSmall = !this.isSmall && this.breakpointObserver.isMatched(Breakpoints.XSmall);

      // On small devices show only the basic columns, store the previous columns to restore them later
      if (this.isXSmall) {
        const columns = ['avatar-checkbox', 'name', 'modified', 'more'];
        if (JSON.stringify(this.displayedColumns) !== JSON.stringify(columns)) {
          this.storageService.setItem('users-displayed-columns', this.displayedColumns);
        }
        this.displayedColumns = columns;
      } else {
        const columns = this.storageService.getItem('users-displayed-columns') as Array<string>;
        if (columns && columns.length > 1) {
          this.displayedColumns = this.columns.filter(c => c.mayNotBeHidden || columns.some(v => c.value === v)).map(c => c.value);
        }
      }
    });

    this.headerService.activeHeader$.next(this);
    this.headerService.toggleSearch.emit(false); // Close search

    // Set activeDetailID from router userID parameter
    this.router.events
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (data instanceof NavigationStart) {
        this.navigationStart = data;
      }
      if (data instanceof NavigationEnd) {

        const firstChild = this.route.snapshot.parent.children[0];
        const secondChild = this.route.snapshot.root.children[1]; // Use snapshot root because of path: '**' in app-routing
        const newDetailID = secondChild && secondChild.outlet === 'detail' ? secondChild.params['userID'] : '';

        if (firstChild && firstChild.outlet === 'primary') {
          if (firstChild.url.length && firstChild.url[0].path === 'search') { // Search was changed
            const query = firstChild.queryParams['query'];
            if (query && query !== this.searchQuery$.value && // If search query has changed and
              (!this.navigationStart || this.navigationStart.navigationTrigger === 'popstate')) { // first or history navigation
              if (this.subscription) {
                this.subscription.unsubscribe();
              }
              this.searchQuery$.next(query);
              this.headerService.toggleSearch.emit(query);
            }
          } else if (this.searchQuery$.value && this.activeDetailID === newDetailID) { // Navigated back from search
            this.searchQuery$.next(null); // Don't trigger a 300ms search query, we do it manually below
            this.headerService.toggleSearch.emit(false);
            this.refreshed.emit('refreshed');
          } else if (this.searchQuery$.value === null && this.activeDetailID === newDetailID) { // Search was closed
            this.refreshed.emit('refreshed');
          }
        }

        // Set activeDetailID from user/:userID parameter
        if (this.activeDetailID !== newDetailID) { // Detail was changed
          this.activeDetailID = newDetailID;
          this.refreshIndex();
        }
      }
    });

    this.scrollDispatcher.scrolled()  // If the user has scrolled within 200px of the bottom
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((scrollable: CdkScrollable) => {
      const bottom = scrollable ? scrollable.measureScrollOffset('bottom') : null;
      this.ngZone.run(() => {   // Fix https://github.com/angular/components/pull/8545
        this.scrolled(bottom);  // Run this in `NgZone.run`, or the table will not renderRows
      });
    });

    // Set the fetching status before the 300ms delay, so that the empty-status doesn't show
    this.searchQuery$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((v: string) => this.fetching = true);

    this.sidenavDetailService.opened$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.scrolled(null);
    });

    this.viewportRuler.change() // If screen size has changed
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.scrolled(null);
    });
  }

  ngAfterViewInit(): void {
    // Find contextMenuTrigger user for right-click
    this.contextMenuTrigger = this.menuTriggers.find(trigger => trigger.menuData['id'] === 'contextMenuTrigger');

    // Search query changed
    this.searchQuery$.pipe(
      skipWhile(v => v === null), // Skip null value
      debounceTime(500),
      takeUntil(this.ngDestroy$)
    ).subscribe((searchString) => {
      this.cursor = 0
      this.dataSource = []
      let search = this.searchQuery$.value || '';
      search = search.trim();

      let filter = {}
      if (search){
        if (environment.name == 'dev'){
          filter = userSearchFilter("email:"+search) 
        } else {
          filter = userSearchFilter("query:"+search) 
        }
      }

      const fields = { avatarIconURL: 1, email: 1, name: 1, surname:1, organization:1};

      // Get data from the server
      this.subscription = this.usersService.findMany(filter, null, this.cursor, this.pageSize,fields)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          const pagination = response.pagination;
          this.lastCountItemReceived = response.data.length
          let data: Array<User>;

          this.fetching = false;

          data = response.data || [];
          this.usersService.data$.next(data);

          // Set search query in address bar
          if (search) {
            this.router.navigate(
              [{ outlets: { primary: ['users', 'search'] }}],
              { queryParams: { query: search }, queryParamsHandling: 'merge' }
            );
          }
        },
        error => {
          this.fetching = false;
          this.usersService.data$.next([]);
          this.usersService.totalItems$.next(0);
          this.dialogService.showDialog('USERS.GETTING_FAILED', error.status, error.url, error.error);
        }
      );
    })

    // Sorting changed
    this.sort.sortChange 
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((value) => {
      this.cursor = 0
      this.dataSource = []
      let sort = {}; // To preserve the order of the sort fields we use Object.assign
      if (this.sort.active && this.sort.direction !== '') {
        sort = Object.assign(sort, { [this.sort.active]: this.sort.direction === 'desc' ? -1 : 1 }); // Sort by selected column
      }

      const fields = { avatarIconURL: 1, email: 1, name: 1, surname:1, organization:1};
      this.subscription = this.usersService.findMany({}, sort, this.cursor, this.pageSize,fields)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          const pagination = response.pagination;
          this.lastCountItemReceived = response.data.length
          this.cursor = pagination.cursor
          let data: Array<User>;

          this.fetching = false;

          data = response.data || [];
          this.usersService.data$.next(data);
          this.usersService.totalItems$.next(pagination.totalItems);
        },
        error => {
          this.fetching = false;
          this.usersService.data$.next([]);
          this.usersService.totalItems$.next(0);
          this.dialogService.showDialog('USERS.GETTING_FAILED', error.status, error.url, error.error);
        }
      );
      
    });


    merge(
      this.usersService.refreshed,  // External refresh called
      this.nextPage.
      pipe(
        debounceTime(150)            // Skip input values between 10 for Type-ahead
      )                            // Load next page
    )
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(value => {
      // When value = null it means the searchQuery$ was set to pristine
      // and because of the debounceTime(300) it executed with a 300ms delay
      if (value === null) {
        return;
      }

      if (this.subscription) {
        this.subscription.unsubscribe(); // Cancel previous request
      }

      let search = this.searchQuery$.value || '';
      search = search.trim();

      let filter = {}
      if (search){
        if (environment.name == 'dev'){
          filter = userSearchFilter("email:"+search) 
        } else {
          filter = userSearchFilter("query:"+search) 
        }
      }
      
      const fields = { avatarIconURL: 1, email: 1, name: 1, surname:1, organization:1};

      let sort = {}; // To preserve the order of the sort fields we use Object.assign
      if (this.sort.active && this.sort.direction !== '') {
        sort = Object.assign(sort, { [this.sort.active]: this.sort.direction === 'desc' ? -1 : 1 }); // Sort by selected column
      }

      // If sorted or (not next page or first navigation)
      if (value.hasOwnProperty('direction') || (value !== 'nextPage' && this.navigationStart)) {
        this.fetching = true;
        this.lastIndex = -1; // Reset lastIndex
        this.lastSelected = 0; // Reset lastSelected
        this.toggleSelectAll(false);
        this.scrollIntoView(0);
      }

      // Get data from the server
      this.subscription = this.usersService.findMany(filter, sort, this.cursor, this.pageSize,fields)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          const pagination = response.pagination;
          this.lastCountItemReceived = response.data.length
          this.cursor = pagination.cursor
          let data: Array<User>;

          this.fetching = false;

          if (value === 'nextPage') {
            data = this.usersService.data$.value;
            data.splice(
              pagination.pageIndex * pagination.pageSize,
              pagination.pageSize,
              ...response.data || []
            );
          } else {
            
            this.usersService.totalItems$.next(0);
          }

          data = response.data || [];
          this.usersService.data$.next(data);
          this.usersService.totalItems$.next(pagination.totalItems);

          // Set search query in address bar
          if (search) {
            this.router.navigate(
              [{ outlets: { primary: ['users', 'search'] }}],
              { queryParams: { query: search }, queryParamsHandling: 'merge' }
            );
          }
        },
        error => {
          this.fetching = false;
          this.usersService.data$.next([]);
          this.usersService.totalItems$.next(0);
          this.dialogService.showDialog('USERS.GETTING_FAILED', error.status, error.url, error.error);
        }
      );
    });

    this.usersService.data$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      Promise.resolve().then(() => {
        this.dataSource = data.length ? [...this.dataSource, ...data] : this.dataSource;
        this.table.renderRows();
        this.countSelectedRows();

        // After sorting try to find index from activeDetailID
        if (this.lastIndex === -1) {
          this.refreshIndex();
        }

        // Stop auto loading after 100 items
        if (data.length < 100) {
          setTimeout(() => {
            // While not scrolled continue loading more pages
            this.scrolled(null);
          }, 1);
        }
      });
    });

    this.usersService.totalItems$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(totalItems => {
      Promise.resolve().then(() => {
        this.totalItems = totalItems;
      });
    });

    // Load list for the first time
    this.refreshed.emit('refreshed');
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
    // Clear the data loaded in the service
    this.usersService.data$.next([]);
    this.usersService.totalItems$.next(0);
  }

  @HostBinding('attr.tabindex') get tabindex(): string { return '0'; }

  /** Cancel selection */
  cancel(): void {
    this.lastIndex = -1; // Reset lastIndex
    this.lastSelected = 0; // Reset lastSelected
    this.toggleSelectAll(false);
  }

  /** Updates the selectedCount variable to be used more efficiently and returns the count. */
  countSelectedRows(): number {
    this.selectedCount = this.dataSource && this.dataSource.length ?
      this.dataSource.filter(item => item.selected).length : 0;

    return this.selectedCount;
  }

  createUser = (): void => {
    this.router.navigate(['user', 'create'], { queryParamsHandling: 'merge' });
  };

  delete(userID?: string): void {
    if (this.selectedCount > 1) {
      this.dialogService.showDialog('USERS.DELETE', null, 'USERS.DELETE_MULTIPLE_NOT_ALLOWED');
    } else {

      this.translate.get([
        this.selectedCount > 1 ? 'USERS.DELETE' : 'USER.DELETE',
        'ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'
      ]).subscribe(translations => {

        const dialogRef = this.dialogService.showDialog(null, null,
          this.selectedCount > 1 ? translations['USERS.DELETE'] : translations['USER.DELETE'],
          translations['ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'], true) as Observable<boolean>;

        dialogRef.subscribe(confirm => {
          if (confirm) {
            const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];

            this.fetching = true;
            this.usersService.deleteOne(userID || String(selected[0]['_id'])).subscribe(
              () => {
                if (this.activeDetailID === userID || selected.length > 1) {
                  this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' });
                }
                this.refreshed.emit('refreshed');
              },
              error => {
                this.fetching = false;
                this.dialogService.showDialog('USERS.DELETE_FAILED', error.status, error.url, error.error);
              });
          }
        });
      });
    }
  }

  /** Whether some, but not all elements are selected. */
  get isAnySelected(): boolean {
    return this.selectedCount > 0 && this.selectedCount !== this.dataSource.length;
  }

  /** Whether the number of selected elements matches the loaded items. */
  get isLoadedSelected(): boolean {
    return this.selectedCount > 0 && this.selectedCount >= this.dataSource.length;
  }

  /** Whether the number of selected elements matches the total items. */
  get isTotalSelected(): boolean {
    return this.selectedCount > 0 && this.selectedCount >= this.totalItems;
  }

  get showFAB(): boolean {
    // Hide the fab button if some rows are selected
    // Show the fab button, but only if sidenavDetail is closed
    return !(this.sidenavDetailService.opened$.value || this.selectedCount);
  }

  getAddressURL(address: string): string {
    return this.usersService.getAddressURL(address);
  }

  onContextMenu(event: MouseEvent, user: User): void {
    const target = event.target as HTMLElement;
    if (user && !target.closest('a, span:not(.mat-button-wrapper)')) { // anchors and spans should trigger default browser menu
      event.preventDefault();
      this.contextMenuPosition.x = `${event.clientX}px`;
      this.contextMenuPosition.y = `${event.clientY}px`;
      this.contextMenuTrigger.menuData['user'] = user;
      this.contextMenuTrigger.openMenu();
    }
  }

  onDblClick(event: MouseEvent, currentItem: any, index: number): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const targetCheck = target.className.indexOf('mat-checkbox-layout') > -1 || // expanded area 48px
        target.className.indexOf('mat-checkbox-inner-container') > -1; // checkbox icon 24px

    if (!targetCheck) { // Ignore dblClick on checkbox
      if (!this.activeDetailID) { // Open detail
        this.showDetails(targetCheck, currentItem);
      } else if (this.activeDetailID) { // Close detail
        this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' });
      }
    }
    window.getSelection().removeAllRanges(); // Clear text selection
  }

  @HostListener('document:keydown', ['$event', '$event.target'])
  onKeyDown(event: KeyboardEvent, target: HTMLElement): void {
    /* eslint-disable complexity */

    // Because we bind to the document we need to allow the event to trigger
    // only in special conditions, to not break the key events of other controls
    // like inputs or buttons
    if (!target || // Target not defined
        this.isMobile || // On mobile don't trigger keydown events
        !(
          ['BODY', 'MAT-SIDENAV'].includes(target.nodeName) || // Body or sidenav
          target.closest('.users') || // Users container
          target.closest('.mat-nav-list a') // nav-list anchors
        )) {
      return;
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault(); // Prevent auto-scrolling
      if (this.lastIndex < this.dataSource.length - 1) {
        this.lastSelected = this.lastIndex++;
        this.scrollIntoView(this.lastIndex);
        this.showDetails(true, this.dataSource[this.lastIndex]);
      }
    } else if (event.key === 'ArrowUp') {
      if (this.lastIndex > 0) {
        this.lastSelected = this.lastIndex--;
        this.scrollIntoView(this.lastIndex);
        this.showDetails(true, this.dataSource[this.lastIndex]);
      }
    }

    if (event.ctrlKey || event.metaKey) { // Ctrl (Windows) or Command (Mac) + A
      if (event.key === 'a') {
        event.preventDefault(); // Prevent text selection
        this.toggleSelectAll(true); // Select all
      }
    } else {
      switch (event.key) {
        case ' ': // Space
          event.preventDefault(); // Prevent page scroll
          if (this.lastIndex < 0) {
            this.lastIndex = this.lastSelected = 0;
          }
          this.dataSource[this.lastIndex].selected = !this.dataSource[this.lastIndex].selected; // Toggle select current
          this.countSelectedRows();
          this.showDetails(true, this.dataSource[this.lastIndex]);
          break;
        case 'a':
            const item = this.matRows.toArray()[this.lastIndex];
            if (item != null) {
              item.element.nativeElement.getElementsByClassName('more-button')[0].click();
            }
            break;
        case 'Enter':
        case 'i':
        case 'o':
          this.toggleInfo();
          break;
        case 'Escape':
          this.lastIndex = -1; // Reset lastIndex
          this.lastSelected = 0; // Reset lastSelected
          this.toggleSelectAll(false); // Deselect all
          break;
        default:
      }
    }
      /* eslint-enable complexity */
  }

  onMouseDown(event: MouseEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    const targetCheck = target.className.indexOf('mat-checkbox-layout') > -1 || // expanded area 48px
        target.className.indexOf('mat-checkbox-inner-container') > -1; // checkbox icon 24px

    if (event.shiftKey) {
      if (this.lastIndex !== index) {
        event.preventDefault(); // Disable text selection
        // Select all between lastSelected and current
        for (let i = Math.min(this.lastIndex, index); i <= Math.max(this.lastIndex, index); i++) {
          if (!targetCheck || i !== index) {
            this.dataSource[i].selected = index > this.lastSelected ? i >= this.lastSelected : i <= this.lastSelected;
          }
        }
      }
    } else if (event.ctrlKey || event.metaKey || targetCheck) { // Ctrl (Windows) or Command (Mac) or Checkbox
      if (!targetCheck) {
        this.dataSource[index].selected = !this.dataSource[index].selected; // Toggle select current
      }
      this.lastSelected = index;
    } else {
      // Deselect all
      if (!currentItem.selected) {
        this.dataSource.forEach(item => item.selected = false);
        this.lastSelected = index;
      }
    }
    this.lastIndex = index;

    if (!targetCheck) {
      this.countSelectedRows();
      this.showDetails(true, currentItem);
    }
  }

  onMouseUp(event: MouseEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    const targetCheck = target.className.indexOf('mat-checkbox-layout') > -1 || // expanded area 48px
        target.className.indexOf('mat-checkbox-inner-container') > -1; // checkbox icon 24px

    if (targetCheck) { // Allow checkbox models to sync
      setTimeout(() => {
        this.countSelectedRows();
        this.showDetails(true, currentItem);
      }, 1);
    }
  }

  onPress(event: PointerEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    if (!target.closest('a, button')) {
      this.dataSource[index].selected = !this.dataSource[index].selected; // Toggle select current

      this.countSelectedRows();
      this.showDetails(true, currentItem);
    }
  }

  onTap(event: PointerEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    if (!target.closest('a, button')) {
      if (this.selectedCount > 0) { // Select mode
        this.onPress(event, currentItem, index); // Toggle select current
      } else {
        this.showDetails(false, currentItem);
      }
    }
  }

  refreshIndex(): void {
    if (this.dataSource && this.activeDetailID) {
      this.lastIndex = this.dataSource.findIndex(user => user._id === this.activeDetailID);
      if (this.lastIndex !== -1) {
        this.scrollIntoView(this.lastIndex);
      }
    } else {
      this.lastIndex = -1;
    }
  }

  scrolled(bottom?: number): void {
    if (!this.dataSource) { return; }

    const D = document;
    if (this.isMobile || this.isXSmall) { // If is-mobile the sidenav-content was scrolled
      bottom = Math.max(D.body.scrollHeight, D.documentElement.scrollHeight,
                        D.body.clientHeight, D.documentElement.clientHeight) -  // Scroll Height
        this.viewportRuler.getViewportScrollPosition().top -                    // Scroll Top
        this.viewportRuler.getViewportSize().height;                            // Viewport Height
    } else if (bottom === null) { // If non-mobile the users was scrolled
      const scrollable = D.getElementById('users'); // This MUST have css 'overflow-y: auto' set to work
      bottom = scrollable ? scrollable.scrollHeight - scrollable.scrollTop - scrollable.clientHeight : null;
    }

    // if (bottom !== null && bottom <= 50) { // Use 50, not 0 to be sure it always triggers the loading of a new page
    //   if (this.dataSource.length < this.totalItems && // Check if not all items are loaded
    //     this.dataSource.length >= (this.pageIndex + 1) * this.pageSize) { // Wait if last page finished loading
    //     this.pageIndex += 1;
    //     this.nextPage.emit('nextPage');
    //   }
    // }


    if (bottom !== null && bottom <= 50) { // Use 50, not 0 to be sure it always triggers the loading of a new page
      if (this.lastCountItemReceived == this.pageSize) { // Wait if last page finished loading
        
        this.nextPage.emit('nextPage');
      }
    }
  }

  scrollIntoView(index: number): void {
    if (this.matRows) {
      const item = this.matRows.toArray()[index];
      if (item != null) {
        const rect = item.element.nativeElement.getBoundingClientRect();
        if ((rect.y <= 120) || ((rect.y + rect.height) > window.innerHeight)) {
          item.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
        }
      }
    }
  }

  showDetails(onlyIfOpened: boolean, currentItem: any): void {
    // If click on checkbox and detail not opened then exit
    if (onlyIfOpened && !this.sidenavDetailService.opened$.value) {
      return;
    }

    if (this.dataSource && this.dataSource.length) {
      // Checked item has priority over currentItem
      if (this.selectedCount === 1) {
        currentItem = this.dataSource.find(item => item.selected);
      }

      // Find currentItem from activeDetailID
      if (!currentItem) {
        currentItem = this.dataSource.find(file => file._id === this.activeDetailID);
      }

      // Show first item if nothing is selected
      if (!currentItem) {
        currentItem = this.dataSource[0];
      }
    }

    this.router.navigate(
      ['user', currentItem._id],
      { queryParamsHandling: 'merge' }
    );
  }

  async editFolderTemplate(user: any) {
    await this.router.navigate(['files-template'], {queryParams: {clientId: user._id}});
  }

  toggleColumn(column: string): void {
    let columns = this.displayedColumns;
    const index = columns.indexOf(column);
    if (index > -1) { // Remove displayed column
      columns.splice(index, 1);
    } else {  // Add displayed column and also maintain position
      columns = this.columns.filter(c => columns.includes(c.value) || c.value === column).map(c => c.value);
    }
    this.displayedColumns = columns;
    this.storageService.setItem('users-displayed-columns', this.displayedColumns);
  }

  toggleInfo(): void {
    if (this.sidenavDetailService.opened$.value) {
      this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' });
    } else {
      this.showDetails(false, this.dataSource[this.lastIndex]);
    }
  }

  toggleSelect(user: User): void {
    user.selected = !user.selected;
    this.countSelectedRows();
  }

  // Toggle select all, or force selected value on all
  toggleSelectAll(selected?: boolean): void {
    if (this.dataSource) {
      const isAllSelected = this.selectedCount === this.dataSource.length;
      selected = (selected === false || selected === true) ? selected : !isAllSelected;
      this.dataSource.forEach(item => {
        item.selected = selected;
      });
      this.selectedCount = selected ? this.dataSource.length : 0;
    }
  }

  trackByIndex = i => i; // https://angular.io/guide/template-syntax#ngfor-with-trackby
}
