import { LineString, MultiPoint, Polygon } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

// Style Constants
export const DEFAULT_STROKE_WIDTH = 2.5;
export const HIGHLIGHT_STROKE_WIDTH = 3.5;

export const DEFAULT_ACTIVE_BLUE_FILL_COLOR = 'rgba(41, 121, 255, 0.1)';
export const HIGHLIGHT_ACTIVE_BLUE_FILL_COLOR = 'rgba(41, 121, 255, 0.3)';
export const ACTIVE_BLUE_STROKE_COLOR = 'rgb(41, 121, 255)';

export const DEFAULT_LIGHT_BLUE_FILL_COLOR = 'rgba(84, 219, 253, 0.1)';
export const HIGHLIGHT_LIGHT_BLUE_FILL_COLOR = 'rgba(84, 219, 253, 0.3)';
export const LIGHT_BLUE_STROKE_COLOR = 'rgb(84, 219, 253)';


// Vertex style with MultiPoint for vertex circles
export const VERTEX_STYLE = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.7)'
    }),
    stroke: new Stroke({
      color: 'rgb(41, 121, 255)',
      width: 1
    })
  }),
  geometry: function (feature) {
    // Use type checking to handle different geometry types
    const geometry = feature.getGeometry();
    
    if (geometry instanceof Polygon) {
      // Get the first ring of the polygon (outer ring)
      const coordinates = geometry.getCoordinates()[0];
      return new MultiPoint(coordinates);
    } else if (geometry instanceof LineString) {
      return new MultiPoint(geometry.getCoordinates());
    }

    return null;
  }
});

// Styles for Active Blue Features
export const DEFAULT_ACTIVE_BLUE_STYLE = new Style({
  fill: new Fill({
    color: DEFAULT_ACTIVE_BLUE_FILL_COLOR
  }),
  stroke: new Stroke({
    color: ACTIVE_BLUE_STROKE_COLOR,
    width: DEFAULT_STROKE_WIDTH
  })
});

export const HIGHLIGHT_ACTIVE_BLUE_STYLE = new Style({
  fill: new Fill({
    color: HIGHLIGHT_ACTIVE_BLUE_FILL_COLOR
  }),
  stroke: new Stroke({
    color: ACTIVE_BLUE_STROKE_COLOR,
    width: HIGHLIGHT_STROKE_WIDTH,
    lineDash: [4, 8] // Adjust these numbers for dot/dash length and spacing

  })
});

// Styles for Light Blue Features
export const DEFAULT_LIGHT_BLUE_STYLE = new Style({
  fill: new Fill({
    color: DEFAULT_LIGHT_BLUE_FILL_COLOR
  }),
  stroke: new Stroke({
    color: LIGHT_BLUE_STROKE_COLOR,
    width: DEFAULT_STROKE_WIDTH
  })
});

export const HIGHLIGHT_LIGHT_BLUE_STYLE = new Style({
  fill: new Fill({
    color: HIGHLIGHT_LIGHT_BLUE_FILL_COLOR
  }),
  stroke: new Stroke({
    color: LIGHT_BLUE_STROKE_COLOR,
    width: HIGHLIGHT_STROKE_WIDTH,
    lineDash: [4, 8] // Adjust these numbers for dot/dash length and spacing

  })
});