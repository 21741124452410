import { BehaviorSubject, Observable } from "rxjs";
import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "./../../../environments/environment";
import { FilesService } from "./../../pages/files/files.service";
import { Login } from "../../pages/login/login.model";
import { QueueItem } from "./../../shared/upload/upload.model";
import { SseData } from "./sse.model";
import { UploadService } from "./../upload/upload.service";
import { DialogService } from "../dialog/dialog.service";

@Injectable({
  providedIn: "root",
})
export class SseServiceService {
  data$: BehaviorSubject<SseData> = new BehaviorSubject(null);
  queueItems?: Array<QueueItem> = [];

  constructor(
    private _zone: NgZone,
    private filesService: FilesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private uploadService: UploadService,
  ) {}

  connectSSE(login: Login): void {
    this.data$.next({"pending":[],"failed":[],"running":[],"success":[]});
    this.getServerSentEvent(
      environment.apiHost + "events?jwt=" + login.token
    ).subscribe((data: any) => {
      const tmpDataArray = [];
      const sseDataObjects = JSON.parse(data.data);
      for (const k in sseDataObjects) {
        tmpDataArray.push(sseDataObjects[k]);
      }

      const sseSorted = this.sortSseData({
        pending: tmpDataArray.filter((s) => s.status === "pending"),
        running: tmpDataArray.filter((s) => s.status === "running"),
        success: tmpDataArray.filter((s) => s.status === "success"),
        failed: tmpDataArray.filter((s) => s.status === "failure"),
      } as SseData);
      console.log('Queue: ',sseSorted)
      if (this.data$.value) {
        console.log("this.data$.value: ",this.data$.value)
        if (
          sseSorted.pending.length !== this.data$.value.pending.length ||
          sseSorted.running.length !== this.data$.value.running.length ||
          sseSorted.success.length !== this.data$.value.success.length ||
          sseSorted.failed.length !== this.data$.value.failed.length
        ) {
          console.log("inside condition")
          this.data$.next(sseSorted);
          // Add to queue
          this.data$.value.pending.forEach((message) => {
            console.log("------------- Process Pending")
            if (
              this.queueItems.filter((item) => {
                return item.key === message.requestId;
              }).length === 0
            ) {
              let icon = 'question_mark';
              switch (message.name) {
                case 'convert':
                  icon = 'photo_library'
                  break;
                case 'watermark':
                  icon = 'branding_watermark'
                  break;
                case 'zip':
                  icon = 'archive'
                  break;
                case 'unzip':
                  icon = 'archive'
                  break;
                case 'generate-pdf':
                  icon = 'picture_as_pdf'
                  break;
                case 'mail':
                  icon = 'mail'
                  break;
              }
              const queueItem = new QueueItem(
                message.requestId,
                message.target.name,
                icon
              );

              if (message.name !== 'mail') {
                //queueItem.startProgress();
                this.queueItems.push(queueItem);
                this.uploadService.queueItems$.next(this.queueItems);
              }
              
            }
          });
          // Starting queue item
          this.data$.value.running.forEach((message) => {
            console.log("------------- Process Running")
            const changedItem = this.queueItems.filter((item) => {
              return item.key === message.requestId;
            })
            if (
              changedItem.length === 1
            ) {
              changedItem[0].label = message.target.name;
              changedItem[0].startProgress();
            }
          });

          // Remove from queue
          this.data$.value.success.forEach((message) => {
            console.log("------------- Process Success")
            const mIndex = this.queueItems.findIndex((item) => {
              return item.key === message.requestId;
            });
            if (mIndex !== -1) {
              this.queueItems.splice(mIndex, 1);
              this.uploadService.queueItems$.next(this.queueItems);
              this.translate.get("INFO").subscribe((translation) => {
                const snackBarRef = this.snackBar.open(
                  `${message.target.name}`,
                  message.name === "generate-pdf"? 'Open in Folder':translation,
                  { duration: 30000 }
                );
                if (message.name === "generate-pdf") {
                  this.filesService.findOne(message.target.objectId.toString())
                  .subscribe( (response) => {
                    if (response){
                      snackBarRef.onAction().subscribe(() => {
                        this.router.navigate(
                          ['',
                            {
                              outlets: {primary: ['files', response.data.folderID ]},
                            },
                          ],
                        );
                        snackBarRef.dismiss();
                      });
                    }
                  })
                } else {
                  snackBarRef.onAction().subscribe(() => {
                    this.router.navigate(
                      [
                        {
                          outlets: { detail: ["file", message.target.objectId] },
                        },
                      ],
                      { queryParamsHandling: "merge" }
                    );
                    snackBarRef.dismiss();
                  });
                }  
              });
              this.filesService.refreshed.emit("refreshed");
            }
          });
        } else if (sseSorted.running?.length === 1 && sseSorted.running[0]?.name === "generate-pdf") { // To update progress percentage for PDF
            this.data$.value.running.forEach(message => {
            // Find the item in the queue that matches the requestId
            let itemToUpdate = this.queueItems.find(item => item.key === message.requestId);
            // If item found, update its progressPercentage
            if (itemToUpdate) {
                itemToUpdate.progressPercentage = sseSorted.running[0].progress * 100;
                // If the progress is less than 100%, update the queue items Observable
                if (sseSorted.running[0].progress < 1) {
                    this.uploadService.queueItems$.next(this.queueItems);
                }
            }
          });
      }
      } else {
        console.log(" Updating THIS.DATA$")
        this.data$.next(sseSorted);
      }
    });
  }

  sortSseData(input: SseData): SseData {
    return {
      pending: input.pending.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : 1
      ),
      running: input.running.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : 1
      ),
      success: input.success.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : 1
      ),
      failed: input.failed.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1)),
    };
  }

  getServerSentEvent(url: string) {
    return new Observable((observer) => {
      const eventSource = this.getEventSource(url);

      eventSource.onmessage = (event) => {
        this._zone.run(() => {
          observer.next(event);
        });
      };

      eventSource.onerror = (error) => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
    });
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url);
  }
}
