<h1 mat-dialog-title>{{data.folder.name}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Folder name</mat-label>
    <input matInput [(ngModel)]="newName">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button (click)="ok()" [disabled]="!newName">OK</button>
</div>
