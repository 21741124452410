export const environment = {
  name: 'prod',
  production: true,

  host: 'https://fairfleet.com/',
  apiHost: 'https://api.fairfleet.com/api/', // For direct back-end api requests that take longer than 60s, uses CORS
  apiPath: 'http://ff3-be/', // on Server => https://fairfleet.com/api/ on Browser => CloudFront timeout 60s, has same-origin
  ff2URL: 'https://fairfleet360.com', // To redirect from /home component
  webSocketURL: 'wss://api.fairfleet.com/ws/thermal',


  bingMapsAPIKey: 'AhbgE340H2VTI3k_GzoBHeVIDSKk-gVIecS0RnLuAzl0QiZz4k49RY9HkFwPPrlv',
  cesiumToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4ODVkNjgzYy03MThkLTRhZjEtYWY2MS0zNDFmY2FiYmIwNjgiLCJpZCI6MTU1MjgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NjgxMzM3MTB9.BWkTsbxrwVh1doY63vbtPIOdjR22zyxmJeD7JyalKo8',
  googleMapsAPIKey: 'AIzaSyBkyGiPqqEKI8gCYHOvXmmAgRVe273-leU',

  mailgunPublicKey: 'pubkey-c2eff2acb9578220d1603a631b3ebf70',
  recaptchaKey: '6Lc_UjwUAAAAAFX8V6ADoAxIrfmKWV2NdZNDHi0j',

  storage: 'fairfleet',
  title: 'FairFleet'
};
