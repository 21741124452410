import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PvModulesService {

  constructor( private http: HttpClient ) { }

  public getPvModules(filter: object): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    return this.http.get(`${environment.apiPath}pv-modules`, { params });
  }

  public getPvModuleById(moduleID: string): Observable<any> {
    return this.http.get(`${environment.apiPath}pv-modules/${moduleID}`);
  }

  public createPvModule(pvModules: any): Observable<any> {
    return this.http.post(`${environment.apiPath}pv-modules`, pvModules);
  }

  public createPvModules(pvModules: object[]): Observable<any> {
    return this.http.post(`${environment.apiPath}pv-modules/bulk`, pvModules);
  }

  public updatePvModule(moduleID: string, body: any): Observable<any> {
    return this.http.put(`${environment.apiPath}pv-modules/${moduleID}`, body);
  }

  public updatePvModules(ids: string[], updates: { [key: string]: any }): Observable<any> {
    return this.http.patch(`${environment.apiPath}pv-modules/bulk`, {ids, updates});
  }

  public deletePvModule(moduleID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}pv-modules/${moduleID}`);
  }

  public getPVModuleInspections(filter: object): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    return this.http.get(`${environment.apiPath}pv-module-inspections`, { params });
  }

  public insertPVModuleInspections(PVModuleInspections: object): Observable<any> {
    return this.http.post(`${environment.apiPath}pv-module-inspections`, PVModuleInspections);
  }

  // =================== Business Logic ==================
  public getOnePVModuleInspectionByOrderId(orderID: string, moduleIDs: string[]): Observable<any> {
    const filter = {
      orderIDs: [orderID],
      moduleIDs: moduleIDs
    };
    return this.getPVModuleInspections(filter);
  }

}
