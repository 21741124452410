import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * Class for managing stylesheets. Stylesheets are loaded into named slots so that they can be
 * removed or changed later.
 */
@Injectable()
export class ThemeService {
  changed$: BehaviorSubject<string> = new BehaviorSubject('');
  public isDarkMode: boolean;

  createLinkElementWithKey(key: string): Element {
    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(this.getClassNameForKey(key));
    document.head.appendChild(linkEl);

    return linkEl;
  }

  getClassNameForKey(key: string): string {
    return `style-${key}`;
  }

  getExistingLinkElementByKey(key: string): Element {
    return document.head.querySelector(`link[rel="stylesheet"].${this.getClassNameForKey(key)}`);
  }

  getLinkElementForKey(key: string): Element {
    return this.getExistingLinkElementByKey(key) || this.createLinkElementWithKey(key);
  }

  /**
   * Remove the stylesheet with the specified key.
   */
  removeStyle(key: string): void {
    const existingLinkElement = this.getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
    this.isDarkMode = false;
    this.changed$.next('');
  }

  /**
   * Set the stylesheet with the specified key.
   */
  setStyle(key: string, href: string): void {
    this.isDarkMode = href.startsWith('dark');
    this.getLinkElementForKey(key).setAttribute('href', href);
    this.changed$.next(href);
  }
}
