import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { ApiService } from './../../shared/interfaces/api-service.interface';
import { environment } from './../../../environments/environment';
import { Folder, Update } from './folder.model';
import { isRootFolderEditable } from './../../config';
import { Login } from './../../pages/login/login.model';
import { LoginStateService } from './../../pages/login/login-state.service';
import { Permission, PermissionRole } from './../../pages/files/file/permission.model';
import { AccountType, UserRole } from '../../pages/users/models/user.model';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class FoldersService implements ApiService<Folder> {
  _myFiles = {
    _id: 'my-files',
    childrenCount: 0,
    level: 0,
    name: 'FILES.MY_FILES'
  };

  activeFolder$: BehaviorSubject<Folder> = new BehaviorSubject(null);
  activeFolderID$: BehaviorSubject<string> = new BehaviorSubject(null);
  data$: BehaviorSubject<Array<Folder>> = new BehaviorSubject([]);
  login: Login;
  refreshed: EventEmitter<any> = new EventEmitter();
  sortedData: Sort = { active: 'name', direction: 'asc' };
  treeControl = new FlatTreeControl<Folder>(folder => folder.level, folder => folder.childrenCount > 0);

  constructor(
      private http: HttpClient,
      private loginStateService: LoginStateService,
      private route: ActivatedRoute,
      private router: Router,
      private permissionsService: PermissionsService
  ) {
    this.loginStateService.login$
    .subscribe(login => {
      if (login) {
        Promise.resolve(undefined).then(() => {
          this.login = login;
        });
      }
    });

    // Set activeFolderID from router folderID parameter
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        const firstChild = this.route.snapshot.firstChild;
        const folderID = firstChild.params['folderID'];
        if (firstChild && firstChild.outlet === 'primary' && this.activeFolderID$.value !== folderID) {
          if (firstChild.routeConfig.path.includes('files') || firstChild.routeConfig.path.includes('inspection') || firstChild.routeConfig.path.includes('PDFeditor')) {
            switch (folderID) {
              case 'my-files':
                this.activeFolder$.next(this._myFiles);
                break;
              case 'search':
                if (!this.activeFolderID$.value) { // Initialize the root folder if null
                  this.activeFolder$.next(this._myFiles);
                }
                break;
              default:
                this.findOne(folderID)
                .subscribe(
                  response => {
                    this.activeFolder$.next(response.data);
                  },
                  () => {
                    // TODO
                  }
                );
            }
            this.activeFolderID$.next(folderID);
          } else {
            // Navigated away from Files, reset activeFolder
            this.activeFolder$.next(null);
            this.activeFolderID$.next(null);
          }
        }
      }
    });
  }

  collapseFolder(folder: Folder): void {
    const data = this.data$.value;
    const index = data.indexOf(folder);
    const loaded = this.loadedChildren(folder, false);
    if (loaded) {
      data.splice(index + 1, loaded); // Remove range
      this.data$.next(data);
    }
  }

  get canEditActiveFolder(): boolean {
    const activeFolder = this.activeFolder$.value;

    // Only admins can edit my-files folder (adding files/folders to it)
    if (this.activeFolder$.value?._id === 'my-files' && ![AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType) ) {
      return false
    }

    // If the logged user is ADMIN he can always upload files or create new folders
    return (this.login && this.permissionsService.permissions$.value?.canCreate.files) ||
    // If the root folder is generally editable then the logged user should be at least a CONTRIBUTOR
    (activeFolder && activeFolder.level === 0 && isRootFolderEditable && this.login && this.permissionsService.permissions$.value?.canCreate.files ) ||
    // When inside a folder the logged user should have explicit EDIT permission role
    (activeFolder && this.permissionsService.permissions$.value?.canCreate.files);
  }

  deleteOne(folderID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}files/${folderID}`, { observe: 'response' });
  }

  findMany(
      filter?: object,
      sort?: object,
      pageIndex?: number,
      pageSize?: number
  ): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (sort && Object.keys(sort).length) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (pageIndex) {
      params = params.append('pageIndex', pageIndex.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }
    params = params.append('fields', JSON.stringify({ name: 1, childrenCount: 1 }));

    return this.http.get<any>(`${environment.apiPath}files`, { params });
  }

  findOne(folderID: string): Observable<any> {
    return this.http.get<Folder>(`${environment.apiPath}files/${folderID}`);
  }

  insertMany(folders: Array<Folder>): Observable<any> {
    return this.http.post(`${environment.apiPath}files`, folders, { observe: 'response' });
  }

  isLoadMore = (folder: Folder) => String(folder._id).startsWith('LOAD_MORE_');

  loadedChildren(folder: Folder, firstChildren: boolean): number {
    const data = this.data$.value;
    const index = data.indexOf(folder);
    if (index < 0) {
      return 0;
    }
    let loaded = 0;
    for (let i = index + 1; i < data.length && data[i].level > folder.level; i++) {
        if (firstChildren) {
          if (data[i].level === folder.level + 1) {
            loaded++; // Count first children
          }
        } else {
          loaded++; // Count deep children
        }
    }

    return loaded;
  }

  refresh(): void {
    const filter = {
      isFolder: true,
      modifiedLast : -10 // Modified in last x seconds using the server-time
    };

    // Get data from the server
    this.findMany(filter, { modified: -1 }, 0, 1000)
    .subscribe(
      response => {
        const modifiedData = (response.data || []) as Array<Folder>;
        modifiedData.forEach(folder => {
          this.upsertItem(folder);
        });
      },
      error => {
        // TODO: Show an error notification
      }
    );
  }

  removeItems(folderIDs: Array<string>): void {
    const data = this.data$.value.filter(folder => !folderIDs.includes(String(folder._id)));
    this.data$.next(data);
  }

  setTreeControl(treeControl: FlatTreeControl<Folder>): void {
    this.treeControl = treeControl;
  }

  updateOne(folderUpdates: Folder): Observable<any> {
    const update = new Update();
    update.$currentDate = new Folder();
    update.$currentDate.modified = true;

    Object.keys(folderUpdates).forEach(key => {
      switch (key) {
        case '_id':
          break;
        default:
          if (folderUpdates[key] || folderUpdates[key] === false) {
            update.$set = update.$set || new Folder();
            update.$set[key] = folderUpdates[key];
          } else {
            update.$unset = update.$unset || new Folder();
            update.$unset[key] = '';
          }
      }
    });

    return this.http.patch(`${environment.apiPath}files/${folderUpdates._id}`, update, { observe: 'response' });
  }

  upsertItem(folder: Folder): void {
    const data = this.data$.value;

    const parent = data.find(f => f._id === (folder.folderID ? folder.folderID : 'my-files'));
    if (parent) {

      const found = data.find(f => f._id === folder._id);
      if (found) {

        if (found.folderID === folder.folderID &&
          found.name === folder.name) {
          return; // Nothing to change
        }

        this.collapseFolder(found);

        // Remove old item if it exists
        const idxFound = data.indexOf(found);
        data.splice(idxFound, 1);

        const idxFoundParent = data.findIndex(f => f._id === (found.folderID ? found.folderID : 'my-files'));
        data[idxFoundParent].childrenCount--;
      }

      // Set folder level
      folder.level = parent.level + 1;

      // Find insertion point depending on the folder name or modified date
      const idxParent = data.indexOf(parent);

      let isEOF = true;
      let start = 0;
      for (let i = idxParent + 1; i < data.length && data[i].level > parent.level; i++) {
        if (!this.isLoadMore(data[i])) {
          start = i;
          if (data[i].level === folder.level) {

            if (this.sortedData.active === 'name') { // Sort by name keeping alphanumeric sorting

              if (folder.name.localeCompare(data[i].name, undefined, {
                numeric: true, // Sort strings with numbers, so that avoid a1, a11, a2 will be sorted a1, a2, a11
                sensitivity: 'base'  // Only strings that differ in base letters compare as unequal. Examples: a ≠ b, a = á, a = A.
              }) === (this.sortedData.direction === 'asc' ? -1 : 1)) {
                isEOF = false; // Set insertion point after a name is found
                break;
              }

            } else if (this.sortedData.active === 'modified') { // Sort by modified

              if (this.sortedData.direction === 'desc') { // Descending
                isEOF = false; // Set insertion point at the top
                break;
              }

              // Ascending: iterate till the end is reached, see below
            }
          }
        }
      }

      if (start) { // Add new item
        if (isEOF) {
          start++; // If end is reached then increment insertion start to set it last
        }
        data.splice(start, 0, ...[folder]);
      }
      data[idxParent].childrenCount = parent.childrenCount ? parent.childrenCount + 1 : 1;
    }
    this.data$.next(data);
  }
}
