import { BehaviorSubject, Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiService } from './../../shared/interfaces/api-service.interface';
import { environment } from './../../../environments/environment';
import { Post, Update } from './post/post.model';

@Injectable()
export class PostsService implements ApiService<Post> {

  data$: BehaviorSubject<Array<Post>> = new BehaviorSubject([]);
  refreshed: EventEmitter<any> = new EventEmitter();
  totalItems$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
      private http: HttpClient
  ) {}

  deleteOne(postID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}posts/${postID}`, { observe: 'response' });
  }

  findMany(
      filter?: object,
      sort?: object,
      pageIndex?: number,
      pageSize?: number
  ): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (sort && Object.keys(sort).length) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (pageIndex) {
      params = params.append('pageIndex', pageIndex.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }

    return this.http.get<any>(`${environment.apiPath}posts`, { params });
  }

  findOne(postID: string): Observable<any> {
    return this.http.get<Post>(`${environment.apiPath}posts/${postID}`);
  }

  insertOne(post: Post): Observable<any> {
    return this.http.post(`${environment.apiPath}posts`, post, { observe: 'response' });
  }

  removeItem(postID: string): void {
      const data = this.data$.value.filter(item => item._id !== postID);
      this.data$.next(data);
      this.totalItems$.next(this.totalItems$.value - 1);
  }

  updateItem(post: Post): void {
    const index = this.data$.value.findIndex(item => item._id === post._id);
    if (index > -1) {
      this.data$.value[index] = post;
      this.data$.next(this.data$.value);
    }
  }

  updateOne(postUpdates: Post): Observable<any> {
    const update = new Update();
    update.$currentDate = new Post();
    update.$currentDate.modified = true;

    Object.keys(postUpdates).forEach(key => {
      switch (key) {
        case '_id':
          break;
        default:
          const value = postUpdates[key];
          if ((Array.isArray(value) && value.length) || // Is array with length > 0
              (!Array.isArray(value) && value) ||       // Is not array and truthy
              value === false) {                        // Is boolean and false
            update.$set = update.$set || new Post();
            update.$set[key] = value;
          } else {                                      // Empty arrays, falsy values
            update.$unset = update.$unset || new Post();
            update.$unset[key] = '';
          }
      }
    });

    return this.http.patch(`${environment.apiPath}posts/${postUpdates._id}`, update, { observe: 'response' });
  }

  upsertItem(post: Post): void {
    const index = this.data$.value.findIndex(item => item._id === post._id);
    if (index > -1) {
      this.data$.value[index] = post;
    } else {
      this.data$.value.unshift(post);
      this.totalItems$.next(this.totalItems$.value + 1);
    }
    this.data$.next(this.data$.value);
  }
}
