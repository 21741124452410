<footer class="footer">
  <div class="footer-list notranslate">
    <app-theme></app-theme>

    <mat-menu
        #appMenu="matMenu"
        [overlapTrigger]="false"
        xPosition="after"
        yPosition="above">
      <button
          *ngFor="let lang of availableLanguages"
          (click)="setLanguage(lang)"
          mat-menu-item
          type="button">{{ lang | uppercase }}</button>
    </mat-menu>

    <button
        class="lang-button"
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
        [title]="'CHANGE_LANGUAGE' | translate">
      <mat-icon>translate</mat-icon>
    </button>

    <div class="footer-end">
      {{ 'APP.TITLE' | translate }} © {{ getYear() }}
    </div>
  </div>
</footer>
