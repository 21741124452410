import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {BehaviorSubject, Observable, Subject, forkJoin, of} from 'rxjs';
import {map, mergeMap, takeUntil, tap} from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { StorageService } from './../helpers/storage.service';
import { LegacyClientOrder, LegacyOrder, Order, OrdersModel, LegacyPilotOrder } from './orders.model';
import { userSearchFilter } from '../helpers/data-helpers';
import { UsersService } from 'src/app/pages/users/users.service';
import { AccountType, User } from 'src/app/pages/users/models/user.model';
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { CollaborationsService } from 'src/app/pages/files/file/sidebar/collaborations/collaborations.service';

export type OrderGroup = 'done' | 'inProgress' | 'checking' | 'notStarted' | 'onHold';
export type OrderStatus = 'done' | 'inProgress' | 'onHold' | 'checking' | 'notStarted';

export const orderColors: {[_: string]: [string, string]} = {
  'done': ['#5b8f41', '#38d23c'],
  'inProgress': ['#3589C6', '#3589C6'],
  'checking': ['#1b4964', '#fece2f'],
  'notStarted': ['#666666', '#cccccc'],
  'onHold': ['gray', 'gray'],
}

export const statusGroups: OrderGroup[] = ['notStarted', 'onHold', 'inProgress', 'checking', 'done'];

export interface OrderPage<O extends LegacyOrder> {
  stats: {[_: string]: number};
  count: number;
  orders?: O[];
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService{
  private ngDestroy$ = new Subject();
  ordersSource = {} as OrdersModel;
  activeOrder$: BehaviorSubject<Order> = new BehaviorSubject(null)
  private loginStateService: LoginStateService;
  private collaborationsService: CollaborationsService;
  public orderIds: number[] = []

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private usersService: UsersService,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.loginStateService = this.injector.get(LoginStateService);
      this.collaborationsService = this.injector.get(CollaborationsService);
      this.ordersSource = this.storageService.getItem("orders");
    }, 10);
  }

  getActiveOrder(legacyId: number): Promise<void> {
    return new Promise((resolve, reject) => {
        const filter = { "legacyId": legacyId };
        this.findManyOrders(filter).pipe(takeUntil(this.ngDestroy$)).subscribe(
            (response: any) => {
                if (response && response.count > 0) {
                    this.setActiveOrder(response.orders[0]);
                    resolve();  // Resolve the promise when the order is successfully set
                } else {
                    reject(new Error("No orders found with the specified legacyId."));
                }
            },
            (error) => {
                reject(error);  // Reject the promise if there's an error in subscription
            }
        );
    });
  }

  removeOrders(): void {
    this.ordersSource = {} as OrdersModel;
    this.storageService.removeItem("ordersCache");
  }

  // it enrich the orders with orderGroupe names, users subscription, and users role
  enrichOrders(resp: any): Observable<any> {
    const filter = { accountType: 'client' };
    const fields = { avatarIconURL: 1, email: 1, name: 1, surname: 1, subscription: 1 };
    const accountType = this.loginStateService.loggedUser$.value?.accountType;
    const userId = this.loginStateService.loggedUser$.value?._id;
  
    if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(accountType)) {
      return this.usersService.findMany(filter, null, null, 1, fields).pipe(
        mergeMap(users => {
          const enrichOrders$ = resp.orders.map((order: Order) => {
            const userInfo = users.data.find((user: User) => user._id === order.clientId);
            order['hasSubscription'] = userInfo ? userInfo.subscription?.length > 0 : false;
            order.status = this.getOrderGroup(order.status);
            order.role = 'ADMIN';
            return of(order);
          });
          return forkJoin(enrichOrders$).pipe(map(() => resp));
        })
      );
    } else if (accountType === AccountType.PILOT) {
      const enrichOrders$ = resp.orders.map((order: Order) => {
        order['hasSubscription'] = false;
        order.status = this.getOrderGroup(order.status);
        order.role = userId === order.assignedPilotId ? 'PILOT' : 'SECONDARY_PILOT';
        return of(order);
      });
      return forkJoin(enrichOrders$).pipe(map(() => resp));
    } else if (accountType === AccountType.EDITOR) {
      const enrichOrders$ = resp.orders.map((order: Order) => {
        order['hasSubscription'] = false;
        order.status = this.getOrderGroup(order.status);
        order.role = "EDITOR";
        order.dateSetToVerify = null; // force it to null because a collaborator can not know if the owner of the resource has a subscription or not 
        return of(order);
      });
      return forkJoin(enrichOrders$).pipe(map(() => resp));
    } else if (accountType === AccountType.CLIENT) {
      const enrichOrders$ = resp.orders.map((order: Order) => {
        if (userId === order.clientId) {
          order['hasSubscription'] = this.loginStateService.loggedUser$.value?.subscription?.length > 0;
          order.status = this.getOrderGroup(order.status);
          order.role = 'OWNER';
          return of(order);
        } else {
          const filter = this.collaborationsService.constructFilter('order', order._id, userId.toString(), undefined, 'user');
          return this.collaborationsService.getCollaborations(filter).pipe(
            tap(collaborations => {
              order['hasSubscription'] = false;
              order.status = this.getOrderGroup(order.status);
              order.role = collaborations.data && collaborations.data.length > 0 ? collaborations.data[0].options.role.toUpperCase() : '-';
              order.hasAccessUntil = collaborations.data[0].expiresAt
            }),
            map(() => order)
          );
        }
      });
      return forkJoin(enrichOrders$).pipe(map(() => resp));
    } else {
      return of(resp); // Return the response as is if not matching any account type
    }
  }

  getOrders(ids: Array<number>): void {
    if (ids.length > 0) {
      let ordersStored = this.storageService.getItem("orders");
      if (ordersStored !== null) {
        const missingIds = ids.filter(order => Object.keys(ordersStored).indexOf(String(order)) < 0);
        if (missingIds.length > 0) {
          this.getNewIds(missingIds);
        }
      } else {
        this.getNewIds(ids);
      }
    }
  }
  
  getStatusIcon(status: string): string{
    switch (status) {
      case 'to_verify':
      case 'done':
      case 'invoiced':
      case 'paid':
        return 'check_circle';

      case 'looking_pilots':
      case 'in_progress':
      case 'inProgress':
      case 'fq_looking_pilots':
        return 'pending';

      case 'requested_job':
      case 'lost':
      case 'notStarted':
        return 'note_add';

      case 'on_hold':
      case 'onHold':
        return 'pause_circle';

      case 'footage_review':
      case 'post_production':
      case 'external_processing':
      case 'review_post_production':
      case 'checking':
        return 'autorenew';
  }
 }

  setActiveOrder(order: Order) {
    this.activeOrder$.next(order);
  }

  private getNewIds(ids: Array<number>): void {
    this.http.post<any>(`${environment.apiPath}legacy/orders/address`,  ids.map((item) => { return Number(item) }) )
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
    response => {
      if(response !== null) {
        const orderObj = {} as OrdersModel;
        response.data.forEach(order=> {
          if (order.address || order.projectName) {
            orderObj[order.id] = {};
            if (order.address) {
              orderObj[order.id].address = order.address;
            }
            if (order.projectName) {
              orderObj[order.id].projectName = order.projectName
            }
          }
        })
        this.storageService.setItem("orders", {...this.ordersSource, ... orderObj});
        this.ordersSource = {...this.ordersSource, ... orderObj};
      } else {
        this.ordersSource =  this.storageService.getItem("orders");
      }
    });
  }

  // --------------------Mongo Endpoints -------------------- //

  findManyOrders(filter?,ownership?): Observable<Order[]>{
    let params = new HttpParams();
    if (filter) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (ownership) {
      params = params.append('ownership', ownership);
    }
    return this.http.get<any>(`${environment.apiPath}orders`, {params}).pipe(map((resp) => resp.data));
  }

  findOne(id: string): Observable<Order> {
    return this.http.get<Order>(`${environment.apiPath}orders/${id}`, {});
  }

  insertOne(order: LegacyOrder): Observable<Order> {
    return this.http.post<Order>(`${environment.apiPath}orders`, order);
  }

  updateOne(order: Order): Observable<Order> { // This endpoint is used only b ff2 
    return this.http.put<Order>(`${environment.apiPath}orders/${order._id}`, order);
  }

  // -------------------- Legacy Mysql Endpoints -------------------- //

  findPilotLegacyOrder(id: number): Observable<LegacyPilotOrder> {
    return this.http.get<{data: LegacyPilotOrder}>(`${environment.apiPath}legacy/orders/${id}?id=${id}`, {}).pipe(map((resp) => resp.data));
  }

  findClientLegacyOrder(id: number): Observable<LegacyClientOrder> {
    return this.http.get<{data: LegacyClientOrder}>(`${environment.apiPath}legacy/orders/${id}?id=${id}`, {}).pipe(map((resp) => resp.data));
  }

  findClientAllLegacyOrders(page?: number, sort?: string, sortDir?: string, filterGroup?: OrderGroup, verifyDate?: number, q?: string): Promise<OrderPage<LegacyOrder>> {
    return this.http.get<{ data: OrderPage<LegacyOrder>}>(`${environment.apiPath}legacy/orders?page=${page ?? ''}&sort=${sort ?? ''}&sortDir=${sortDir ?? ''}&statGroup=${filterGroup ?? ''}&verifyDate=${verifyDate ?? ''}&q=${q ? encodeURI(q) : ''}`)
      .pipe(map((resp) => resp.data)).toPromise();
  }

  findPilotAllLegacyOrders(page?: number, sort?: string, sortDir?: string, filterGroup?: OrderGroup, q?: string): Promise<OrderPage<LegacyOrder>> {
    return this.http.get<{ data: OrderPage<LegacyOrder>}>(`${environment.apiPath}legacy/orders?page=${page ?? ''}&sort=${sort ?? ''}&sortDir=${sortDir ?? ''}&statGroup=${filterGroup ?? ''}&pilot=1&q=${q ? encodeURI(q) : ''}`)
      .pipe(map((resp) => resp.data)).toPromise();
  }

  // -------------------- Helpers -------------------- //

  public getOrderGroup(status: string): any {
    const doneStatuses = ['to_verify', 'done', 'invoiced', 'paid'];
    const inProgressStatuses = ['looking_pilots', 'in_progress', 'fq_looking_pilots', 'inProgress'];
    const notStartedStatuses = ['requested_job', 'lost', 'notStarted'];
    const checkingStatuses = ['footage_review', 'post_production', 'external_processing', 'review_post_production', 'checking'];
    const onHoldStatuses = ['on_hold'];
    if (doneStatuses.includes(status)) {
      return 'done';
    } else if (inProgressStatuses.includes(status)) {
      return 'inProgress';
    } else if (notStartedStatuses.includes(status)) {
      return 'notStarted';
    } else if (checkingStatuses.includes(status)) {
      return 'checking';
    } else if (onHoldStatuses.includes(status)) {
      return 'onHold';
    }
    return 'notStarted';
  }

  public fromOrderGroupToOrderStatus(orderGroup: OrderGroup): string[] {
    switch (orderGroup) {
      case 'done':
        return ['done', 'invoiced', 'paid', 'done'];
      case 'inProgress':
        return ['looking_pilots', 'in_progress', 'fq_looking_pilots', 'inProgress'];
      case 'notStarted':
        return ['requested_job', 'lost', 'notStarted'];
      case 'checking':
        return ['footage_review', 'post_production', 'external_processing', 'review_post_production', 'checking'];
      case 'onHold':
        return ['on_hold'];
    }
  }

  public getOrderStatus(status: string): OrderStatus {
    switch (status) {
      case 'to_verify':
      case 'done':
      case 'invoiced':
      case 'paid':
        return 'done';

      case 'looking_pilots':
      case 'in_progress':
      case 'inProgress':
      case 'fq_looking_pilots':
      case 'on_hold':
        return 'inProgress';

      case 'requested_job':
      case 'lost':
      case 'notStarted':
        return 'notStarted';

      case 'on_hold':
      case 'onHold':
        return 'onHold';

      case 'footage_review':
      case 'post_production':
      case 'external_processing':
      case 'review_post_production':
      case 'checking':
        return 'checking';
    }
    return 'notStarted';
  }

  public getOrderColor(group: OrderGroup, map?: boolean): string {
    if (map) {
      return orderColors[group][1];
    } else {
      return orderColors[group][0];
    }
  }

  public getAddress(id: number, withProjectName = true): string {
    // return 'Address | Project Name'
    let tmpReturn = "";
    if (id && this.ordersSource !== null && this.ordersSource[id.toString()]) {
      if (this.ordersSource[id.toString()].address) {
        tmpReturn = this.ordersSource[id.toString()].address;
      }
      if (withProjectName && this.ordersSource[id.toString()].projectName) {
        if (tmpReturn.length !== 0) {
          tmpReturn = tmpReturn + ' | '
        }
        tmpReturn = tmpReturn + this.ordersSource[id.toString()].projectName
      }
    }
    return tmpReturn
  }

  public canAccessFolder(order):boolean {
    if  (this.loginStateService.loggedUser$.value?.accountType === AccountType.ADMIN || this.loginStateService.loggedUser$.value?.accountType === AccountType.SUPERADMIN || order.hasSubscription) {
      return true;
    } else {
      const dateToVerify = new Date(order.dateSetToVerify);
      const currentDate = new Date();
  
      // Add 90 days to dateSetToVerify
      const expirationDate = new Date(dateToVerify);
      expirationDate.setDate(expirationDate.getDate() + 90);
      return  currentDate > expirationDate;
    }
  }

}
