<mat-tree
    #tree
    class="folders"
    [dataSource]="dataSource"
    [treeControl]="treeControl">

  <!-- Expandable or Leaf nodes -->
  <mat-tree-node
      *matTreeNodeDef="let node"
      [id]="node._id"
      [class.droppable]="isRootFolderEditable || node.level > 0"
      [class.expandable-node]="hasChild(null, node)"
      [class.leaf-node]="!hasChild(null, node)"
      [draggable]="!isMobile && node._id && node.level > 0"
      (dragend)="onDragEnd($event)"
      (dragstart)="onDragStart($event)"
      matTreeNodePadding
      [matTreeNodePaddingIndent]="20"
      routerLinkActive="active">

    <button
        *ngIf="hasChild(null, node)"
        [attr.aria-label]="'toggle ' + node.name"
        [disabled]="node.fetching"
        mat-icon-button
        [attr.title]="(node.fetching || treeControl.isExpanded(node)) ?
        null : ((node.childrenCount === 1 ? 'FOLDERS.SHOW_SUBFOLDER' : 'FOLDERS.SHOW_SUBFOLDERS') |
        translate: { count: node.childrenCount })"
        matTreeNodeToggle>
      <mat-icon class="rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right' }}
      </mat-icon>
    </button>

    <a
        (click)="closeSidenavNavigation()"
        draggable="false"
        [routerLink]="[{ outlets: { primary: ['files', node._id] }}]">
      <mat-icon>folder</mat-icon>
      <p>{{ node.name?.startsWith('FILES.') ? (node.name | translate) : node.name }}</p>
    </a>

    <button
        *ngIf="node.level === 0"
        class="sort-button"
        (click)="$event.stopPropagation()"
        mat-icon-button
        [matMenuTriggerFor]="sortMenu">
      <mat-icon>{{ sortedData.direction === 'asc' ? 'arrow_upwards' : 'arrow_downwards' }}</mat-icon>
    </button>
  </mat-tree-node>

  <!-- Load more nodes -->
  <mat-tree-node
      *matTreeNodeDef="let node; when: isLoadMore"
      [id]="node._id"
      class="load-more-node"
      matTreeNodePadding
      [matTreeNodePaddingIndent]="20">

    <button
        (click)="expandFolder(node, false, true)"
        mat-button>
      <mat-icon>expand_more</mat-icon>
      <p>{{ (sortedData.active === 'modified' ? (sortedData.direction === 'asc' ? 'OLDER' : 'RECENT') : 'MORE') | translate }}...</p>
    </button>
  </mat-tree-node>
</mat-tree>

<mat-menu
    #sortMenu="matMenu"
    matSort
    [matSortActive]="sortedData.active"
    [matSortDirection]="sortedData.direction"
    (matSortChange)="sortData($event)"
    matSortDisableClear>
  <ng-template matMenuContent>
    <button mat-menu-item>
      <div mat-sort-header="name">
        <mat-icon>sort_by_alpha</mat-icon>
        <span>{{ 'SORT_BY_NAME' | translate }}</span>
      </div>
    </button>
    <button mat-menu-item>
      <div mat-sort-header="modified" start="desc">
        <mat-icon>update</mat-icon>
        <span>{{ 'SORT_BY_MODIFIED' | translate }}</span>
      </div>
    </button>
  </ng-template>
</mat-menu>
