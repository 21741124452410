<div>
  <div *ngIf="templateFolders">
    <mat-form-field appearance="fill">
      <mat-label>Client or order</mat-label>
      <mat-select [(ngModel)]="selectedIdx" (ngModelChange)="fillFolder()">
        <mat-option *ngFor="let tf of templateFolders; let i = index" [value]="i">
          <span *ngIf="!tf.orderId">{{tf.userName}} (Master)</span>
          <span *ngIf="tf.orderId">FF{{tf.orderId}}<span *ngIf="tf.orderName"> | {{tf.orderName}}</span><span *ngIf="tf.orderAddress"> | {{tf.orderAddress}}</span></span>
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="isDirty()">
        <strong>Unsaved changes!</strong>
      </mat-hint>
    </mat-form-field>

    <div *ngIf="selectedIdx !== undefined">
      <table mat-table [dataSource]="folders" *ngIf="folders.length > 0">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let row"> <div [ngStyle]="{'padding-left': (row.indent * 16 + 'px')}">{{row.folder.name}}</div> </td>
        </ng-container>

        <ng-container matColumnDef="more">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row; let index = index">
            <button mat-icon-button class="more-button" tabindex="-1" type="button"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{idx: index, name: row.name}">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="padded" *ngIf="folders.length === 0">
        <i>No folders yet.</i>
      </div>

      <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-name="name" let-idx="idx">
          <button mat-menu-item (click)="openFolderDialog(folderFromIndex(idx))">Edit</button>
          <button mat-menu-item (click)="addFolder(folderFromIndex(idx))">Add folder</button>
          <button mat-menu-item (click)="deleteFolder(idx)">Delete</button>
        </ng-template>
      </mat-menu>

      <div class="buttons">
        <button mat-button (click)="addFolder()">New folder</button>
        <button mat-button (click)="copyMaster()" *ngIf="selectedIdx > 0">Copy master</button>
        <button mat-button (click)="clear()" [disabled]="!templateFolders[selectedIdx].root.subs || templateFolders[selectedIdx].root.subs.length === 0">Clear</button>
        <button mat-button (click)="save()" [disabled]="!isDirty()">Save</button>
        <button mat-button (click)="refresh()" [disabled]="!isDirty()">Reset</button>
      </div>
    </div>

    <div class="padded" *ngIf="selectedIdx === undefined">
      <i>No folder selected.</i>
    </div>
  </div>

  <div class="padded" *ngIf="!templateFolders">
    Loading template folders...
  </div>

</div>
