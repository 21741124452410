import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-to-imogent-dialog',
  templateUrl: './send-to-imogent-dialog.component.html',
  styleUrls: ['./send-to-imogent-dialog.component.scss']
})
export class SendToImogentDialogComponent {
  formGroup: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<SendToImogentDialogComponent>,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      imogentSuborderId: new FormControl('', [
        Validators.required,
        Validators.minLength(20),
        Validators.maxLength(20),
        // Safe Characters https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
        Validators.pattern(/^[^:\\|\/{}<>´`"~^%#]+$/)
      ])
    });
  }

  get orderId(): AbstractControl { return this.formGroup.get('orderId'); }
  get imogentSuborderId(): AbstractControl { return this.formGroup.get('imogentSuborderId'); }
}
