import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { FileModel } from '../files/file/file.model';
import { SidebarService } from '../files/file/sidebar/sidebar.service';

@Injectable({
  providedIn: 'root'
})

export class ViewerService {
  action$: BehaviorSubject<string> = new BehaviorSubject(null);
  demFileModel$: BehaviorSubject<FileModel> = new BehaviorSubject(null);
  height$: BehaviorSubject<number> = new BehaviorSubject(0.001);
  imagesFileModels$: BehaviorSubject<any> = new BehaviorSubject(null);
  orthoFileModel$: BehaviorSubject<FileModel> = new BehaviorSubject(null);
  showWater$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  view$: BehaviorSubject<string> = new BehaviorSubject("2d");
  viewerFileModel$: BehaviorSubject<FileModel> = new BehaviorSubject(null);

  constructor(
      private sidebarService: SidebarService
  ) { }

  getViewerObj(viewerFileModel: FileModel): any {
    const viewerObj = {};
    const viewer = viewerFileModel.viewer
    if (viewer.dem) { Object.assign(viewerObj, { dem: viewer.dem }); }
    if (viewer.tiles3d) { Object.assign(viewerObj, { tiles3d: viewer.tiles3d }); }
    if (viewer.orthomosaic) { Object.assign(viewerObj, { orthomosaic: viewer.orthomosaic }); }
    if (viewer.imageCones?.imagesFolder) { Object.assign(viewerObj, { imagesFolder: viewer.imageCones.imagesFolder }); }

    // set referenceValues
    if (Object.keys(viewer.referenceValues).length > 0) { 
      const referenceValuesObj = {};
      const referenceValues = viewer.referenceValues
      if (referenceValues.height2d) { Object.assign(referenceValuesObj, { height2d: Number(referenceValues.height2d) }); }
      if (referenceValues.height3d) { Object.assign(referenceValuesObj, { height3d: Number(referenceValues.height3d)}); }
      if (referenceValues.heightLocalWater) { Object.assign(referenceValuesObj, { heightLocalWater: Number(referenceValues.heightLocalWater)}); }

      Object.assign(viewerObj, {referenceValues: referenceValuesObj});
    }

    if (Object.keys(viewer.view3d).length > 0) {
      const view3dObj = {};
      const view3d = viewer.view3d
      if (view3d.camera) { Object.assign(view3dObj, { camera: view3d.camera }); }
      if (view3d.target) { Object.assign(view3dObj, { target: view3d.target }); }

      Object.assign(viewerObj, {view3d: view3dObj});
    }

    return viewerObj
  }

  makeViewerObj(): FileModel {
    const viewerFileModel = this.viewerFileModel$.value
    if (!viewerFileModel.viewer) {
      viewerFileModel.viewer = {};
    }
    if (!viewerFileModel.viewer.referenceValues) {
      viewerFileModel.viewer.referenceValues = {};
    }
    if (!viewerFileModel.viewer.view3d) {
      viewerFileModel.viewer.view3d = {};
    }
    return viewerFileModel
  }

  setUpdate(values): void {
    const viewerFileModel = this.viewerFileModel$.value
    const fileUpdates: FileModel = {};
    Object.assign(fileUpdates, { _id: viewerFileModel._id });
    this.viewerFileModel$.value.modified = new Date();
    Object.assign(fileUpdates, values);

    this.sidebarService.updateOne(fileUpdates, viewerFileModel);
    this.viewerFileModel$.next(viewerFileModel);
  }
}
