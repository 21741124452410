import { SvgHelper } from './SvgHelper';

export class ResizeGrip {
  readonly GRIP_SIZE = 5;
  visual: SVGGraphicsElement;

  constructor() {
    this.visual = SvgHelper.createCircle(this.GRIP_SIZE, [['class', 'grip']]);
  }
}
