import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'severityLabel'
})
export class SeverityLabelPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 100: return 'SITE.URGENT';
      case 80: return 'SITE.CRITICAL';
      case 60: return 'SITE.IMPORTANT';
      case 40: return 'SITE.MINOR';
      case 20: return 'SITE.ADVISORY_NOTE';
      default: return 'Undefined';
    }
  }
}