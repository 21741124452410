import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { FilesService } from '../../files.service';
import { Vector as VectorSource } from 'ol/source';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { FileModel } from '../../file/file.model';
import { Coordinate } from 'ol/coordinate';
import { mercatorToWGS84, toValidNumber } from '../utils/helpers';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PVModule } from '../model/pv-module.model';
import { PvModulesService } from './pv-modules.service';


@Injectable({
  providedIn: 'root'
})
export class PvInspectionService {
  ngDestroy$ = new Subject();

  constructor(
    private filesService: FilesService,
    private http: HttpClient,
    private pvModulesService: PvModulesService
  ) {}

  // -------------------------- API Calls -----------------------------
  public findOne(folderId: string): Observable<any> {
    return this.filesService.findOne(folderId).pipe(
      take(1), // Ensures we only take one emission from the observable
      switchMap(orthoInspectionFolder => {
        orthoInspectionFolder.data.orthoInspectionMode
        return orthoInspectionFolder.data;
      })
    );
  }

  public getPvInspections(filter: string): Observable<any> {
    return this.http.get(`${environment.apiPath}/pv-module-inspections`, { params: { filter } });
  }

  public getPvInspectionById(inspectionID: string): Observable<any> {
    return this.http.get(`${environment.apiPath}/pv-module-inspections/${inspectionID}`);
  }

  public createPvInspection(body: any): Observable<any> {
    return this.http.post(`${environment.apiPath}/pv-module-inspections`, body);
  }

  public updatePvInspection(inspectionID: string, body: any): Observable<any> {
    return this.http.put(`${environment.apiPath}/pv-module-inspections/${inspectionID}`, body);
  }

  public deletePvInspection(inspectionID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}/pv-module-inspections/${inspectionID}`);
  }

  // ------------------------ Business Logic --------------------------
  getOrthoInspectionData(folderId: string): Observable<any> {
    return this.filesService.findOne(folderId).pipe(
      take(1),
      switchMap(orthoInspectionFolder => {  
        const mapLayers = orthoInspectionFolder.data.orthoInspectionMode?.mapLayers;
  
        // If mapLayers is not present or not an array, return the folder data as is
        if (!Array.isArray(mapLayers) || mapLayers.length === 0) {
          return of(orthoInspectionFolder.data);
        }
  
        // Create an array of observables to fetch each layer's file data
        const layerRequests = mapLayers.map(layer => {
          const fileRequest = layer.type === 'geojson'
            ? this.filesService.findOne(layer.fileId, { meta: 0 })
            : this.filesService.findOne(layer.fileId);
  
          return fileRequest.pipe(
            take(1),
            map(layerFile => {
              layer.file = layerFile.data;
              return layer;
            })
          );
        });
  
        return forkJoin(layerRequests).pipe(
          map(() => orthoInspectionFolder.data)
        );
      }),
      catchError(error => {
        console.error('Error fetching ortho inspection data:', error);
        return throwError(() => error);
      })
    );
  }
  
  saveGeojsonAsIndependentModules(vectorSource: VectorSource, siteId: string ):void {
    if (!vectorSource) {
      console.error("Vector source is undefined!");
      return;
    }

    const geojsonParser = new GeoJSON();
    const features = vectorSource.getFeatures();

    const documents = features.map((feature: Feature) => ({
        siteId: siteId,
        location: {
          geometry: geojsonParser.writeFeatureObject(feature).geometry, 
          panelNumber: feature.getProperties().panelNumber,
          arrayId: feature.getProperties().arrayId,
          stringNumber: feature.getProperties().stringNumber,
          tiltAngle: toValidNumber(feature.getProperties().tiltAngle),
          azimuthAngle: toValidNumber(feature.getProperties().azimuthAngle),
          shadingInfo: feature.getProperties().shadingInfo || 'none',
        },
        maxPower: toValidNumber(feature.getProperties().capacity),
        identification: {
          serialNumber: feature.getProperties().serialNumber,
          fId: feature.getProperties().fId,
          moduleType: feature.getProperties().moduleType || feature.getProperties().module_type,
          manufacturer: feature.getProperties().manufacturer,
          modelNumber: feature.getProperties().modelNumber,
          installationDate: feature.getProperties().installationDate,
          removalDate: feature.getProperties().removalDate,
          warrantyExpiryDate: feature.getProperties().warrantyExpiryDate
        }
    }));

    this.pvModulesService.createPvModules(documents).subscribe(
      (response) => {
        console.log('response:', response)
      },
      (error) => {
        console.error('error:', error)
      }
    );
  }

  fetchNearByImages(imagesFolderId: string, coordinateMercator: Coordinate, distance: number = 1): Observable<FileModel[]> {
    // Convert distance to radians for geospatial query
    let radius = (distance / 6378100).toFixed(10); // Keep it as a string
    
    const filter = {
      folderID: { $oid: imagesFolderId },
      geo: {
        $geoWithin: {
          $centerSphere: [mercatorToWGS84(coordinateMercator), 0.000001567827], // Convert back if needed
        },
      },
    };

    const sort = {
      "taken": 1,
    }

    const fields = {
      'meta': 0,
      'annotations': 0,
      "childrenCount": 0,
      "path": 0
    }
  
    return this.filesService.findMany(filter, sort, undefined, undefined, fields).pipe(
      takeUntil(this.ngDestroy$),
      map(dataResponse => (dataResponse ? dataResponse.data : []))
    );
  }
}
