<form class="login-form">
  <h2>{{ 'LOGIN_OPTIONS' | translate }}</h2>
  <a
      draggable="false"
      mat-stroked-button
      (click)="authenticateWithGasoidcMercedes()">{{ 'LOGIN_WITH' | translate: { provider: 'Mercedes Portal' } }}</a>

  <!-- <a
      draggable="false"
      mat-stroked-button
      href="">Option 2</a>

  <a
      draggable="false"
      mat-stroked-button
      href="">option 3</a>

  <a
      draggable="false"
      mat-stroked-button
      href="">option 4</a> -->
</form>
