import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Annotation } from './annotation.model';
import { environment } from './../../../../../../environments/environment';
import { FilesService } from '../../../files.service';
import { InspectionService } from '../../../inspection/inspection.service';

@Injectable({
  providedIn: 'root'
})
export class AnnotationsService {

  thermalAnnotation: any;
  wideAnnotation: any;
  thermalAnnotations: any;
  wideAnnotations: any;
  constructor(
    private http: HttpClient,
    private filesService: FilesService,
    private inspectionService: InspectionService,
  ) { }
  annotations$: BehaviorSubject<Array<Annotation>> = new BehaviorSubject([]);
  thermalAnnotations$: BehaviorSubject<Array<Annotation>> = new BehaviorSubject([]);
  masterAnnotations$: BehaviorSubject<Array<Annotation>> = new BehaviorSubject([]);
  temperatureAnno$: BehaviorSubject<Annotation> = new BehaviorSubject(undefined);
  closeSetNew$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  addNewAnnotation$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  setAnnotations(annotations: Annotation[]){
    this.annotations$.next(annotations);
  }

  setNewComment(status){
    this.addNewAnnotation$.next(status)
  }

  TemperatureAnnotation(newAnno){
    this.temperatureAnno$.next(newAnno)
  }

  closeSetNew(status){
    this.closeSetNew$.next(status)
  }

  getThermalAnnotations(masterAnnotations): Array<Annotation>{
    const thermalId = this.filesService.allData.find(file => file._id === masterAnnotations[0].fileID).thermalSubFileModel._id
    return this.filesService.allData.find(file => file._id === thermalId).annotations
  }

  setThermalAnnotations(thermalAnnos):void{
    this.thermalAnnotations$.next(thermalAnnos)
  }

  setMasterAnnotations(masterAnnos):void{
    this.masterAnnotations$.next(masterAnnos)
  }

  updateOne(annotationsID: string, orgAnnotation: any, annotation: any): Observable<any> {
    const changesAnnotation = {}

    if (annotation.comment && orgAnnotation.comment !== annotation.comment) {
      Object.assign(changesAnnotation, { comment: annotation.comment});
    }
    if (orgAnnotation.coordinates !== annotation.coordinates) {
      Object.assign(changesAnnotation, { coordinates: annotation.coordinates});
    }
    if (orgAnnotation.feature !== annotation.feature) {
      Object.assign(changesAnnotation, { feature: annotation.feature});
    }
    if (orgAnnotation.stateDimension !== annotation.stateDimension) {

      Object.assign(changesAnnotation, { stateDimension: annotation.stateDimension});
    }
    if (orgAnnotation.stateForm !== annotation.stateForm) {
      Object.assign(changesAnnotation, { stateForm: annotation.stateForm});
    }

    const annotationRequest = {
        "$set": changesAnnotation
    }
    return this.http.patch(`${environment.apiPath}annotations/${annotationsID}`, annotationRequest, { observe: 'response' });
  }

  insertOne(annotation: any): Observable<any> {
    return this.http.post(`${environment.apiPath}annotations`, annotation, { observe: 'response' });
  }

  deleteOne(annotationsID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}annotations/${annotationsID}`, { observe: 'response' });
  }

  getAllAnnotationsFeatures (folderId): Observable<any>{
    return this.http.get<any>(`${environment.apiPath}files/${folderId}/features`);
  }

  updateAnnotationStats(fileModel,newStats?): object{
    if (newStats){
      return newStats
    }
    if (fileModel.annotations){
      let newAnnotationStats:object = {};
      newAnnotationStats['total'] = fileModel.annotations.length
      newAnnotationStats['urgent'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 100).length
      newAnnotationStats['high'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 80).length
      newAnnotationStats['medium'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 60).length
      newAnnotationStats['low'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 40).length
      newAnnotationStats['advisory'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 20).length
      return newAnnotationStats
    }
    return fileModel.annotationStats
  }

  updateTypesStats(fileModel): object {
    let newTypesStats:object = {};
    if (fileModel.annotations){
      fileModel.annotations.forEach((annotation)=>{
        if(newTypesStats[annotation.feature]){
          newTypesStats[annotation.feature]++
        } else {
          newTypesStats[annotation.feature]=1
        }
      })
    }
    this.inspectionService.dataSourceBeforeFilter$.value.find(file => fileModel._id === file._id).typesStats=newTypesStats;
    return newTypesStats
  }

}
