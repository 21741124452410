<ng-container *ngIf="dataSourcePending.filteredData.length > 0">
  <h2>{{ "TASKLIST.PENDING" | translate }}</h2>
  <div class="mat-elevation-z8 tableWrapper">
    <table mat-table [dataSource]="dataSourcePending">
      <!-- timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>timestamp</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timestamp | date: "dd.MM.yyyy | HH:mm:ss" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Target Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef>target</th>
        <td mat-cell *matCellDef="let element">{{ element.target.name }}</td>
      </ng-container>

      <!-- Step Column -->
      <ng-container matColumnDef="step">
        <th mat-header-cell *matHeaderCellDef>step</th>
        <td mat-cell *matCellDef="let element">
          <span class="clickable">{{ element.step }}</span>
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef>progress</th>
        <td mat-cell *matCellDef="let element">
          <progress *ngIf="element.progress" [value]="element.progress" [max]="1"></progress> ({{element.progress * 100 | number: '1.1-1'}} %)
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    #paginatorPending
    *ngIf="dataSourcePending.filteredData.length > 10"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
  >
  </mat-paginator>
</ng-container>

<ng-container *ngIf="dataSourceRunning.filteredData.length > 0">
  <h2>{{ "TASKLIST.RUNNING" | translate }}</h2>
  <div class="mat-elevation-z8 tableWrapper">
    <table mat-table [dataSource]="dataSourceRunning">
      <!-- timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>timestamp</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timestamp | date: "dd.MM.yyyy | HH:mm:ss" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Target Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef>target</th>
        <td mat-cell *matCellDef="let element">{{ element.target.name }}</td>
      </ng-container>

      <!-- Step Column -->
      <ng-container matColumnDef="step">
        <th mat-header-cell *matHeaderCellDef>step</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.step }}</span>
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef>progress</th>
        <td mat-cell *matCellDef="let element">
          <progress *ngIf="element.progress" [value]="element.progress" [max]="1"></progress> ({{element.progress * 100 | number: '1.1-1'}} %)
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    #paginatorRunning
    *ngIf="dataSourceRunning.filteredData.length > 10"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
  >
  </mat-paginator>
</ng-container>

<ng-container *ngIf="dataSourceSuccess.filteredData.length > 0">
  <h2>{{ "TASKLIST.SUCCESS" | translate }}</h2>
  <div class="mat-elevation-z8 tableWrapper">
    <table mat-table [dataSource]="dataSourceSuccess">
      <!-- timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>timestamp</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timestamp | date: "dd.MM.yyyy | HH:mm:ss" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Target Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef>target</th>
        <td mat-cell *matCellDef="let element">
          <span class="clickable" (click)="goToZip(element.target.objectId)">{{
            element.target.name
          }}</span>
        </td>
      </ng-container>

      <!-- Errors Column -->
      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef>errors</th>
        <td mat-cell *matCellDef="let element">{{ element.errors }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsError"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsError"></tr>
    </table>
  </div>
  <mat-paginator
    #paginatorSuccess
    *ngIf="dataSourceSuccess.filteredData.length > 10"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
  >
  </mat-paginator>
</ng-container>

<ng-container *ngIf="dataSourceFailed.filteredData.length > 0">
  <h2>{{ "TASKLIST.FAILED" | translate }}</h2>
  <div class="mat-elevation-z8 tableWrapper">
    <table mat-table [dataSource]="dataSourceFailed">
      <!-- timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef>timestamp</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timestamp | date: "dd.MM.yyyy | HH:mm:ss" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Target Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef>target</th>
        <td mat-cell *matCellDef="let element">
          <span class="clickable" (click)="goToZip(element.target.objectId)">{{
            element.target.name
          }}</span>
        </td>
      </ng-container>

      <!-- Errors Column -->
      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef>errors</th>
        <td mat-cell *matCellDef="let element">{{ element.errors }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsError"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsError"></tr>
    </table>
  </div>
  <mat-paginator
    #paginatorFailed
    *ngIf="dataSourceFailed.filteredData.length > 10"
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
  >
  </mat-paginator>
</ng-container>

<h2
  *ngIf="
    dataSourcePending.filteredData.length === 0 &&
    dataSourceRunning.filteredData.length === 0 &&
    dataSourceSuccess.filteredData.length === 0 &&
    dataSourceFailed.filteredData.length === 0
  "
>
  {{ "TASKLIST.NO_TASKS" | translate }}
</h2>
