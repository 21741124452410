<div class="mat-elevation-z8 table-container">
  <div class="header">
    <div class="title">
      <h2 mat-dialog-title>{{ 'SIDEBAR.ANNOTATIONS.INSPECTION_ANNOTATIONS' | translate }}</h2>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon class="filter-icon">filter_list</mat-icon>
      </button>
      <div class="button-container" *ngIf="selection.hasValue()">
        <button mat-raised-button color="accent" (click)="data.addMoreAnnotationsToTask ? addAnnotations() : createTask()">
          {{ data.addMoreAnnotationsToTask ? 'Add Annotations' : 'Create Task' }}
        </button>
      </div>
      <mat-menu #menu="matMenu">
        <mat-option *ngFor="let column of allColumns; let i = index" [value]="column" (selectionChange)="$event.stopPropagation()">
          <mat-checkbox
            [checked]="displayedColumns.includes(column) || i === 0"
            [disabled]="i === 0 || column === 'comment'"
            (change)="i !== 0 && column !== 'comment' && toggleColumn(column)">
            {{ getHeader(column) | translate }}
          </mat-checkbox>
        </mat-option>
      </mat-menu>
    </div>
    <mat-icon class="close-icon" (click)="close()">close</mat-icon>
  </div>
  <div class="table-wrapper">
    <mat-table [dataSource]="dataSource" matSort class="annotation-table sticky-header">
    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef class="medium-cell">
        <mat-checkbox [ngStyle]="{'margin-right': '11px'}" (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
        {{ 'FILE.IMAGE' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="medium-cell">
        <mat-checkbox
          class="hidden-checkbox"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)">
        </mat-checkbox>
        <div class="map" [ngClass]="'_'+element.file._id+element._id"></div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="annoID">
      <mat-header-cell *matHeaderCellDef class="medium-cell">{{ 'PDF-EDITOR.ANNOTATION' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element" class="medium-cell"> {{element.annoID}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="severity">
      <mat-header-cell *matHeaderCellDef mat-sort-header="stateDimension" class="medium-cell"> {{ 'ANNOTATION.SEVERITY' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="medium-cell"> {{inspectionService.getStateDimension(element.stateDimension, data.currentLang)}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header="feature" class="medium-cell"> {{ 'ANNOTATION.TYPE' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="medium-cell"> {{inspectionService.getFeature(element.feature, data.currentLang)}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="minimumTemperature">
      <mat-header-cell *matHeaderCellDef mat-sort-header="minimumTemperature" class="small-cell"> T.Min </mat-header-cell>
      <mat-cell *matCellDef="let element" class="small-cell"> {{ element.minimumTemperature ? (element.minimumTemperature.toFixed(2) + ' °C') : '-' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="maximumTemperature">
      <mat-header-cell *matHeaderCellDef mat-sort-header="maximumTemperature" class="small-cell"> T.Max </mat-header-cell>
      <mat-cell *matCellDef="let element" class="small-cell"> {{ element.maximumTemperature ? (element.maximumTemperature.toFixed(2) + ' °C') : '-' }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="averageTemperature">
      <mat-header-cell *matHeaderCellDef mat-sort-header="averageTemperature" class="small-cell"> T.Avg </mat-header-cell>
      <mat-cell *matCellDef="let element" class="small-cell"> {{ element.averageTemperature ? (element.averageTemperature.toFixed(2) + ' °C') : '-' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="deltaTemperature">
      <mat-header-cell *matHeaderCellDef mat-sort-header="deltaTemperature" class="small-cell"> T.Delta </mat-header-cell>
      <mat-cell *matCellDef="let element" class="small-cell"> {{ element.deltaTemperature ? (element.deltaTemperature.toFixed(2) + ' °C') : '-' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
      <mat-header-cell *matHeaderCellDef> {{ 'SIDEBAR.ANNOTATIONS.COMMENT' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.comment ? element.comment : '-' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell *matHeaderCellDef class="medium-cell"> {{ 'USER.TITLE' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="medium-cell gray"> {{element.userName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modificationDate">
      <mat-header-cell *matHeaderCellDef class="medium-cell" mat-sort-header="modified"> {{ 'SITE.LIST.LAST_MODIFICATION' | translate }} </mat-header-cell>
      <mat-cell *matCellDef="let element" class="medium-cell gray"> {{ element.modified | date:'dd.MM.yyyy HH:mm:ss' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="wrappedFooter">
      <mat-footer-cell *matFooterCellDef colspan="6">{{ 'SITE.TOTAL' | translate }}: {{dataSource?.data.length || 0}}</mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'dark-theme': theme.includes('dark')}"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected-row': selection.isSelected(row)}"></mat-row>
    <mat-footer-row *matFooterRowDef="['wrappedFooter']" class="table-footer" [ngClass]="{'dark-theme': theme.includes('dark')}"></mat-footer-row>

    </mat-table>
  </div>
</div>
