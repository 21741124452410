<div class="spinner" *ngIf="fetching">
  <mat-progress-spinner
      color="accent"
      diameter="48"
      [mode]="mode"
      strokeWidth="4"
      [value]="value">
  </mat-progress-spinner>
  <span *ngIf="value">{{ value | number:'1.0-0' }}%</span>
</div>
