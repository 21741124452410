import { RectangleBase } from './RectangleBase';
import { SvgHelper } from './SvgHelper';

export class Rectangle extends RectangleBase {
  constructor() {
    super();
    this.type = 'Rectangle';
  }

  static draw = (): RectangleBase => {
    const rectangle = new Rectangle();
    rectangle.setup();

    return rectangle;
  };

  protected setup(): void {
    super.setup();
    SvgHelper.setAttributes(this.visual, [['class', 'drawing rectangle']]);
  }
}
