import { Directive, HostListener } from '@angular/core';

@Directive()
export abstract class ComponentCanDeactivateDirective {

  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: Event): void {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
