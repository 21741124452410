<div class="wrapper"
    [class.dark]="this.theme && this.theme.includes('dark')">
  <h1>{{ 'SIDEBAR.ANALYTICS.STRUCTURE' | translate }}</h1>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'SIDEBAR.ANALYTICS.TOWER' | translate }}
        </mat-panel-title>
        <mat-panel-description>
          <button mat-icon-button (click)="toggleAll($event, dataStructure, allStructureVisible)">
            <mat-icon *ngIf="allStructureVisible">visibility</mat-icon>
            <mat-icon *ngIf="!allStructureVisible">visibility_off</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="level" *ngFor="let structure of dataStructure">
        <div
            [style.background]="structure.color+'80'"
            [style.border-color]="structure.color"
            class="colorCircle"></div>
        <div class="text">
          {{ 'SIDEBAR.ANALYTICS.LEVELS.'+structure.name | translate }}<br>
          {{structure.height.toFixed(2)}}m
        </div>
        <button mat-icon-button (click)="toggleItem($event, structure)">
          <mat-icon *ngIf="structure.visible">visibility</mat-icon>
          <mat-icon *ngIf="!structure.visible">visibility_off</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'SIDEBAR.ANALYTICS.MOUNTS' | translate }}
        </mat-panel-title>
        <mat-panel-description>
          <button mat-icon-button (click)="toggleAll($event, dataMounts, allMountsVisible)">
            <mat-icon *ngIf="allMountsVisible">visibility</mat-icon>
            <mat-icon *ngIf="!allMountsVisible">visibility_off</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-accordion #mountAccordion="matAccordion">
        <mat-expansion-panel *ngFor="let mount of dataMounts" (opened)="select(mount.name)" (closed)="select('')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{mount.name}}
            </mat-panel-title>
            <mat-panel-description>
              <button mat-icon-button (click)="toggleItem($event, mount)">
                <mat-icon *ngIf="mount.visible">visibility</mat-icon>
                <mat-icon *ngIf="!mount.visible">visibility_off</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="meta-item">
            <span class="meta-key">Location</span>
            <span class="meta-value">{{mount.location}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Type</span>
            <span class="meta-value">{{mount.type}}</span>
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>

  <h1 *ngIf="dataAntenna.length !== 0 || dataDish.length !== 0 || dataRRU.length !== 0">
    {{ 'SIDEBAR.ANALYTICS.EQUIPMENT' | translate }}
    <button mat-icon-button (click)="showTable()">
      <mat-icon>file_open</mat-icon>
    </button>
  </h1>
  <mat-accordion>
    <mat-expansion-panel *ngIf="dataAntenna.length !== 0" (closed)="antennaAccordion.closeAll()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'SIDEBAR.ANALYTICS.ANTENNA' | translate }}
        </mat-panel-title>
        <mat-panel-description>
          <button mat-icon-button (click)="toggleAll($event, dataAntenna, allAntennaVisible)">
            <mat-icon *ngIf="allAntennaVisible">visibility</mat-icon>
            <mat-icon *ngIf="!allAntennaVisible">visibility_off</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-accordion #antennaAccordion="matAccordion">
        <mat-expansion-panel *ngFor="let antenna of dataAntenna" (opened)="select(antenna.name)" (closed)="select('')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{antenna.name}} - {{convertDirection(antenna.azimuth)}}
            </mat-panel-title>
            <mat-panel-description>
              <button mat-icon-button (click)="toggleItem($event, antenna)">
                <mat-icon *ngIf="antenna.visible">visibility</mat-icon>
                <mat-icon *ngIf="!antenna.visible">visibility_off</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="meta-item">
            <span class="meta-key">Manufacturer</span>
            <span class="meta-value">{{antenna.manufacturer}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Model</span>
            <span class="meta-value">{{antenna.model}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Face</span>
            <span class="meta-value">{{antenna.face}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Azimuth</span>
            <span class="meta-value">{{antenna.azimuth}}°</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Tilt</span>
            <span class="meta-value">{{antenna.tilt}}°</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Height</span>
            <span class="meta-value">{{ calcLength(antenna.height).toFixed(2) }}m</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Width</span>
            <span class="meta-value">{{ calcLength(antenna.width).toFixed(2) }}m</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Depth</span>
            <span class="meta-value">{{ calcLength(antenna.depth).toFixed(2) }}m</span>
          </p>
        </mat-expansion-panel>
      </mat-accordion>

    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="dataDish.length !== 0" (closed)="dishAccordion.closeAll()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'SIDEBAR.ANALYTICS.DISH' | translate }}
        </mat-panel-title>
        <mat-panel-description>
          <button mat-icon-button (click)="toggleAll($event, dataDish, allDishVisible)">
            <mat-icon *ngIf="allDishVisible">visibility</mat-icon>
            <mat-icon *ngIf="!allDishVisible">visibility_off</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-accordion #dishAccordion="matAccordion">
        <mat-expansion-panel *ngFor="let antenna of dataDish" (opened)="select(antenna.name)" (closed)="select('')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{antenna.name}} - {{convertDirection(antenna.azimuth)}}
            </mat-panel-title>
            <mat-panel-description>
              <button mat-icon-button (click)="toggleItem($event, antenna)">
                <mat-icon *ngIf="antenna.visible">visibility</mat-icon>
                <mat-icon *ngIf="!antenna.visible">visibility_off</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="meta-item">
            <span class="meta-key">Manufacturer</span>
            <span class="meta-value">{{antenna.manufacturer}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Model</span>
            <span class="meta-value">{{antenna.model}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Face</span>
            <span class="meta-value">{{antenna.face}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Azimuth</span>
            <span class="meta-value">{{antenna.azimuth}}°</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Tilt</span>
            <span class="meta-value">{{antenna.tilt}}°</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Height</span>
            <span class="meta-value">{{ calcLength(antenna.height).toFixed(2) }}m</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Width</span>
            <span class="meta-value">{{ calcLength(antenna.width).toFixed(2) }}m</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Depth</span>
            <span class="meta-value">{{ calcLength(antenna.depth).toFixed(2) }}m</span>
          </p>
        </mat-expansion-panel>
      </mat-accordion>

    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="dataRRU.length !== 0" (closed)="RRUAccordion.closeAll()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'SIDEBAR.ANALYTICS.RRU' | translate }}
        </mat-panel-title>
        <mat-panel-description>
          <button mat-icon-button (click)="toggleAll($event, dataRRU, allRRUVisible)">
            <mat-icon *ngIf="allRRUVisible">visibility</mat-icon>
            <mat-icon *ngIf="!allRRUVisible">visibility_off</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-accordion #RRUAccordion="matAccordion">
        <mat-expansion-panel *ngFor="let antenna of dataRRU" (opened)="select(antenna.name)" (closed)="select('')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{antenna.name}} - {{convertDirection(antenna.azimuth)}}
            </mat-panel-title>
            <mat-panel-description>
              <button mat-icon-button (click)="toggleItem($event, antenna)">
                <mat-icon *ngIf="antenna.visible">visibility</mat-icon>
                <mat-icon *ngIf="!antenna.visible">visibility_off</mat-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="meta-item">
            <span class="meta-key">Manufacturer</span>
            <span class="meta-value">{{antenna.manufacturer}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Model</span>
            <span class="meta-value">{{antenna.model}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Face</span>
            <span class="meta-value">{{antenna.face}}</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Azimuth</span>
            <span class="meta-value">{{antenna.azimuth}}°</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Tilt</span>
            <span class="meta-value">{{antenna.tilt}}°</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Height</span>
            <span class="meta-value">{{ calcLength(antenna.height).toFixed(2) }}m</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Width</span>
            <span class="meta-value">{{ calcLength(antenna.width).toFixed(2) }}m</span>
          </p>
          <p class="meta-item">
            <span class="meta-key">Depth</span>
            <span class="meta-value">{{ calcLength(antenna.depth).toFixed(2) }}m</span>
          </p>
        </mat-expansion-panel>
      </mat-accordion>

    </mat-expansion-panel>
  </mat-accordion>
</div>
