import {
  E_USER_DOCUMENT_TYPES,
  E_USER_ID_TYPE,
  IIdType,
  UserLanguage,
  IUserType,
  UserSkill
} from '../interfaces/user.interfaces';
import { USER_LANGUAGE } from '../user.model';

export enum SECTIONS {
  PRE_PRODUCT = 'My Specialties',
  POST_PRODUCT = 'Post Production'
}

export const USER_LANGUAGES: UserLanguage[] = [
  {
    label: 'USER.LANGUAGE.ENGLISH',
    code: USER_LANGUAGE.ENGLISH
  },
  {
    label: 'USER.LANGUAGE.GERMAN',
    code: USER_LANGUAGE.GERMAN
  },
  //TODO: Languages are related to emails that are sent via a collaboration, meaning if the template has no version of the specified language, it will generate 500 Error.
  {
    label: 'USER.LANGUAGE.ITALIAN',
    code: USER_LANGUAGE.ITALIAN
  },
  {
    label: 'USER.LANGUAGE.SPANISH',
    code: USER_LANGUAGE.SPANISH
  },
// {
//   label: 'USER.LANGUAGE.ARABIC',
//   code: USER_LANGUAGE.ARABIC
// },
]

export const USER_DOCUMENTS_TYPES: IUserType[] = [
  {
    type: E_USER_DOCUMENT_TYPES.ID_DOCUMENT,
    label: 'USER.DOCUMENT.ID_DOCUMENT'
  },
  {
    type:  E_USER_DOCUMENT_TYPES.OPERATOR,
    label: 'USER.DOCUMENT.OPERATOR_WAIVER'
  },
  {
    type:  E_USER_DOCUMENT_TYPES.INSURANCE,
    label: 'USER.DOCUMENT.INSURANCE'
  },
  {
    type:  E_USER_DOCUMENT_TYPES.PERMIT,
    label: 'USER.DOCUMENT.FLIGHT_PERMIT'
  }
]

export const USER_ID_TYPES: IIdType[] = [
  {
    type: E_USER_ID_TYPE.CARD,
    label: 'USER.DOCUMENT.CARD'
  }
]
