import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TemplateFolderDialogData} from "../files-template.component";

@Component({
  selector: 'app-template-rename-dialog',
  templateUrl: './template-rename-dialog.component.html',
  styleUrls: ['./template-rename-dialog.component.scss']
})
export class TemplateRenameDialogComponent implements OnInit {

  public newName?: string;

  constructor(
    public dialogRef: MatDialogRef<TemplateRenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TemplateFolderDialogData,
  ) {}

  ngOnInit() {
    this.newName = this.data.folder.name;
  }

  close(): void {
    this.dialogRef.close();
  }

  ok(): void {
    if (!this.newName) {
      return;
    }
    this.data.folder.name = this.newName;
    this.dialogRef.close();
  }

}
