<h2 mat-dialog-title>{{ 'FILES.SEND_TO_RAPTOR_MAPS' | translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" novalidate>
    <mat-form-field>
      <mat-label>{{ 'ORDER.ID' | translate }}</mat-label>
      <input
          id="order-id"
          formControlName="orderId"
          type="number"
          (keyup.enter)="dialogRef.close({orderId: orderId.value})"
          matInput>
      <mat-icon matSuffix>shop</mat-icon>
      <mat-error *ngIf="orderId.errors?.min">{{ 'VALIDATION.POSITIVE' | translate }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      type="button">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      #create
      color="accent"
      [disabled]="!formGroup.valid"
      [mat-dialog-close]="{ orderId: orderId.value }"
      mat-raised-button
      type="button">{{ 'OK' | translate | uppercase }}</button>
</div>
