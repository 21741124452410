<mat-card class="documents-container">
  <mat-card-content>
    <mat-card *ngIf="!isDocumentButtonClicked && !activeDocumentType" (click)="toggleDocumentAdd()" class="document-adder">
      <mat-card-content>
        <mat-icon >playlist_add</mat-icon>
      </mat-card-content>
    </mat-card>
    <mat-card
      *ngIf="isDocumentButtonClicked && !activeDocumentType"
      (click)="toggleDocumentAdd()"
      class="document-adder"
    >
      <mat-card-content>
        <ul>
          <li
            *ngFor="let type of documentTypes"
            (click)="selectDocumentType(type.type)"
          >
            {{ type.label | translate }}
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
    <ng-container
      *ngIf="activeDocumentType"
      [ngTemplateOutlet]="documentCardTemplate(activeDocumentType)"
      [ngTemplateOutletContext]="{document: {}}">
    </ng-container>
    <ng-container
      *ngFor="let document of documents; let i = index"
      [ngTemplateOutlet]="documentCardTemplate(document.type)"
      [ngTemplateOutletContext]="{document: document, index: i}">
    </ng-container>
  </mat-card-content>
</mat-card>

<ng-template #idDocument let-document="document" let-index="index">
  <mat-card class="document-card">
    <mat-card-header>
      <mat-card-subtitle class="document-title">{{ getDocumentCardLabel(document) | translate }}</mat-card-subtitle>
      <div class="actions small" *ngIf="!isDocumentEditing(document, index); else saveActions">
        <mat-icon (click)="openEditor(index, document)">edit</mat-icon>
        <mat-icon (click)="deleteDocument(document.id)">delete_outline</mat-icon>
      </div>
      <ng-template #saveActions>
        <div class="actions">
          <button
            mat-stroked-button
            color="accent"
            (click)="cancelEditing()"
          >
            {{ 'CANCEL' | translate }}
          </button>
          <button
            mat-raised-button
            color="accent"
            [disabled]="(documentForm.invalid && documentForm.touched) || !this.attachmentId"
            (click)="addDocument(document)"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-card-header>
    <mat-card-content [formGroup]="documentForm" class="document-body">
      <p>
        <span>
          {{ 'USER.DOCUMENT.ID_TYPE' | translate }}:
        </span>
        <mat-select
          *ngIf="isDocumentEditing(document, index)"
          formControlName="idType"
          [placeholder]="'USER.DOCUMENT.SELECT_TYPE' | translate"
        >
          <mat-option [value]="option.type" *ngFor="let option of documentIdTypes">
            {{ option.label | translate }}
          </mat-option>
        </mat-select>
        <span *ngIf="!isDocumentEditing(document, index)">{{ getTypeIdLabel(document.idType) | translate }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.ISSUING_COUNTRY' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="country"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.country }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.EXPIRATION_DATE' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="expirationDate"
          type="date">
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.expirationDate | date: 'yyyy.MM.dd' }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.NUMBER' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          value="top"
          formControlName="number"
          type="number"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.number }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.ATTACHED_DOCUMENT' | translate }}:</span>
        <input type="file" #uploader (change)="uploadFileHandler($event.target.files)" class="uploader">
        <button
          *ngIf="isDocumentEditing(document, index)"
          mat-stroked-button
          color="accent"
          (click)="uploader.click()"
        >
          {{ 'UPLOAD.LABEL' | translate }}
        </button>
        <a
          *ngIf="!isDocumentEditing(document, index)"
          [attr.href]="getFileLink(document.file)"
          target="_blank"
          rel="noopener"
        >
          {{ 'USER.DOCUMENT.LINK' | translate }}
        </a>
      </p>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #insurance let-document="document" let-index="index">
  <mat-card class="document-card">
    <mat-card-header>
      <mat-card-subtitle class="document-title">{{ getDocumentCardLabel(document) | translate }}</mat-card-subtitle>
      <div class="actions small" *ngIf="!isDocumentEditing(document, index); else saveActions">
        <mat-icon (click)="openEditor(index, document)">edit</mat-icon>
        <mat-icon (click)="deleteDocument(document.id)">delete_outline</mat-icon>
      </div>
      <ng-template #saveActions>
        <div class="actions">
          <button
            mat-stroked-button
            color="accent"
            (click)="cancelEditing()"
          >
            {{ 'CANCEL' | translate }}
          </button>
          <button
            mat-raised-button
            color="accent"
            [disabled]="(documentForm.invalid && documentForm.touched) || !this.attachmentId"
            (click)="addDocument(document)"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-card-header>
    <mat-card-content [formGroup]="documentForm" class="document-body">
      <p>
        <span>
          {{ 'USER.DOCUMENT.COMPANY' | translate }}:
        </span>
        <input
          matInput
          *ngIf="isDocumentEditing(document, index)"
          formControlName="company"
        />
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.company }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.POLICY' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="number"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.number }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.TOTAL' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="totalInsured"
          type="text">
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.totalInsured }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.EXPIRATION' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          value="top"
          formControlName="expirationDate"
          type="date"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.expirationDate | date: 'yyyy.MM.dd' }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.ATTACHED_DOCUMENT' | translate }}:</span>
        <input type="file" #uploader (change)="uploadFileHandler($event.target.files)" class="uploader">
        <button
          *ngIf="isDocumentEditing(document, index)"
          mat-stroked-button
          color="accent"
          (click)="uploader.click()"
        >
          {{ 'UPLOAD.LABEL' | translate }}
        </button>
        <a
          *ngIf="!isDocumentEditing(document, index)"
          [attr.href]="getFileLink(document.file)"
          target="_blank"
          rel="noopener"
        >
          {{ 'USER.DOCUMENT.LINK' | translate }}
        </a>
      </p>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #permit let-document="document" let-index="index">
  <mat-card class="document-card">
    <mat-card-header>
      <mat-card-subtitle class="document-title">{{ getDocumentCardLabel(document) | translate }}</mat-card-subtitle>
      <div class="actions small" *ngIf="!isDocumentEditing(document, index); else saveActions">
        <mat-icon (click)="openEditor(index, document)">edit</mat-icon>
        <mat-icon (click)="deleteDocument(document.id)">delete_outline</mat-icon>
      </div>
      <ng-template #saveActions>
        <div class="actions">
          <button
            mat-stroked-button
            color="accent"
            (click)="cancelEditing()"
          >
            {{ 'CANCEL' | translate }}
          </button>
          <button
            mat-raised-button
            color="accent"
            [disabled]="(documentForm.invalid && documentForm.touched) || !this.attachmentId"
            (click)="addDocument(document)"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-card-header>
    <mat-card-content [formGroup]="documentForm" class="document-body">
      <p>
        <span>
          {{ 'USER.DOCUMENT.COMPANY' | translate }}:
        </span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="company"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.company }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.LICENSE' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="numberLicense"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.numberLicense }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.REGION' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="region"
          type="text">
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.region }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.EXPIRATION' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          value="top"
          formControlName="expirationDate"
          type="date"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.expirationDate | date: 'yyyy.MM.dd' }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.COMMENT' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          value="top"
          formControlName="comment"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.comment }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.ATTACHED_DOCUMENT' | translate }}:</span>
        <input type="file" #uploader (change)="uploadFileHandler($event.target.files)" class="uploader">
        <button
          *ngIf="isDocumentEditing(document, index)"
          mat-stroked-button
          color="accent"
          (click)="uploader.click()"
        >
          {{ 'UPLOAD.LABEL' | translate }}
        </button>
        <a
          *ngIf="!isDocumentEditing(document, index)"
          [attr.href]="getFileLink(document.file)"
          target="_blank"
          rel="noopener"
        >
          {{ 'USER.DOCUMENT.LINK' | translate }}
        </a>
      </p>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #operator let-document="document" let-index="index">
  <mat-card class="document-card">
    <mat-card-header>
      <mat-card-subtitle class="document-title">{{ getDocumentCardLabel(document) | translate }}</mat-card-subtitle>
      <div class="actions small" *ngIf="!isDocumentEditing(document, index); else saveActions">
        <mat-icon (click)="openEditor(index, document)">edit</mat-icon>
        <mat-icon (click)="deleteDocument(document.id)">delete_outline</mat-icon>
      </div>
      <ng-template #saveActions>
        <div class="actions">
          <button
            mat-stroked-button
            color="accent"
            (click)="cancelEditing()"
          >
            {{ 'CANCEL' | translate }}
          </button>
          <button
            mat-raised-button
            color="accent"
            [disabled]="(documentForm.invalid && documentForm.touched) || !this.attachmentId"
            (click)="addDocument(document)"
          >
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-card-header>
    <mat-card-content [formGroup]="documentForm" class="document-body">
      <p>
        <span>
          {{ 'USER.DOCUMENT.AUTHORITY' | translate }}:
        </span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="issuingAuthority"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.issuingAuthority }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.EXPIRATION' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          formControlName="expirationDate"
          type="date"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.expirationDate | date: 'yyyy.MM.dd' }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.COMMENT' | translate }}:</span>
        <input
          *ngIf="isDocumentEditing(document, index)"
          matInput
          value="top"
          formControlName="comment"
          type="text"
        >
        <span *ngIf="!isDocumentEditing(document, index)">{{ document.comment }}</span>
      </p>
      <p>
        <span>{{ 'USER.DOCUMENT.ATTACHED_DOCUMENT' | translate }}:</span>
        <input type="file" #uploader (change)="uploadFileHandler($event.target.files)" class="uploader">
        <button
          *ngIf="isDocumentEditing(document, index)"
          mat-stroked-button
          color="accent"
          (click)="uploader.click()"
        >
          {{ 'UPLOAD.LABEL' | translate }}
        </button>
        <a
          *ngIf="!isDocumentEditing(document, index)"
          [attr.href]="getFileLink(document.file)"
          target="_blank"
          rel="noopener"
        >
          {{ 'USER.DOCUMENT.LINK' | translate }}
        </a>
      </p>
    </mat-card-content>
  </mat-card>
</ng-template>
