<div class="slider-container">
  <span class="slider-label">
    {{ 'INSPECTION.ALTITUDE' | translate }}:
  </span>
  <ngx-slider
      class="heightSlider"
      [(value)]="minValue"
      [(highValue)]="maxValue"
      [options]="options"
      (userChangeEnd)="filterSet()">
  </ngx-slider>
</div>
