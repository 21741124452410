import { BehaviorSubject, Subject } from 'rxjs';
import { ElementRef, Injectable } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SidenavDetailService {

  nativeElement: HTMLElement;
  opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resized$: BehaviorSubject<number> = new BehaviorSubject(360);
  title$: Subject<string> = new Subject();

  private sidenav: MatSidenav;

  /**
   * Close this sidenav, and return a Promise that will resolve when it's fully closed (or get rejected if it didn't).
   *
   * @returns Promise<MatDrawerToggleResult>
   */
  close(): Promise<MatDrawerToggleResult> {
    this.opened$.next(false);

    return this.sidenav.close();
  }

  /**
   * Open this sidenav, and return a Promise that will resolve when it's fully opened (or get rejected if it didn't).
   *
   * @returns Promise<MatDrawerToggleResult>
   */
  open(): Promise<MatDrawerToggleResult> {
    this.opened$.next(true);

    return this.sidenav.open();
  }

  /**
   * Called when sidenav was resized.
   */
  resized(width: number): void {
    this.resized$.next(width);
  }

  /**
   * Setter for sidenav.
   */
  setSidenav(sidenav: any): void {
    this.title$.next('');
    this.sidenav = sidenav;
    this.nativeElement = sidenav._elementRef.nativeElement;
  }

  setTitle(title: string): void {
    this.title$.next(title);
  }

  /**
   * Toggle this sidenav. This is equivalent to calling open() when it's already opened, or close() when it's closed.
   */
  toggleOpen(): Promise<MatDrawerToggleResult> {
    return this.sidenav.toggle(!this.opened$.value);
  }
}
