import { EventEmitter } from '@angular/core';

import { LineBaseState } from './LineBaseState';
import { Base } from './Base';
import { ResizeGrip } from './ResizeGrip';
import { SvgHelper } from './SvgHelper';

export class LineBase extends Base {
  protected line: SVGLineElement;
  protected previousState: LineBaseState;
  private activeGrip: ResizeGrip;
  private controlBox: SVGGElement;
  private controlGrip1: ResizeGrip;
  private controlGrip2: ResizeGrip;
  private readonly MIN_LENGTH = 20;
  private touchAreaLine: SVGLineElement;
  private x1 = 0;
  private x2: number = this.width;
  private y1 = 0;
  private y2 = 0;

  static draw = (): LineBase => {
    const lineBase = new LineBase();
    lineBase.setup();

    return lineBase;
  };

  deselect(): void {
    super.deselect();
    this.controlBox.style.display = 'none';
  }

  endManipulation(): void {
    super.endManipulation();
    this.isResizing = false;
    this.activeGrip = null;
  }

  getState(): LineBaseState {
    const state: LineBaseState = Object.assign(
      {
        x1: this.x1,
        y1: this.y1,
        x2: this.x2,
        y2: this.y2
      },
      super.getState()
    );

    return state;
  }

  gripMouseDown = (ev: MouseEvent) => {
    this.isResizing = true;
    this.activeGrip = (ev.target as SVGGraphicsElement) === this.controlGrip1.visual ? this.controlGrip1 : this.controlGrip2;
    this.previousMouseX = ev.screenX;
    this.previousMouseY = ev.screenY;
    this.previousState = this.getState();
    ev.stopPropagation();
  };

  restoreState(state: LineBaseState): void {
    this.x1 = state.x1;
    this.y1 = state.y1;
    this.x2 = state.x2;
    this.y2 = state.y2;
    super.restoreState(state);
    this.adjustLine();
  }

  select(): void {
    super.select();
    this.controlBox.style.display = '';
  }

  stateFromGeoJSON(): void {
    const rect = this.target.getBoundingClientRect();
    const properties = this.geoJSON.properties;
    const scale = rect.width / properties['mw'];
    const coordinates = this.geoJSON.geometry['coordinates'];
    const x = properties['x'] * scale;
    const y = properties['y'] * scale;
    this.x1 = coordinates[0][0] * scale;
    this.y1 = coordinates[0][1] * scale;
    this.x2 = coordinates[1][0] * scale;
    this.y2 = coordinates[1][1] * scale;

    const translate = this.visual.transform.baseVal.getItem(0);
    translate.setTranslate(x, y);
    this.visual.transform.baseVal.replaceItem(translate, 0);

    this.adjustLine();
    this.adjustControlBox();
  }

  stateToGeoJSON(): void {
    const matrix = this.visual.transform.baseVal.getItem(0).matrix;
    const rect = this.target.getBoundingClientRect();
    const mw = this.target.naturalWidth;
    const mh = this.target.naturalHeight;
    const scale = mw / rect.width;
    const x = matrix.e * scale;
    const y = matrix.f * scale;
    const x1 = this.x1 * scale;
    const y1 = this.y1 * scale;
    const x2 = this.x2 * scale;
    const y2 = this.y2 * scale;

    this.geoJSON = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [x1, y1],
          [x2, y2]
        ]
      },
      properties: {
        shape: this.type,
        x,  // Translate x
        y, // Translate y
        mw, // The full width of the image at the time when the drawing was made
        mh // The full height of the image at the time when the drawing was made
      }
    };
  }

  protected adjustLine(): void {
    this.line.setAttribute('x1', this.x1.toString());
    this.line.setAttribute('y1', this.y1.toString());
    this.line.setAttribute('x2', this.x2.toString());
    this.line.setAttribute('y2', this.y2.toString());
    this.touchAreaLine.setAttribute('x1', this.x1.toString());
    this.touchAreaLine.setAttribute('y1', this.y1.toString());
    this.touchAreaLine.setAttribute('x2', this.x2.toString());
    this.touchAreaLine.setAttribute('y2', this.y2.toString());
  }

  protected resize(dx: number, dy: number): void {
    const previousX1 = this.previousState ? this.previousState.x1 : 0;
    const previousY1 = this.previousState ? this.previousState.y1 : 0;
    const previousX2 = this.previousState ? this.previousState.x2 : 0;
    const previousY2 = this.previousState ? this.previousState.y2 : 0;

    if (this.activeGrip) {
      if (this.activeGrip === this.controlGrip1
          && this.getLineLength(previousX1 + dx, previousY1 + 1, previousX2, previousY2) >= this.MIN_LENGTH) {
        this.x1 = previousX1 + dx;
        this.y1 = previousY1 + dy;
      } else if (this.activeGrip === this.controlGrip2
          && this.getLineLength(previousX1, previousY1, previousX2 + dx, previousY2 + dy) >= this.MIN_LENGTH) {
        this.x2 = previousX2 + dx;
        this.y2 = previousY2 + dy;
      }
    }

    this.adjustLine();
    this.adjustControlBox();
  }

  protected setup(): void {
    super.setup();

    this.touchAreaLine = SvgHelper.createLine(0, 0, this.x2, 0,
      [['stroke', 'transparent'], ['stroke-width', '30']]);
    this.addToRenderVisual(this.touchAreaLine);
    this.line = SvgHelper.createLine(0, 0, this.x2, 0);
    this.addToRenderVisual(this.line);

    this.addControlBox();
  }

  private addControlBox = () => {
    this.controlBox = SvgHelper.createGroup([
      ['class', 'drawing-line-control-box'],
      ['style', 'display: none']
    ]);
    this.addToVisual(this.controlBox);

    this.addControlGrips();
  };

  private addControlGrips = () => {
    this.controlGrip1 = this.createGrip();
    this.controlGrip2 = this.createGrip();

    this.positionGrips();
  };

  private adjustControlBox = () => {
    this.positionGrips();
  };

  private createGrip = (): ResizeGrip => {
    const grip = new ResizeGrip();
    grip.visual.transform.baseVal.appendItem(SvgHelper.createTransform());
    this.controlBox.appendChild(grip.visual);

    return grip;
  };

  private getLineLength = (x1: number, y1: number, x2: number, y2: number): number => {
    const dx = Math.abs(x1 - x2);
    const dy = Math.abs(y1 - y2);

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
  };

  private positionGrip = (grip: SVGGraphicsElement, x: number, y: number) => {
    const translate = grip.transform.baseVal.getItem(0);
    translate.setTranslate(x, y);
    grip.transform.baseVal.replaceItem(translate, 0);
  };

  private positionGrips = () => {
    const gripSize = this.controlGrip1.GRIP_SIZE;

    const x1 = this.x1 - gripSize / 2;
    const y1 = this.y1 - gripSize / 2;
    const x2 = this.x2 - gripSize / 2;
    const y2 = this.y2 - gripSize / 2;

    this.positionGrip(this.controlGrip1.visual, x1, y1);
    this.positionGrip(this.controlGrip2.visual, x2, y2);
  };
}
