import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, ViewChild} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {OrdersService} from "../../../shared/orders/orders.service";
import {LegacyClientOrder, LegacyOrder, LegacyPilotOrder} from "../../../shared/orders/orders.model";
import {SidenavDetailService} from "../../../shared/sidenav/sidenav-detail/sidenav-detail.service";
import {environment} from "../../../../environments/environment";
import {LazyLoadService} from "../../../shared/helpers/lazy-load.service";
import {MatStepper} from "@angular/material/stepper";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MapsService} from "../../maps/maps.service";
import { FilesService } from '../../files/files.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { Collaboration } from '../../files/file/sidebar/collaborations/collaboration.model';
import { CollaborationsService } from '../../files/file/sidebar/collaborations/collaborations.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';
import { LoginStateService } from '../../login/login-state.service';
import { PortfolioService } from 'src/app/shared/portfolio/portfolio.service';
import { AccountType } from '../../users/models/user.model';
import { Portfolio } from 'src/app/shared/portfolio/portfolio.model';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements AfterViewInit, OnDestroy {

  public fetching = true;
  private ngDestroy$ = new Subject();
  public order?: LegacyOrder;
  public orderID?: number;
  public isInspection = false;
  public siteExists = true
  public clientOrder?: LegacyClientOrder;
  public pilotOrder?: LegacyPilotOrder;
  public googleMap?: google.maps.Map;
  public mapPolygon?: google.maps.Polygon;
  public mapMarker?: google.maps.Marker;
  public currentStep = 0;
  public collaborations: Collaboration[] = undefined;
  public loggedUser: any;
  public refreshed: EventEmitter<any> = new EventEmitter();
  public loadCollaborations: EventEmitter<any> = new EventEmitter();

  googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries=drawing,places`;
  googleMapIsLoaded = false;
  @ViewChild('googleMap') mapsElementRef: ElementRef<HTMLDivElement>;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(public ordersService: OrdersService,
              private route: ActivatedRoute,
              private router: Router,
              private sidenavDetailService: SidenavDetailService,
              private lazyLoadService: LazyLoadService,
              private mapsService: MapsService,
              public translate: TranslateService,
              private sanitizer: DomSanitizer,
              private filesService: FilesService,
              private dialogService: DialogService,
              private collaborationsService: CollaborationsService,
              public permissionsService: PermissionsService,
              private loginStateService: LoginStateService,
              private portfolioService: PortfolioService ) 
  {
    this.googleMapIsLoaded = this.mapsService.isLoaded;
    this.loginStateService.loggedUser$.pipe(takeUntil(this.ngDestroy$))
    .subscribe((user) => {
      if (user) {
        this.loggedUser = user
      }
    });

    this.route.params
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(params => {
      if (params) {
        const orderID = params['orderID'];
        if (orderID && this.order?.id !== orderID) {
          this.orderID = orderID;
          this.getOrder(orderID); // get order from Mysql
          if (!this.ordersService.activeOrder$.value) { // means that page was newly refreshed
            this.ordersService.findManyOrders({"legacyId":Number(orderID)},null)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe((resp: any) => {
              this.ordersService.setActiveOrder(resp.orders[0])
              this.refreshed.emit('refreshed');
            });       
          } else {
            this.loadCollaborations.emit('loadCollaborations');
          }
        }
      }
    });

  }

  ngOnDestroy() {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
    this.ordersService.activeOrder$.next(null);
  }

  ngAfterViewInit() {
    this.refreshed.pipe(takeUntil(this.ngDestroy$)).subscribe(()=>{
      this.loadCollaborators().then(() => {
        // set permissions
        if (this.loginStateService.loggedUser$.value?.accountType !== 'admin' && this.loginStateService.loggedUser$.value?.accountType !== 'superAdmin') {
          const collaboration = this.collaborations.find(collaboration => collaboration.subject.id === this.loginStateService.loggedUser$.value._id)
          if (collaboration) {
            this.permissionsService.setPermissions(collaboration.options.role);
          } else {
            if (this.loginStateService.loggedUser$.value.accountType === AccountType.PILOT) {
              this.permissionsService.setPermissions('pilot');
            } else if (this.loginStateService.loggedUser$.value.accountType === AccountType.CLIENT) {
              this.permissionsService.setPermissions('owner');
            }
          }
        }
        // load collaborators
        this.initializeButtons()
      }).catch(error => {
        console.error('Error loading collaborators:', error);
      }); 
    })

    this.loadCollaborations.pipe(takeUntil(this.ngDestroy$)).subscribe(()=>{ // when we switch between orders we need just to load collaborations
      this.loadCollaborators().then(() => {
        this.initializeButtons()  
      }).catch(error => {
        console.error('Error loading collaborators:', error);
      }); 
    })


    if (this.googleMapIsLoaded) {
      this.initMap();
    } else {
      this.lazyLoadService.loadScript(this.googleMapsURL).subscribe(_ => {
        this.googleMapIsLoaded = true;
        this.initMap();
      });
    }
  }

  loadCollaborators(): Promise<void> {
    const filter = this.collaborationsService.constructFilter('order', this.ordersService.activeOrder$.value?._id);
    return new Promise((resolve, reject) => {
      this.collaborationsService.getCollaborations(filter).pipe(
        takeUntil(this.ngDestroy$)
      ).subscribe(
        response => {
          if (response.data) {
            this.collaborations = this.collaborationsService.enrichCollaborationsWithUsersData(response.data);
          } else {
            this.collaborations = [];
          }
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  private initMap() {
    if (!this.mapsElementRef) {
      return;
    }
    this.googleMap = new google.maps.Map(this.mapsElementRef.nativeElement, {
      center: {lat: 48.119078, lng: 11.550390},
      zoom: 16,
      disableDefaultUI: false,
      gestureHandling: 'greedy',
      mapTypeControl: false,
      minZoom: 2,
      maxZoom: 18,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      mapTypeId: 'satellite',
      tilt: 0,
    });
    this.moveMap();
  }

  private getOrder(id) {
    this.fetching = true;
      if (this.loggedUser?.accountType !== AccountType.PILOT) {
      try {
        const o = this.ordersService.findClientLegacyOrder(id).pipe(takeUntil(this.ngDestroy$))
        .subscribe((o)=> {
          this.order = o;
          this.clientOrder = o;
          this.pilotOrder = undefined;
          this.fetching = false;
    
          if (this.dateNotNull(o?.verificationDate)) {
            this.currentStep = 6;
          } else if (this.dateNotNull(o?.flightDateEnd)) {
            this.currentStep = 4;
          } else if (this.dateNotNull(o?.flightDateBegin)) {
            this.currentStep = 3;
          } else if (this.dateNotNull(o?.preferredDate)) {
            this.currentStep = 2;
          } else if (this.dateNotNull(o?.bookingDate)) {
            this.currentStep = 1;
          } else if (!!o?.createdAt) {
            this.currentStep = 0;
          }
    
          if (this.stepper) {
            // Refresh the stepper state
            this.stepper.linear = false;
            this.stepper.reset();
            this.stepper.selectedIndex = this.currentStep;
            this.stepper.selected = this.stepper.steps.get(this.currentStep);
            this.stepper.linear = true;
          }
          this.moveMap();
        });
        
      } catch (error) {
        console.error('Error fetching client legacy order:', error);
        this.fetching = false;
      }
    } else {
      try {
        const o = this.ordersService.findPilotLegacyOrder(id).pipe(takeUntil(this.ngDestroy$))
        .subscribe((o)=>{
          this.order = o;
          this.clientOrder = undefined;
          this.pilotOrder = o;
          this.fetching = false;
          this.moveMap();
        });
        
      } catch (error) {
        console.error('Error fetching pilot legacy order:', error);
        this.fetching = false;
      }
    }
  }

  private initializeButtons() {
    //reset to default
    this.isInspection = false;
    this.siteExists = true

    if (this.ordersService.activeOrder$.value?.siteId) {
      this.isInspection = true;
      this.siteExists = true;
    } else {
      this.siteExists = false;
      const filter = { $and: [{ orderID: this.ordersService.activeOrder$.value?.legacyId || this.orderID }, { tags: "inspection" }] };
      try {
        this.filesService.findMany(filter, null, null, null, null).pipe(takeUntil(this.ngDestroy$))
        .subscribe((resp)=> {
          this.isInspection = resp.data.length > 0;
        })
        
      } catch (error) {
        console.error('Error fetching files:', error);
        this.isInspection = false;
      }
    }
  }

  navigateToSite(siteId?: string) {
      this.router.navigate(
        [{ outlets: { primary: ['portfolio', siteId || this.ordersService.activeOrder$.value?.siteId ], detail: null } }],
        { state: { order: this.order } }
      );
  }

  public createSite(legacyOrder: LegacyOrder) {
    this.portfolioService.getPortfolioByUser(this.ordersService.activeOrder$.value.clientId).subscribe(
      (portfolioResponse) => {
        if (portfolioResponse.data) {
          this.createSiteAndNavigateTo(portfolioResponse.data[0]);
        } else {
          const dialog = this.dialogService.showDialog('Information', null, 'You have no portfolio, do you want to create one', null, true, false) as Observable<any>;
          dialog.subscribe(
            (confirm) => {
              if (confirm) {
                this.portfolioService.createPortfolio(this.ordersService.activeOrder$.value.clientId, 'My Portfolio')
                .subscribe((portfolioResponse)=>{
                  if (portfolioResponse.data) {
                    this.createSiteAndNavigateTo(portfolioResponse.data);
                  }
                })
              }
            }
          );
        }
      }
    )
  }

  private createSiteAndNavigateTo (portfolio: Portfolio) {
    const order = this.ordersService.activeOrder$.value
    this.portfolioService.createPortfolioSites(order._id, order.address, order.address ,order.clientId, portfolio._id, 'building').subscribe(
      (siteResponse) => {
        if (siteResponse.data) {
          this.dialogService.showDialog('SITE.POPUP.SITE_CREATION_SUCCESS',null,null,null,null,true);
          this.navigateToSite(siteResponse.data._id);
        }
      },
      (error) => {
        this.dialogService.showDialog('SITE.POPUP.SITE_CREATION_FAILED',null,null,null,null,true);
      }
    );
  }

  private moveMap() {
    if (!this.googleMap || !this.order) {
      return;
    }

    if (this.mapMarker) {
      this.mapMarker.setMap(null);
    }
    if (this.mapPolygon) {
      this.mapPolygon.setMap(null);
    }

    let coords: {lat: number; lng: number}[] = [];
    try {
      coords = JSON.parse(this.order.coordinates ?? '[]');
    } catch (e) {
    }
    coords = coords.filter(c => c.lng && c.lat);

    if (coords.length === 0) {
      if (!this.order.address) {
        return;
      }
      // We couldn't find coordinates => center to address
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: this.order.address,
        },
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            const firstResult = results[0].geometry;
            if (firstResult.viewport) {
              const bounds = new google.maps.LatLngBounds();
              bounds.union(firstResult.viewport);
              this.mapMarker = new google.maps.Marker({
                position: bounds.getCenter(),
                map: this.googleMap,
                title: this.order.address,
              });
              this.googleMap.fitBounds(bounds);
            } else {
              this.mapMarker = new google.maps.Marker({
                position: firstResult.location,
                map: this.googleMap,
                title: this.order.address,
              });
              this.googleMap.setCenter(firstResult.location);
              this.googleMap.setZoom(18);
            }
          }
        }
      );
      return;
    }

    if (coords.length > 1) {
      // Set polygon
      this.mapPolygon = new google.maps.Polygon({
        paths: coords,
        strokeColor: '#F4A624',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#F4A624',
        fillOpacity: 0.35,
        draggable: false,
        editable: false,
        geodesic: false,
      });
      this.mapPolygon.setMap(this.googleMap);

      const bounds = new google.maps.LatLngBounds();
      coords.forEach(c => bounds.extend(c));
      this.googleMap.fitBounds(bounds);
    } else if (coords.length === 1) {
      this.mapMarker = new google.maps.Marker({
        position: coords[0],
        map: this.googleMap,
        title: this.order.address,
      });
      this.googleMap.setCenter(coords[0]);
      this.googleMap.setZoom(18);
    } else {
      // Should not happen
    }
  }

  public close() {
    if (this.sidenavDetailService.opened$.value) {
      this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([{ outlets: { primary: 'files', detail: null }}], { queryParamsHandling: 'merge' });
    }
  }

  public dateNotNull(date: string): boolean {
    if (!date) {
      return false;
    }
    if (date.startsWith('000')) {
      return false;
    }
    return true;
  }

  public getState(index: number, valueSet: boolean): string {
    if (this.currentStep === index) {
      return 'edit';
    }
    if (valueSet && this.currentStep > index) {
      return 'done';
    }
    if (!valueSet && this.currentStep > index) {
      return 'error';
    }
    return 'number';
  }

  public getDescriptionHtml(): SafeHtml {
    if (!this.pilotOrder) {
      return this.sanitizer.bypassSecurityTrustHtml('');
    }
    let description;
    if (this.translate.currentLang === 'en') {
      description = this.pilotOrder.descriptionEn ?? this.pilotOrder.descriptionDe;
    } else {
      description = this.pilotOrder.descriptionDe ?? this.pilotOrder.descriptionEn;
    }
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

}


