import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from './../../../../shared/dialog/dialog.service';
import { FileModel } from './../file.model';
import { FilesService } from './../../files.service';
import { Login } from './../../../login/login.model';
import { Permission, PermissionRole } from './../permission.model';
import { AccountType, UserRole } from '../../../users/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fileModel: FileModel;

  private ngDestroy$ = new Subject();

  constructor(
    public filesService: FilesService,
    private dialogService: DialogService
  ) { }

  hasPermission(permissions: Permission[],login: Login, role: PermissionRole): boolean {
    return (login && [AccountType.ADMIN, AccountType.SUPERADMIN].includes(login.accountType)) || // Admin always has permission
    (permissions && permissions.some(
    (permission: Permission) => permission.userID === login._id && permission.role >= role));
  }

  updateOne(fileUpdates: FileModel, fileModel: FileModel): void {
    this.fetchingChange$.next(true);

    this.filesService.updateOne(fileUpdates)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      () => {
        // update data models
        fileModel = {
          ...fileModel
        };
        this.dialogService.showDialog('FILE.SAVING_SUCCESSFUL', null, null, null, false, true);
        this.filesService.updateItem(fileModel);
      },
      error => {
        this.fetchingChange$.next(false);
        this.dialogService.showDialog('FILE.SAVING_FAILED', error.status, error.url, error.error);
      }
    );
  }
}
