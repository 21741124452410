export const pois = {
  airport: {svg: 'marker_airport', icon: 'https://fairfleet.com/a/Panorama/png/icon_airport.png' },
  bank: {svg: 'marker_bank', icon: 'https://fairfleet.com/a/Panorama/png/icon_bank.png' },
  bar: {svg: 'marker_bar', icon: 'https://fairfleet.com/a/Panorama/png/icon_bar.png' },
  beach: {svg: 'marker_beach', icon: 'https://fairfleet.com/a/Panorama/png/icon_beach.png' },
  bride: { svg: 'marker_bridge', icon: 'https://fairfleet.com/a/Panorama/png/icon_bridge.png'},
  bus_international: {svg: 'marker_bus_international', icon: 'https://fairfleet.com/a/Panorama/png/icon_bus_international.png' },
  bus: {svg: 'marker_bus', icon: 'https://fairfleet.com/a/Panorama/png/icon_bus.png' },
  central_station: {svg: 'marker_central_station', icon: 'https://fairfleet.com/a/Panorama/png/icon_central_station.png' },
  church: {svg: 'marker_church', icon: 'https://fairfleet.com/a/Panorama/png/icon_church.png' },
  city: {svg: 'marker_city', icon: 'https://fairfleet.com/a/Panorama/png/icon_city.png' },
  coffee: {svg: 'marker_coffee', icon: 'https://fairfleet.com/a/Panorama/png/icon_coffee.png' },
  construction: {svg: 'marker_construction', icon: 'https://fairfleet.com/a/Panorama/png/icon_construction.png' },
  custom: {icon: 'https://fairfleet.com/a/Panorama/png/', marker: 'https://fairfleet.com/a/Panorama/png/' },
  db: {svg: 'marker_db', icon: 'https://fairfleet.com/a/Panorama/png/icon_db.png' },
  ev_station: {svg:'marker_ev_station', icon: 'https://fairfleet.com/a/Panorama/png/icon_ev_station.png'},
  fitness_center: { svg: 'marker_fitness_center', icon: 'https://fairfleet.com/a/Panorama/png/icon_fitness_center.png'},
  freetime: {svg: 'marker_free_time', icon: 'https://fairfleet.com/a/Panorama/png/icon_free_time.png' },
  gas_station: {svg:'marker_gas_station', icon: 'https://fairfleet.com/a/Panorama/png/icon_gas_station.png'},
  golf: {svg: 'marker_golf', icon: 'https://fairfleet.com/a/Panorama/png/icon_golf.png' },
  harbour: {svg: 'marker_harbour', icon: 'https://fairfleet.com/a/Panorama/png/icon_harbour.png' },
  highway_international: {svg: 'marker_highway_international', icon: 'https://fairfleet.com/a/Panorama/png/icon_highway_international.png' },
  highway: {svg: 'marker_highway', icon: 'https://fairfleet.com/a/Panorama/png/icon_highway.png' },
  hospital: {svg: 'marker_hospital', icon: 'https://fairfleet.com/a/Panorama/png/icon_hospital.png' },
  hotel_bed: {svg: 'marker_hotel_bed', icon: 'https://fairfleet.com/a/Panorama/png/icon_hotel_bed.png' },
  hotel: {svg: 'marker_hotel', icon: 'https://fairfleet.com/a/Panorama/png/icon_hotel.png' },
  marker: {svg: 'marker_general', icon: 'https://fairfleet.com/a/Panorama/png/icon_general.png' },
  metro: {svg: 'marker_metro', icon: 'https://fairfleet.com/a/Panorama/png/icon_metro.png' },
  object: {svg: 'marker_house', icon: 'https://fairfleet.com/a/Panorama/png/icon_house.png' },
  park: {svg:'marker_park', icon: 'https://fairfleet.com/a/Panorama/png/icon_park.png'},
  parking: {svg:'marker_parking', icon: 'https://fairfleet.com/a/Panorama/png/icon_parking.png'},
  photo: {svg: 'marker_photo', icon: 'https://fairfleet.com/a/Panorama/png/icon_photo.png' },
  post_office: {svg: 'marker_post_office', icon: 'https://fairfleet.com/a/Panorama/png/icon_post_office.png' },
  restaurant: {svg: 'marker_restaurant', icon: 'https://fairfleet.com/a/Panorama/png/icon_restaurant.png' },
  sbahn_ubahn: {svg: 'marker_sbahn_ubahn', icon: 'https://fairfleet.com/a/Panorama/png/icon_sbahn_ubahn.png' },
  sbahn: {svg: 'marker_sbahn', icon: 'https://fairfleet.com/a/Panorama/png/icon_sbahn.png' },
  school: {svg: 'marker_school', icon: 'https://fairfleet.com/a/Panorama/png/icon_school.png' },
  shopping: {svg: 'marker_shopping', icon: 'https://fairfleet.com/a/Panorama/png/icon_shopping.png' },
  sight: {svg: 'marker_sight', icon: 'https://fairfleet.com/a/Panorama/png/icon_sight.png' },
  skyscraper: {svg: 'marker_skyscraper', icon: 'https://fairfleet.com/a/Panorama/png/icon_skyscraper.png' },
  soccer: {svg: 'marker_soccer', icon: 'https://fairfleet.com/a/Panorama/png/icon_soccer.png' },
  supermarket: {svg: 'marker_supermarket', icon: 'https://fairfleet.com/a/Panorama/png/icon_supermarket.png' },
  theater: { svg: 'marker_theater', icon: 'https://fairfleet.com/a/Panorama/png/icon_theater.png'},
  trade_fair: {svg: 'marker_trade_fair', icon: 'https://fairfleet.com/a/Panorama/png/icon_trade_fair.png' },
  tram: {svg: 'marker_tram', icon: 'https://fairfleet.com/a/Panorama/png/icon_tram.png' },
  ubahn_sbahn: {svg: 'marker_ubahn_sbahn', icon: 'https://fairfleet.com/a/Panorama/png/icon_ubahn_sbahn.png' },
  ubahn: {svg: 'marker_ubahn', icon: 'https://fairfleet.com/a/Panorama/png/icon_ubahn.png' },
  university: {svg: 'marker_university', icon: 'https://fairfleet.com/a/Panorama/png/icon_university.png' },
};
export const poisCluster = [
  { name: 'general', list: ['marker',  'object', 'skyscraper',] },
  { name: "transport", list: ['airport', 'bus', 'bus_international', 'bride', 'central_station', 'db', 'harbour', 'highway', 'highway_international', 'metro','sbahn', 'sbahn_ubahn', 'tram', 'ubahn', 'ubahn_sbahn'] },
  { name: "freetime", list: ['bar', 'beach', 'fitness_center', 'coffee', 'golf', 'freetime', 'park', 'photo', 'shopping', 'sight', 'soccer', 'theater'] },
  { name: "infrastructure", list: ['bank', 'church', 'city', 'construction', 'hospital', 'hotel', 'hotel_bed', 'parking', 'post_office', 'restaurant', 'school', 'supermarket', 'gas_station', 'ev_station', 'trade_fair', 'university'] }
]