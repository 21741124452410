<h2 mat-dialog-title>{{ data.header| translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" novalidate>
    <mat-form-field>
      <mat-label>{{ data.header| translate }}</mat-label>
      <textarea
        cdkTextareaAutosize
        formControlName="content"
        matInput></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      type="button">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      #create
      color="accent"
      [disabled]="!formGroup.valid"
      [mat-dialog-close]="{ content: content.value }"
      mat-raised-button
      type="button">{{ 'OK' | translate | uppercase }}</button>
</div>
