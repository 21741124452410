
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable()
export class ResetPasswordService {

  constructor(
      private http: HttpClient
  ) { }

  resetPassword(userID: string, password: string, token: string): Observable<Object> {
    return this.http
      .patch(`${environment.apiPath}users/${userID}`, { password }, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`
        })
      });
  }
}
