import { Component, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from './../dialog/dialog.service';
import { FileModel } from './../../pages/files/file/file.model';
import { FilesService } from './../../pages/files/files.service';
import { QueueItem } from './../upload/upload.model';
import { ThemeService } from './../theme/theme.service';
import { UploadService } from './../upload/upload.service';

export class Breadcrumb {
  label: string;
  id?: string;
}

@Component({
  selector: 'app-files-select-dialog',
  templateUrl: './files-select-dialog.component.html',
  styleUrls: ['./files-select-dialog.component.scss']
})

export class FilesSelectDialogComponent implements AfterViewInit, OnDestroy {

  breadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();
  dataSource: Array<FileModel> = [];
  displayedColumns = ['avatar-checkbox', 'name', 'created', 'modified', 'select'];
  fakeFolder: string
  fakeFolderInside: boolean;
  fetching = false;
  folderID: string;
  initFolderID: string;
  ngDestroy$ = new Subject();
  pageIndex = 0;
  pageSize = 500;
  queuedItemsID: Array<String> = [];
  queueItems: Array<QueueItem> = [];
  selectedCount = 0;
  theme: string;
  totalItems = 0;
  uploadAcceptType: string;
  uploadID: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogService: DialogService,
    private uploadService: UploadService,
    public filesService: FilesService,
    public dialogRef: MatDialogRef<FilesSelectDialogComponent>,
    private themeService: ThemeService,
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.initFolderID = this.inputData.folderID;
    this.fakeFolder = this.inputData.fakeFolder;

    if(this.inputData.uploadAcceptType) {
      this.uploadAcceptType = this.inputData.uploadAcceptType.join(', ');
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.navigateToFolder(this.inputData.folderID, this.fakeFolder!==undefined, this.inputData.legacyId, this)
    }, 1);

    this.uploadService.queueItems$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(queueItems => {
      this.queueItems = queueItems;
      queueItems.forEach((item) => {
        const id = item.key.split('/')[1];
        if(!this.queuedItemsID.includes(id)) {
          this.queuedItemsID.push(id)
        }
      })
      if(this.queueItems.length === 0 && this.uploadID) {
        this.dialogRef.close({'id' : this.uploadID})
      }
    });
  }

  handleFilesInput(files: FileList): void {
    const fileToUpload = files.item(0);
    if (this.fakeFolderInside) {
      const folder = {
        name: this.fakeFolder,
        folderID: { $oid: this.initFolderID },
        isFolder: true,
        mimeType: 'application/vnd.folder'
      };
      
      this.filesService.insertMany([folder])
      .subscribe(response => {
        this.handleFileUpload(fileToUpload, response.body.data[0]._id)
      });
    } else {
      this.handleFileUpload(fileToUpload, this.folderID);
    }  
    
  }

  handleFileUpload(fileToUpload: File, folder: string):void {
    this.uploadService.presignPutURLs([fileToUpload.name], this.folderID, 1440)
    .subscribe(presignResponse => {
      this.fetching = true;
      this.uploadService.handleFilesQueue([fileToUpload], presignResponse.data, folder);
      this.uploadID = presignResponse.data[0].key.split('/')[1]
    },
    error => {
      this.dialogService.showDialog('UPLOAD.FAILED', error.status, error.url, error.error);
    });
  }

  handleFolderInput(files: FileList): void {
    this.uploadService.handleFilesInput(files, this.folderID);
  }

  navigateToFolder(folderID, checkFakeFolder = false, legacyId: number = undefined, dem = undefined ): void {
    if (folderID === 'fakeFolder') {
      folderID = this.initFolderID;
      checkFakeFolder = true;
      this.dataSource = [];
    }

    const filter = {}

    if (folderID) {
      filter['folderID'] = folderID?.length === 24 ?{ $oid: folderID } : { $exists: false }
    }

    if (legacyId) {
      filter['orderID'] = legacyId
    }

    if (dem) {
      filter['tags'] = { $in: ['dem'] }
    }

    const sort = {"isFolder":-1, "modified":-1, "created":-1}

    this.filesService.findMany(filter, sort, this.pageIndex, this.pageSize)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {
        this.fakeFolderInside = false;
        let initFakeFolderFound = false;
        let dataSource = response.data
        let folders = []

        //check if fakeFolder already
        if (dataSource && dataSource.length > 0) {
          folders = dataSource.filter(file => {
            if(file.mimeType === 'application/vnd.folder') {
              return file;
            }
          })
        }

        folders.forEach(folder => {
          if (folder.name === this.fakeFolder) {
            initFakeFolderFound = true;
            if (checkFakeFolder) {
              this.navigateToFolder(folder._id)
            }
          }
        });
        if (checkFakeFolder && initFakeFolderFound) {
          return
        } else if (checkFakeFolder && !initFakeFolderFound) {
          this.fakeFolderInside = true
        }
        if (!this.fakeFolderInside) {
          if(response.data &&this.inputData.acceptMimeType && this.inputData.acceptMimeType.length > 0){
            dataSource = response.data.filter(file => this.inputData.acceptMimeType.includes(file.mimeType));
          }
          if(response.data && this.inputData.acceptTag && this.inputData.acceptTag.length > 0){
            dataSource = response.data.filter(file => {
              if (file.tags?.filter(tag => this.inputData.acceptTag.includes(tag)).length >0 ||
                  file.mimeType === 'application/vnd.folder') {
                return file;
              }
            });
          }

          // add fakeFolder to dataSource
          if (this.initFolderID === folderID && this.fakeFolder && !initFakeFolderFound) {
            dataSource = [{name: this.fakeFolder, mimeType: 'application/vnd.folder', isFolder: true, _id:'fakeFolder'},... dataSource];
          }
          this.dataSource = dataSource;
        }

        this.breadcrumbs = [{label: 'FILES.MY_FILES', id: ''}];

        if(folderID && folderID !== '') {
          this.filesService.findOne(folderID)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(
            response => {
              this.folderID = response.data._id;
              if (response.data.parents) { // Parents
                response.data.parents.forEach(parent => {
                  this.breadcrumbs.push({
                    label: parent.name,
                    id: String(parent._id)
                  });
                });
              }
              // If inside fakeFolder edit breadCrumbs
              if(this.initFolderID === folderID && this.fakeFolderInside) {
                this.breadcrumbs.push({label: response.data.name, id: response.data._id})
                this.breadcrumbs.push({label: this.fakeFolder})
              } else {
                this.breadcrumbs.push({label: response.data.name})
              }
            }
          )
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  get getDisplayedColumns(): string[] {
    return this.displayedColumns;
  }
}
