<div class="content">
  <section class="main-info" [class.full-screen]="!user || !flightsData || !earningsData">
    <h2>{{ (!isMyProfile ? 'USER.TITLE' : 'USER.ME') | translate }}</h2>
    <p class="subtitle">{{ 'USER.SUBTITLE' | translate }}</p>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-icon mat-card-avatar (click)="file.click()" *ngIf="!currentFile" class="header-image-placeholder">person
        </mat-icon>
        <img *ngIf="currentFile" (click)="file.click()" src="{{currentFile}}" alt="avatar" mat-card-avatar>
        <div>
          <mat-card-title>{{ user?.name }} {{ user?.surname }}</mat-card-title>
          <mat-card-subtitle>{{ user?.email }}</mat-card-subtitle>
        </div>
        <div class="edit-button">
          <button class="delete" *ngIf="login.accountType === accountType.SUPERADMIN && false" (click)="removeUser()"
            mat-icon-button>
            <mat-icon color="warn">delete</mat-icon>

          </button>
          <a class="delete"
            *ngIf="[accountType.EDITOR, accountType.PILOT, accountType.CLIENT, accountType.ADMIN].includes(login.accountType) && false"
            href="mailto:fly@fairfleet.com?subject=Please%20Delete%20me&body=Hey%20Operations%20Team,%0D%0Dplease%20delete%20me"
            target="_blank" rel="noopener" mat-menu-item>
            <mat-icon>delete</mat-icon>
          </a>
          <button class="active-button" *ngIf="user?.active && false" (click)="deactivateUser()" mat-stroked-button>
            <mat-icon>block</mat-icon>
            <span>{{ 'USER.DEACTIVATE' | translate }}</span>
          </button>
          <button class="active-button" *ngIf="!user?.active && false" (click)="activateUser()" mat-stroked-button>
            <mat-icon>check_circle</mat-icon>
            <span>{{ 'USER.ACTIVATE' | translate }}</span>
          </button>
          <button *ngIf="!isEditMode" mat-raised-button color="accent" (click)="toggleEditMode()">
            {{ 'EDIT' | translate }}
          </button>
          <div *ngIf="isEditMode" class="edit-actions">

            <button mat-stroked-button color="accent" *ngIf="user" (click)="discardChanges()">
              {{ 'CANCEL' | translate }}
            </button>
            <button mat-raised-button color="accent" [disabled]="formGroup.invalid && formGroup.touched"
              (click)="saveUser()">
              {{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="formGroup" autocomplete="off">
          <input type="file" [disabled]="!isEditMode" (change)="handleFileInput($event.target.files)" #file
            class="file-input">
          <mat-form-field>
            <mat-label>{{ 'NAME' | translate }}</mat-label>
            <input autocomplete="off" formControlName="name" matInput required>
            <mat-icon matSuffix>person</mat-icon>

            <mat-hint *ngIf="!user">{{ 'GIVEN_NAME' | translate }} & {{ 'FAMILY_NAME' | translate }}</mat-hint>
            <mat-error *ngIf="name.errors">{{ getNameError() | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'USER.SURNAME' | translate }}</mat-label>
            <input autocomplete="off" formControlName="surname" matInput required>
            <mat-icon matSuffix>person</mat-icon>

            <mat-hint *ngIf="!user">{{ 'GIVEN_NAME' | translate }} & {{ 'FAMILY_NAME' | translate }}</mat-hint>
            <mat-error *ngIf="name.errors">{{ getNameError() | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'ORGANIZATION' | translate }}</mat-label>
            <input autocomplete="off" formControlName="organization" matInput>
            <mat-icon matSuffix>business</mat-icon>
            <mat-error *ngIf="organization.errors">{{ getOrganizationError() }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'EMAIL' | translate }}</mat-label>
            <input autocomplete="off" formControlName="email" matInput required type="email">

            <a color="accent" (click)="$event.stopPropagation()" draggable="false" href="mailto:" mat-icon-button
              matSuffix rel="nofollow noopener" title="{{ 'COMPOSE_EMAIL' | translate }}">
              <mat-icon>email</mat-icon>
            </a>

            <mat-error *ngIf="email.errors">{{ getEmailError() | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'PHONE' | translate }}</mat-label>
            <input autocomplete="off" formControlName="phone" matInput type="tel">
            <a color="accent" (click)="$event.stopPropagation()" draggable="false" href="tel:" mat-icon-button matSuffix
              rel="nofollow noopener" title="{{ 'CALL_PHONE' | translate }}">
              <mat-icon>phone</mat-icon>
            </a>
            <mat-error *ngIf="phone.errors">{{ getPhoneError() }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'NEW_PASSWORD' | translate }}</mat-label>
            <input autocomplete="new-password" formControlName="password" matInput
              [type]="isPasswordShown ? 'text' : 'password'">
            <button mat-icon-button matSuffix (click)="toggleShowPassword($event)" type="button">
              <mat-icon>{{ isPasswordShown ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="password.errors">{{ getPasswordError() }}</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="user?.accountType !== accountType.PILOT">
            <mat-label>{{ 'ADDRESS' | translate }}</mat-label>
            <input #addressInput autocapitalize="off" autocorrect="off" formControlName="address" matInput
              spellcheck="off">
            <a color="accent" (click)="$event.stopPropagation()" draggable="false"
              href="{{ getAddressURL(addressInput.value) }}" mat-icon-button matSuffix rel="noopener" target="_blank"
              title="{{ 'SHOW_ON_MAP' | translate }}">
              <mat-icon>location_on</mat-icon>
            </a>

            <mat-error *ngIf="address.errors">{{ getAddressError() }}</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="user?.accountType === accountType.CLIENT">
            <mat-label>{{ 'USER.SUBSCRIPTION.TITLE' | translate }}</mat-label>
            <mat-select formControlName="subscription">
              <mat-select-trigger>
                {{ subscriptionType.getLabel(subscription.value) | translate }}
              </mat-select-trigger>
              <mat-option [value]="subscriptionType.NO_SUBSCRIPTION">
                <span>{{ subscriptionType.getLabel(subscriptionType.NO_SUBSCRIPTION) | translate }}</span>
              </mat-option>
              <mat-option [value]="subscriptionType.FF_PLUS">
                <span>{{ subscriptionType.getLabel(subscriptionType.FF_PLUS) | translate }}</span>
              </mat-option>
              <mat-option [value]="subscriptionType.FF_PLUS_BUSINESS">
                <span>{{ subscriptionType.getLabel(subscriptionType.FF_PLUS_BUSINESS) | translate }}</span>
              </mat-option>
              <mat-option [value]="subscriptionType.FF_INSPECT_PRO">
                <span>{{ subscriptionType.getLabel(subscriptionType.FF_INSPECT_PRO) | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(loggedUser?.accountType)">
            <mat-label>{{ 'USER.TYPE' | translate }}</mat-label>
            <mat-select formControlName="type">
              <mat-select-trigger>
                {{ accountType.getLabel(type.value) | translate }}
              </mat-select-trigger>
              <mat-option [value]="accountType.PILOT">
                <span>{{ accountType.getLabel(accountType.PILOT) | translate }}</span>
              </mat-option>
              <mat-option [value]="accountType.CLIENT">
                <span>{{ accountType.getLabel(accountType.CLIENT) | translate }}</span>
              </mat-option>
              <mat-option [value]="accountType.ADMIN">
                <span>{{ accountType.getLabel(accountType.ADMIN) | translate }}</span>
              </mat-option>
              <mat-option [value]="accountType.EDITOR">
                <span>{{ accountType.getLabel(accountType.EDITOR) | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'USER.LANGUAGE.TITLE' | translate }}</mat-label>
            <mat-select formControlName="language">
              <mat-select-trigger>
                {{ getLanguageLabel(language.value || languages[0].code) | translate }}
              </mat-select-trigger>
              <mat-option *ngFor="let language of languages" [value]="language.code">
                {{ language.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>
  </section>
  <section class="stats-info" *ngIf="user && flightsData && earningsData">
    <h2>{{ 'USER.STATS_TITLE' | translate }}</h2>
    <p class="subtitle">{{ 'USER.STATS_SUBTITLE' | translate }}</p>
    <div class="stats-container">
      <app-flight-stats [inputDataSetter]="flightsData"></app-flight-stats>
      <app-earnings-stats [inputDataSetter]="earningsData"></app-earnings-stats>
    </div>
  </section>
  <section class="pre-product" *ngIf="user?._id && user?.accountType === accountType.PILOT">
    <h2>{{ 'USER.SPECIALIZATION' | translate }}</h2>
    <p class="subtitle">{{ 'USER.SPECIALIZATION_SUBTITLE' | translate }}</p>
    <div class="specialties-container">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-subtitle>{{ 'USER.PRE_PRODUCT' | translate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-select (valueChange)="addPreProduct($event)" [placeholder]="'USER.SELECT_SKILL' | translate">
            <mat-option [value]="option" *ngFor="let option of preProductOptions">
              {{ option.name | translate }}
            </mat-option>
          </mat-select>

          <ul class="skill-list" *ngIf="preProduct.value.length">
            <li *ngFor="let option of preProduct.value">
              <p>
                {{ option.name | translate }}
                <span class="description">{{ option.description | translate }}</span>
              </p>
              <mat-icon (click)="deletePreProduct(option)">close</mat-icon>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-subtitle>{{ 'USER.POST_PRODUCT' | translate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-select (valueChange)="addPostProduct($event)" [placeholder]="'USER.SELECT_SKILL' | translate">
            <mat-option [value]="option" *ngFor="let option of postProductOptions">
              {{ option.name | translate }}
            </mat-option>
          </mat-select>

          <ul class="skill-list" *ngIf="postProduct.value.length">
            <li *ngFor="let option of postProduct.value">
              <p>
                {{ option.name | translate }}
                <span class="description">{{ option.description | translate }}</span>
              </p>
              <mat-icon (click)="deletePostProduct(option)">close</mat-icon>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </div>
  </section>
  <section class="documents" *ngIf="user?._id && user?.accountType === accountType.PILOT">
    <h2>{{ 'USER.DOCUMENTS_TITLE' | translate }}</h2>
    <p class="subtitle">{{ 'USER.DOCUMENTS_SUBTITLE' | translate }}</p>
    <app-user-documents [documentsSetter]="documents.value" (documentsChange)="documentsHandler($event)">
    </app-user-documents>
  </section>
</div>
<app-spinner [fetching]="fetching"></app-spinner>
