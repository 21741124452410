<div class="upload-container" [dir]="dir">
  <mat-list>
    <mat-list-item
        *ngFor="let item of queueItems"
        matTooltip="{{
          item.loaded | size }}{{
          item.loaded >= 0 && item.size >= 0 ? '/' : '' }}{{
          item.size | size }}{{
          item.loaded >= 0 || item.size >= 0 ? ' | ' : '' }}{{
          item.loaded >= 0 ? item.percentDone() + '%' : '' }}{{
          item.loaded >= 0 && item.elapsedTime ? ' | ' : '' }}{{
          item.elapsedTime | date:'mm:ss' }}{{
          item.estimatedTime ? '/-' + (item.estimatedTime | date:'mm:ss') : '' }}"
        matTooltipPosition="right">

      <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>

      <h3 mat-line title="{{ item.label | translate }}">
        <ng-container *ngIf="item.label">
          {{ item.label | translate }}
        </ng-container>
        <ng-container *ngIf="item.icon === 'archive'">
          {{ 'UPLOAD.ARCHIVE' | translate }}
        </ng-container>
        <ng-container *ngIf="item.icon === 'photo_library'">
          {{ 'UPLOAD.CONVERT' | translate }}
        </ng-container>
      </h3>
      <p *ngIf="!item.inProgress && !item.stopped" mat-line>{{ 'UPLOAD.QUEUED' | translate }}</p>

      <button
          *ngIf="item.inProgress"
          (click)="item.stopUpload(); nextUpload()"
          mat-icon-button>
        <mat-progress-spinner
            class="queue-progress-spinner"
            color="accent"
            diameter="32"
            [mode]="item.percentDone() >= 1 && item.percentDone() <= 99 ? 'determinate' : 'indeterminate'"
            strokeWidth="2"
            [value]="item.percentDone()"></mat-progress-spinner>
        <mat-icon>stop</mat-icon>
      </button>

      <button
          *ngIf="!item.inProgress"
          (click)="removeQueueItem(item)"
          mat-icon-button >
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>
