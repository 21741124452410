<mat-tab-group
    #matTabGroup
    mat-stretch-tabs
    [(selectedIndex)]="tabIndex"
    animationDuration="0ms"
    [class.dark]="this.theme && this.theme.includes('dark')">

  <ng-form [formGroup]="formSettings">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>map</mat-icon>
        <p class="mat-tab-label-text">
          {{ 'SIDEBAR.VIEWER.MAP_SETTINGS' | translate }}
        </p>
      </ng-template>
      <div class="mat-tab-content borderless">
        <div class="userSection">
          <div class="action-headline">
            <div class="mat-form-field-wrapper">
              <mat-slide-toggle
                  formControlName="showWater"
                  (change)="toggleShowWater()">
                </mat-slide-toggle>
            </div>
            <span>Display Water Level</span>
            <mat-icon aria-label="info">info</mat-icon>
          </div>
          <div class="waterBox" *ngIf="showWater.value">
            <div class="row" *ngIf="viewerFileModel?.orderID === '14411'">
              <mat-form-field >
                <mat-label>Water Depth(m) at</mat-label>
                <mat-select [formControl]="waterDepth" (selectionChange)="onWaterDepthChange($event.value)">
                  <mat-option value="10">10-year return period</mat-option>
                  <mat-option value="100">100-year return period</mat-option>
                  <mat-option value="500">500-year return period</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row extra-bottom-margin" *ngIf="waterDepth.value && viewerFileModel?.orderID === '14411'">
              <button class="selection-button" mat-button color="accent" [class.active]="yearActiveButton === '2030'" (click)="setYearActiveButton('2030')">2030</button>
              <button class="selection-button" mat-button color="accent" [class.active]="yearActiveButton === '2040'" (click)="setYearActiveButton('2040')">2040</button>
              <button class="selection-button" mat-button color="accent" [class.active]="yearActiveButton === '2050'" (click)="setYearActiveButton('2050')">2050</button>
            </div>
            <div class="row">
              <span class="bold-text">Water Level Increase Step Size (meters):</span>
            </div>
            <div class="row no-bottom-margin">
              <button class="step-button" mat-button color="accent" [class.active]="stepActiveButton === '0.01'" (click)="setStepActiveButton('0.01')">0.01</button>
              <button class="step-button" mat-button color="accent" [class.active]="stepActiveButton === '0.10'" (click)="setStepActiveButton('0.10')">0.10</button>
              <button class="step-button" mat-button color="accent" [class.active]="stepActiveButton === '1.00'" (click)="setStepActiveButton('1.00')">1.00</button>
              <button class="step-button" mat-button color="accent" [class.active]="stepActiveButton === '10.0'" (click)="setStepActiveButton('10.0')">10.0</button>
            </div>
            <div class="row extra-bottom-margin">
              <div class="slider">
                <mat-icon (click)="setWaterHeightAdd(-stepSize)">remove</mat-icon>
                <mat-slider
                    formControlName="waterheight"
                    thumbLabel="false"
                    (input)="setWaterHeight($event.value)"
                    [max]="demFileModel?.dem?.max"
                    [min]="demFileModel?.dem?.min"
                    [step]="0.1">
                </mat-slider>
                <mat-icon (click)="setWaterHeightAdd(+stepSize)">add</mat-icon>
              </div>
            </div>
            <div class="row extra-bottom-margin">
              <div class="left-box">
                <div class="row">
                  <span class="bold-text">{{ 'WATER.ABOVE_NORMAL_ZERO' | translate }}:</span>
                  <span class="bold-text blue">{{ waterHeight.toFixed(2) }}  m</span>
                </div>
                <div class="row" *ngIf="referenceValues && referenceValues.heightLocalWater">
                  <span class="bold-text">{{ 'WATER.ABOVE_SET_ZERO' | translate }}:</span>
                  <span class="bold-text blue">{{ waterHeightLocal?.toFixed(2) }} m</span>
                </div>
              </div>
              <div class="setNull">
                <button
                    class="set-zero-button"
                    mat-raised-button
                    color="accent"
                    (click)="pickReferenceHeightLocalWater()">
                  {{ 'SIDEBAR.VIEWER.SET_ZERO' | translate }}
                </button>
              </div>
            </div>
            <div class="action-headline">
              <div class="mat-form-field-wrapper">
                <mat-slide-toggle
                  formControlName="waterStyleBoolean"
                  (change)="toggleWaterStyle()">
                  </mat-slide-toggle>
              </div>
              <span>{{ 'SIDEBAR.VIEWER.COLOR_RAMP' | translate }}</span>
            </div>
            <div class="info-box extra-bottom-margin" *ngIf="waterStyleBoolean.value && view === '2d' && waterStyle">
              <mat-icon>info</mat-icon>
              <div class="body">
                <span class="title"> Color Scheme:</span>
                <div class="content">
                  <div class="color">
                    <div class="square" [ngStyle]="{'background-color': waterStyle.steps[0].color}" (click)="toggleBoolean(waterStyle.steps[0])"></div>
                    <span class="range">0.00 m - {{(waterStyle.steps[1].value - 0.01).toFixed(2)}} m</span>
                  </div>
                  <div class="color">
                    <div class="square" [ngStyle]="{'background-color': waterStyle.steps[1].color}" (click)="toggleBoolean(waterStyle.steps[1])"></div>
                    <span class="range">{{waterStyle.steps[1].value.toFixed(2)}} m - {{waterStyle.steps[2].value.toFixed(2)}} m</span>
                  </div>
                  <div class="color">
                    <div class="square" [ngStyle]="{'background-color': waterStyle.steps[2].color}" (click)="toggleBoolean(waterStyle.steps[2])"></div>
                    <span class="range">> {{waterStyle.steps[2].value.toFixed(2)}} m</span>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let step of waterStyle.steps; let i = index">
              <span *ngIf="step.edit"
                    (cpInputChange)="colorUpdate()"
                    (cpSliderDragEnd)="colorUpdate()"
                    (colorPickerSelect)="toggleBoolean(step)"
                    [(colorPicker)]="step.color"
                    [cpAlphaChannel]="'disabled'"
                    [cpCancelButton]="false"
                    [cpDialogDisplay]="'inline'"
                    [cpOKButton]="true"
                    [cpOKButtonClass]=" 'mat-focus-indicator mat-raised-button mat-button-base mat-accent fullWidth'"
                    [cpToggle]="true"></span>
            </ng-container>

            <div class="action-headline" *ngIf="viewerFileModel?.orderID === '14411'">
              <div class="mat-form-field-wrapper">
                <mat-slide-toggle
                  formControlName="displaySpotsBoolean"
                  (change)="displaySpots()">
                  </mat-slide-toggle>
              </div>
              <span>Display Selected Areas:</span>
            </div>

          </div>

          <!-- Pano Perspektive -- >
          <div *ngIf="view === '3d'"
              class="perspective">
            {{ 'SIDEBAR.SETTINGS.PANO.INIT_PERSPECTIVE' | translate }}
            <div class="minor">
            <button mat-raised-button color="accent" (click)="save3dView()">
              <mat-icon class="white rtl-mirror">push_pin</mat-icon>
              {{ 'SIDEBAR.SETTINGS.PANO.SET' | translate }}
            </button>
            <button
                class="right"
                color="accent"
                mat-raised-button
                (click)="set3dView()">
            <mat-icon class="white rtl-mirror">preview</mat-icon> {{ 'SIDEBAR.SETTINGS.PANO.INGEST' | translate }}
            </button>
            </div>
          </div>-->

        </div>


        <div class="adminSection" *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType)">
          <p class="interaction" (click)="toggleAdmin()">
            <mat-icon class="rtl-mirror">description</mat-icon>
            adminSettings
            <mat-icon class="rtl-mirror right">{{expandAdmin ? 'expand_less' : 'expand_more'}}</mat-icon>
          </p>
          <div *ngIf="expandAdmin">

            <!-- setReferenceHeight2D -->
            <div class="hiddenInput"  *ngIf="view === '2d'">
              <div>
                referenceHeight2D:
                <mat-icon class="rtl-mirror"
                    (click)="pickReferenceHeight2D()">colorize</mat-icon>
              </div>

              <mat-form-field [class.focus]="edit==='referenceHeight2D'">
                <input matInput
                    (focus)="setState('referenceHeight2D',true)"
                    formControlName="referenceHeight2D">
              </mat-form-field>
              <div *ngIf="edit==='referenceHeight2D'"
                  class="interaction">
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    [disabled]="referenceHeight2D.errors"
                    (click)="setReferenceHeight2D()">{{ 'SAVE' | translate }}</button>
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    (click)="setState('referenceHeight2D', false)">{{ 'CANCEL' | translate }}</button>
              </div>
            </div>

            <!-- DEM -->
            <div class="hiddenInput" *ngIf="view === '2d'">
              <div>
                DEM:
                <mat-icon class="rtl-mirror"
                    (click)="pickDem()">colorize</mat-icon>
              </div>

              <mat-form-field [class.focus]="edit==='dem'">
                <input matInput
                    (focus)="setState('dem',true)"
                    formControlName="dem">
                    <mat-error *ngIf="dem.errors?.minlength">{{ 'VALIDATION.MIN_LENGTH' | translate: { count: dem.errors?.minlength?.requiredLength } }}</mat-error>
                    <mat-error *ngIf="dem.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: dem.errors?.maxlength?.requiredLength } }}</mat-error>
              </mat-form-field>
              <div *ngIf="edit==='dem'"
                  class="interaction">
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    [disabled]="dem.errors"
                    (click)="setDem()">{{ 'SAVE' | translate }}</button>
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    (click)="setState('dem', false)">{{ 'CANCEL' | translate }}</button>
              </div>
            </div>

            <!-- Orthomosaic -->
            <div class="hiddenInput" *ngIf="view === '2d'">
              <div>
                {{ 'SIDEBAR.SETTINGS.INFO.ORTHOMOSAIC' | translate }}:
                <mat-icon class="rtl-mirror"
                    (click)="pickOrthomosaic()">colorize</mat-icon>
              </div>

              <mat-form-field [class.focus]="edit==='orthomosaic'">
                <input matInput
                    (focus)="setState('orthomosaic',true)"
                    formControlName="orthomosaic">
                    <mat-error *ngIf="orthomosaic.errors?.minlength">{{ 'VALIDATION.MIN_LENGTH' | translate: { count: orthomosaic.errors?.minlength?.requiredLength } }}</mat-error>
                    <mat-error *ngIf="orthomosaic.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: orthomosaic.errors?.maxlength?.requiredLength } }}</mat-error>
              </mat-form-field>
              <div *ngIf="edit==='orthomosaic'"
                  class="interaction">
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    [disabled]="orthomosaic.errors"
                    (click)="setOrthomosaic()">{{ 'SAVE' | translate }}</button>
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    (click)="setState('orthomosaic', false)">{{ 'CANCEL' | translate }}</button>
              </div>
            </div>

            <!-- DEM -->
            <div class="hiddenInput" *ngIf="view === '3d'">
              <div>
                pickTiles3d:
                <mat-icon class="rtl-mirror"
                    (click)="pickTiles3d()">colorize</mat-icon>
              </div>

              <mat-form-field [class.focus]="edit==='tiles3d'">
                <input matInput
                    (focus)="setState('tiles3d',true)"
                    formControlName="tiles3d">
                    <mat-error *ngIf="tiles3d.errors?.minlength">{{ 'VALIDATION.MIN_LENGTH' | translate: { count: tiles3d.errors?.minlength?.requiredLength } }}</mat-error>
                    <mat-error *ngIf="tiles3d.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: tiles3d.errors?.maxlength?.requiredLength } }}</mat-error>
              </mat-form-field>
              <div *ngIf="edit==='tiles3d'"
                  class="interaction">
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    [disabled]="tiles3d.errors"
                    (click)="setTiles3d()">{{ 'SAVE' | translate }}</button>
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    (click)="setState('dem', false)">{{ 'CANCEL' | translate }}</button>
              </div>
            </div>

            <!-- referenceHeight3D -->
            <div class="hiddenInput" *ngIf="view === '3d'">
              <div>
                referenceHeight3D:
                <mat-icon class="rtl-mirror"
                    (click)="pickReferenceHeight3D()">colorize</mat-icon>
              </div>

              <mat-form-field [class.focus]="edit==='referenceHeight3D'">
                <input matInput
                    (focus)="setState('referenceHeight3D',true)"
                    formControlName="referenceHeight3D">
              </mat-form-field>
              <div *ngIf="edit==='referenceHeight3D'"
                  class="interaction">
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    [disabled]="referenceHeight3D.errors"
                    (click)="setReferenceHeight3D()">{{ 'SAVE' | translate }}</button>
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    (click)="setState('referenceHeight3D', false)">{{ 'CANCEL' | translate }}</button>
              </div>
            </div>

            <div class="hiddenInput">
              <div>
                imageFiles:
              </div>

              <mat-form-field [class.focus]="edit==='imagesFolder'">

                <input matInput
                    (focus)="setState('imagesFolder',true)"
                    formControlName="imagesFolder">
              </mat-form-field>
              <div *ngIf="edit==='imagesFolder'"
                  class="interaction">

                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    [disabled]="imagesFolder.errors"
                    (click)="setImagesFolder()">{{ 'SAVE' | translate }}</button>
                <button
                    mat-button
                    color="accent"
                    class="confirm"
                    (click)="setState('imagesFolder', false)">{{ 'CANCEL' | translate }}</button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </mat-tab>
  </ng-form>
</mat-tab-group>
