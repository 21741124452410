<h2 mat-dialog-title>{{ 'FILES.CREATE_NEW_FOLDER' | translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" novalidate>
    <mat-form-field>
      <mat-label>{{ 'NAME' | translate }}</mat-label>
      <input
          id="folder-name"
          matInput
          formControlName="name"
          (keyup.enter)="dialogRef.close({name: name.value})"
          [matAutocomplete]="autoGroup">
          <mat-icon matSuffix>create_new_folder</mat-icon>
        <mat-autocomplete #autoGroup="matAutocomplete">
          <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.category">
            <mat-option *ngFor="let name of group.names" [value]="name">
              {{ name }}
            </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-error *ngIf="name.errors?.pattern">{{ 'VALIDATION.USE_SAFE_CHARACTERS' | translate }}</mat-error>
      <mat-error *ngIf="name.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: name.errors?.maxlength?.requiredLength } }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      type="button">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      #create
      color="accent"
      [disabled]="!formGroup.valid"
      [mat-dialog-close]="{ name: name.value }"
      mat-raised-button
      type="button">{{ 'OK' | translate | uppercase }}</button>
</div>
