import { NgModule } from '@angular/core';

import { SafePipe } from './safe.pipe';
import { SizePipe } from './size.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { AmountPipe } from './amount.pipe'
import { FormatDatePipe } from './formatDate.pipe'
import { ExpireInPipe } from './expireIn.pipe';
import { AddDaysPipe } from './addDays.pipe';
import { FilterTasksByStatusPipe } from './filterTasksByStatus.pipe';
import { DaysLeftPipe } from './daysLeft.pipe';
import { IsSameDayPipe } from './is-same-day.pipe';

@NgModule({
  declarations: [
    SafePipe,
    SizePipe,
    ShortNamePipe,
    AmountPipe,
    FormatDatePipe,
    ExpireInPipe,
    AddDaysPipe,
    FilterTasksByStatusPipe,
    DaysLeftPipe,
    IsSameDayPipe,
  ],
  exports: [
    SafePipe,
    SizePipe,
    ShortNamePipe,
    AmountPipe,
    FormatDatePipe,
    ExpireInPipe,
    AddDaysPipe,
    FilterTasksByStatusPipe,
    DaysLeftPipe,
    IsSameDayPipe,
  ]
})
export class PipesModule { }
