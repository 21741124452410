import { ResizeGrip } from './ResizeGrip';

export class RectangularGrips {
  eResize: ResizeGrip;
  neResize: ResizeGrip;
  nResize: ResizeGrip;
  nwResize: ResizeGrip;
  seResize: ResizeGrip;
  sResize: ResizeGrip;
  swResize: ResizeGrip;
  wResize: ResizeGrip;

  findGripByVisual = (gripVisual: SVGGraphicsElement) => {
    switch (gripVisual) {
      case this.eResize.visual: return this.eResize;
      case this.neResize.visual: return this.neResize;
      case this.nResize.visual: return this.nResize;
      case this.nwResize.visual: return this.nwResize;
      case this.seResize.visual: return this.seResize;
      case this.sResize.visual: return this.sResize;
      case this.swResize.visual: return this.swResize;
      case this.wResize.visual: return this.wResize;
      default:
    }
  };
}
