export const SITE_TYPES = [
  'building',
  'telecommunication-tower',
  'bridge',
  'electricity-pylon',
]

export const SITE_TYPE_LABELS = {
    'building': 'SITE.MODAL.BUILDING',
  'telecommunication-tower': 'SITE.MODAL.TOWER',
  'bridge': 'SITE.MODAL.BRIDGE',
  'electricity-pylon': 'SITE.MODAL.PYLON'
}

export const SITE_COLOR_BY_SEVERITY = ['#8EBD2A','#EBAF14','#c91c1c'] // i==0 means low // i==1 means medium // i==2 means critical 

export interface Portfolio {
    name: string,
    userId: string,
    _id: string
}

export interface PortfolioStats {
    averageScore: number,
    sitesStats: {
        critical: number,
        important: number,
        minor: number,
        total: number,
        scores: Record<string, number>
    }
}

export interface Site {
    address: string,
    name: string,
    portfolioId: string,
    type: 'building' | 'telecommunication-tower' | 'bridge' | 'electricity-pylon',
    userId: string,
    _id: string
}

export interface SiteStats {
    annotationStats: {
        advisory: number,
        low: number,
        high: number,
        medium: number,
        unknown: number,
        urgent: number,
        total: number,
    },
    featureStats: {
        [key: string]: number
    },
    score: number
}

export interface SiteOrder {
    legacyId: number,
    roles: {
        userId: string,
        role: number
    }[],
    siteId: string,
    _id: string
}

export interface ResponseData<T> {
    data: T
}
