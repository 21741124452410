import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-bentley-dialog',
  templateUrl: './bentley-dialog.component.html',
  styleUrls: ['./bentley-dialog.component.scss']
})
export class BentleyDialogComponent {
  formGroup: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<BentleyDialogComponent>,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      bentleyProjectID: new FormControl('', [
        Validators.required,
        Validators.minLength(36),
        Validators.maxLength(36),
        // Safe Characters https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
        Validators.pattern(/^[^:\\|\/{}<>´`"~^%#]+$/)
      ])
    });
  }

  get bentleyProjectID(): AbstractControl { return this.formGroup.get('bentleyProjectID'); }
}
