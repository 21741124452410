import { Coordinate } from 'ol/coordinate';
import { Component, Inject, Input, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,  NavigationEnd,  Router } from '@angular/router';
import { skip, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Annotation, AnnotationsStateDimension, DrawType } from './annotation.model';
import { FileModel } from '../../file.model';
import { FilesService } from '../../../files.service';
import { Login } from '../../../../login/login.model';
import { OlImageService, SeverityToColor } from '../../../../../shared/ol-image/ol-image.service'
import { PermissionRole } from '../../permission.model';
import { SidebarService } from '../sidebar.service';
import { ThemeService } from './../../../../../shared/theme/theme.service';
import { AnnotationTypesObj, SortArray } from './../../../../../shared/annotations/annotation-types';
import { InspectionService } from './../../../inspection/inspection.service';
import { AnnotationsService } from './annotations.service';
import { AnnotationsOverviewDialogComponent } from 'src/app/shared/annotations-overview-dialog/annotations-overview-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';
import { TaskDialogComponent } from 'src/app/shared/task-dialog/task-dialog.component';
import { Task } from 'src/app/pages/tasks/task/task.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-sidebar-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.scss']
})
export class AnnotationsComponent implements OnChanges, OnDestroy {
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  formComments: FormGroup;
  currentLang: string;
  @ViewChild('annotationsBody') annotationsBody: ElementRef;
  @Input() fileModel: FileModel;
  stateForm: DrawType = 'Box';
  drawed: boolean = false;
  editing: number = null;
  @Input() login: Login;
  @Input() isSiteURL: boolean;
  permissionRole = PermissionRole;
  stateNewComment: boolean;
  private ngDestroy$ = new Subject();
  annotationTypes = {general: []};
  annotationTypesGeneral: any;
  annotationTypesObj = AnnotationTypesObj
  theme: string;
  selectionStatus: boolean;
  private modal: MatDialogRef<AnnotationsOverviewDialogComponent, void> | null = null;


  constructor(
    public filesService: FilesService,
    public inspectionService: InspectionService,
    private annotationsService: AnnotationsService,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    private olImageService: OlImageService,
    private route: ActivatedRoute,
    private router: Router,
    private sidebarService: SidebarService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private readonly dialog: MatDialog,
    public permissionsService: PermissionsService,
    private dialogService: DialogService
  ) {
    // get language for sortAnnotation by language
    this.currentLang = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: any) => {
      this.currentLang = event.lang;
      this.sortAnnotationTypes();
    });

    // make AnnotationTypesObj to AnnotationTypes Obj with List
    this.inspectionService.inspectionFile$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (Object.keys(data).length !== 0) {
        // setup inspection Types based on AnnotationTypeKeys
        const inspectionTypes = ['general']
        if (data.tags) {
          data.tags.forEach(tag => {
            if(Object.keys(AnnotationTypesObj).includes(tag)) {
              inspectionTypes.push(tag)
            }
          })
          for (const [key, value] of Object.entries(AnnotationTypesObj)) {
            if (inspectionTypes.includes(key)) {
              this.annotationTypes[key] = []
              for (const [key2, value2] of Object.entries(value)) {
                const annotationType = {  ... value2  };
                annotationType["id"] = key2;
                annotationType['completed'] = false;
                this.annotationTypes[key].push(annotationType);
              }
            }
          }
          this.sortAnnotationTypes();
        }
      }
    });

    this.annotationsService.addNewAnnotation$
    .pipe(takeUntil(this.ngDestroy$),skip(1))
    .subscribe(status => {
      this.setNewComment(status)
    });

    this.annotationsService.temperatureAnno$
    .pipe(takeUntil(this.ngDestroy$),skip(1))
    .subscribe(tempAnno => {
      setTimeout(() => {
        this.setNewComment(true, tempAnno[0])
      }, 100);
    });

    this.annotationsService.closeSetNew$
    .pipe(takeUntil(this.ngDestroy$),skip(1))
    .subscribe(status => {
      this.stateNewComment=status
    });

    this.olImageService.finished$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.drawed = data
    });
    this.olImageService.coords$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (data[0].length >0) {
        this.coordinates.setValue(data)
      }
    })
    this.formComments = this.formBuilder.group({
      comment: new FormControl({ value: '' }, []),
      coordinates : new FormControl({ value: '' }, [
        Validators.required
      ]),
      feature: new FormControl({ value: '' }, [
        Validators.required
      ]),
      stateDimension: new FormControl({ value: '' }, [
        Validators.required
      ]),
      maximum: new FormControl({ value: '' }, []),
      minimum: new FormControl({ value: '' }, []),
      average: new FormControl({ value: '' }, []),
      delta: new FormControl({ value: '' }, []),

    })

    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  ngOnDestroy(): void {
    this.olImageService.setCoords([[]]);
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  sortAnnotationTypes(): void {
    for (const [key, value] of Object.entries(this.annotationTypes)) {
      this.annotationTypes[key] = SortArray(value, this.currentLang);
    }

    // Remove general from this.annotationTypes to Sort 
    if (this.annotationTypes['general']) {
      this.annotationTypesGeneral = this.annotationTypes['general'];
      delete this.annotationTypes['general'];
    } else {
      this.annotationTypesGeneral = SortArray(this.annotationTypesGeneral, this.currentLang);
    }
    
  }

  get comment(): AbstractControl { return this.formComments.get('comment'); }
  get average(): AbstractControl { return this.formComments.get('average'); }
  get minimum(): AbstractControl { return this.formComments.get('minimum'); }
  get maximum(): AbstractControl { return this.formComments.get('maximum'); }
  get delta(): AbstractControl { return this.formComments.get('delta'); }
  get coordinates(): AbstractControl { return this.formComments.get('coordinates'); }
  get feature(): AbstractControl { return this.formComments.get('feature'); }
  get stateDimension(): AbstractControl { return this.formComments.get('stateDimension'); }
  get checkSend(): boolean {
    return !( this.drawed && this.stateDimension.value !== null && this.feature.value !== null );
  }

  ngOnChanges(): void {
    this.setNewComment(false);
  }

  generatePDF(): void {
    if (this.isSiteURL) {
      this.router.navigate(
        ['', { outlets: { primary: 'portfolio/PDFeditor/'+this.fileModel?.folderID, detail: null } }], {
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate(
        ['', { outlets: { primary: 'PDFeditor/'+this.fileModel?.folderID, detail: null } }], {
        queryParamsHandling: 'merge'
      });
    }
  }

  getColor(stateDimension): string {
    return AnnotationsStateDimension.getColor(stateDimension);
  }

  CreateTask(annotation) {
    const passedAnnotation = {
      ...annotation,
      file: {
        _id: this.fileModel._id,
        webContentLink: this.fileModel.webContentLink,
        webViewLink: this.fileModel.webViewLink,
        thumbnailLink: this.fileModel.thumbnailLink,
        width: this.fileModel.width,
        height: this.fileModel.height
      }
    }
    const taskData: Task = {
      description: annotation.comment,
      linkedResources: [{
        type: 'annotation',
        resource: passedAnnotation,
      }]
    };

    const dialogRef = this.dialog.open(TaskDialogComponent, {
      data: {
        task: taskData,
        action: 'create',
        folderId: this.fileModel.folderID,
        currentLang: this.currentLang
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        annotation.tasks = (annotation.tasks ?? []).concat(result);
        this.dialogService.showDialog('Task Created Successfully', null, null, null, null, true);
      } else {
      }
    });
  }

  openTaskPopup(task?: Task): void {
    const dialogRef = this.dialog.open(TaskDialogComponent, {
      data: {
        task: task || null,
        action: 'update',
        folderId: this.fileModel.folderID,
        currentLang: this.currentLang
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.updateTaskInsideAllAnnotations(result.data) 
      }
    });
  }

  updateTaskInsideAllAnnotations(newTask: Task) {
    this.fileModel.annotations.forEach((annotation)=>{
      annotation.tasks = annotation.tasks?.map(t => t._id === newTask._id ? newTask : t);
    })
  }

  toggleAnnotationDialog() {
    this.modal = this.dialog.open<AnnotationsOverviewDialogComponent>(AnnotationsOverviewDialogComponent, {
      data: {
        currentLang: this.currentLang,
        folderID: this.fileModel.folderID
      },
      width: '85vw',
      height: '85vh',
      panelClass: 'all-annotations',
      maxWidth: '1300px',
      maxHeight: '1300px',
      closeOnNavigation: true
    });
  }

  async editAnnotation(i: number): Promise<void> {
    const newAnnotation = {
      "fileID": this.fileModel._id,
      "comment": this.comment.value,
      "coordinates": this.coordinates.value as Array<Array<Coordinate>>,
      "feature": this.feature.value,
      "stateDimension": this.stateDimension.value,
      "stateForm": this.stateForm,
      "type": 1,
      "minimumTemperature":this.selectionStatus || !this.minimum.value ? null :this.extractFloatFromString(this.minimum.value),
      "maximumTemperature": this.selectionStatus || !this.maximum.value? null : this.extractFloatFromString(this.maximum.value),
      "deltaTemperature": this.selectionStatus || !this.delta.value? null :this.extractFloatFromString(this.delta.value),
      "averageTemperature":this.selectionStatus || !this.average.value? null: this.extractFloatFromString(this.average.value),
    }
    this.annotationsService.updateOne(String(this.fileModel.annotations[i]._id), this.fileModel.annotations[i], newAnnotation)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      (response) => {
        this.fileModel.annotations[i] = response.body.data.annotation
        this.annotationsService.setAnnotations(this.fileModel.annotations)
        this.fileModel.annotationStats=this.annotationsService.updateAnnotationStats(this.fileModel)
        this.fileModel.typesStats = this.annotationsService.updateTypesStats(this.fileModel)
    },
    error => {
      this.fetchingChange$.next(false);
      // this.dialogService.showDialog('FILE.DELETE', error.status, error.url, error.error);
    });

    if (this.fileModel.thermalSubFileModel){
      const thermalAnnos = await this.annotationsService.getThermalAnnotations(this.fileModel.annotations)
      setTimeout(() => {
        if (thermalAnnos){
          const correspondingThermalAnnotation = thermalAnnos.find(Anno => Anno.annoID==this.fileModel.annotations[i].annoID)
          const j = thermalAnnos.indexOf(correspondingThermalAnnotation)
          const scalingFactor = this.fileModel.thermalSubFileModel.scalingFactor
          const thermalWidth = this.fileModel.thermalSubFileModel.width
          const thermalHeight = this.fileModel.thermalSubFileModel.height
          const xTrans = this.fileModel.thermalSubFileModel.xTrans
          const yTrans = this.fileModel.thermalSubFileModel.yTrans
  
          const moveX = (this.fileModel.width-thermalWidth*scalingFactor)/2 + xTrans * scalingFactor
          const moveY = (this.fileModel.height-thermalHeight*scalingFactor)/2 + yTrans * scalingFactor
          const newThermalAnnotation = {
            "fileID": this.fileModel.thermalSubFileModel._id,
            "comment": this.comment.value,
            "coordinates": this.adjustCoortinates(this.coordinates.value,scalingFactor,-moveX,-moveY) as Array<Array<Coordinate>>,
            "feature": this.feature.value,
            "stateDimension": this.stateDimension.value,
            "stateForm": this.stateForm,
            "type": 1,
            "minimumTemperature":this.selectionStatus || !this.minimum.value ? null :this.extractFloatFromString(this.minimum.value),
            "maximumTemperature": this.selectionStatus || !this.maximum.value? null : this.extractFloatFromString(this.maximum.value),
            "deltaTemperature": this.selectionStatus || !this.delta.value? null :this.extractFloatFromString(this.delta.value),
            "averageTemperature":this.selectionStatus || !this.average.value? null: this.extractFloatFromString(this.average.value),
          }
          const thermalModel = this.filesService.allData.find(file => file._id == this.fileModel.thermalSubFileModel._id)
          this.annotationsService.updateOne(String(correspondingThermalAnnotation._id), correspondingThermalAnnotation, newThermalAnnotation)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(
            (response) => {
            thermalModel.annotations[j] = response.body.data
            this.annotationsService.setThermalAnnotations(thermalModel.annotations)
            thermalModel.annotationStats=this.annotationsService.updateAnnotationStats(thermalModel)
            thermalModel.typesStats = this.annotationsService.updateTypesStats(thermalModel)
            },
          error => {
            this.fetchingChange$.next(false);
          });
        }
      }, 500);
    }
    


    if (this.fileModel.master){
      const MasterModel = await  this.filesService.allData.find(file => file._id == this.filesService.masterFileModel._id)
      setTimeout(() => {
        const correspondingMasterAnnotation = MasterModel?.annotations?.find(Anno => Anno.annoID==this.fileModel.annotations[i].annoID)
        if (correspondingMasterAnnotation){
          const j = MasterModel.annotations.indexOf(correspondingMasterAnnotation)
          const scalingFactor = this.filesService.masterFileModel.thermalSubFileModel.scalingFactor
          const masterWidth = this.filesService.masterFileModel.width;
          const masterHeight = this.filesService.masterFileModel.height;
          const xTrans = this.filesService.masterFileModel.thermalSubFileModel.xTrans;
          const yTrans = this.filesService.masterFileModel.thermalSubFileModel.yTrans;
    
          const moveX = (masterWidth/scalingFactor-this.fileModel.width)/2 + xTrans
          const moveY = (masterHeight/scalingFactor-this.fileModel.height)/2 + yTrans
          const newMasterAnnotation = {
            "fileID": this.filesService.masterFileModel._id,
            "comment": this.comment.value,
            "coordinates": this.adjustCoortinates(this.coordinates.value,1/this.filesService.masterFileModel.thermalSubFileModel.scalingFactor,moveX,moveY) as Array<Array<Coordinate>>,
            "feature": this.feature.value,
            "stateDimension": this.stateDimension.value,
            "stateForm": this.stateForm,
            "type": 1,
            "minimumTemperature":this.selectionStatus || !this.minimum.value ? null :this.extractFloatFromString(this.minimum.value),
            "maximumTemperature": this.selectionStatus || !this.maximum.value? null : this.extractFloatFromString(this.maximum.value),
            "deltaTemperature": this.selectionStatus || !this.delta.value? null :this.extractFloatFromString(this.delta.value),
            "averageTemperature":this.selectionStatus || !this.average.value? null: this.extractFloatFromString(this.average.value),
          }
          this.annotationsService.updateOne(String(correspondingMasterAnnotation._id), correspondingMasterAnnotation, newMasterAnnotation)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(
            (response) => {
              MasterModel.annotations[j] = response.body.data
              this.annotationsService.setMasterAnnotations(MasterModel.annotations)
              MasterModel.annotationStats=this.annotationsService.updateAnnotationStats(MasterModel)
              MasterModel.typesStats = this.annotationsService.updateTypesStats(MasterModel)
            },
          error => {
            this.fetchingChange$.next(false);
          });
        }

      }, 500);
    }
  }

  combinedActions(){
    this.closeAnnotationInput()
    this.olImageService.setStatus("remove draft")
  }

  sendComment(): void {
    if (this.editing !== undefined) {
      this.editAnnotation(this.editing);
    } else {
      this.addAnnotation();
    }
    // this.setUpdate();
    this.combinedActions()
  }

  scrollTop(): void {
    this.annotationsBody.nativeElement.scrollTop = 0;
  }

  setUpdate(): void {
    const fileUpdates: FileModel = {};
    if (this.fileModel && this.fileModel._id) { Object.assign(fileUpdates, { _id: this.fileModel._id }); }
    Object.assign(fileUpdates, { annotations: this.fileModel.annotations });

    this.sidebarService.updateOne(fileUpdates, this.fileModel);
  }

  adjustCoortinates(points,scalingFactor,xTrans,yTrans):Array<Array<Coordinate>>{
    const adjustedPoints = points.map((point) =>
    point.map((coords) => {
      const newX = (coords[0] + xTrans) / scalingFactor;
      const newY = (coords[1] + yTrans) / scalingFactor;
      return [newX, newY];
    })
  );
  return adjustedPoints;
  }

  // newAnno was added just for the annotations that are comming from mesuring temperature
  addAnnotation(newAnno?): void {
    if (!this.coordinates.value){
      this.olImageService.setCoords(this.olImageService.coords$.value )
    }
    let newAnnotation
    if (newAnno){
      newAnnotation = newAnno
    } else {
      newAnnotation = {
        "fileID": this.fileModel._id,
        "comment": this.comment.value,
        "coordinates": this.coordinates.value as Array<Array<Coordinate>>,
        "feature": this.feature.value,
        "stateDimension": this.stateDimension.value,
        "stateForm": this.stateForm,
        "type": 1,
        "minimumTemperature":this.selectionStatus || !this.minimum.value ? null :this.extractFloatFromString(this.minimum.value),
        "maximumTemperature": this.selectionStatus || !this.maximum.value? null : this.extractFloatFromString(this.maximum.value),
        "deltaTemperature": this.selectionStatus || !this.delta.value? null :this.extractFloatFromString(this.delta.value),
        "averageTemperature":this.selectionStatus || !this.average.value? null: this.extractFloatFromString(this.average.value),
      }
    }
     

    this.annotationsService.insertOne(newAnnotation)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      (response) => {
        if (this.fileModel.annotations && this.fileModel.annotations.length > 0) {
          this.fileModel.annotations.push(response.body.data["actualAnnotation"]);
        } else {
          this.fileModel.annotations = [response.body.data["actualAnnotation"]]
        }
        this.annotationsService.setAnnotations(this.fileModel.annotations)
        //updating stats
        this.fileModel.annotationStats=this.annotationsService.updateAnnotationStats(this.fileModel)
        this.fileModel.typesStats = this.annotationsService.updateTypesStats(this.fileModel)
        if(this.fileModel.type=="Thermal"){
          const wideModel = this.filesService.allData.find(file => file._id == this.fileModel.master)
          if (wideModel.annotations && wideModel.annotations?.length > 0) {
            wideModel.annotations.push(response.body.data["matchAnnotation"]);
          } else {
            wideModel.annotations = [response.body.data["matchAnnotation"]]
          }
          this.annotationsService.setMasterAnnotations(wideModel.annotations)
          wideModel.annotationStats=this.annotationsService.updateAnnotationStats(wideModel)
          wideModel.typesStats = this.annotationsService.updateTypesStats(wideModel)
        } else if (this.fileModel.type=="Wide") {
          const thermalModel = this.filesService.allData.find(file => file._id == this.fileModel.thermalSubFileModel._id)
          if (thermalModel.annotations && thermalModel.annotations?.length > 0) {
            thermalModel.annotations.push(response.body.data["matchAnnotation"]);
          } else {
            thermalModel.annotations = [response.body.data["matchAnnotation"]]
          }
          this.annotationsService.setThermalAnnotations(thermalModel.annotations)
          thermalModel.annotationStats=this.annotationsService.updateAnnotationStats(thermalModel)
          thermalModel.typesStats = this.annotationsService.updateTypesStats(thermalModel)
        }
      },
      error => {
        this.fetchingChange$.next(false);
    });
  }

   extractFloatFromString(input: string): number | null {
    const trimmedInput = input.trim();
  
    if (trimmedInput === "" || trimmedInput === " " || trimmedInput === "-") {
      return null;
    }
  
    const regexResult = /([-+]?\d*\.\d+|\d+)/.exec(trimmedInput);
  
    if (regexResult && regexResult.length > 0) {
      return parseFloat(regexResult[0]);
    }
  
    return null;
  }

  closeAnnotationInput(){
    this.editing = undefined;
    this.formComments.reset({
      comment: "",
      minimum: null,
      maximum: null,
      delta: null,
      average: null
    });
    this.olImageService.setEditing(null)
    this.stateNewComment = false;
  }


  setNewComment(state: boolean, temperature?:Annotation): void {
    this.selectionStatus=true;
    this.editing = undefined;
    this.stateNewComment = state;
    if (temperature){
      this.formComments.reset({
        comment: "",
        minimum: temperature.minimumTemperature? temperature.minimumTemperature.toFixed(2).toString() +" °C": null,
        maximum: temperature.maximumTemperature? temperature.maximumTemperature.toFixed(2).toString() +" °C": null,
        delta: temperature.deltaTemperature? temperature.deltaTemperature.toFixed(2).toString() +" °C": null,
        average: temperature.averageTemperature? temperature.averageTemperature.toFixed(2).toString() +" °C": null
      });
      this.selectionStatus=false;
      this.olImageService.setEditing(-1)
    }else{
      this.formComments.reset({
        comment: "",
      });
      this.selectForm('Box')   
      if (state) {
        this.olImageService.setStatus("clear map")
        this.olImageService.setEditing(-1)
      } else {
        this.olImageService.setStatus("put back draft")
        this.olImageService.setEditing(null)
      }
    }
    
    setTimeout(()=>{this.scrollTop();},1)
  }

  selectForm(form: DrawType): void {
    this.stateForm = form;
    this.olImageService.setType(form);
  }
  
  selectDimension(dimenstion: number): void {
    this.stateDimension.setValue(dimenstion);
  }

  edit(i: number): void {
    if (!this.fileModel.annotations[i].averageTemperature){
      this.editing = i;
      this.stateNewComment = true;
      this.formComments.reset({
        comment: this.fileModel.annotations[i].comment,
        feature: this.fileModel.annotations[i].feature,
        stateDimension: this.fileModel.annotations[i].stateDimension
      });
      this.coordinates.setValue(this.fileModel.annotations[i].coordinates)
      this.selectForm(this.fileModel.annotations[i].stateForm)
      this.olImageService.setEditing(i)
      this.drawed = true;
      setTimeout(()=>{this.scrollTop();},1)
    }else{
      this.olImageService.setInteraction(false)
      this.editing = i;
      this.stateNewComment = true;
      this.selectionStatus = false;
      this.formComments.reset({
        comment: this.fileModel.annotations[i].comment,
        feature: this.fileModel.annotations[i].feature,
        stateDimension: this.fileModel.annotations[i].stateDimension,
        minimum: this.fileModel.annotations[i].minimumTemperature? this.fileModel.annotations[i].minimumTemperature.toFixed(2).toString() +" °C":null,
        maximum: this.fileModel.annotations[i].maximumTemperature? this.fileModel.annotations[i].maximumTemperature.toFixed(2).toString() +" °C":null,
        delta: this.fileModel.annotations[i].deltaTemperature? this.fileModel.annotations[i].deltaTemperature.toFixed(2).toString() +" °C":null,
        average: this.fileModel.annotations[i].averageTemperature? this.fileModel.annotations[i].averageTemperature.toFixed(2).toString() +" °C":null,

      });
      this.coordinates.setValue(this.fileModel.annotations[i].coordinates)
      this.selectForm(this.fileModel.annotations[i].stateForm)
      this.olImageService.setEditing(i)
      this.drawed = true;
      setTimeout(()=>{this.scrollTop();},1)
    }

  }

  delete(i: number): void {
    this.annotationsService.deleteOne(String(this.fileModel.annotations[i]._id))
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      () => {
        if (this.fileModel.annotations.length > 1) {
          this.fileModel.annotations.splice(i, 1)
        } else {
          this.fileModel.annotations = []
        }
        this.annotationsService.setAnnotations(this.fileModel.annotations)
        this.fileModel.annotationStats=this.annotationsService.updateAnnotationStats(this.fileModel)
        this.fileModel.typesStats = this.annotationsService.updateTypesStats(this.fileModel)
    },
    error => {
      this.fetchingChange$.next(false);
     // this.dialogService.showDialog('FILE.DELETE', error.status, error.url, error.error);
    });
  }
  
  severityToColor(stateDimension): string {
    return SeverityToColor[stateDimension] || 'blue';
  }

  formToIcon(stateForm: string): string {
    switch (stateForm) {
      case 'Box':
        return 'crop_square'
        break;
      case 'Circle':
        return 'circle'
        break;
      case 'Polygon':
        return 'gesture'
        break;
      default:
        return ''
    }
  }

}
