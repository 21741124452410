export enum CollaborationRole {
  COORDINATOR = 'coordinator', // Can view, edit, and delete the file. A single owner must always exist for each file.
  EDITOR = 'editor', // Can view and edit the file.
  VIEWER = 'viewer', // Can only view the file.
}

export class Collaboration {
  _id?: string;
  expiresAt?: Date;
  sharedBy?: any;
  resource?: {
    id?: string;
    type?: 'order' | 'file' | 'annotation' | 'measurement' | 'site' | 'portfolio';
  };
  subject?: {
    id?: string;
    email?: string;
    name?: string;
    username?: string;
    avatarIconLink?: string;
    type?: 'user' | 'team';
  };
  options?: {
    allowInheritance?: boolean;
    role?: CollaborationRole;
  };

  static getIcon(role: CollaborationRole): string {
    switch (role) {
      case CollaborationRole.COORDINATOR:
        return 'coordinator';
      case CollaborationRole.EDITOR:
        return 'editor';
      case CollaborationRole.VIEWER:
        return 'viewer';
      default:
        return 'error';
    }
  }

  static getLabel(role: CollaborationRole): string {
    switch (role) {
      case CollaborationRole.COORDINATOR:
        return 'COLLABORATION.ROLE.COORDINATOR';
      case CollaborationRole.EDITOR:
        return 'COLLABORATION.ROLE.EDITOR';
      case CollaborationRole.VIEWER:
        return 'COLLABORATION.ROLE.VIEWER';
      default:
        return String(role); // Convert the enum to string if not matched.
    }
  }
}