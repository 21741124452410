<form class="form" [formGroup]="formGroup">

  <mat-form-field>
    <mat-label>{{ 'NEW_PASSWORD' | translate }}</mat-label>
    <input
        formControlName="password"
        matInput
        name="password"
        type="{{ showPassword ? 'text' : 'password' }}">

    <button
        (click)="toggleShowPassword($event)"
        [disabled]="fetching || password.disabled"
        mat-icon-button
        matSuffix
        title="{{ (showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD') | translate }}"
        type="button">
      <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>

    <mat-error *ngIf="password.errors?.required">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
    <mat-error *ngIf="password.errors?.minlength">{{ 'VALIDATION.MIN_LENGTH' | translate: { count: password.errors?.minlength?.requiredLength } }}</mat-error>
    <mat-error *ngIf="password.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: password.errors?.maxlength?.requiredLength } }}</mat-error>
  </mat-form-field>

  <button
      (click)="submitNewPassword()"
      color="accent"
      [disabled]="!password || fetching"
      mat-raised-button
      type="submit">{{ 'SUBMIT' | translate }}</button>

</form>
