import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ResizeEvent } from 'angular-resizable-element';
import { Subject } from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import { FileModel } from '../file/file.model';
import { FilesComponent } from '../files.component';
import { FilesService } from '../files.service';
import { InspectionService } from '../inspection/inspection.service';
import { searchToFilter } from 'src/app/shared/helpers/data-helpers';
import { SidenavNavigationService } from './../../../shared/sidenav/sidenav-navigation/sidenav-navigation.service';
import { ThemeService } from '../../../shared/theme/theme.service';
import { Viewer3dService } from '../../viewer/viewer3d/viewer3d.service';
import { ViewerService } from '../../viewer/viewer.service';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-inspection-3d',
  templateUrl: './inspection-3d.component.html',
  styleUrls: ['./inspection-3d.component.scss']
})

export class Inspection3DComponent implements AfterViewInit{
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('files') files: FilesComponent;

  border = 24;
  horizontal = 500;
  inspectionArea = {height: 0,width: 0}
  maxValue: number = 33;
  minValue: number = 25;
  options: Options = {
    floor:25,
    ceil: 33,
    translate: (value: number): string => {
      return value + 'm';
    }
  };
  rowHeight: number;
  selectedFileDisplayedName: string;
  selectedFileFullName: string;
  theme: string;
  vertical = 500;
  viewer3d = null;

  private ngDestroy$ = new Subject();

  constructor(
    private filesService: FilesService,
    private inspectionService: InspectionService,
    private route: ActivatedRoute,
    private sidenavNavigationService: SidenavNavigationService,
    private themeService: ThemeService,
    private viewer3dService: Viewer3dService,
    private viewerService: ViewerService,
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.filesService.findOne(this.route.snapshot.params['folderID'])
      .pipe(take(1))
      .subscribe(file => {
        this.loadImagesFileModels(file.data)
      })

    this.route.params
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(params => {
      const folderID = params['folderID']
      if(folderID) {
        this.filesService.findOne(folderID)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
          response => {
            if(response.data && response.data.viewer) {
              if (response.data.viewer.imageCones.imagesFolder) {
                this.loadImagesFileModels(response.data);
              }
              if (response.data.viewer.tiles3d) {
                this.load3dTiles(response.data.viewer.tiles3d);
              }
              if (response.data.viewer.pano) {
                this.setPanoID(response.data.viewer.pano);
              }
            }
          }
        );
      }
    }
  );


    this.inspectionService.selectedFile$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (Object.keys(data).length !== 0) {
        if(data.name.length > 25) {
          const matches = /_(\d+)(?!.*\d).*/.exec(data.name);
          //this.selectedFileDisplayedName = "..."+matches[0];
          this.selectedFileDisplayedName = data.name;
          this.selectedFileFullName = data.name;
        } else {
          this.selectedFileDisplayedName = data.name;
          this.selectedFileFullName = "";
        }
      }
    });

    import('../../viewer/viewer3d/viewer3d.component').then(({ Viewer3dComponent }) => {
      this.viewer3d = Viewer3dComponent
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.updateInspectionArea();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.rowHeight = (this.wrapper.nativeElement.offsetHeight-1) / 2;
      this.files.setViewMode('grid');
      this.sidenavNavigationService.fold();
    }, 1);
    setTimeout(() => {
      this.sidenavNavigationService.fold();
      this.updateInspectionArea();
    }, 10);
  }

  filesViewChange(): void {
    if(this.files.viewMode === 'list') {
      this.files.setViewMode('grid');
    } else {
      this.files.setViewMode('list');
    }
  }

  setPanoID(objectID): void {
    this.viewer3dService.panoID$.next(objectID);
  }

  load3dTiles(objectID: string): void {
    //add tiles3d
    this.filesService.findOne(objectID)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
    response => {
      this.viewer3dService.fileModel$.next(response.data);
    });
  }

  loadImagesFileModels(fileModel: FileModel): void {
    let search = '';
    search = search.trim();
    const filter = searchToFilter(search);
    if (!Object.keys(filter).length) {
      // Set folderID from the route ID
      filter['folderID'] = { $oid: fileModel.viewer?.imageCones?.imagesFolder || fileModel._id }
    }
    // Default sorting and fields: Get icon markers fields
    const sort: object = { modified: -1 };

    // Get data from the server
    this.filesService.findMany(filter, sort, 0, 100000, null)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {
        const payload = {fileModels: response.data}
        if(fileModel.viewer?.imageCones?.position?.z) {
          payload["z"] = fileModel.viewer.imageCones.position.z
        }
        if (fileModel.viewer) {
          this.viewerService.imagesFileModels$.next(payload);
        }
        this.filesService.currentFiles$.next(response.data)
    });
  }

  resizingVertical(event: ResizeEvent): void {
    const value = event.rectangle.height;
    if (value < this.border + (this.border / 2)) {
      this.vertical = this.border;
    } else if (value > (this.inspectionArea.height - (this.border / 2))){
      this.vertical = this.inspectionArea.height;
    } else {
      this.vertical = value;
    }

    this.viewerService.action$.next('updateSize');
  }

  resizingHorizontal(event: ResizeEvent): void {

    const value = event.rectangle.width;
      if (value < this.border + (this.border / 2)) {
        this.horizontal = this.border;
      } else if (value > (this.inspectionArea.width - this.border - (this.border / 2))){
        this.horizontal = this.inspectionArea.width;
      } else {
        this.horizontal = value;

    }

    this.viewerService.action$.next('updateSize');
  }

  updateInspectionArea(): void {
    // timeout is needed to avoid wrong height when fullscreen
    setTimeout(() => {
      this.inspectionArea.width = this.wrapper.nativeElement.offsetWidth;
      this.inspectionArea.height = this.wrapper.nativeElement.offsetHeight;
      this.vertical = ((this.inspectionArea.height - (this.border)) / 5 * 3);
      this.horizontal = (this.inspectionArea.width / 5) * 3 - (this.border / 2);

      setTimeout(() => {
        this.viewerService.action$.next('updateSize');
      }, 1);
    }, 250);
  }
}
