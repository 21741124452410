import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HeaderService } from '../../../shared/header/header.service';
import { OLMapsService } from '../../../shared/openlayers/maps.service';
import { FilesService } from '../../files/files.service';
import { MatTableDataSource } from '@angular/material/table';
import { FileModel } from '../../files/file/file.model';
import { SiteAdderModalComponent } from '../../../shared/site-adder-modal/site-adder-modal.component';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LinkDeviceComponent } from '../../../shared/link-device/link-device.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {PortfolioService} from '../../../shared/portfolio/portfolio.service';
import { LoginStateService } from '../../login/login-state.service';
import { InputData } from '../../../shared/doughnut-chart/doughnut-chart.component';
import { TranslateService } from '@ngx-translate/core';
import {Portfolio, ResponseData, Site, SiteStats} from '../../../shared/portfolio/portfolio.model';
import {orderColors, OrderGroup, OrdersService, OrderStatus} from '../../../shared/orders/orders.service';
import { LegacyClientOrder, Order } from '../../../shared/orders/orders.model';
import { Annotation } from '../../files/file/sidebar/annotations/annotation.model';
import { InspectionService } from '../../files/inspection/inspection.service';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { PortfoliosService } from '../../portfolios/portfolios.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit, OnDestroy {
  @ViewChild('imageMap', { static: false }) imageMap!: ElementRef;
  public site!: Site;
  public portfolio!: Portfolio;
  public stats!: SiteStats;
  public orders!: Order[];
  public recentOrder!: LegacyClientOrder;
  public results: FileModel[] = [];
  public RecentOrderAnnotations: Annotation[] = [];
  public immidiateAnnos: Annotation[] = [];
  public currentLang: string = '';
  private siteId: string = undefined
  private portfolioId: string = undefined

  public displayedColumns: string[] = [
    'annoType',
    'comment',
    'lastModification'
  ];

  public typeLabels: string[] = [
    'SITE.TYPE1',
    'SITE.TYPE2',
    'SITE.TYPE3',
    'SITE.TYPE4'
  ];

  public severityLabels: string[] = [
    'SITE.ADVISORY_NOTE',
    'SITE.URGENT',
    'SITE.CRITICAL',
    'SITE.IMPORTANT',
    'SITE.MINOR',
  ]

  public dataSource = new MatTableDataSource(this.immidiateAnnos);
  public severityDistributionData!: InputData;
  public smallData: InputData[] = [];

  private ngDestroy$ = new Subject();
  theme: string= this.themeService.changed$.value;

  constructor(
    private readonly headerService: HeaderService,
    private readonly oLMapsService: OLMapsService,
    private readonly dialog: MatDialog,
    private readonly portfolioService: PortfolioService,
    private readonly loginStateService: LoginStateService,
    private readonly translateService: TranslateService,
    private readonly ordersService: OrdersService,
    private readonly router: Router,
    private readonly themeService: ThemeService,
    private readonly dialogService: DialogService,
    private readonly route: ActivatedRoute,
    private readonly portfoliosService: PortfoliosService,
    public readonly inspectionService: InspectionService,
    public filesService: FilesService,
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
      this.loadStatistic()
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.orders = [navigation.extras.state['order']];
    }

    this.currentLang = this.translateService.currentLang;

    this.translateService.onLangChange
    .subscribe((lang)=> {
      this.getSeverityDistributionData();
      this.currentLang = lang.lang;
    })

    this.route.params.subscribe(params => {
      this.portfolioId = params['portfolioID'];
      this.siteId = params['siteID'];
      this.loadSitePage()
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.updateNavigation();
    }, 100);
  }

  public ngOnDestroy(): void {
    this.headerService.breadCrumbs$.next([]);
  }

  private loadMap(fileModel: FileModel, annos: Annotation[]): void {
    if(this.imageMap.nativeElement.children.length > 0) {
      this.imageMap.nativeElement.children[0].remove();
    }
    this.oLMapsService.renderImage({
      extent: [0, 0, fileModel.width, fileModel.height],
      target: this.imageMap.nativeElement,
      image: fileModel.webViewLink,
      imagePreview: fileModel.thumbnailLink,
      annotations: annos,
      overVeiwMap: false,
      interactions: false,
      fileModelMatch: fileModel.thermalSubFileModel,
      scalingFactor: fileModel.thermalSubFileModel?.scalingFactor,
      original: fileModel,
      center: this.filesService.centerAdjustments,
    });
  }

  public openSiteDialog(): void {
    const modal = this.dialog.open<SiteAdderModalComponent, Site>(SiteAdderModalComponent, {
      width: '55vw',
      maxWidth: '700px',
      minWidth: '300px',
      closeOnNavigation: true
    });

    modal.componentInstance.site = this.site;

    modal.afterClosed()
      .pipe(
        filter(value => !!value),
        take(1),
        switchMap(value =>  this.portfolioService.editSite(this.site._id, {
          ...value,
          portfolioId: this.portfolio._id
        }))
      )
      .subscribe((value) => {
        this.site = value.data
        this.updateNavigation();
      })
  }

  public openLinkDialog(): void {
    const modal = this.dialog.open<LinkDeviceComponent, string[]>(LinkDeviceComponent, {
      width: '55vw',
      maxWidth: '700px',
      minWidth: '300px',
      closeOnNavigation: true
    });

    let submittedOrders;
    modal.componentInstance.tags = this.orders?.map(order => `FF${order.legacyId}`)

    modal.afterClosed()
    .pipe(
      filter(value => !!value),
      take(1),
      switchMap(value =>  {
        submittedOrders = this.formatOrderIds(value)
        return this.portfolioService.addSiteOrders(this.site._id, submittedOrders)
      })
    )
    .subscribe((addedOrdersToSite: any) => {
      let deniedOrders = ""
      submittedOrders.forEach((orderId)=> {
          if (!addedOrdersToSite.data?.includes(Number(orderId))){
            deniedOrders = deniedOrders + "FF" + orderId + ", "
          }
        })
        if (deniedOrders != "") {
          this.dialogService.showDialog('Linking was denied', null, "You do not own the following order(s):", deniedOrders, false,true);
        }
        return this.loadSitePage();
    })
  }

  private formatOrderIds(orderIds: string[]): string[] {
    return orderIds.map(id => id.startsWith('FF') ? id.substring(2) : id);
  }

  public get tooltip(): string {
    if (this.indicator === 1) {
      return 'SITE.FAIR_TOOLTIP'
    }

    if (this.indicator  === 2) {
      return 'SITE.MEDIOCRE_TOOLTIP'
    }

    return 'SITE.CRITICAL_TOOLTIP'
  }

  private loadSitePage() {
    this.portfoliosService.getPortfolioById(this.portfolioId).pipe(takeUntil(this.ngDestroy$))
    .subscribe((portfolioResult) => {
      this.portfolio = portfolioResult.data
    })

    this.portfolioService.getSiteById(this.siteId).pipe(takeUntil(this.ngDestroy$))
    .subscribe((siteResult) => {
      this.site = siteResult.data
    })

    this.portfolioService.getSiteStatistics(this.siteId).pipe(takeUntil(this.ngDestroy$))
    .subscribe((siteStats) => {
      this.stats = siteStats.data
      this.loadStatistic()
    })

    this.portfolioService.getSiteOrders(this.siteId).pipe(takeUntil(this.ngDestroy$))
    .subscribe((siteOrders) => {
      this.orders = siteOrders.data
      this.getRecentOrder()
    })

    this.getResults().pipe(takeUntil(this.ngDestroy$))
    .subscribe((results)=> {
      this.results = results.data || [];
      this.getCardsThumbails()
    })

    this.getRecentOrderAnnotations().pipe(takeUntil(this.ngDestroy$))
    .subscribe((RecentOrderAnnotations)=> {
      this.RecentOrderAnnotations = RecentOrderAnnotations.data || [];
      this.loadAnnotations();
      setTimeout(() => this.showAnnotations(this.immidiateAnnos[0]), 50);
      this.dataSource.data = this.immidiateAnnos;
    })


  }

  public openResult(result: FileModel): void {
    if (result?.isFolder && !result.tags?.includes('inspection')) {
      this.router.navigate(['', { outlets: { primary: ['portfolio', this.site._id, 'files', result._id], detail: null}}]);
    }

    if (!result.tags?.includes('inspection') && !result?.isFolder) {
      this.router.navigate(['', { outlets: { detail: ['file', result._id, { view: 'fullscreen' }]}}]);
    }

    if (result?.isFolder &&  result.tags?.includes('inspection')) {
      this.router.navigate(
        [{ outlets: { primary: ['portfolio', this.site._id, 'inspection', result._id], detail: ['file', result._id] }}],
        { queryParamsHandling: 'merge' }
      );
    }
  }

  public get color(): string {
    return this.portfolioService.getColorOfScore(this.stats?.score);
  }

  public get indicator(): number {
    return this.portfolioService.getScoreIndicator(this.stats?.score);
  }

  public getResults(): Observable<ResponseData<FileModel[]>> {
    return this.portfolioService.getSiteResults(this.siteId)
  }

  public getRecentOrderAnnotations(): Observable<any> {
    return this.portfolioService.getSiteRecentOrderAnnotations(this.siteId)
  }

  private loadAnnotations(): void {
    this.immidiateAnnos = this.RecentOrderAnnotations.filter((annotation: Annotation)=> annotation.stateDimension >= 80) || []
  }

  public getOrderColor(group: OrderGroup, map?: boolean): string {
    if (map) {
      return orderColors[group][1];
    } else {
      return orderColors[group][0];
    }
  }

  public getCardsThumbails(): void {
    this.results.forEach(item => {
      if (item.card && item.card.thumbnail) {
        this.filesService.findOne(item.card.thumbnail)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
        response => {
          item.thumbnailLink = response.data.thumbnailLink;
        });
      }
    });
  }

  public getOrderGroup(status: string): OrderGroup {
    return this.ordersService.getOrderGroup(status);
  }

  public getOrderStatus(status: string): OrderStatus {
   return this.ordersService.getOrderStatus(status);
  }

  public getRecentOrder(): void {
      if (!this.orders || this.orders.length === 0) {
          return; // Exit if there are no orders
      }

      const recentOrder = this.orders.reduce((latestOrder, currentOrder) => 
          new Date(currentOrder.flightDateEnd) > new Date(latestOrder.flightDateEnd) 
              ? currentOrder 
              : latestOrder
      );

      this.ordersService.findClientLegacyOrder(recentOrder.legacyId)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(legacyOrder => {
              this.recentOrder = legacyOrder;
          });
  }

  public get labelCondition(): string {
    return this.portfolioService.getLabelsOfScore(this.stats?.score);
  }

  public getTypeDistributionData(): InputData {
    if (!this.stats) return;

    const stats = {...this.stats.featureStats};

    const length = Object.keys(stats).length;
    const sorted: string[] =[];
    let others = 0;

    for (let i = 0; i < length; i++) {
      let max = -1;
      let maxKey = '';

      Object.entries(stats).forEach(([key, value], index) => {
        if (max < value) {
          max = value;
          maxKey = key;
        }
      });

      if (sorted.length < 3) {
        sorted.push(maxKey);
      } else {
        others += stats[maxKey];
      }

      stats[maxKey] = -1;
    }

    const translated = sorted.map(type => this.inspectionService.getFeature(type, this.currentLang));

    translated.push(this.translateService.instant('SITE.OTHER_TYPE'));

    return {
      labels: translated,
      records: [
        {
          data: this.stats.featureStats?.[sorted[0]] || 0,
          color: '#2979FF'
        },
        {
          data:  this.stats.featureStats?.[sorted[1]] || 0,
          color: '#87B2FB'
        },
        {
          data:  this.stats.featureStats?.[sorted[2]] || 0,
          color: '#B8D2FF'
        },
        {
          data:  others,
          color: '#DDEAFF'
        }
      ]
    }
  }

  public loadStatistic(): void {
    this.getSeverityDistributionData();
    this.getSmallData();
  }

  public getSeverityDistributionData(): InputData {
    if (!this.stats) return;

    const translated = this.severityLabels.map(label => this.translateService.instant(label));

    this.severityDistributionData = {
      labels: translated,
      records: [
        {
          data:  this.stats.annotationStats.advisory,
          color: '#7385A7',
        },
        {
          data:  this.stats.annotationStats.urgent,
          color: '#8837B4'
        },
        {
          data:  this.stats.annotationStats.high,
          color: '#DE1F1F'
        },
        {
          data:  this.stats.annotationStats.medium,
          color: '#F3C41C'
        },
        {
          data:  this.stats.annotationStats.low,
          color: '#27C100'
        }
      ]
    }
  }

  public getSmallData(): InputData {
    if (!this.stats) return;
    const pieData = [
      {
        labels: ['', ''],
        records: [
          {
            data: this.stats.annotationStats.advisory,
            color: '#7385A7'
          },
          {
            data: this.stats.annotationStats.total - this.stats.annotationStats.advisory,
            color: this.theme.includes('dark')?"#494949":"#DDEAFF",
          }
        ]
      },
        {
          labels: ['', ''],
          records: [
            {
              data:  this.stats.annotationStats.urgent,
              color: '#B251E7'
            },
            {
              data: this.stats.annotationStats.total - this.stats.annotationStats.urgent,
              color: this.theme.includes('dark')?"#494949":"#DDEAFF",
            }
          ]
        },
        {
          labels: ['', ''],
          records: [
            {
              data: this.stats.annotationStats.high,
              color: '#DE1F1F'
            },
            {
              data: this.stats.annotationStats.total - this.stats.annotationStats.high,
              color: this.theme.includes('dark')?"#494949":"#DDEAFF",
            }
          ]
        },
        {
          labels: ['', ''],
          records: [
            {
              data:  this.stats.annotationStats.medium,
              color: '#F3C41C'
            },
            {
              data: this.stats.annotationStats.total - this.stats.annotationStats.medium,
              color: this.theme.includes('dark')?"#494949":"#DDEAFF",
            }
          ]
        },
        {
          labels: ['', ''],
          records: [
            {
              data: this.stats.annotationStats.low,
              color: '#27C100'
            },
            {
              data: this.stats.annotationStats.total - this.stats.annotationStats.low,
              color: this.theme.includes('dark')?"#494949":"#DDEAFF",
            }
          ]
        }
    ]
    this.smallData= pieData
  }

  public get progress(): string {
    if (this.stats?.score < 0.33) {
      return `100%`;
    }

    if (this.stats?.score < 0.66) {
      return `calc((100% - 50px) * 0.66666 + 25px)`;
    }

    return `calc((100% - 50px) * 0.33333 + 25px)`;
  }

  public updateNavigation(): void {
    this.headerService.breadCrumbs$.next([{
      label: this.portfolio?.name,
      link: ['portfolios', this.portfolio?._id]
    }, {
      label: this.site.name,
      link: ['portfolios', this.portfolio?._id, 'sites', this.site._id]
    }])
  }

  public showAnnotations(annotation: Annotation): void {
    if (!annotation) return;
    this.loadMap(annotation.file, [annotation]);
  }
}
