<div class="wrapper">
  <mat-dialog-content>
    <mat-icon class="close" (click)="close()">close</mat-icon>
    <h1 mat-dialog-title>Equipment</h1>
    <ng-container *ngFor="let type of data | keyvalue">
      <h2
          *ngIf="type.value.length > 0"
          mat-dialog-title>{{ type.key }}</h2>
      <div *ngIf="type.value.length > 0">
        <table mat-table [dataSource]="type.value">

          <!-- name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
          </ng-container>

          <!-- manufacturer Column -->
          <ng-container matColumnDef="manufacturer">
            <th mat-header-cell *matHeaderCellDef> Manufacturer </th>
            <td mat-cell *matCellDef="let element"> {{ element.manufacturer }} </td>
          </ng-container>

          <!-- models Column -->
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef> Model </th>
            <td mat-cell *matCellDef="let element"> {{ element.model }} </td>
          </ng-container>

          <!-- face Column -->
          <ng-container matColumnDef="face">
            <th mat-header-cell *matHeaderCellDef> Face </th>
            <td mat-cell *matCellDef="let element"> {{ element.face }} </td>
          </ng-container>

          <!-- elevation Column -->
          <ng-container matColumnDef="elevation">
            <th mat-header-cell *matHeaderCellDef> Elevation </th>
            <td mat-cell *matCellDef="let element"> {{ element.elevation.toFixed(2) }} </td>
          </ng-container>

          <!-- size Column -->
          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef> Size (height x width x depth) </th>
            <td mat-cell *matCellDef="let element"> {{ calcLength(element.height).toFixed(2) }} x {{ calcLength(element.width).toFixed(2) }} x {{ calcLength(element.depth).toFixed(2) }} (m) </td>
          </ng-container>

          <!-- azimuth Column -->
          <ng-container matColumnDef="azimuth">
            <th mat-header-cell *matHeaderCellDef> Azimuth </th>
            <td mat-cell *matCellDef="let element"> {{ element.azimuth }}°</td>
          </ng-container>

          <!-- tilt Column -->
          <ng-container matColumnDef="tilt">
            <th mat-header-cell *matHeaderCellDef> Tilt </th>
            <td mat-cell *matCellDef="let element"> {{ element.tilt }}°</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </ng-container>
  </mat-dialog-content>
  <div mat-dialog-actions class="right">
    <button mat-raised-button
        disabled="true"
        color="accent">
      Export
    </button>
  </div>
</div>
