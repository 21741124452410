import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Options } from '@angular-slider/ngx-slider';

import { AnnotationStateDimensionObj, AnnotationTypesObj, ImageTypeObj, SortArray } from '../../annotations/annotation-types';
import { FilesService, FilterFileData } from './../../../pages/files/files.service';
import { InspectionService } from './../../../pages/files/inspection/inspection.service';
import { ThemeService } from '../../theme/theme.service';
import { AnnotationsService } from 'src/app/pages/files/file/sidebar/annotations/annotations.service';

@Component({
  selector: 'app-inspection-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnDestroy {
  currentLang: string;
  filterFileData: FilterFileData;
  filterFileDataLength: number;
  panelOpenState = false;
  showFilter = false;
  stateDimensionAllChecked = false;
  stateDimensionIndeterminate = false;
  stateDimensionNone: boolean = false;
  stateDimensionNumber = 0;
  imageTypeNumber = 0;
  imageTypeAllChecked: boolean = true;
  imageTypesIndeterminate: boolean = false;
  theme: string;
  typesAllChecked = false;
  typesIndeterminate = false;
  typesNumber = 0;
  usedFeatures: {[key: string]: any[];}  = {};
  usedStateDimension = [];
  usedImageTypes = [];
  showSlider = false;
  options: Options = {
    floor: 0,
    ceil: 0,
    translate: (value: number): string => {
      return value + 'm';
    }
  };

  private ngDestroy$ = new Subject();

  constructor(
    private inspectionService: InspectionService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private themeService: ThemeService,
    private filesService: FilesService,
    private annotationsService: AnnotationsService
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.route.queryParamMap.subscribe((params) => {
      this.setFilterFromQuery(params);
    });

    // get language for sortAnnotation by language
    this.currentLang = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: any) => {
      this.currentLang = event.lang;
      for (const [key, value] of Object.entries(this.usedFeatures)) {
        this.usedFeatures[key] = SortArray(value, this.currentLang);
      }
    });


    //this is to show slider or not
    this.inspectionService.allFiles$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (Object.keys(data).length !== 0) {
        const heights = []
        data.forEach((fileModel) => {
          if(fileModel.relativeAltitude) {
            heights.push(fileModel.relativeAltitude);
          }
        });
        if (heights.length > 1 &&
            !(this.options.floor === Math.floor(Math.min(...heights)) &&
            this.options.ceil === Math.ceil(Math.max(...heights)))) {
          const newOptions: Options = Object.assign({}, this.options);
          newOptions.floor = Math.floor(Math.min(...heights));
          newOptions.ceil = Math.ceil(Math.max(...heights));
          if (newOptions.ceil - newOptions.floor > 2) {
            this.showSlider= true
          } else{
            this.showSlider= false
          }
        }
      }
    });

    this.annotationsService.annotations$
    .pipe(takeUntil(this.ngDestroy$),skip(1))
    .subscribe(()=>{
      this.showFilter=false
      const newFilterParameters = this.inspectionService.updateFilterParameters(this.inspectionService.dataSourceBeforeFilter$.getValue())
      setTimeout(() => {
        if (newFilterParameters['stateDimension']) {
          // stateDimension
          this.usedStateDimension = []
          for (const [key, value] of Object.entries(AnnotationStateDimensionObj)) {
            if(newFilterParameters['stateDimension'].includes(value.value)) {
              const annotationStateDimension = { ...value };
              annotationStateDimension["id"] = key;
              annotationStateDimension['completed'] = false;
              this.usedStateDimension.push(annotationStateDimension);
            }
          }  
          // features
          setTimeout(() => {
            this.usedFeatures = {};
            newFilterParameters['features'].forEach((feature) => {
              if (!Object.keys(this.usedFeatures).includes(feature?.split('-')[0])) {
                this.usedFeatures[feature?.split('-')[0]] = [];
              }
              const temp = AnnotationTypesObj[feature?.split('-')[0]][feature?.split('-')[1]];
              temp['id'] = feature?.split('-')[1];
              temp['completed'] = false;
              this.usedFeatures[feature?.split('-')[0]].push(temp);
              for (const [key, value] of Object.entries(this.usedFeatures)) {
                this.usedFeatures[key] = SortArray(value, this.currentLang);
              }
            })
          }, 100);
          //imageType
          this.usedImageTypes = []
          for (const [key, value] of Object.entries(ImageTypeObj)) {
            let temp2 ={}
            newFilterParameters['imageType'].forEach ((type)=>{
                if (type.toLowerCase()== key){
                  temp2 = {... value}
                  temp2['id'] == key
                  temp2['completed'] = true
                  temp2['value'] = type
                }
            })
            if (Object.keys(temp2).length>0){
              this.usedImageTypes.push(temp2)
            }
          }
          this.showFilter = false;
          this.stateDimensionMatch()
          this.typesMatch()
          this.imageTypesMatch()
          
        }
      }, 600);
    })

    // This will initialize the filter
    this.inspectionService.featuresStateDimension()
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (data['stateDimension']) {
        // stateDimension
        this.usedStateDimension = []
        for (const [key, value] of Object.entries(AnnotationStateDimensionObj)) {
          if(data['stateDimension'].includes(value.value)) {
            const annotationStateDimension = { ...value };
            annotationStateDimension["id"] = key;
            annotationStateDimension['completed'] = false;
            this.usedStateDimension.push(annotationStateDimension);
          }
        }
        this.stateDimensionMatch();

        // features
        this.usedFeatures = {};
        data['features'].forEach((feature) => {
          if (!Object.keys(this.usedFeatures).includes(feature.split('-')[0])) {
            this.usedFeatures[feature.split('-')[0]] = [];
          }
          const temp = AnnotationTypesObj[feature.split('-')[0]][feature.split('-')[1]];
          temp['id'] = feature.split('-')[1];
          temp['completed'] = false;
          this.usedFeatures[feature.split('-')[0]].push(temp);
          for (const [key, value] of Object.entries(this.usedFeatures)) {
            this.usedFeatures[key] = SortArray(value, this.currentLang);
          }
        })
        this.typesMatch();

        //imageType
        this.usedImageTypes = []
        for (const [key, value] of Object.entries(ImageTypeObj)) {
          let temp2 ={}
          data['imageType'].forEach ((type)=>{
              if (type.toLowerCase()== key){
                temp2 = {... value}
                temp2['id'] == key
                temp2['completed'] = true
                temp2['value'] = type
              }
          })
          if (Object.keys(temp2).length>0){
            this.usedImageTypes.push(temp2)
          }
        }
        this.imageTypesMatch();
        this.filterUpdate()
        this.showFilter = false;
      }
    })
  }

  private setFilterFromQuery(params: ParamMap): void {
    this.filterFileData = {
      ...(params.get('annotationType') && { annotationType: params.get('annotationType').split(',') }),
      ...(params.get('feature') && { feature: params.get('feature').split(',') }),
      ...(params.get('maxHeight') && { maxHeight: Number(params.get('maxHeight')) }),
      ...(params.get('minHeight') && { minHeight: Number(params.get('minHeight')) }),
      ...(params.get('imageType') && { imageType: params.get('imageType').split(',') }),
    };
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  filterCheck(): boolean {
    if (Object.keys(this.usedFeatures).length > 0 || this.usedImageTypes.length > 0) {
      return true
    }
    return false

  }

  filterSet(): void {
    this.showFilter = false;
    let annotationStateDimensionString = this.stateDimensionList('id',',')
    const annotationTyesString = this.typesList('id',',');
    let imageTypeString = this.imageTypeList('value',',')
    if (annotationStateDimensionString.length === 0 && this.stateDimensionNone) {
      annotationStateDimensionString = "none";
    }

    const tmpQueryParams = {}

    if (annotationStateDimensionString !== "") {
      tmpQueryParams['feature'] = annotationStateDimensionString;
    }
    if (annotationTyesString !== "") {
      tmpQueryParams['annotationType'] = annotationTyesString;
    }
    if (this.filterFileData['minHeight']) {
      tmpQueryParams['minHeight'] = this.filterFileData['minHeight']
    }
    if (this.filterFileData['maxHeight']) {
      tmpQueryParams['maxHeight'] = this.filterFileData['maxHeight']
    }
    if (imageTypeString !=="") {
      tmpQueryParams['imageType'] = imageTypeString;
    }
    setTimeout(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: tmpQueryParams
      });
    }, 1);
  }

  filterToggle(): void {
    this.showFilter = !this.showFilter;
  }

  filterUpdate(): void {
    const tmpQueryParams = {}
    if (this.filterFileData !== undefined) {
      if(this.filterFileData['feature']) {
        tmpQueryParams['feature'] = this.filterFileData['feature'].join(',');
        if (tmpQueryParams['feature'].length === 0) {
          delete tmpQueryParams['feature'];
        }
      } else {
        delete tmpQueryParams['feature'];
      }
      if (this.filterFileData['annotationType']) {
        tmpQueryParams['annotationType'] = this.filterFileData['annotationType'].join(',');
        if (tmpQueryParams['annotationType'].length === 0) {
          delete tmpQueryParams['annotationType'];
        }
      } else {
        delete tmpQueryParams['annotationType'];
      }
      if (this.filterFileData['minHeight']) {
        tmpQueryParams['minHeight'] = this.filterFileData['minHeight'];
      }
      if (this.filterFileData['maxHeight']) {
        tmpQueryParams['maxHeight'] = this.filterFileData['maxHeight'];
      }

      if (this.filterFileData['imageType']) {
        tmpQueryParams['imageType'] = this.filterFileData['imageType'].join(',');
        if (tmpQueryParams['imageType'].length === 0) {
          delete tmpQueryParams['imageType'];
        }
      }
      if (Object.keys(tmpQueryParams).length > 0 ) {
        setTimeout(() => {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: tmpQueryParams
          });
        }, 1);
      }
    }
  }

  stateDimensionCheckAllComplete(): void {
    const booleanList = []
    this.usedStateDimension.forEach((dimension) =>{
      booleanList.push(dimension.completed);
    });

    this.stateDimensionNumber = booleanList.filter(Boolean).length
    if (this.stateDimensionNumber>0){
      if (!booleanList.includes(false)) {
        this.stateDimensionAllChecked = true;
      } else {
        this.stateDimensionAllChecked = false;
      }
      if (booleanList.includes(true) && booleanList.includes(false) ) {
        this.stateDimensionIndeterminate = true;
      } else {
        this.stateDimensionIndeterminate = false;
      }
      this.stateDimensionNone = false;
    }else{
      this.stateDimensionAllChecked = false;
      this.stateDimensionIndeterminate = false;
    }

  }

  stateDimensionList(item: string, join: string): string {
    const stateDimensions = []
    this.usedStateDimension.forEach((dimension) =>{
      if(dimension.completed) {
        stateDimensions.push(dimension[item]);
      }
    });
    return stateDimensions.join(join);
  }

  imageTypeList(item: string, join: string): string {
    const imageTypes = []
    this.usedImageTypes.forEach((type) =>{
      if(type.completed) {
        imageTypes.push(type[item]);
      }
    });
    return imageTypes.join(join);
  }

  stateDimensionMatch(): void {

    if(this.filterFileData && this.filterFileData['feature'] && this.usedStateDimension && this.usedStateDimension.length > 0) {
      const listID = []
      this.usedStateDimension.forEach((feature) => {
        feature['completed'] = this.filterFileData['feature'].includes(feature['id']);
        listID.push(feature['id']);
      })
      // check if filter exists in dataset
      let stateDimensionExisting = [...this.filterFileData['feature']];
      if (!this.filterFileData['feature'].includes('none')) {
        this.filterFileData['feature'].forEach((feature) => {
          if(!listID.includes(feature)) {
            stateDimensionExisting = stateDimensionExisting.filter((item) => {return item !== feature})
          }
        })
        this.filterFileData['feature'] = stateDimensionExisting;
      } else {
        this.filterFileData['feature'] = ['none']
        this.stateDimensionNone = true;
      }
    }
    this.stateDimensionCheckAllComplete();
  }

  stateDimensionSetAll(completed: boolean): void {
    for (const [key, value] of Object.entries(this.usedStateDimension)) {
      value.completed = completed;
    }
    if(completed) {
      this.stateDimensionUpdateNone(false);
      this.stateDimensionNumber = this.usedStateDimension.length;
    } else {
      this.stateDimensionIndeterminate = false;
      this.stateDimensionNumber = 0;
    }
    this.stateDimensionAllChecked = completed;
  }

  stateDimensionUpdateNone(completed: boolean): void {
    this.stateDimensionNone = completed;
    if (completed) {
      this.typesSetAll(false);
      this.stateDimensionSetAll(false);
      this.imageTypesSetAll(false)
    }
  }

  typesCheckAllComplete(interaction = true): void {
    const booleanList = []
    for (const [key, value] of Object.entries(this.usedFeatures)) {
      value.forEach((annotation) => {
        booleanList.push(annotation.completed);
      })
    }

    this.typesNumber = booleanList.filter(Boolean).length
    if (this.typesNumber){
      if (!booleanList.includes(false)) {
        this.typesAllChecked = true;
      } else {
        this.typesAllChecked = false;
      }
      if (booleanList.includes(true) && booleanList.includes(false) ) {
        this.typesIndeterminate = true;
      } else {
        this.typesIndeterminate = false;
      }
      if(interaction) {
        this.stateDimensionNone = false;
      } else {
        if (this.filterFileData && this.filterFileData['feature'] && this.filterFileData['feature'].includes('none')) {
          this.stateDimensionNone = true;
        } else {
          this.stateDimensionNone = false;
        }
      }
    } else{
      this.typesAllChecked = false;
      this.typesIndeterminate = false;

    }
  }

  typesList(item: string, join: string): string {
    const types = []
    for (const [key, value] of Object.entries(this.usedFeatures)) {
      value.forEach((annotation) => {
        if (annotation.completed) {
          types.push(annotation[item])
        }
      })
    }
    return types.join(join);
  }

  typesMatch(): void {
    const listID = []
    if(this.filterFileData && this.filterFileData['annotationType'] && this.usedFeatures && Object.keys(this.usedFeatures).length > 0) {
      for (const [key, value] of Object.entries(this.usedFeatures)) {
        value.forEach((feature) => {
          feature['completed'] = this.filterFileData['annotationType'].includes(feature['id']);
          listID.push(feature['id']);
        })
      }
      // check if filter exists in dataset
      let typesExisting = [...this.filterFileData['annotationType']];
      this.filterFileData['annotationType'].forEach((feature) => {
        if(!listID.includes(feature)) {
          typesExisting = typesExisting.filter((item) => {return item !== feature})
        }
      })
      this.filterFileData['annotationType'] = typesExisting;
    }
    this.typesCheckAllComplete(false);
  }

  typesSetAll(completed: boolean): void {
    for (const [key, value] of Object.entries(this.usedFeatures)) {
      value.forEach((annotation) => {
        annotation.completed = completed;
      })
    }
    if(completed) {
      this.stateDimensionUpdateNone(false);
      this.typesCheckAllComplete();
    } else {
      this.typesIndeterminate = false;
      this.typesNumber = 0;
    }
    this.typesAllChecked = completed;
  }

  imageTypesMatch(): void {
    if(this.filterFileData && this.filterFileData['imageType'] && this.usedImageTypes && this.usedImageTypes.length > 0) {
        this.usedImageTypes.forEach((type) => {
          type['completed'] = this.filterFileData['imageType'].includes(type.value);
        })
    }
    this.imageTypeCheckAllComplete();
  }

  imageTypeCheckAllComplete(): void {
    const booleanList = []
    this.usedImageTypes.forEach((type) =>{
      booleanList.push(type.completed);
    });
    this.imageTypeNumber = booleanList.filter(Boolean).length
    if (this.imageTypeNumber>0){
      if (!booleanList.includes(false)) {
        this.imageTypeAllChecked = true;
      } else {
        this.imageTypeAllChecked = false;
      }
      if (booleanList.includes(true) && booleanList.includes(false) ) {
        this.imageTypesIndeterminate = true;
      } else {
        this.imageTypesIndeterminate = false;
      }
    } else{
      this.imageTypesIndeterminate = false;
      this.imageTypeAllChecked = false;
    }
  }

  imageTypesSetAll(completed: boolean): void {
    for (let  type of this.usedImageTypes) {
        type.completed = completed;
      }
    if(completed) {
      this.stateDimensionUpdateNone(false);
      this.imageTypeCheckAllComplete();
    } else {
      this.imageTypesIndeterminate = false;
      this.imageTypeNumber = 0;
    }
    this.imageTypeAllChecked = completed;
  }
}
