import { AfterViewInit, Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { searchToFilter } from './../../shared/helpers/data-helpers';
import { Previewer } from 'pagedjs';
import { TranslateService } from '@ngx-translate/core';

import { AnnotationTypesObj, AnnotationStateDimensionObj } from './../../shared/annotations/annotation-types';
import { Annotation, AnnotationsStateDimension } from '../files/file/sidebar/annotations/annotation.model';
import { CameraDictionaryObj } from './../../shared/dictionarys/cameras';
import { FileModel } from './../files/file/file.model';
import { FilesService, FilterFileData } from './../files/files.service';
import { InspectionService } from './../files/inspection/inspection.service';
import { Login } from './../login/login.model'
import { LoginStateService } from './../login/login-state.service';
import { LongTextDialogComponent } from './../../shared/long-text-dialog/long-text-dialog.component';
import { OLLayerService } from './../../shared/openlayers/layer.service';
import { OLMapsService } from './../../shared/openlayers/maps.service';
import { OrdersService } from './../../shared/orders/orders.service';
import { PdfEditorServiceService } from './pdf-editor-service.service';
import { Permission, PermissionRole } from './../files/file/permission.model'
import { UserRole, AccountType } from './../users/models/user.model'

import Map from 'ol/Map';
import Interaction from 'ol/interaction/Interaction';
import { AnnotationsService } from '../files/file/sidebar/annotations/annotations.service';
import { LegacyClientOrder } from 'src/app/shared/orders/orders.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { SlackService } from 'src/app/shared/slack/slack.service';


interface ReportSummary {
  generatedDate: string
  createDate: Array<string>
  cameraModels: Array<string>
  annotationCount: {
    advisory: number,
    low: number,
    middle: number,
    high: number,
    urgent: number
  }
}

@Component({
  selector: 'app-pdf-editor',
  templateUrl: './pdf-editor.component.html',
  styleUrls: ['./pdf-editor.component.scss']
})
export class PdfEditorComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('content') content: ElementRef;
  @ViewChild('output') output: ElementRef;

  annotationStateDimensionObj = AnnotationStateDimensionObj
  annotationTypesObj = {};
  currentLang: string;
  dataSource: Array<FileModel> = [];
  dataSourceFiltered: Array<FileModel> = [];
  filterFileData: FilterFileData
  folderModel: FileModel;
  iframe: HTMLIFrameElement;
  loading: boolean;
  loadingObj = {};
  login: Login;
  reportSummary = {} as ReportSummary;
  activeFolderID$: BehaviorSubject<string> = new BehaviorSubject(null); // null means pristine, untouched
  inspectionAddress: string;
  orderModel: LegacyClientOrder;
  overviewImage;
  accountType = AccountType;

  metaStatus = {
    'metaName': true,
    'metaDate': true,
    'metaTags': true,
    'metaPosition': true,
    'metaAltitude': true,
    'metaHeading': true
  }

  overviewMap: Map;
  overviewMapInteractions: Interaction[];
  overviewMapInteractionsActive = true;;
  pdfElement: any;
  permissionRole = PermissionRole;
  viewLoaded = false;
  linkedDataSource = false

  private ngDestroy$ = new Subject();

  constructor(
    private dialog: MatDialog,
    public loginStateService: LoginStateService,
    private oLLayerService: OLLayerService,
    private oLMapsService: OLMapsService,
    private pdfService: PdfEditorServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private ordersService: OrdersService,
    public filesService: FilesService,
    public inspectionService: InspectionService,
    private annotationsService: AnnotationsService,
    private dialogService: DialogService,
    private slackService: SlackService
  ) {
    this.loading = true
    // create Object without inspectionTypes to translate every annotationType
    for (const [key, value] of Object.entries(AnnotationTypesObj)) {
      for (const [keyNested, valueNested] of Object.entries(value)) {
        this.annotationTypesObj[String(keyNested)] = valueNested;
      }
    }

    this.currentLang = this.translate.currentLang
    this.pdfService.PDFConfiguration['language'] = this.currentLang
    this.translate.onLangChange.subscribe((event: any) => {
      if(this.pdfElement){
        this.currentLang = event.lang;
        this.iframe.parentNode.removeChild(this.iframe);
        this.pdfElement = this.createFrame();
        this.generatePDF();
      }
    });
    this.loginStateService.login$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(login => {
      this.login = login;
    });

    this.router.events
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      const firstChild = this.route.snapshot.parent.children[0];
      this.activeFolderID$.next(firstChild.params['folderID']);
    })
  }

  get hasDescription(): boolean {
    return this.folderModel && this.folderModel.inspection && this.folderModel.inspection?.summary && this.folderModel.inspection?.summary.length !== 0
  }


  ngOnDestroy(): void {
    setTimeout(()=>{
      this.router.navigate(
        [{ outlets: { detail: null }}],
        { queryParamsHandling: 'merge' }
      );
    },100)
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  convertToStringArray(metaTags): string[] {
    return Object.keys(metaTags).filter(key => metaTags[key] === true);
  }

  ngOnInit(): void {
    this.route.params
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(params => {
      const fileIDs = params['folderID']
      this.filesService.findOne(fileIDs)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
      response => {
        this.folderModel = response.data;

        // get inspection address
        
        this.inspectionAddress = this.getAddress(this.folderModel.orderID)
        this.getOrderModel(this.folderModel.orderID).pipe(takeUntil(this.ngDestroy$)).subscribe( (order)=>{
          this.orderModel=order
          this.pdfService.PDFConfiguration['projectName'] = this.orderModel?.projectName
          this.pdfService.PDFConfiguration['projectAddress'] = this.orderModel?.address
        })
        this.pdfService.PDFConfiguration['inspectionId'] = this.folderModel._id
        this.pdfService.PDFConfiguration['saveToFolderID'] = this.folderModel.parents[this.folderModel.parents?.length - 1]?._id.toString()
        this.pdfService.PDFConfiguration['orderId'] = this.folderModel.orderID
        this.pdfService.PDFConfiguration['description'] = this.folderModel.inspection?.summary
        this.pdfService.PDFConfiguration['metaTags'] = this.convertToStringArray(this.metaStatus) 

        // getFolderOrder
        this.ordersService.getOrders([Number(this.folderModel.orderID)]);

        this.route.queryParamMap.subscribe((params) => {
          this.filterFileData = {
            ...(params.get('feature') && { feature: params.get('feature').split(',') }),
            ...(params.get('annotationType') && { annotationType: params.get('annotationType').split(',') }),
            ...(params.get('minHeight') && { minHeight: Number(params.get('minHeight')) }),
            ...(params.get('maxHeight') && { maxHeight: Number(params.get('maxHeight')) }),
            ...(params.get('imageType') && { imageType: params.get('imageType').split(',') }),
          };
          this.pdfService.PDFConfiguration['filesFilter'] = this.constructFilesFilter(this.filterFileData);

          let search = '';
          search = search.trim();
          const filter = searchToFilter(search);
          if (!Object.keys(filter).length) {
            // Set folderID from the route ID
            filter['folderID'] = fileIDs && fileIDs.length === 24 ?
              { $oid: fileIDs } : { $exists: false };
          }

          const sort: object = { "inspection.fileID": 1 };
          // Get data from the server
          this.filesService.findMany(filter, sort, 0, 100000, null)
          .pipe(
            switchMap(data =>
              this.enrichFilesWithFeatures(data.data).pipe(
                takeUntil(this.ngDestroy$)
              )
            ),
            takeUntil(this.ngDestroy$))
          .subscribe(
            response => {
              this.dataSource = this.filesService.filterAnnotations(this.filterFileData, response.filter(file => file.thumbnailLink));
              if (this.dataSource[0]?.type){
                this.linkedDataSource = true;
              } else{
                this.linkedDataSource = false;
              }
              if (this.filterFileData['imageType'] && this.filterFileData['imageType'].includes('Wide') && !this.filterFileData['imageType'].includes('Thermal') )
              {
                this.dataSource.forEach(file => {
                  if (this.linkedDataSource){
                    file.thermalSubFileModel=undefined
                  }
                })
              }
              if (this.filterFileData['imageType'] && !this.filterFileData['imageType'].includes('Wide') && this.filterFileData['imageType'].includes('Thermal') )
              {
                this.dataSource.forEach(file => {
                  if (this.linkedDataSource){
                    file.master=undefined
                  }
                })
              }
              if (this.filterFileData['imageType'] && this.filterFileData['imageType'].includes('Wide') && this.filterFileData['imageType'].includes('Thermal') )
              {
                this.dataSource.forEach((file)=>{
                  if (file.thermalSubFileModel){
                    const thermalModel = this.dataSource.find(f=>f._id==file.thermalSubFileModel._id)
                    file.thermalSubFileModel=thermalModel
                  }
                })
                this.dataSource=this.dataSource.filter(file => file.type!=="Thermal")
              }
              if (!this.filterFileData['imageType'])
              {
                this.dataSource.forEach((file)=>{
                  if (file.thermalSubFileModel){
                    const thermalModel = this.dataSource.find(f=>f._id==file.thermalSubFileModel._id)
                    file.thermalSubFileModel=thermalModel
                  }
                })
                this.dataSource=this.dataSource.filter(file => file.type!=="Thermal")
              }
              let advisory = 0
              let low = 0
              let middle = 0
              let high = 0
              let urgent = 0
              let createDate = []
              let cameraModels = []

              // iterate trough files
              this.dataSource.forEach((file) => {

                // collect createDates
                if (file['meta']['CreateDate']) {
                  const fileDateParts = file['meta']['CreateDate'].split(' ')[0].split(':');
                  const fileDate = fileDateParts[2]+'.'+fileDateParts[1]+'.'+fileDateParts[0]

                  if (!createDate.includes(fileDate)) {
                    createDate.push(fileDate)
                  }
                }

                // collect cameraModel
                if (!cameraModels.includes(file['meta']['Model'])) {
                  cameraModels.push(file['meta']['Model'])
                }

                if(file.annotations) {
                  file.annotations = file.annotations.filter(annotation => annotation.stateDimension === AnnotationsStateDimension['urgent']).concat(
                    file.annotations.filter(annotation => annotation.stateDimension === AnnotationsStateDimension['high']),
                    file.annotations.filter(annotation => annotation.stateDimension === AnnotationsStateDimension['middle']),
                    file.annotations.filter(annotation => annotation.stateDimension === AnnotationsStateDimension['low']),
                    file.annotations.filter(annotation => annotation.stateDimension === AnnotationsStateDimension['advisory'])
                  )
                  file.annotations.forEach((annotation) => {
                    if (annotation.stateDimension === AnnotationsStateDimension['low']) {
                      low = low + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['middle']) {
                      middle = middle + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['advisory']) {
                      advisory = advisory + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['high']) {
                      high = high + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['urgent']) {
                      urgent = urgent + 1
                    }
                  })
                  const rest = this.getOnlyThermalAnnotation(file)
                  rest.forEach((annotation) => {
                    if (annotation.stateDimension === AnnotationsStateDimension['low']) {
                      low = low + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['middle']) {
                      middle = middle + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['advisory']) {
                      advisory = advisory + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['high']) {
                      high = high + 1
                    } else if (annotation.stateDimension === AnnotationsStateDimension['urgent']) {
                      urgent = urgent + 1
                    }
                  })
                }
              })

              
              const nowDate = new Date;
              const generatedDate = ("0" + nowDate.getDate()).slice(-2)+'.'+("0" + (nowDate.getMonth() + 1)).slice(-2)+'.'+nowDate.getFullYear();
              this.reportSummary.generatedDate = generatedDate;
              this.reportSummary.createDate = createDate;
              this.reportSummary.cameraModels = cameraModels.map((cameraModel) => {
                if(cameraModel) {
                  if (CameraDictionaryObj.hasOwnProperty(cameraModel.replace('-',''))) {
                    const cameraObj = CameraDictionaryObj[cameraModel.replace('-','')];
                    return cameraObj.brand + ' ' + cameraObj.model
                  } else {
                    return cameraModel
                  }
                }
              });
              this.reportSummary.annotationCount = {
                advisory: advisory,
                low: low,
                middle: middle,
                high: high,
                urgent: urgent
              }

              this.createFrame();
              this.generatePDF();
            });
          this.router.navigate(
            [{ outlets: { detail: 'PDFeditor' }}],
            { queryParamsHandling: 'merge' }
          );
        })
      });
    });
    this.pdfService.events$.forEach((event) => {
      switch (event[0]) {
        case 'savePDF':
          this.savePDF()
          break;
        case 'meta':
          if (event[2]) {
            this.showMeta(event[1])
          } else {
            this.hideMeta(event[1])
          }
          break;
      }
    });

    //This part is to for generating a PDF
    this.pdfService.getPDFConfiguration$.pipe(takeUntil(this.ngDestroy$),skip(1),debounceTime(500))
    .subscribe(response => {
      if (response) {
        if (this.loginStateService.loggedUser$.value?.accountType === AccountType.ADMIN) {
          this.getPDFConfiguration()
          this.pdfService.generatePDF().pipe(takeUntil(this.ngDestroy$))
          .subscribe(response => {
            if (response) {
              this.dialogService.showDialog("PDF Generation Started!", null, "It will be uploaded to your parent folder soon", null, false, true);
            } 
          },
          error =>{
            this.dialogService.showDialog("PDF Generation Failed!", null, error.error.errors[0] , null, false, true);
          });
        } else {
          this.slackService.sendNotification(this.router.url,this.loginStateService.loggedUser$.value?.email).pipe(takeUntil(this.ngDestroy$))
          .subscribe(response => {
            if (response) {
              this.dialogService.showDialog("PDF Generation Started!", null, "It will be uploaded to your parent folder soon", null, false, true);
            } 
          }),
          error => {
            console.error('Error sending Slack notification:', error);
          }  
        }
        
      }
    });

  }



  ngAfterViewInit(): void {
    this.viewLoaded = true;
  }

  private enrichFilesWithFeatures(data): Observable<any> {
    return this.annotationsService.getAllAnnotationsFeatures(this.activeFolderID$.value).pipe(
      takeUntil(this.ngDestroy$),
      map(response => {
        const featuresData = response.data;
  
        if (featuresData != null) {
          data.forEach(file => {
            const fileId = file._id;
            const fileFeatures = featuresData[fileId];
  
            if (fileFeatures) {
              file.typesStats = fileFeatures;
            }
          });
        }
  
        return data;
      })
    );
  }

  getOnlyThermalAnnotation(fileModel): Annotation[]{
    let result=[]
    if (fileModel.annotations){
      fileModel.thermalSubFileModel?.annotations?.forEach((anno)=>{
        if (fileModel.annotations.findIndex(a=> a.annoID==anno.annoID) ==-1){
          result.push(anno)
        }
      })
    }else{
      if (fileModel.thermalSubFileModel?.annotations){
        result = fileModel.thermalSubFileModel.annotations
      }
    }
    return result
  }

  getAddress(orderID): string {
    return this.ordersService.getAddress(orderID,false)
  }

  getOrderModel (orderID: number): Observable<LegacyClientOrder>{
    return this.ordersService.findClientLegacyOrder(orderID)
  }

  getImageYaw(file: FileModel): string {
    const yaw = this.filesService.getImageYaw(file)
    let text = this.convertDirection(yaw)
    return text + ' ('+ yaw + ')'
  }

  generatePDF(): void {
    setTimeout(() => {
      if (this.viewLoaded) {
        this.createPDF();
      } else {
        this.generatePDF();
      }
    }, 500);
  }

  makeMultiline(text: string): string {
    if (text) {
      return text
        .split('\n').join('<br>');
    }
  }

  toggleInteraction(): void {
    if(this.overviewMapInteractionsActive) {
      // remove
      if (this.currentLang === 'en') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "EDIT";
      } else if (this.currentLang === 'de') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Bearbeiten";
      } else if (this.currentLang === 'es') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Editar";
      } else if (this.currentLang === 'it') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Modifica";
      }
      this.overviewMapInteractions.forEach((interaction) => {
        this.overviewMap.removeInteraction(interaction);
      })
    } else {
      if (this.currentLang === 'en') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Finish editing";
      } else if (this.currentLang === 'de') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Bearbeiten beenden";
      } else if (this.currentLang === 'es') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Editar final";
      } else if (this.currentLang === 'it') {
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").innerHTML = "Modifica finale";
      }
      this.overviewMapInteractions.forEach((interaction) => {
        this.overviewMap.addInteraction(interaction);
      })
    }
    this.overviewMapInteractionsActive = !this.overviewMapInteractionsActive;
  }



  checkLoadingObj(): void {
    const values = Object.values(this.loadingObj);
    if (values.every(v => v === false)) {
      this.loading = false;
    }
  }

  createPDF(): void {
    const previewer = new Previewer();
    previewer.preview(
      this.content.nativeElement.innerHTML,
      ['/assets/css/inspection.css'],
      this.pdfElement
    ).then(() => {
      if (this.iframe) {
        this.overviewMap = this.oLMapsService.renderMap({target: this.iframe.contentWindow?.document.querySelector(".overviewMap")})
        this.overviewMapInteractions = this.overviewMap.getInteractions().getArray().slice();
        this.toggleInteraction();

        let hasOrtho = false;

        if (this.folderModel?.card?.thumbnail) {
          this.filesService.findOne(this.folderModel?.card?.thumbnail)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(
          response => {
            let mapDiv = this.iframe.contentWindow.document.querySelector(".thumbnail");           
            this.loadMap(mapDiv, response.data, null, response.data._id.toString());
            this.pdfService.PDFConfiguration["overviewImage"] = response.data.thumbnailLink
            this.overviewImage = response.data.thumbnailLink
          });
        }
        if(this.folderModel.inspection && this.folderModel.inspection.orthomosaic) {
          this.filesService.findOne(this.folderModel.inspection.orthomosaic)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(
          response => {
            this.oLLayerService.addOrtho(this.overviewMap, response.data)
            hasOrtho = true;
          });
        }
        //adding the ol-map
        this.oLLayerService.addImages(this.overviewMap, this.dataSource, !hasOrtho, true)
        this.dataSource.forEach((fileModel) => {
          const mapDiv = this.iframe.contentWindow.document.querySelector("._"+fileModel._id);
          this.loadMap(mapDiv, fileModel, fileModel.annotations, fileModel._id.toString());
          if (fileModel.thermalSubFileModel){
            const mapDivThermalMatch = this.iframe.contentWindow.document.querySelector("._match_"+fileModel.thermalSubFileModel._id);
            const thermal = fileModel.thermalSubFileModel
            this.loadMap (mapDivThermalMatch,thermal,thermal.annotations,thermal._id.toString(),false)
            if (thermal.annotations) {
                thermal.annotations.forEach((annotation, index) => {
                const correspondingIndex = fileModel.annotations?.findIndex((anno) => anno.annoID === annotation.annoID )
                const mapDiv = this.iframe.contentWindow.document.querySelector("._match_"+fileModel.thermalSubFileModel._id+correspondingIndex);
                this.loadMap(mapDiv, thermal, [annotation],thermal._id.toString()+index, true);
              })
            }
            if (this.getOnlyThermalAnnotation(fileModel).length!=0){
              const restAnnos = this.getOnlyThermalAnnotation(fileModel)
              restAnnos?.forEach((annotation, index) => {
                const mapDiv = this.iframe.contentWindow.document.querySelector("._matchrest_"+fileModel.thermalSubFileModel._id+index);
                this.loadMap(mapDiv, thermal, [annotation],thermal._id.toString()+index, true);
              })
            }
          }
          if (fileModel.annotations) {
            fileModel.annotations.forEach((annotation, index) => {
              const mapDiv = this.iframe.contentWindow.document.querySelector("._"+fileModel._id+index);
              this.loadMap(mapDiv, fileModel, [annotation],fileModel._id.toString()+index, true);
            })
          }
        })
        this.iframe.contentWindow.document.querySelector(".editDescription").addEventListener('click', this.helperEditSummary);
        this.iframe.contentWindow.document.querySelector(".editOverviewMap").addEventListener('click', this.helperEditMap);
        this.iframe.contentWindow.document.querySelector(".logo-upload").addEventListener('change', (event)=>{
          const files = (this.iframe.contentWindow.document.querySelector(".logo-upload") as HTMLInputElement).files;
          if (files && files.length > 0) {
              const file = files[0];
              // Use FileReader to read the file
              const reader = new FileReader();
              reader.onload = (e) => {
                  // Change the src of the logo-display image to the selected file
                  (this.iframe.contentWindow.document.getElementById('logo-display') as HTMLImageElement).src = e.target.result as string;
                  this.pdfService.PDFConfiguration["clientLogo"] = e.target.result.toString()
              };
              reader.readAsDataURL(file); // Convert the file to a data URL
          }
        });

        for (let key in this.metaStatus) {
          if (!this.metaStatus[key]) {
            this.hideMeta(key)
          }
        }
        this.pdfService.PDFConfiguration["clientLogo"] = (this.iframe.contentWindow.document.getElementById('logo-display') as HTMLImageElement).src
      }
    })
  }

  existCorrespondingThermalAnno(fileModel, annotation){
    const thermalModel = fileModel.thermalSubFileModel
    if (thermalModel.annotations){
      return thermalModel?.annotations.findIndex(anno=> anno.annoID===annotation.annoID)==-1? false:true;
    }
    return false
  }

  showMeta(metaClass: string): void {
    if (this.iframe.contentWindow) {
      const elementArray = this.iframe.contentWindow.document.querySelectorAll<HTMLElement>('.'+metaClass);
      elementArray.forEach((element) => {
        element.style.cssText = '';
        (element.children[0] as HTMLElement).style.cssText = ''
      })
    }
  }

  adjustFeatures () {
    let features = []
    this.dataSource.forEach ((file) => {
      file.annotations?.forEach((annotation) => {
        if (!features.includes(annotation.feature)) {
          features.push(annotation.feature)
        }
      })
    })
    return features
  }

  constructFilesFilter(filterFileData):any {
    let filesFilter = {}
    if (filterFileData['feature']) {
      let stateDimension = []
      filterFileData['feature'].forEach(feature => {
        switch(feature) { 
          case 'advisory': { 
            stateDimension.push(20) 
             break; 
          } 
          case 'low': { 
            stateDimension.push(40) 
             break; 
          } 
          case 'middle': { 
            stateDimension.push(60) 
             break; 
          } 
          case 'high': { 
            stateDimension.push(80) 
             break; 
          } 
          case 'urgent': { 
            stateDimension.push(100) 
             break; 
          } 
       }
      })
      filesFilter['stateDimensions'] = stateDimension
    }

    if (filterFileData['annotationType']) {
      filesFilter['features'] = this.adjustFeatures()
    }

    if (filterFileData['imageType']) {
      filesFilter['imageTypes'] = filterFileData['imageType']
    }

    if (filterFileData['minHeight']) {
      filesFilter['minRelativeAltitude'] = filterFileData['minHeight']
    }

    if (filterFileData['maxHeight']) {
      filesFilter['maxRelativeAltitude'] = filterFileData['maxHeight']
    }

    return filesFilter
  }

  getBase64ForOverviewMap():string {
    var mapCanvas = document.createElement('canvas');
    const size = this.overviewMap.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    var mapContext = mapCanvas.getContext('2d');
    Array.prototype.forEach.call(this.overviewMap.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer, .GeoTiff.Ortho '), function(canvas) {
      if (canvas.width > 0) {
        const opacity =
          canvas.parentNode.style.opacity || canvas.style.opacity;
        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
        let matrix;
        const transform = canvas.style.transform;
        if (transform) {
          // Get the transform parameters from the style's transform matrix
          matrix = transform
            .match(/^matrix\(([^\(]*)\)$/)[1]
            .split(',')
            .map(Number);
        } else {
          matrix = [
            parseFloat(canvas.style.width) / canvas.width,
            0,
            0,
            parseFloat(canvas.style.height) / canvas.height,
            0,
            0,
          ];
        }
        // Apply the transform to the export map context
        CanvasRenderingContext2D.prototype.setTransform.apply(
          mapContext,
          matrix,
        );
        const backgroundColor = canvas.parentNode.style.backgroundColor;
        if (backgroundColor) {
          mapContext.fillStyle = backgroundColor;
          mapContext.fillRect(0, 0, canvas.width, canvas.height);
        }
        mapContext.drawImage(canvas, 0, 0);
      }
    });
    return mapCanvas.toDataURL('image/png');
  }

  getPDFConfiguration() {
    this.pdfService.PDFConfiguration['overviewMapImage'] = this.getBase64ForOverviewMap()
    this.pdfService.PDFConfiguration["clientLogo"] = (this.iframe.contentWindow?.document.getElementById('logo-display') as HTMLImageElement)?.src
    this.pdfService.PDFConfiguration["overviewImage"] = this.overviewImage
    this.pdfService.PDFConfiguration['filesFilter'] = this.constructFilesFilter(this.filterFileData)
    this.pdfService.PDFConfiguration['projectAddress'] = this.orderModel?.address
    this.pdfService.PDFConfiguration['inspectionId'] = this.folderModel._id
    this.pdfService.PDFConfiguration['orderId'] = this.folderModel.orderID
    this.pdfService.PDFConfiguration['description'] = this.folderModel.inspection?.summary
    this.pdfService.PDFConfiguration['projectName'] = this.orderModel?.projectName
    this.pdfService.PDFConfiguration['language'] = this.currentLang
    this.pdfService.PDFConfiguration['metaTags'] = this.convertToStringArray(this.metaStatus) 
  }

  hideMeta(metaClass: string): void {
    this.metaStatus[metaClass] = false;
    if (this.iframe.contentWindow) {
      const elementArray = this.iframe.contentWindow.document.querySelectorAll<HTMLElement>('.'+metaClass);
      elementArray.forEach((element) => {
        element.style.cssText = 'visibility: hidden; padding:0; height:0;';
        (element.children[0] as HTMLElement).style.cssText = 'height:0;'
      })
    }
    this.pdfService.PDFConfiguration['metaTags'] = this.convertToStringArray(this.metaStatus) 
  }

  helperEditMap(): void {
    (document.querySelector(".toggleOverviewMap") as HTMLButtonElement).click();
  }

  // helperEditSummary is needed to open Dialog from iframe.
  helperEditSummary(): void {
    (document.querySelector(".editSummary") as HTMLButtonElement).click();
  }

  editSummary(): void {
    const dialogRef = this.dialog.open(LongTextDialogComponent, {
      width: '720px',
      data: {header: 'PDF-EDITOR.SUMMARY', content: this.folderModel.inspection?.summary}
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      if (response !== undefined && response.content !== undefined) {
        this.makeInspectionObj();
        this.folderModel.inspection.summary = response.content;
        this.setUpdate({ inspection: this.getInspectionObj() });

        this.iframe.parentNode.removeChild(this.iframe);
        this.pdfElement = this.createFrame();

        this.generatePDF();
      }
    });
  }

  getInspectionObj(): any {
    const inspection = {};
    if (this.folderModel.inspection?.summary) { Object.assign(inspection, { summary: this.folderModel.inspection?.summary }); }
    if (this.folderModel.inspection.orthomosaic) { Object.assign(inspection, { orthomosaic: this.folderModel.inspection.orthomosaic }); }
    return inspection
  }

  setUpdate(values): void {
    const fileUpdates: FileModel = {};
    Object.assign(fileUpdates, { _id: this.folderModel._id });
    this.folderModel.modified = new Date();
    Object.assign(fileUpdates, values);

    this.updateOne(fileUpdates, this.folderModel);
  }

  updateOne(fileUpdates: FileModel, fileModel: FileModel): void {
    //this.fetchingChange$.next(true);

    this.filesService.updateOne(fileUpdates)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      () => {
        // update data model
        fileModel = {
          ...fileModel
        };
        this.filesService.updateItem(fileModel);
      },
      error => {
        //this.fetchingChange$.next(false);
        //this.dialogService.showDialog('FILE.SAVING_FAILED', error.status, error.url, error.error);
      }
    );
  }

  convertMetaDate(inputString: string): string {
    if (inputString) {
      const parts = inputString.split(' ');
      const date = parts[0].split(':')
      const time = parts[1].split(':')

      return date[2]+'.'+date[1]+'.'+date[0]+' '+time[0]+':'+time[1]
    }
  }

  convertDirection(direction): string {
    const x = direction;
    switch (true) {
      case (x > -180 && x <= -157.5):
        return 'S';
      case (x > -157.5 && x <= -112.5):
        return 'SW';
      case (x > -112.5 && x <= -67.5):
        return 'W';
      case (x > -67.5 && x <= -22.5):
        return 'NW';
      case (x > -22.5 && x <= 22.5):
        return 'N';
      case (x > 22.5 && x <= 67.5):
        return 'NE';
      case (x > 67.5 && x <= 112.5):
        return 'E';
      case (x > 112.5 && x <= 157.5):
        return 'SE';
      case (x > 157.5 && x <= 180):
        return 'S';
      default:
        return '';
    }
  }

  makeInspectionObj(): void {
    if (!this.folderModel.inspection) {
      this.folderModel.inspection = {};
    }
  }

  loadMap(mapDiv: any, fileModel: FileModel, annotations: any, id: string, showAnnotation = false): void {
    if (!showAnnotation) {
      const map = this.oLMapsService.renderImage({
        extent: [0, 0, fileModel.width, fileModel.height],
        target: mapDiv,
        image: fileModel.thumbnailLink,
        interactions: false,
        annotations: annotations,
        labeling: true
      });

     map.on('loadstart', () => {
        this.loadingObj[id] = true;
      });

      // TODO: this part should be optimized since for some reason loadend does not always
      // work on dev and prod but locally it works fine..
      map.on('rendercomplete', () => {
          this.loadingObj[id] = false;
          this.checkLoadingObj();
        });

      map.on('postcompose', () => {
          this.loadingObj[id] = false;
          this.checkLoadingObj();
      });

        map.on('loadend', () => {
          this.loadingObj[id] = false;
          this.checkLoadingObj();
        });
      map.getView().fit([0, 0, fileModel.width, fileModel.height]);
    } else {
      const annoExtent = this.oLLayerService.getAnnotationExtent(annotations[0])
      // check if high res img is needed, based on the height and with of the annotation
      let image = fileModel.thumbnailLink
      if (
        ((annoExtent[2] - annoExtent[0]) / fileModel.width * 920) <= 302 ||
        ((annoExtent[3] - annoExtent[1]) / fileModel.height * 720) < 206
      ) {
        image = fileModel.fileURL
      }
      const map = this.oLMapsService.renderImage({
        extent: [0, 0, fileModel.width, fileModel.height],
        target: mapDiv,
        image: image,
        imagePreview: fileModel.thumbnailLink,
        interactions: false,
        annotations: annotations,
        labeling: false
      });
      map.on('loadstart', () => {
        this.loadingObj[id] = true;
      });
      map.on('loadend', () => {
        this.loadingObj[id] = false;
        this.checkLoadingObj();
      });

      map.getView().fit(this.oLLayerService.getAnnotationExtent(annotations[0]), {padding:[15, 15, 15, 15]});
    }
  }

  // IFrame Shit
  createFrame(): void {
    this.iframe = document.createElement("iframe");
    this.iframe.classList.add("pagedPreviewer-previewFrame");
    this.iframe.style.border = "0";
    this.iframe.style.width = "100%";
    this.iframe.style.height = "100%";
    this.output.nativeElement.appendChild(this.iframe);

    setTimeout(() => {

      this.pdfElement = this.iframe.contentDocument.createElement("div");
      this.pdfElement.classList.add("pdfReport");

      this.iframe.contentDocument.body.append(this.pdfElement);

      const style = this.iframe.contentDocument.createElement("style");

      style.innerText = `.pagedjs_page .pagedjs_margin-top-left > .pagedjs_margin-content::after {
        content: '` + this.inspectionAddress + `';
      }

      .pagedjs_page .pagedjs_margin-bottom-left > .pagedjs_margin-content::after {
        content: 'Confidential - FairFleet © ` + new Date().getFullYear() + `';
      }
      `
      if (this.folderModel.orderID) {
        style.innerText = style.innerText + `.pagedjs_page .pagedjs_margin-top-center > .pagedjs_margin-content::after {
          content: '- FF` + this.folderModel.orderID+ ` -';
        }`;
      }
      this.iframe.contentDocument.body.appendChild(style);

      const linkPDF = this.iframe.contentDocument.createElement("link");
      linkPDF.rel  = 'stylesheet';
      linkPDF.type = 'text/css';
      linkPDF.href = '/assets/css/pdf.css';
      this.iframe.contentDocument.body.appendChild(linkPDF);

      const linkInspection = this.iframe.contentDocument.createElement("link");
      linkInspection.rel  = 'stylesheet';
      linkInspection.type = 'text/css';
      linkInspection.href = '/assets/css/inspection.css';
      this.iframe.contentDocument.body.appendChild(linkInspection);
    }, 100);
  }

  savePDF(): void {
    var t = (document.querySelector(".pagedPreviewer-previewFrame") as HTMLIFrameElement).contentWindow;
    t.focus(), t.print();
  }
}
