<div
    class="annotationsBody"
    [class.dark]="this.theme && this.theme.includes('dark')">
  <div class="body">
    <ng-form [formGroup]="formPDF">
      <div class="metaData">
        <p><mat-icon class="rtl-mirror">info</mat-icon> {{ 'PDF-EDITOR.META_TAGS' | translate }}</p>
        <p>
          <mat-checkbox formControlName="metaName" (change)="change($event, 'metaName')">{{ 'PDF-EDITOR.NAME' | translate }}</mat-checkbox>
        </p>
        <p>
          <mat-checkbox formControlName="metaDate" (change)="change($event, 'metaDate')">{{ 'PDF-EDITOR.DATE' | translate }}</mat-checkbox>
        </p>
        <p>
          <mat-checkbox formControlName="metaPosition" (change)="change($event, 'metaPosition')">{{ 'PDF-EDITOR.POSITION' | translate }}</mat-checkbox>
        </p>
        <p>
          <mat-checkbox formControlName="metaAltitude" (change)="change($event, 'metaAltitude')">{{ 'PDF-EDITOR.ALTITUDE' | translate }}</mat-checkbox>
        </p>
        <p>
          <mat-checkbox formControlName="metaHeading" (change)="change($event, 'metaHeading')">{{ 'PDF-EDITOR.HEADING' | translate }}</mat-checkbox>
        </p>
      </div>
    </ng-form>
  </div>
  <div class="footer">
    <button
      (click)="generatePDF()"
      color="accent"
      mat-raised-button
      title="Generate PDF"
      type="button">
      <mat-icon class="rtl-mirror">description</mat-icon>
      {{ 'SAVE' | translate | uppercase }}
    </button>
    <!--<button
      (click)="savePDF()"
      color="accent"
      mat-raised-button
      title="Generate PDF"
      type="button">
      <mat-icon class="rtl-mirror">description</mat-icon>
      {{ 'SAVE' | translate | uppercase }}
    </button>  -->
  </div>
</div>
