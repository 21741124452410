<div class="content">
  <mat-card class="portfolio-status">
    <mat-card-title>
      {{ 'SITE.PROPERTY_SCORE' | translate | uppercase}}
      <div class="tooltip">
        <mat-icon mat-list-icon>info_outline</mat-icon>
        <div class="tooltip-body mat-elevation-z2">
          <h3>{{ 'SITE.TOOLTIP.SCORING' | translate }}:</h3>
          <ul>
            <li>
              <span class="point">{{ 'SITE.TOOLTIP.CRITICAL_LABEL' | translate }}:
                <span class="explanation">
                  {{ 'SITE.TOOLTIP.CRITICAL_EXPLANATION' | translate }}
                </span>
              </span>
            </li>
            <li>
              <span class="point">
                {{ 'SITE.TOOLTIP.MEDIOCRE_LABEL' | translate }}:
                <span class="explanation">
                  {{ 'SITE.TOOLTIP.MEDIOCRE_EXPLANATION' | translate }}
                </span>
              </span>
            </li>
            <li>
              <span class="point">
                {{ 'SITE.TOOLTIP.FAIR_LABEL' | translate }}:
                <span class="explanation">
                  {{ 'SITE.TOOLTIP.FAIR_EXPLANATION' | translate }}
                </span>
              </span>
            </li>
          </ul>
          <p class="disclaimer-headline">{{ 'SITE.TOOLTIP.DISCLAIMER' | translate }}:</p>
          <p class="disclaimer">{{ 'SITE.TOOLTIP.DISCLAIMER_TEXT' | translate }}</p>
        </div>
      </div>
      :
    </mat-card-title>
    <mat-card-content>
      <span class="badge" [style.background-color]="color">{{ indicator }}</span>
      <div class="progress">
        <div class="dividers">
          <span class="divider"></span>
          <span class="divider"></span>
          <span class="divider"></span>
          <span class="divider"></span>
        </div>

        <div
          class="progress-bar"
          [style.background-color]="color"
          [style.width]="progress"
        >
          <p>{{ labelCondition | translate }}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="site-overview">
    <mat-card-header>
      <mat-card-title>
        {{ 'SITE.STATISTICAL_OVERVIEW' | translate }}
      </mat-card-title>
      <mat-card-actions>
        <button
          color="accent"
          mat-stroked-button
          (click)="openSiteDialog()"
        >{{ 'SITE.MODIFY' | translate }}</button>
        <button
          color="accent"
          class="link-button"
          mat-raised-button
          (click)="openLinkDialog()"
        >{{ 'SITE.LINK_ORDER' | translate }}</button>
      </mat-card-actions>
    </mat-card-header>
    <mat-card-content>
      <div class="cards">
        <h2>{{ 'SITE.RESULTS' | translate }}:</h2>
        <div class="cards-container">
          <ul>
            <li *ngFor="let result of results">
              <mat-card (click)="openResult(result)">
                <mat-card-content>
                  <div class="preview">
                    <div
                      *ngIf="result.thumbnailLink && !result?.mimeType?.startsWith('video')"
                      [ngStyle]="{'background-image': 'url(' + result.thumbnailLink + ')'}"
                      class="image"
                      bis_skin_checked="1">
                      <mat-icon *ngIf="result.tags?.includes('inspection')" class="overlayIcon inspect" svgIcon="fairfleet_inspect"></mat-icon>
                      <mat-icon *ngIf="result.tags?.includes('360_stitched')" class="overlayIcon" disabled> 360 </mat-icon>
                    </div>

                    <div
                      *ngIf="!result.thumbnailLink && !result?.mimeType?.startsWith('video')"
                      class="image">
                      <mat-icon class="overlayIcon  darkColor" disabled *ngIf="!result.tags?.includes('inspection') "> {{filesService.getFileIcon(result)}}</mat-icon>
                      <mat-icon *ngIf="result.tags?.includes('inspection')" class="overlayIcon inspect darkColor" svgIcon="fairfleet_inspect"></mat-icon>
                      <mat-icon *ngIf="result.tags?.includes('360_stitched')" class="overlayIcon darkColor" disabled> 360 </mat-icon>
                    </div>

                    <div
                        *ngIf="result?.mimeType?.startsWith('video')"
                        class="videoWrapper">
                      <video
                          [muted]="'muted'"
                          crossorigin
                          playsinline
                          loop
                          preload="auto"
                          onmouseover="this.play()"
                          onmouseout="this.pause();"
                          onloadstart="this.currentTime = 1;"
                          src="{{ result?.webViewLink }}">
                      </video>
                      <mat-icon class="overlayIcon" disabled> play_circle </mat-icon>
                    </div>

                  </div>
                  <h3>{{ result.name }}</h3>
                </mat-card-content>
              </mat-card>
            </li>
          </ul>
        </div>
      </div>
      <div class="statistics">
        <h2>{{ 'SITE.STATISTICS' | translate }}</h2>
        <div class="statistic-container">
          <div class="total">
            <span class="value">{{  this.stats?.annotationStats.total }}</span>
            <p>{{ 'SITE.TOTAL' | translate }}</p>
          </div>
          <div class="type-chart">
            <h3>{{ 'SITE.TYPE_DISTRIBUTION' | translate }}:</h3>
            <app-doughnut-chart
              [dataSetter]="getTypeDistributionData()"
              [showLegend]="true"
            ></app-doughnut-chart>
          </div>
          <div class="distribution-chart">
            <h3>{{ 'SITE.SEVERITY_DISTRIBUTION' | translate }}:</h3>
            <app-doughnut-chart
              [dataSetter]="severityDistributionData"
              [showLegend]="true"
            ></app-doughnut-chart>
          </div>
          <div class="small-chart" [style.border-color]="'#7385A7'">
            <app-doughnut-chart
              [dataSetter]="smallData[0]"
              [value]="stats?.annotationStats.advisory"
              [showLegend]="false"
            ></app-doughnut-chart>
            <p [style.color]="'#7385A7'">{{ 'SITE.ADVISORY_COUNT' | translate }}</p>
          </div>
          <div class="small-chart" [style.border-color]="'#27C100'">
            <app-doughnut-chart
              [dataSetter]="smallData[4]"
              [value]="stats?.annotationStats.low"
              [showLegend]="false"
            ></app-doughnut-chart>
            <p [style.color]="'#27C100'">{{ 'SITE.MINOR_COUNT' | translate }}</p>
          </div>
          <div class="small-chart" [style.border-color]="'#DDB010'">
            <app-doughnut-chart
              [dataSetter]="smallData[3]"
              [value]="stats?.annotationStats?.medium"
              [showLegend]="false"
            ></app-doughnut-chart>
            <p [style.color]="'#DDB010'">{{ 'SITE.IMPORTANT_COUNT' | translate }}</p>
          </div>
          <div class="small-chart" [style.border-color]="'#DE1F1F'">
            <app-doughnut-chart
              [dataSetter]="smallData[2]"
              [value]="stats?.annotationStats.high"
              [showLegend]="false"
            ></app-doughnut-chart>
            <p [style.color]="'#DE1F1F'">{{ 'SITE.CRITICAL_COUNT' | translate }}</p>
          </div>
          <div class="small-chart" [style.border-color]="'#9F44D0'">
            <app-doughnut-chart
              [dataSetter]="smallData[1]"
              [value]="stats?.annotationStats.urgent"
              [showLegend]="false"
            ></app-doughnut-chart>
            <p [style.color]="'#9F44D0'">{{ 'SITE.URGENT_COUNT' | translate }}</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="inspect-files" *ngIf="immidiateAnnos.length">
    <mat-card-title>
      {{ 'SITE.IMMEDIATE_ACTIONS' | translate }}
    </mat-card-title>
    <mat-card-content>
      <table
        class="files"
        mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="annoType">
          <th mat-header-cell *matHeaderCellDef> {{ 'SITE.LIST.ANNOTATION_TYPE' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ inspectionService.getFeature(element.feature, currentLang) }} </td>
        </ng-container>

        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef> {{ 'SITE.LIST.COMMENT' | translate }} </th>
          <td mat-cell *matCellDef="let element" class="comment"> {{ element.comment }} </td>
        </ng-container>

        <ng-container matColumnDef="lastModification">
          <th mat-header-cell *matHeaderCellDef> {{ 'SITE.LIST.LAST_MODIFICATION' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.modified | date: 'dd.MM.YYYY HH:mm:ss' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="showAnnotations(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="annotations" #imageMap></div>
    </mat-card-content>
  </mat-card>
  <mat-card class="order" *ngIf="recentOrder">
    <mat-card-title>
      {{ 'SITE.ORDER.TITLE' | translate }}
    </mat-card-title>
    <mat-card-content>
      <div class="records">
        <p *ngIf="recentOrder.address">
          <span class="label">
            <mat-icon class="material-icons-outlined">location_on</mat-icon>
            {{ 'SITE.ORDER.ADDRESS' | translate }}:
          </span>
          <span class="value">{{ recentOrder.address }}</span>
        </p>
        <p>
          <span class="label">
            <mat-icon class="material-icons-outlined">update</mat-icon>
            {{ 'SITE.ORDER.STATUS' | translate }}:
          </span>
          <span class="value status" [style.background-color]="getOrderColor(getOrderGroup(recentOrder.status), true)">
            {{ getOrderStatus(recentOrder.status) | uppercase | translate | uppercase }}
            <mat-icon class="material-icons">autorenew</mat-icon>
          </span>
        </p>
        <p *ngIf="recentOrder.projectName">
          <span class="label">
            <mat-icon class="material-icons-outlined">description</mat-icon>
            {{ 'SITE.ORDER.PROJECT' | translate }}:
          </span>
          <span class="value">{{ recentOrder.projectName }}</span>
        </p>
        <p *ngIf="recentOrder.comment">
          <span class="label">
            <mat-icon class="material-icons-outlined">forum</mat-icon>
            {{ 'SITE.ORDER.COMMENT' | translate }}:
          </span>
          <span class="value">{{ recentOrder.comment }}</span>
        </p>
        <p *ngIf="recentOrder.typeOfService">
          <span class="label">
            <mat-icon class="material-icons">grid_view</mat-icon>
            {{ 'SITE.ORDER.TYPES_SERVICES' | translate }}:
          </span>
          <span class="value">{{ recentOrder.typeOfService }}</span>
        </p>
        <p *ngIf="recentOrder.services">
          <span class="label">
            <mat-icon class="material-icons">widgets</mat-icon>
            {{ 'SITE.ORDER.SERVICES' | translate }}:
          </span>
          <span class="value">{{ recentOrder.services }}</span>
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>

