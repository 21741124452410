import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'expireIn'
})
export class ExpireInPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string | null): string {
    if (!value) {
      return '-';
    }

    const expirationDate = new Date(value);
    const today = new Date();
    const timeDiff = expirationDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysLeft <= 0) {
      return 'ORDERS.ORDER_EXPIRED';
    } else if (daysLeft === 1) {
      return `${daysLeft} ${this.translate.instant('ORDER.DAY')}`;
    }

    return `${daysLeft} ${this.translate.instant('ORDER.DAYS')}`;
  }
}
