import { Injectable } from '@angular/core';
import { Polygon } from 'ol/geom';
import { Measurement } from './measurement.model';
import { getArea } from 'ol/sphere';
import { Feature } from 'ol';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Extent } from 'ol/extent';
import TileLayer from 'ol/layer/WebGLTile';
import Map from 'ol/Map';

@Injectable({
  providedIn: 'root'
})
export class MeasurementsService {

  constructor(
    private http: HttpClient,
  ) {}

  //--------------------------------------------------------- API Calls ---------------------------------------------------------//
  public findOne(measurementId: string): Observable<any>{
    return this.http.get<Measurement>(`${environment.apiPath}measurements/${measurementId}`);
  }

  public findMany(fileID: string): Observable<any>{
    let filter = {}
    if (fileID) {
      filter['fileID'] = fileID
    }
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    return this.http.get<Measurement>(`${environment.apiPath}measurements`, { params });
  }

  public insertOne(measurement: Measurement): Observable<any>{
    return this.http.post(`${environment.apiPath}measurements`, measurement);
  }

  public updateOne(measurement: Measurement): Observable<any>{
    return this.http.patch(`${environment.apiPath}measurements/${measurement._id}`,measurement);
  }

  public softDeleteOne(measurementId: string): Observable<any>{
    return this.http.delete<Measurement>(`${environment.apiPath}measurements/${measurementId}`);
  }

  public hardDeleteOne(measurementId: string): Observable<any>{
    return this.http.delete<Measurement>(`${environment.apiPath}measurements/${measurementId}/hard`);
  }

  //------------------------------------------------ Measurement Document Enrichers ----------------------------------------------//
  public enrichVolumeMeasurementWithComputedFields(measurement: Measurement, hasElevationValues: boolean, feature: Feature ,poly: Polygon, map: Map ) {
    measurement.value2d = getArea(poly);
    measurement.selected = false
    measurement.modifiedAt = new Date().toISOString();
    measurement.feature = feature

    if (hasElevationValues) {
      const geoTiffLayer = map.getAllLayers()[3] as TileLayer
      const pixels = [];
      let interiorPixels = this.extractInteriorPixels(poly.getExtent(),poly);
      //get all pixels inside the coordinates and push elevation
      interiorPixels.forEach((row) => {
        const line = []
        row.forEach((coordinate)=>{
          if (coordinate.length>0){
            const pixel = map.getPixelFromCoordinate(coordinate);        
            line.push({coordinates: coordinate, elevation:geoTiffLayer.getData(pixel)[0]});
          } else {
            line.push(undefined);
          }
        })
        pixels.push(line)
      });
      const elevationValues = this.extractElevations(pixels)
      const {min,max} = this.getMinMaxValues(elevationValues)
      measurement.elevationsMax = max
      measurement.elevationsMin = min
      measurement.pixels = pixels
      measurement.value3d = this.calculateSurfaceArea(measurement.value2d, elevationValues)
      measurement.pixelSurface = this.getPixelSurface(elevationValues, measurement.value2d)
      measurement.baseBordersAverageElevation = this.getBordersElevationAverage(elevationValues)
      
      measurement.volumeAverageLowestPoint = this.calculateVolumeAverageLowestPoint(elevationValues, measurement.pixelSurface, measurement.baseBordersAverageElevation);
      measurement.volumeRealLowestPoint = this.calculateVolumeRealLowestPoint(elevationValues, measurement.pixelSurface, measurement.elevationsMin);
      measurement.volumeAverageInterpolation = this.calculateVolumeContourAverageInterpolation(elevationValues, measurement.pixelSurface, measurement.baseBordersAverageElevation);
      measurement.volumeLinearInterpolation = this.calculateVolumeContourLinearInterpolation(elevationValues, measurement.pixelSurface);
      measurement.volumeCustomElevation = this.calculateVolumeWithCustomElevation(elevationValues, max-min, measurement.pixelSurface, measurement.elevationsMax);
      measurement.volumeCut = this.calculateCutVolume(elevationValues, measurement.pixelSurface, measurement.baseBordersAverageElevation)
      measurement.volumeFill = this.calculateFillVolume(elevationValues, measurement.pixelSurface, measurement.baseBordersAverageElevation)
    }
  }

  public enrichAreaMeasurementWithComputedFields(measurement: Measurement, hasElevationValues: boolean, poly: Polygon, map: Map ) {
  }

  public enrichLengthMeasurementWithComputedFields(measurement: Measurement, hasElevationValues: boolean, poly: Polygon, map: Map ) {
  }

  //------------------------------------------------------------ Helpers ------------------------------------------------------------//
  private extractInteriorPixels(extent: Extent, poly: Polygon) {
    const interiorPixels = [];
    for (let y = extent[3]; y >= extent[1]; y--) {
      const row = [];
      for (let x = extent[0]; x <= extent[2]; x++) {
        const pixelCoord = [x, y];
        if (poly.intersectsCoordinate(pixelCoord)) {
          row.push(pixelCoord);
        } else {
          row.push([]);
        }
      }
      interiorPixels.push(row);
    }
    return interiorPixels;
  }

  public calculateSurfaceArea(area: number, elevationMatrix: (number | undefined)[][]): number {
    const numRows = elevationMatrix.length;
    const numCols = elevationMatrix[0].length;
    // Calculate pixel size based on the total number of cells with elevation values
    let totalCells = 0;
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        if (elevationMatrix[row][col] !== undefined) {
          totalCells++;
        }
      }
    }
    const pixelSize = Math.sqrt(area / totalCells);
    let surfaceArea = 0;
    let count = 0;
    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        if (elevationMatrix[row][col] !== undefined && row < numRows - 1) {
          if  (elevationMatrix[row][col + 1] !== undefined &&  elevationMatrix[row +1 ][col] !== undefined){
            const dzRight = Math.abs(elevationMatrix[row][col] - elevationMatrix[row][col + 1]);
            const dzButtom = Math.abs(elevationMatrix[row][col] - elevationMatrix[row + 1][col]);
            const avgDz = (dzRight+dzButtom)/2
            surfaceArea +=  pixelSize * (pixelSize + avgDz);
            count++
          } else if (elevationMatrix[row][col + 1] == undefined &&  elevationMatrix[row + 1][col] !== undefined) {
            const dzButtom = Math.abs(elevationMatrix[row][col] - elevationMatrix[row + 1][col]);
            surfaceArea +=  pixelSize * (pixelSize + dzButtom);
            count++
          } else if (elevationMatrix[row][col + 1] !== undefined &&  elevationMatrix[row+1][col] == undefined){
            const dzRight = Math.abs(elevationMatrix[row][col] - elevationMatrix[row][col + 1]);
            surfaceArea +=  pixelSize * (pixelSize + dzRight);
            count++
          } else {
            surfaceArea += pixelSize * pixelSize
            count++
          }

        } else if (row == numRows - 1) {
          if (elevationMatrix[row][col] !== undefined){
            if (elevationMatrix[row][col + 1] !== undefined){
              const dzRight = Math.abs(elevationMatrix[row][col] - elevationMatrix[row][col + 1]);
              surfaceArea +=  pixelSize * (pixelSize + dzRight);
              count++
            } else {
              surfaceArea += pixelSize * pixelSize
              count++
            }
          }
        }
      }
    }
    return surfaceArea;
  }

  public calculateRealLength(length: number,oneRowElevationValues: number[] ) {
    var step =length/200
    var leng =step
    for (var i = 0; i < oneRowElevationValues.length-1; i++) {
      leng = leng + Math.sqrt(Math.pow(step, 2) + Math.pow(Math.abs(oneRowElevationValues[i] - oneRowElevationValues[i + 1]), 2));
    }
    return leng;
  }

  public extractElevations(pixels: any[][]): number[][] {
    return pixels.map(row => this.extractElevationsLine(row));
  }

  public extractElevationsLine(row): number[] {
    return row.map(pixel => (pixel ? pixel.elevation : undefined));
  }

  public getSlope(firstPoint: number, lastPoint: number, length: number): number {
    // Calculate the change in elevation
    const elevationChange = lastPoint - firstPoint;
    // Calculate the slope (change in elevation divided by horizontal length)
    const slope = elevationChange / length;
    // Calculate the slope angle in radians
    const slopeRadians = Math.atan(slope);
    // Convert radians to degrees
    const slopeDegrees = (slopeRadians * 180) / Math.PI;
    return slopeDegrees;
  }

  public getMinMaxValues(matrix: number[][]): { min: number | undefined; max: number | undefined } {
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;
    for (const row of matrix) {
      for (const value of row) {
        if (value) {
          min = Math.min(min, value);
          max = Math.max(max, value);
        }
      }
    }
    return { min, max };
  }

  public getPixelSurface(elevationValues: number[][], area: number): number {
    let totalDefinedCells = 0;
    for (let row = 0; row < elevationValues.length; row++) {
      for (let col = 0; col < elevationValues[row].length; col++) {
          const elevation = elevationValues[row][col];
          if (elevation) {
              totalDefinedCells++;
          }
      }
    }
    return area / totalDefinedCells;
  }

  public getBordersElevationAverage(elevationValues: number[][]): number {
    let elevationSum = 0;
    let totalElevationCells = 0;
    const numRows = elevationValues.length;
    const numCols = elevationValues[0]?.length || 0;

    // Process the first and last row
    for (let col = 0; col < numCols; col++) {
        if (elevationValues[0][col] !== undefined) { // First row
            elevationSum += elevationValues[0][col];
            totalElevationCells++;
        }
        if (elevationValues[numRows - 1][col] !== undefined) { // Last row
            elevationSum += elevationValues[numRows - 1][col];
            totalElevationCells++;
        }
    }

    // Process the first and last column (excluding corners already counted)
    for (let row = 1; row < numRows - 1; row++) {
        if (elevationValues[row][0] !== undefined) { // First column
            elevationSum += elevationValues[row][0];
            totalElevationCells++;
        }
        if (elevationValues[row][numCols - 1] !== undefined) { // Last column
            elevationSum += elevationValues[row][numCols - 1];
            totalElevationCells++;
        }
    }

    // Process inner cells that have undefined neighbors
    for (let row = 1; row < numRows - 1; row++) {
        for (let col = 1; col < numCols - 1; col++) {
            const elevation = elevationValues[row][col];
            if (elevation && (
                elevationValues[row - 1][col] === undefined || // Above neighbor
                elevationValues[row + 1][col] === undefined || // Below neighbor
                elevationValues[row][col - 1] === undefined || // Left neighbor
                elevationValues[row][col + 1] === undefined    // Right neighbor
            )) {
                elevationSum += elevation;
                totalElevationCells++;
            }
        }
    }

    // Avoid division by zero
    return totalElevationCells > 0 ? elevationSum / totalElevationCells : 0;
  }

  private nextContourCell (elevationValues: number[][], row: number, col: number, numCols: number): number {
    for (let j = col; j < numCols-1; j++) {
      if ( elevationValues[row][j] && (elevationValues[row][j+1] == undefined || elevationValues[row+1][j] == undefined || elevationValues[row-1][j] == undefined)) {
        return j
      }    
    }
    return numCols-1
  }

  //--------------------------------------------------------- Volume calculators ---------------------------------------------------------//
  public calculateVolumeAverageLowestPoint(elevationValues: number[][], pixelSurface: number, baseBordersAverageElevation: number): number {
    if (elevationValues.length === 0 || elevationValues[0].length === 0) {
        return 0; // Handle empty data
    }
    let volumeAvg = 0
    
    //Calculate volume
    for (let row = 0; row < elevationValues.length; row++) {
        for (let col = 0; col < elevationValues[row].length; col++) {
            const elevation = elevationValues[row][col];
            if (elevation && elevation > baseBordersAverageElevation ) {
              volumeAvg += (elevation - baseBordersAverageElevation) * pixelSurface;
          }
        }
    }
    return volumeAvg;
  }
    
  public calculateVolumeRealLowestPoint(elevationValues: number[][], pixelSurface: number, LowestElevation: number): number {
    if (elevationValues.length === 0 || elevationValues[0].length === 0) {
        return 0; // Handle empty data
    }
    let volumeAvg = 0
    
    //Calculate volume
    for (let row = 0; row < elevationValues.length; row++) {
        for (let col = 0; col < elevationValues[row].length; col++) {
            const elevation = elevationValues[row][col];
            if (elevation && elevation > LowestElevation ) {
              volumeAvg += (elevation - LowestElevation) * pixelSurface;
          }
        }
    }
    return volumeAvg;
  }

  public calculateVolumeWithCustomElevation(elevationValues: number[][], CustomElevation: number, pixelSurface: number, elevationsMax: number): number {
    if (elevationValues.length === 0 || elevationValues[0].length === 0) {
      return 0; // Handle empty data
    }
    let volumeAvg = 0
    const customLowestPoint = elevationsMax - CustomElevation

    //Calculate volume
    for (let row = 0; row < elevationValues.length; row++) {
        for (let col = 0; col < elevationValues[row].length; col++) {
            const elevation = elevationValues[row][col];
            if (elevation && elevation > customLowestPoint ) {
              volumeAvg += (elevation - customLowestPoint) * pixelSurface;
          }
        }
    }
    return volumeAvg;
  }

  public calculateVolumeContourAverageInterpolation(elevationValues: number[][], pixelSurface: number, baseBordersAverageElevation: number): number {
    let volumeTotal = 0;
    let volumeGround = 0;

    // Calculate the total volume above the average elevation
    for (let i = 0; i < elevationValues.length; i++) {
      for (let j = 0; j < elevationValues[i].length; j++) {
        if (elevationValues[i][j] !== undefined ) {
          volumeTotal += elevationValues[i][j] * pixelSurface;
          volumeGround += baseBordersAverageElevation * pixelSurface;
        }
      }
    }
    return volumeTotal - volumeGround;
  }

  public calculateVolumeContourLinearInterpolation(elevationValues: number[][], pixelSurface: number): number {
    let volumeTotal = 0;
    let volumeGround = 0;
    let groundElevationValues = [];

    const numRows = elevationValues.length;
    const numCols = elevationValues[0].length;

    // Calculate pixel size based on the total area and number of cells

    // Reconstruct the elevation 2D array of the ground
    for (let row = 0; row < numRows; row++) {
        let groundElevationValuesRow = [];
        let col = 0;
        while (col < numCols) {
            if (row <= 2 || row >= numRows - 2 || elevationValues[row][col] === undefined) {
                // Directly use the elevation value for the boundary rows or undefined values
                groundElevationValuesRow.push(elevationValues[row][col]);
                col++;
            } else {
                // Find the next boundary cell in the row
                const start = elevationValues[row][col];
                const JEnd = this.nextContourCell(elevationValues, row, col + 1, numCols);
                const stepSize = (elevationValues[row][JEnd] - start) / (JEnd - col);
                // Fill the cells between the current and next boundary cell
                for (let j = col; j <= JEnd; j++) {
                    groundElevationValuesRow.push(start + stepSize * (j - col));
                }
                // Skip to the next boundary cell
                col = JEnd + 1;
            }
        }
        groundElevationValues.push(groundElevationValuesRow);
    }
    // Calculate the total volume above the average ground elevation
    for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
            const elevation = elevationValues[i][j];
            const groundElevation = groundElevationValues[i][j];
            if (elevationValues[i][j] && groundElevationValues[i][j] ) {
              volumeTotal += elevation * pixelSurface;
              volumeGround += groundElevation * pixelSurface;
            }
        }
    }
    return volumeTotal - volumeGround;
  }

  //--------------------------------------------------- Cut and Fill Calculations ------------------------------------------------------ //
  private calculateVolumeGrid(elevationValues: number[][], baseElevation: number, pixelSurface: number, calculateAbove: boolean): number {
    let volume = 0;
    for (let row = 0; row < elevationValues.length; row++) {
        for (let col = 0; col < elevationValues[row].length; col++) {
            const elevation = elevationValues[row][col];
            if (elevation) {
                if (calculateAbove && elevation > baseElevation) {
                    volume += (elevation - baseElevation) * pixelSurface;
                } else if (!calculateAbove && elevation < baseElevation) {
                    volume += (baseElevation - elevation) * pixelSurface;
                }
            }
        }
    }
    return volume;
  }

  public calculateFillVolume(elevationValues: number[][], pixelSurface: number, baseBordersAverageElevation): number {
    if (elevationValues.length === 0 || elevationValues[0].length === 0) return 0;
    return this.calculateVolumeGrid(elevationValues, baseBordersAverageElevation, pixelSurface, false);
  }

  public calculateCutVolume(elevationValues: number[][], pixelSurface: number, baseBordersAverageElevation): number {
    if (elevationValues.length === 0 || elevationValues[0].length === 0) return 0;
    return this.calculateVolumeGrid(elevationValues, baseBordersAverageElevation, pixelSurface, true);
  }
}

