<h2>{{ 'PORTFOLIO.MODAL.CREATE' | translate }}:</h2>
<form [formGroup]="form" (submit)="submit()">
  <mat-form-field>
    <mat-label>{{ 'PORTFOLIO.MODAL.NAME' | translate }}</mat-label>
    <input formControlName="name" matInput >
  </mat-form-field>
<!--  TODO: Add later-->
<!--  <mat-form-field>-->
<!--    <mat-label>{{ 'PORTFOLIO.MODAL.TYPE' | translate }}</mat-label>-->
<!--    <mat-select formControlName="type">-->
<!--      <mat-option *ngFor="let type of types" [value]="type">-->
<!--        {{ labels[type] | translate }}-->
<!--      </mat-option>-->
<!--    </mat-select>-->
<!--  </mat-form-field>-->
  <mat-form-field>
    <mat-label>{{ 'PORTFOLIO.MODAL.ADDRESS' | translate }}</mat-label>
    <input
      formControlName="address"
      #addressInput
      autocapitalize="off"
      autocorrect="off"
      matInput
    >
  </mat-form-field>
  <div class="controls">
    <button type="button" mat-stroked-button color="accent" (click)="close()">{{ 'CANCEL' | translate }}</button>
    <button type="submit" [disabled]="form.touched && form.invalid" mat-raised-button color="accent">{{ 'SAVE' | translate }}</button>
  </div>
</form>


