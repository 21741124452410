import { Component, Inject, OnInit, Input} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KrpanoService } from '../pano/krpano.service';


@Component({
  selector: 'app-pano-dialog',
  templateUrl: './pano-dialog.component.html',
  styleUrls: ['./pano-dialog.component.scss']
})
export class PanoDialogComponent implements OnInit {
  @Input() panoList
  selectedRowIndex = null;
  constructor(
    private readonly modal: MatDialogRef<PanoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private krpanoService: KrpanoService,
  ) {}


  getPano(fileModel,index)
  { 
    if (index != this.selectedRowIndex){
      this.selectedRowIndex = index;
      const panoDiv = document.getElementById("panoDiv") as HTMLDivElement;
      if (panoDiv !== null) {
        this.krpanoService.divRemove(panoDiv);
      }
      if (document.getElementById("container") !== null) {
        const container = document.getElementById(
          "container"
        ) as HTMLDivElement;
        if (fileModel.pano === undefined) {
          fileModel.pano = {};
        }
        this.krpanoService.divAdd(container)
        this.krpanoService.loadPano(
          fileModel,
          false,
          false,
          ""
        );
      } else {
        if (!fileModel.isFolder)
        { setTimeout(() => {
          this.getPano(fileModel,index);
          }, 250);
        }
      }
    }
  }

  ngOnInit(): void {
    this.getPano(this.data.panoList['0'],0)
  }

  public close(): void {
    const panoDiv = document.getElementById("panoDiv") as HTMLDivElement;
    if (panoDiv !== null) {
      this.krpanoService.divRemove(panoDiv);
    }
    this.modal.close();
  }

}
