import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Task } from './task.model';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnDestroy {
  @Input() task: Task;
  theme: string;
  @ViewChild('taskRoot', { static: true }) taskRoot: ElementRef<HTMLElement>;
  ngDestroy$ = new Subject();

  constructor(
    private themeService: ThemeService
  ) { 
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  getElement(): ElementRef<HTMLElement> {
    return this.taskRoot;
  }

}
