import { Injectable } from '@angular/core';

/* OpenLayers */
import { Fill, Stroke, Style, Text } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { Image as ImageLayer, Vector as VectorLayer } from 'ol/layer';
import { ImageStatic, Vector as VectorSource } from 'ol/source';
import {OverviewMap} from 'ol/control.js';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import Projection from 'ol/proj/Projection';
import TileLayer from 'ol/layer/WebGLTile';
import View from 'ol/View';

import { Annotation, AnnotationsStateDimension } from './../../pages/files/file/sidebar/annotations/annotation.model';
import { FileModel } from 'src/app/pages/files/file/file.model';
import { BehaviorSubject } from 'rxjs';
import { Measurement } from 'src/app/pages/files/file/sidebar/measurements/measurement.model';


@Injectable()
export class OLMapsService {
  extent: [number, number, number, number];
  styles: { [name: string]: Style } = {};//Style for Annotations
  mapLayers: any;
  location$: BehaviorSubject<string> = new BehaviorSubject("")
  addNewMeasurement$: BehaviorSubject<Measurement>  = new BehaviorSubject(undefined);
  addSavedMeasurement$: BehaviorSubject<Measurement>  = new BehaviorSubject(undefined);
  enrichMeasurement$: BehaviorSubject<Measurement>  = new BehaviorSubject(undefined);
  drawNewMeasurement$: BehaviorSubject<Measurement>  = new BehaviorSubject(undefined);
  focusFeature$: BehaviorSubject<any> = new BehaviorSubject(undefined)
  deleteFeature$: BehaviorSubject<Feature> = new BehaviorSubject(undefined)
  focusPixel$:BehaviorSubject<number[]> = new BehaviorSubject(undefined)
  mapReady$:BehaviorSubject<boolean> = new BehaviorSubject(undefined)

  renderMap(options: {
    target: HTMLElement
  }): Map {
    let mapOptions = {
      controls: [],
      view: new View({
        center: fromLonLat([0, 0 ]),
        zoom: 2,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: options.target
    };

    const map = new Map(mapOptions);
    return map
  }

  renderImage(options:
              {extent?: [number, number, number, number],
                image: string,
                imagePreview?: string,
                interactions: boolean,
                annotations?: Array<Annotation>,
                target: HTMLElement,
                labeling?: boolean,
                overVeiwMap?: boolean,
                drawEnd?(evt): void,
                fileModelMatch?:FileModel,
                original?:FileModel,
                scalingFactor?: number,
                center?: number[],
                translationError?:number[]}): Map {
    const interactions = options.interactions;
    const polygons = [];
    this.extent = [0, 0, 1024, 968];
    if (options.extent) {
      this.extent = options.extent;
    }
    if (options.annotations) {
      let i = 0;
      for (const annotation of options.annotations) {
        if (annotation.coordinates) {
          const poly = new Feature({
            geometry: new Polygon(annotation.coordinates),
            name: `dynamic-polygon-${i}`
          });
          const colorPolygon = AnnotationsStateDimension.getColor(annotation.stateDimension);
          poly.setStyle(new Style({
            fill: new Fill({ color: 'transparent' }),
            stroke: new Stroke({ color: colorPolygon, width: 3 })
          }));
          polygons.push(poly);
        }
        i++;
      }
    }
    const source = new VectorSource({
      wrapX: false,
      features: polygons
    });
    const vector = new VectorLayer({
      zIndex: 0,
      source: source
    });    
    const projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: this.extent
    });
    const imageSourcePrev = new ImageStatic({
      crossOrigin: 'anonymous',
      url: options.imagePreview,
      imageExtent: this.extent,
      projection
    })    
    let mapOptions = {
      view: new View({
        projection,
        center: getCenter(this.extent),
        zoom: 1,
        constrainOnlyCenter: true,
        enableRotation: false,
      }),
      layers: [
        new ImageLayer({
          zIndex: -10,
          source: new ImageStatic({
            crossOrigin: 'anonymous',
            url: options.imagePreview,
            imageExtent: this.extent,
            projection
          })
        }),
        new ImageLayer({
          zIndex: -9,
          source: new ImageStatic({
            crossOrigin: 'anonymous',
            url: options.image,
            imageExtent: this.extent,
            projection
          })
        }),
        vector
      ],
      target: options.target
    };
    if (options.fileModelMatch){
      mapOptions = {
        view: new View({
          projection,
          center: getCenter(this.extent),
          zoom: 1,
          constrainOnlyCenter: true,
          enableRotation: false,
        }),
        layers: [
          new ImageLayer({
            zIndex: -10,
            source: new ImageStatic({
              crossOrigin: 'anonymous',
              url: options.imagePreview,
              imageExtent: this.extent,
              projection
            })
          }),
          new ImageLayer({
            zIndex: -9,
            source: new ImageStatic({
              crossOrigin: 'anonymous',
              url: options.image,
              imageExtent: this.extent,
              projection
            })
          }),
          new ImageLayer({
            zIndex: 0,
            source:  new ImageStatic({
              crossOrigin: 'anonymous',
              url:options.fileModelMatch.fileURL,
              imageExtent: [options.center[0], options.center[1], (options.fileModelMatch.width*options.scalingFactor)+options.center[0], (options.fileModelMatch.height*options.scalingFactor)+options.center[1]],
              
            }),
            opacity: 0,
          }),
         // vector
        ],
        target: options.target
      };
    }
    mapOptions = {...mapOptions,
      ...{
        controls: [],
      }}
    if (!interactions) {
      mapOptions['interactions'] = []
    }
    let overviewMapControl
    if (options.overVeiwMap) {
      overviewMapControl = new OverviewMap({
        className: 'ol-overviewmap ol-custom-overviewmap',
        layers: [
          new ImageLayer({
            zIndex: -10,
            source: imageSourcePrev
          })
        ],
        collapsible: false,
        collapsed: false,
        view: new View({
          projection,
          extent: this.extent,
          zoom: 0.175,
          maxZoom: 0.175,
          minZoom: 0.175
        })
      });
      mapOptions['controls'] = [overviewMapControl];
    }
    const map = new Map(mapOptions);
    if (options.labeling && options.annotations) {
      map.addLayer(this.getLabelLayer(options.annotations, -1));
    }
    return map;
  }

  setFocusFeature (measurement){
    this.focusFeature$.next(measurement)
  }

  deleteFeature (feature: Feature){
    this.deleteFeature$.next(feature)
  }

  setFocusPixel (pixel){
    this.focusPixel$.next(pixel)
  }

  getLabelLayer(annotations: Annotation[], editing: number): any {
    const labels = [];
    let i = 0;
    if (annotations) {
      for (const annotation of annotations) {
        if (annotation.coordinates && i !== editing) {
          const poly = new Feature({
            geometry: new Polygon(annotation.coordinates)
          });
          const colorPolygon = AnnotationsStateDimension.getColor(annotation.stateDimension);

          // Placeholder for Label
          let placement = 'center' as CanvasTextAlign;
          let newFeature = new Feature()
          const extent = poly.getGeometry().getExtent()
          if (annotation.stateForm === 'Box') {
            if (annotation.averageTemperature && !annotation.maximumTemperature){
              placement = 'center';
              newFeature = new Feature({
                geometry: new Point([extent[0] + (extent[2] - extent[0]) / 2 , extent[3]-2]),
              })
            } else{
              placement = 'left';
              newFeature = new Feature({
                geometry: new Point([extent[0] , extent[3]]),
              })
            }
            
          } else if (annotation.stateForm === 'Circle') {
            newFeature = new Feature({
              geometry: new Point([extent[0] + (extent[2] - extent[0]) / 2, extent[3]]),
            })
          } else if (annotation.stateForm === 'Polygon') {
            const point = poly.getGeometry().getCoordinates()[0].find(point => {
              if (point[1] === extent[3]) {
                return point;
              }
            })
            newFeature = new Feature({
              geometry: new Point([point[0], point[1]]),
            })
          }
          newFeature.setStyle(new Style({
            text: new Text({
              backgroundFill: new Fill({
                color: colorPolygon
              }),
              text: annotation.annoID,
              textAlign: placement,
              font: '15px Calibri,sans-serif',
              fill: new Fill({
                color: '#000'
              }),
              offsetY: -10,
              padding: [2,0,2,2]
            })
          }));


          labels.push(newFeature);
        }
        i++;
      }
    }
    const sourceLabel = new VectorSource({
      wrapX: false,
      features: labels
    });

    return new VectorLayer({
      source: sourceLabel,
      className: "Label",
    });
  }

  addNewMeasurement(measurement: Measurement){
    this.addNewMeasurement$.next(measurement)
  }

  addSavedMeasurement(measurement: Measurement){
    this.addSavedMeasurement$.next(measurement)
  }

  enrichMeasurement(measurement: Measurement){
    this.enrichMeasurement$.next(measurement)
  }

  drawNewMeasurement(measurement: Measurement){
    this.drawNewMeasurement$.next(measurement)
  }

  getAnnotationExtent(annotation: Annotation): number[] {
    if (annotation.coordinates) {
      const poly = new Feature({
        geometry: new Polygon(annotation.coordinates),
      });
      const colorPolygon = AnnotationsStateDimension.getColor(annotation.stateDimension);
      poly.setStyle(new Style({
        fill: new Fill({ color: 'transparent' }),
        stroke: new Stroke({ color: colorPolygon, width: 3 })
      }));
      return poly.getGeometry().getExtent()
    }
  }
}
