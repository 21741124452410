import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { TasksService } from './tasks.service';
import { StatusType, Task } from './task/task.model';
import { Subject } from 'rxjs';
import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TaskComponent } from './task/task.component';
import { TaskDialogComponent } from 'src/app/shared/task-dialog/task-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  animations: [
    trigger('dragDrop', [
      state('void', style({ opacity: 0, transform: 'scale(0.5)' })),
      state('*', style({ opacity: 1, transform: 'scale(1)' })),
      
      // Transition for entering the view
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      
      // Transition for leaving the view
      transition(':leave', [
        animate('1ms ease-in', style({ opacity: 0, transform: 'scale(0.8)' }))
      ]),
  
      // Placeholder effect during drag
      transition('* => *', [
        animate('300ms ease', style({ opacity: 1, transform: 'translateX(0)' }))
      ])
    ])
  ]
})

export class TasksComponent implements OnInit, OnDestroy {
  groupedTasks = [];
  tasksByStatus: { [key: string]: Task[] } = {};
  ngDestroy$ = new Subject();
  private taskWidth: number;
  filterBy: string = '';
  searchQuery: string = '';
  groupBy: string = '';
  archived: boolean = false;
  theme: string;
  showOnboardingGuide = true
  fetching = true
  visibleColumns: number = 4;
  expandedColumns: boolean[] = [true, true, true, true];
  maxExpandedColumns: number = 4; 

  @ViewChild('dragPreview') dragPreview: ElementRef<HTMLElement>;
   // Listen to window resize events
   @HostListener('window:resize', ['$event'])
   onResize(event: any) {
     this.updateMaxExpandedColumns(event.target.innerWidth);
   }

  constructor(
    private tasksService: TasksService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private themeService: ThemeService,
    private dialogService: DialogService
  ) {
    this.updateMaxExpandedColumns(window.innerWidth); // Initialize based on the current window size
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.processQueryParams(params);
      this.loadTasks();
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  toggleColumn(index: number) {
    if (this.expandedColumns[index]) {
      this.expandedColumns[index] = false;
    } else {
      const expandedCount = this.expandedColumns.filter(expanded => expanded).length;
      if (expandedCount < this.maxExpandedColumns) {
        this.expandedColumns[index] = true;
      } else {
        const indexToCollapse = this.expandedColumns.indexOf(true);
        this.expandedColumns[indexToCollapse] = false;
        this.expandedColumns[index] = true;
      }
    }
  }

  isColumnExpanded(index: number): boolean {
    return this.expandedColumns[index];
  }


  processQueryParams(params: any): void {
    if (params.filter) {
      const filterObj = JSON.parse(decodeURIComponent(params.filter));
      this.filterBy = filterObj.key || '';
      this.searchQuery = filterObj.value || '';
    }

    if (params.groupby) {
      this.groupBy = params.groupby;
    }

    if (params.archived !== undefined) {
      this.archived = params.archived === 'true';
    }
  }

  loadTasks(): void {
    this.tasksService.findMany().pipe(takeUntil(this.ngDestroy$))
    .subscribe((response)=> {
      this.fetching = false
      if (response) {
        const newResponse = this.structureData(this.filterBy, this.searchQuery, this.groupBy, response)
        this.groupedTasks = this.groupTasksByOrderAndStatus(newResponse)
        this.showOnboardingGuide = false
      }
    })
  }

  closeOnboardingGuide() {
    this.showOnboardingGuide = false
  }

  structureData(filterBy: string, searchQuery: string, groupBy: string, tasks: any[]): any[] {
    // Step 1: Filter tasks by 'filterBy' criteria
    let filteredTasks = tasks.filter(task => {
        if (filterBy) {
            return task[filterBy] === filterBy;
        }
        return true;
    });

    // Step 2: Search tasks by 'searchQuery'
    if (searchQuery) {
        filteredTasks = filteredTasks.filter(task => 
            task.title.includes(searchQuery) || task.description.includes(searchQuery)
        );
    }

    // Step 4: Group tasks by 'groupBy' criteria
    const groupedTasks = filteredTasks.reduce((groups, task) => {
        let groupKey;
        
        // Determine the group key based on the groupBy field
        switch (groupBy) {
            case 'orderID':
                groupKey = task.linkedResources[0]?.resource.file?.orderID || 'other';
                break;
            case 'creator':
                groupKey = task.creator.email || 'other';
                break;
            case 'assignee':
                groupKey = task.assignee?.email || 'other';
                break;
            case 'urgency':
                groupKey = task.urgency || 'other';
                break;
            case 'deadline':
                groupKey = task.deadline || 'other';
                break;
            default:
                groupKey = 'other';
                break;
        }

        if (!groups[groupKey]) {
            groups[groupKey] = [];
        }

        groups[groupKey].push({
            _id: task._id,
            title: task.title,
            description: task.description,
            linkedResources: task.linkedResources.map(res => ({
                type: res.type,
                resource: res.resource,
            })),
            assignee: {
                name: task.assignee?.name || '',
                surname: task.assignee?.surname || ''
            },
            creator: task.creator.name,
            urgency: task.urgency,
            status: task.status,
            createdAt: new Date(task.createdAt),
            modifiedAt: new Date(task.modifiedAt),
            deadline: new Date(task.deadline),
            archived: task.archived
        });

        return groups;
    }, {});

    // Step 5: Format the grouped tasks
    return Object.keys(groupedTasks).map(groupKey => ({
        key: groupKey,
        tasks: groupedTasks[groupKey],
    }));
  }

  groupTasksByOrderAndStatus(groups: any[]): any[] {
    return groups.map(group => {
      const tasksByStatus = {
        to_do: [],
        in_progress: [],
        review: [],
        done: []
      };
  
      group.tasks.forEach(task => {
        if (tasksByStatus[task.status]) {
          tasksByStatus[task.status].push(task);
        } else {
          console.warn(`Unknown status ${task.status} for task with ID ${task._id}`);
        }
      });
  
      return {
        key: group.key,
        tasksByStatus
      };
    });
  }
  
  onGroupByChange(groupBy: string): void {
    this.groupBy = groupBy;
    this.updateUrl();
  }

  onArchivedChange(archived: boolean): void {
    this.archived = archived;
    this.updateUrl();
  }

  onFilterByChange(filterBy: string): void {
    this.filterBy = filterBy;
    this.updateUrl();
  }

  onSearchQueryChange(searchQuery: string): void {
    this.searchQuery = searchQuery;
    this.updateUrl();
  }

  isColumnHidden(index: number): boolean {
    return index >= this.visibleColumns;
  }

  updateUrl(): void {
    let filter: { [key: string]: string } = {};
    if (this.filterBy) {
        filter['key'] = this.filterBy;
    } 
    if (this.searchQuery) {
        filter['value'] = this.searchQuery;
    }
    let queryParams: { [key: string]: any } = {};
    if (Object.keys(filter).length > 0) {
        queryParams['filter'] = JSON.stringify(filter);
    }
    if (this.groupBy) {
        queryParams['groupby'] = this.groupBy;
    }
    if (this.archived !== undefined) {
        queryParams['archived'] = this.archived;
    }
    if (Object.keys(queryParams).length > 0) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge' 
        });
    }
    this.loadTasks();
  }

  drop(event: CdkDragDrop<Task[]>, newStatus: StatusType, key: number): void {  
    if (event.previousContainer === event.container) {
      moveItemInArray(this.groupedTasks.find(group => group.key === key).tasksByStatus[newStatus], event.previousIndex, event.currentIndex);
    } else {
      const task = event.previousContainer.data[event.previousIndex];
      task.status = newStatus;
  
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.updateTaskStatus(task)
    }
  }

  updateTaskStatus(task) {
    const UpdatedTask: Task = {
      linkedResources: [],
      title: task.title,
      assigneeEmail: task.assigneeEmail,
      creatorId: task.creatorId,
      urgency: task.urgency,
      status: task.status,
      deadline: task.deadline,
      description: task.description,
    };
    task.linkedResources.forEach(linkedResource => {
      UpdatedTask.linkedResources.push({
        "resource":linkedResource.resource._id,
        "type":linkedResource.type
      })
    });
    this.tasksService.updateOne(task._id, UpdatedTask).subscribe(response => {
    }, error => {
      this.dialogService.showDialog('Failed to Update Task', null, 'error', null, null, true);
    });
  }

  updateMaxExpandedColumns(width: number) {
    if (width > 1200) {
      this.maxExpandedColumns = 4;
    } else if (width > 992) {
      this.maxExpandedColumns = 3;
    } else if (width > 768) {
      this.maxExpandedColumns = 2;
    } else {
      this.maxExpandedColumns = 1;
    }
    this.enforceMaxExpandedColumns();
  }

    enforceMaxExpandedColumns() {
      let expandedCount = this.expandedColumns.filter(expanded => expanded).length;
      while (expandedCount > this.maxExpandedColumns) {
        const indexToCollapse = this.expandedColumns.lastIndexOf(true);
        this.expandedColumns[indexToCollapse] = false;
        expandedCount--;
      }
    }


  onMouseDown(taskComponent: TaskComponent): void {
    this.taskWidth = taskComponent.getElement().nativeElement.clientWidth;
  }

  onDragStarted(): void {
    this.waitForElement(() => this.dragPreview, 1, 2000)
      .then(() => {
        if (this.dragPreview && this.taskWidth !== null) {
          this.dragPreview.nativeElement.style.width = `${this.taskWidth}px`;
        }
      })
      .catch((error) => console.error(error));
  }

  waitForElement(conditionFn: () => any, interval: number, timeout: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();

      const checkCondition = () => {
        const result = conditionFn();
        if (result) {
          resolve(result);
        } else if (Date.now() - startTime >= timeout) {
          reject(new Error('Element not found within timeout.'));
        } else {
          setTimeout(checkCondition, interval);
        }
      };

      checkCondition();
    });
  }

  openTaskPopup(task?: Task): void {
    const dialogRef = this.dialog.open(TaskDialogComponent, {
      data: {
        task: task || null,
        action: 'update',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (task) {
          this.dialogService.showDialog('Task Updated Successfully', null, null, null, null, true);
        }
      }
    });
  }

}
