<div *ngIf="measurements.length > 0" class="measurements">
  <div  class="measurements-list"  *ngFor="let measurement of measurements; let i = index">
    <mat-expansion-panel class="measurement-container" [expanded]="measurement.selected">
      <mat-expansion-panel-header (click)="handleSelectMeasurement(i)" class="panel-header">
        <mat-panel-title class="title-text">
          <ng-container [ngSwitch]="measurement.type">
            <mat-icon *ngSwitchCase="'length'" svgIcon="length"></mat-icon>
            <mat-icon *ngSwitchCase="'volume'" svgIcon="volume"></mat-icon>
            <mat-icon *ngSwitchDefault svgIcon="measure_area"></mat-icon>
          </ng-container>
          <span *ngIf="!measurement.selected" (click)="isEditing = true">{{measurement.title || 'No title'}}</span>
          <mat-form-field *ngIf="measurement.selected" appearance="outline" class="title-input">
            <mat-label>Title</mat-label>
            <input matInput placeholder="Placeholder" [(ngModel)]="measurement.title" (ngModelChange)="onTitleChange(i)" (keydown)="stopPropagation($event)" (click)="stopPropagation($event)">
          </mat-form-field>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="details" *ngIf="measurement.type === 'length'">
        <div class="info-header-container">
          <div class="text">
            <span class="info-header">{{ 'MEASURE.LENGTH_PROFILE' | translate}}:</span>
            <mat-icon class="info-icon-simple" aria-label="info" fontSet="material-icons-outlined" matTooltip="{{ 'MEASURE.TOOLTIP.PROFILE' | translate}}" [matTooltipPosition]="'right'">info</mat-icon>
          </div>
        </div>
        <table>
          <tr>
            <td class="info-title">{{ 'MEASURE.2D_LENGTH' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.value2d"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.value2d">
                {{ measurement.value2d.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.3D_LENGTH' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.value3d"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.value3d">
                {{ measurement.value3d.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.SLOPE' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.slope"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.slope">
                {{ measurement.slope.toFixed(2) }} °
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_DIFFERENCE' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!(measurement?.elevationsMax && measurement?.elevationsMin)"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMax && measurement?.elevationsMin">
                {{ (measurement.elevationsMax - measurement.elevationsMin).toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MIN' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.elevationsMin"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMin">
                {{ measurement.elevationsMin.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MAX' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.elevationsMax"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMax">
                {{ measurement.elevationsMax.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
        </table>
        <div class="chart">
          <ngx-skeleton-loader
                *ngIf="!measurement?.pixels"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '142px' }">
          </ngx-skeleton-loader>
          <div [attr.id]="'canvas-container' + i"></div>
        </div>
      </div>
      <div class="details" *ngIf="measurement.type === 'area'">
        <div class="info-header-container">
          <div class="text">
            <span class="info-header">{{ 'MEASURE.SURFACE_PROFILE' | translate}}:</span>
            <mat-icon class="info-icon-simple" aria-label="info" fontSet="material-icons-outlined" matTooltip="{{ 'MEASURE.TOOLTIP.PROFILE' | translate}}" [matTooltipPosition]="'right'">info</mat-icon>
          </div>
        </div>
        <table>
          <tr>
            <td class="info-title">{{ 'MEASURE.BASE_AREA' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.value2d"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.value2d">
                {{ measurement.value2d.toFixed(2) }} m<sup>2</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.SURFACE_AREA' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.value3d"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.value3d">
                {{ measurement.value3d.toFixed(2) }} m<sup>2</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_DIFFERENCE' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!(measurement?.elevationsMax && measurement?.elevationsMin)"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMax && measurement?.elevationsMin">
                {{ (measurement.elevationsMax - measurement.elevationsMin).toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MIN' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.elevationsMin"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMin">
                {{ measurement.elevationsMin.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.ELEVATIONS_MAX' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.elevationsMax"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMax">
                {{ measurement.elevationsMax.toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
        </table>
        <div class="chart">
          <ngx-skeleton-loader
                *ngIf="!measurement?.pixels"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '300px' }">
          </ngx-skeleton-loader>
          <div #chartDiv [attr.id]="'canvas' + i"></div>
        </div>
      </div>
      <div class="details" *ngIf="measurement.type === 'volume'">
        <div class="info-header-container">
          <div class="text">
            <span class="info-header">{{ 'MEASURE.TOOLTIP.VOLUME_PROFILE' | translate}}:</span>
            <div class="tooltip">
              <mat-icon class="info-icon" aria-label="info" fontSet="material-icons-outlined"
                (mouseenter)="showVolumeTooltip = true"
                (mouseleave)="showVolumeTooltip = false"
                [class.show]="showVolumeTooltip">info</mat-icon>
              <div class="tooltip-body" [class.show]="showVolumeTooltip">
                <h2>{{ 'MEASURE.TOOLTIP.VOLUME.TITLE' | translate}}</h2>
                <ul>
                  <li>
                    <h3>{{ 'MEASURE.TOOLTIP.VOLUME.LOWEST_POINT' | translate}}:</h3>
                    <span class="explanation">{{ 'MEASURE.TOOLTIP.VOLUME.LOWEST_POINT_EXPLANATION' | translate}}</span>
                  </li>
                  <li>
                    <h3>{{ 'MEASURE.TOOLTIP.VOLUME.CUSTOM_ELEVATION' | translate}}:</h3>
                    <span class="explanation">{{ 'MEASURE.TOOLTIP.VOLUME.CUSTOM_ELEVATION_EXPLANATION' | translate}}</span>
                  </li>
                  <li>
                    <h3>{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_LINEAR_INTERPOLATION' | translate}}:</h3>
                    <span class="explanation">{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_LINEAR_INTERPOLATION_EXPLANATION' | translate}}</span>
                  </li>
                  <li>
                    <h3>{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_AVERAGE_INTERPOLATION' | translate}}:</h3>
                    <span class="explanation">{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_LINEAR_INTERPOLATION_EXPLANATION' | translate}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="input">
          <mat-form-field class="selection" [ngStyle]="measurement.selectedVolumeApproach === 'volumeCustomElevation' ? { 'width': '63%' } : { 'width': '100%' }">
            <mat-label>{{ 'MEASURE.TOOLTIP.VOLUME.TITLE' | translate}}</mat-label>
            <mat-select [(value)]="measurement.selectedVolumeApproach" (selectionChange)="handleVolumeApproachChange($event, i)">
              <!--<mat-option value="volumeAverageLowestPoint">Lowest Point (Avg.)</mat-option>-->
              <mat-option value="volumeRealLowestPoint">{{ 'MEASURE.TOOLTIP.VOLUME.LOWEST_POINT' | translate}}</mat-option>
              <mat-option value="volumeAverageInterpolation">{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_AVERAGE_INTERPOLATION' | translate}}</mat-option>
              <mat-option value="volumeLinearInterpolation">{{ 'MEASURE.TOOLTIP.VOLUME.CONTOUR_LINEAR_INTERPOLATION' | translate}}</mat-option>
              <mat-option value="volumeCustomElevation">{{ 'MEASURE.TOOLTIP.VOLUME.CUSTOM_ELEVATION' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="measurement.selectedVolumeApproach === 'volumeCustomElevation'" class="elevation">
            <mat-label>{{ 'MEASURE.HEIGHT' | translate}}</mat-label>
            <input matInput type="number"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="measurement.customElevation" (ngModelChange)="handleElevationChange($event, i)"
              min="0" max="10000" step="0.01">
          </mat-form-field>
        </div>
        <table>
          <tr>
            <td class="info-title">{{ 'MEASURE.BASE_AREA' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.value2d"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                count="1"
                appearance="line"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.value2d">
                {{ measurement.value2d.toFixed(2) }} m<sup>2</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.CUT' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.volumeCut"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.volumeCut">
                {{ measurement.volumeCut.toFixed(2) }} m<sup>3</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.FILL' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.volumeFill"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.volumeFill">
                {{ measurement.volumeFill.toFixed(2) }} m<sup>3</sup>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.HEIGHT' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!(measurement?.elevationsMax && measurement?.elevationsMin)"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.elevationsMax && measurement?.elevationsMin">
                {{ (measurement.elevationsMax - measurement.elevationsMin).toFixed(2) }} m
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="info-title">{{ 'MEASURE.VOLUME' | translate }}:</td>
            <td class="info">
              <ngx-skeleton-loader
                *ngIf="!measurement?.[measurement.selectedVolumeApproach]"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '18px' }">
              </ngx-skeleton-loader>
              <ng-container *ngIf="measurement?.[measurement.selectedVolumeApproach]">
                {{ measurement[measurement.selectedVolumeApproach].toFixed(2) }} m<sup>3</sup>
              </ng-container>
            </td>
          </tr>
        </table>
        <div class="chart">
          <ngx-skeleton-loader
                *ngIf="!measurement?.pixels"
                count="1"
                appearance="line"
                [animation]="theme.includes('dark') ? 'progress-dark' : 'progress'"
                [theme]="{ 'height': '300px' }">
          </ngx-skeleton-loader>
          <div #chartDiv [attr.id]="'canvas' + i"></div>
        </div>
      </div>
      <div class="interaction">
        <button mat-button color="accent" mat-raised-button *ngIf="showSaveIcon[i] || !measurement._id" (click)="save(i)"> {{ 'SAVE' | translate }} </button>
        <button mat-button (click)="handleDeleteMeasurement(i)">Remove</button>
      </div>
    </mat-expansion-panel>
  </div>
</div>
<div class="measurements-footer">
  <button
    color="accent"
    [disabled]="!fileModel?.folderID"
    mat-raised-button
    (click)="exportCSV()"
    title="Generate PDF"
    type="button">
    {{ 'MEASURE.ACTION.EXPORT_CSV' | translate | uppercase }}
  </button>
</div>
