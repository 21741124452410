import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Pilot} from "./pilots.model";
import {environment} from "../../../environments/environment";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PilotsService {

  private _fetching = false;
  private _pilots: Pilot[] = [];
  private _tags: string[] = [];
  private _filteredPilots = new BehaviorSubject<Pilot[]>([]);
  private _testFilter = new Set<boolean>();
  private _tagFilter = new Set<string>();

  constructor(private http: HttpClient) {
  }
  async getPilots(): Promise<Pilot[]> {
    if (this._pilots.length > 0) {
      return this._pilots;
    }
    this._fetching = true;
    const pilots = await this.http.get<{data: Pilot[]}>(`${environment.apiPath}pilots`).toPromise();
    this._pilots = pilots.data;
    const tags = new Set<string>();
    this._pilots.forEach(p => {
      p.tags.forEach(t => tags.add(t));
    });
    this._tags = Array.from(tags.values()).sort((a, b) => a.localeCompare(b));
    this._testFilter.add(true);
    this._testFilter.add(false);
    this._fetching = false;
    this.filterPilots();
    return this._pilots;
  }

  async getPilot(id: string): Promise<Pilot | undefined> {
    await this.getPilots();
    return this._pilots.find(p => p.id == id);
  }

  get filteredPilots(): BehaviorSubject<Pilot[]> {
    return this._filteredPilots;
  }

  public get tags(): string[] {
    return this._tags;
  }

  public hasTagFilter(tag: string): boolean {
    return this._tagFilter.has(tag);
  }

  public setTagFilter(tag: string, set: boolean) {
    if (!set) {
      this._tagFilter.delete(tag);
    } else {
      this._tagFilter.add(tag);
    }
    this.filterPilots();
  }

  public toggleTagFilter(tag: string) {
    this.setTagFilter(tag, !this.hasTagFilter(tag));
  }

  public hasTestFilter(tag: boolean): boolean {
    return this._testFilter.has(tag);
  }

  public setTestFilter(test: boolean, set: boolean) {
    if (!set) {
      this._testFilter.delete(test);
    } else {
      this._testFilter.add(test);
    }
    this.filterPilots();
  }

  public toggleTestFilter(test: boolean) {
    this.setTestFilter(test, !this.hasTestFilter(test));
  }

  private filterPilots() {
    let pilots = this._pilots;
    if (typeof this._testFilter !== 'undefined') {
      pilots = pilots.filter(p => this._testFilter.has(p.test));
    }
    if (this._tagFilter.size > 0) {
      pilots = pilots.filter(
        (p) =>
        p.tags.find((tag) =>
          this._tagFilter.has(tag)
        )
      );
    }
    this.filteredPilots.next(pilots);
  }

}
