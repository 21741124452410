import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilesService} from "../files/files.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TemplateFolder, TemplateFolderEntry} from "../files/file/file.model";
import {MatDialog} from "@angular/material/dialog";
import {TemplateRenameDialogComponent} from "./template-rename-dialog/template-rename-dialog.component";

/**
 * Interface containing data for the template dialog component {@link TemplateRenameDialogComponent} passed from this
 * component.
 */
export interface TemplateFolderDialogData {
  folder: TemplateFolderEntry;
}

/**
 * Data for a single row in the folder table. Entries are generated by {@link fillFolder}.
 */
interface FolderRow {
  idx: number;
  parent: TemplateFolderEntry;
  folder: TemplateFolderEntry;
  indent: number;
}

@Component({
  selector: 'app-files-template',
  templateUrl: './files-template.component.html',
  styleUrls: ['./files-template.component.scss']
})
export class FilesTemplateComponent implements OnInit, OnDestroy {

  /**
   * ID of the selected client passed via GET parameter `clientId`.
   */
  public clientId?: string;

  /**
   * Folders actually displayed in the table. Contains links to entries inside {@link templateFolders}, so that changes
   * are automatically reflected there. Should be updated whenever the template structure changes using
   * {@link fillFolder}.
   */
  public folders: FolderRow[] = [];

  /**
   * Template folder data ready to be sent to the backend.
   */
  public templateFolders?: TemplateFolder[];

  /**
   * Original template folder data used to check whether an entry is dirty. It is initialized in {@link refresh} and
   * should not be manipulated anywhere else.
   */
  public templateFoldersOrig?: TemplateFolder[];

  /**
   * Index of the selected template folder. Used instead of the immediate template folder so that we keep the selection
   * when refreshing the entries.
   */
  public selectedIdx = 0;

  /**
   * Columns displayed in the materials table.
   */
  public displayedColumns: string[] = ['name', 'more'];

  private ngDestroy$ = new Subject<void>();

  constructor(public filesService: FilesService, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    activatedRoute.queryParams.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      this.clientId = params['clientId'];
    });
  }

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }

  ngOnDestroy() {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  /**
   * Fetches all template folders from the backend.
   */
  async refresh(): Promise<void> {
    this.templateFolders = await this.filesService.getTemplateFolders(this.clientId);
    this.templateFoldersOrig = JSON.parse(JSON.stringify(this.templateFolders));
    this.fillFolder();
  }

  /**
   * Store the currently selected template folders in the backend.
   */
  async save(): Promise<void> {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    await this.filesService.putTemplateFolder(this.templateFolders[this.selectedIdx]);
    await this.refresh();
  }

  /**
   * Remove all folders from the currently selected template folder.
   */
  public clear() {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    const selectedFolder = this.templateFolders[this.selectedIdx];
    selectedFolder.root.subs = [];
    this.fillFolder();
  }

  /**
   * Copy the folder structure of the master template folder. It will override the whole structure.
   */
  public copyMaster() {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    const selectedFolder = this.templateFolders[this.selectedIdx];
    selectedFolder.root.subs = this.templateFolders[0].root.subs;
    this.fillFolder();
  }

  /**
   * Indicates whether the template folder has been changed.
   */
  public isDirty() {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    return JSON.stringify(this.templateFolders[this.selectedIdx]) !== JSON.stringify(this.templateFoldersOrig[this.selectedIdx]);
  }

  /**
   * Adds a new sub-folder to the given {@param parent}. If no parent is given we use the root folder of the template.
   * @param parent folder to which the new sub-folder should be added (optional)
   */
  public addFolder(parent?: TemplateFolderEntry) {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    const selectedFolder = parent ?? this.templateFolders[this.selectedIdx].root;
    if (!selectedFolder.subs) {
      selectedFolder.subs = [];
    }
    const newFolder = {
      name: `Unnamed folder ${Math.floor(Math.random() * 899 + 100)}`,
      subs: [],
    };
    selectedFolder.subs.push(newFolder);
    this.fillFolder();
    this.openFolderDialog(newFolder);
  }

  /**
   * Removes the folder.
   * @param idx index of the folder inside the {@link folders} array.
   */
  public deleteFolder(idx: number) {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    const selectedFolder = this.folders[idx];
    selectedFolder.parent.subs.splice(selectedFolder.parent.subs.indexOf(selectedFolder.folder), 1);
    selectedFolder.parent.subs = [...selectedFolder.parent.subs];
    this.fillFolder();
  }

  /**
   * Fetches the {@link TemplateFolderEntry} from the {@link folders} array.
   * @param idx index of the folder
   */
  public folderFromIndex(idx: number): TemplateFolderEntry {
    return this.folders[idx].folder;
  }

  /**
   * Open a dialog for the given folder to allow for editing its properties (e.g., name).
   * @param folder folder to edit
   */
  public openFolderDialog(folder: TemplateFolderEntry): void {
    this.dialog.open(TemplateRenameDialogComponent, {
      width: '360px',
      data: {folder} as TemplateFolderDialogData,
    });
  }

  /**
   * Re-creates the {@link folders} array from the selected template folder. Should always be called when the
   * template folder is changed to reflect these changes in the table.
   */
  public fillFolder() {
    if (typeof this.selectedIdx === 'undefined') {
      return;
    }
    const selectedFolder = this.templateFolders[this.selectedIdx];
    this.folders = [];
    fillFolder(this.folders, selectedFolder.root);
  }

}

/**
 * Recursively fills the {@param rows} parameter from a {@param parent} folder entry.
 * @param rows array to be filled
 * @param parent node entry whose sub folders are put inside the {@param rows} array
 * @param indent indentation value used in the table to show the nested structure
 */
function fillFolder(rows: FolderRow[], parent: TemplateFolderEntry, indent = 0) {
  if (!parent.subs || parent.subs.length === 0) {
    return;
  }
  for (let idx = 0; idx < parent.subs.length; idx++) {
    rows.push({
      idx,
      parent,
      folder: parent.subs[idx],
      indent,
    });
    fillFolder(rows, parent.subs[idx], indent + 1);
  }
}
