import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-to-raptor-maps-dialog',
  templateUrl: './send-to-raptor-maps-dialog.component.html',
  styleUrls: ['./send-to-raptor-maps-dialog.component.scss']
})
export class SendToRaptorMapsDialogComponent {

  formGroup: FormGroup;

  constructor(
      public dialogRef: MatDialogRef<SendToRaptorMapsDialogComponent>,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      orderId: new FormControl('', [
        Validators.required,
        Validators.maxLength(256),
        Validators.min(1)
      ])
    });
  }

  get orderId(): AbstractControl { return this.formGroup.get('orderId'); }
}
