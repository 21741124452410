import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {EarningsStats } from '../../pages/users/models/interfaces/stats.interfaces';
import { Chart, ChartConfiguration, ChartData } from 'chart.js';
import { ThemeService } from '../theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AmountPipe } from '../pipes/amount.pipe';

@Component({
  selector: 'app-earnings-stats',
  templateUrl: './earnings-stats.component.html',
  styleUrls: ['./earnings-stats.component.scss']
})
export class EarningsStatsComponent implements OnInit, AfterViewInit, OnDestroy {

  public inputData: EarningsStats;
  private maxIndex: number = 0;
  private  maxValue: number = 0;
  @ViewChild('bar') bar: ElementRef<HTMLCanvasElement>;

  @Input()
  public set inputDataSetter(inputData: EarningsStats) {
    this.inputData = inputData;
    if (!inputData) return;
    this.inputData.monthsEarnings
      .forEach((value, index)=> {
        if (this.maxValue < value) {
          this.maxValue = value
          this.maxIndex = index;
        }
      })
    this.updateChart();
  }

  public chart: Chart;
  private destroy$ = new Subject<void>();

  public dataBar: ChartData<'bar', number[], string> = {
    labels: [],
    datasets: [{
      label: 'Data',
      data: [],
      backgroundColor: ['#2979FF'],
      borderRadius: 10
    },
      {
        label: 'Data',
        data: [],
        backgroundColor: ['#F2F2F2'],
        borderRadius: 10
      }]
  };

  public configBar: ChartConfiguration<'bar', number[], string> = {
    type: 'bar',
    data: this.dataBar,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          max: this.maxValue ? this.maxValue * 1.5 : 60,
          display: false
        },
        x: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            maxRotation: 0,
            color: (ctx) => ctx.index === this.maxIndex ? '#2979FF' : 'inherit',
          }
        }
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Bar Chart'
        },
        datalabels: {
          display:true,
          align: 'top',
          anchor: 'end',
          formatter: (value, { dataIndex }) => {
            const data = this.inputData.monthsEarnings[dataIndex]
            if (value !== (this.maxValue || 40)) return '';
            return data ? `+${this.amountPipe.transform(data)}`: '0k';
          }
        },
      }
    }
  }

  constructor(
    private readonly theme: ThemeService,
    private readonly translate: TranslateService,
    private readonly amountPipe: AmountPipe
  ) {}

  public ngOnInit(): void {
    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        this.dataBar.labels = this.inputData.mothsLabels
          .map(label => this.translate.instant(label));
        this.chart.update();
      })

    this.theme.changed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => {
        if (!this.chart) return;
        this.dataBar.datasets[1].backgroundColor = this.theme.isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#F2F2F2';
        this.configBar.options.plugins['datalabels'].color = this.theme.isDarkMode ? 'white' : 'inherit';
        this.configBar.options.scales.x.ticks.color = (ctx) =>
          ctx.tick.label === 'April' ? '#2979FF' :  this.theme.isDarkMode ? 'white' : 'inherit';
        this.chart.update()
      })
  
  }

  public ngAfterViewInit(): void {
    this.updateChart();
  }

  private updateChart(): void {
    if (!this.bar?.nativeElement || !this.inputData) return;

    this.dataBar.labels = this.inputData.mothsLabels
      .map(label => this.translate.instant(label));
    this.dataBar.datasets[0].data = this.inputData.monthsEarnings;
    this.dataBar.datasets[1].data = this.inputData.monthsEarnings.map(_ => this.maxValue || 40);

    this.configBar.options.scales['y'].max = this.maxValue ? this.maxValue * 1.5 : 60;

    if (this.chart) {
      this.chart.update();

    } else {
      this.chart = new Chart(this.bar.nativeElement, this.configBar);
    }
  }

  public ngOnDestroy(): void {
    this.chart.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
