<h2 mat-dialog-title>{{ 'CHECK_DATA.TITLE' | translate }}</h2>

<mat-dialog-content>

  <h3>DroneModels</h3>
  <div *ngFor="let item of chartData['cameraModels'] | keyvalue">
    DroneModel: <span class="key">{{item.key}}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{item.value}}</span>
  </div>

  <h3>RelativeAltitude</h3>
  <div *ngFor="let item of chartData['relativeAltitudeKeys']">
    RelativeAltitude: <span class="key">{{ item['string'] }}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{ chartData['relativeAltitude'][item['string']] }}</span>
  </div>

  <h3>Gimbal-Pitch</h3>
  <div *ngFor="let item of chartData['pitchKeys']">
    GimbalPitch: <span class="key">{{ item['string'] }}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{ chartData['pitch'][item['string']] }}</span>
  </div>

  <h3>ISO</h3>
  <div *ngFor="let item of chartData['iso'] | keyvalue">
    ISO: <span class="key">{{item.key}}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{item.value}}</span>
  </div>

  <h3>Shutter</h3>
  <div *ngFor="let item of chartData['shutterKeys']">
    Shutter: <span class="key">{{ item['string'] }}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{ chartData['shutter'][item['string']] }}</span>
  </div>

  <h3>Aperture</h3>
  <div *ngFor="let item of chartData['aperture'] | keyvalue">
    Aperture: <span class="key">{{item.key}}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{item.value}}</span>
  </div>

  <h3>RtkFlag</h3>
  <div *ngFor="let item of chartData['RtkFlag'] | keyvalue">
    RtkFlag: <span class="key">{{item.key}}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{item.value}}</span>
  </div>

  <h3>ImageSize</h3>
  <div *ngFor="let item of chartData['imageSizeKeys']">
    ImageSize: <span class="key">{{ item['string'] }}</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{ chartData['imageSize'][item['string']] }}</span>
  </div>

  <h3>FlightSpeed</h3>
  <div *ngFor="let item of chartData['flightSpeed'] | keyvalue">
    FlightSpeed: <span class="key">{{item.key}}</span> <span *ngIf="item.key !== 'undefined'">m/s</span> {{ 'CHECK_DATA.NUMBER_OF_IMAGES' | translate }}: <span class="key">{{item.value}}</span>
  </div>

  <h3>Tabelle</h3>
  <div class="mat-elevation-z8 tableWrapper"
  *ngIf="imgDataSource.length > 0">
    <table mat-table [dataSource]="imgDataSource">

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> name </th>
        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
      </ng-container>

      <!-- iso Column -->
      <ng-container matColumnDef="iso">
        <th mat-header-cell *matHeaderCellDef> ISO </th>
        <td mat-cell *matCellDef="let element"> {{ element.meta.ISO }} </td>
      </ng-container>

      <!-- shutter Column -->
      <ng-container matColumnDef="shutter">
        <th mat-header-cell *matHeaderCellDef> Shutter </th>
        <td mat-cell *matCellDef="let element"> <span *ngIf="element.meta.ShutterSpeed">{{ element.meta.ShutterSpeed.val }}</span> </td>
      </ng-container>

      <!-- aperture Column -->
      <ng-container matColumnDef="aperture">
        <th mat-header-cell *matHeaderCellDef> Aperture </th>
        <td mat-cell *matCellDef="let element"> {{element.meta.Aperture}} </td>
      </ng-container>

      <!-- aperture Column -->
      <ng-container matColumnDef="pitch">
        <th mat-header-cell *matHeaderCellDef> Pitch </th>
        <td mat-cell *matCellDef="let element"> {{ filesService.getImagePitch(element) }} </td>
      </ng-container>

      <!-- RelativeHeight Column -->
      <ng-container matColumnDef="RelativeAltitude">
        <th mat-header-cell *matHeaderCellDef> RelativeAltitude </th>
        <td mat-cell *matCellDef="let element"> {{ getRelAlt(element) }} </td>
      </ng-container>

      <!-- RtkFlag Column -->
      <ng-container matColumnDef="RtkFlag">
        <th mat-header-cell *matHeaderCellDef> RtkFlag </th>
        <td mat-cell *matCellDef="let element"> {{ element.meta.RtkFlag }} </td>
      </ng-container>

      <!-- FlightSpeed Column -->
      <ng-container matColumnDef="flightSpeed">
        <th mat-header-cell *matHeaderCellDef> FlightSpeed </th>
        <td mat-cell *matCellDef="let element"> {{ calcFlightSpeed(element) }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      type="button">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      color="accent"
      mat-raised-button
      mat-dialog-close
      type="button">{{ 'OK' | translate | uppercase }}</button>
</div>
