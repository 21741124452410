import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import { AnnotationStateDimensionObj, AnnotationTypesObj } from './../../../shared/annotations/annotation-types';
import { FileModel } from './../file/file.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor() { }
  dataSourceBeforeFilter$: BehaviorSubject<Array<FileModel>> = new BehaviorSubject([]);
  public allFiles$: Subject<Array<FileModel>> = new Subject();
  dataSource$: BehaviorSubject<Array<FileModel>> = new BehaviorSubject([]);
  selectedFile$: BehaviorSubject<FileModel> = new BehaviorSubject({});
  inspectionFile$: BehaviorSubject<FileModel> = new BehaviorSubject({});

  setDataSourceBeforeFilter(newDataSource: FileModel[]): void {
    if (newDataSource && newDataSource.length > 0) {
      this.dataSourceBeforeFilter$.next(newDataSource);
    }
  }

  updateFilterParameters(dataSourceBeforeFilter: FileModel[]): any{
    const features = [];
    const stateDimension = [];
    const imageType = [];

    if (dataSourceBeforeFilter){
      setTimeout(() => {
        dataSourceBeforeFilter?.forEach((fileModel) => {
          if(fileModel.annotationStats) {
            if (fileModel.annotationStats.total>0){
              // extract annotationStats used
              if (fileModel.annotationStats.advisory>0){
                if (!stateDimension.includes(20)){
                  stateDimension.push(20)
                }
              }
              if (fileModel.annotationStats.low>0){
                if (!stateDimension.includes(40)){
                  stateDimension.push(40)
                }
              }
              if (fileModel.annotationStats.medium>0){
                if (!stateDimension.includes(60)){
                  stateDimension.push(60)
                }
              }
              if (fileModel.annotationStats.high>0){
                if (!stateDimension.includes(80)){
                  stateDimension.push(80)
                }
              }
              if (fileModel.annotationStats.urgent>0){
                if (!stateDimension.includes(100)){
                  stateDimension.push(100)
                }
              }

              // extract features used
              for (const type in fileModel.typesStats) {
                if (fileModel.typesStats.hasOwnProperty(type)) {
                  if (!features.includes(type)) {
                    features.push(type);
                  }
                }
              }
            }
          }
          if (fileModel.type){
            if (!imageType.includes(fileModel.type)){
              imageType.push(fileModel.type);
            }
          }
        });
      }, 300);
    }
    return {'features':features, 'stateDimension':stateDimension, 'imageType':imageType}
  }


   
  featuresStateDimension(): Observable<{[key: string]: any[];}>{
    return this.allFiles$
      .pipe(map(newDataSource => {
        const features = [];
        const stateDimension = [];
        const imageType = [];
        newDataSource.forEach((fileModel) => {
          if(fileModel.annotationStats) {
            if (fileModel.annotationStats.total>0){
              // extract annotationStats used
              if (fileModel.annotationStats.advisory>0){
                if (!stateDimension.includes(20)){
                  stateDimension.push(20)
                }
              }
              if (fileModel.annotationStats.low>0){
                if (!stateDimension.includes(40)){
                  stateDimension.push(40)
                }
              }
              if (fileModel.annotationStats.medium>0){
                if (!stateDimension.includes(60)){
                  stateDimension.push(60)
                }
              }
              if (fileModel.annotationStats.high>0){
                if (!stateDimension.includes(80)){
                  stateDimension.push(80)
                }
              }
              if (fileModel.annotationStats.urgent>0){
                if (!stateDimension.includes(100)){
                  stateDimension.push(100)
                }
              }

              // extract features used
              for (const type in fileModel.typesStats) {
                if (fileModel.typesStats.hasOwnProperty(type)) {
                  if (!features.includes(type)) {
                    features.push(type);
                  }
                }
              }
            }
          }

          if (fileModel.type){
            if (!imageType.includes(fileModel.type)){
              imageType.push(fileModel.type);
            }
          }
        });
        return {'features':features, 'stateDimension':stateDimension, 'imageType':imageType}
      }))
  }

  setDataSource(newDataSource: FileModel[]): void {
    if (newDataSource) {
      this.dataSource$.next(newDataSource);
    }
  }

  setSelectedFile(newSelectedFile: FileModel): void {
    if (newSelectedFile._id !== this.selectedFile$.value._id) {
      this.selectedFile$.next(newSelectedFile);
    }
  }

  setInspectionFile(inspectionFile: FileModel): void {
    if (inspectionFile._id !== this.inspectionFile$.value._id) {
      this.inspectionFile$.next(inspectionFile);
    }
  }

  getFeature(annotation: string, language: string): string {
    if (annotation.includes('-')){
      const annotationParts = annotation.split('-')
      try {
        return AnnotationTypesObj[annotationParts[0]][annotationParts[1]][language];
      } catch (e) {
        console.error(e)
        return 'noFeature'
      }
    } else {
      try {
        return AnnotationTypesObj['old'][annotation][language];
      } catch (e) {
        console.error(e)
        return 'noFeature'
      }
    }
  }

  getStateDimension(stateDimension: number, language: string): string {
    let severity = ''
    switch (stateDimension) {
      case 20: severity = 'advisory'; break;
      case 40: severity = 'low'; break;
      case 60: severity = 'middle'; break;
      case 80: severity = 'high'; break;
      case 100: severity = 'urgent'; break;
    }
    return AnnotationStateDimensionObj[severity][language]
  }

}
