import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDays'
})
export class AddDaysPipe implements PipeTransform {

  transform(value: string | null, days: number = 90): string {
    if (!value) {
      return undefined;
    }

    const dateToVerify = new Date(value);
    const expirationDate = new Date(dateToVerify);
    expirationDate.setDate(expirationDate.getDate() + days);

    if (!expirationDate?.getDate()) {
      return undefined
    }  else {
      return expirationDate.toISOString();
    }
  }
}
