
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable()
export class ForgotPasswordService {

  constructor(
    private http: HttpClient
  ) { }

  forgotPassword(email: string): Observable<Object> {
    return this.http.post(`${environment.apiPath}forgotPassword`, email);
  }

}
