<div *ngIf="overlay || fileModel?.hidden" class="overlay" (click)="setState('popupClose',false)"></div>
<ng-form [formGroup]="formShare" [class.dark]="this.theme && this.theme.includes('dark')">

  <!-- Copy Link Popup -->
  <div *ngIf="popupLink" class="popupWraper">
    <div class="popup">
      <div class="header"><mat-icon class="rtl-mirror">link</mat-icon> {{ 'SIDEBAR.SHARE.URL' | translate }}</div>
      <div class="hint">{{ 'SIDEBAR.SHARE.LINK_HINT' | translate }}</div>
      <div class="minor">
        <button mat-button (click)="setState('popupLink', false)">{{ 'SIDEBAR.SHARE.CLOSE' | translate | uppercase }}</button>
        <button mat-button class="right" (click)="openURL(publicLink)">{{ 'SIDEBAR.SHARE.GO_TO_URL' | translate | uppercase }}</button>
      </div>
    </div>
  </div>

  <!-- Iframe Popup -->
  <div *ngIf="popupIFrame" class="popupWraper">
    <div class="popup">
      <div class="header"><mat-icon class="rtl-mirror">code</mat-icon> {{ 'SIDEBAR.SHARE.IFRAME' | translate }}</div>
      <div class="hint">{{ 'SIDEBAR.SHARE.IFRAME_HINT' | translate}}</div>
      <div class="minor">
        <button mat-button class="right" (click)="setState('popupIFrame', false)">{{ 'SIDEBAR.SHARE.CLOSE' | translate | uppercase }}</button>
      </div>
    </div>
  </div>

  <!-- File Publishing Inputs -->
  <div class="file-publish-section">
    <mat-slide-toggle
        class="publish-toggle"
        formControlName="public"
        (change)="toggleMakePublic()"
        [disabled]="!legalCheckbox.value">
      Make File Public
    </mat-slide-toggle>

    <div class="legal-checkbox-container">
      <mat-checkbox formControlName="legalCheckbox" class="legal-checkbox" disabled="{{public.value}}">
      </mat-checkbox>
      <span class="checkbox-note" > {{ 'SIDEBAR.SHARE.LEGAL' | translate}} </span>
    </div>

    <div class="legal-checkbox-container" *ngIf="public.value && false">
      <mat-checkbox formControlName="legalCheckboxPoi" class="legal-checkbox">
      </mat-checkbox>
      <span class="checkbox-note" > POI Images Legal statement that fairfleet is not responsible for images uploaded by the client </span>
    </div>

    <div class="legal-checkbox-container" *ngIf="public.value">
      <mat-checkbox formControlName="downloadable" class="legal-checkbox" (change)="toggleMakeDownloadable()">
      </mat-checkbox>
      <span class="checkbox-note" > {{ 'SIDEBAR.SHARE.DOWNLOADABLE' | translate }} </span>
    </div>

    <div class="links" *ngIf="public.value">
      <div class="copy-link">
        <input type="text" class="copy-link-input" value="{{publicLink}}" readonly>
        <button type="button" class="copy-link-button" [cdkCopyToClipboard]="publicLink" (click)="setState('popupLink', true)">
          <span class="material-icons small-icon">content_copy</span>
        </button>
      </div>
      <button mat-raised-button
        color="accent"
        class="iframe-button"
        (click)="downloadIframe()">
        <mat-icon class="primary-button-icon">download</mat-icon>
        IFRAME
      </button>
    </div>
  </div>

</ng-form>
