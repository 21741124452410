<!-- Users container -->
<div
    id="users"
    cdkScrollable
    class="users"
    [class.scroll-disabled]="fetching"
    [dir]="dir"
    tabindex="0">

  <!-- Table view -->
  <mat-table
      #table
      [dataSource]="dataSource"
      matSort
      matSortActive="modified"
      matSortDirection="desc"
      matSortDisableClear
      [ngClass]="'c' + displayedColumns.length"
      [trackBy]="trackByIndex">

    <ng-container matColumnDef="avatar-checkbox">
      <mat-header-cell *matHeaderCellDef class="center">
        <mat-checkbox
            *ngIf="dataSource"
            (change)="$event ? toggleSelectAll() : null"
            [checked]="isLoadedSelected"
            [indeterminate]="isAnySelected"
            title="{{ (dataSource.length === totalItems ?
              (isTotalSelected ? 'DESELECT_ALL' : 'SELECT_ALL_TOTAL') :
              (isLoadedSelected ? 'DESELECT_RANGE' : 'SELECT_RANGE')) |
              translate: {
                count: dataSource.length,
                total: totalItems + (dataSource.length < totalItems && totalItems >= totalLimit && totalItems % pageSize === 0 && '+')
              }
            }}"></mat-checkbox>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        <mat-icon
            *ngIf="!row.avatarIconLink"
            class="avatar-icon">account_circle</mat-icon>

        <img
            *ngIf="row.avatarIconLink"
            alt="{{ row.name }}"
            class="avatar-img"
            draggable="false"
            src="{{ row.avatarIconLink }}">

        <mat-checkbox
            *ngIf="!isMobile"
            [checked]="row.selected"
            (change)="$event ? toggleSelect(row) : null"
            (click)="$event.stopPropagation()"
            tabindex="-1"></mat-checkbox>

        <mat-icon
            *ngIf="isMobile && row.selected"
            class="check-circle"
            color="accent">check_circle</mat-icon>
      </mat-cell>

      <mat-footer-cell *matFooterCellDef class="center">
        {{ 'PAGINATOR_RANGE' | translate: {
          start: 1,
          end: dataSource?.length,
          total: totalItems + (dataSource?.length < totalItems && totalItems >= totalLimit && totalItems % pageSize === 0 && '+')
        } }}
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="_id">
      <mat-header-cell
          *matHeaderCellDef
          class="x5"
          mat-sort-header
          start="desc">{{ 'ID' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row" class="x5">{{ row._id }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell
          *matHeaderCellDef
          class="x4"
          dir="auto"
          mat-sort-header>{{ 'NAME' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x4"
          dir="auto">

        <ng-container *ngIf="isMobile">{{ row.name }}</ng-container>

        <a
            *ngIf="!isMobile"
            (click)="showDetails(false, row)"
            queryParamsHandling="merge"
            [routerLink]="['user', row._id]"
            tabindex="-1"
            title="{{ 'INFO' | translate }}">{{ row.name }} {{ row.surname }}</a>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x4"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell
          *matHeaderCellDef
          class="x5"
          dir="auto"
          mat-sort-header>{{ 'EMAIL' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x5"
          dir="auto">
        <a
            *ngIf="row.email"
            draggable="false"
            href="mailto:{{ row.email }}"
            tabindex="-1"
            title="{{ 'COMPOSE_EMAIL' | translate }}">{{ row.email }}</a>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell
          *matHeaderCellDef
          class="x3"
          dir="auto"
          mat-sort-header>{{ 'PHONE' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x3"
          dir="auto">
        <a
            *ngIf="row.phone"
            draggable="false"
            href="tel:{{ row.phone }}"
            tabindex="-1"
            title="{{ 'CALL_PHONE' | translate }}">{{ row.phone }}</a>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x3"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell
          *matHeaderCellDef
          class="x5"
          dir="auto"
          mat-sort-header>{{ 'ADDRESS' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x5"
          dir="auto">
        <a
            *ngIf="row.address"
            draggable="false"
            href="{{ getAddressURL(row.address) }}"
            rel="noopener"
            target="_blank"
            tabindex="-1"
            title="{{ 'SHOW_ON_MAP' | translate }}">{{ row.address }}</a>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="organization">
      <mat-header-cell
          *matHeaderCellDef
          class="x3"
          dir="auto"
          mat-sort-header>{{ 'ORGANIZATION' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x3"
          dir="auto">{{ row.organization }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x3"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="accountType">
      <mat-header-cell
          *matHeaderCellDef
          class="x1"
          dir="auto"
          mat-sort-header>{{ 'USER.ACCOUNT_TYPE' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x1"
          dir="auto"
          title="{{ accountType.getLabel(row.accountType) | translate }}">
        {{ accountType.getLabel(row.accountType) | translate }}
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x1"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell
          *matHeaderCellDef
          class="x3"
          dir="auto"
          mat-sort-header>{{ 'ACTIVE' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x3"
          dir="auto">{{ (row.active ? 'ACTIVE' : 'DEACTIVATED') | translate }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x3"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell
          *matHeaderCellDef
          class="x2"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'CREATED' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x2"
          dir="auto">{{ row.created | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="modified">
      <mat-header-cell
          *matHeaderCellDef
          class="x2"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'MODIFIED' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x2"
          dir="auto">{{ row.modified | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="more">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
            *ngIf="!selectedCount"
            class="more-button"
            [disabled]="fetching"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{user: row}"
            tabindex="-1"
            type="button">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    <mat-row
        #matRow
        *matRowDef="let row; let i = index; columns: displayedColumns;"
        [id]="row._id"
        [class.active]="i === this.lastIndex"
        [class.selected]="row.selected"
        (contextmenu)="onContextMenu($event, row)"
        (dblclick.not-mobile)="onDblClick($event, row, i)"
        (mousedown.not-mobile)="onMouseDown($event, row, i)"
        (mouseup.not-mobile)="onMouseUp($event, row, i)"
        (press.is-mobile)="onPress($event, row, i)"
        (tap.is-mobile)="onTap($event, row, i)"></mat-row>

    <mat-footer-row
        *matFooterRowDef="displayedColumns"
        [class.hide]="!dataSource?.length"></mat-footer-row>
  </mat-table>

  <section
      *ngIf="!fetching && !dataSource"
      class="empty-state-grid">
    <div class="thumbnail">
      <i></i>
      <div class="content">
        <ng-container *ngIf="!this.searchQuery$.value; else noMatchesFound">
          <mat-icon disabled>person_add</mat-icon>
          <p>{{ 'USERS.NO_USERS_HERE' | translate }}</p>
          <span class="mat-small">{{ 'USER.ADD' | translate }}</span>
        </ng-container>
        <ng-template #noMatchesFound>
          <mat-icon disabled>search</mat-icon>
          <p>{{ 'NO_MATCHES_FOUND' | translate }}</p>
          <span class="mat-small">{{ 'TRY_ANOTHER_SEARCH' | translate }}</span>
        </ng-template>
      </div>
    </div>
  </section>
</div>

<!-- Spinner overlay -->
<app-spinner [fetching]="fetching"></app-spinner>

<!-- Context menu -->
<div
    class="context-menu-trigger"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ id: 'contextMenuTrigger', user: null }">
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-user="user">
    <button (click)="showDetails(false, user)" mat-menu-item>
      <mat-icon aria-label="info">info</mat-icon>
      <span>{{ 'INFO' | translate }}</span>
    </button>
    <button *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType) && [accountType.EDITOR, accountType.PILOT, accountType.CLIENT, accountType.ADMIN, accountType.SUPERADMIN].includes(user?.accountType)" (click)="editFolderTemplate(user)" mat-menu-item>
      <mat-icon aria-label="template folder">folder</mat-icon>
      <span>{{ 'FILES_TEMPLATE.BUTTON' | translate }}</span>
    </button>
    <mat-divider *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType)"></mat-divider>
    <button
        *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType)"
        (click)="delete(user._id)"
        [disabled]="selectedCount > 1"
        mat-menu-item>
      <mat-icon color="warn">delete</mat-icon>
      <span>{{ (selectedCount > 1 ? 'USERS.DELETE' : 'USER.DELETE') | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<!-- Floating Action Button -->
<button
    *ngIf="showFAB"
    @popAnimation
    color="accent"
    (click)="createUser()"
    mat-fab
    title="{{ 'USER.ADD' | translate }}">
  <mat-icon>person_add</mat-icon>
</button>

<button
    *ngIf="showFAB"
    @popAnimation
    color="accent"
    (click)="createUser()"
    mat-mini-fab
    title="{{ 'USER.ADD' | translate }}">
  <mat-icon>person_add</mat-icon>
</button>
