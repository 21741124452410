import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from '../login.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-login-options-dialog',
  templateUrl: './login-options-dialog.component.html',
  styleUrls: ['./login-options-dialog.component.scss']
})
export class LoginOptionsDialogComponent implements OnDestroy {

  ngDestroy$ = new Subject();

  constructor(private dialog: MatDialog,
    private loginService: LoginService
  ) { }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginOptionsDialogComponent, {
      width: '400px', // Adjust width as needed
      autoFocus: false // Optionally disable autofocus
    });

    // Handle dialog close if needed
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed');
    });
  }

  authenticateWithGasoidcMercedes(): void {
    // Implement Google authentication logic here
    this.loginService.authenticateWithGasoidcMercedes().pipe(takeUntil(this.ngDestroy$))
    .subscribe((response: any) => {
      if (response.data.link) {
        window.location.href = response.data.link; // Redirect the user to the link
      }
    });
  }

}
