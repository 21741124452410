import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AfterViewInit, Component, Inject, Input, Optional, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { Platform } from "@angular/cdk/platform";
// eslint-disable-next-line import/no-named-as-default
import Swiper from "swiper";
import { SwiperConfigInterface, SwiperDirective } from "ngx-swiper-wrapper";
import { ZoomOptions } from "swiper/types/components/zoom";

import { DialogService } from "./../../shared/dialog/dialog.service";
import { FileModel } from "./../files/file/file.model";
import { FilesService } from "./../files/files.service";
import { KrpanoService } from "./../../shared/pano/krpano.service";
import { LoginStateService } from "../login/login-state.service";

@Component({
  selector: "app-shared",
  templateUrl: "./shared.component.html",
  styleUrls: ["./shared.component.scss"],
})
export class SharedComponent implements AfterViewInit {
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fileModel: FileModel;
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isPano = false;
  poisTag = '';
  swiperConfig: SwiperConfigInterface = {};
  @ViewChild(SwiperDirective) swiperDirectiveRef?: SwiperDirective;
  swiperSilent = false;
  swiperWheeling: NodeJS.Timeout;
  swiperZoomed = false;
  swiperZooming: NodeJS.Timeout;
  view: string;
  virtualData: any = { slides: [] };

  constructor(
    public filesService: FilesService,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private platform: Platform,
    private krpanoService: KrpanoService,
    public loginStateService: LoginStateService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      fileModelID?: string,
      },
  ) {
    this.route.params.subscribe((params) => {
      if (this.data && this.data.fileModelID) {
        this.getFile(this.data.fileModelID);
      } else {
        const fileID = params["fileID"];
        this.getFile(fileID);
      }
    });
  }

  get swiper(): Swiper {
    return this.swiperDirectiveRef
      ? this.swiperDirectiveRef.swiper()
      : undefined;
  }
  get webContentLink(): string {
    return (
      this.fileModel &&
      this.fileModel.webContentLink &&
      this.fileModel.webContentLink.split("?")[0]
    );
  }
  get zoomMaxRatio(): number {
    // Dynamic max zoom depending on resolution
    // <= 500px = 2 zoom levels = scale(2)
    // > 500px = 3 zoom levels = scale(4)
    // > 2K = 4 zoom levels = scale(8)
    // > 4K = 5 zoom levels = scale(16)
    // > 8K = 6 zoom levels = scale(32)
    return this.fileModel
      ? Math.pow(
          2,
          Math.ceil(
            Math.sqrt(
              Math.max(this.fileModel.height, this.fileModel.width) / 500
            )
          )
        )
      : 2;
  }

  disableContext(): boolean {
    if (this.fileModel?.downloadable) {
      return true;
    } else {
      return false;
    }
  }

  download(): void {
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = this.fileModel.webContentLink;
    link.download = this.fileModel.name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getFile(fileID: string): void {
    this.filesService.findOneShared(fileID).subscribe(
      (response) => {
        if (response) {
          this.fileModel = response.data;
          this.poisTag = '';
          if (this.fileModel.tags) {
            this.fileModel.tags.forEach(tag => {
              if (tag.toLowerCase().startsWith('pois:')) {
                this.poisTag = tag.replace('pois:','')
              }
            });
          }
          this.setFile(); // update form models
        }
      },
      (error) => {
        if (error.status !== 404) {
          const dialogRef = this.dialogService.showDialog(
            null,
            null,
            "FILE.SHARE.ACCESS_DENIED",
            null,
            true,
            true
          ) as Observable<boolean>;
          dialogRef.subscribe(() => {
            this.router.navigate([
              { outlets: { primary: "404", detail: null } },
            ]);
          });
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.swiperConfig = {
      a11y: {
        enabled: true,
      },
      direction: "horizontal",
      keyboard: true,
      mousewheel: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        renderCustom: (swiper, current, total) => {
          return `${current} / ${this.filesService.totalItems$.value}`;
        },
        type: "custom",
      },
      roundLengths: true,
      runCallbacksOnInit: false,
      scrollbar: false,
      slidesPerView: 1,
      virtual: {
        slides: this.filesService.data$.value,
        renderExternal: (data) => {
          this.virtualData = data;
        },
      },
      zoom: {
        maxRatio: 2,
      },
    };
  }

  onPdfLoad(event: any, url: string): void {
    if (event.target.src !== url) {
      if (!this.fileModel?.downloadable) {
        url = url + "#toolbar=0";
      }
      event.target.src = url
    }
  }

  panoLoad(): void {
    if (document.getElementById("id-" + this.fileModel._id) !== null) {
      const container = document.getElementById(
        "id-" + this.fileModel._id
      ) as HTMLDivElement;
      if (this.fileModel.pano === undefined) {
        this.fileModel.pano = {};
      }
      this.krpanoService.divAdd(container);
      this.krpanoService.loadPano(this.fileModel, true, false, this.poisTag);

      this.swiper.allowTouchMove = false; // Disable swiper touch
      this.swiper.zoom.disable();
    } else {
      setTimeout(() => {
        this.panoLoad();
      }, 250);
    }
  }

  setFile(): void {
    this.swiperInit();
    if (this.swiper) {
      this.swiper.allowTouchMove = true; // Enable swiper touch
      this.swiper.zoom.enable();
    }

    if (
      this.fileModel.tags &&
      this.fileModel.tags.some((x) => x === "360_stitched") &&
      this.fileModel.width / 2 === this.fileModel.height
    ) {
      this.panoLoad();
      this.isPano = true;
    }
  }

  swiperIndexChange(index: number): void {
    if (!this.swiperSilent) {
      const id = this.filesService.data$.value[index]._id;
      const params = this.view ? { view: this.view } : {};

      this.router.navigate([{ outlets: { detail: ["file", id, params] } }], {
        queryParamsHandling: "merge",
      });
    }
  }

  swiperInit(): void {
    if (this.swiper) {
      // Define zoom.out with dynamic transitionDuration parameter
      this.swiper.zoom.out = (transitionDuration = 300) => {
        const swiper = this.swiper as any;

        const zoom = swiper.zoom as any;
        const params = swiper.params.zoom;
        const { gesture } = zoom;

        if (!gesture.$slideEl) {
          gesture.$slideEl = swiper.clickedSlide
            ? this.swiper["$"](swiper.clickedSlide)
            : swiper.slides.eq(swiper.activeIndex);
          gesture.$imageEl = gesture.$slideEl.find("img, svg, canvas");
          gesture.$imageWrapEl = gesture.$imageEl.parent(
            `.${params.containerClass}`
          );
        }
        if (!gesture.$imageEl || gesture.$imageEl.length === 0) {
          return;
        }

        zoom.scale = 1;
        zoom.currentScale = 1;
        gesture.$imageWrapEl
          .transition(transitionDuration)
          .transform("translate3d(0,0,0)");
        gesture.$imageEl
          .transition(transitionDuration)
          .transform("translate3d(0,0,0) scale(1)");
        gesture.$slideEl.removeClass(`${params.zoomedSlideClass}`);
        gesture.$slideEl = undefined;
      };

      // Remove momentum with 300ms transition causing the image to flicker
      this.swiper.zoom["onTouchEnd"] = () => {
        const zoom = this.swiper.zoom as any;
        const { gesture, image, velocity } = zoom;
        if (!gesture.$imageEl || gesture.$imageEl.length === 0) {
          return;
        }

        if (!image.isTouched || !image.isMoved) {
          image.isTouched = false;
          image.isMoved = false;

          return;
        }
        image.isTouched = false;
        image.isMoved = false;

        // Define if we need image drag
        const scaledWidth = image.width * zoom.scale;
        const scaledHeight = image.height * zoom.scale;
        image.minX = Math.min(gesture.slideWidth / 2 - scaledWidth / 2, 0);
        image.maxX = -image.minX;
        image.minY = Math.min(gesture.slideHeight / 2 - scaledHeight / 2, 0);
        image.maxY = -image.minY;
        image.currentX = Math.max(
          Math.min(image.currentX, image.maxX),
          image.minX
        );
        image.currentY = Math.max(
          Math.min(image.currentY, image.maxY),
          image.minY
        );

        gesture.$imageWrapEl
          .transition(0)
          .transform(`translate3d(${image.currentX}px, ${image.currentY}px,0)`);
      };

      this.swiperSetIndex(String(this.fileModel._id));

      (this.swiper.params.zoom as ZoomOptions).maxRatio = this.zoomMaxRatio;
      if (this.swiper.zoom.scale > 1) {
        this.swiperZoomed = false;
        (this.swiper.zoom as any).out(0);
      }

      this.swiperResize();
    }
  }

  swiperResize(): void {
    if (this.swiper) {
      this.swiperSilent = true;
      this.swiper.updateSize();
      this.swiper.updateSlides();
      this.swiper.slideTo(this.swiper.activeIndex, 0, false);
      this.swiperSilent = false;
    }
  }

  swiperSetIndex(_id: string): void {
    this.swiperSilent = true; // Silence events
    if (this.swiperDirectiveRef) {
      const index = this.filesService.data$.value.findIndex(
        (file) => file._id === _id
      );

      if (index === -1) {
        //if (this.swiper.virtual.slides !== [this.fileModel]) {
          this.swiper.virtual.slides = [this.fileModel];
          this.swiper.updateSlides();
          this.swiperDirectiveRef.update();
        //}
        this.swiperDirectiveRef.setIndex(0, 0, true);
      } else {
        if (this.swiper.virtual.slides !== this.filesService.data$.value) {
          this.swiper.virtual.slides = this.filesService.data$.value;
          this.swiper.updateSlides();
          this.swiperDirectiveRef.update();
        }
        this.swiperDirectiveRef.setIndex(index, 0, true);
      }
    }
    this.swiperSilent = false;
  }

  swiperWheel(event: any): void {
    const swiper = this.swiper as any;

    const zoom = swiper.zoom as any;
    const params = swiper.params.zoom;
    const { gesture } = zoom;

    if (!gesture.$slideEl || gesture.$slideEl.length === 0) {
      gesture.$slideEl = this.swiper["$"](event.target).closest(
        ".swiper-slide"
      );
      gesture.$imageEl = gesture.$slideEl.find("img");
      gesture.$imageWrapEl = gesture.$imageEl.parent(
        `.${params.containerClass}`
      );
    }
    if (!gesture.$imageEl || gesture.$imageEl.length === 0) {
      return;
    }

    // Prevent default scroll on the image
    if (
      event.target === gesture.$imageEl[0] ||
      event.target.closest("svg") ||
      zoom.scale > 1
    ) {
      event.preventDefault();
    } else {
      // Prevent scrolling the parent slide from wheel inertia from the image
      if (this.swiperWheeling) {
        event.preventDefault();
      }

      return;
    }

    // Detect wheeling inertia by using a 1s timeout
    clearTimeout(this.swiperWheeling);
    this.swiperWheeling = setTimeout(() => {
      this.swiperWheeling = undefined;
    }, 1000);

    let offsetX: number;
    let offsetY: number;
    let diffX: number;
    let diffY: number;
    let translateX = 0;
    let translateY = 0;
    let imageWidth: number;
    let imageHeight: number;
    let scaledWidth: number;
    let scaledHeight: number;
    let translateMinX: number;
    let translateMinY: number;
    let translateMaxX: number;
    let translateMaxY: number;
    let slideWidth: number;
    let slideHeight: number;

    if (event) {
      slideWidth = gesture.$slideEl[0].offsetWidth;
      slideHeight = gesture.$slideEl[0].offsetHeight;
      offsetX = gesture.$slideEl.offset().left;
      offsetY = gesture.$slideEl.offset().top;
      diffX = event.clientX - offsetX - slideWidth / 2;
      diffY = event.clientY - offsetY - slideHeight / 2;

      const transform = gesture.$imageWrapEl.css("transform").split(",");
      if (transform.length === 6) {
        translateX = parseFloat(transform[4]);
        translateY = parseFloat(transform[5]);
      }

      // Determine the point on where the slide is zoomed in
      const zoomTarget = {
        x: (diffX - translateX) / zoom.scale,
        y: (diffY - translateY) / zoom.scale,
      };

      // Apply zoom
      zoom.scale += event.deltaY * -0.01; // Change scale
      zoom.scale = Math.min(Math.max(1, zoom.scale), params.maxRatio); // Restrict scale
      zoom.currentScale = zoom.scale;

      if (zoom.scale > 1) {
        imageWidth = gesture.$imageEl[0].offsetWidth;
        imageHeight = gesture.$imageEl[0].offsetHeight;
        scaledWidth = imageWidth * zoom.scale;
        scaledHeight = imageHeight * zoom.scale;

        translateMinX = Math.min(
          (slideWidth - imageWidth) / 2 - scaledWidth / 2,
          0
        );
        translateMinY = Math.min(
          (slideHeight - imageHeight) / 2 - scaledHeight / 2,
          0
        );
        translateMaxX = -translateMinX;
        translateMaxY = -translateMinY;

        // Calculate x and y based on zoom
        translateX = -zoomTarget.x * zoom.scale + diffX;
        translateY = -zoomTarget.y * zoom.scale + diffY;

        if (translateX < translateMinX) {
          translateX = translateMinX;
        }
        if (translateX > translateMaxX) {
          translateX = translateMaxX;
        }

        if (translateY < translateMinY) {
          translateY = translateMinY;
        }
        if (translateY > translateMaxY) {
          translateY = translateMaxY;
        }
      } else {
        translateX = 0;
        translateY = 0;
      }
    } else {
      translateX = 0;
      translateY = 0;
    }

    // Apply scale transform
    if (zoom.scale > 1) {
      gesture.$slideEl.addClass(`${params.zoomedSlideClass}`);
    } else {
      gesture.$slideEl.removeClass(`${params.zoomedSlideClass}`);
    }
    gesture.$imageWrapEl
      .transition(0)
      .transform(`translate3d(${translateX}px, ${translateY}px,0)`);
    gesture.$imageEl
      .transition(0)
      .transform(`translate3d(0,0,0) scale(${zoom.scale})`);
  }

  swiperZoomChange(event: Array<any>): void {
    // 0: scale, 1: imageEl, 2: slideEl
    const scale = event[0];
    const imageEl = event[1];

    if (scale > 1) {
      this.swiperZoomed = true;

      // Fix blurry image on mobile
      // https://github.com/nolimits4web/swiper/issues/45
      if (this.isMobile) {
        clearTimeout(this.swiperZooming);
        this.swiperZooming = setTimeout(() => {
          this.renderer.setStyle(
            imageEl,
            "transform",
            `translate(0,0) scale(${scale})`
          );
        }, 300);
      }
    }
  }
}
