import { USER_LANGUAGE } from '../user.model';

export interface ResponseData<T> {
  data: T;
  pagination: {
    currentItemCount: number;
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  }
}

export interface SpecialtiesResponse {
  id: number;
  name: string;
  section: string;
  slug: string;
}

export interface PilotSpecialtiesResponse {
  id: number;
  pilotId: number;
  specialty: SpecialtiesResponse ;
  value: string;
}

export interface UserSkill {
  id: number;
  name: string;
  description?: string;
}

export enum E_USER_DOCUMENT_TYPES {
  PERMIT = 'operator-license',
  INSURANCE = 'insurance',
  ID_DOCUMENT = 'id-document',
  OPERATOR = 'operator-waiver'
}

export enum E_USER_ID_TYPE {
  CARD= 'card'
}

export interface IUserType {
  label: string,
  type: E_USER_DOCUMENT_TYPES
}

export interface IIdType {
  label: string,
  type: E_USER_ID_TYPE
}

export interface IDocument {
  id?: string;
  type: E_USER_DOCUMENT_TYPES;
  expirationDate: string;
  file: string | object;
}

export interface IDocumentId extends IDocument {
  idType: E_USER_ID_TYPE,
  country: string,
  number: string
}

export interface IDocumentInsurance extends IDocument{
  company: string;
  number: string;
  totalInsured: string
}

export interface IDocumentPermit extends IDocument {
  company: string;
  numberLicense: string;
  region: string;
  comment: string
}

export interface UserLanguage {
  label: string;
  code: USER_LANGUAGE;
}

export interface IDocumentOperator extends IDocument{
  issuingAuthority: string
  comment: string
}
