import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GasoidcAuthenticationService {

  constructor(private http: HttpClient) {}

  authenticateGasoidc(code: string, state: string): Observable<any> {
    const body = { code: code, state: state };
    return this.http.post<any>(`${environment.apiPath}gasoidc/continue`, body);
  }
}