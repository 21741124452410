<div #content class="content">
  <div class="coverPage">

    <!-- Button to upload logo -->
    <span *ngIf="loginStateService.loggedUser$.value?.subscription?.length > 0 || [accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value.accountType)" class="button noPrint" onclick="document.getElementById('logo-upload').click()">Upload Your Logo</span>
    <!-- Hidden file input -->
    <input type="file" id="logo-upload" class="logo-upload" accept="image/png, image/jpeg, image/jpg" hidden/>
    <!-- Image tag where the logo will be displayed -->
    <img class="pdfLogo" alt="FairFleet Logo" id="logo-display" src="https://fairfleet-assets.s3.eu-central-1.amazonaws.com/a/Logos/FairFleet_Logo_Two_Liner_black.svg">

    <div class="pdfTitle" >
      <h1 class="top" *ngIf="folderModel && folderModel.tags.includes('property')">PROPERTY</h1>
      <h1 class="top" *ngIf="folderModel && folderModel.tags.includes('pv')">SOLAR PANEL</h1>
      <h1 class="top" *ngIf="folderModel && folderModel.tags.includes('antenna')">ANTENNA</h1>
      <h1 class="top" *ngIf="folderModel && folderModel.tags.includes('powerline')">POWER LINE</h1>
      <h1 class="top" *ngIf="folderModel && folderModel.tags.includes('serialNumber')">SERIAL NUMBER</h1>
      <h1 class="middleLine">INSPECTION</h1>
      <h1 class="bottom">REPORT</h1>
    </div>
    <img class="backgroundImage" src="https://fairfleet-assets.s3.eu-central-1.amazonaws.com/a/Single_Drone_Icon_Black_1217.svg" alt="backgroundImage">
  </div>

  <div class="pdfSummary reset">
    <div class="headline">
      <div class="rectangle"></div>
      <h1>{{ 'PDF-EDITOR.SUMMARY' | translate }}</h1>
    </div>

    <!-- ANNOTATIONS SECTION -->
    <div class="sub-section">
      <h2 class="sub-headline">{{ 'FILE.ANNOTATIONS.ANNOTATIONS' | translate }}</h2>
      <div class="annotationWrapper" *ngIf="reportSummary.annotationCount">
        <div class="annotationCount custom-font advisory">{{ 'SIDEBAR.ANNOTATIONS.FEATURE.ADVISORY' | translate }}<span>{{ reportSummary.annotationCount.advisory }}</span></div>
        <div class="annotationCount custom-font green">{{ 'SIDEBAR.ANNOTATIONS.FEATURE.LOW' | translate }}<span>{{ reportSummary.annotationCount.low }}</span></div>
        <div class="annotationCount custom-font orange">{{ 'SIDEBAR.ANNOTATIONS.FEATURE.MIDDLE' | translate }}<span>{{ reportSummary.annotationCount.middle }}</span></div>
        <div class="annotationCount custom-font red">{{ 'SIDEBAR.ANNOTATIONS.FEATURE.HIGH' | translate }}<span>{{ reportSummary.annotationCount.high }}</span></div>
        <div class="annotationCount custom-font purple">{{ 'SIDEBAR.ANNOTATIONS.FEATURE.URGENT' | translate }}<span>{{ reportSummary.annotationCount.urgent }}</span></div>
      </div>
    </div>

    <!-- PROJECT OVERVIEW SECTION -->
    <div class="sub-section">
      <h2>{{ 'PDF-EDITOR.PROJECT_OVERVIEW' | translate }}</h2>
      <table class="metaInfo">
        <tr class="metaInfoRow" *ngIf="folderModel && folderModel.orderID">
          <td class="metaInfoTitle">{{ 'ORDER.ID' | translate }}:</td>
          <td class="metaInfoValue">FF{{ folderModel?.orderID }}</td>
        </tr>
        <tr class="metaInfoRow" *ngIf="orderModel?.projectName">
          <td class="metaInfoTitle">{{ 'ORDER.PROJECT_NAME' | translate }}:</td>
          <td class="metaInfoValue">{{ orderModel?.projectName }}</td>
        </tr>
        <tr class="metaInfoRow" *ngIf="orderModel?.address">
          <td class="metaInfoTitle">{{ 'ORDER.ADDRESS' | translate }}:</td>
          <td class="metaInfoValue">{{ orderModel?.address }}</td>
        </tr>
        <tr class="metaInfoRow">
          <td class="metaInfoTitle">{{ 'PDF-EDITOR.REPORT_DATE' | translate }}:</td>
          <td class="metaInfoValue">{{ reportSummary.generatedDate }}</td>
        </tr>
        <tr class="metaInfoRow">
          <td class="metaInfoTitle">{{ 'PDF-EDITOR.CAPTURE_DATE' | translate }}:</td>
          <td class="metaInfoValue" *ngIf="reportSummary.createDate" >{{ reportSummary.createDate.join(', ') }}</td>
        </tr>
        <tr class="metaInfoRow" *ngIf="reportSummary.cameraModels">
          <td class="metaInfoTitle">{{ 'PDF-EDITOR.USED_CAMERA' | translate }}:</td>
          <td class="metaInfoValue">{{ reportSummary.cameraModels.join(', ') }}</td>
        </tr>
      </table>

    </div>

    <!-- PROPERTY OVERVIEW IMAGE SECTION -->
    <div *ngIf="folderModel?.card?.thumbnail" class="sub-section">
      <h2>Property Overview:</h2>
      <div class="thumbnail">
      </div>
    </div>
  </div>

  <div class="mapPage">

    <!-- DESCRIPTION SECTION -->
    <div class="sub-section">
      <h2 >{{ 'PDF-EDITOR.DESCRIPTION' | translate }} <span class="editDescription noPrint">{{ 'PDF-EDITOR.EDIT' | translate }}</span></h2>
      <p *ngIf="!hasDescription">{{ 'PDF-EDITOR.DESCRIPTION_TEXT' | translate }}</p>
      <p class="normalText" *ngIf="hasDescription" [innerHTML]="makeMultiline(folderModel?.inspection?.summary)"></p>
    </div>

    <!-- ACTIVE FILTER SECTION -->
    <div class="sub-section">
      <h2 *ngIf="filterFileData && filterFileData['feature'] || filterFileData && filterFileData['annotationType'] || filterFileData && filterFileData['minHeight'] || filterFileData && filterFileData['maxHeight'] || filterFileData && filterFileData['imageType']">
        {{ 'FILTERS_ACTIVE' | translate}}:
      </h2>
      <p class="normalText" *ngIf="filterFileData && filterFileData['feature']">
        <span>{{ 'SIDEBAR.ANNOTATIONS.DIMENSION' | translate}}: </span>
        <ng-container *ngIf="!filterFileData['feature'].includes('none')">
          <ng-container *ngFor="let annotationFeature of filterFileData['feature']; let last = last">
            <ng-container>{{ annotationStateDimensionObj[annotationFeature][currentLang] }}<ng-container *ngIf="!last">, </ng-container></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="filterFileData['feature'].includes('none')">
          <ng-container>{{ 'NOT_CLASSIFIED' | translate }}</ng-container>
        </ng-container>
      </p>
      <p class="normalText" *ngIf="filterFileData && filterFileData['annotationType']">
        <span>{{ 'SIDEBAR.ANNOTATIONS.TYPE' | translate}}: </span>
        <ng-container *ngFor="let annotationType of filterFileData['annotationType']; let last = last">
          <ng-container>{{ annotationTypesObj[annotationType][currentLang] }}<ng-container *ngIf="!last">, </ng-container></ng-container>
        </ng-container>
      </p>
      <p class="normalText" *ngIf="filterFileData && filterFileData['minHeight'] || filterFileData && filterFileData['maxHeight']">
        <span>{{ 'INSPECTION.ALTITUDE' | translate }}: </span>
        <span *ngIf="filterFileData['minHeight']">{{filterFileData['minHeight']}}m</span><span *ngIf="!filterFileData['minHeight']">&infin;</span> /
        <span *ngIf="filterFileData['maxHeight']">{{filterFileData['maxHeight']}}m</span><span *ngIf="!filterFileData['maxHeight']">&infin;</span>
      </p>
    </div>

    <!-- OVERVIEW MAP SECTION -->
    <div class="sub-section">
      <h2>{{ 'PDF-EDITOR.OVERVIEWMAP' | translate }} <span class="editOverviewMap noPrint">{{ 'PDF-EDITOR.EDIT' | translate }}</span></h2>
      <div class="overviewMap"></div>
    </div>
  </div>

  <div class="pdfFile" *ngFor="let file of dataSource.slice(0, 3); let i = index;">
    <div class="imageIdHeadline">
      <div class="image_id borderBottom">
        <h2>IMAGE ID: </h2>
        <h2>{{ file.inspection.fileID }}</h2>
      </div>
    </div>
    <div class="pdfMeta">
      <ul class="left">
        <li class="metaName">{{ 'PDF-EDITOR.NAME' | translate }}:
          <span class="rtl">
            <a href="./inspection/{{ folderModel._id }}(detail:file/{{ file._id }})" target="_blank" rel="noopener">
              {{ file.name }}
            </a>
          </span>
        </li>
        <li class="metaDate" *ngIf="file.meta.CreateDate">{{ 'PDF-EDITOR.DATE' | translate }}: <span>{{ convertMetaDate(file.meta.CreateDate) }}</span></li>
      </ul>
      <ul>
        <li class="metaPosition" *ngIf="file.meta.GPSLatitude">{{ 'PDF-EDITOR.POSITION' | translate }}:
          <span *ngIf="file.meta && file.meta.GPSLatitude && file.meta.GPSLongitude">
            <a href="https://www.google.com/maps/search/{{file.meta.GPSLatitude.num | number:'.6'}},{{file.meta.GPSLongitude.num | number:'.6'}}?hl=en&source=opensearch" target="_blank" rel="noopener">
                {{ file.meta.GPSLatitude.num | number:'.6' }},{{ file.meta.GPSLongitude.num | number:'.6' }}
            </a>
          </span>
        </li>
        <li class="metaAltitude" *ngIf="filesService.checkImageHeightRel(file)">{{ 'PDF-EDITOR.ALTITUDE' | translate }}: <span>{{ filesService.getImageHeightRel(file) }}m</span></li>
        <li class="metaHeading" *ngIf="file.yaw">{{ 'PDF-EDITOR.HEADING' | translate }}: <span>{{ getImageYaw(file) }}</span></li>
      </ul>
    </div>
    <div [ngStyle]="{'height': file.thermalSubFileModel ? '252px' : '527px'}" class="pdfImage">
      <div class="image" [ngStyle]="{'width': file.thermalSubFileModel ? '51.5%' : '100%'}" [ngClass]="'_'+file._id"></div>
      <div *ngIf="file.thermalSubFileModel" class="image" [ngClass]="'_match_'+file.thermalSubFileModel._id"></div>
    </div>
    <h2 *ngIf="file.annotations?.length > 0" class="sub-headline">{{ 'FILE.ANNOTATIONS.ANNOTATIONS' | translate }}</h2>
    <div class="pdfAnnotation" *ngFor="let annotation of file.annotations; let ia = index; let odd = odd;"
        [ngClass]="odd ? 'flex-row' : 'flex-row'">
      <!-- Since OpenLayers gets loaded after the width and height of the image is loaded, we use a placeholder until the real image gets loaded -->
      <img height="165" alt="placeholder" class="placeholderImg" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD//gATQ3JlYXRlZCB3aXRoIEdJTVD/4gKwSUNDX1BST0ZJTEUAAQEAAAKgbGNtcwQwAABtbnRyUkdCIFhZWiAH5QAKAA0ACQAZABFhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA1kZXNjAAABIAAAAEBjcHJ0AAABYAAAADZ3dHB0AAABmAAAABRjaGFkAAABrAAAACxyWFlaAAAB2AAAABRiWFlaAAAB7AAAABRnWFlaAAACAAAAABRyVFJDAAACFAAAACBnVFJDAAACFAAAACBiVFJDAAACFAAAACBjaHJtAAACNAAAACRkbW5kAAACWAAAACRkbWRkAAACfAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACQAAAAcAEcASQBNAFAAIABiAHUAaQBsAHQALQBpAG4AIABzAFIARwBCbWx1YwAAAAAAAAABAAAADGVuVVMAAAAaAAAAHABQAHUAYgBsAGkAYwAgAEQAbwBtAGEAaQBuAABYWVogAAAAAAAA9tYAAQAAAADTLXNmMzIAAAAAAAEMQgAABd7///MlAAAHkwAA/ZD///uh///9ogAAA9wAAMBuWFlaIAAAAAAAAG+gAAA49QAAA5BYWVogAAAAAAAAJJ8AAA+EAAC2xFhZWiAAAAAAAABilwAAt4cAABjZcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltjaHJtAAAAAAADAAAAAKPXAABUfAAATM0AAJmaAAAmZwAAD1xtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAEcASQBNAFBtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEL/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAVSf/8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwF//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwF//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPyF//9oADAMBAAIAAwAAABCf/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPxB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPxB//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPxB//9k=">
      <div class="map"
          [ngClass]="'_'+file._id+ia"></div>
      <div *ngIf=" file.thermalSubFileModel && existCorrespondingThermalAnno(file, annotation)" class="map"
          [ngClass]="'_match_'+file.thermalSubFileModel._id+ia"></div>

      <div [ngStyle]="{'width': file.thermalSubFileModel && existCorrespondingThermalAnno(file, annotation) ? '315px' : '485px'}" class="annotationContainer">
        <table class="annotationInfo">
          <tr *ngIf="annotation.annoID" class="annotationInfoRow">
            <td class="annotationInfoTitle">{{ 'PDF-EDITOR.ANNOTATION' | translate }}:</td>
            <td class="annotationInfoValue">{{ annotation.annoID }}</td>
          </tr>

          <tr *ngIf="annotation.feature" class="annotationInfoRow">
            <td class="annotationInfoTitle">{{ 'PDF-EDITOR.FEATURE' | translate }}:</td>
            <td class="annotationInfoValue">{{ inspectionService.getFeature(annotation.feature, currentLang) }} ( {{inspectionService.getStateDimension(annotation.stateDimension, currentLang)}} )</td>
          </tr>
            <tr *ngIf="annotation.averageTemperature" class="annotationInfoRow">
              <td class="annotationInfoTitle">Temperatures:</td>
              <td class="annotationInfoValue Temperature">
                <span>Avg: {{annotation.averageTemperature}}°C | </span>
                <span>Delta: {{annotation.deltaTemperature}}°C | </span>
                <span>Min: {{annotation.minimumTemperature}}°C | </span>
                <span>Max: {{annotation.maximumTemperature}}°C </span>
              </td>
            </tr>
            <tr *ngIf="annotation.comment" class="annotationInfoRow">
              <td class="annotationInfoTitle">{{ 'PDF-EDITOR.COMMENT' | translate }}:</td>
              <td class="annotationInfoValue">{{annotation.comment}}</td>
            </tr>
        </table>
        <div class="annotationFooter">
          <hr> <!-- Horizontal line -->
          <div class="content">
            <div class="left">{{annotation.userName}}</div>
            <div class="right">{{annotation.modified | date:'dd.MM.yyyy HH:mm:ss' }}</div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!filterFileData['imageType']">
          <div class="pdfAnnotation" *ngFor="let annotation of getOnlyThermalAnnotation(file); let ia = index; let odd = odd;">
        <!-- Since OpenLayers gets loaded after the width and height of the image is loaded, we use a placeholder until the real image gets loaded -->
        <img height="165" alt="placeholder" class="placeholderImg" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD//gATQ3JlYXRlZCB3aXRoIEdJTVD/4gKwSUNDX1BST0ZJTEUAAQEAAAKgbGNtcwQwAABtbnRyUkdCIFhZWiAH5QAKAA0ACQAZABFhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA1kZXNjAAABIAAAAEBjcHJ0AAABYAAAADZ3dHB0AAABmAAAABRjaGFkAAABrAAAACxyWFlaAAAB2AAAABRiWFlaAAAB7AAAABRnWFlaAAACAAAAABRyVFJDAAACFAAAACBnVFJDAAACFAAAACBiVFJDAAACFAAAACBjaHJtAAACNAAAACRkbW5kAAACWAAAACRkbWRkAAACfAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACQAAAAcAEcASQBNAFAAIABiAHUAaQBsAHQALQBpAG4AIABzAFIARwBCbWx1YwAAAAAAAAABAAAADGVuVVMAAAAaAAAAHABQAHUAYgBsAGkAYwAgAEQAbwBtAGEAaQBuAABYWVogAAAAAAAA9tYAAQAAAADTLXNmMzIAAAAAAAEMQgAABd7///MlAAAHkwAA/ZD///uh///9ogAAA9wAAMBuWFlaIAAAAAAAAG+gAAA49QAAA5BYWVogAAAAAAAAJJ8AAA+EAAC2xFhZWiAAAAAAAABilwAAt4cAABjZcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltjaHJtAAAAAAADAAAAAKPXAABUfAAATM0AAJmaAAAmZwAAD1xtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAEcASQBNAFBtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEL/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAVSf/8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwF//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwF//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPyF//9oADAMBAAIAAwAAABCf/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPxB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPxB//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPxB//9k=">
        <div class="map" [ngClass]="'_matchrest_'+file.thermalSubFileModel._id+ia"></div>

        <div>
          <table class="annotationInfo">
            <tr class="annotationInfoRow">
              <td class="annotationInfoTitle">{{ 'PDF-EDITOR.ANNOTATION' | translate }}:</td>
              <td class="annotationInfoValue">{{ annotation.annoID }}</td>
            </tr>
            <tr class="annotationInfoRow">
              <td class="annotationInfoTitle">{{ 'PDF-EDITOR.FEATURE' | translate }}:</td>
              <td class="annotationInfoValue">{{ inspectionService.getFeature(annotation.feature, currentLang) }} ({{inspectionService.getStateDimension(annotation.stateDimension, currentLang)}})</td>
            </tr>
              <tr class="annotationInfoRow">
                <td class="annotationInfoTitle">Temps.:</td>
                <td class="annotationInfoValue Temperature">
                  <span>Avg: {{annotation.averageTemperature}}°C | </span>
                  <span>Delta: {{annotation.deltaTemperature}}°C | </span>
                  <span>Min: {{annotation.minimumTemperature}}°C | </span>
                  <span>Max: {{annotation.maximumTemperature}}°C </span>
                </td>
              </tr>
              <tr class="annotationInfoRow">
                <td class="annotationInfoTitle">{{ 'PDF-EDITOR.COMMENT' | translate }}:</td>
                <td class="annotationInfoValue">{{annotation.comment}}</td>
              </tr>
          </table>
          <div class="annotationFooter">
            <hr>
            <div class="content">
              <div class="left"><span class="material-icons">person</span>{{annotation.userName}}</div>
              <div class="right"><span class="material-icons">schedule</span>{{annotation.modified | date:'dd.MM.yyyy HH:mm:ss' }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="filterFileData['imageType']?.includes('Wide') && filterFileData['imageType']?.includes('Thermal')">
      <div class="pdfAnnotation" *ngFor="let annotation of getOnlyThermalAnnotation(file); let ia = index;">
    <!-- Since OpenLayers gets loaded after the width and height of the image is loaded, we use a placeholder until the real image gets loaded -->
    <img height="165" alt="placeholder" class="placeholderImg" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD//gATQ3JlYXRlZCB3aXRoIEdJTVD/4gKwSUNDX1BST0ZJTEUAAQEAAAKgbGNtcwQwAABtbnRyUkdCIFhZWiAH5QAKAA0ACQAZABFhY3NwQVBQTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9tYAAQAAAADTLWxjbXMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA1kZXNjAAABIAAAAEBjcHJ0AAABYAAAADZ3dHB0AAABmAAAABRjaGFkAAABrAAAACxyWFlaAAAB2AAAABRiWFlaAAAB7AAAABRnWFlaAAACAAAAABRyVFJDAAACFAAAACBnVFJDAAACFAAAACBiVFJDAAACFAAAACBjaHJtAAACNAAAACRkbW5kAAACWAAAACRkbWRkAAACfAAAACRtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACQAAAAcAEcASQBNAFAAIABiAHUAaQBsAHQALQBpAG4AIABzAFIARwBCbWx1YwAAAAAAAAABAAAADGVuVVMAAAAaAAAAHABQAHUAYgBsAGkAYwAgAEQAbwBtAGEAaQBuAABYWVogAAAAAAAA9tYAAQAAAADTLXNmMzIAAAAAAAEMQgAABd7///MlAAAHkwAA/ZD///uh///9ogAAA9wAAMBuWFlaIAAAAAAAAG+gAAA49QAAA5BYWVogAAAAAAAAJJ8AAA+EAAC2xFhZWiAAAAAAAABilwAAt4cAABjZcGFyYQAAAAAAAwAAAAJmZgAA8qcAAA1ZAAAT0AAACltjaHJtAAAAAAADAAAAAKPXAABUfAAATM0AAJmaAAAmZwAAD1xtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAEcASQBNAFBtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEL/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAVSf/8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwF//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwF//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPyF//9oADAMBAAIAAwAAABCf/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPxB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPxB//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPxB//9k=">
    <div class="map"
        [ngClass]="'_matchrest_'+file.thermalSubFileModel._id+ia"></div>
    <div>
    </div>

    <div>
      <table class="annotationInfo">
        <tr class="annotationInfoRow">
          <td class="annotationInfoTitle">{{ 'PDF-EDITOR.ANNOTATION' | translate }}:</td>
          <td class="annotationInfoValue">{{ annotation.annoID }}</td>
        </tr>
        <tr class="annotationInfoRow">
          <td class="annotationInfoTitle">{{ 'PDF-EDITOR.FEATURE' | translate }}:</td>
          <td class="annotationInfoValue">{{ inspectionService.getFeature(annotation.feature, currentLang) }} ( {{inspectionService.getStateDimension(annotation.stateDimension, currentLang)}} )</td>
        </tr>
          <tr class="annotationInfoRow">
            <td class="annotationInfoTitle">Temps.:</td>
            <td class="annotationInfoValue Temperature">
              <span>Avg: {{annotation.averageTemperature}}°C | </span>
              <span>Delta: {{annotation.deltaTemperature}}°C | </span>
              <span>Min: {{annotation.minimumTemperature}}°C | </span>
              <span>Max: {{annotation.maximumTemperature}}°C </span>
            </td>
          </tr>
          <tr class="annotationInfoRow">
            <td class="annotationInfoTitle">{{ 'PDF-EDITOR.COMMENT' | translate }}:</td>
            <td class="annotationInfoValue">{{annotation.comment}}</td>
          </tr>
      </table>
      <div class="annotationFooter">
        <hr>
        <div class="content">
          <div class="left"><span class="material-icons">person</span>{{annotation.userName}}</div>
          <div class="right"><span class="material-icons">schedule</span>{{annotation.modified | date:'dd.MM.yyyy HH:mm:ss' }}</div>
        </div>
      </div>
    </div>
  </div>
    </ng-container>
  </div>

  <div class="blurPage">

    <!-- BLURED PAGE -->
    <div class="sub-section">
      <div class="note">
        <span>Click On</span>
        <span>Save</span>
        <span>to Generate the Full PDF</span>
      </div>
      <div class="bluredImage">
        <img src="assets/images/template.png" alt="bluredImage">
      </div>
    </div>
  </div>
</div>
<button class="editSummary" (click)="editSummary()">Edit</button>
<button class="toggleOverviewMap" (click)="toggleInteraction()">Edit</button>
<div #output class="preview"></div>
<mat-progress-bar
    *ngIf="loading"
    color="accent"
    mode="indeterminate" ></mat-progress-bar>
