import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from './../../shared/dialog/dialog.service';
import { Login } from './../login/login.model';
import { LoginStateService } from './../login/login-state.service';
import { ResetPasswordService } from './reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy, OnInit {

  fetching: boolean;
  formGroup: FormGroup;
  ngDestroy$ = new Subject();
  showPassword: boolean;
  token: string;
  userID: string;

  constructor(
      private dialogService: DialogService,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder,
      private loginStateService: LoginStateService,
      private resetPasswordService: ResetPasswordService,
      private route: ActivatedRoute,
      private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      password: new FormControl({ value: '', disabled: this.fetching }, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ])
    });

  }

  get password(): AbstractControl { return this.formGroup.get('password'); }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  ngOnInit(): void {
    this.route.params
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(params => {
      if (params) {
        this.userID = params['userID'];
        this.token = params['jwt'];
      }
    });
  }

  submitNewPassword(): void {
    this.fetching = true;
    this.resetPasswordService
    .resetPassword(this.userID, this.password.value, this.token)
    .subscribe(response => {
      this.fetching = false;
      if (response['token']) {
          const login = new Login(response['token']);
          this.loginStateService.saveLogin(login);

          this.router.navigate(['/']);
      } else {
        this.dialogService.showDialog('AUTH.PASSWORD_RESET_FAILED', null, 'AUTH.NO_TOKEN');
      }
    },
    error => {
      this.fetching = false;
      this.dialogService.showDialog('AUTH.PASSWORD_RESET_FAILED', error.status, error.url, error.error);
    });
  }

  toggleShowPassword(event: MouseEvent): void {
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }
}
