import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

import { ComponentCanDeactivateDirective } from './component-can-deactivate.directive';
import { DialogService } from './../dialog/dialog.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateDirective> {

  constructor(private dialogService: DialogService) { }

  canDeactivate(component: ComponentCanDeactivateDirective): Observable<boolean> | boolean {

    if (typeof component.canDeactivate === 'function' && !component.canDeactivate()) {
      const confirm = this.dialogService.showDialog(null, null, 'DISCARD_CHANGES', null, true);

      return confirm;
    }

    return true;
  }
}
