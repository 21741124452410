import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// eslint-disable-next-line import/no-unresolved
import { Point } from 'geojson';

import { environment } from './../../../environments/environment';

@Injectable()
export class LambdaService {

  constructor(private http: HttpClient) {
    // to do
  }

  runFunction(fn: string, lambdaRequestBody: LambdaRequestBody): Observable<any> {
    const href = `${environment.apiPath}lambda/${encodeURIComponent(fn)}`;

    return this.http.post<any>(href, lambdaRequestBody);
  }

}

export class LambdaRequestBody {
  bucket: string;
  keys: Array<string>;
  operations: Array<LambdaOperation>;
  region?: string;
  tagging?: string;
}

export class LambdaOperation {
  name: string;
  parameters?: Array<string>;
}

export class LambdaResponseBody {
  /* eslint-disable @typescript-eslint/member-ordering */
  // Returned by meta operation
  meta?: object;
  geo?: Point;
  taken?: Date;

  // Returned by fileURL, iconURL or thumbnailURL operations
  fileURL?: string;
  webContentLink?: string;
  webViewLink?: string;
  fileHeight?: number;
  fileWidth?: number;

  iconURL: string;
  iconLink?: string;
  iconHeight?: number;
  iconWidth?: number;

  thumbnailURL: string;
  thumbnailLink?: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
}
