import { UserRole, AccountType } from '../users/models/user.model';

export class Token {
  exp?: number; // Expire in 24 hours until we implement some refresh jwt method
  iat?: number;
  role?: number;
  accountType?: AccountType;
  provider?: string;
  sub?: string; // Subject
  subscription?: string; // active Subscription
}

export class RefreshToken {
  exp?: number;
  sub?: string;
}

export class Login {
  refreshToken?: string;
  token?: string;

  get _id(): string {
    const userClaims = Login.userClaimsToken(this.token);

    return userClaims.sub;
  }

  get isLoggedIn(): boolean {
    const userClaims = Login.userClaimsToken(this.token);

    return !!userClaims.sub;
  }

  get role(): UserRole {
    const userClaims = Login.userClaimsToken(this.token);

    return userClaims.role;
  }

  get accountType(): AccountType {
    const userClaims = Login.userClaimsToken(this.token);
    return userClaims.accountType;
  }

  constructor(token: string, refreshToken?: string) {
    this.token = token;
    this.refreshToken = refreshToken;
  }

  public checkSubscription(): boolean {
    const userClaims = Login.userClaimsToken(this.token);

    if (!userClaims) {
        return false;
    }

    // Check if Admin or SuperAdmin
    const isAdmin = [AccountType.ADMIN, AccountType.SUPERADMIN].includes(userClaims.accountType);
    
    // Check if user has a valid subscription
    const hasSubscription = userClaims.subscription && userClaims.subscription !== '';

    return isAdmin || hasSubscription;
}

  private static urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // eslint-disable-next-line no-throw-literal
        throw 'Illegal base64url string!';
    }

    const isBrowser = typeof window !== 'undefined' && typeof window.atob === 'function';
    output = isBrowser ? window.atob(output) : Buffer.from(output, 'base64').toString('binary');

    return decodeURIComponent(escape(output));
  }

  private static userClaimsToken(token: string): Token {
    if (token === null || token === '') { return new Token(); }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = Login.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded) as Token;
  }
}
