import * as HttpStatus from 'http-status-codes';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import { LoginStateService } from './../../pages/login/login-state.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private token: string;

  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private loginStateService: LoginStateService,
      private router: Router
  ) {
    this.loginStateService.login$.subscribe(login => {
      if (login) {
        this.token = login.token;
      }
    });
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<any> { // HttpEvent<any>

    if (isPlatformBrowser(this.platformId) && httpRequest.url.startsWith(environment.apiPath)) {
      // console.log('httpRequest.url: ', httpRequest.url, 'environment.apiPath: ', environment.apiPath);
      httpRequest = httpRequest.clone({
        setHeaders: { // Prevent caching when navigating back
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        },
        url: httpRequest.url.replace(environment.apiPath, '/api/'),
        // url: httpRequest.url,
      });
    }

    // Only specify the JWT token in the Authorization header of requests you send to the API service.
    // Do NOT send the token over unsecured channels and do NOT include it in HTTP requests that you send to other services.
    // The JWT token that you obtain from the login service is like a password and should be handled with great care.
    // Anyone that possesses the token may use it to perform operations on behalf of your user.
    if (this.token &&
        (httpRequest.url.startsWith(environment.apiHost) || // Long requests (> 180s)
        httpRequest.url.startsWith(environment.apiPath) || // Short requests (< 180s)
        httpRequest.url.startsWith('/api/'))) { // Relative path when in browser, see above
      httpRequest = this.addToken(httpRequest, this.token);
    }

    return next.handle(httpRequest)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse && error.status === HttpStatus.StatusCodes.UNAUTHORIZED &&
              !httpRequest.url.endsWith('/refreshToken')) { // Don't handle the 401 error returned from refreshToken request
            return this.handle401Error(httpRequest, next);
          } else {
            return throwError(error);
          }
        })
      );
  }

  // eslint-disable-next-line class-methods-use-this
  private addToken(httpRequest: HttpRequest<any>, token: string): HttpRequest<any> {
    return httpRequest.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshToken$.next(null);

      return this.loginStateService.refreshToken()
        .pipe(
            switchMap((response: any) => {
              this.isRefreshing = false;
              const token = response['data']['token'];
              this.refreshToken$.next(token);

              return next.handle(this.addToken(request, token));
            }),
            catchError((error: any) => {
              this.router.navigate([{ outlets: { primary: 'login', detail: null }}]);

              return of(error);
            })
        );
    } else {
      return this.refreshToken$.pipe(
        filter(token => token != null),
        take(1),
        switchMap(newToken => {
          return next.handle(this.addToken(request, newToken));
        }));
    }
  }
}
