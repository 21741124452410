<div
    class="header mat-toolbar-single-row"
    [class.dark]="this.theme && this.theme.includes('dark')">
    <app-inspection-filter></app-inspection-filter>
</div>
<div #wrapper
    class="wrapper"
    [class.dark]="this.theme && this.theme.includes('dark')">
  <div
      class="imageArea"
      mwlResizable
      #resizableVertical="mwlResizable"
      [style.height.px]="vertical"
      (resizing)="resizingVertical($event)">
    <app-ol-image></app-ol-image>
  </div>
  <div class="horizontalBorder"
    [style.top.px]="vertical+height">
    <div
        [style.right.px]="inspectionArea.width-horizontal"
        class="borderHeader mapHeader">
      <div class="center">
        <mat-icon *ngIf="showMap">room</mat-icon>
        <span *ngIf="showMap">{{ 'INSPECTION.VIEW.MAP' | translate }}</span>
      </div>
    </div>
    <div
        *ngIf="antennaInspection"
        [style.left.px]="horizontalLeft"
        class="borderHeader center contentHeader">
      <mat-icon>settings_input_antenna</mat-icon>
      {{ 'INSPECTION.VIEW.ANTENNA' | translate }}
    </div>
    <mat-icon
        mwlResizeHandle
        [resizableContainer]="resizableVertical"
        [resizeEdges]="{bottom: true}"
        class="handleHorizontal"
        svgIcon="resize_handle_vertical_arrows"
        [style.left.px]="horizontalFiles() -32"></mat-icon>
    <div
        (click)="filesViewChange(files)"
        (mouseenter)="showFilesSwitcher=true"
        (mouseleave)="showFilesSwitcher=false"
        [style.left.px]="horizontalFiles()"
        class="borderHeader contentHeader interaction">
      <span class="center">
        <mat-icon *ngIf="files.viewMode === 'grid'">view_module</mat-icon>
        <mat-icon *ngIf="files.viewMode === 'list'">view_list</mat-icon>
        {{ 'INSPECTION.VIEW.CONTENT' | translate }}
        <span class="small" *ngIf="showFilesSwitcher && files.viewMode === 'list'">(Grid view)</span>
        <span class="small" *ngIf="showFilesSwitcher && files.viewMode === 'grid'">(List view)</span>
      </span>
    </div>
  </div>
  <div
      mwlResizable
      #resizableHorizontalLeft="mwlResizable"
      class="mapArea"
      [style.top.px]="vertical+border+height"
      [style.width.px]="horizontalLeft-border"
      (resizing)="resizingHorizontalLeft($event)">
      <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="viewer"
          [value]="selectedMapView"
          (change)="mapViewerChange(group.value)"
          *ngIf="has3dView">
        <mat-button-toggle value="2d">2D</mat-button-toggle>
        <mat-button-toggle value="3d">3D</mat-button-toggle>
      </mat-button-toggle-group>
      <ng-template #containerMap></ng-template>
  </div>
  <div class="verticalBorder"
      [style.top.px]="vertical+border+height"
      [style.left.px]="horizontalLeft-border">
      <mat-icon
          mwlResizeHandle
          [resizableContainer]="resizableHorizontalLeft"
          [resizeEdges]="{right: true}"
          class="handleVertical"
          svgIcon="resize_handle_vertical_arrows"></mat-icon>
  </div>
  <div
      mwlResizable
      #resizableHorizontal="mwlResizable"
      class="mapArea"
      [style.top.px]="vertical+border+height"
      [style.left.px]="horizontalLeft"
      [style.width.px]="horizontalAntenna()"
      (resizing)="resizingHorizontal($event)">
      <ng-template
          #containerAntenna></ng-template>
  </div>
  <div
      *ngIf="antennaInspection"
      class="verticalBorder"
      [style.top.px]="vertical+border+height"
      [style.left.px]="horizontal">
      <mat-icon
          *ngIf="antennaInspection"
          mwlResizeHandle
          [resizableContainer]="resizableHorizontal"
          [resizeEdges]="{right: true}"
          class="handleVertical"
          svgIcon="resize_handle_vertical_arrows"></mat-icon>
  </div>
  <div
      class="folderArea"
      [style.top.px]="vertical+border+height"
      [style.left.px]="horizontalFiles()">
    <app-files #files></app-files>
  </div>
</div>
