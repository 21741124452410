import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CameraDictionaryObj } from '../dictionarys/cameras';
import { FileModel } from './../../pages/files/file/file.model';
import { FilesService } from './../../pages/files/files.service';
import { searchToFilter } from './../helpers/data-helpers';

@Component({
  selector: 'app-data-check',
  templateUrl: './data-check.component.html',
  styleUrls: ['./data-check.component.scss']
})
export class DataCheckComponent implements OnInit{
  subscription: Subscription;
  imgDataSource: Array<FileModel> = [];
  displayedColumns: string[] = ['name', 'iso', 'shutter', 'aperture', 'pitch', 'RelativeAltitude', 'RtkFlag', 'flightSpeed'];
  chartData = {}

  private ngDestroy$ = new Subject();

  @ViewChild('doubleLineCanvas') doubleLineCanvas: ElementRef;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: {
    folderModel?: FileModel,
    },
    public filesService: FilesService
  ) {
  }

  ngOnInit(): void {
    let search = '';
    search = search.trim();
    const filter = searchToFilter(search);
    if (!Object.keys(filter).length) {
      // Set folderID from the route ID
      filter['folderID'] = this.data.folderModel._id && String(this.data.folderModel._id).length === 24 ?
        { $oid: this.data.folderModel._id } : { $exists: false };
    }

    const sort: object = { name: 1 };
    const fields: object = { name: 1, geo: 1, iconLink: 1, tags: 1, meta: 1, annotations: 1, mimeType: 1, yaw: 1, relativeAltitude: 1, pitch: 1, public:1, hidden:1/*, downloadable:1*/ };

    // Get data from the server
    this.subscription = this.filesService.findMany(filter, sort, 0, 100000, fields)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {
        this.imgDataSource = response.data.filter(data => data.mimeType === 'image/jpeg');
        this.getChartdata();
      });
  }

  calcFlightSpeed(data: FileModel): string {
    const speedXY = Math.sqrt(Math.pow(data['meta']['FlightXSpeed'], 2) + Math.pow(data['meta']['FlightYSpeed'], 2));
    const speedXYZ = Math.sqrt(Math.pow(speedXY, 2) + Math.pow(data['meta']['FlightZSpeed'], 2)).toFixed(1);
    if (speedXYZ === 'NaN') {
      return ''
    }
    return speedXYZ;
  }

  getChartdata(): void {
    const apertureValues = {}
    const cameraModelsValue = {}
    const flightSpeedValues = {}
    const imageSizeValues = {}
    const isoValues = {}
    const pitchValues = {}
    const relativeAltitudeValues = {}
    const RtkFlagValues = {}
    const shutterValues = {}
    
    this.imgDataSource.forEach(data =>  {
      // Aperture
      if (data['meta']['ApertureValue'] && data['meta']['ApertureValue']['val']){
        if (Object.keys(apertureValues).includes(String(data['meta']['ApertureValue']['val']))){
          apertureValues[data['meta']['ApertureValue']['val']] = apertureValues[data['meta']['ApertureValue']['val']] + 1
        }
        if (!Object.keys(apertureValues).includes(String(data['meta']['ApertureValue']['val']))){
          apertureValues[data['meta']['ApertureValue']['val']] = 1
        }
      }
      else if (data['meta']['Aperture']){
        if (Object.keys(apertureValues).includes(String(data['meta']['Aperture']))){
          apertureValues[data['meta']['Aperture']] = apertureValues[data['meta']['Aperture']] + 1
        }
        if (!Object.keys(apertureValues).includes(String(data['meta']['Aperture']))){
          apertureValues[data['meta']['Aperture']] = 1
        }
      }

      // cameraModels
      if (Object.keys(cameraModelsValue).includes(String(data['meta']['Model']))){
        cameraModelsValue[data['meta']['Model']] = cameraModelsValue[data['meta']['Model']] + 1
      }
      if (!Object.keys(cameraModelsValue).includes(String(data['meta']['Model']))){
        cameraModelsValue[data['meta']['Model']] = 1
      }

      // FlightSpeed
      let speedXYZ = this.calcFlightSpeed(data);
      if (speedXYZ === '') {
        speedXYZ = undefined;
      }
      if (Object.keys(flightSpeedValues).includes(String(speedXYZ))){
        flightSpeedValues[speedXYZ] = flightSpeedValues[speedXYZ] + 1
      }
      if (!Object.keys(flightSpeedValues).includes(String(speedXYZ))){
        flightSpeedValues[speedXYZ] = 1
      }

      // ImageSize
      const size = String(data['meta']['ImageWidth'])+'x'+String(data['meta']['ImageHeight'])
      if (Object.keys(imageSizeValues).includes(size)){
        imageSizeValues[size] = imageSizeValues[size] + 1
      }
      if (!Object.keys(imageSizeValues).includes(size)){
        imageSizeValues[size] = 1
      }

      // ISO
      if (Object.keys(isoValues).includes(String(data['meta']['ISO']))){
        isoValues[data['meta']['ISO']] = isoValues[data['meta']['ISO']] + 1
      }
      if (!Object.keys(isoValues).includes(String(data['meta']['ISO']))){
        isoValues[data['meta']['ISO']] = 1
      }

      // Pitch
      if (this.filesService.checkImagePitch(data)) {
        if (Object.keys(pitchValues).includes(String(this.filesService.getImagePitch(data)))){
          pitchValues[this.filesService.getImagePitch(data)] = pitchValues[this.filesService.getImagePitch(data)] + 1
        }
        if (!Object.keys(pitchValues).includes(String(this.filesService.getImagePitch(data)))){
          pitchValues[this.filesService.getImagePitch(data)] = 1
        }
      }

      // relativeAltitude
      if (this.filesService.checkImageHeightRel(data)) {
        if (Object.keys(relativeAltitudeValues).includes(String(this.filesService.getImageHeightRel(data)))){
          relativeAltitudeValues[Number(this.filesService.getImageHeightRel(data)).toFixed(1)] = relativeAltitudeValues[this.filesService.getImageHeightRel(data)] + 1
        }
        if (!Object.keys(relativeAltitudeValues).includes(String(this.filesService.getImageHeightRel(data)))){
          relativeAltitudeValues[Number(this.filesService.getImageHeightRel(data)).toFixed(1)] = 1
        }
      }

      // RTK-Flag
      if (Object.keys(RtkFlagValues).includes(String(data['meta']['RtkFlag']))){
        RtkFlagValues[data['meta']['RtkFlag']] = RtkFlagValues[data['meta']['RtkFlag']] + 1
      }
      if (!Object.keys(RtkFlagValues).includes(String(data['meta']['RtkFlag']))){
        RtkFlagValues[data['meta']['RtkFlag']] = 1
      }

      // ShutterSpeed
      if(data['meta']['ShutterSpeed'] && data['meta']['ShutterSpeed']['val']) {
        if (Object.keys(shutterValues).includes(String(data['meta']['ShutterSpeed']['val']))){
          shutterValues[data['meta']['ShutterSpeed']['val']] = shutterValues[data['meta']['ShutterSpeed']['val']] + 1
        }
        if (!Object.keys(shutterValues).includes(String(data['meta']['ShutterSpeed']['val']))){
          shutterValues[data['meta']['ShutterSpeed']['val']] = 1
        }
      }
    })

    // cameraModels
    Object.keys(cameraModelsValue).forEach(element => {
      const cameraObj = (CameraDictionaryObj[element.split('-').join('')]);
      if (cameraObj !== undefined) {
        cameraModelsValue[cameraObj.brand+' - '+cameraObj.model] = cameraModelsValue[element]
        delete cameraModelsValue[element]
      }
    });

    // imageSize
    const imageSizeKeys = []
    Object.keys(imageSizeValues).forEach(element => {
      imageSizeKeys.push({string: element, value: Number(element.split('x')[0])});
    });
    imageSizeKeys.sort((a, b ) => a.value - b.value)

    // pitch
    const pitchKeys = []
    Object.keys(pitchValues).forEach(element => {
      pitchKeys.push({string: element, value: Number(element)});
    });
    pitchKeys.sort((a, b ) => a.value - b.value)

    // relativeAltitude
    const relativeAltitudeKeys = []
    Object.keys(relativeAltitudeValues).forEach(element => {
      relativeAltitudeKeys.push({string: element, value: element});
    });
    relativeAltitudeKeys.sort((a, b ) => a.value - b.value)

    // shutter
    const shutterKeys = []
    Object.keys(shutterValues).forEach(element => {
      shutterKeys.push({string: element, value: 1 / Number(element.split('/')[1])});
    });
    shutterKeys.sort((a, b ) => a.value - b.value)

    this.chartData['aperture'] = apertureValues
    this.chartData['cameraModels'] = cameraModelsValue
    this.chartData['flightSpeed'] = flightSpeedValues
    this.chartData['imageSize'] = imageSizeValues
    this.chartData['imageSizeKeys'] = imageSizeKeys
    this.chartData['iso'] = isoValues
    this.chartData['pitch'] = pitchValues
    this.chartData['pitchKeys'] = pitchKeys
    this.chartData['relativeAltitude'] = relativeAltitudeValues
    this.chartData['relativeAltitudeKeys'] = relativeAltitudeKeys
    this.chartData['RtkFlag'] = RtkFlagValues
    this.chartData['shutter'] = shutterValues
    this.chartData['shutterKeys'] = shutterKeys
  }

  getRelAlt(element): string {
    return Number(this.filesService.getImageHeightRel(element)).toFixed(1);
  }
}
