import { Directive } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ComponentCanDeactivateDirective } from './component-can-deactivate.directive';

@Directive()
export abstract class FormCanDeactivateDirective extends ComponentCanDeactivateDirective {

 abstract get form(): NgForm;

 canDeactivate(): boolean {
    return !this.form || this.form.submitted || !this.form.dirty;
  }
}
