import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FilterFileData, FilesService } from './../../../pages/files/files.service';
import { InspectionService } from './../../../pages/files/inspection/inspection.service';

@Component({
  selector: 'app-inspection-height-slider',
  templateUrl: './height-slider.component.html',
  styleUrls: ['./height-slider.component.scss']
})
export class HeightSliderComponent implements OnDestroy {

  filterFileData: FilterFileData;
  maxValue: number = 0;
  minValue: number = 0;
  options: Options = {
    floor: 0,
    ceil: 0,
    translate: (value: number): string => {
      return value + 'm';
    }
  };
  showSlider = false;
  usedStateDimension = [];

  private ngDestroy$ = new Subject();

  constructor(
    private filesService: FilesService,
    private inspectionService: InspectionService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.filterFileData = {
        ...(params.get('annotationType') && { annotationType: params.get('annotationType').split(',') }),
        ...(params.get('feature') && { feature: params.get('feature').split(',') }),
        ...(params.get('maxHeight') && { maxHeight: Number(params.get('maxHeight')) }),
        ...(params.get('minHeight') && { minHeight: Number(params.get('minHeight')) }),
        ...(params.get('imageType') && { imageType: params.get('imageType').split(',') }),
      };
    });

    this.inspectionService.dataSourceBeforeFilter$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (Object.keys(data).length !== 0) {
        const heights = []
        data.forEach((fileModel) => {
          if(fileModel.relativeAltitude) {
            heights.push(fileModel.relativeAltitude);
          }
        });
        if (heights.length > 1 &&
            !(this.options.floor === Math.floor(Math.min(...heights)) &&
            this.options.ceil === Math.ceil(Math.max(...heights)))) {
          const newOptions: Options = Object.assign({}, this.options);
          newOptions.floor = Math.floor(Math.min(...heights));
          newOptions.ceil = Math.ceil(Math.max(...heights));


          this.options = newOptions;
          // Set the values to param or floor/ceil
          if (this.filterFileData && this.filterFileData.maxHeight) {
            this.maxValue = this.filterFileData.maxHeight
          } else {
            this.maxValue = newOptions.ceil;
          }

          if (this.filterFileData && this.filterFileData.minHeight) {
            this.minValue = this.filterFileData.minHeight
          } else {
            this.minValue = newOptions.floor;
          }
          if (newOptions.ceil - newOptions.floor > 2) {
            this.showSlider = true
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  filterSet(): void {
    const tmpQueryParams = {}

    if (this.filterFileData['feature']) {
      tmpQueryParams['feature'] = this.filterFileData['feature'].join(',')
    }
    if (this.filterFileData['annotationType']) {
      tmpQueryParams['annotationType'] = this.filterFileData['annotationType'].join(',')
    }

    if (this.minValue !== this.options.floor) {
      tmpQueryParams['minHeight'] = this.minValue
    }
    if (this.maxValue !== this.options.ceil) {
      tmpQueryParams['maxHeight'] = this.maxValue
    }
    if (this.filterFileData['imageType']) {
      tmpQueryParams['imageType'] = this.filterFileData['imageType'].join(',');
    }

    setTimeout(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: tmpQueryParams
      });
    }, 1);
  }
}
