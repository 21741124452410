<form *ngIf="!successMessage" class="form">

  <mat-form-field>
    <mat-label>{{ 'FORGOT_PASSWORD_MESSAGE' | translate }}</mat-label>
    <input
        autocomplete="email"
        [disabled]="fetching"
        matInput
        name="email"
        [(ngModel)]="email"
        type="email">
  </mat-form-field>

  <button
      (click)="requestReset()"
      color="accent"
      [disabled]="!email || fetching"
      mat-raised-button>{{ 'SUBMIT' | translate }}</button>

  <button
      [disabled]="fetching"
      mat-stroked-button
      routerLink="/login"
      type="button">{{ 'LOGIN' | translate }}</button>

</form>

<div *ngIf="successMessage" class="success-message">
  <mat-icon>mail_outline</mat-icon>
  <h5>{{ 'PASSWORD_RESET_SUCCESS_MESSAGE' | translate }}</h5>

  <button
      (click)="resetForm()"
      color="accent"
      mat-button
      type="button">{{ 'RESEND_PASSWORD_RECOVERY_LINK' | translate }}</button>
</div>
