import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FilesSelectDialogComponent } from './../../../shared/files-select-dialog/files-select-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

import { FileModel } from './../../files/file/file.model';
import { Login } from './../../login/login.model';
import { LoginStateService } from './../../login/login-state.service';
import { ReferenceValues } from './../viewer.model';
import { ThemeService } from './../../../shared/theme/theme.service';
import { UserRole, AccountType } from '../../users/models/user.model';
import { Viewer2dService, WaterStyle } from './../viewer2d/viewer2d.service';
import { Viewer3dService } from './../viewer3d/viewer3d.service';
import { ViewerService } from './../viewer.service';

@Component({
  selector: 'app-viewer-sidebar',
  templateUrl: './viewer-sidebar.component.html',
  styleUrls: ['./viewer-sidebar.component.scss']
})

export class ViewerSidebarComponent {
  @ViewChild("matTabGroup") matTabGroup: MatTabGroup;
  color = '#ff0000';
  demFileModel: FileModel;
  edit: string;
  expandAdmin = false;
  formSettings: FormGroup;
  login: Login;
  ngDestroy$ = new Subject();
  referenceValues: ReferenceValues;
  tabIndex: number;
  theme: string;
  userRole = UserRole;
  accountType = AccountType;
  view: string;
  viewerFileModel: FileModel;
  waterHeight: number;
  yearActiveButton: string = undefined;
  stepActiveButton: string = '0.01';
  stepSize: number = 0.01;
  spotsDisplayed: boolean = false
  waterStyle: WaterStyle = {
    name: "water",
    steps: [
      {
        color: "#66F84E",
        edit: false
      },
      {
        value: 0.26,
        color: "#FFDB1E",
        edit: false
      },
      {
        value: 0.50,
        color: "#FF2323",
        edit: false
      },
    ]
  }

  get dem(): AbstractControl { return this.formSettings.get('dem'); }
  get tiles3d(): AbstractControl { return this.formSettings.get('tiles3d'); }
  get imagesFolder(): AbstractControl { return this.formSettings.get('imagesFolder'); }
  get orthomosaic(): AbstractControl { return this.formSettings.get('orthomosaic'); }
  get referenceHeight2D(): AbstractControl { return this.formSettings.get('referenceHeight2D'); }
  get referenceHeight3D(): AbstractControl { return this.formSettings.get('referenceHeight3D'); }
  get referenceHeightLocalWater(): AbstractControl { return this.formSettings.get('referenceHeightLocalWater'); }
  get tilesURL(): AbstractControl { return this.formSettings.get('tilesURL'); }
  get modelScale3d(): AbstractControl { return this.formSettings.get('modelScale3d'); }
  get modelHeight(): AbstractControl { return this.formSettings.get('modelHeight'); }
  get waterheight(): AbstractControl { return this.formSettings.get('waterheight'); }
  get waterStyleBoolean(): AbstractControl { return this.formSettings.get('waterStyleBoolean'); }
  get waterDepth(): AbstractControl { return this.formSettings.get('waterDepth'); }
  get displaySpotsBoolean(): AbstractControl { return this.formSettings.get('displaySpotsBoolean'); }
  get showWater(): AbstractControl { return this.formSettings.get('showWater'); }
  get waterHeightLocal(): number {
    if(this.waterHeight && this.referenceValues?.heightLocalWater) {
      return this.waterHeight - this.referenceValues.heightLocalWater;
    }
    return null;
  }

  constructor(
    @Inject(FormBuilder) private formBuilder: FormBuilder,
      private dialog: MatDialog,
      private loginStateService: LoginStateService,
      private themeService: ThemeService,
      private viewer2dService: Viewer2dService,
      private viewer3dService: Viewer3dService,
      private viewerService: ViewerService,
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.formSettings = this.formBuilder.group({
      dem: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      tiles3d: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      imagesFolder: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      orthomosaic: new FormControl({ value: '' }, [
        Validators.maxLength(24),
        Validators.minLength(24)
      ]),
      referenceHeight2D: new FormControl({ value: '' }),
      referenceHeight3D: new FormControl({ value: '' }),
      referenceHeightLocalWater: new FormControl({ value: '' }),
      modelHeight: new FormControl({ value: '' }),
      tilesURL: new FormControl({ value: '' }),
      modelScale3d: new FormControl({ value: '' }),
      showWater: new FormControl({ value: '' }),
      waterheight: new FormControl({ value: '' }),
      waterStyleBoolean: new FormControl({ value: '' }),
      displaySpotsBoolean: new FormControl({ value: '' }),
      waterDepth: new FormControl({value: '10'})
    });

    this.loginStateService.login$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((login) => {
      this.login = login;
    });

    this.viewerService.demFileModel$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if(data) {
        this.demFileModel = data;
      }
    });

    this.viewerService.view$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if(data) {
        this.view = data;
      }
    });

    this.viewerService.viewerFileModel$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if(data) {
        this.viewerFileModel = data;
        this.referenceValues = data.viewer?.referenceValues;
        this.setFile();
      }
    });

    this.viewerService.height$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.waterHeight = data;
    });
  }

  colorUpdate(): void {
    this.viewer2dService.style$.next(this.waterStyle);
  }

  setYearActiveButton(buttonName: string) {
    this.yearActiveButton = buttonName;
    const jumpValues = {
      '2030': { '10': 25.79, '100': 26.93, '500': 27.64 },
      '2040': { '10': 25.81, '100': 26.96, '500': 27.68 },
      '2050': { '10': 25.84, '100': 27.04, '500': 27.74 }
    };
    const jumpValue = jumpValues[buttonName]?.[this.waterDepth.value];
    if (jumpValue !== undefined) {
      this.setWaterHeight(jumpValue);
    } else {
      console.error("Invalid year or water depth value");
    }
  }

  setStepActiveButton(buttonName: string) {
    switch (buttonName) {
      case '0.01':
        this.stepSize = 0.01;
        break;
      case '0.10':
        this.stepSize = 0.10;
        break;
      case '1.00':
        this.stepSize = 1.00;
        break;
      case '10.0':
        this.stepSize = 10.00;
        break;
    }
    this.stepActiveButton = buttonName;
  }

  pickDem(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'folderID': this.viewerFileModel._id,
        'acceptTag': ['dem'],
        'acceptMimeType': ['image/tiff', 'application/vnd.folder']
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.dem.setValue(dialog.id);
        this.setDem();
      }
    });
  }

  pickOrthomosaic(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'folderID': this.viewerFileModel._id,
        'acceptTag': ['orthomosaic'],
        'acceptMimeType': ['image/tiff', 'application/vnd.folder']
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.orthomosaic.setValue(dialog.id);
        this.setOrthomosaic();
      }
    });
  }

  pickTiles3d(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'folderID': this.viewerFileModel._id,
        'acceptTag': ['3dtiles'],
        'acceptMimeType': ['application/zip', 'application/vnd.folder']
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.tiles3d.setValue(dialog.id);
        this.setTiles3d();
      }
    });
  }

  pickReferenceHeight2D(): void {
    this.viewerService.action$.next("setReferenceHeight2D");
  }

  pickReferenceHeight3D(): void {
    this.viewerService.action$.next("setReferenceHeight3D");
  }

  pickReferenceHeightLocalWater(): void {
    if(this.view === '2d') {
      this.viewerService.action$.next("setReferenceHeightLocalWater2D");
    } else if (this.view === '3d'){
      this.viewerService.action$.next("setReferenceHeightLocalWater3D");
    }
  }

  resetForm(): void {
    this.formSettings.reset({
      waterheight: this.waterHeight,
      dem: this.viewerFileModel.viewer?.dem,
      tiles3d: this.viewerFileModel.viewer?.tiles3d,
      imagesFolder: this.viewerFileModel.viewer?.imageCones?.imagesFolder,
      orthomosaic: this.viewerFileModel.viewer?.orthomosaic,
      referenceHeight2D: this.viewerFileModel.viewer?.referenceValues?.height2d,
      referenceHeight3D: this.viewerFileModel.viewer?.referenceValues?.height3d,
      referenceHeightLocalWater: this.viewerFileModel.viewer?.referenceValues?.heightLocalWater,
      showWater: this.viewerService.showWater$.value,
      waterStyleBoolean: false,
    });
  }

  save3dView(): void {
    this.viewerService.action$.next("save3dView");
  }

  set3dView(): void {
    this.viewerService.action$.next("set3dView");
  }

  setDem(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.dem = this.dem.value
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('dem', false);
  }

  setTiles3d(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.tiles3d = this.tiles3d.value;
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('dem', false);
  }

  setFile(): void {
    this.resetForm()
  }

  setImagesFolder(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.imageCones.imagesFolder = this.imagesFolder.value
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('dem', false);
  }

  setModelHeightAdd(value): void {
    this.modelHeight.setValue(Number(Number(this.modelHeight.value) + value).toFixed(2))
    this.viewer3dService.tmpHeight$.next(this.modelHeight.value)
  }

  setModelScale3dAdd(value: number) {
    this.modelScale3d.setValue(Number(Number(this.modelScale3d.value) + value).toFixed(2))
    this.viewer3dService.tmpScale$.next(this.modelScale3d.value)
    this.edit = 'modelScale3d';
  }

  setOrthomosaic(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.orthomosaic = this.orthomosaic.value
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('orthomosaic', false);
  }

  setReferenceHeight2D(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.referenceValues.height2d = this.referenceHeight2D.value
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('dem', false);
  }

  setReferenceHeight3D(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.referenceValues.height3d = this.referenceHeight3D.value
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('dem', false);
  }

  setReferenceHeightLocalWater(): void {
    this.viewerFileModel = this.viewerService.makeViewerObj();
    this.viewerFileModel.viewer.referenceValues.heightLocalWater = this.referenceHeightLocalWater.value
    this.viewerService.setUpdate({ viewer: this.viewerService.getViewerObj(this.viewerFileModel) });
    this.setState('dem', false);
  }

  setState(state: string, value: boolean): void {
    if (this.edit !== state || !value) {
      this.resetForm();
    }
    if (value) {
      this.edit = state;
    } else {
      this.edit = '';
    }
  }

  setWaterHeight(value) {
    this.waterheight.setValue(value)
    this.viewerService.height$.next(value);
  }

  setWaterHeightAdd(value) {
    this.waterheight.setValue(this.waterHeight + value)
    this.viewerService.height$.next(this.waterHeight + value);
  }

  toggleAdmin(): void {
    this.expandAdmin = !this.expandAdmin;
  }

  toggleBoolean(toggledStep): void {
    this.waterStyle.steps.forEach(step => {
      if (step === toggledStep) {
        step.edit = !step.edit;
      } else {
        step.edit = false;
      }
    });
  }

  toggleShowWater(): void {
    this.viewerService.showWater$.next(this.showWater.value);
  }

  onWaterDepthChange(selectedValue: string) {
    if (!this.yearActiveButton) {
      this.setYearActiveButton ('2030')
    } else {
      this.setYearActiveButton (this.yearActiveButton)
    }
  }

  toggleWaterStyle(): void {
    if(this.waterStyleBoolean.value) {
      this.waterStyle.name = "waterSteps"
      this.viewer2dService.style$.next(this.waterStyle);
    } else {
      this.waterStyle.name = "water"
      this.viewer2dService.style$.next(this.waterStyle);
    }
  }

  displaySpots(): void {
     if (this.spotsDisplayed) {
      this.viewer2dService.setSpotAction("remove")
      this.spotsDisplayed = false
     } else {
      this.viewer2dService.setSpotAction("add")
      this.spotsDisplayed = true
     }
  }
}
