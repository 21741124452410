import {BehaviorSubject, Observable,Subject} from 'rxjs';
import {takeUntil,skip} from 'rxjs/operators';
import {EventEmitter, Injectable, Renderer2} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Platform} from '@angular/cdk/platform';

import {AnnotationStateDimensionObj} from './../../shared/annotations/annotation-types';
import {ApiService} from './../../shared/interfaces/api-service.interface';
import {DialogService} from './../../shared/dialog/dialog.service';
import {environment} from './../../../environments/environment';
import {FileModel, FilterUpdate, TemplateFolder, Update, ZipRequest} from './file/file.model';
import {prefix} from './../../config';
import {LoginStateService} from "../login/login-state.service";
import {UserRole} from "../users/models/user.model";
import { InspectionService } from './inspection/inspection.service';
import { HighContrastMode } from '@angular/cdk/a11y';

export interface FilterFileData {
  feature?: Array<string>,
  annotationType?: Array<string>,
  minHeight?: number,
  maxHeight?: number,
  imageType?: Array<string>,
}

@Injectable()
export class FilesService implements ApiService<FileModel> {
  masterFileModel: FileModel;
  data$: BehaviorSubject<Array<FileModel>> = new BehaviorSubject([]);
  annotationsTabOpen$: BehaviorSubject<boolean> =new BehaviorSubject(undefined)
  displayedFile$: BehaviorSubject<FileModel> = new BehaviorSubject({});
  hasSubFolder$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  refreshed: EventEmitter<any> = new EventEmitter();
  forceRefreshed: EventEmitter<any> = new EventEmitter();
  totalItems$: BehaviorSubject<number> = new BehaviorSubject(0);
  allData: FileModel[];
  centerAdjustments: number[]=[0,0];
  translationAdjustment: number[]=[0,0]
  update$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  previousFileModel: FileModel;
  public currentFiles$ = new Subject<Partial<FileModel>[]>();
  private ngDestroy$ = new Subject();

  constructor(
      private dialogService: DialogService,
      private http: HttpClient,
      private platform: Platform,
      private inspectionService:InspectionService,
  ) {

    this.inspectionService.dataSourceBeforeFilter$
    .pipe(takeUntil(this.ngDestroy$),skip(1))
    .subscribe(data => {
      this.allData = data;
      }
    )
  }

  forceReload(): void {
    setTimeout(() => {
      this.forceRefreshed.emit("refreshed");
    });
  }

  bentleyGetEquipment(bentleyRequest: any): Observable<any> {
    const url = `${environment.apiHost}bentley/getEquipment`;

    return this.http.post<any>(url, bentleyRequest);
  }

  setFetchingChange (state){
    this.fetchingChange$.next(state)
  }

  openAnnotationTab(status){
    this.annotationsTabOpen$.next(status)
  }
  
  propertiesCalc(fileModel){
    if (fileModel?.thermalSubFileModel){
      this.centerAdjustments[0]=(fileModel?.width-fileModel?.thermalSubFileModel.width*fileModel.thermalSubFileModel.scalingFactor)/2;
      this.centerAdjustments[1]=(fileModel?.height-fileModel?.thermalSubFileModel.height*fileModel.thermalSubFileModel.scalingFactor)/2;
      this.translationAdjustment[0]= - 0.29927*fileModel?.pitch* fileModel.thermalSubFileModel.scalingFactor;
      this.translationAdjustment[1]= - 0.29927*fileModel?.pitch* fileModel.thermalSubFileModel.scalingFactor;

    } else{
      this.centerAdjustments = [0,0];
      this.translationAdjustment = [0,0]
    }
  }

  getMaster(fileID):void{
    this.waitForAllDataAndAssign(fileID);
  }

  async  waitForAllDataAndAssign(fileID) {
    while (!this.allData) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.masterFileModel = this.allData.find(obj => obj._id === fileID);
  }

  getFileNumberFromName(fileModelName):number{
    const reversedFilename = fileModelName?.split("").reverse().join("");
    const digitRegex = /\d/;
    const match = digitRegex.exec(reversedFilename);
    if (match) {
      const digitIndex = match.index;
      const lastDigitIndex = fileModelName.length - digitIndex - 1;
      return Number(fileModelName.slice(lastDigitIndex-3,lastDigitIndex+1));
    }
    return -1; // No digit found in the filename
  }

  isThermal (fileID){
    if (fileID){
      const fileModel: FileModel = this.allData.find(obj => (obj._id==fileID))
      if (fileModel) {
          return (this.getImageType(fileModel)=="Thermal")? true:false;
      }
    }
  }

  updateAnnotationStats(fileModel,newStats?): object{
    if (newStats){
      return newStats
    }
    if (fileModel.annotations){
      let newAnnotationStats:object = {};
      newAnnotationStats['total'] = fileModel.annotations.length
      newAnnotationStats['urgent'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 100).length
      newAnnotationStats['high'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 80).length
      newAnnotationStats['medium'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 60).length
      newAnnotationStats['low'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 40).length
      newAnnotationStats['advisory'] = fileModel.annotations.filter(annotation => annotation.stateDimension === 20).length
      return newAnnotationStats
    }
    return fileModel.annotationStats
    
  }

  getImageType (fileModel: FileModel): string{
    if (fileModel.meta['XPKeywords'] == "single"){
      return("JPG");
    } return ("Thermal");
  }

  /**
   * Check if image can be viewed in the browser
   */
  canViewImage(fileModel: FileModel): boolean {
    // Only if the thumbnailLink is present
    // Tiff files are currently supported only by EDGE and SAFARI
    // https://en.wikipedia.org/wiki/Comparison_of_web_browsers#Image_format_support
    return fileModel && fileModel.thumbnailLink &&
        (fileModel.mimeType === 'image/tiff' ? this.platform.EDGE || this.platform.SAFARI : true);
  }

  convert2JPG(fileID: object): Observable<any> {
    const url = `${environment.apiHost}convert-raws`;

    return this.http.post<any>(url, fileID);

  }

  createZip(zipRequest: ZipRequest): Observable<any> {
    // Big Zip creation can take a long time, so we use the apiHost to avoid timeouts
    const url = `${environment.apiHost}s3/zip`;

    return this.http.put<any>(url, zipRequest);
  }

  deleteMany(filter: object): Observable<any> {
    return this.http.request('delete', `${environment.apiPath}files`, { body: filter, observe: 'response' });
  }

  deleteOne(fileID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}files/${fileID}`, { observe: 'response' });
  }

  hideFiles(fileId: string[], value: boolean):  Observable<any>{
    const url = `${environment.apiPath}files/hidden`;
    return this.http.post<any>(url, {"files":fileId,"value":value});
  }

  download(renderer: Renderer2, fileModel?: FileModel, token?: string): void {
    const selected = (!fileModel && this.data$.value) ? this.data$.value.filter(item => item.selected) : [];
    const isFolder = fileModel ? fileModel.isFolder : selected.some(file => file.isFolder);
    const fileIDs = fileModel ? [String(fileModel._id)] : selected.map(file => String(file._id));

    if (fileIDs.length === 1 && !isFolder) { // Single file
      // Check if the user still has permission to view the file
      // and also get the webContentLink field used for downloads
      this.findOne(fileIDs[0]).subscribe(
      response => {
        // webViewLink (1day) opens known formats (images, videos, pdf) in a new tab, depending of their content-type
        // webContentLink (7days) always downloads the file

        // If content-disposition: attachment is used in a response with the content-type: application/octet-stream,
        // the implied suggestion is that the user agent should not display the response,
        // but directly enter a `save response as...' dialog.
        // https://www.w3.org/Protocols/rfc2616/rfc2616-sec19.html
        const a = renderer.createElement('a');
        if (typeof a.download === 'undefined') {
          // Fallback to download file by briefly opening a new tab
          // This is needed on browsers that haven't yet implemented the download attribute
          this.openFile(null, response.data.webContentLink);
        } else {
          // To avoid briefly opening a new tab we create an anchor with a download attribute
          // then we click it to trigger the download, then we remove it from the DOM
          renderer.setStyle(a, 'display', 'none');
          renderer.setAttribute(a, 'href', response.data.webContentLink);
          renderer.setProperty(a, 'download', response.data.name);
          renderer.appendChild(document.body, a);
          a.click();
        }
        setTimeout(() => {
          renderer.removeChild(document.body, a);  // Remove the form after the download has started
        }, 1);
      },
      error => {
        this.dialogService.showDialog('FILES.GETTING_FAILED', error.status, error.url, error.error);
      });

    } else if (fileIDs.length > 1 || isFolder) {  // Multiple files or folder
      // Download a zip archive of files
      const orderID = selected.length ? selected[0]['orderID'] : null;
      const name = selected.length === 1 ? `${selected[0]['name']}_` : (fileModel ? `${fileModel.name}_` : '');
      const destKey = `${orderID ? `${prefix}${orderID}_` : name}${new Date().toISOString().substr(0, 10)}_archive.zip`;

      // Use an iframe and form in order to trigger the download at the beginning of the stream
      const form = renderer.createElement('form');
      renderer.setStyle(form, 'display', 'none');
      renderer.setAttribute(form, 'action', `${environment.apiHost}s3/zip?jwt=${token}`); // Authenticate with jwt token
      renderer.setAttribute(form, 'method', 'post');
      renderer.setAttribute(form, 'target', 'downloadIframe'); // Link to the hidden iframe, so the new tab doesn't trigger

      // Append input elements with values to submit with the form
      let input = renderer.createElement('input');
      renderer.setAttribute(input, 'name', 'destKey');
      renderer.setAttribute(input, 'value', destKey);
      renderer.appendChild(form, input);

      fileIDs.forEach((fileID: string) => {
        input = renderer.createElement('input');
        renderer.setAttribute(input, 'name', 'fileIDs');
        renderer.setAttribute(input, 'value', fileID);
        renderer.appendChild(form, input);
      });

      // Append the form and trigger the submit
      renderer.appendChild(document.body, form);
      form.submit();

      setTimeout(() => {
        renderer.removeChild(document.body, form);  // Remove the form after the request is sent
      }, 1);
    }
  }

  findMany(
      filter?: object,
      sort?: object,
      cursor?: number,
      pageSize?: number,
      fields?: object,
      postfilter?: object,
  ): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (sort && Object.keys(sort).length) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (cursor) {
      params = params.append('cursor', cursor.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (fields && Object.keys(fields).length) {
      params = params.append('fields', JSON.stringify(fields));
    }
    if (postfilter && Object.keys(postfilter).length) {
      params = params.append('postfilter', JSON.stringify(postfilter));
    }

    // This will be activated only when we have a search without any predefined settings like orderID, etc...
    // It should only work on production since on dev and locally we do mot have searching indexes
    if (environment.name === "prod"){
      if (filter?.['$and']?.[0]?.['name']?.['$regex']) {
        let nameRegex;
        nameRegex = filter['$and'].map(object => object['name']?.['$regex']?.trim()).join(" ");
        nameRegex = nameRegex?.trim();
        if (nameRegex) { 
          const encodedNameRegex = encodeURIComponent(nameRegex);
          return this.http.get<any>(`${environment.apiPath}files/search/*${encodedNameRegex}*`);
        }
      }
    }

    return this.http.get<any>(`${environment.apiPath}files`, { params });
  }

  findManyShared(
      filter?: object,
      sort?: object,
      pageIndex?: number,
      pageSize?: number,
      fields?: object,
      postfilter?: object,
  ): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (sort && Object.keys(sort).length) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (pageIndex) {
      params = params.append('pageIndex', pageIndex.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (fields && Object.keys(fields).length) {
      params = params.append('fields', JSON.stringify(fields));
    }
    if (postfilter && Object.keys(postfilter).length) {
      params = params.append('postfilter', JSON.stringify(postfilter));
    }

    return this.http.get<any>(`${environment.apiPath}shared`, { params });
  }

  findOne(fileID: string, fields?: object): Observable<any> {
    let params = new HttpParams();
    if (fields && Object.keys(fields).length) {
      params = params.append('fields', JSON.stringify(fields));
    }
    return this.http.get<FileModel>(`${environment.apiPath}files/${fileID}`, { params });
  }

  findOneShared(fileID: string): Observable<any> {
    return this.http.get<FileModel>(`${environment.apiPath}shared/${fileID}`);
  }

  filterAnnotations(filterFileData: FilterFileData, dataSourceBeforeFilter: Array<FileModel>): Array<FileModel> {
    if (Object.keys(filterFileData).length !== 0) {
        const filteredAnnotations = this.filterFiles(filterFileData, dataSourceBeforeFilter).map(file => {
          if (file.annotations) {
            const annotations = file.annotations.filter(annotation => {
              let feature = true;
              let type = true;
              if (filterFileData.feature !== undefined) {
                feature = true // filterFileData.feature?.includes(annotation.stateDimension) ToDo Mike
              }
              if (filterFileData.annotationType !== undefined) {
                type = filterFileData.annotationType?.includes(annotation.feature.split("-")[1])
              }
              if (type && feature) {
                return annotation;
              }
            })
            file.annotations = annotations;
          }
          return file;
        })
      return filteredAnnotations

    } else {
      return dataSourceBeforeFilter
    }

  }

  // filtering files 
  filterFiles(filterFileData: FilterFileData, dataSourceBeforeFilter: Array<FileModel>): Array<FileModel> {
    if (Object.keys(filterFileData).length !== 0) {
      let selectedFiles = dataSourceBeforeFilter?.filter(file => {
        let selectedFeature = false;
        let selectedAnnotationType = false;
        let selectedMinAltitude = false;
        let selectedMaxAltitude = false;
        let selectedimageType = false;

        // Show Folders
        if (file.isFolder) {
          return file;
        }

        // Filter feature
        if(filterFileData.feature) {
          if (filterFileData['feature'].includes('none')) {
            // Severity level is none
            if (!file.annotationStats || (file.annotationStats && file.annotationStats.total>0)) {
              selectedFeature = true;
            }
          } else if (file.annotationStats) {
            // Severity level
            filterFileData['feature'].forEach(filterItem => {
               //TODO: confusion between middle and medium 
                if (file.annotationStats.hasOwnProperty(filterItem) && file.annotationStats[filterItem]>0 || ( filterItem ==='middle' && file.annotationStats.hasOwnProperty("medium") && file.annotationStats["medium"]>0)){
                  selectedFeature = true;
                }
            });
          }
        } else {
          selectedFeature = true;
        }

        //Filter by type
        if (filterFileData.imageType) {
          if(file.type){
            filterFileData['imageType'].forEach(filterItem =>{
              if (file.type==filterItem){
                selectedimageType=true;
              }
            })
          }
        }else{
          selectedimageType=true;
        }

        // Filter annotationType
        if(filterFileData.annotationType) {
          if (file.annotationStats.total > 0) {
            filterFileData['annotationType']?.forEach(filterItem => {
              Object.keys(file.typesStats).forEach((type)=>{
                if (type.includes(filterItem)){      
                  if (file.typesStats[type] > 0) {
                    selectedAnnotationType = true;
                  }
                }
              })
            });
          }
        } else {
          selectedAnnotationType = true;
        }

        // Filter minHeight
        if(filterFileData.minHeight) {
          if(file.relativeAltitude &&
            file.relativeAltitude > filterFileData.minHeight)
          {
            selectedMinAltitude = true;
          }
        } else {
          selectedMinAltitude = true;
        }

        // Filter maxHeight
        if(filterFileData.maxHeight) {
          if(file.relativeAltitude &&
            file.relativeAltitude < filterFileData.maxHeight)
          {
            selectedMaxAltitude = true;
          }
        } else {
          selectedMaxAltitude = true;
        }

        if (selectedFeature &&
            selectedAnnotationType &&
            selectedMinAltitude &&
            selectedMaxAltitude &&
            selectedimageType
        ){
          return file;
        }
      })
      return selectedFiles;
    }
    else {
      return dataSourceBeforeFilter
    }
  }

  enrichFileWithFeatures(fileModel: FileModel): FileModel {
    const dataSourceBeforeFilter = this.inspectionService.dataSourceBeforeFilter$.value;
    const matchingFile = dataSourceBeforeFilter.find(file => fileModel._id === file._id);
    if (matchingFile) {
      fileModel['typesStats'] = matchingFile['typesStats'];
    }
    return fileModel;
  }

  getFileIcon(fileModel: FileModel): string {
    if (fileModel && fileModel.mimeType) {
      const mime = fileModel.mimeType;
      if (mime.startsWith('image')) {
        return 'photo';
      } else if (mime.startsWith('audio')) {
        return 'audiotrack';
      } else if (mime.startsWith('video')) {
        return 'videocam';
      } else if (mime.startsWith('text')) {
        return 'description';
      } else if (mime.endsWith('pdf')) {
        return 'picture_as_pdf';
      } else if (mime.endsWith('zip')) {
        return 'archive';
      } else if (mime.endsWith('folder') || fileModel.isFolder) {
        return 'folder';
      }
    }

    return fileModel && fileModel._id && String(fileModel._id).indexOf(',') > -1 ? 'playlist_add_check' : 'insert_drive_file';
  }

  checkImageHeightRel(fileModel: FileModel): boolean {
    return fileModel.meta && (fileModel.meta['RelativeAltitude'] || fileModel.meta['AboveGroundAltitude'])
  }

  checkImagePitch(fileModel: FileModel): boolean {
    return fileModel.meta && (fileModel.meta['GimbalPitchDegree'] || fileModel.meta['CameraPitchDegree'] || fileModel.meta['GPSIMUFlightPitch'])
  }

  checkImageYaw(fileModel: FileModel): boolean {
    return fileModel.meta && (fileModel.meta['GimbalYawDegree'] || fileModel.meta['CameraYawDegree'] || fileModel.meta['GPSIMUFlightYaw'])
  }

  getImageHeightRel(fileModel: FileModel): number {
    if(fileModel.meta['AboveGroundAltitude']) {
      return Number(fileModel.meta['AboveGroundAltitude']);
    }
    return fileModel.meta['RelativeAltitude']
  }

  getImagePitch(fileModel: FileModel): number {
    if (
      fileModel.meta['GimbalYawDegree'] === '+0.00' &&
      fileModel.meta['GimbalPitchDegree'] === '+0.00' &&
      fileModel.meta['GimbalRollDegree'] === '+0.00'
    ) {
      return Number(fileModel.meta['FlightPitchDegree']);
    }
    if(
      fileModel.meta['DroneModel'] && fileModel.meta['DroneModel'] === 'Mini 3 Pro'
    ) {
      return Number(fileModel.meta['FlightPitchDegree']);
    }
    if(fileModel.meta['CameraPitchDegree'] !== undefined) {
      return Number(fileModel.meta['CameraPitchDegree']);
    }
    if(fileModel.meta['GPSIMUFlightPitch'] !== undefined) {
      return Number(fileModel.meta['GPSIMUFlightPitch']);
    }
    return Number(fileModel.meta['GimbalPitchDegree']);
  }

  getImageYaw(fileModel: FileModel): number {
    if (
        fileModel.meta['GimbalYawDegree'] === '+0.00' &&
        fileModel.meta['GimbalPitchDegree'] === '+0.00' &&
        fileModel.meta['GimbalRollDegree'] === '+0.00'
    ) {
      return Number(fileModel.meta['FlightYawDegree']);
    }
    if (
        fileModel.meta['Model'] && fileModel.meta['Model'] === 'L1D-20c' ||
        fileModel.meta['Model'] && fileModel.meta['Model'] === 'L2D-20c' ||
        fileModel.meta['Model'] && fileModel.meta['Model'] === 'FC8482' || // Mini 4 Pro
        fileModel.meta['Model'] && fileModel.meta['Model'] === 'FC4170' || // Mavic 3 zoom lens
        fileModel.meta['Model'] && fileModel.meta['Model'] === 'MAVIC2-ENTERPRISE-ADVANCED' ||
        fileModel.meta['DroneModel'] && fileModel.meta['DroneModel'] === 'Mini 3 Pro'
    ) {
      return Number(fileModel.meta['FlightYawDegree']);
    }
    if(fileModel.meta['CameraYawDegree'] !== undefined) {
      return Number(fileModel.meta['CameraYawDegree']);
    }
    if(fileModel.meta['GPSIMUFlightYaw'] !== undefined) {
      return Number(fileModel.meta['GPSIMUFlightYaw']);
    }
    return Number(fileModel.meta['GimbalYawDegree']);
  }

  insertMany(files: Array<FileModel>): Observable<any> {
    return this.http.post(`${environment.apiPath}files`, files);
  }

  isPublic(fileModel: FileModel): boolean {
    return fileModel.public
  }

  makePublic(fileId: string, value: boolean):  Observable<any> {
    const url = `${environment.apiPath}files/${fileId}/public`;
    return this.http.post<any>(url, {"value":value});
  }

  makeDownloadable(fileId: string, value: boolean):  Observable<any> {
    const url = `${environment.apiPath}files/${fileId}/downloadable`;
    return this.http.post<any>(url, {"value":value});
  }

  openFile(event: Event, url: string): void {
    if (url) {
      if (event) {
        event.preventDefault();
      }
      if (!window.open(url)) { // Fallback if popup blocker is active
        window.location.href = url;
      }
    }
  }

  removeItems(fileIDs: Array<string>): void {
      const data = this.data$.value.filter(item => !fileIDs.includes(String(item._id)));
      this.data$.next(data);
      this.totalItems$.next(this.totalItems$.value - fileIDs.length);
  }

  sendToImogent(imogentRequest: any): Observable<any> {
    const url = `${environment.apiHost}imogent`;

    return this.http.post<any>(url, imogentRequest);
  }

  sendToRaptorMaps(raptorMapsRequest: any): Observable<any> {
    const url = `${environment.apiHost}raptormaps`;

    return this.http.post<any>(url, raptorMapsRequest);
  }

  updateDisplayedFile(fileModel: FileModel) {
    this.displayedFile$.next(fileModel);
  }

  updateItem(fileModel: FileModel): void {
    const index = this.data$.value.findIndex(item => item._id === fileModel._id);
    if (index > -1) {
      this.data$.value[index] = fileModel;
      this.data$.next(this.data$.value);
    }
  }

  getThermalIds():string{
    const thermalIds: string[] = [];

    for (const data of this.allData) {
      if (data.type === 'Thermal') {
        thermalIds.push(data._id.toString());
      }
    }

    return thermalIds.join(',').toString();
  }

  updateMany(fileUpdates: FileModel): Observable<any> {

    const update = new Update();
    update.$currentDate = new FileModel();
    update.$currentDate.modified = true;

    Object.keys(fileUpdates).forEach(key => {
      if (key !== '_id') {
        if (fileUpdates[key] || fileUpdates[key] === false || fileUpdates[key] === 0 ) {
          update.$set = update.$set || new FileModel();
          update.$set[key] = fileUpdates[key];
        } else {
          update.$unset = update.$unset || new FileModel();
          update.$unset[key] = '';
        }
      }
    });

    const filterUpdate: FilterUpdate = {
      filter: {
        _id : {
          $in: String(fileUpdates._id).split(',').map(_id => ({ $oid: _id }))
        }
      },
      update
    };

    return this.http.patch(`${environment.apiPath}files`, filterUpdate, { observe: 'response' });
  }

  updateOne(fileUpdates: FileModel): Observable<any> {
    const update = new Update();
    update.$currentDate = new FileModel();
    update.$currentDate.modified = true;

    Object.keys(fileUpdates).forEach(key => {
      switch (key) {
        case '_id':
          break;
        default:
          const value = fileUpdates[key];
          if ((Array.isArray(value) && value.length) || // Is array with length > 0
              (!Array.isArray(value) && value) ||       // Is not array and truthy
              value === false ||                        // Is boolean and false
              value === 0) {                            // Is 0
            update.$set = update.$set || new FileModel();
            update.$set[key] = value;
          } else {                                      // Empty arrays, falsy values
            update.$unset = update.$unset || new FileModel();
            update.$unset[key] = '';
          }
      }
    });

    if (fileUpdates['name']) {
      // Renaming can take a long time because it must create a file copy with the new name
      // so we use the apiHost to avoid timeouts
      return this.http.patch(`${environment.apiHost}files/${fileUpdates._id}`, update, { observe: 'response' });
    } else {
      return this.http.patch(`${environment.apiPath}files/${fileUpdates._id}`, update, { observe: 'response' });
    }
  }

  async unzipOne(fileId: string): Promise<Object> {
    return this.http.post(`${environment.apiPath}files/${fileId}/unzip`, {}).toPromise();
  }

  upsertItem(fileModel: FileModel): void {
    const index = this.data$.value.findIndex(item => item._id === fileModel._id);
    if (index > -1) {
      this.data$.value[index] = fileModel;
    } else {
      this.data$.value.unshift(fileModel);
      this.totalItems$.next(this.totalItems$.value + 1);
    }
    this.data$.next(this.data$.value);
  }

  watermark(fileIDs: Object, parentFolder: String): Observable<any> {
    const url = `${environment.apiHost}watermark`;

    return this.http.post<any>(url, {
      ids: fileIDs,
      parentFolder: parentFolder
    });
  }

  async putTemplateFolder(tpl: TemplateFolder): Promise<void> {
    await this.http.put<void>(`${environment.apiPath}files-template`, tpl).toPromise();
  }

  async getTemplateFolders(clientId: string): Promise<TemplateFolder[]> {
    const templateFolders = await this.http.get<{ data: TemplateFolder[] }>(`${environment.apiPath}files-template?clientId=${clientId}`).toPromise();
    return templateFolders.data;
  }

  public canUnzip(fileModel: FileModel | undefined): boolean {
    if (!fileModel || !fileModel.name) {
      return false;
    }
    return fileModel.name.endsWith('.zip');
  }

  async unzip(fileModel: FileModel): Promise<void> {
    await this.unzipOne(fileModel._id as string);
    if (!fileModel.tags) {
      fileModel.tags = [];
    }
    fileModel.tags.push('unzipping');
  }

}
