<div class="task-card mat-elevation-z3" [class.dark]="this.theme && this.theme.includes('dark')" [ngClass]="task.urgency" #taskRoot>
  <div class="task-header">
    <span class="task-title">{{task.title}}</span>
  </div>
  <div class="task-body">
    <p>{{task.description}}</p>
  </div>
  <div class="task-footer">
    <!-- <span class="task-type">{{task.linkedResources[0]?.type}}</span> -->
    <span class="task-deadline">{{ "TASKS.DEADLINE" | translate }}: {{task.deadline | daysLeft}}</span>
    <div class="avatar-container">
      <ng-container *ngIf="(task.assignee)?.avatarThumbnailURL; else initials">
        <img
          src="task.assignee.avatarThumbnailURL ? task.assignee.avatarThumbnailURL : 'assets/default-avatar.png'"
          alt="{{task.assignee.name}} {{task.assignee.surname}}"
          class="avatar-img"
        />
      </ng-container>
      <ng-template #initials>
          <div class="avatar-initials">
              {{ task.assignee.name[0] }}{{ task.assignee.surname[0] }}
          </div>
      </ng-template>
  </div>
  </div>
</div>
