<mat-card class="example-card">
  <mat-card-content>
    <div class="stat-label">
      <h3>{{ 'USER.LAST_FLIGHTS' | translate }}</h3>
      <div class="stat-circle">
        <div class="digit">{{inputData?.pieData}}</div>
        <canvas #pie></canvas>
      </div>
    </div>
    <div class="canvas-wrapper">
      <canvas class="filled-screen" #canvas></canvas>
    </div>

  </mat-card-content>
</mat-card>
