
import { AnalyticsTowerComponent } from './pages/files/file/sidebar/analytics-tower/analytics-tower.component';
import { AnnotationsComponent } from './pages/files/file/sidebar/annotations/annotations.component';
import { AnnotationsPanoComponent } from './pages/files/file/sidebar/annotations-pano/annotations-pano.component';
import { AntennaTableComponent } from './shared/antenna-table/antenna-table.component'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './shared/helpers/auth.guard';
import { BentleyDialogComponent } from './shared/bentley-dialog/bentley-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, EventManager, HammerModule, HAMMER_GESTURE_CONFIG, TransferState } from '@angular/platform-browser';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { ColorPickerModule } from 'ngx-color-picker';
import { CustomEventManager } from './shared/helpers/custom-event-manager';
import { CustomHammerConfig } from './shared/helpers/custom-hammer-config';
import { DataCheckComponent } from './shared/data-check/data-check.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { DialogService } from './shared/dialog/dialog.service';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { FaqComponent } from './pages/faq/faq.component';
import { FileComponent } from './pages/files/file/file.component';
import { FilesComponent } from './pages/files/files.component';
import { FilesSelectDialogComponent } from './shared/files-select-dialog/files-select-dialog.component';
import { FilesService } from './pages/files/files.service';
import { FilesTemplateComponent } from './pages/files-template/files-template.component';
import { FilterComponent } from './shared/inspection/filter/filter.component';
import { FilterDialogComponent } from './shared/filter-dialog/filter-dialog.component';
import { FolderDialogComponent } from './shared/folder-dialog/folder-dialog.component';
import { FoldersComponent } from './shared/folders/folders.component';
import { FoldersService } from './shared/folders/folders.service';
import { FooterModule } from './shared/footer/footer.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotPasswordService } from './pages/forgot-password/forgot-password.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { HeaderComponent } from './shared/header/header.component';
import { HeaderService } from './shared/header/header.service';
import { HeightSliderComponent } from './shared/inspection/height-slider/height-slider.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inspection3DComponent } from './pages/files/inspection-3d/inspection-3d.component';
import { InspectionComponent } from './pages/files/inspection/inspection.component';
import { LambdaService } from './shared/helpers/lambda.service';
import { LanguageService } from './shared/i18n/language.service';
import { LoginComponent } from './pages/login/login.component';
import { LoginService } from './pages/login/login.service';
import { LongTextDialogComponent } from './shared/long-text-dialog/long-text-dialog.component';
import { MapsService } from './pages/maps/maps.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from './shared/helpers/material.module';
import { NgModule } from '@angular/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OlImageComponent } from './shared/ol-image/ol-image.component';
import { OLLayerService } from './shared/openlayers/layer.service';
import { OLMapsService } from './shared/openlayers/maps.service';
import { OrderComponent } from './pages/orders/order/order.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { PdfEditorComponent } from './pages/pdf-editor/pdf-editor.component';
import { PdfEditorSidebarComponent } from './pages/pdf-editor/sidebar/pdf-editor-sidebar.component';
import { PilotComponent } from './pages/pilots/pilot/pilot.component'
import { PipesModule } from './shared/pipes/pipes.module';
import { PostsService } from './pages/posts/posts.service';
import { PreviousRouteService } from './shared/helpers/previous-route.service';
import { RecaptchaModule } from 'ng-recaptcha';
import { RegistrationComponent } from './pages/registration/registration.component';
import { RegistrationService } from './pages/registration/registration.service';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordService } from './pages/reset-password/reset-password.service';
import { ResizableModule } from 'angular-resizable-element';
import { SendToImogentDialogComponent } from './shared/send-to-imogent-dialog/send-to-imogent-dialog.component';
import { SendToRaptorMapsDialogComponent } from './shared/send-to-raptor-maps-dialog/send-to-raptor-maps-dialog.component';
import { SettingsComponent } from './pages/files/file/sidebar/settings/settings.component';
import { ShareComponent } from './pages/files/file/sidebar/share/share.component';
import { SharedComponent } from './pages/shared/shared.component';
import { ShareLinkComponent } from './shared/share-link/share-link.component';
import { SidenavContentService } from './shared/sidenav/sidenav-content/sidenav-content.service';
import { SidenavDetailService } from './shared/sidenav/sidenav-detail/sidenav-detail.service';
import { SidenavNavigationComponent } from './shared/sidenav/sidenav-navigation/sidenav-navigation.component';
import { SidenavNavigationService } from './shared/sidenav/sidenav-navigation/sidenav-navigation.service';
import { SpinnerModule } from './shared/spinner/spinner.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { StorageService } from './shared/helpers/storage.service';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { TasklistComponent } from './pages/tasklist/tasklist.component';
import { TemplateRenameDialogComponent } from './pages/files-template/template-rename-dialog/template-rename-dialog.component';
import { ThemeModule } from './shared/theme/theme.module';
import { ThemeService } from './shared/theme/theme.service';
import { Tiled3dComponent } from './pages/files/file/sidebar/tiled3d/tiled3d.component';
import { TokenInterceptor } from './shared/helpers/token-interceptor';
import { TranslateBrowserLoader } from './shared/i18n/translate-browser-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UploadComponent } from './shared/upload/upload.component';
import { UploadService } from './shared/upload/upload.service';
import { UserComponent } from './pages/users/user/user.component';
import { UsersComponent } from './pages/users/users.component';
import { UsersService } from './pages/users/users.service';
import { ViewerComponent } from './pages/viewer/viewer.component';
import { ViewerService } from './pages/viewer/viewer.service';
import { ViewerSidebarComponent } from './pages/viewer/sidebar/viewer-sidebar.component';
import {CustomMatPaginatorIntl} from './shared/i18n/translate-paginator';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { PanoDialogComponent } from './shared/pano-dialog/pano-dialog.component';
import { FlightStatsComponent } from './shared/flight-stats/flight-stats.component';
import { EarningsStatsComponent } from './shared/earnings-stats/earnings-stats.component';
import { UserDocumentsComponent } from './pages/users/user-documents/user-documents.component';
import { AmountPipe } from './shared/pipes/amount.pipe';
import {PortfolioComponent} from "./pages/portfolio/portfolio.component";
import {SiteAdderModalComponent} from "./shared/site-adder-modal/site-adder-modal.component";
import {SiteComponent} from "./pages/portfolio/site/site.component";
import {DoughnutChartComponent} from "./shared/doughnut-chart/doughnut-chart.component";
import {LinkDeviceComponent} from "./shared/link-device/link-device.component";
import {AddPortfolioDialogComponent} from "./shared/add-portfolio-dialog/add-portfolio-dialog.component";
import {AddModifyCollaboratorsDialogComponent} from './shared/add-modify-collaborators-dialog/add-modify-collaborators-dialog.component';
import {MatChipsModule, MatChipInputEvent} from '@angular/material/chips';
import { AnnotationsOverviewDialogComponent } from './shared/annotations-overview-dialog/annotations-overview-dialog.component';
import { CollaborationsComponent } from './pages/files/file/sidebar/collaborations/collaborations.component';
import { GasoidcAuthenticationComponent } from './pages/gasoidc-authentication/gasoidc-authentication.component';
import { MeasurementsComponent } from './pages/files/file/sidebar/measurements/measurements.component';
import { LoginOptionsDialogComponent } from './pages/login/login-options-dialog/login-options-dialog.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { TaskComponent } from './pages/tasks/task/task.component';
import { TaskDialogComponent } from './shared/task-dialog/task-dialog.component';
import { OlMapComparisonComponent } from './pages/ol-map-comparison/ol-map-comparison.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// AoT requires an exported function for factories
export function exportTranslateStaticLoader(): TranslateBrowserLoader {
  return new TranslateBrowserLoader();
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1
};

@NgModule({
  declarations: [
    AnalyticsTowerComponent,
    AnnotationsComponent,
    AnnotationsPanoComponent,
    AntennaTableComponent,
    AppComponent,
    BentleyDialogComponent,
    DataCheckComponent,
    DialogComponent,
    FaqComponent,
    FileComponent,
    FilesComponent,
    FilesSelectDialogComponent,
    FilesTemplateComponent,
    FilesTemplateComponent,
    FilterComponent,
    FilterDialogComponent,
    FolderDialogComponent,
    FoldersComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    HeightSliderComponent,
    HomeComponent,
    Inspection3DComponent,
    InspectionComponent,
    LoginComponent,
    LongTextDialogComponent,
    NotFoundComponent,
    OlImageComponent,
    OrderComponent,
    OrdersComponent,
    PdfEditorComponent,
    PdfEditorSidebarComponent,
    PilotComponent,
    RegistrationComponent,
    ResetPasswordComponent,
    SendToImogentDialogComponent,
    SendToRaptorMapsDialogComponent,
    SettingsComponent,
    ShareComponent,
    SharedComponent,
    ShareLinkComponent,
    SidenavNavigationComponent,
    TasklistComponent,
    TemplateRenameDialogComponent,
    TemplateRenameDialogComponent,
    Tiled3dComponent,
    UploadComponent,
    UserComponent,
    UsersComponent,
    ViewerComponent,
    ViewerSidebarComponent,
    PanoDialogComponent,
    FlightStatsComponent,
    EarningsStatsComponent,
    UserDocumentsComponent,
    PortfolioComponent,
    SiteAdderModalComponent,
    SiteComponent,
    DoughnutChartComponent,
    LinkDeviceComponent,
    AddPortfolioDialogComponent,
    AddModifyCollaboratorsDialogComponent,
    AnnotationsOverviewDialogComponent,
    CollaborationsComponent,
    GasoidcAuthenticationComponent,
    MeasurementsComponent,
    LoginOptionsDialogComponent,
    TasksComponent,
    TaskComponent,
    TaskDialogComponent,
    OlMapComparisonComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'fe'}), // this should always be first
    AppRoutingModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    DigitOnlyModule,
    FooterModule,
    FormsModule,
    HammerModule,
    HttpClientJsonpModule,
    HttpClientModule,
    MaterialModule,
    NgxSliderModule,
    PipesModule,
    ReactiveFormsModule,
    RecaptchaModule,
    ResizableModule,
    SpinnerModule,
    SwiperModule,
    ThemeModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    NgxSkeletonLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [TransferState]
      }
    }),
    GoogleMapsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    },
    {
      provide: EventManager,
      useClass: CustomEventManager
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    AuthGuard,
    CanDeactivateGuard,
    DialogService,
    FilesService,
    FoldersService,
    ForgotPasswordService,
    HeaderService,
    LambdaService,
    LanguageService,
    LoginService,
    MapsService,
    OLLayerService,
    OLMapsService,
    PostsService,
    PreviousRouteService,
    RegistrationService,
    ResetPasswordService,
    SidenavContentService,
    SidenavDetailService,
    SidenavNavigationService,
    StorageService,
    ThemeService,
    TokenInterceptor,
    TransferState,
    UploadService,
    UsersService,
    ViewerService,
    AmountPipe,
  ],
  entryComponents: [
    DialogComponent,
    FilterDialogComponent,
    FolderDialogComponent,
    SendToImogentDialogComponent,
    SendToRaptorMapsDialogComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
