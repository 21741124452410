import { Base } from './Base';
import { RectangularGrips } from './RectangularGrips';
import { ResizeGrip } from './ResizeGrip';
import { SvgHelper } from './SvgHelper';

export class RectangularBase extends Base {
  protected MIN_SIZE = 5;

  private activeGrip: ResizeGrip;
  private readonly CB_DISTANCE = 10;
  private controlBox: SVGGElement;
  private controlGrips: RectangularGrips;
  private controlRect: SVGRectElement;

  static draw = (): RectangularBase => {
    const rectangularBase = new RectangularBase();
    rectangularBase.setup();

    return rectangularBase;
  };

  deselect(): void {
    super.deselect();
    this.controlBox.style.display = 'none';
  }

  endManipulation(): void {
    super.endManipulation();
    this.isResizing = false;
    this.activeGrip = null;
  }

  gripMouseDown = (ev: MouseEvent) => {
    this.isResizing = true;
    this.activeGrip = this.controlGrips.findGripByVisual(ev.target as SVGGraphicsElement);
    this.previousMouseX = ev.screenX;
    this.previousMouseY = ev.screenY;
    this.previousState = this.getState();
    ev.stopPropagation();
  };

  select(): void {
    super.select();
    this.controlBox.style.display = '';
  }

  protected resize(dx: number, dy: number): void {
    const previousWidth = this.previousState ? this.previousState.width : 0;
    const previousHeight = this.previousState ? this.previousState.height : 0;
    const previousX = this.previousState ? this.previousState.translateX : 0;
    const previousY = this.previousState ? this.previousState.translateY : 0;

    let translateX = previousX;
    let translateY = previousY;

    switch (this.activeGrip) {
      case this.controlGrips.nwResize:
        this.width = previousWidth - dx;
        this.height = previousHeight - dy;
        translateX += dx;
        translateY += dy;
        break;
      case this.controlGrips.swResize:
        this.width = previousWidth - dx;
        this.height = previousHeight + dy;
        translateX += dx;
        break;
      case this.controlGrips.neResize:
        this.width = previousWidth + dx;
        this.height = previousHeight - dy;
        translateY += dy;
        break;
      case this.controlGrips.seResize:
        this.width = previousWidth + dx;
        this.height = previousHeight + dy;
        break;
      case this.controlGrips.wResize:
        this.width = previousWidth - dx;
        translateX += dx;
        break;
      case this.controlGrips.eResize:
        this.width = previousWidth + dx;
        break;
      case this.controlGrips.nResize:
        this.height = previousHeight - dy;
        translateY += dy;
        break;
      case this.controlGrips.sResize:
        this.height = previousHeight + dy;
        break;
      default:
    }

    if (this.width < this.MIN_SIZE) {
      this.width = this.MIN_SIZE;
      translateX = translateX > previousX ? previousX + previousWidth - this.width : previousX;
    }
    if (this.height < this.MIN_SIZE) {
      this.height = this.MIN_SIZE;
      translateY = translateY > previousY ? previousY + previousHeight - this.height : previousY;
    }

    if (translateX !== 0 || translateY !== 0) {
      const translate = this.visual.transform.baseVal.getItem(0);
      translate.setTranslate(translateX, translateY);
      this.visual.transform.baseVal.replaceItem(translate, 0);
    }

    this.adjustControlBox();
  }

  protected setup(): void {
    super.setup();

    this.addControlBox();
  }

  private addControlBox = () => {
    this.controlBox = SvgHelper.createGroup([
      ['class', 'drawing-rect-control-box'],
      ['style', 'display: none']
    ]);
    const translate = SvgHelper.createTransform();
    translate.setTranslate(-this.CB_DISTANCE / 2, -this.CB_DISTANCE / 2);
    this.controlBox.transform.baseVal.appendItem(translate);

    this.addToVisual(this.controlBox);

    this.controlRect = SvgHelper.createRect(
        this.width + this.CB_DISTANCE,
        this.height + this.CB_DISTANCE,
        [['class', 'drawing-rect-control-rect']]);

    this.controlBox.appendChild(this.controlRect);

    this.controlGrips = new RectangularGrips();
    this.addControlGrips();
  };

  private addControlGrips = () => {
    this.controlGrips.nwResize = this.createGrip('nw-resize');
    this.controlGrips.nResize = this.createGrip('n-resize');
    this.controlGrips.neResize = this.createGrip('ne-resize');
    this.controlGrips.wResize = this.createGrip('w-resize');
    this.controlGrips.eResize = this.createGrip('e-resize');
    this.controlGrips.swResize = this.createGrip('sw-resize');
    this.controlGrips.sResize = this.createGrip('s-resize');
    this.controlGrips.seResize = this.createGrip('se-resize');

    this.positionGrips();
  };

  private adjustControlBox = () => {
    this.controlRect.setAttribute('width', (this.width + this.CB_DISTANCE).toString());
    this.controlRect.setAttribute('height', (this.height + this.CB_DISTANCE).toString());

    this.positionGrips();
  };

  private createGrip = (className?: string): ResizeGrip => {
    const grip = new ResizeGrip();
    grip.visual.transform.baseVal.appendItem(SvgHelper.createTransform());
    grip.visual.classList.add(className);
    this.controlBox.appendChild(grip.visual);

    /*grip.visual.addEventListener('mousedown', this.gripMouseDown);
    grip.visual.addEventListener('mousemove', this.gripMouseMove);
    grip.visual.addEventListener('mouseup', this.gripMouseUp);

    grip.visual.addEventListener('touchstart', this.onTouch, { passive: false });
    grip.visual.addEventListener('touchend', this.onTouch, { passive: false });
    grip.visual.addEventListener('touchmove', this.onTouch, { passive: false });*/

    return grip;
  };

  private positionGrip = (grip: SVGGraphicsElement, x: number, y: number) => {
    const translate = grip.transform.baseVal.getItem(0);
    translate.setTranslate(x, y);
    grip.transform.baseVal.replaceItem(translate, 0);
  };

  private positionGrips = () => {
    const gripSize = this.controlGrips.nwResize.GRIP_SIZE;

    const left = -gripSize / 2;
    const top = left;
    const cx = (this.width + this.CB_DISTANCE) / 2 - gripSize / 2;
    const cy = (this.height + this.CB_DISTANCE) / 2 - gripSize / 2;
    const bottom = this.height + this.CB_DISTANCE - gripSize / 2;
    const right = this.width + this.CB_DISTANCE - gripSize / 2;

    this.positionGrip(this.controlGrips.nwResize.visual, left, top);
    this.positionGrip(this.controlGrips.nResize.visual, cx, top);
    this.positionGrip(this.controlGrips.neResize.visual, right, top);
    this.positionGrip(this.controlGrips.wResize.visual, left, cy);
    this.positionGrip(this.controlGrips.eResize.visual, right, cy);
    this.positionGrip(this.controlGrips.swResize.visual, left, bottom);
    this.positionGrip(this.controlGrips.sResize.visual, cx, bottom);
    this.positionGrip(this.controlGrips.seResize.visual, right, bottom);
  };
}
