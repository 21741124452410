import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { Portfolio, PortfolioStats, ResponseData, Site, SITE_COLOR_BY_SEVERITY, SiteOrder, SiteStats } from './portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public getPortfolioByUser(userId: string): Observable<ResponseData<Portfolio[]>> {
    return this.http.get<ResponseData<Portfolio[]>>(`${environment.apiPath}portfolios/user/${userId}`)
  }

  public getPortfolioStats(id: string): Observable<ResponseData<PortfolioStats>> {
    return this.http.get<ResponseData<PortfolioStats>>(`${environment.apiPath}portfolios/${id}/statistics`)
  }

  public getPortfolioById(id: string): Observable<ResponseData<Portfolio>> {
    return this.http.get<ResponseData<Portfolio>>(`${environment.apiPath}portfolios/${id}`)
  }

  public getPortfolioSites(id: string): Observable<ResponseData<Site[]>> {
    return this.http.get<ResponseData<Site[]>>(`${environment.apiPath}portfolios/${id}/sites`)
  }

  public createPortfolioSites(orderId: string, siteName: string, siteAddress: string, userId: string, portfolioId: string, type: string): Observable<ResponseData<Site>> {
    let requestBody = {}
    if (orderId) {
      requestBody['linkOrderID'] = orderId
    }

    if (siteName || siteAddress || userId || portfolioId || type) {
      requestBody['site'] = {}
      if (siteName) {
        requestBody['site']['name'] = siteName
      }
      if (siteAddress) {
        requestBody['site']['address'] = siteAddress
      }
      if (userId) {
        requestBody['site']['userId'] = userId
      }
      if (portfolioId) {
        requestBody['site']['portfolioId'] = portfolioId
      }
      if (type) {
        requestBody['site']['type'] = type
      }
    }

    return this.http.post<ResponseData<Site>>(`${environment.apiPath}sites`, requestBody)
  }

  public linkSiteToPortfolio(portfolioId: string, siteIds: string[]): Observable<void> {
    return this.http.post<void>(`${environment.apiPath}portfolios/${portfolioId}/sites`, siteIds)
  }

  public getSiteById(siteId: string): Observable<ResponseData<Site>> {
    return this.http.get<ResponseData<Site>>(`${environment.apiPath}sites/${siteId}`)
  }

  public editSite(siteId: string, site: Site): Observable<ResponseData<Site>> {
    return this.http.patch<ResponseData<Site>>(`${environment.apiPath}sites/${siteId}`, site)
  }

  public getSiteResults(siteId: string): Observable<any> {
    return this.http.get(`${environment.apiPath}sites/${siteId}/results`)
  }

  public getSiteOrders(siteId: string): Observable<ResponseData<SiteOrder[]>> {
    return this.http.get<ResponseData<SiteOrder[]>>(`${environment.apiPath}sites/${siteId}/orders`)
  }

  public addSiteOrders(siteId: string, orderIds: string[]): Observable<ResponseData<SiteOrder[]>> {
    return this.http.post<ResponseData<SiteOrder[]>>(`${environment.apiPath}sites/${siteId}/orders`, orderIds)
  }

  public getSiteStatistics(siteId: string): Observable<ResponseData<SiteStats>> {
    return this.http.get<ResponseData<SiteStats>>(`${environment.apiPath}sites/${siteId}/statistics`)
  }

  public getSiteRecentOrderAnnotations (siteId: string): Observable<ResponseData<SiteStats>> {
    return this.http.get<ResponseData<SiteStats>>(`${environment.apiPath}sites/${siteId}/annotations`)
  }

  public getColorOfScore(score: number): string {
    if (score > 0.5) {
      return SITE_COLOR_BY_SEVERITY[2];
    }
    if (score > 0.25) {
      return SITE_COLOR_BY_SEVERITY[1];
    }
    return SITE_COLOR_BY_SEVERITY[0];
  }

  public getScoreIndicator(score: number): number {
    if (score > 0.5) {
      return 3;
    }
    if (score > 0.25) {
      return 2;
    }
    return 1;
  }

  public getLabelsOfScore(score: number): string {
    if (score > 0.5) {
      return 'PORTFOLIO.CRITICAL_CONDITION';
    }
    if (score > 0.25) {
      return 'PORTFOLIO.IMPORTANT_CONDITION';
    }
    return 'PORTFOLIO.MINOR_CONDITION';
  }

  public createPortfolio(userId: string, name: string): Observable<ResponseData<Portfolio>> {
    return this.http.post<ResponseData<Portfolio>>(`${environment.apiPath}portfolios`, { userId, name })
  }

  public editPortfolio(portfolioId: string, name: string): Observable<ResponseData<Portfolio>> {
    return this.http.patch<ResponseData<Portfolio>>(`${environment.apiPath}portfolios/${portfolioId}`, { name })
  }
}
