import { PdfEditorServiceService } from './../pdf-editor-service.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ThemeService } from './../../../shared/theme/theme.service';

@Component({
  selector: 'app-pdf-editor-sidebar',
  templateUrl: './pdf-editor-sidebar.component.html',
  styleUrls: ['./pdf-editor-sidebar.component.scss']
})

export class PdfEditorSidebarComponent {
  formPDF: FormGroup;
  theme: string;
  private ngDestroy$ = new Subject();

  constructor(
    private pdfService: PdfEditorServiceService,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    private themeService: ThemeService
  ) {
    this.formPDF = this.formBuilder.group({
      metaName : new FormControl({ value: '' }),
      metaDate : new FormControl({ value: '' }),
      metaPosition : new FormControl({ value: '' }),
      metaAltitude : new FormControl({ value: '' }),
      metaHeading : new FormControl({ value: '' })
    })

    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  change(event: any, field: string): void {
    this.pdfService.newEvent(['meta', field, event.checked]);
  }

  generatePDF() {
    this.pdfService.getPDFConfiguration$.next(true)
  }

  savePDF(): void {
    this.pdfService.newEvent(['savePDF']);
  }
}
