import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilesService } from 'src/app/pages/files/files.service';
import { OLLayerService } from '../openlayers/layer.service';
import { OLMapsService } from '../openlayers/maps.service';
import { FileModel } from 'src/app/pages/files/file/file.model';
import { InspectionService } from 'src/app/pages/files/inspection/inspection.service';
import { ThemeService } from '../theme/theme.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LinkedResource, Task } from 'src/app/pages/tasks/task/task.model';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';

export interface AnnotationsOverviewData {
  currentLang: string;
  folderID: string;
  addMoreAnnotationsToTask: boolean;
  alreadySelectedResources: string[];
}

@Component({
  selector: 'app-annotations-overview-dialog',
  templateUrl: './annotations-overview-dialog.component.html',
  styleUrls: ['./annotations-overview-dialog.component.scss']
})

export class AnnotationsOverviewDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  allColumns: string[] = ['image', 'annoID', 'severity', 'type', 'minimumTemperature', 'maximumTemperature',  'averageTemperature', 'deltaTemperature', 'comment', 'user', 'modificationDate'];
  displayedColumns: string[] = [...this.allColumns];
  dataSource = new MatTableDataSource<any>([]); // Initialize with an empty array
  ngDestroy$ = new Subject();
  loadingObj = {};
  loading: boolean = false;
  iframe: HTMLIFrameElement;
  theme: string= this.themeService.changed$.value;
  selection = new SelectionModel<any>(true, []);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AnnotationsOverviewData,
    public dialogRef: MatDialogRef<AnnotationsOverviewDialogComponent>,
    private readonly filesService: FilesService,
    private changeDetectorRef: ChangeDetectorRef,
    private oLLayerService: OLLayerService,
    private oLMapsService: OLMapsService,
    public inspectionService: InspectionService,
    private themeService:ThemeService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const filter = {
      folderID: { $oid: this.data.folderID }
    }
    const newDataSource = [];
    this.filesService.findMany(filter, {}, 0, 100000, null)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe( response => {
      response.data.forEach((file)=>{
      if(file.annotations && file.annotations?.length > 0 ) {
        file.annotations.forEach( (annotation) => {
          annotation['file']= {
            thumbnailLink: file.thumbnailLink,
            height: file.height,
            width: file.width,
            _id: file._id
          }
          annotation.annoID = file.inspection.fileID+'-'+annotation.annoID
          newDataSource.push(annotation);
          // Check if addMoreAnnotationsToTask is true and pre-select annotations
          if (this.data.addMoreAnnotationsToTask && this.data.alreadySelectedResources.includes(annotation._id)) {
            this.selection.select(annotation);
          }
        } )
      }    
      })
      this.dataSource.data = newDataSource; // Assign data to dataSource
      this.setupSortingAccessor();

      setTimeout(() => {
        this.dataSource.data.forEach((annotation) => {
          const mapDiv = document.querySelector("._"+annotation.file._id+annotation._id);
          this.loadMap(mapDiv, annotation.file, [annotation],annotation.file._id.toString());
        })
      }, 500);
    }); 
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }

  addAnnotations() {
    const selectedAnnotations = this.selection.selected;
    if (this.data.addMoreAnnotationsToTask) {
      const annotationsToAdd = selectedAnnotations.map(annotation => ({
        type: 'annotation',
        resource: annotation
      }))
      this.dialogRef.close({
        annotationsToAdd
      });
    }
  }

  createTask() {
    const selectedAnnotations = this.selection.selected;
    const annotationsToAdd = selectedAnnotations.map(annotation => ({
        type: 'annotation',
        resource: annotation
    })) as LinkedResource[];
  
    const task: Task = {
      linkedResources: annotationsToAdd
    };
  
    // Close the current dialog
    this.dialogRef.close();
  
    // Open the new Task dialog
    this.dialog.open(TaskDialogComponent, {
      data: {
        task: task || null,
        action: 'create',
        folderId: this.data.folderID,
        currentLang: this.data.currentLang
      }
    });
  }


  setupSortingAccessor() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'stateDimension':
          return item.stateDimension;
        case 'feature':
          return item.feature;
        case 'minimumTemperature':
          return item.minimumTemperature;
        case 'maximumTemperature':
          return item.maximumTemperature;
        case 'deltaTemperature':
          return item.deltaTemperature;
        case 'averageTemperature':
          return item.averageTemperature;
        case 'modified':
          return new Date(item.modified).getTime();  // Ensure dates are comparable
        case 'user':
          return item.userName.toLowerCase();  // Assuming sorting by user name as a string
        case 'comment':
          return item.comment ? item.comment.toLowerCase() : '';  // Handle possible null values
        default:
          return item[property];
      }
    };
  }

  toggleColumn(column: string): void {
    const index = this.displayedColumns.indexOf(column);
    if (index > -1) {
      this.displayedColumns.splice(index, 1); // Remove the column if it's already displayed
    } else {
      // Insert the column back in the original order
      const originalIndex = this.allColumns.indexOf(column);
      let newIndex = this.displayedColumns.findIndex(col => this.allColumns.indexOf(col) > originalIndex);
      if (newIndex === -1) { // If no columns are found that come after this one, push to the end
        newIndex = this.displayedColumns.length;
      }
      this.displayedColumns.splice(newIndex, 0, column);
    }
    setTimeout(() => {
      this.dataSource.data.forEach((annotation) => {
        const mapDiv = document.querySelector("._"+annotation.file._id+annotation._id);
        this.loadMap(mapDiv, annotation.file, [annotation],annotation.file._id.toString());
      })
    }, 500);
  }

  getHeader(column): string {
    let col = ""
    switch (column) {
      case 'minimumTemperature': col = 'ANNOTATION.MINIMUM'; break;
      case 'maximumTemperature': col = 'ANNOTATION.MAXIMUM'; break;
      case 'deltaTemperature': col = 'ANNOTATION.DELTA'; break;
      case 'averageTemperature': col = 'ANNOTATION.AVERAGE'; break;
      case 'image': col = 'FILE.IMAGE'; break;
      case 'annoID': col = 'PDF-EDITOR.ANNOTATION'; break;
      case 'severity': col = 'ANNOTATION.SEVERITY'; break;
      case 'type': col = 'ANNOTATION.TYPE'; break;
      case 'comment': col = 'SIDEBAR.ANNOTATIONS.COMMENT'; break;
      case 'user': col = 'USER.TITLE'; break;
      case 'modificationDate': col = 'SITE.LIST.LAST_MODIFICATION'; break;
    }
    return col
  }

  refreshOpenLayersMaps() {
    // Code to safely initialize or redraw OpenLayers maps if they are not visible or are reinitialized
  }

  trackByFn(index, item) {
    return item.id;
  }

  loadMap(mapDiv: any, fileModel: FileModel, annotations: any, id: string): void {
      // check if high res img is needed, based on the height and with of the annotation
      let image = fileModel.thumbnailLink
      const map = this.oLMapsService.renderImage({
        extent: [0, 0, fileModel.width, fileModel.height],
        target: mapDiv,
        image: image,
        imagePreview: fileModel.thumbnailLink,
        interactions: false,
        annotations: annotations,
        labeling: false
      });
      map.on('loadstart', () => {
        this.loadingObj[id] = true;
      });
      map.on('loadend', () => {
        this.loadingObj[id] = false;
        this.checkLoadingObj();
      });

      map.getView().fit(this.oLLayerService.getAnnotationExtent(annotations[0]), {padding:[5, 5, 5, 5]});
    
  }

  checkLoadingObj(): void {
    const values = Object.values(this.loadingObj);
    if (values.every(v => v === false)) {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.ngDestroy$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  logSelection() {
    this.selection.selected.forEach(s => console.log(s.name));
  }
}
