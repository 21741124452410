import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-portfolio-dialog',
  templateUrl: './add-portfolio-dialog.component.html',
  styleUrls: ['./add-portfolio-dialog.component.scss']
})
export class AddPortfolioDialogComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private readonly modal: MatDialogRef<AddPortfolioDialogComponent>,
    private readonly fb: FormBuilder
  ) { }

  public close(): void {
    this.modal.close();
  }

  public submit(): void {
    this.modal.close(this.form.value)
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
    })
  }

  public ngOnInit(): void {
    this.buildForm();
  }

}
