import { Pipe, PipeTransform } from '@angular/core';
import { Task } from 'src/app/pages/tasks/task/task.model';

@Pipe({
  name: 'filterTasksByStatus'
})
export class FilterTasksByStatusPipe implements PipeTransform {
  transform(tasks: Task[], status: string): Task[] {
    return tasks.filter(task => task.status === status);
  }
}
