import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';

import { ActiveHeader } from './header.interface';
import { Column } from './../interfaces/column.interface';
import { getPathValue } from './../helpers/data-helpers';
import { OrderGroup } from "../orders/orders.service";
import { FileModel } from 'src/app/pages/files/file/file.model';
import {Breadcrumb} from "./header.component";

@Injectable()
export class HeaderService {

  activeHeader$: BehaviorSubject<ActiveHeader> = new BehaviorSubject(undefined);
  title$: BehaviorSubject<string> = new BehaviorSubject(null);
  toggleSearch: EventEmitter<any> = new EventEmitter();
  breadCrumbs$: Subject<Array<Breadcrumb>> = new Subject();

  constructor(
    private http: HttpClient,
  ) {
    this.activeHeader$.subscribe(component => {
      if (component) {
        component.ngDestroy$.subscribe(() => {
          // reset toolbar
          this.activeHeader$.next(undefined);
        });
      }
    });
  }

  bentleyGetEquipment(): void {
    const bentleyGetEquipmentFunction: () => void = getPathValue(this, 'activeHeader$.value.bentleyGetEquipment');
    if (bentleyGetEquipmentFunction !== undefined) {
      this.activeHeader$.value.bentleyGetEquipment();
    }
  }

  cancel(): void {
    const cancelFunction: () => void = getPathValue(this, 'activeHeader$.value.cancel');
    if (cancelFunction !== undefined) {
      this.activeHeader$.value.cancel();
    }
  }

  convert2JPG(): void {
    const convert2JPGFunction: () => void = getPathValue(this, 'activeHeader$.value.convert2JPG');
    if (convert2JPGFunction !== undefined) {
      this.activeHeader$.value.convert2JPG();
    }
  }

  createZip(): void {
    const archiveFunction: () => void = getPathValue(this, 'activeHeader$.value.createZip');
    if (archiveFunction !== undefined) {
      this.activeHeader$.value.createZip();
    }
  }

  checkData(): void {
    const checkData: () => void = getPathValue(this, 'activeHeader$.value.checkData');
    if (checkData !== undefined) {
      this.activeHeader$.value.checkData();
    }
  }

  matchData(fileID): Observable<any> {
    return this.http.post<any>(`${environment.apiPath}files/${fileID}/match`, false).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  delete(): void {
    const deleteFunction: () => void = getPathValue(this, 'activeHeader$.value.delete');
    if (deleteFunction !== undefined) {
      this.activeHeader$.value.delete();
    }
  }

  hide(value): void {
    const hideFunction: () => void = getPathValue(this, 'activeHeader$.value.hide');
    if (hideFunction !== undefined) {
      this.activeHeader$.value.hide(undefined, undefined, value);
      this.cancel()
    }
  }

  download(): void {
    const downloadFunction: () => void = getPathValue(this, 'activeHeader$.value.download');
    if (downloadFunction !== undefined) {
      this.activeHeader$.value.download();
    }
  }

  share(): void {
    const shareFunction: () => void = getPathValue(this, 'activeHeader$.value.share');
    if (shareFunction !== undefined) {
      this.activeHeader$.value.share();
    }
  }

  get columns(): Array<Column> {
    return getPathValue(this, 'activeHeader$.value.columns');
  }

  get displayedColumns(): Array<string> {
    return getPathValue(this, 'activeHeader$.value.displayedColumns');
  }

  get searchAutocomplete(): Array<object> {
    const searchAutocomplete = getPathValue(this, 'activeHeader$.value.searchAutocomplete');

    return searchAutocomplete !== undefined ? searchAutocomplete : [];
  }

  get showContextualHeader(): boolean {
    const showContextualHeader: boolean = getPathValue(this, 'activeHeader$.value.showContextualHeader');

    return showContextualHeader || this.selectedCount > 0;
  }

  get showDeleteButton(): boolean {
    const deleteFunction: () => void = getPathValue(this, 'activeHeader$.value.delete');

    return deleteFunction !== undefined ? this.selectedOwner : false;
  }

  get showDownloadButton(): boolean {
    const downloadFunction: () => void = getPathValue(this, 'activeHeader$.value.download');

    return downloadFunction !== undefined ? true : false;
  }

  get showShareButton(): boolean {
    const shareFunction: () => void = getPathValue(this, 'activeHeader$.value.share');

    return shareFunction !== undefined ? true : false;
  }

  get showImogentButton(): boolean {
    const sendToImogentFunction: () => void = getPathValue(this, 'activeHeader$.value.sendToImogent');

    return sendToImogentFunction !== undefined ? true : false;
  }

  get showRaptorMapsButton(): boolean {
    const sendToRaptorMapsFunction: () => void = getPathValue(this, 'activeHeader$.value.sendToRaptorMaps');

    return sendToRaptorMapsFunction !== undefined ? true : false;
  }

  get showNewOrderButton(): boolean {
    return getPathValue(this, 'activeHeader$.value.showNewOrderButton');
  }

  get showConvert2JPGButton(): boolean {
    const regenerateThumbnailsFunction: () => void = getPathValue(this, 'activeHeader$.value.convert2JPG');

    return regenerateThumbnailsFunction !== undefined ? true : false;
  }

  get showRegenerateThumbnailsButton(): boolean {
    const regenerateThumbnailsFunction: () => void = getPathValue(this, 'activeHeader$.value.regenerateThumbnails');

    return regenerateThumbnailsFunction !== undefined ? true : false;
  }

  get showToggleInfoButton(): boolean {
    const toggleInfoFunction: () => void = getPathValue(this, 'activeHeader$.value.toggleInfo');

    return toggleInfoFunction !== undefined ? true : false;
  }

  get filterStatus(): OrderGroup | undefined {
    return getPathValue(this, 'activeHeader$.value.filterStatus');
  }

  get showZipButton(): boolean {
    const zipFunction: () => void = getPathValue(this, 'activeHeader$.value.createZip');

    return zipFunction !== undefined ? true : false;
  }

  get selectedCount(): number {
    const selectedCount: number = getPathValue(this, 'activeHeader$.value.selectedCount');

    return selectedCount ? selectedCount : 0;
  }

  get selectedConvertibleCount(): number {
    const selectedCount: number = getPathValue(this, 'activeHeader$.value.selectedConvertibleCount');

    return selectedCount ? selectedCount : 0;
  }

  get selectedRegenerableCount(): number {
    const selectedCount: number = getPathValue(this, 'activeHeader$.value.selectedRegenerableCount');

    return selectedCount ? selectedCount : 0;
  }

  get selectedZIPCount(): number {
    const selectedCount: number = getPathValue(this, 'activeHeader$.value.selectedZIPCount');

    return selectedCount ? selectedCount : 0;
  }

  get selectedOwner(): boolean {
    return getPathValue(this, 'activeHeader$.value.selectedOwner');
  }

  get viewMode(): 'grid' | 'list' | 'map' {
    return getPathValue(this, 'activeHeader$.value.viewMode');
  }

  get mapMode(): 'empty' | 'orders' | 'pilots' {
    return getPathValue(this, 'activeHeader$.value.mapMode');
  }

  regenerateThumbnails(): void {
    const regenerateThumbnailsFunction: () => void = getPathValue(this, 'activeHeader$.value.regenerateThumbnails');
    if (regenerateThumbnailsFunction !== undefined) {
      this.activeHeader$.value.regenerateThumbnails();
    }
  }

  sendToImogent(): void {
    const sendToImogentFunction: () => void = getPathValue(this, 'activeHeader$.value.sendToImogent');
    if (sendToImogentFunction !== undefined) {
      this.activeHeader$.value.sendToImogent();
    }
  }

  sendToRaptorMaps(): void {
    const sendToRaptorMapsFunction: () => void = getPathValue(this, 'activeHeader$.value.sendToRaptorMaps');
    if (sendToRaptorMapsFunction !== undefined) {
      this.activeHeader$.value.sendToRaptorMaps();
    }
  }

  setViewMode(viewMode: 'grid' | 'list'): void {
    const setViewModeFunction: () => void = getPathValue(this, 'activeHeader$.value.setViewMode');
    if (setViewModeFunction !== undefined) {
      this.activeHeader$.value.setViewMode(viewMode);
    }
  }

  setMapMode(viewMode: 'empty' | 'orders' | 'pilots'): void {
    const setViewModeFunction: () => void = getPathValue(this, 'activeHeader$.value.setMapMode');
    if (setViewModeFunction !== undefined) {
      this.activeHeader$.value.setMapMode(viewMode);
    }
  }

  filterOrders(statusGroup: OrderGroup | undefined) {
    const filterOrdersFunction: (statusGroup: OrderGroup | undefined) => void = getPathValue(this, 'activeHeader$.value.filterOrders');
    if (filterOrdersFunction !== undefined) {
      this.activeHeader$.value.filterOrders(statusGroup);
    }
  }

  verifyOrders(months: number | undefined) {
    const verifyOrdersFunction: (months: number | undefined) => void = getPathValue(this, 'activeHeader$.value.verifyOrders');
    if (verifyOrdersFunction !== undefined) {
      this.activeHeader$.value.verifyOrders(months);
    }
  }

  filterPilotTags(tag: string | undefined) {
    const filterPilotTagsFunction: (months: number | undefined) => void = getPathValue(this, 'activeHeader$.value.filterPilotTags');
    if (filterPilotTagsFunction !== undefined) {
      this.activeHeader$.value.filterPilotTags(tag);
    }
  }

  filterPilotTest(test: boolean | undefined) {
    const filterPilotTestFunction: (months: number | undefined) => void = getPathValue(this, 'activeHeader$.value.filterPilotTest');
    if (filterPilotTestFunction !== undefined) {
      this.activeHeader$.value.filterPilotTest(test);
    }
  }

  get verifyStatus(): number | undefined {
    return this.activeHeader$.value.filterVerify;
  }

  get pilotTagFilter(): string | undefined {
    return this.activeHeader$.value.pilotTagFilter;
  }

  get pilotTestFilter(): boolean | undefined {
    return this.activeHeader$.value.pilotTestFilter;
  }

  toggleColumn(column: string): void {
    const setViewModeFunction: () => void = getPathValue(this, 'activeHeader$.value.toggleColumn');
    if (setViewModeFunction !== undefined) {
      this.activeHeader$.value.toggleColumn(column);
    }
  }

  toggleInfo(): void {
    const toggleInfoFunction: () => void = getPathValue(this, 'activeHeader$.value.toggleInfo');
    if (toggleInfoFunction !== undefined) {
      this.activeHeader$.value.toggleInfo();
    }
  }

  watermark(): void {
    const watermarkFunction: () => void = getPathValue(this, 'activeHeader$.value.watermark');
    if (watermarkFunction !== undefined) {
      this.activeHeader$.value.watermark();
    }
  }

}
