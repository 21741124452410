/// <reference types='@types/googlemaps' />
// Error still open here https://github.com/googlemaps/google-maps-services-js/issues/42
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {BehaviorSubject, bindCallback, Observable, of, Subject, Subscription, zip} from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { TranslateService} from '@ngx-translate/core';

import {DialogService} from './../../../shared/dialog/dialog.service';
import {environment} from './../../../../environments/environment';
import {LambdaRequestBody, LambdaService} from './../../../shared/helpers/lambda.service';
import {LazyLoadService} from './../../../shared/helpers/lazy-load.service';
import {LoginStateService} from './../../login/login-state.service';
import {popAnimation} from './../../../shared/animations/pop.animation';
import {UploadService} from './../../../shared/upload/upload.service';
import { CreateUserDocumentRequest, User, USER_LANGUAGE, AccountType, Subscription as SubscriptionType } from '../models/user.model';
import {UsersService} from './../../users/users.service';
import {HeaderService} from '../../../shared/header/header.service';
import {Login} from '../../login/login.model';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {EarningsStats, FlightStats} from '../models/interfaces/stats.interfaces';
import {E_USER_DOCUMENT_TYPES, IDocument, UserLanguage, UserSkill} from '../models/interfaces/user.interfaces';
import {SECTIONS, USER_LANGUAGES,} from '../models/constants/user.constants';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import { isEmptyJSON } from 'src/app/shared/helpers/data-helpers';

Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  Filler,
  PieController,
  ArcElement,
  ChartDataLabels,
  BarController,
  BarElement
);

interface LatestStat {
  months: number[],
  amount: number[]
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: [popAnimation]
})
export class UserComponent implements AfterViewInit, OnDestroy, OnInit {

  public user: User;
  public preProductList: UserSkill[] = [];
  public postProductList: UserSkill[] = [];
  public isPilotSpecialties = false;
  private LAST_MONTHS = 7;

  private monthLabels = {
    0:  'USER.STATS.JANUARY',
    1:  'USER.STATS.FEBRUARY',
    2:  'USER.STATS.MARCH',
    3:  'USER.STATS.APRIL',
    4:  'USER.STATS.MAY',
    5:  'USER.STATS.JUNE',
    6:  'USER.STATS.JULY',
    7:  'USER.STATS.AUGUST',
    8:  'USER.STATS.SEPTEMBER',
    9:  'USER.STATS.OCTOBER',
    10:  'USER.STATS.NOVEMBER',
    11:  'USER.STATS.DECEMBER',
  }

  public flightsData!: FlightStats;

  public earningsData!: EarningsStats;
  public isPasswordShown: boolean = false;
  public fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loggedUser: User;
  private ngDestroy$ = new Subject();
  public accountType = AccountType;
  public subscriptionType = SubscriptionType
  public formGroup: FormGroup;
  public login: Login;
  public fetching: boolean = false;
  public isEditMode: boolean = false;
  public currentFile: string = '';
  public googleMapsURL: string = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries=drawing,places`;
  public placesAutocomplete: google.maps.places.Autocomplete;
  public isMyProfile: boolean = false;
  public active = true;
  private uploadSubscription: Subscription;

  @ViewChild('addressInput') addressAutocomplete: ElementRef<HTMLInputElement>;

  constructor(
      private dialogService: DialogService,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder,
      private lambdaService: LambdaService,
      private lazyLoadService: LazyLoadService,
      private loginStateService: LoginStateService,
      private ngZone: NgZone,
      private route: ActivatedRoute,
      private readonly headerService: HeaderService,
      private translate: TranslateService,
      private uploadService: UploadService,
      private usersService: UsersService,
      private readonly router: Router
  ) {}

  public ngOnInit(): void {

    this.buildForm();

    this.fetchInterception();

    this.listenLoggedUser();

    this.listenUser();

    this.listenLogin();
  }

  private setHeaderTitle(): void {
    this.isMyProfile = this.user?._id === this.loggedUser?._id;

    this.headerService.title$.next(this.isMyProfile ? 'USER.ME' : 'USER.TITLE');
  }

  private listenUser(): void {
    this.route.params
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(params => {
        if (params) {
          const userID = params['userID'];
          if (userID && userID !== 'create') {
            this.getUser(userID);
          } else {
            this.toggleEditMode();
            this.password.addValidators([ Validators.required ])
          }
        }
      });
  }

  private listenLoggedUser(): void {
    this.loginStateService.loggedUser$
      .pipe(
        takeUntil(this.ngDestroy$)
      )
      .subscribe(user => {
        this.loggedUser = user;
        this.setHeaderTitle()
      })
  }

  private listenLogin(): void {
    this.loginStateService.login$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(login => {
        this.login = login;
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [0],
      subscription: [0],
      address:[
        { value: '', disabled: !this.isEditMode },
        Validators.maxLength(500),
        //this.validateAddress.bind(this) // This validation was removed because house number in some countries does not get validated
      ],
      email: [{ value: '', disabled: !this.isEditMode }, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.email
      ]],
      name: [{ value: '', disabled: !this.isEditMode }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
      ]],
      surname: [{ value: '', disabled: !this.isEditMode }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
      ]],
      organization: [{ value: '', disabled: !this.isEditMode }, [
        Validators.maxLength(100)
      ]],
      password: [{ value: '', disabled: !this.isEditMode }, [
        Validators.minLength(5),
        Validators.maxLength(100)
      ]],
      phone: [{ value: '', disabled: !this.isEditMode }, [
        Validators.minLength(1),
       // Validators.maxLength(30) // This validation was removed because in ff2 the user can have multiple numbers 
      ]],
      language: [{ value: '', disabled: !this.isEditMode }],
      preProduct: [[]],
      postProduct: [[]],
      documents: [[]]
    });
  }

  private fetchInterception(): void {
    this.fetchingChange$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(fetching => {
        this.fetching = fetching;
        if (!Object.keys(this.formGroup.controls).length) return;
        Object.keys(this.formGroup.controls).forEach(key => {
          fetching || !this.login || [AccountType.EDITOR, AccountType.PILOT, AccountType.CLIENT].includes(this.login?.accountType) || !this.isEditMode ?
            this.formGroup.controls[key].disable() :
            this.formGroup.controls[key].enable();
        });
      });
  }

  public toggleEditMode():void {
    this.isEditMode = !this.isEditMode;

    Object.keys(this.formGroup.controls).forEach(key => {
      !this.isEditMode ?
        this.formGroup.controls[key].disable() :
        this.formGroup.controls[key].enable();
    });
    
    if ([AccountType.EDITOR, AccountType.PILOT, AccountType.CLIENT].includes(this.login?.accountType)) {
      this.type.disable()
      // this.email.disable();
      //this.organization.disable();
      //this.phone.disable();
      // this.address.disable();
      this.type.disable()
      this.subscription.disable()
    }
  }

  public getNameError(): string {
    if (this.name.errors?.required) {
      return 'VALIDATION.REQUIRED'
    }


    if (this.name.errors?.minlength) {
      return 'VALIDATION.MIN_LENGTH'
    }

    if (this.name.errors?.maxlength) {
      return this.translate.instant('VALIDATION.MAX_LENGTH', { count: this.name.errors?.maxlength?.requiredLength })
    }

    return ''
  }

  public getOrganizationError(): string {
    if (this.organization.errors?.maxlength) {
      return this.translate.instant('VALIDATION.MAX_LENGTH', { count: this.organization.errors?.maxlength?.requiredLength })
    }
  }

  public getEmailError(): string {
    if (this.email.errors?.required) {
      return 'VALIDATION.REQUIRED'
    }

    if (this.email.errors?.email) {
      return 'VALIDATION.INVALID_EMAIL'
    }

    if (this.email.errors?.maxlength) {
      return 'VALIDATION.MAX_LENGTH'
    }

    return ''
  }

  public getPasswordError(): string {
    const minCount = this.password.errors?.minlength?.requiredLength;
    const maxCount = this.password.errors?.maxlength?.requiredLength;

    if (this.password.errors?.minlength) {
      return this.translate.instant('VALIDATION.MIN_LENGTH', { count: minCount });
    }

    if (this.password.errors?.maxlength) {
      return this.translate.instant('VALIDATION.MAX_LENGTH', { count: maxCount });
    }

    return ''
  }

  public getPhoneError(): string {
    const count = this.phone.errors?.maxlength?.requiredLength;

    if (this.phone.errors?.minlength) {
      return this.translate.instant('VALIDATION.MIN_LENGTH', { count });
    }

    if (this.phone.errors?.maxlength) {
      return this.translate.instant('VALIDATION.MAX_LENGTH', { count });
    }

    return ''
  }

  public getAddressError(): string {
    const count = this.address.errors?.maxlength?.requiredLength;

    if (this.address.errors?.maxlength) {
      return  this.translate.instant('VALIDATION.MAX_LENGTH', { count });
    }

    if (this.address.errors?.street_number) {
      return  this.translate.instant('VALIDATION.STREET_NUMBER_REQUIRED');
    }

    if (this.address.errors?.address) {
      return  this.translate.instant('VALIDATION.INVALID_ADDRESS');
    }

    return ''
  }

  public discardChanges(): void {
    this.setUser();
    this.toggleEditMode();
  }

  public get address(): AbstractControl { return this.formGroup.get('address'); }
  public get email(): AbstractControl { return this.formGroup.get('email'); }
  public get name(): AbstractControl { return this.formGroup.get('name'); }
  public get surname(): AbstractControl { return this.formGroup.get('surname'); }
  public get organization(): AbstractControl { return this.formGroup.get('organization'); }
  public get password(): AbstractControl { return this.formGroup.get('password'); }
  public get phone(): AbstractControl { return this.formGroup.get('phone'); }
  public get language(): AbstractControl { return this.formGroup.get('language'); }
  public get postProduct(): AbstractControl { return this.formGroup.get('postProduct'); }
  public get preProduct(): AbstractControl { return this.formGroup.get('preProduct'); }
  public get documents(): AbstractControl { return this.formGroup.get('documents'); }
  public get subscription(): AbstractControl { return this.formGroup.get('subscription'); }
  public get type(): AbstractControl { return this.formGroup.get('type'); }
  public get languages(): UserLanguage[] {
    return USER_LANGUAGES;
  }

  public getLanguageLabel(code: USER_LANGUAGE): string {
    return this.languages.find(other => other.code === code)!.label;
  }

  public getAddressURL(address: string): string {
    return this.usersService.getAddressURL(address);
  }

  public addPostProduct(option: UserSkill): void {
    this.usersService.addSpeciality(this.user._id.toString(), option.id)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(value => {
        this.postProduct.setValue([
          ...this.postProduct.value,
          option
        ])
      })

  }

  public deletePostProduct(option: UserSkill): void {
    this.usersService.removeSpeciality(this.user._id.toString(), option.id)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(_ => {
        this.postProduct.setValue(
          this.postProduct.value.filter(other => other !== option)
        )
      })

  }

  public addPreProduct(option: UserSkill): void {
    this.usersService.addSpeciality(this.user._id.toString(), option.id)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(value => {
        this.preProduct.setValue([
          ...this.preProduct.value,
          option
        ])
      })
  }

  public deletePreProduct(option: UserSkill): void {
    this.usersService.removeSpeciality(this.user._id.toString(), option.id)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(_=> {
    this.preProduct.setValue(
      this.preProduct.value.filter(other => other !== option)
    )})
  }

  public get preProductOptions(): UserSkill[] {
    return this.preProductList.filter(skill => !this.preProduct.value.includes(skill))
  }
  public get postProductOptions(): UserSkill[] {
    return this.postProductList.filter(skill => !this.postProduct.value.includes(skill))
  }

  public getUser(userID: string): void {
    this.fetchingChange$.next(true);
    this.usersService.findOne(userID)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {
        this.user = response.data;
        this.setHeaderTitle();
        this.setUser();
      },
      error => {
        this.fetchingChange$.next(false);
        this.dialogService.showDialog('USER.GETTING_FAILED', error.status, error.url, error.error);
      });
  }

  public handleFileInput(files: FileList): void {
    const fileToUpload = files.item(0);

    this.uploadService.presignPutURL(fileToUpload.name)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(presignResponse => {
          this.uploadService.uploadFile(presignResponse.data.putURL, fileToUpload)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
              (event: HttpEvent<any>) => {
                switch (event.type) {
                  case HttpEventType.Sent:
                  case HttpEventType.ResponseHeader:
                  case HttpEventType.DownloadProgress:
                    break;
                  case HttpEventType.UploadProgress:
                    break;
                  default:
                    const res = event as HttpResponse<any>;
                    const eTag = res.headers.get('ETag'); // Check for eTag
                    if (eTag) {
                      // Generate thumbnail
                      const lambdaRequestBody: LambdaRequestBody = {
                        bucket: environment.storage,
                        keys: [presignResponse.data.key],
                        operations: [
                          // Resize and crop the img to fill the 144x144 area (for table icons on @2x retina)
                          { name: 'iconURL', parameters: ['144', '144'] },
                          // Resize the img to height = 720px preserving the aspect ratio (for grid thumbnails on @2x retina)
                          { name: 'thumbnailURL', parameters: ['0', '720'] }
                        ]
                      };
                      this.lambdaService.runFunction(`s3ImageProcessing${environment.production ? '' : 'Dev'}`, lambdaRequestBody)
                        .pipe(takeUntil(this.ngDestroy$))
                        .subscribe(lambdaResponse => {
                            this.currentFile = lambdaResponse.data.thumbnailLink;
                            this.formGroup.markAsDirty();
                          },
                          error => {
                            this.dialogService.showDialog('USER.SAVING_FAILED', error.status, error.url, error.error);
                          });
                    } else {
                      this.dialogService.showDialog('FILE.SAVING_FAILED', null, 'File ETag not returned', null);
                    }
                }
              },
              error => {
                this.dialogService.showDialog('USER.SAVING_FAILED', error.status, error.url, error.error);
              });
        },
        error => {
          this.dialogService.showDialog('USER.SAVING_FAILED', error.status, error.url, error.error);
        });
  }

  public documentsHandler(newDocuments: IDocument[]): void {
    let adders: Observable<IDocument>[] = [];
    let createdElements: IDocument[] = [];
    this.getChangedDocuments(newDocuments).forEach(document => {
      if (!document.id) {

        adders.push(
          this.addDocument(document)
          .pipe(map(value => {
            createdElements.push({
              id: value.data._id,
              ...document
            })

            return{
              id: value.data._id,
              ...document
            }
          }
          ))
        )
      } else {
        adders.push(
          this.updateDocument(document)
            .pipe(map(value => document))
        )
      }
      })

    const deleteIds: string[] = [];
    this.documents.value.forEach(prev => {
      if (!newDocuments.some(curr => curr.id === prev.id)) {
        deleteIds.push(prev.id)
      }
    })

    let existingElements: IDocument[] = newDocuments.filter(document => !!document.id);

    if (adders.length) {
      zip(...adders)
        .pipe(
          takeUntil(this.ngDestroy$))
        .subscribe(values => {
          this.documents.setValue([
            ...existingElements,
            ...createdElements
          ]);
        })
    } else if (deleteIds.length){
      this.usersService.deleteDocuments(deleteIds)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(_ => {
          this.documents.setValue([
            ...createdElements,
            ...existingElements
          ]);
        })
    }
  }

  private  updateDocument(document: IDocument):  Observable<CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES>> {
    return this.usersService.updateDocument(document.id, document)
  }

  public addDocument(document: IDocument): Observable<any> {
    return this.usersService.createDocument(this.user._id.toString(), document)
  }

  public saveUser(toggleEdit: boolean = true): void {
    const updates = this.getUserUpdates();
    if (Object.keys(updates).length !== 0){
      this.fetchingChange$.next(true);
      const previousUserData = this.user
      this.user = {
        ...this.user,
        ...updates,
      }
      let request: Observable<any>;
  
      if (!this.user._id) {
        request = this.insertUser(this.user)
      } else {
        request = this.updateUser(this.user)
      }
      request
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(_ =>  {
          this.fetchingChange$.next(false);
          if (this.loggedUser._id == this.user._id){
            this.loginStateService.loggedUser$.next(this.user)
          }
          if (toggleEdit) {
            this.toggleEditMode()
          }
        },
          error => {
          console.log(error)
            this.fetchingChange$.next(false);
            this.ngZone.run(() => {
              this.user=previousUserData
              this.discardChanges()
              this.dialogService.showDialog('Error', null, null, error.error);
            });
          });
    } else {
      if (toggleEdit) {
        this.toggleEditMode()
      }
    }
  }

  public updateUser(user: User): Observable<any> {
    return this.usersService.updateOne({
      _id: this.user._id,
      ...user,
    })
  }
  public insertUser(user: User): Observable<any> {
    return this.usersService.insertMany([user])
      .pipe(
        takeUntil(this.ngDestroy$),
        tap(response => {
          this.password.removeValidators([ Validators.required ])
          this.user = user;
          this.user._id = response.body.data[0]._id;

          this.usersService.upsertItem(this.user);
          this.setUser();
        })
      )
  }

  private getUserUpdates(): User {
    const userUpdates: User = {};

    if (this.address.dirty) { Object.assign(userUpdates, { address: this.address.value }); }
    if (this.email.dirty) { Object.assign(userUpdates, { email: this.email.value }); }
    if (this.name.dirty) { Object.assign(userUpdates, { name: this.name.value }); }
    if (this.surname.dirty) { Object.assign(userUpdates, { surname: this.surname.value }); }
    if (this.organization.dirty) { Object.assign(userUpdates, { organization: this.organization.value }); }
    if (this.password.dirty) { Object.assign(userUpdates, { password: this.password.value }); }
    if (this.phone.dirty) { Object.assign(userUpdates, { phone: this.phone.value }); }
    if (this.currentFile) { Object.assign(userUpdates, { avatarThumbnailLink: this.currentFile }); }
    if (this.subscription.dirty) { Object.assign(userUpdates, { subscription: this.subscription.value }); }
    if (this.user.active !== this.active) { Object.assign(userUpdates, { active: this.active }); }
    if (this.type.dirty) { Object.assign(userUpdates, { accountType: this.type.value }); }
    if (this.language.dirty) { Object.assign(userUpdates, { language: this.language.value }); }

    return userUpdates;
  }


  public deactivateUser(): void {
    this.active = false;

    this.saveUser(false);
  }

  public activateUser(): void {
    this.active = true;
    this.saveUser(false);
  }
  public removeUser(): void {
    if (!this.user._id) {
      return;
    }

    this.translate.get([
      'USER.DELETE',
      'ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'
    ]).subscribe(translations => {

      const dialogRef = this.dialogService.showDialog(null, null,
        translations['USER.DELETE'],
        translations['ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'], true) as Observable<boolean>;

      dialogRef.subscribe(confirm => {
        if (confirm) {
          this.fetchingChange$.next(true);

          this.usersService.deleteOne(String(this.user._id))
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
              () => {
                if (this.login._id === this.user._id) {
                  // If it's the logged user, then logout
                  this.router.navigate([{ outlets: { primary: 'login', detail: null }}]);
                } else {
                  // Otherwise remove the user from the list and close the user details
                  this.usersService.removeItem(String(this.user._id));
                  this.router.navigate([{ outlets: { primary: 'users', detail: null }}]);
                }
              },
              error => {
                this.fetchingChange$.next(false);
                this.dialogService.showDialog('USER.DELETE', error.status, error.url, error.error);
              });
        }
      });
    });
  }


  public ngAfterViewInit(): void {
    this.lazyLoadService.loadScript(this.googleMapsURL)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(_ => {
        this.placesAutocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete?.nativeElement, {
          types: ['address']
        });
        this.placesAutocomplete.setFields(['formatted_address']);
        this.placesAutocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult = this.placesAutocomplete.getPlace();

            this.address.setValue((place && place.formatted_address) || this.addressAutocomplete.nativeElement.value,
              { onlySelf: false });
            this.address.markAsDirty();
          });
        });
    });
  }

  public getChangedDocuments(newDocuments: IDocument[]): IDocument[] {
    return newDocuments.filter(document => {
      const prev = this.documents.value.find(other => other.id === document.id)
      if (prev && JSON.stringify(prev) === JSON.stringify(document)) {
        return false;
      }

      return true;
    })
  }

  public ngOnDestroy(): void {
    this.headerService.title$.next(null);
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public setUser(): void {
    this.fetchingChange$.next(false);
    this.active = this.user.active;
    this.formGroup.patchValue({
      language: this.user.language,
      phone: this.user.phone,
      password: this.user.password,
      organization: this.user.organization,
      name: this.user.name,
      surname: this.user.surname,
      subscription: this.user.subscription,
      email: this.user.email,
      address: this.user.address,
      type: this.user.accountType
    });
    if ([AccountType.EDITOR, AccountType.PILOT, AccountType.CLIENT].includes(this.login?.accountType)) {
      this.email.disable();
      this.organization.disable();
      this.phone.disable();
      this.address.disable();
    }

    this.currentFile = this.user.avatarThumbnailLink;

    //specialities, statistics and doc are only for pilot
    if (this.user.accountType === AccountType.PILOT){
      this.listenStatistic();
      this.listenDocuments();
      this.listenSpecialties();
    }
  }

  public get isAdmin(): boolean {
    return [AccountType.CLIENT, AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType);
  }

  private listenStatistic(): void {
    this.usersService.getStatistic(this.user._id.toString())
      .pipe(take(1))
      .subscribe(({ data: { count = 0, earnings = [], flights = [], months = [], years = [] } = {} }) => {

        const statFlights = this.getLatestValues(months, years, flights)

        this.flightsData = {
          pieData: statFlights.amount.reduce((a, b) => a + b, 0),
          monthsFlight: statFlights.amount,
          mothsLabels: statFlights.months.map(month => this.monthLabels[month])
        }

        const statEarnings = this.getLatestValues(months, years, earnings)
        this.earningsData = {
          total: statEarnings.amount.reduce((a, b) => a + b, 0),
          monthsEarnings: statEarnings.amount,
          mothsLabels: statEarnings.months.map(month => this.monthLabels[month])
        }
      })
  }

  private listenDocuments(): void {

    this.usersService.getDocuments(this.user._id.toString())
      .pipe(take(1))
      .subscribe(response => {
        this.documents.setValue(response.data)
      })
  }

  private listenSpecialties(): void {
    this.usersService.getPilotSpecialities(this.user._id.toString())
      .pipe(take(1))
      .subscribe(response => {
        if (!isEmptyJSON(response)) {
          this.isPilotSpecialties = true;
          const preProducts: UserSkill[] = response.data
            .filter(value => value.specialty.section === SECTIONS.PRE_PRODUCT)
            .map(value => ({
              name: value.specialty.name,
              id: value.specialty.id,
              description: value.specialty.slug
            }))
  
          const postProducts: UserSkill[] = response.data
            .filter(value => value.specialty.section === SECTIONS.POST_PRODUCT)
            .map(value => ({
              name: value.specialty.name,
              id: value.specialty.id,
              description: value.specialty.slug
            }))
  
          this.postProduct.setValue(postProducts)
          this.preProduct.setValue(preProducts)
        }
      })

    this.usersService.getSpecialities()
      .pipe(take(1))
      .subscribe(value => {
        this.preProductList = value.data
          .filter(value => value.section === SECTIONS.PRE_PRODUCT)
          .map(value => ({
            id: value.id,
            name: value.name,
            description: value.slug
          }));
        this.postProductList = value.data
          .filter(value => value.section === SECTIONS.POST_PRODUCT)
          .map(value => ({
            id: value.id,
            name: value.name,
            description: value.slug
          }));

      })
  }

  private getLatestValues(months: number[], years: number[], amount: number[]): LatestStat {
    const today = new Date();
    const amounts: number[] = [];
    const outMonths: number[] = [];

    for (let i = 0; i < this.LAST_MONTHS; i++) {
      const startIndex = years.findIndex(year => year === today.getFullYear())
      const endIndex = years.findIndex(year => year === today.getFullYear() + 1)
      if (startIndex === -1) {
        amounts.unshift(0);
        outMonths.unshift(today.getMonth())
        today.setMonth(today.getMonth() - 1);
        continue;
      }

      let searched = false;

      for (let j = startIndex; j < (endIndex !== -1 ? endIndex : months.length); j++) {
        if (months[j] - 1 === today.getMonth()) {
          amounts.unshift(amount[j]);
          searched = true;
          break;
        }
      }

      if (!searched) {
        amounts.unshift(0);
      }

      outMonths.unshift(today.getMonth())
      today.setMonth(today.getMonth() - 1);
    }

    return {
      months: outMonths,
      amount: amounts
    }
  }

  public toggleShowPassword(event: MouseEvent): void {
    if (!this.isEditMode) return;
    event.stopPropagation();
    this.isPasswordShown = !this.isPasswordShown;
  }

  public validateAddress(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      return this.getPlaceFromQuery(['place_id'], control.value)
      .pipe(
        take(1),
        mergeMap((placeResults) => {
        if (placeResults && placeResults.length) {
          return this.getPlaceDetails(['address_components.types'], placeResults[0].place_id)
          .pipe(
            take(1),
            map((placeResult: google.maps.places.PlaceResult) => {

            if (placeResult.address_components &&
                placeResult.address_components.some(addressComponent => addressComponent.types.includes('street_number'))) {
              return null;
            }

            return { street_number: true };
          }));
        }

        return of({ address: true });
      }));
    }

    return of(null);
  }

  private getPlaceDetails(fields: Array<string>, placeId: string): Observable<google.maps.places.PlaceResult> {
    const callback = (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (result) {
          return result;
        }
      }
    };
    const service = new google.maps.places.PlacesService(this.addressAutocomplete.nativeElement);
    const $getDetailsAsObservable = bindCallback(service.getDetails.bind(service), callback);

    return $getDetailsAsObservable({ fields, placeId });

  }

  private  getPlaceFromQuery(fields: Array<string>, query: string): Observable<Array<google.maps.places.PlaceResult>> {
    const callback = (results: Array<google.maps.places.PlaceResult>, status: google.maps.places.PlacesServiceStatus) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (results) {
          return results;
        }
      }
    };

    const service = new google.maps.places.PlacesService(this.addressAutocomplete.nativeElement);
    const $getPlaceFromQueryAsObservable = bindCallback(service.findPlaceFromQuery.bind(service), callback);

    return $getPlaceFromQueryAsObservable({ fields, query });
  }
}
