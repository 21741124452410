import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  public transform(value: number): string {
    if (value > 1000) {
      return (value / 1000).toFixed(2) + ' K'
    }
    return value.toString();
  }

}
