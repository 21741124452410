import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { environment } from '../../../environments/environment';
import { LazyLoadService } from '../../shared/helpers/lazy-load.service';
import { Chart, ChartConfiguration, ChartData, Legend } from 'chart.js';
import { MatTableDataSource } from '@angular/material/table';
import { SiteAdderModalComponent } from '../../shared/site-adder-modal/site-adder-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PortfolioService } from '../../shared/portfolio/portfolio.service';
import { LoginStateService } from '../login/login-state.service';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { AddPortfolioDialogComponent } from '../../shared/add-portfolio-dialog/add-portfolio-dialog.component';
import { AccountType, User} from '../users/models/user.model';
import { HeaderService } from '../../shared/header/header.service';
import { TranslateService } from '@ngx-translate/core';
import { Portfolio, PortfolioStats, ResponseData, Site, SITE_COLOR_BY_SEVERITY, SITE_TYPE_LABELS } from '../../shared/portfolio/portfolio.model';
import MarkerClusterer from "../maps/markerclustererplus";
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { UsersService } from '../users/users.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';

Chart.register(Legend)
interface Coord {
  lat: number;
  lng: number;
}

const markerPath = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit, AfterViewInit, OnDestroy {
  public markerClustererOrders?: MarkerClusterer;
  private loggedUser!: User;
  public portfolio!: Portfolio;
  public stats!: PortfolioStats;
  private sites: Site[] = [];
  public dataSource = new MatTableDataSource(this.sites);
  public lng: number = 0;
  public lat: number = 0;
  public labels = SITE_TYPE_LABELS;
  public displayedColumns: string[] = [
    'name',
    'address',
    'score'
  ];
  public viewId= '';
  private ngDestroy$ = new Subject();
  theme: string= this.themeService.changed$.value;

  public googleMapStyles: google.maps.MapTypeStyle[] =
    [
      {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'poi',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      },
      {
        'featureType': 'transit',
        'stylers': [
          {
            'visibility': 'off'
          }
        ]
      }
    ]


  private chartLabels: string[] = [
    'PORTFOLIO.MINOR',
    'PORTFOLIO.IMPORTANT',
    'PORTFOLIO.CRITICAL'
  ]

  public googleMapsOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    mapTypeControl: false,
    minZoom: 2,
    styles: this.googleMapStyles,
    scaleControl: false,
  };

  public googleMapIsLoaded = false;
  @ViewChild(GoogleMap) googleMap: GoogleMap;
  @ViewChild('pie') pie: ElementRef<HTMLCanvasElement>;
  public googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries=drawing,places`;
  public isListView = true;
  private chart;

  public dataPie: ChartData<'doughnut', number[], string> = {
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: SITE_COLOR_BY_SEVERITY,
      hoverOffset: 4,
      borderWidth: 0,
    }],
  };

  public configPie: ChartConfiguration<'doughnut', number[], string> = {
    type: 'doughnut',
    data: this.dataPie,
    options: {
      cutout: '70%',
      responsive: true,
      plugins: {
        legend: {
          display: true,
          title: {
            display: true,
            padding: 10,
          },
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'rectRounded',
            color: this.theme.includes('dark')?"#e0e0e0":"rgb(110, 110, 110)",
          }
        },
        datalabels: {
          display: false
        }
      }
    },
  };

  constructor(
    private readonly lazyLoadService: LazyLoadService,
    private readonly headerService: HeaderService,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly portfolioService: PortfolioService,
    private loginService: LoginStateService,
    private themeService: ThemeService,
    private router: Router,
    private ordersService: OrdersService,
    private usersService: UsersService,
    private permissionsService: PermissionsService
    ) {
      this.themeService.changed$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(theme => {
        this.theme = theme;
        this.configPie.options.plugins.legend.labels.color= theme.includes('dark')?"#e0e0e0":"gray";
        this.updateChart()
      });

     // this.disableCollaborationButton()
     }

     public drawSites(): void {
      if (!this.googleMapIsLoaded || !this.googleMap) return;
    
      if (this.markerClustererOrders) {
        this.markerClustererOrders.clearMarkers();
      }
    
      const markers: google.maps.Marker[] = [];
      const coords: Coord[] = [];
      for (const site of this.sites) {
        const geocoder = new google.maps.Geocoder();
        if (geocoder) {
          geocoder.geocode({'address': site.address}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              let coord: Coord = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
              coords.push(coord);
              let color = this.getColorOfSite(site._id);
    
              const svgMarker = {
                path: markerPath,
                fillColor: color,
                fillOpacity: 1,
                strokeColor: '#000',
                strokeWeight: 1,
                scale: 1,
              };
              const mapMarker = new google.maps.Marker({
                position: coord,
                title: site.name,
                icon: svgMarker,
              });
    
              mapMarker.addListener('click', () => {
                this.openSite(site);
              });
    
              markers.push(mapMarker);
    
            } else {
              markers.push(null);
            }
    
            if (markers.length === this.sites.length) {
              const bounds = new google.maps.LatLngBounds();
              for (const coord of coords) {
                bounds.extend(coord);
              }
              const coordFunc = bounds.getCenter();
    
              this.lat = coordFunc.lat();
              this.lng = coordFunc.lng();
    
              // Fit the map to the bounds containing all markers
              this.googleMap.fitBounds(bounds);
    
              const notNullMarkers = markers.filter(marker => !!marker);
    
              // Initialize MarkerClusterer
              this.markerClustererOrders = new MarkerClusterer(this.googleMap.googleMap, notNullMarkers, {
                imagePath: '/assets/images/icons/marker_cluster6',
                imageExtension: 'svg',
                gridSize: 5, // Increase or decrease this value to control how close markers need to be to cluster
                maxZoom: 15,  // Set the maximum zoom level where clustering occurs
                minimumClusterSize: 5, // Set the minimum number of markers required to form a cluster
                zoomOnClick: true // Allow zooming in when clicking on a cluster
              });
    
              // Custom image mapper function to determine cluster color based on the most critical site
              this.markerClustererOrders.imageMapper = (clusterMarkers) => {
                let mostCriticalColor = SITE_COLOR_BY_SEVERITY[0]; // Default to the least critical color
    
                for (const marker of clusterMarkers) {
                  const color = ((marker.getIcon() as unknown) as any).fillColor;
                  if (color === SITE_COLOR_BY_SEVERITY[2]) { // Assuming '#D00000' is the color for the most critical sites
                    mostCriticalColor = SITE_COLOR_BY_SEVERITY[2];
                    break; // No need to continue if we found the most critical color
                  } else if (color === SITE_COLOR_BY_SEVERITY[1]) { // Assuming '#F3C41C' is the color for important sites
                    mostCriticalColor = SITE_COLOR_BY_SEVERITY[1];
                  }
                }
    
                // Return the appropriate cluster icon based on the most critical color found
                if (mostCriticalColor === SITE_COLOR_BY_SEVERITY[2]) {
                  return '/assets/images/icons/marker_cluster3';
                } else if (mostCriticalColor === SITE_COLOR_BY_SEVERITY[1]) {
                  return '/assets/images/icons/marker_cluster2';
                } else {
                  return '/assets/images/icons/marker_cluster6';
                }
              };
            }
          });
        }
      }
    }
    
    

  public openSite(site){
    this.router.navigate(['./portfolio/', site._id]);
  }

  public openSiteDialog(): void {
    const modal = this.dialog.open<SiteAdderModalComponent, Site>(SiteAdderModalComponent, {
      width: '55vw',
      maxWidth: '700px',
      minWidth: '350px',
      closeOnNavigation: true
    });

    modal.afterClosed()
      .pipe(
        filter(value => !!value),
        take(1),
        switchMap(value => this.addSite(value))
      )
      .subscribe((value) => {
        if (!value) return;
        this.sites = [
          ...this.sites,
          value
        ];
        this.dataSource.data = this.sites;
        this.drawSites();
      })
  }

  public correctAvg(stats){
    return ((stats.sitesStats.minor*1)+(stats.sitesStats.important*2)+(stats.sitesStats.critical*3))/stats.sitesStats.total
  }

  public addSite(site: Site): Observable<Site> {
    if (this.loggedUser.accountType === AccountType.CLIENT){
      site['userId']= this.loggedUser._id.toString()
    }
    let created: Site;
    return this.portfolioService.createPortfolioSites(undefined, site.name, site.address, site.userId, site.portfolioId, 'building')
      .pipe(switchMap(value => {
        created = value.data
        var linkedSites = []
        if (this.sites){
          this.sites.forEach((site)=>{
            linkedSites.push(site._id)
          })
        }
        linkedSites.push(value.data._id)
        this.stats.sitesStats.total+=1
        this.stats.sitesStats.minor+=1
        this.stats.averageScore = this.correctAvg(this.stats)
        this.updateChart()
        return this.portfolioService.linkSiteToPortfolio(this.portfolio._id.toString(), linkedSites)
      }), map(() => created))
  }

  public ngAfterViewInit(): void {
    this.updateChart();
  }
  public toggleListView(value: boolean): void {
    this.isListView = value;
  }

    private getPortfolio(): Observable<Portfolio> {
      return this.loginService.loggedUser$
        .pipe(switchMap(user => {
          if (!user) return EMPTY;
          this.loggedUser = user;
          return this.portfolioService.getPortfolioByUser(user._id.toString())
        }),
          switchMap(portfolio => {
            if (!portfolio?.data[0]) return this.createPortfolio('Portfolio')
              .pipe(map(response => response.data))
            return of(portfolio.data[0])
          }),
          switchMap(portfolio => {
            if (!portfolio) return EMPTY;
            this.portfolio = portfolio;
            const id = portfolio._id.toString();
            return combineLatest(this.portfolioService.getPortfolioStats(id),
              this.portfolioService.getPortfolioSites(id));
          }), map(stat => {
            this.stats = stat[0]?.data;
            this.stats.averageScore = this.correctAvg(this.stats)
            this.sites = stat[1].data;
            this.drawSites();
            this.dataSource.data = this.sites;
            this.updateChart();
            return this.portfolio;
        }))
    }

    public getColorOfSite(siteId: string): string {
      const score = this.stats.sitesStats.scores[siteId];

      return this.portfolioService.getColorOfScore(score)
    }

  public getLabelOfSite(siteId: string): string {
    const score = this.stats.sitesStats.scores[siteId];

    return this.portfolioService.getLabelsOfScore(score)
  }

  public switchView(): void {
    if (this.viewId === google.maps.MapTypeId.ROADMAP) {
      this.viewId = google.maps.MapTypeId.SATELLITE
    } else {
      this.viewId = google.maps.MapTypeId.ROADMAP
    }
  }

  public indicator(siteId: string): number {
    const score = this.stats.sitesStats.scores[siteId];
    return this.portfolioService.getScoreIndicator(score);
  }

    private updateChart(): void {
      if (!this.pie) return;
      this.configPie.data.labels = this.chartLabels.map(value => this.translateService.instant(value))
      this.configPie.data.datasets[0].data = [
        this.stats.sitesStats.minor,
        this.stats.sitesStats.important,
        this.stats.sitesStats.critical
      ];
      if (!this.chart) {
        this.chart = new Chart(this.pie.nativeElement, this.configPie);
      } else {
        this.chart.update()
      }
    }

    private listenPortfolio(): void {
      this.getPortfolio()
        .subscribe(portfolio => {
          this.headerService.breadCrumbs$.next([{label: portfolio?.name || 'PORTFOLIO.TITLE', link: ['portfolio']}])
        })
  }

  private createPortfolio(name: string): Observable<ResponseData<Portfolio>> {
    return this.portfolioService.createPortfolio(this.loggedUser._id.toString(), name)
  }

  public openPortfolioDialog(): void {
    const modal = this.dialog.open<AddPortfolioDialogComponent, Portfolio>(AddPortfolioDialogComponent, {
      width: '55vw',
      maxWidth: '700px',
      minWidth: '350px',
      closeOnNavigation: true
    });

    modal.afterClosed()
      .pipe(
        take(1),
        filter(value => !!value),
        switchMap(value =>  this.createPortfolio(value.name)),
        switchMap(() => this.getPortfolio())
      ).subscribe(() => {
        this.headerService.breadCrumbs$.next([{label: this.portfolio?.name || 'PORTFOLIO.TITLE', link: ['portfolio']}])
      })
  }

  public ngOnInit(): void {
    this.listenPortfolio();
    this.translateService.onLangChange
      .subscribe(()=> this.updateChart())

    this.lazyLoadService.loadScript(this.googleMapsURL).subscribe(_ => {
      this.googleMapIsLoaded = true;
      this.viewId = google.maps.MapTypeId.ROADMAP
      setTimeout(()=> this.drawSites());
    });
  }

  public ngOnDestroy(): void {
    this.headerService.breadCrumbs$.next([]);
  }

}
