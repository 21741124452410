import { AfterViewInit, Component, ViewChild, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";

import { HeaderService } from "./../../shared/header/header.service";
import { SseServiceService } from "./../../shared/sse/sse-service.service";

import { SseData } from "../../shared/sse/sse.model";

@Component({
  selector: "app-tasklist",
  templateUrl: "./tasklist.component.html",
  styleUrls: ["./tasklist.component.scss"],
})
export class TasklistComponent implements AfterViewInit, OnDestroy {
  data: SseData;
  displayedColumnsError: string[] = ["name", "target", "timestamp", "errors"];
  displayedColumns: string[] = ["name", "target", "timestamp", "step", "progress"];
  dataSourcePending = new MatTableDataSource<Object>([]);
  dataSourceRunning = new MatTableDataSource<Object>([]);
  dataSourceSuccess = new MatTableDataSource<Object>([]);
  dataSourceFailed = new MatTableDataSource<Object>([]);

  @ViewChild("paginatorPending") paginatorPending: MatPaginator;
  @ViewChild("paginatorRunning") paginatorRunning: MatPaginator;
  @ViewChild("paginatorSuccess") paginatorSuccess: MatPaginator;
  @ViewChild("paginatorFailed") paginatorFailed: MatPaginator;

  ngDestroy$ = new Subject();

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private sseServiceService: SseServiceService
  ) {
    this.headerService.activeHeader$.next(this);
    this.headerService.toggleSearch.emit(false); // Close search

    this.sseServiceService.data$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((data) => {
        if (data) {
          this.dataSourcePending = new MatTableDataSource<Object>(data.pending);
          this.dataSourcePending.paginator = this.paginatorPending;

          this.dataSourceRunning = new MatTableDataSource<Object>(data.running);
          this.dataSourceRunning.paginator = this.paginatorRunning;

          this.dataSourceSuccess = new MatTableDataSource<Object>(data.success);
          this.dataSourceSuccess.paginator = this.paginatorSuccess;

          this.dataSourceFailed = new MatTableDataSource<Object>(data.failed);
          this.dataSourceFailed.paginator = this.paginatorFailed;
        }
      });
  }

  goToZip(targetID: any): void {
    this.router.navigate([{ outlets: { detail: ["file", targetID] } }], {
      queryParamsHandling: "merge",
    });
  }

  // call viewInit again to load paginator
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.paginatorSuccess) {
        this.dataSourcePending.paginator = this.paginatorPending;
        this.dataSourceRunning.paginator = this.paginatorRunning;
        this.dataSourceSuccess.paginator = this.paginatorSuccess;
        this.dataSourceFailed.paginator = this.paginatorFailed;
      } else {
        this.ngAfterViewInit();
      }
    }, 50);
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }
}
