import { ThemeService } from './../../../../../shared/theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Inspection3dService } from '../../../inspection-3d/inspection-3d.service';
import { AntennaTableComponent } from '../../../../../shared/antenna-table/antenna-table.component';
import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar-analytics-tower',
  templateUrl: './analytics-tower.component.html',
  styleUrls: ['./analytics-tower.component.scss']
})
export class AnalyticsTowerComponent {
  dataMounts = [];
  dataAntenna = [];
  dataDish = [];
  dataRRU = [];
  dataStructure = [
    {name: "GroundLevel", height: 0.00, color: "#808080", visible: false},
    {name: "TowerBase", height: 0.31, color: "#ff0000", visible: false},
    {name: "TopOfSteel", height: 29.45, color: "#0000ff", visible: false},
    {name: "HighestEquipment", height: 30.46, color: "#008000", visible: false},
    {name: "LightningRod", height: 31.36, color: "#4f928e", visible: false},
  ]
  theme: string;

 private ngDestroy$ = new Subject();

 get allMountsVisible(): boolean {
  return this.dataMounts.every(({ visible }) => visible);
 }
 get allAntennaVisible(): boolean {
  return this.dataAntenna.every(({ visible }) => visible);
 }
 get allDishVisible(): boolean {
  return this.dataDish.every(({ visible }) => visible);
 }
 get allRRUVisible(): boolean {
  return this.dataRRU.every(({ visible }) => visible);
 }
 get allStructureVisible(): boolean {
  return this.dataStructure.every(({ visible }) => visible);
 }

  constructor(
    private dialog: MatDialog,
    private themeService: ThemeService,
    private inspection3dService: Inspection3dService
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });


    this.inspection3dService.dataMounts$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      data.forEach(element => {
        element.visible = true;
      });
      this.dataMounts = data
    });

    this.inspection3dService.dataAntenna$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      data.forEach(element => {
        element.visible = true;
      });
      this.dataAntenna = data
      
    });
    
    this.inspection3dService.dataMicroWave$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      data.forEach(element => {
      element.visible = true;
    });
      this.dataDish = data
    });
    
    this.inspection3dService.dataRRU$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      data.forEach(element => {
        element.visible = true;
      });
      this.dataRRU = data;
    });
  }

  select(antennaName: string): void {
    if(antennaName.length > 0) {
      setTimeout(() => {
        this.inspection3dService.selectedAntenna$.next(antennaName);
      }, 1);
    } else {
      this.inspection3dService.selectedAntenna$.next(antennaName);
    }
  }

  updateVisibility(): void {
    const visibleAntenna = {
      antenna: [],
      dish: [],
      mounts: [],
      RRU: [],
      structure: []
    }
    this.dataMounts.forEach((mount) => {
      if (mount.visible) {
        visibleAntenna.mounts.push(mount.name);
      }
    })
    this.dataAntenna.forEach((antenna) => {
      if (antenna.visible) {
        visibleAntenna.antenna.push(antenna.name);
      }
    })
    this.dataDish.forEach((antenna) => {
      if (antenna.visible) {
        visibleAntenna.dish.push(antenna.name);
      }
    })
    this.dataRRU.forEach((antenna) => {
      if (antenna.visible) {
        visibleAntenna.RRU.push(antenna.name);
      }
    })
    this.dataStructure.forEach((strucutre) => {
      if (strucutre.visible) {
        visibleAntenna.structure.push(strucutre.name);
      }
    })
    this.inspection3dService.visibleAntenna$.next(visibleAntenna);
  }

  show(event): void {
    event.stopPropagation();
  }

  toggleAll(event, group, status): void {
    event.stopPropagation();
    group.forEach(element => {  
      element.visible = !status;
    });
    this.updateVisibility();
  }

  toggleItem(event, item): void {
    event.stopPropagation();
    item.visible = !item.visible;
    this.updateVisibility();
  }

  showTable(): void {
    const dialogRef = this.dialog.open(AntennaTableComponent, {
      width: '90%'
    });
  }
  
  calcLength(inputValue: number): number {
    return inputValue/39.37;
  }

  convertDirection(direction): string {
    const x = direction;
    switch (true) {
      case (x > 0 && x <= 22.5):
        return 'N';
      case (x > 22.5 && x <= 67.5):
        return 'NE';
      case (x > 67.5 && x <= 112.5):
        return 'E';
      case (x > 112.5 && x <= 157.5):
        return 'SE';
      case (x > 157.5 && x <= 202.5):
        return 'S';
      case (x > 202.5 && x <= 247.5):
        return 'SW';
      case (x > 247.5 && x <= 292.5):
        return 'W';
      case (x > 292.5 && x <= 337.5):
        return 'NW';
      case (x > 337.5 && x <= 360):
        return 'N';
      default:
        return '';
    }
  }

}
