import { Router, ActivatedRoute } from '@angular/router';
import { ViewerService } from './viewer.service';
import { Component, AfterViewInit, OnDestroy, ViewChild, ViewContainerRef, Compiler, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { FileModel } from './../files/file/file.model';
import { FilesService } from './../files/files.service';
import { searchToFilter } from 'src/app/shared/helpers/data-helpers';
import { Viewer3dService } from './viewer3d/viewer3d.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('containerMap', { read: ViewContainerRef }) containerMap: ViewContainerRef;

  demFileModel: FileModel;
  ngDestroy$ = new Subject();
  orthoFileModel: FileModel;
  selectedView: string;
  viewer: any;

  constructor(
      private compiler: Compiler,
      private filesService: FilesService,
      private injector: Injector,
      private route: ActivatedRoute,
      private router: Router,
      private viewer3dService: Viewer3dService,
      private viewerService: ViewerService,
  ) {
    this.loadMap();

    this.viewerService.showWater$.next(true);
    this.route.params
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(params => {
      const folderID = params['folderID']
      if(folderID) {
        this.filesService.findOne(folderID)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
        response => {
          this.viewerService.viewerFileModel$.next(response.data);

          //add tiles3d
          if (response.data.viewer?.tiles3d) {
            this.filesService.findOne(response.data.viewer?.tiles3d)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
            response => {
              this.viewer3dService.fileModel$.next(response.data);
            });
          }

          //add Ortho
          if (response.data.viewer?.orthomosaic) {
            this.filesService.findOne(response.data.viewer?.orthomosaic)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
            response => {
              this.viewerService.orthoFileModel$.next(response.data);
            });
          }
          
          //add dem
          if (response.data.viewer?.dem) {
            this.filesService.findOne(response.data.viewer?.dem)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
            response => {
              this.viewerService.demFileModel$.next(response.data);
              if (response.data.dem?.min) {
                this.viewerService.height$.next(response.data.dem.min);
              }
            });
          }

          if (response.data.viewer?.imagesFolder) {
            let search = '';
            search = search.trim();
            const filter = searchToFilter(search);
            if (!Object.keys(filter).length) {
              // Set folderID from the route ID
              filter['folderID'] = { $oid: response.data.viewer?.imagesFolder }
            }
            // Default sorting and fields: Get icon markers fields
            const sort: object = { modified: -1 };
            const fields: object = { name: 1, geo: 1, iconLink: 1, tags: 1, meta: 1, annotations: 1, yaw: 1, relativeAltitude: 1, pitch: 1, public:1, hidden:1, downloadable:1 };

            // Get data from the server
            this.filesService.findMany(filter, sort, 0, 100000, fields)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
              response => {
                this.viewerService.imagesFileModels$.next({fileModels: response.data});
            });
          }
        });
      }
    });

    this.viewerService.view$.subscribe(data => {
      this.selectedView = data;
    })    
  }

  loadMap(): void {
    import('../viewer/viewer2d/viewer2d.module').then(({ Viewer2dModule }) => {
      // Compile the module
      if(this.containerMap) {
        this.compiler.compileModuleAsync(Viewer2dModule).then(moduleFactory => {
          // Create a moduleRef, resolve an entry component, create the component
          const moduleRef = moduleFactory.create(this.injector);
          const componentFactory = moduleRef.instance.resolveComponentView2d();
          const { instance } = this.containerMap.createComponent(componentFactory);
          this.viewer = instance;
        });
      }
    });
  }

  loadViewer3d(): void {
    import('../viewer/viewer3d/viewer3d.module').then(({ Viewer3dModule }) => {
      // Compile the module
      if(this.containerMap) {
        this.compiler.compileModuleAsync(Viewer3dModule).then(moduleFactory => {
          // Create a moduleRef, resolve an entry component, create the component
          const moduleRef = moduleFactory.create(this.injector);
          const componentFactory = moduleRef.instance.resolveComponentView3d();
          const { instance } = this.containerMap.createComponent(componentFactory);
          this.viewer = instance;
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.router.navigate(
      [{ outlets: { detail: ['viewer'] }}],
      { queryParamsHandling: 'merge' }
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
    setTimeout(() => {
      this.router.navigate(
        [{ outlets: { detail: null }}],
        { queryParamsHandling: 'merge' }
      );
    }, 100);
  }

  viewerChange(value: string): void {
    this.viewerService.view$.next(value)

    this.containerMap.remove();
    if(value === '2d') {
      this.loadMap();
    } else {
      this.loadViewer3d();
    }
  }
}
