import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartDataset, ChartOptions } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import { PvModulesService } from '../services/pv-modules.service';
import { getActiveInspection, getAnnotationType } from '../utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { SEVERITY_COLORS } from '../utils/severity-colors';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import TileLayer from 'ol/layer/WebGLTile';
import { Feature, Map as OlMap, View } from 'ol';
import { GeoTIFF, OSM } from 'ol/source';
import { Order } from 'src/app/shared/orders/orders.model';
import { PortfolioService } from 'src/app/shared/portfolio/portfolio.service';
import { FilesService } from '../../files.service';
import { PVModule, THERMAL_PALETTES } from '../model/pv-module.model';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { DEFAULT_STYLE } from '../utils/pv-module-styles';
import { Polygon } from 'ol/geom';
import { PvInspectionOlmapService } from '../services/pv-inspection-olmap.service';
import { FileModel } from '../../file/file.model';

@Component({
  selector: 'app-solar-site',
  templateUrl: './solar-site.component.html',
  styleUrls: ['./solar-site.component.scss']
})
export class SolarSiteComponent implements OnInit {
  siteId: string;
  siteStats: any = {};
  pvModules: any[] = [];
  activeOrder: Order;
  map: OlMap;
  inspectionFolder: FileModel = undefined

  tableRows: {
    type: string;
    count: number;
    modelTypes: number;
    dcKw: number;
    dcPercent: number;
  }[]

  currentLang: string;

  tableTotals = {
    count: 0,
    modelTypes: 0,
    dcKw: 0,
    dcPercent: 0
  };

  severityLabels = ['Critical', 'Urgent', 'Important', 'Minor', 'Advisory Note'];
  severityData = [5, 23, 23, 23, 23];

  displayedColumns: string[] = ['type', 'count', 'modelTypes', 'dcKw', 'dcPercent'];

  theme:string = this.themeService.changed$.value;
  ngDestroy$ = new Subject();

  typeLabels = ['Soiling', 'Cracking', 'Shading', 'Hotspot', 'Others'];
  typeData = [25, 20, 15, 10, 30];

  chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: ''
      }
    }
  };

  severityChart: ChartDataset[] = [
    { data: this.severityData, label: 'By Severity' }
  ];

  typeChart: ChartDataset[] = [
    { data: this.typeData, label: 'By Type' }
  ];

  public selectedThermalPalette: string[] = THERMAL_PALETTES[0]?.value ?? [];
  
  @ViewChild('severityChart') severityChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('typeChart') typeChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('map', { static: true }) mapRef!: ElementRef<HTMLDivElement>;


  constructor(
    private route: ActivatedRoute,
    private pvModulesService: PvModulesService,
    private translate: TranslateService,
    private themeService: ThemeService,
    private portfolioService: PortfolioService,
    private filesService: FilesService,
    private pvInspectionOlMapService: PvInspectionOlmapService,
    private router: Router,
    
  ) {
    this.currentLang = this.translate.currentLang
    this.translate.onLangChange.subscribe((event: any) => {
      this.currentLang = event.lang;
    });

    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
      const root = document.documentElement;
      root.classList.toggle('dark-theme', theme.includes('dark'));
    });
  }

  ngOnInit(): void {
    this.map = this.initMap()
    this.route.paramMap.subscribe(params => {
      this.siteId = params.get('siteID')!;

      this.portfolioService.getSiteOrders(this.siteId).pipe(takeUntil(this.ngDestroy$))
      .subscribe((siteOrders) => {
        this.activeOrder = siteOrders.data[0]

        const filter = {
          $and: [
            { orderID: this.activeOrder.legacyId },
            { tags: { $regex: "pv-inspection", $options: "i" } }
          ]
        };
        this.filesService.findMany(filter).pipe(takeUntil(this.ngDestroy$))
        .subscribe((responseFolders)=> {
          const inspectionFolderId = responseFolders.data[0]._id
          this.filesService.findOne(inspectionFolderId).pipe(takeUntil(this.ngDestroy$))
          .subscribe((responseFolder)=>{
            if (responseFolder.data) {
               this.inspectionFolder = responseFolder.data
              this.addLayers(this.inspectionFolder.orthoInspectionMode.mapLayers)
            }
          })
        })

      })

      this.loadModules();
    });
  }

  private loadModules(): void {
    this.pvModulesService.getPvModules({ siteId: this.siteId }).subscribe({
      next: (response) => {
        if (response.data) {
          this.pvModules = response.data;
          this.calculateSiteStats();
        }
      }
    });
  }

  private renderSeverityChart() {
    if (!this.siteStats?.anomalySeverity) return;
  
    const ctx = this.severityChartRef.nativeElement.getContext('2d');
  
    new Chart(ctx!, {
      type: 'doughnut',
      data: {
        labels: ['Urgent', 'High', 'Middle', 'Low', 'Advisory'],
        datasets: [{
          data: [
            this.siteStats.anomalySeverity['Urgent'],
            this.siteStats.anomalySeverity['High'],
            this.siteStats.anomalySeverity['Middle'],
            this.siteStats.anomalySeverity['Low'],
            this.siteStats.anomalySeverity['Advisory'],
          ],
          backgroundColor: [
            SEVERITY_COLORS.URGENT, // Urgent
            SEVERITY_COLORS.CRITICAL, // High
            SEVERITY_COLORS.IMPORTANT, // Middle
            SEVERITY_COLORS.LOW, // Low
            SEVERITY_COLORS.AD, // Advisory
          ]
        }]
      },
      options: {
        responsive: true,
        cutout: '60%',
        elements: {
          arc: {
            borderWidth: 0,
          }
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        backgroundColor: 'transparent',
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded',
              color: this.theme.includes('dark')?"#e0e0e0":"rgb(110, 110, 110)",
            }
          },
          datalabels: {
            display: false
          }
        }
      }
    });
  }
  
  public getAnnotationTypeLabel(annotationType: string): string {
    return getAnnotationType(annotationType, this.currentLang);
  }

  private renderTypeChart() {
    if (!this.siteStats?.anomalyTypes) return;
  
    const ctx = this.typeChartRef.nativeElement.getContext('2d');
  
    // Raw types from data
    const rawTypes = Object.keys(this.siteStats.anomalyTypes);
    const values = Object.values(this.siteStats.anomalyTypes);
  
    // Translated labels using getAnnotationTypeLabel
    const labels = rawTypes.map(type => this.getAnnotationTypeLabel(type));
    const blueShades = this.generateBlueShades(labels.length);
  
    new Chart(ctx!, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [{
          data: values,
          backgroundColor: blueShades
        }]
      },
      options: {
        responsive: true,
        backgroundColor: 'transparent', // not strictly necessary but good to include
        cutout: '60%',
        elements: {
          arc: {
            borderWidth: 0,
          }
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded',
              color: this.theme.includes('dark')?"#e0e0e0":"rgb(110, 110, 110)",
            }
          },
          datalabels: {
            display: false
          }
        }
      }
    });
  }
  
  private generateBlueShades(count: number): string[] {
    const baseHue = 220; // blue
    const saturation = 100;
    const lightnessStart = 40;
    const lightnessStep = 40 / count; // range from 40% to 80%
  
    return Array.from({ length: count }, (_, i) => {
      const lightness = lightnessStart + i * lightnessStep;
      return `hsl(${baseHue}, ${saturation}%, ${lightness}%)`;
    });
  }
  
  private calculateSiteStats(): void {
    const totalModules = this.pvModules.length;
  
    const modulesWithAnomalies = this.pvModules.filter(module => {
      const inspection = module.inspections?.[0];
      return inspection?.annotations?.length > 0;
    });
  
    const totalAnomalies = modulesWithAnomalies
      .map(module => module.inspections?.[0]?.annotations ?? [])
      .reduce((acc, curr) => acc.concat(curr), []);
  
      const severityMap: Record<string, number> = {
        Urgent: 0,
        High: 0,
        Middle: 0,
        Low: 0,
        Advisory: 0
      };
  
    const typeMap: Record<string, number> = {};
  
    for (const annotation of totalAnomalies) {
      const severity = annotation.stateDimension;
    
      switch (severity) {
        case 100:
          severityMap['Urgent']++;
          break;
        case 80:
          severityMap['High']++;
          break;
        case 60:
          severityMap['Middle']++;
          break;
        case 40:
          severityMap['Low']++;
          break;
        case 20:
          severityMap['Advisory']++;
          break;
        default:
          break;
      }
    
      const type = annotation.feature;
      if (type) {
        typeMap[type] = (typeMap[type] || 0) + 1;
      }
    }
  
    const identifiedModules = this.pvModules.filter(m =>
      !!m.identification?.serialNumber
    ).length;
  
    const totalCapacityWatts = this.pvModules.reduce((sum, m) => sum + (m.maxPower || 0), 0);
    const affectedCapacityWatts = modulesWithAnomalies.reduce((sum, m) => sum + (m.maxPower || 0), 0);
  
    const totalCapacityKw = totalCapacityWatts / 1000;
    const affectedCapacityKw = affectedCapacityWatts / 1000;
  
    const affectedPercent = totalCapacityKw > 0
      ? Math.round((affectedCapacityKw / totalCapacityKw) * 100)
      : 0;
  
    // Table data grouped by anomaly type
    const tableMap = new Map<string, { count: number; modelTypes: Set<string> }>();
    for (const module of modulesWithAnomalies) {
      const inspection = module.inspections?.[0];
      const model = module.identification?.modelNumber || 'Unknown';
  
      for (const annotation of inspection?.annotations ?? []) {
        const type = annotation.feature || 'Unknown';
        if (!tableMap.has(type)) {
          tableMap.set(type, { count: 1, modelTypes: new Set([model]) });
        } else {
          const entry = tableMap.get(type)!;
          entry.count++;
          entry.modelTypes.add(model);
        }
      }
    }
  
    this.tableRows = Array.from(tableMap.entries()).map(([type, data]) => {
      const modulesWithThisType = modulesWithAnomalies.filter(m =>
        m.inspections?.[0]?.annotations?.some(a => a.feature === type)
      );
  
      const totalTypePowerWatts = modulesWithThisType.reduce((sum, m) => sum + (m.maxPower || 0), 0);
      const dcKw = totalTypePowerWatts / 1000;
  
      const dcPercent = totalCapacityWatts > 0
        ? parseFloat(((totalTypePowerWatts / totalCapacityWatts) * 100).toFixed(2))
        : 0;
  
      return {
        type,
        count: data.count,
        modelTypes: data.modelTypes.size,
        dcKw: parseFloat(dcKw.toFixed(2)),
        dcPercent
      };
    });

    // Calculate totals for footer
    const totalCount = this.tableRows.reduce((sum, row) => sum + row.count, 0);
    const totalModelTypes = this.tableRows.reduce((sum, row) => sum + row.modelTypes, 0);
    const totalDcKw = this.tableRows.reduce((sum, row) => sum + row.dcKw, 0);
    const totalDcPercent = this.tableRows.reduce((sum, row) => sum + row.dcPercent, 0);

    this.tableTotals = {
      count: totalCount,
      modelTypes: totalModelTypes,
      dcKw: parseFloat(totalDcKw.toFixed(2)),
      dcPercent: parseFloat(totalDcPercent.toFixed(2))
    };
  
    this.siteStats = {
      totalModules,
      modulesWithAnomalies: modulesWithAnomalies.length,
      identifiedModules,
      capacity: `${totalCapacityKw.toFixed(2)} kWp`,
      affectedModulesPercent: Math.round((modulesWithAnomalies.length / totalModules) * 100),
      affectedPerformance: `${affectedCapacityKw.toFixed(0)} kW`,
      affectedPerformancePercent: affectedPercent,
      date: '12.14.2025',
  
      weather: {
        temperature: 18.36,
        humidity: 51,
        wind: '6.44 m/s',
        condition: 'Mostly Cloudy'
      },
  
      inspection: {
        period: '15.09.2025 - 17.09.2025',
        imagingSystem: 'XT234',
        uas: 'DJI M210',
        sunlightHours: 1000,
        ppaRate: '$0.10/kWh'
      },
  
      anomalySeverity: severityMap,
      anomalyTypes: typeMap
    };
  
    setTimeout(() => {
      this.renderSeverityChart();
      this.renderTypeChart();
    });
  }

  // ol-Map
  public initMap(): OlMap {
    let mapOptions = {
      controls: [],
      projection: 'EPSG:25832',
      view: new View({
        center: [722122, 4973948],
        zoom: 2,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: this.mapRef.nativeElement
    };
    const map = new OlMap(mapOptions);
    setTimeout(() => {
      this.updateSize(map);      
    }, 100);

    return map
  }

  public updateSize(map: OlMap): void {
    map.updateSize();
  }

  private addLayers(mapLayers: object[]): void {
    this.initLayers(mapLayers);
    setTimeout(() => {
      this.addPvModuleFeaturesFromMongo();        
    }, 1000);
  }

  private addPvModuleFeaturesFromMongo(): void {
    if (!this.pvModules || this.pvModules.length === 0) return;
  
    const totalModules = this.pvModules.length;
    let index = 0;
  
    const sharedSource = new VectorSource();
    const vectorLayer = new VectorLayer({
      source: sharedSource,
      style: (feature) => feature.get('_baseStyle') ?? DEFAULT_STYLE,
      zIndex: 120,
      className: 'pv-module',
    });
  
    this.map.addLayer(vectorLayer);
    
    const processBatch = () => {
      const features: Feature[] = [];
      const end = Math.min(index + 5000, totalModules);
  
      for (; index < end; index++) {
        const module = this.pvModules[index];
  
        if (!module?.location?.geometry?.coordinates?.length) {
          console.warn('Invalid module geometry', module);
          continue;
        }
  
        const feature = new Feature({
          _id: module._id,
          geometry: new Polygon(module.location.geometry.coordinates),
          siteId: module.siteId,
          inspections: module.inspections,
          createdAt: module.createdAt,
          modifiedAt: module.modifiedAt
        });
  
        feature.setId(module._id);
        feature.set('type', 'pvModule');
  
        const inspection = module.inspections?.find(i => i.orderId === this.activeOrder._id)
        feature.set('type', 'pvModule');

        const annotations = inspection?.annotations ?? [];
        const maxSeverity = Math.max(...annotations.map(a => a.stateDimension || 0), 0);
  
        const baseStyle = this.pvInspectionOlMapService.getStyleBySeverity(maxSeverity, false);
        feature.set('_baseStyle', baseStyle);
        feature.setStyle(baseStyle);
  
        features.push(feature);
      }
  
      if (features.length > 0) {
        sharedSource.addFeatures(features);
      }
  
      if (index < totalModules) {
        setTimeout(processBatch, 0); // Let UI thread breathe
      }
    };
  
    processBatch();
  }

  private initLayers(mapLayers: any[] = []): void {
    mapLayers.forEach((layer) => {
      this.filesService.findOne(layer.fileId).pipe(takeUntil(this.ngDestroy$))
      .subscribe((response) => {
        if (response.data) {
          layer.file = response.data;
          switch (layer.type) {
            case 'tiff': {
              const geoTiffLayer = this.addTiffLayer(this.map, layer);
              if (layer.isMainTiff) {
                geoTiffLayer.getSource().getView().then((viewOptions) => this.map.getView().fit(viewOptions.extent));
              }
              break;
            }
          }
        }
      })
    });
  }

  public addTiffLayer(map: OlMap, layer): TileLayer {
    const geoTiffSource = new GeoTIFF({
      sources: [
        {
          url: layer.file.webViewLink,
        },
      ],
      interpolate: true,
    });
    const geoTiffLayer = new TileLayer({
      source: geoTiffSource,
      zIndex: layer.zIndex,
      className: layer.type
    });
    map.addLayer(geoTiffLayer);    
    return geoTiffLayer;
  }

  public navigateToFullInspectionMode(): void {
    this.router.navigate(['pv-inspection', this.inspectionFolder._id], {});
  }
}
