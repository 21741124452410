import { LineBase } from './LineBase';
import { SvgHelper } from './SvgHelper';

export class Line extends LineBase {
  constructor() {
      super();
      this.type = 'Line';
  }

  static draw = (): LineBase => {
      const line = new Line();
      line.setup();

      return line;
  };

  protected setup(): void {
      super.setup();
      SvgHelper.setAttributes(this.visual, [['class', 'drawing line']]);
  }

}
