<div
  *ngIf="showSlider || ( usedFeatures | json) !== '{}' || this.usedImageTypes.length !==0 || ( usedStateDimension | json) !== '{}'"
  [class.dark]="this.theme && this.theme.includes('dark')"
  class="margin filterWrapper">
  <button
      mat-menu-item
      matTooltipClass="tooltip-multiline"
      type="button"
      (click)="filterToggle()">
  <mat-icon
      [matBadge]="filterFileDataLength"
      matBadgeSize="small"
      matBadgeColor="warn">filter_list</mat-icon>
    <span>{{ 'INSPECTION.VIEW.FILTER' | translate }}</span>
  </button>

  <div *ngIf="showFilter"
      class="filter">

    <!--Slider Filter-->
    <app-inspection-height-slider *ngIf="showSlider"></app-inspection-height-slider>

    <mat-accordion>

      <!--Annotation Dimension Filter-->
      <mat-expansion-panel *ngIf="( usedStateDimension | json) !== '{}'" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'SIDEBAR.ANNOTATIONS.DIMENSION' | translate}}
            <span class="counter" *ngIf="stateDimensionNumber > 0">{{ stateDimensionNumber }}</span>
              <span class="counter" *ngIf="stateDimensionNone">1</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="example-section">
          <!-- None -->
          <div class="example-list-section">
            <mat-checkbox class="example-margin"
                [checked]="stateDimensionNone"
                (change)="stateDimensionUpdateNone($event.checked)"
                color="accent">
              {{ 'NOT_CLASSIFIED' | translate }}
            </mat-checkbox>
          </div>
          <!-- Select All -->
          <div class="example-list-section">
            <mat-checkbox class="example-margin"
                color="accent"
                [checked]="stateDimensionAllChecked"
                [indeterminate]="stateDimensionIndeterminate"
                (change)="stateDimensionSetAll($event.checked)">
              {{ 'SELECT_ALL' | translate }}
            </mat-checkbox>
          </div>
          <span class="example-list-section">
            <ul>
              <li *ngFor="let type of usedStateDimension">
                <mat-checkbox
                    color="accent"
                    [(ngModel)]="type.completed"
                    (ngModelChange)="stateDimensionCheckAllComplete()">
                  {{ type[currentLang] }}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
      </mat-expansion-panel>

      <!--Annotation Type Filter-->
      <mat-expansion-panel *ngIf="( usedFeatures | json) !== '{}'" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'SIDEBAR.ANNOTATIONS.TYPE' | translate}}
            <span class="counter" *ngIf="typesNumber > 0">{{ typesNumber }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="example-section">
          <span class="example-list-section">
            <mat-checkbox class="example-margin"
              (change)="typesSetAll($event.checked)"
              color="accent"
              [checked]="typesAllChecked"
              [indeterminate]="typesIndeterminate">
              {{ 'SELECT_ALL' | translate }}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <ul>
              <ng-container *ngFor="let type of usedFeatures | keyvalue">
                <li class="type"> {{ 'ANNOTATION.INSPECTION.ASSET_TYPE.' + type.key | translate}}: </li>
                <li *ngFor="let annotationType of type.value">
                  <span>
                    <mat-checkbox
                        [(ngModel)]="annotationType.completed"
                        color="accent"
                        (ngModelChange)="typesCheckAllComplete()">
                      {{ annotationType[currentLang] }}
                    </mat-checkbox>
                  </span>
                </li>
              </ng-container>
            </ul>
          </span>
        </section>
      </mat-expansion-panel>

      <!--Image Type Filter-->
      <mat-expansion-panel *ngIf="this.usedImageTypes.length !==0"
                          (opened)="panelOpenState = true"
                          (closed)="panelOpenState = true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'FILE.IMAGETYPE.TITLE' | translate}}
            <span class="counter" *ngIf="imageTypeNumber > 0">{{ imageTypeNumber }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="example-section">
          <span class="example-list-section">
            <mat-checkbox class="example-margin"
              (change)="imageTypesSetAll($event.checked)"
              color="accent"
              [checked]="imageTypeAllChecked">
              {{ 'SELECT_ALL' | translate }}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <ul>
              <ng-container *ngFor="let type of usedImageTypes">
                <li>
                  <mat-checkbox
                  color="accent"
                  [(ngModel)]="type.completed"
                  (ngModelChange)="imageTypeCheckAllComplete()">
                  {{ 'FILE.IMAGETYPE.' + type.value.toUpperCase() | translate}}
                  </mat-checkbox>
                </li>
              </ng-container>
            </ul>
          </span>
        </section>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="stateDimensionNone || stateDimensionAllChecked || stateDimensionIndeterminate || typesAllChecked || typesIndeterminate || imageTypeAllChecked || imageTypesIndeterminate"
        class="listFilter">
      <div class="newLine"><span>{{ 'FILTERS_ACTIVE' | translate}}:</span></div>
        <div *ngIf="stateDimensionNone || stateDimensionAllChecked || stateDimensionIndeterminate" class="newLine"><span>{{ 'SIDEBAR.ANNOTATIONS.DIMENSION' | translate}}: </span>
          <ng-container *ngIf="stateDimensionNone">
            {{ 'NOT_CLASSIFIED' | translate }}
          </ng-container>
          <ng-container *ngIf="stateDimensionAllChecked || stateDimensionIndeterminate">
            {{ stateDimensionList(currentLang, ', ') }}
          </ng-container>
      </div>
      <div *ngIf="typesAllChecked || typesIndeterminate" class="newLine"><span>{{ 'SIDEBAR.ANNOTATIONS.TYPE' | translate}}: </span>
        {{ typesList(currentLang, ', ') }}
      </div>
      <div *ngIf="imageTypeAllChecked || imageTypesIndeterminate" class="newLine"><span>{{ 'FILE.IMAGETYPE.TITLE' | translate}}: </span>
        {{ imageTypeList(currentLang,', ') }}
      </div>
    </div>

    <div class="interaction" *ngIf=" ( usedFeatures | json) !== '{}' || this.usedImageTypes.length !==0 || ( usedStateDimension | json) !== '{}' ">
      <button
          mat-button
          class="confirm"
          (click)="filterToggle()">{{ 'CANCEL' | translate }}</button>
      <button
          mat-raised-button
          color="accent"
          class="confirm"
          (click)="filterSet()">{{ 'APPLY' | translate }}</button>
    </div>
  </div>
</div>
