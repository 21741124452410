export class Renderer {
  rasterize(
      target: HTMLImageElement,
      drawingSVG: SVGSVGElement,
      done: (dataUrl: string) => void,
      naturalSize?: boolean,
      imageType?: string,
      imageQuality?: number,
      drawingsOnly?: boolean
  ): void {
    const canvas = document.createElement('canvas');
    const previousWidth = drawingSVG.width.baseVal.value;
    const previousHeight = drawingSVG.height.baseVal.value;

    if (naturalSize === true) {
      // Scale to full image size
      drawingSVG.width.baseVal.value = target.naturalWidth;
      drawingSVG.height.baseVal.value = target.naturalHeight;
    }

    canvas.width = drawingSVG.width.baseVal.value;
    canvas.height = drawingSVG.height.baseVal.value;

    // Remove absolute path used for Safari
    const data = drawingSVG.outerHTML.replace(`url('${document.location}#`, "url('#");

    if (naturalSize === true) {
      // Scale back to previous size
      drawingSVG.width.baseVal.value = previousWidth;
      drawingSVG.height.baseVal.value = previousHeight;
    }

    const ctx = canvas.getContext('2d');
    if (drawingsOnly !== true) {
      ctx.drawImage(target, 0, 0, canvas.width, canvas.height);
    }

    const objectURL = window.URL; // || window.webkitURL || window;

    const img = new Image(canvas.width, canvas.height);
    img.setAttribute('crossOrigin', 'anonymous');

    const blob = new Blob([data], { type: 'image/svg+xml' });

    const url = objectURL.createObjectURL(blob);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      objectURL.revokeObjectURL(url);

      done(canvas.toDataURL(imageType !== undefined ? imageType : 'image/png', imageQuality));
    };

    img.src = url;
  }
}
