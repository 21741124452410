import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

import { DialogService } from './../../shared/dialog/dialog.service';
import { environment } from './../../../environments/environment';
import { Login } from './../login/login.model';
import { LoginStateService } from './../login/login-state.service';
import { RegistrationService, RegistrationUserModel } from './registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

  fetching: boolean;
  formGroup: FormGroup;
  recaptchaKey: string = environment.recaptchaKey;
  showPassword = false;
  validCaptcha = false;

  // email checked with mailgun, to not check again
  private checkedEmail: string;

  constructor(
      private dialogService: DialogService,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder,
      private loginStateService: LoginStateService,
      private registrationService: RegistrationService,
      private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      name: new FormControl({ value: '', disabled: this.fetching }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)
      ]),
      email: new FormControl({ value: '', disabled: this.fetching }, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(40),
        Validators.email
      ]),
      password: new FormControl({ value: '', disabled: this.fetching }, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ]),
      phone: new FormControl({ value: '', disabled: this.fetching }, [
        Validators.minLength(1),
        Validators.maxLength(30)
      ])
    });

    // Validate emails via MailGun, charges apply
    // https://app.mailgun.com/app/billing/plan
    /*this.formGroup.valueChanges
      .pipe(debounceTime(600))
      .subscribe(values => {
        if (values && values['email'] && values['email'] !== this.checkedEmail) {
          this.checkedEmail = values['email'];
          this.validateEmail();
        }
      });*/
  }

  get email(): AbstractControl { return this.formGroup.get('email'); }
  get name(): AbstractControl { return this.formGroup.get('name'); }
  get password(): AbstractControl { return this.formGroup.get('password'); }
  get phone(): AbstractControl { return this.formGroup.get('phone'); }

  handleCorrectCaptcha(token: string): void {
    if (token) {
      this.validCaptcha = true;
    }
  }

  register(): void {

    const registrationUser: RegistrationUserModel = {
      email: this.email.value,
      name: this.name.value,
      password: this.password.value,
      phone: this.phone.value
    };

    this.fetching = true;
    this.registrationService
    .register(registrationUser)
    .subscribe(
      response => {
        this.fetching = false;
        if (response['data'] && response['data']['token']) {
          const login = new Login(response['data']['token']);
          this.loginStateService.saveLogin(login);

          this.router.navigate(['/']);
        } else {
          this.dialogService.showDialog('AUTH.REGISTRATION_FAILED', null, 'AUTH.NO_TOKEN');
        }
      },
      error => {
        this.fetching = false;
        this.dialogService.showDialog('AUTH.REGISTRATION_FAILED', error.status, error.url, error.error);
      }
    );
  }

  toggleShowPassword(event: MouseEvent): void {
    event.stopPropagation();
    this.showPassword = !this.showPassword;
  }

  validateEmail(): void {
    // validate email via MailGun
    this.registrationService
    .validateEmail(this.email.value)
    .subscribe(
      response => {
        if (response && response['is_valid']) {
          // remove email errors
          this.email.setErrors(undefined);
        } else {
          // set email error to true, leaving other errors
          console.warn('[MAILGUN] Invalid email');
          const errors: ValidationErrors = this.email.errors || {};
          errors.email = true;
          this.email.setErrors(errors);
        }
      },
      error => {
        console.warn('[MAILGUN] error by email validation request', error.error);
      }
    );
  }
}
