import { Feature } from "ol";

// Define the base structure of a measurement
export interface Measurement {
  _id?: string;                                                 // Id of the measurement
  fileID?: string;                                              // file attached to this measurement
  type?: 'length' | 'area' | 'volume' | 'spot' | 'elevation' ;  // Type of the measurement
  createdAt?: string;                                             // Creation time of the measurement
  modifiedAt?: string;                                            // Last modification time of the measurement
  title?: string;                                               // Title of the measurement
  comment?: string;                                             // Description / Comment of the measurement
  value2d?: number;                                             // 2D measurement value
  value3d?: number;                                             // 3D measurement value (optional, may not exist for some types) | it represents surfaceLength or surface Area but no volumes
  location?: Location;                                          // GeoJSON format to locate the measured feature
  
  //Computed fields
  selected?: boolean;                                          // Used to expand details or not; if true means show details
  pixels?: any;                                                // 
  slope?: number;                                              // Slope value for line measurements
  elevationsMin?: number;                                      // Minimum elevation
  elevationsMax?: number;                                      // Maximum elevation
  elevationsDifference?: number;                               // Calculated as elevationsMax - elevationsMin
  customElevation?: number;                                    // Custom elevation for volume calculations (optional)
  selectedVolumeApproach?: VolumeApproach;                     // Current selected approach for volume measurement
  feature?: Feature;                                           // Rendered open-layer feature
  pixelSurface?: number; 
  baseBordersAverageElevation?: number;
                                        

  //Computed volume calculations
  volumeAverageLowestPoint?: number;
  volumeRealLowestPoint?: number;
  volumeAverageInterpolation?: number;
  volumeLinearInterpolation?: number;
  volumeCustomElevation?: number;
  volumeCut?: number;
  volumeFill?: number;
}

export interface Location {
  type?: 'Point' | 'LineString' | 'Polygon' | 'MultiPoint' | 'MultiLineString' | 'MultiPolygon'; 
  coordinates?: any;
}

export type Point = [number, number, number?]; [/* longitude */, /* latitude */, /* elevation */]


// Enum for volume approach selections to ensure consistency
export enum VolumeApproach {
  VolumeRealLowestPoint = "volumeRealLowestPoint",
  VolumeAverageInterpolation = "volumeAverageInterpolation",
  VolumeLinearInterpolation = "volumeLinearInterpolation",
  VolumeCustomElevation = "volumeCustomElevation"
}