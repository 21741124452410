import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent {
  formGroup: FormGroup;

  features = [
    {value: 'moss', viewValue: 'Moos'},
    {value: 'corrosion', viewValue: 'Korrosion'},
    {value: 'rubble', viewValue: 'Schutt'},
    {value: 'hail', viewValue: 'Hagel'}
  ];

  constructor(
      public dialogRef: MatDialogRef<FilterDialogComponent>,
      @Inject(FormBuilder)
      private formBuilder: FormBuilder,
      private route: ActivatedRoute
  ) {
    route.queryParamMap.subscribe((params) => {
      this.formGroup = this.formBuilder.group({
        feature: new FormControl(params.get('feature') || '', []),
        comment: new FormControl(params.get('comment') || '', []),
        annotationType: new FormControl(params.get('annotationType') || '', []),
        name: new FormControl(params.get('name') || '', [
          Validators.maxLength(256),
          // Safe Characters https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
          Validators.pattern(/^[^:\\|\/{}<>´`"~^%#]+$/)
        ])
      });
    })
  }

  get feature(): AbstractControl { return this.formGroup.get('feature'); }
  get name(): AbstractControl { return this.formGroup.get('name'); }
  get comment(): AbstractControl { return this.formGroup.get('comment'); }
  get annotationType(): AbstractControl { return this.formGroup.get('annotationType'); }
}
