
import { Component, Input, OnDestroy } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';

import { Login } from './../../../pages/login/login.model';
import { LoginStateService } from './../../../pages/login/login-state.service';
import { SidenavNavigationService } from './sidenav-navigation.service';
import { AccountType, User, UserRole } from '../../../pages/users/models/user.model';

@Component({
  selector: 'app-sidenav-navigation',
  templateUrl: './sidenav-navigation.component.html',
  styleUrls: ['./sidenav-navigation.component.scss']
})
export class SidenavNavigationComponent implements OnDestroy {

  @Input() dir: Direction;
  loggedUser: User;
  login: Login;
  userRole = UserRole;
  accountType = AccountType

  constructor(
      private loginStateService: LoginStateService,
      private sidenavNavigationService: SidenavNavigationService
  ) {
    this.loginStateService.loggedUser$.subscribe(loggedUser => {
      if (loggedUser) {
        Promise.resolve(undefined).then(() => {
          this.loggedUser = loggedUser;
        });
      }
    });

    this.loginStateService.login$.subscribe(login => {
      if (login) {
        Promise.resolve(undefined).then(() => {
          this.login = login;
        });
      }
    });
  }

  closeSidenavNavigation(): void {
    if (this.sidenavNavigationService.isMobile || this.sidenavNavigationService.isXSmall) {
      this.sidenavNavigationService.close();
    }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.loginStateService.login$.unsubscribe();
  }
}
