<h2 mat-dialog-title>{{ 'FILES.CREATE_NEW_FILTER' | translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" novalidate>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'SIDEBAR.ANNOTATIONS.SERVITY_LABEL' | translate }}</mat-label>
      <mat-select
        formControlName="feature">
        <mat-option value="">
          -
        </mat-option>
        <mat-option value="low">
          {{ 'SIDEBAR.ANNOTATIONS.FEATURE.LOW' | translate }}
        </mat-option>
        <mat-option value="middle">
          {{ 'SIDEBAR.ANNOTATIONS.FEATURE.MIDDLE' | translate }}
        </mat-option>
        <mat-option value="high">
          {{ 'SIDEBAR.ANNOTATIONS.FEATURE.HIGH' | translate }}
        </mat-option>
        <mat-option value="own">
          {{ 'SIDEBAR.ANNOTATIONS.FEATURE.CUSTOM' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'SIDEBAR.ANNOTATIONS.FEATURE_LABEL' | translate }}</mat-label>
      <mat-select
        formControlName="annotationType">
        <mat-option value="">
          -
        </mat-option>
        <mat-option *ngFor="let feature of features" [value]="feature.value">
          {{feature.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'SIDEBAR.ANNOTATIONS.COMMENT' | translate }}</mat-label>
      <textarea
        cdkTextareaAutosize
        formControlName="comment"
        matInput></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'NAME' | translate }}</mat-label>
      <input
        id="filter-name"
        formControlName="name"
        (keyup.enter)="dialogRef.close({name: name.value})"
        matInput>
      <mat-error *ngIf="name.errors?.pattern">{{ 'VALIDATION.USE_SAFE_CHARACTERS' | translate }}</mat-error>
      <mat-error *ngIf="name.errors?.maxlength">{{ 'VALIDATION.MAX_LENGTH' | translate: { count: name.errors?.maxlength?.requiredLength } }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      type="button">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      #create
      color="accent"
      [disabled]="!formGroup.valid"
      [mat-dialog-close]="{ feature: feature.value, annotationType: annotationType.value, name: name.value, comment: comment.value }"
      mat-raised-button
      type="button">{{ 'OK' | translate | uppercase }}</button>
</div>
