import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { environment } from './../../../environments/environment.prod';
import { FileModel } from './../../pages/files/file/file.model';
import { FilesService } from './../../pages/files/files.service';
import { Hotspot } from './hotspot.model';
import { pois } from './pois';

declare let krpanoJS: any;
declare global {
  interface Window { 
    krpanoFunction: any;
    krpanoView: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class KrpanoService {
  krpano: any;
  panoID: any;
  hotspotName: string;
  showSelector = true;
  ngDestroy$ = new Subject();

  getHotspotURL(icon: string, poisTag: string, color = '' ): string {
    if (color.length === 0) {
      color = '#536262';
    }
    if (pois[icon] !== undefined) {
      return environment.apiHost + "poi/" + pois[icon].svg + "/" + color.replace('#','')
    } else if (icon.split(':').length === 2 && icon.split(':')[0] !== 'custom') {
      if (icon.split(':')[0] === 'green') {
        color = '0f8138';
      } else if(icon.split(':')[0] === 'yellow') {
        color = 'f0ff3e';
      } else if(icon.split(':')[0] === 'blue') {
        color = '3389c6';
      } else if(icon.split(':')[0] === 'cushwake') {
        color = 'e61c30';
      }
      
      return environment.apiHost + "poi/" + pois[icon.split(':')[1]].svg + "/" + color.replace('#','')
    } else if (icon.split(':').length === 2 && icon.split(':')[0] === 'custom') {
      return environment.apiHost + "poi/" + "marker_" + icon.split(':')[1] + "/" + color.replace('#','')
    } else {
      return environment.apiHost + "poi/" + "marker_" + icon + "/" + color.replace('#','')
    }
  }

  getPanoID(): any {
    return this.panoID;
  }

  divAdd(container: HTMLDivElement): void {
    const div = document.createElement('div');
    div.id = 'panoDiv';
    div.style.position = 'absolute';
    div.style.width = '100%';
    div.style.height = '100%';
    container.appendChild(div);
  }

  divRemove(panoDiv: HTMLDivElement): void {
    panoDiv.remove();
  }
  
  idUnset(): void {
    this.panoID = '';
  }

  polygonStart(name: string): void {
    this.krpano.set('drawing','true')
    this.krpano.set('polyName',name)
    const poly = this.krpano.addhotspot(name);
    poly.loadstyle('tooltip');
    this.krpano.set('hotspot[get(polyName)].enabled', 'false')
    this.krpano.set('pid', '0');
    this.krpano.set('editable', 'false');
    this.krpano.set('hotspot[get(polyName)].bordercolor', '0x2979ff');
    this.krpano.set('hotspot[get(polyName)].fillcolor', '0x2979ff');
  }

  polygonStop(): void {
    this.krpano.set('drawing', 'false');
    this.krpano.set('editable','false')
    this.krpano.set('hotspot[get(polyName)].enabled', 'true')
  }

  polygonGetPoints(name: string): any {
    const polygon = this.krpano.get(`hotspot[${name}]`).point.getArray();
    
    polygon.map(function(item) { 
      delete item.index; 
      delete item.name;
      return item; 
    });
    return polygon
  }

  
  /**
   * Adds a new hotspot to the pano and to the hotspots array
   */
  hotspotAdd(name: string, icon: string, poisTag: string, color?: string, h?: number, v?: number ): void {
    this.hotspotName = name;
    if (!h) { h = this.krpano.get('view.hlookat'); }
    if (!v) { v = this.krpano.get('view.vlookat'); }

    const hs = this.krpano.addhotspot(name);

    const hotspotURL = this.getHotspotURL(icon, poisTag, color)
    this.krpano.set(`hotspot[${name}].url`, this.makeURLHotspot(hotspotURL));
    this.krpano.set(`hotspot[${name}].ath`, h);
    this.krpano.set(`hotspot[${name}].atv`, v);
    hs.loadstyle('tooltip | hotspotStyle');
    this.krpano.set(`hotspot[${name}].ondown`, 'draghotspot();');
    this.krpano.set('editable', 'false');
    this.hotspotSelectedAdd(h, v);
  }

  hotspotGetPosition(name: string): any {
    return {
      ath: parseFloat(this.krpano.get(`hotspot[${name}].ath`)).toFixed(4),
      atv: parseFloat(this.krpano.get(`hotspot[${name}].atv`)).toFixed(4)
    };
  }

  hotspotLookTo(name: string): void {
    this.krpano.call(`looktohotspot(${name},${this.krpano.get('view.fov')})`);
  }

  hotspotMove(name: string, enable: boolean): void {
    this.hotspotName = name;
    if (enable) {
      this.krpano.set(`hotspot[${name}].ondown`, 'draghotspot();');
      this.krpano.set('editable', 'false');
      const h = this.krpano.get(`hotspot[${name}].ath`);
      const v = this.krpano.get(`hotspot[${name}].atv`);
      this.hotspotSelectedAdd(h, v);
    } else {
      this.krpano.set(`hotspot[${name}].ondown`, '');
      this.krpano.set('editable', 'true');
      this.hotspotSelectedRemove();
    }
  }

  hotspotRemove(name: string): void {
    this.krpano.call(`removehotspot(${name})`);
    this.krpano.set('editable', 'true');
    this.hotspotSelectedRemove();
  }

  hotspotSelectedAdd(h: number, v: number): void {
    const hs = this.krpano.addhotspot('selected');
    this.krpano.set("hotspot['selected'].url", this.makeURLHotspot('https://fairfleet.com/a/Panorama/png/marker_selected_100.png'));
    this.krpano.set("hotspot['selected'].ath", h);
    this.krpano.set("hotspot['selected'].atv", v);
    hs.loadstyle('hotspotSelectedStyle');
  }

  hotspotSelectedRemove(): void {
    this.krpano.call('removehotspot(selected)');
  }

  /**
   * phrases from hotspots to xmlString
   * @returns XML-string for the krpano XML-config
   */
  hotspotsXML(hotspots: Array<Hotspot>, shared = false, poisTag = ''): string {
    let xmlString = '';
    if (hotspots === undefined) {
      return xmlString;
    } else {
      hotspots.forEach(hotspot => {
        if (hotspot.type === 'poi') {
          const hotspotURL = this.getHotspotURL(hotspot.icon, poisTag, hotspot.color)
          xmlString = `${xmlString}<hotspot name="${hotspot.name}" url="${this.makeURLHotspot(hotspotURL)}" style="tooltip | hotspotStyle" tooltip="${this.makeTextSave(hotspot.tooltip)}" imageURL="${hotspot.imageURL}" alpha="${(hotspot.alpha) ? hotspot.alpha : 1.0}" scale="${(hotspot.scale) ? hotspot.scale : 1.0}" ath="${hotspot.ath}" atv="${hotspot.atv}" ${(shared && hotspot.openURL) ? `onclick="openurl('${hotspot.openURL}', _blank)"` : ''} />`;
        } else if (hotspot.type === 'polygon') {
          xmlString = `${xmlString}<hotspot name="${hotspot.name}" tooltip="${this.makeTextSave(hotspot.tooltip)}" style="tooltip" fillcolor="${(hotspot.color) ? hotspot.color.replace('#', '0x') : '0x536262'}" fillalpha="${(hotspot.alpha !== undefined) ? hotspot.alpha : 0.5}" bordercolor="${(hotspot.color) ? hotspot.color.replace('#', '0x') : '0x536262'}" ${(shared && hotspot.openURL) ? `onclick="openurl('${hotspot.openURL}', _blank)"` : ''} >`;
          hotspot.points.forEach(point => {
            xmlString = `${xmlString}<point ath="${point.ath}" atv="${point.atv}"/>`;
          });
          
          xmlString = `${xmlString}</hotspot>`;

        }
      });

      const usedFileIDs = {}
      hotspots.forEach((hotspot) => {
        if(hotspot.imageID && !Object.keys(hotspot.imageID).includes(hotspot.imageID.toString())) {
          usedFileIDs[hotspot.imageID.toString()] = hotspot.name;
        }
      })

      if(Object.keys(usedFileIDs).length >0) {
        setTimeout(() => {
          this.updateByImageID(usedFileIDs, shared);
        }, 1000);
      }

      return xmlString;
    }
  }

  hotspotUpdate(name: string, attribute: string, value: string, poisTag = '', color = ''): void {
    if (attribute === 'url') {
      const hotspotURL = this.getHotspotURL(value, poisTag, color)
      this.krpano.set(`hotspot[${name}].url`, `${hotspotURL}`);
    } else if (attribute === 'tooltip') {
      this.krpano.set(`hotspot[${name}].${attribute}`, this.makeTextMultiline(value));
    } else {
      this.krpano.set(`hotspot[${name}].${attribute}`, value);
    }
  }


  makeURLHotspot(url: String): String {
    if (url) {
      return url
          .split('.jpg').join(`.jpg?nocache=${Date.now()}`)
          .split('.png').join(`.png?nocache=${Date.now()}`);
    }
  }

  loadPano(fileModel: FileModel, shared = false, edit = false, poisTag = ''): void {
    let littlePlanet = false;
    if (fileModel.pano.littlePlanet === undefined && this.panoID !== fileModel._id) {
      littlePlanet = true;
    }
    this.panoID = fileModel._id;

  krpanoJS.embedpano({xml: null, target: 'panoDiv', html5: 'webgl+only', onready: krpanoJSinterface => {
    this.krpano = krpanoJSinterface.get('global');
    const drawing = `<events onkeydown="if(keycode == 32, stopdraw(get(polyName)));" onclick="if(drawing == true, addpoint());"/>
    <action name="startdraw">
      set(drawing, true);
      set(polyName,'var1');
      addhotspot(get(polyName)); 
      set(hotspot[get(polyName)].enabled, false); <!-- needed to add new Points -->
      set(pid, 0);
    </action>
    <action name="stopdraw">
      jscall('krpanoFunction("stopDraw")');
    </action>
    <action name="addpoint">
      screentosphere(mouse.x, mouse.y, toh, tov);
      set(hotspot[get(polyName)],point[get(pid)].ath=get(toh),point[get(pid)].atv=get(tov));
      if(_points, set(_points, calc:_points + ' ' + data[html_point].content + 'ath="' + toh + '" atv="' + tov + '"' + data[html_/exit].content);, set(_points, calc:' ' + data[html_point].content + 'ath="' + toh + '" atv="' + tov + '"' + data[html_/exit].content); );
      updatescreen();
      mousemove();
      inc(pid);
    </action>
    <action name="mousemove">
      screentosphere(mouse.x, mouse.y, toh, tov);
      set(hotspot[get(polyName)].point[get(pid)].ath, get(toh));
      set(hotspot[get(polyName)].point[get(pid)].atv, get(tov));
      updatescreen();
      if(drawing == true, delayedcall(0.0, mousemove()));
    </action>`

if (fileModel.tags.includes('slider')) {
  // Load slider app
  // TODO: once tested, get rid of debug mode!
  this.krpano.actions.loadxml(`
    <krpano debugmode="true" debugjsactions="true" debugjsplugins="true">
    <include url="https://fairfleet.com/a/Panorama/krpano/1.20.9/skin/vtourskin.xml" keep="true" />
    <include url="https://fairfleet.com/a/Panorama/krpano/1.20.9/scripts/viewer_1.02.xml" keep="true" /> <!-- Reduce to viewer.xml -->
    ${(fileModel.pano?.compass) ? '<include url="https://fairfleet.com/a/Panorama/krpano/1.20.9/scripts/compass_1.0.xml" />' : ''}
  
    <style name="cubeface" distorted="true" width="1000" height="1000" enabled="false" alpha="0.0" />

    <skin_settings
      webvr="false"
      controlbar_offset="20"
      design_skin_images="${this.makeURLHotspot('vtourskin.png')}"
      design_text_css="color:#FFFFFF; font-family:Roboto; font-style: normal !important; font-size:14px !important;"
      layout_width="100%"
      littleplanetintro="false"
      thumbs="false"
      />

    <view
      hlookat="${(fileModel.pano.view && fileModel.pano.view.hlookat) ? fileModel.pano.view.hlookat : 0}"
      vlookat="${(fileModel.pano.view && fileModel.pano.view.vlookat) ? fileModel.pano.view.vlookat : 0}"
      maxpixelzoom="1"
      fovtype="MFOV"
      fov="${(fileModel.pano.view && fileModel.pano.view.fov) ? fileModel.pano.view.fov : 100}"
      fovmax="130" />
 
    <style name="button" type="text" zorder="2"
      css="text-align:center;"
      padding="4 8"
      bgborder="4 0xFFFFFF 1"
      bgroundedge="1"
      bgshadow="0 1 4 0x000000 1.0"
      onover="set(bgcolor, 0xC7E4FC);"
      onout="calc(bgcolor, pressed ? 0x90CAF9 : 0xFFFFFF);"
      ondown="set(bgcolor, 0x90CAF9);"
      onup="calc(bgcolor, hovering ? 0xC7E4FC : 0xFFFFFF);"
    />

    <layer name="blend_slider" type="container" zorder="1" autoalpha="true" alpha="1"
    align="bottom" y="100" width="200" height="16" bgalpha="0.5" bgcolor="0xFFFFFF" bgborder="1 0xFFFFFF 0.2" bgroundedge="9" bgblur="5"
    >
      <layer name="blend_grip" align="left" edge="center" style="button" width="48" height="28" bgroundedge="20" padding="0" textalign="center" text="" x="10"
        ondown.addevent="
        copy(drag_currentx, x);
        copy(drag_stagex, mouse.stagex);
          asyncloop(pressed,
            calc(newx, drag_currentx + (mouse.stagex - drag_stagex)); 
            clamp(newx, 0, 200);
            copy(x, newx);
            setblend(calc(newx / 200));				
            );"
      />
    </layer>

    <!-- load Hotspots -->
    ${(fileModel.pano.hotspots) ? this.hotspotsXML(fileModel.pano.hotspots, shared, poisTag) : ''}

    <!-- load XML -->
    ${(fileModel.pano.customXML) ? this.makeURLHotspot(fileModel.pano.customXML) : ''}

    <action name="setblend">
      set(blend1, %1);
      sub(blend2, 1, %1);
      copy(hotspot[pano1_f].alpha, blend1);
      copy(hotspot[pano1_l].alpha, blend1);
      copy(hotspot[pano1_r].alpha, blend1);
      copy(hotspot[pano1_b].alpha, blend1);
      copy(hotspot[pano1_u].alpha, blend1);
      copy(hotspot[pano1_d].alpha, blend1);
      copy(hotspot[pano2_f].alpha, blend2);
      copy(hotspot[pano2_l].alpha, blend2);
      copy(hotspot[pano2_r].alpha, blend2);
      copy(hotspot[pano2_b].alpha, blend2);
      copy(hotspot[pano2_u].alpha, blend2);
      copy(hotspot[pano2_d].alpha, blend2);
    </action>

    <action name="start" autorun="onstart">
      setblend(0);
      if(device.desktop, skin_hideskin(instant););
    </action>

    <!-- load drawing -->
    ${drawing}

    </krpano>
  `);
  } else {
  // Load normal app
    this.krpano.actions.loadxml(`
    <krpano>
    <!-- <include> -->
  <include url="https://fairfleet.com/a/Panorama/krpano/1.20.9/skin/vtourskin.xml" keep="true" />
  <include url="https://fairfleet.com/a/Panorama/krpano/1.20.9/scripts/viewer_1.02.xml" keep="true" /> <!-- Reduce to viewer.xml -->
  ${(fileModel.pano?.compass) ? '<include url="https://fairfleet.com/a/Panorama/krpano/1.20.9/scripts/compass_1.0.xml" />' : ''}

  <skin_settings
      webvr="false"
      controlbar_offset="20"
      design_skin_images="${this.makeURLHotspot('vtourskin.png')}"
      design_text_css="color:#FFFFFF; font-family:Roboto; font-style: normal !important; font-size:14px !important;"
      layout_width="100%"
      littleplanetintro="${littlePlanet}"
      loadingtext="loading"
      loadscene_flags="MERGE|KEEPVIEW"
      thumbs="false"
      />

  <action name="startup" autorun="onstart">
    loadscene(Pano 1,null,MERGE,BLEND(1));
  </action>


  <scene name="Pano 1"
      ${(fileModel.pano.compassCorrection) ? 'onstart="korrect_comp('+fileModel.pano.compassCorrection+');"' : ''}
      title="${(fileModel.pano.title) ? this.makeTextSave(fileModel.pano.title) : ''}"
      thumburl="${this.makeURLImage(fileModel.thumbnailLink)}">

    <!-- <preview> -->
    <preview url="${this.makeURLImage(fileModel.thumbnailLink)}" />
    <image>
      <sphere url="${this.makeURLImage(fileModel.webViewLink)}" />
    </image>
    <view
        hlookat="${(fileModel.pano.view && fileModel.pano.view.hlookat) ? fileModel.pano.view.hlookat : 0}"
        vlookat="${(fileModel.pano.view && fileModel.pano.view.vlookat) ? fileModel.pano.view.vlookat : 0}"
        maxpixelzoom="1"
        fovtype="MFOV"
        fov="${(fileModel.pano.view && fileModel.pano.view.fov) ? fileModel.pano.view.fov : 100}"
        fovmax="130" />

    <!-- load Hotspots -->
    ${(fileModel.pano.hotspots) ? this.hotspotsXML(fileModel.pano.hotspots, shared, poisTag) : ''}

    <!-- load XML -->
    ${(fileModel.pano.customXML) ? this.makeURLHotspot(fileModel.pano.customXML) : ''}
    </scene> 

    <!-- load drawing -->
    ${drawing}

</krpano>`);
    }}});
    if (edit) {
      this.krpano.set('editable', 'true');
    }
  }

  lookTo(toH: number, toV: number, foV = 0): void {
    if (foV === 0) {
      this.krpano.call(`lookto(${toH},${toV},${this.krpano.get('view.fov')})`);
    } else {
      this.krpano.call(`lookto(${toH},${toV},${foV})`);
    }
  }

  makeTextMultiline(text: String): string {
    if (text) {
      return text
      .split('\n').join('[br]');
    }
  }

  makeTextSave(text: String): string {
    if (text) {
      return text
      .split('\n').join('[br]')
      .split('"').join('&quot;')
      .split("'").join('&apos;')
      .split('<').join('&lt;')
      .split('>').join('&gt;')
      .split('&').join('&amp;');
    }

    return '';
  }

  viewGet(): any {
    return {
      ath: this.krpano.get('view.hlookat').toFixed(4),
      atv: this.krpano.get('view.vlookat').toFixed(4),
      fov: this.krpano.get('view.fov').toFixed(4)
    };
  }

  makeURLImage(url: String): String {
    return url.split('&').join('&amp;');
  }

  mouseDownGet(): any {
    const pnt = this.krpano.screentosphere(this.krpano.get('mouse.downx'), this.krpano.get('mouse.downy'));
    const hPos = pnt.x;
    const vPos = pnt.y;

    return {
      v: vPos,
      h: hPos
    };
  }

  updateByImageID(usedFileIDs: any, shared = false): void {
    const filter = {'_id': { $in: Object.keys(usedFileIDs).map(id => ({ $oid: id.trim() })) }}
    const sort = {"isFolder":-1, "modified":-1, "created":-1}

    let findMany: Observable<any>
    if(shared) {
      findMany = this.filesService.findManyShared(filter, sort, 0, 1000)
    } else {
      findMany = this.filesService.findMany(filter, sort, 0, 1000)
    }

    findMany
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {
        response.data.forEach(element => {
          this.krpano.set(`hotspot[${usedFileIDs[element._id]}].imageURL`, element.webViewLink);
        });
      }
    );
  }

  updateSelector(value): void {
    this.showSelector = value
    if (value) {
      this.hotspotSelectedRemove();
    } else {
      const h = this.krpano.get(`hotspot[${this.hotspotName}].ath`);
      const v = this.krpano.get(`hotspot[${this.hotspotName}].atv`);
      this.hotspotSelectedAdd(h, v);
    }
  }

  constructor(
    public filesService: FilesService,
  ) { }
}