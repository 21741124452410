import { EventEmitter } from '@angular/core';

import { BaseState } from './BaseState';
import { SvgHelper } from './SvgHelper';

export class Base {
  change: EventEmitter<any> = new EventEmitter();
  readonly DEFAULT_TEXT = 'Double-click to edit text';
  defs: Array<SVGElement> = [];
  geoJSON: GeoJSON.Feature;
  onSelected: (base: Base) => void;
  renderVisual: SVGGElement;
  target: HTMLImageElement;
  text: string = this.DEFAULT_TEXT;
  type = 'Base';
  visual: SVGGElement;
  protected height = 50;
  protected isActive = true;
  protected isResizing = false;
  protected previousMouseX = 0;
  protected previousMouseY = 0;
  protected previousState: BaseState;
  protected width = 200;
  private isDragging = false;

  static draw = (): Base => {
    const base = new Base();
    base.setup();

    return base;
  };

  deselect(): void {
    this.isActive = false;
    this.endManipulation();

    return;
  }

  endManipulation(): void {
    if (this.isDragging || this.isResizing) {
      this.isDragging = false;
      this.isResizing = false;
      this.stateToGeoJSON();
      this.change.next();
    }
  }

  getState(): BaseState {
    const config: BaseState = {
      type: this.type,
      width: this.width,
      height: this.height,
      translateX: this.visual.transform.baseVal.getItem(0).matrix.e,
      translateY: this.visual.transform.baseVal.getItem(0).matrix.f
    };

    return config;
  }

  gripMouseDown = (ev: MouseEvent) => {
    return;
  };

  manipulate = (ev: MouseEvent) => {
    let scale: number;
    // Prefer getting the scale from the drawingArea
    const drawingArea = this.visual.closest('div');
    if (drawingArea && drawingArea.style) {
        const transform = drawingArea.style.transform;
        if (transform && transform.includes('scale(')) {
            scale = parseFloat(transform.replace('scale(', '').replace(')', ''));
        }
    }
    if (!scale) {
        // As fallback try to get scale from getScreenCTM
        // because on Firefox it always returns 1, see http://jsfiddle.net/ps_svg/4x73N/
        scale = this.visual.getScreenCTM().a || 1;
    }

    // Prefer screenX (over offsetX) as it works also in Firefox
    // Apply scale in case the drawingArea was resized
    const dx = (ev.screenX - this.previousMouseX) / scale;
    const dy = (ev.screenY - this.previousMouseY) / scale;

    if (this.isDragging) {
      this.move(dx, dy);
    }
    if (this.isResizing) {
      this.resize(dx, dy);
    }
  };

  mouseDown = (ev: MouseEvent) => {
    ev.stopPropagation();
    this.select();
    this.isDragging = true;
    this.previousMouseX = ev.screenX;
    this.previousMouseY = ev.screenY;
    this.previousState = this.getState();
  };

  mouseMove = (ev: MouseEvent) => {
    ev.stopPropagation();
    this.manipulate(ev);
  };

  mouseUp = (ev: MouseEvent) => {
    ev.stopPropagation();
    this.endManipulation();
  };

  move = (dx: number, dy: number) => {
    const previousX = this.previousState ? this.previousState.translateX : 0;
    const previousY = this.previousState ? this.previousState.translateY : 0;

    const translate = this.visual.transform.baseVal.getItem(0);
    translate.setTranslate(previousX + dx, previousY + dy);
    this.visual.transform.baseVal.replaceItem(translate, 0);
  };

  renderText(): void {
    return;
  }

  restoreState(state: BaseState): void {
    this.width = state.width;
    this.height = state.height;

    this.resize(state.width, state.height);

    const translate = this.visual.transform.baseVal.getItem(0);
    translate.matrix.e = state.translateX;
    translate.matrix.f = state.translateY;
    this.visual.transform.baseVal.replaceItem(translate, 0);
  }

  select(): void {
    this.isActive = true;
    if (this.onSelected) {
      this.onSelected(this);
    }

    return;
  }

  stateFromGeoJSON(): void {
    const rect = this.target.getBoundingClientRect();
    const properties = this.geoJSON.properties;
    const scale = rect.width / properties['mw'];
    const coordinates = this.geoJSON.geometry['coordinates'][0];
    const x = coordinates[0][0] * scale;
    const y = coordinates[0][1] * scale;
    this.width = properties['w'] * scale;
    this.height = properties['h'] * scale;

    this.resize(this.width, this.height);

    const translate = this.visual.transform.baseVal.getItem(0);
    translate.setTranslate(x, y);
    this.visual.transform.baseVal.replaceItem(translate, 0);

    if (this.type === 'Text' && this.geoJSON.properties.text && this.text !== this.geoJSON.properties.text) {
      this.text = this.geoJSON.properties.text;
      this.renderText();
    }
  }

  stateToGeoJSON(): void {
    const matrix = this.visual.transform.baseVal.getItem(0).matrix;
    const rect = this.target.getBoundingClientRect();
    const mw = this.target.naturalWidth;
    const mh = this.target.naturalHeight;
    const scale = mw / rect.width;
    const x = matrix.e * scale;
    const y = matrix.f * scale;
    const w = this.width * scale;
    const h = this.height * scale;

    this.geoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [x, y], // north-west point
          [x, y + h], // south-west point
          [x + w, y + h], // south-east point
          [x + w, y], // north-east point
          [x, y] // north-west (closing point)
        ]]
      },
      properties: {
        w,  // Shape unscaled width
        h, // Shape unscaled height
        mw, // The full width of the image at the time when the drawing was made
        mh, // The full height of the image at the time when the drawing was made,
        shape: this.type
      }
    };

    if (this.type === 'Text' && this.text !== this.DEFAULT_TEXT) {
      this.geoJSON.properties.text = this.text;
    }
  }

  protected addToRenderVisual = (el: SVGElement) => {
    this.renderVisual.appendChild(el);
  };

  protected addToVisual = (el: SVGElement) => {
    this.visual.appendChild(el);
  };

  protected resize(x: number, y: number): void {
    return;
  }

  protected setup(): void {
    this.visual = SvgHelper.createGroup();
    // translate
    this.visual.transform.baseVal.appendItem(SvgHelper.createTransform());

    this.renderVisual = SvgHelper.createGroup([['class', 'render-visual']]);
    this.visual.appendChild(this.renderVisual);
  }
}
