<div
    class="app-container"
    [class.full-screen]="fullScreen"
    [class.full-screen-primary]="fullScreenPrimary"
    [class.full-screen-detail]="fullScreenDetail"
    [class.sidenav-detail-opened]="sidenavDetailOpened"
    [class.x-small]="isXSmall"
    [ngClass]="isMobile ? 'is-mobile' : 'not-mobile'">
    <app-header *ngIf="!fullScreen" [dir]="dir"></app-header>

  <mat-sidenav-container [dir]="dir">
    <mat-sidenav
        #sidenavNavigation
        autoFocus="false"
        class="sidenav-navigation"
        [class.visible]="fullScreen"
        [disableClose]="!(isMobile || isXSmall)"
        [fixedInViewport]="(isMobile || isXSmall)"
        fixedTopGap="56"
        [mode]="(isMobile || isXSmall) ? 'over' : 'side'"
        mwlResizable
        opened="false"
        (openedChange)="sidenavNavigationOpenedChange($event)"
        [resizeEdges]="{left: dir === 'rtl', right: dir === 'ltr'}"
        (resizeEnd)="sidenavNavigationResizeEnd($event)"
        (resizing)="sidenavNavigationResizing($event)"
        role="navigation">

      <app-sidenav-navigation [dir]="dir"></app-sidenav-navigation>

      <mat-icon
          *ngIf="!(isMobile || isXSmall)"
          mwlResizeHandle
          [resizeEdges]="{left: dir === 'rtl', right: dir === 'ltr'}"
          class="resize-handle-right"
          svgIcon="resize_handle_vertical"></mat-icon>
    </mat-sidenav>

    <mat-sidenav-content
        #sidenavContent
        cdkScrollable
        id="sidenav-content">
      <main role="main" class="main-content">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>

    <mat-sidenav
        #sidenavDetail
        autoFocus="false"
        class="sidenav-detail"
        [class.visible]="fullScreen"
        (closedStart)="sidenavDetailOpenedStart(false)"
        disableClose="true"
        [fixedInViewport]="fullScreenDetail || isXSmall"
        [mode]="(fullScreenDetail || isMobile || isXSmall) ? 'over' : 'side'"
        mwlResizable
        opened="false"
        (openedChange)="sidenavDetailOpenedChange($event)"
        (openedStart)="sidenavDetailOpenedStart(true)"
        position="end"
        [resizeEdges]="{left: dir === 'ltr', right: dir === 'rtl'}"
        (resizeEnd)="sidenavDetailResizeEnd($event)"
        (resizeStart)="sidenavDetailResizeStart()"
        (resizing)="sidenavDetailResizing($event)"
        role="region">

      <router-outlet name="detail"></router-outlet>

      <mat-icon
          *ngIf="!(hideDetailHandle || fullScreenDetail || isMobile || isXSmall)"
          mwlResizeHandle
          [resizeEdges]="{left: dir === 'ltr', right: dir === 'rtl'}"
          class="resize-handle-left"
          svgIcon="resize_handle_vertical"></mat-icon>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
