<div>
  <header>
    <mat-toolbar
      class="user-toolbar"
      role="toolbar">

      <button
        class="close-button"
        (click)="close()"
        mat-icon-button
        title="{{ 'CLOSE' | translate }}"
        type="button">
        <mat-icon class="rtl-mirror">close</mat-icon>
      </button>

      <h2 *ngIf="pilot" class="header-title" title="{{ pilot.firstName }} {{ pilot.lastName }}">
        {{ pilot.firstName }} {{ pilot.lastName }}
      </h2>

      <span class="fill-remaining"></span>
    </mat-toolbar>
  </header>

  <mat-tab-group *ngIf="pilot" mat-stretch-tabs>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>info</mat-icon>
        <span class="tab-label">{{'ORDER.INFO' | translate}}</span>
      </ng-template>

      <div class="tab-content tab-values">
        <div class="ro-field" title="ID">
          <p class="label">
            <mat-icon matSuffix>link</mat-icon>
            <span>{{'ID' | translate}}</span>
          </p>
          <p class="value">
            <a href="https://fairfleet360.com/admin/pilot/view?id={{pilot.id}}" rel="noopener" target="_blank"><strong>{{ pilot.id }}</strong></a>
          </p>
        </div>

        <div class="ro-field">
          <p class="label">
            <mat-icon matSuffix>label</mat-icon>
            <span>{{'TAGS' | translate}}</span>
          </p>
          <p class="value">
            <mat-chip-list>
              <mat-chip *ngFor="let tag of pilot.tags">{{tag}}</mat-chip>
            </mat-chip-list>
          </p>
        </div>

        <div class="ro-field">
          <p class="label">
            <mat-icon matSuffix>email</mat-icon>
            <span>{{'EMAIL' | translate}}</span>
          </p>
          <p class="value">{{ pilot.email }}</p>
        </div>

        <div class="ro-field">
          <p class="label">
            <mat-icon matSuffix>description</mat-icon>
            <span>{{'NAME' | translate}}</span>
          </p>
          <p class="value">{{ pilot.firstName }} {{ pilot.lastName }}</p>
        </div>

        <div class="ro-field">
          <p class="label">
            <mat-icon matSuffix>phone</mat-icon>
            <span>{{'PHONE' | translate}}</span>
          </p>
          <p class="value">{{ pilot.phone }}</p>
        </div>

        <div class="ro-field">
          <p class="label">
            <mat-icon matSuffix>location_on</mat-icon>
            <span>{{'ADDRESS' | translate}}</span>
          </p>
          <p class="value">{{ pilot.street }} {{ pilot.streetNumber }}</p>
          <p class="value">{{ pilot.city }} {{ pilot.zip }}</p>
          <p class="value">{{ pilot.country }}</p>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>
