export const CameraDictionaryObj = {
  EP800: {brand:"DJI",model:"Zenmuse L1"},
  FC1102: {brand:"DJI",model:"Spark"},
  FC200: {brand:"DJI",model:"Phantom 2 Vision"},
  FC220: {brand:"DJI",model:"Mavic Pro"},
  FC300C: {brand:"DJI",model:"Phantom 3"},
  FC300S: {brand:"DJI",model:"Phantom 3 Pro"},
  FC300SE: {brand:"DJI",model:"Phantom 3 Pro"},
  FC300X: {brand:"DJI",model:"Phantom 3 Pro"},
  FC300XW: {brand:"DJI",model:"Phantom 3 Adv"},
  FC330: {brand:"DJI",model:"Phantom 4"},
  FC350: {brand:"DJI",model:"X3"},
  FC350H: {brand:"DJI",model:"X3 OSMO"},
  FC350Z: {brand:"DJI",model:"OSMO zoom"},
  FC550: {brand:"DJI",model:"X5"},
  FC550RAW: {brand:"DJI",model:"X5RAW"},
  FC6310: {brand:"DJI",model:"Phantom 4 Pro"},
  FC6310S: {brand:"DJI",model:"Phantom 4 Pro v2"},
  FC6310R: {brand:"DJI",model:"Phantom 4 RTK"},
  FC6510: {brand:"DJI",model:"Zenmuse X4S"},
  FC6520: {brand:"DJI",model:"Zenmuse X5S"},
  FC6540: {brand:"DJI",model:"Zenmuse X7"},
  FC7303: {brand:"DJI",model:"Mini 2"},
  HG310: {brand:"DJI",model:"OSMO"},
  L1D20c: {brand:"DJI",model:"Mavic 2 Pro"},
  L2D20c: {brand: "DJI",model:"Mavic 3"},
  FC4170: {brand: "DJI",model:"Mavic 3 - Zoom"},
  ZenmuseP1: {brand:"DJI",model:"Zenmuse P1"},
  iXM100: {brand:"Phase One",model:"iXM-100"},
  MAVIC2ENTERPRISEADVANCED: {brand:"DJI",model:"Mavic 2 Enterprise Advanced"},
}