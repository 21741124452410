<div class="form">
  <h2 class="extra-margin-bottom">{{ 'COLLABORATION.POPUP.TITLE.'+((this.action === 'INVITE'? 'INVITE': 'MODIFY') + '_' + (this.selectedUsers?.length <= 1? 'COLLABORATOR': 'COLLABORATORS') + '_' + (this.selectedResource?.value | uppercase)) | translate }}: <span class="resource-name">{{ resource[selectedResource?.value]?.name }}</span></h2>

  <!-- Users Selection -->
  <div class="section">
    <mat-chip-list class="chip-list" #chipList>
      <mat-chip class="user-chip" *ngFor="let user of selectedUsers">
        {{ user.email }}
        <button class="chip-remove-button" (click)="removeUser(user)" mat-icon-button color="primary" >
          <mat-icon class="chip-remove-icon">cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
    <form [formGroup]="userForm">
      <mat-form-field class="user-form-field">
        <input matInput
          class="user-input"
          formControlName="userInput"
          [matAutocomplete]="auto"
          (input)="userChange($event.target.value)"
          (keydown)="handleKeydown($event)"
          placeholder="{{ 'COLLABORATION.POPUP.INPUT_EMAIL' | translate }}">
        <mat-error *ngIf="this.userInput.errors">{{ this.userInput.errors.invalid }}</mat-error>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addUserFromAutoComplete($event.option.value)">
        <mat-option *ngFor="let option of usersAutocompleteOptions" [value]="option.email">
          {{ option.email }}
          <span class="secondary-option-text">| {{ option.name }} {{ option.surname }}</span>
        </mat-option>
      </mat-autocomplete>
    </form>
    <!-- <div class="role-hint">* Non signed-up users will receive an invitation via email.</div> -->
  </div>

  <!-- Role Selection -->
  <div class="section">
    <div aria-label="Role selection" class="role-selection" [class.dark]="this.theme && this.theme.includes('dark')">
      <div class="role" *ngFor="let role of this.roles"
        (click)="selectRole(role)"
        [ngClass]="{'selected-role': role?.value === selectedRole?.value, 'unselected-role': role?.value !== selectedRole?.value}"
        [class.dark]="this.theme && this.theme.includes('dark')">
        {{ role.label }}
      </div>
    </div>
    <div class="role-hint" *ngIf="false">* {{ this.selectedRole.hint }}</div>
    <mat-checkbox *ngIf="this.selectedRole.value === 'coordinator'" [(ngModel)]="restriction">Restrict collaboration inheritance to organizational level.</mat-checkbox>
  </div>

  <!-- Resource -->
  <div class="section resource" *ngIf="false">
    <h3 class="title">Resource</h3>
    <mat-select class="mat-select-resource" [(value)]="selectedResource" (selectionChange)="selectResource($event.value)" [disabled]="true">
      <mat-select-trigger>
        <span class="label">{{ selectedResource?.label }}</span>
        <small class="hint">{{ selectedResource?.hint }}</small>
      </mat-select-trigger>
      <mat-option class="mat-option-resource" *ngFor="let option of collaborationResourceOptions" [value]="option">
        <span class="label-option">{{ option.label }}</span>
        <small class="hint-option">{{ option.hint }}</small>
      </mat-option>
    </mat-select>
  </div>

  <!-- notify collaborators check box -->
  <div class="section resource" [formGroup]="userForm">
      <mat-checkbox formControlName="notifyCollaborators">
        {{ 'COLLABORATION.POPUP.'+( 'NOTIFY_' + (this.selectedUsers?.length <= 1? 'COLLABORATOR': 'COLLABORATORS') ) | translate }}
      </mat-checkbox>
  </div>

  <!-- Expiration data only for admins -->
  <div class="section resource" *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType)">
    <h3 class="title">Expiration Date:</h3>
    <mat-form-field class="input-container" appearance="fill" [formGroup]="userForm">
      <mat-label>Pick a date </mat-label>
      <input matInput [matDatepicker]="picker" formControlName="expirationDate">
      <mat-hint *ngIf="this.expirationDate.value">This Collaboration will expire after {{this.expirationDate.value?.toISOString() | expireIn | translate }}</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <!-- Summary -->
  <div class="info-box" [class.dark]="this.theme && this.theme.includes('dark')">
    <mat-icon class="mat-icon-info">info</mat-icon>
    <div class="body">
      <span class="title">{{ 'COLLABORATION.POPUP.INFORMATION.TITLE' | translate }}:</span>
      <div class="content" [class.dark]="this.theme && this.theme.includes('dark')">
        {{ 'COLLABORATION.POPUP.INFORMATION.'+((this.selectedUsers?.length <= 1? 'USER': 'USERS') + '_' + (this.selectedRole?.value | uppercase ) + '_' + (this.selectedResource?.value | uppercase)) | translate }}
      </div>
    </div>
  </div>

  <!-- Action Buttons -->
  <div class="actions">
    <button class="button"
      (click)="dialogRef.close()"
      mat-stroked-button
      type="button">{{ 'CANCEL' | translate }}</button>
    <button class="button"
      (click)="addCollaborators()"
      color="accent"
      [disabled]="this.selectedUsers?.length === 0 && (!this.userInput.value || !!this.userInput.errors)"
      mat-raised-button
      [matTooltip]="'COLLABORATION.POPUP.TITLE.INVITE_BUTTON_TOOLTIP' | translate"
      [matTooltipPosition]="'above'"
      [matTooltipDisabled]="!(this.selectedUsers?.length === 0 && (!this.userInput.value || !!this.userInput.errors))">
      <mat-icon class="material-icons">add</mat-icon>
      {{ 'COLLABORATION.ACTION.'+((this.action === 'INVITE'? 'INVITE': 'MODIFY') + '_' + (this.selectedUsers?.length <= 1? 'COLLABORATOR': 'COLLABORATORS') ) | translate }}
    </button>
  </div>
</div>
