import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  @Input() fetching: boolean;
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() value = 0;

  constructor() {
    // nothing here
  }
}
