<div
    [class.withFooter]="formOpen"
    class="annotationsBody"
    [class.dark]="this.theme && this.theme.includes('dark')"
    #annotationsBody
    (scroll)="scrollDetector()"
    (click)="scrollDetector()">
  <div
      *ngIf="view && permissionsService.permissions$.value?.canUpdate.files"
      class="header">
    <div class="wrapper">
      <button *ngIf="!formOpen"
          mat-raised-button
          class="add"
          color="accent"
          (click)="newAnnotation()">
        <mat-icon class="rtl-mirror">add_location_alt</mat-icon>
      </button>

      <button *ngIf="!formOpen && checkSubscription()"
          mat-raised-button
          class="add"
          color="accent"
          (click)="newPolygon()">
        <mat-icon class="rtl-mirror">draw</mat-icon>
      </button>
    </div>

    <ng-form *ngIf="formOpen"
        [formGroup]="formAnnotationPano">
      <mat-form-field>
        <mat-label>{{ 'SIDEBAR.ANNOTATIONS_PANO.TOOLTIP' | translate }}</mat-label>

        <textarea
            formControlName="tooltip"
            cdkTextareaAutosize
            matInput
            (keyup)="updateTooltip($event)"></textarea>

        <mat-hint>{{ 'SIDEBAR.ANNOTATIONS_PANO.TOOLTIP_HINT' | translate }}</mat-hint>
      </mat-form-field>

      <div *ngIf="checkSubscription()"
          class="slider">
          <ng-container *ngIf="type === 'poi'">
            {{ 'SIDEBAR.ANNOTATIONS_PANO.TRANSPARENCY' | translate }}
          </ng-container>
          <ng-container *ngIf="type === 'polygon'">
            {{ 'SIDEBAR.ANNOTATIONS_PANO.TRANSPARENCY_FILL' | translate }}
          </ng-container>

        <mat-slider
            formControlName="alpha"
            thumbLabel="true"
            (input)="updateTransparency($event)"
            [max]="1.0"
            [min]="0"
            [step]="0.05"></mat-slider>
      </div>

      <div *ngIf="type === 'poi' && checkSubscription()"
          class="slider">
        {{ 'SIDEBAR.ANNOTATIONS_PANO.SCALE' | translate }}
        <mat-slider
            formControlName="scale"
            thumbLabel="true"
            (input)="updateScale($event)"
            [max]="1.75"
            [min]="0.5"
            [step]="0.05"></mat-slider>
      </div>

      <div
          *ngIf="checkSubscription() && this.poisTag !== '' && isCustomMarker()"
          class="mat-form-field-wrapper">
        <mat-slide-toggle
            formControlName="isCheckedCustomMarker"
            (change)="toggleCustomMarker()">Custom Hotspot</mat-slide-toggle>
      </div>

      <div *ngIf="checkSubscription()"
          class="mat-form-field-wrapper">
        <mat-slide-toggle formControlName="isCheckedOpenURL">{{ 'SIDEBAR.ANNOTATIONS_PANO.OPENURL_TOGGLE' | translate }}</mat-slide-toggle>
      </div>
      <mat-form-field *ngIf="checkSubscription() && isCheckedOpenURL()">
        <mat-label>{{ 'SIDEBAR.ANNOTATIONS_PANO.OPENURL' | translate }}</mat-label>

        <input
            formControlName="openURL"
            matInput>
        <mat-hint>{{ 'SIDEBAR.ANNOTATIONS_PANO.OPENURL_HINT' | translate }}</mat-hint>
      </mat-form-field>

      <div *ngIf="type === 'poi' && checkSubscription()"
          class="mat-form-field-wrapper">
        <mat-slide-toggle (click)="checkImageID()" formControlName="isCheckedImageURL">{{ 'SIDEBAR.ANNOTATIONS_PANO.IMAGEURL_TOGGLE' | translate }}</mat-slide-toggle>
      </div>
      <mat-form-field  class="hidden" *ngIf="checkSubscription() && isCheckedImageURL()">
        <mat-label>ImageID</mat-label>
        <input formControlName="imageID"
            (keyup)="updateImageURL($event)"
            matInput>
      </mat-form-field>

      <div class="poiImage" *ngIf="imageURLView">
        <button
            mat-fab
            (click)="selectFileDialog()">
          <mat-icon>edit</mat-icon>
        </button>
        <img
          alt="item.key"
          draggable="false"
          src="{{imageURLView}}">
      </div>

      <div *ngIf="checkSubscription() && !(customForm && customPoiActive)">
        {{ 'SIDEBAR.ANNOTATIONS_PANO.COLOR' | translate }}

        <div class="circleList">
            <span *ngFor="let colorPresent of colorsProposals"
                (click)="colorUpdate(colorPresent)"
                [class.selected]="colorPresent===color"
                [style.background]="colorPresent"
                class="circle"></span>
        </div>
        <span class="mat-hint" *ngIf="colorsUsed.length > 0">
          {{ 'SIDEBAR.ANNOTATIONS_PANO.COLOR_USED' | translate }}
        </span>
        <div class="circleList">
          <span *ngFor="let colorPresent of colorsUsed"
              (click)="colorUpdate(colorPresent)"
              [class.selected]="colorPresent===color"
              [style.background]="colorPresent"
              class="circle"></span>
        </div>
        <div class="center">
          <button *ngIf="!colorShowPicker"
              mat-button
              (click)="colorTogglePicker()"
              class="confirm show">{{ 'SIDEBAR.ANNOTATIONS_PANO.COLOR_ANOTHER' | translate }}</button>
        </div>


      </div>
      <div *ngIf="checkSubscription() && colorShowPicker">
        <span
            (cpInputChange)="colorUpdate($event.color)"
            (cpSliderDragEnd)="colorUpdate($event.color)"
            [(colorPicker)]="color"
            [cpAlphaChannel]="'disabled'"
            [cpCancelButton]="false"
            [cpDialogDisplay]="'inline'"
            [cpFallbackColor]="colorsProposals[0]"
            [cpOKButton]="false"
            [cpToggle]="true"
            [cpWidth]="'297px'"
            [style.background]="color"
            ></span>
      </div>

      <div class="accordion" *ngIf="type === 'poi'">
        <mat-accordion>

          <mat-expansion-panel
              *ngFor="let cluster of poisCluster"
              [expanded]="activePanel === cluster.name"
              hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{cluster.name}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hotspots">

              <div *ngFor="let item of cluster.list"
                  [class.selected] = "formAnnotationPano?.value.icon === item || formAnnotationPano?.value.icon === poisTag+':'+item"
                  class="hotspot"
                  matRipple>
                <img
                    (click)="updateIcon(item)"
                    alt="item.key"
                    draggable="false"
                    src="{{getIconURL(item)}}">
              </div>

            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
              *ngIf="poisCustom.length >= 1"
              [expanded]="activePanel === 'custom'"
              hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                custom
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hotspots">

              <div *ngFor="let item of poisCustom"
              [class.selected] = "formAnnotationPano?.value.icon === item || formAnnotationPano?.value.icon === poisTag+':'+item"
              class="hotspot"
              matRipple>
              <img
                    (click)="updateIconCustom(item)"
                    alt="item.key"
                    draggable="false"
                    src="{{getIconURL(item)}}">
              </div>

            </div>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </ng-form>

  </div>
  <div class="main">
    <div *ngIf="noAnnotation('')"
      class="noAnnotation">
    {{ 'SIDEBAR.ANNOTATIONS_PANO.NO' | translate | uppercase }}
    </div>
    <div *ngIf="fileModel && fileModel.pano && fileModel.pano.hotspots">
      <!-- POI -->
      <p *ngIf="!noAnnotation('poi')" class="listing">{{ 'SIDEBAR.ANNOTATIONS_PANO.ANNOTATIONS' | translate | uppercase }}:</p>
      <div class="annotation" *ngFor="let annotation of fileModel.pano.hotspots; let i = index;">
        <ng-container *ngIf="annotation.type === 'poi'">
          <div class="left">
            <img *ngIf="annotation.type === 'poi'"
                [style.background]="annotation.color"
                alt="lookTo"
                draggable="false"
                src="{{getIconURL(annotation.icon)}}"
                title="lookTo"
                (click)="lookTo(annotation.name)">
          </div>
          <div class="left text">
            {{annotation.tooltip}}
          </div>
          <button *ngIf="!formOpen && permissionsService.permissions$.value?.canUpdate.files"
              mat-icon-button
              class = "menu"
              [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
                *ngIf="view"
                mat-menu-item
                (click)="edit(i, true)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'EDIT' | translate }}</span>
            </button>
            <button
                mat-menu-item
                (click)="delete(i)">
              <mat-icon color="warn">delete</mat-icon>
              <span>{{ 'DELETE' | translate }}</span>
            </button>
          </mat-menu>
        </ng-container>
      </div>
      <p *ngIf="!noAnnotation('polygon')" class="listing">{{ 'SIDEBAR.ANNOTATIONS_PANO.POLYGONS' | translate | uppercase }}:</p>
      <!-- Polygon -->
      <div class="annotation" *ngFor="let annotation of fileModel.pano.hotspots; let i = index;">
        <!-- POI -->
        <ng-container *ngIf="annotation.type === 'polygon'">
          <div class="left">
            <div *ngIf="annotation.type === 'polygon'"
              (click)="lookTo(annotation.name)"
              [style.background-color]="polygonFillColor(annotation)"
              [style.border-color]="polygonBorderColor(annotation)"
              class="iconPolygon">
            </div>
          </div>
          <div class="left text">
            {{annotation.tooltip}}
          </div>
          <button *ngIf="!formOpen && permissionsService.permissions$.value?.canUpdate.files"
              mat-icon-button
              class = "menu"
              [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
                *ngIf="view && checkSubscription()"
                mat-menu-item
                (click)="edit(i, true)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'EDIT' | translate }}</span>
            </button>
            <button
                mat-menu-item
                (click)="delete(i)">
              <mat-icon color="warn">delete</mat-icon>
              <span>{{ 'DELETE' | translate }}</span>
            </button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>
  <div
      *ngIf="formOpen"
      class="footer">
    <div class="actions">
      <div
          *ngIf="!scrolled"
          class="scrollArrow">
        <mat-icon class="bounce">arrow_downward</mat-icon>
      </div>
      <button
          mat-raised-button
          class="confirm"
          color="accent"
          (click)="save()"><mat-icon class="rtl-mirror">send</mat-icon> {{ 'SAVE' | translate }}</button>
      <button
          mat-button
          class="confirm"
          (click)="abort()">{{ 'CANCEL' | translate }}</button>
    </div>
  </div>
</div>
