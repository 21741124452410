import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  constructor() { }

  exportToCsv(filename: string, data: any[]) {
    if (!Array.isArray(data)) {
      console.error('Data is not an array.');
      return;
    }
  
    let csvContent = "data:text/csv;charset=utf-8,";
  
    // Extract headers from the first object's keys
    const headers = ['Title', 'Type','Created At','Geometry Type', 'Area (m²)', 'Surface Area (m²)', 'Slope (°)', 'Volume Approach', 'Custom Elevation (m)', 'Volume (m³)', 'Elevations Max (m)', 'Elevations Min (m)', 'Elevations Difference (m)', 'Coordinates (GeoJSON x.y.z)' ]
    csvContent += headers.join(",") + "\n";
      // Extract data rows
      data.forEach((object) => {
        const values = headers.map((header) => {
          if (header === 'Geometry Type') {
            return object.location ? object.location.type : '';
          } else if (header === 'Coordinates (GeoJSON x.y.z)') {
            return object.location && object.location.coordinates ? `"${this.stringifyGeoJsonCoordinates(object.location.coordinates)}"` : '';
          } else if (header === 'Created At') {
            return object.createdAt ? object.createdAt : '';
          } else if (header === 'Area (m²)') {
            return object.value2d ? object.value2d.toFixed(3) : '';
          } else if (header === 'Title') {
            return object.title ? object.title : '';
          } else if (header === 'Type') {
            return object.type ? object.type.toUpperCase() : '';
          } else if (header === 'Surface Area (m²)') {
            return object.value3d ? object.value3d.toFixed(3) : '';
          } else if (header === 'Volume Approach') {
            return object.selectedVolumeApproach ? object.selectedVolumeApproach : '';
          } else if (header === 'Slope (°)') {
            return object.slope ? object.slope.toFixed(3) : '';
          } else if (header === 'Custom Elevation (m)') {
            return object.customElevation ? object.customElevation.toFixed(3) : '';
          } else if (header === 'Volume (m³)') {
            return object[object.selectedVolumeApproach] ? object[object.selectedVolumeApproach].toFixed(3) : '';
          } else if (header === 'Elevations Max (m)') {
            return object.elevationsMax ? object.elevationsMax.toFixed(3) : '';
          } else if (header === 'Elevations Min (m)') {
            return object.elevationsMin ? object.elevationsMin.toFixed(3) : '';
          } else if (header === 'Elevations Difference (m)') {
            return (object.elevationsMax-object.elevationsMin).toFixed(3);
          } else {
            return object[header] !== undefined ? object[header] : '';
          }
        });
        csvContent += values.join(",") + "\n";
      });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  // Function to stringify GeoJSON coordinates
 stringifyGeoJsonCoordinates(coordinates: any): string {
  if (!coordinates) return '';

  if (Array.isArray(coordinates) && Array.isArray(coordinates[0])) {
    // Array of arrays (e.g., MultiPoint, MultiLineString, MultiPolygon)
    return coordinates.map((coord) => JSON.stringify(coord)).join(';');
  } else if (Array.isArray(coordinates)) {
    // Simple array (e.g., Point, LineString, Polygon)
    return JSON.stringify(coordinates);
  } else {
    return JSON.stringify(coordinates);
  }
 } 
}
