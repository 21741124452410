<div class="container mat-typography" [dir]="dir">
  <div *ngFor="let accordion of accordions" class="mat-title">
    <h2 [id]="accordion.id">{{ 'FAQ.ACCORDIONS.' + accordion.key + '.TITLE' | translate }}</h2>
    <mat-accordion multi="true">
      <mat-expansion-panel *ngFor="let question of accordion.questions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'FAQ.ACCORDIONS.' + accordion.key + '.QUESTIONS.' + question.key + '.TITLE' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p
            [innerHtml]="'FAQ.ACCORDIONS.' + accordion.key + '.QUESTIONS.' + question.key + '.CONTENT' | translate"
            class="content">
        </p>

        <mat-action-row *ngIf="question.action">
          <a
              color="accent"
              draggable="false"
              mat-raised-button
              href="{{ 'FAQ.ACCORDIONS.' + accordion.key + '.QUESTIONS.' + question.key + '.HREF' | translate }}"
              rel="noopener"
              target="_blank">
            {{ 'FAQ.ACCORDIONS.' + accordion.key + '.QUESTIONS.' + question.key + '.ACTION' | translate | uppercase }}
          </a>
        </mat-action-row>

      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>
