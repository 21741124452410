import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

declare var Hammer: any;

@Injectable() export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): any {
    const mc = new Hammer(element);

    for (const eventName in this.overrides) {
      if (eventName) {
        mc.get(eventName).set(this.overrides[eventName]);
      }
    }

    return mc;
  }
}
