import { takeUntil } from 'rxjs/operators';
import { Viewer3dService } from './../../../../viewer/viewer3d/viewer3d.service';
import { FileModel } from './../../file.model';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Component, Inject, Input, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar-tiled3d',
  templateUrl: './tiled3d.component.html',
  styleUrls: ['./tiled3d.component.scss']
})
export class Tiled3dComponent implements OnChanges {

  @Input() fileModel: FileModel;
  private ngDestroy$ = new Subject();
  formTiled3d: FormGroup;
  model: any;
  edit: string;
  expandLocation = false;

  get maximumScreenSpaceError(): AbstractControl { return this.formTiled3d.get('maximumScreenSpaceError')}
  get positionX(): AbstractControl { return this.formTiled3d.get('positionX'); }
  get positionY(): AbstractControl { return this.formTiled3d.get('positionY'); }
  get positionZ(): AbstractControl { return this.formTiled3d.get('positionZ'); }
  get rotationX(): AbstractControl { return this.formTiled3d.get('rotationX'); }
  get rotationY(): AbstractControl { return this.formTiled3d.get('rotationY'); }
  get rotationZ(): AbstractControl { return this.formTiled3d.get('rotationZ'); }
  get scale(): AbstractControl { return this.formTiled3d.get('scale'); }

  constructor(
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    public viewer3dService: Viewer3dService,
  ) {

    this.viewer3dService.data3d$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if(data) {
        this.model = data;
        this.resetForm();
      }
    });

    this.formTiled3d = this.formBuilder.group({
      maximumScreenSpaceError: new FormControl({ value: '' }),
      positionX: new FormControl({ value: '' }),
      positionY: new FormControl({ value: '' }),
      positionZ: new FormControl({ value: '' }),
      rotationX: new FormControl({ value: '' }),
      rotationY: new FormControl({ value: '' }),
      rotationZ: new FormControl({ value: '' }),
      scale: new FormControl({ value: '' })
    });
  }

  ngOnChanges(): void {
    this.resetForm();
  }

  resetForm(): void {
    if(this.fileModel) {
      this.formTiled3d.reset({
        maximumScreenSpaceError: (this.fileModel.tiles3d?.maximumScreenSpaceError) ? (this.fileModel.tiles3d?.maximumScreenSpaceError) : (16),
        positionX: (this.fileModel.tiles3d?.position?.x) ? (this.fileModel.tiles3d?.position?.x) : ((this.model) ? (this.model.position.x) : (null)),
        positionY: (this.fileModel.tiles3d?.position?.y) ? (this.fileModel.tiles3d?.position?.y) : ((this.model) ? (this.model.position.y) : (null)),
        positionZ: (this.fileModel.tiles3d?.position?.z) ? (this.fileModel.tiles3d?.position?.z) : ((this.model) ? (this.model.position.z) : (null)),
        rotationX: (this.fileModel.tiles3d?.rotation?.x) ? (this.fileModel.tiles3d?.rotation?.x) : 0,
        rotationY: (this.fileModel.tiles3d?.rotation?.y) ? (this.fileModel.tiles3d?.rotation?.y) : 0,
        rotationZ: (this.fileModel.tiles3d?.rotation?.z) ? (this.fileModel.tiles3d?.rotation?.z) : 0,
        scale: (this.fileModel.tiles3d?.scale) ? (this.fileModel.tiles3d?.scale) : ((this.model) ? (this.model.scale.x) : (null))
      });
    }
  }

  updateLivePosition(): void {
    this.viewer3dService.tmpPosition$.next(
      {
        x: this.positionX.value,
        y: this.positionY.value,
        z: this.positionZ.value
      }
    )
  }

  updateLiveRotation(): void {
    this.viewer3dService.tmpRotation$.next(
      {
        x: this.rotationX.value,
        y: this.rotationY.value,
        z: this.rotationZ.value
      }
    )
  }

  save3dView(): void {
    this.viewer3dService.action$.next("save3dViewTiled");
  }
  
  set3dView(): void {
    this.viewer3dService.action$.next("set3dView");
  }

  resetPosition(): void {
    this.viewer3dService.tmpPosition$.next(null);
    this.viewer3dService.tmpRotation$.next(null);
    this.viewer3dService.tmpScale$.next(null);
    this.resetForm();
    this.positionY.setValue(0);
    this.scale.setValue(1);
  }

  updateLiveScale(): void {
    this.viewer3dService.tmpScale$.next(
      this.scale.value
    );
  }

  setLocation(): void {
    this.fileModel = this.viewer3dService.makeTiles3dObj(this.fileModel);
    if (this.maximumScreenSpaceError.value !== null && this.maximumScreenSpaceError.value !== 16) {
      this.fileModel.tiles3d.maximumScreenSpaceError = this.maximumScreenSpaceError.value;
    } else {
      delete this.fileModel.tiles3d.maximumScreenSpaceError;
    }
    
    if (this.scale.value !== null && this.scale.value !== 1) {
      this.fileModel.tiles3d.scale = this.scale.value;
    } else {
      delete this.fileModel.tiles3d.scale;
    }

    if (
        this.positionX.value !== null && this.positionX.value !== this.model.initialPosition.x ||
        this.positionY.value !== null && this.positionY.value !== this.model.initialPosition.y ||
        this.positionZ.value !== null && this.positionZ.value !== this.model.initialPosition.z
    ) {
      this.fileModel.tiles3d.position = {}

      if (this.positionX.value !== null && this.positionX.value !== this.model.initialPosition.x) {
        this.fileModel.tiles3d.position.x = this.positionX.value;
      }

      if (this.positionY.value !== null && this.positionY.value !== this.model.initialPosition.y) {
        this.fileModel.tiles3d.position.y = this.positionY.value;
      }

      if (this.positionZ.value !== null && this.positionZ.value !== this.model.initialPosition.z) {
        this.fileModel.tiles3d.position.z = this.positionZ.value;
      }
    } else {
      delete this.fileModel.tiles3d.position;
    }

    if (
        this.rotationX.value !== null && this.rotationX.value !== 0 ||
        this.rotationY.value !== null && this.rotationY.value !== 0 ||
        this.rotationZ.value !== null && this.rotationZ.value !== 0
    ) {
      this.fileModel.tiles3d.rotation = {}

      if (this.rotationX.value !== null && this.rotationX.value !== 0) {
        this.fileModel.tiles3d.rotation.x = this.rotationX.value;
      }

      if (this.rotationY.value !== null && this.rotationY.value !== 0) {
        this.fileModel.tiles3d.rotation.y = this.rotationY.value;
      }

      if (this.rotationZ.value !== null && this.rotationZ.value !== 0) {
        this.fileModel.tiles3d.rotation.z = this.rotationZ.value;
      }
    } else {
      delete this.fileModel.tiles3d.rotation;
    }
    this.toggleLocation();
    this.viewer3dService.setUpdate(this.fileModel, {tiles3d: this.fileModel.tiles3d})
  }

  setState(state: string, value: boolean): void {
    if (this.edit !== state || !value) {
      this.resetForm();
    }
    if (value) {
      this.edit = state;
    } else {
      this.edit = '';
    }
  }

  toggleLocation(): void {
    this.expandLocation = !this.expandLocation;
  }
}
