<mat-card class="example-card">
  <mat-card-content>
    <div class="stat-label">
      <h3>{{ 'USER.LAST_EARNINGS' | translate }}</h3>
      <p class="total-flights"><strong>{{ (inputData?.total | amount) | currency:'EUR':true}}</strong></p>
    </div>
    <div class="canvas-wrapper">
      <canvas #bar></canvas>
    </div>
  </mat-card-content>
</mat-card>
