import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private socket$: WebSocketSubject<any>;
  private token: string;
  isConnected: boolean = false;

  constructor(private loginStateService: LoginStateService) {
    // Getting the Auth Token and constructing socket URL
    this.loginStateService.login$.subscribe(login => {
      if (login) {
        this.token = login.token;
      }
    });
  }

  // Observable to receive messages
  getMessages(): Observable<any> {
    return this.socket$.asObservable();
  }

  // Send a message through the WebSocket
  send(message: any): void {
    this.socket$.next(message);
  }

  // Close the WebSocket and update status
  close(): void {
    this.socket$.complete();
    this.isConnected = false;
  }

  // connect the WebSocket and update status
  connect() {
    if (this.isConnected){
      this.unsubscribe()
      this.close();
    }
    const websocketUrl = environment.webSocketURL; 
    const websocketUrlWithToken = `${websocketUrl}?jwt=${this.token}`;
    this.socket$ = webSocket({
      url: websocketUrlWithToken,
      openObserver: {
        next: () => {
          this.isConnected = true;
          //console.log('WebSocket connection is open.');
        }
      },
      closeObserver: {
        next: () => {
          this.isConnected = false;
         // console.log('WebSocket connection is closed.');
        }
      },
      deserializer: (e) => JSON.parse(e.data),
      serializer: (value) => JSON.stringify(value),
    });
    
    this.isConnected = true;
    this.socket$.subscribe(
      () => {
       // console.log('exchange data');
      },
      (error) => {
        //console.error('WebSocket connection error:', error);
      },
      () => {
        this.isConnected = false;
       // console.log('Complete');
      }
    );
  }

  // Unsubscribe from the WebSocket
  unsubscribe() {
    this.socket$.unsubscribe();
    this.isConnected = false;
  }
}