
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable()
export class RegistrationService {

  constructor(
      private http: HttpClient
  ) { }

  register(registrationUser: RegistrationUserModel): Observable<Object> {
    return this.http.post(`${environment.apiPath}register`, registrationUser);
  }

  requestPasswordReset(email: string): Observable<Object> {
    return this.http.post(`${environment.apiPath}requestPasswordReset`, email);
  }

  validateEmail(email: string): Observable<Object> {
    return this.http
      .jsonp('https://api.mailgun.net/v3/address/validate?address=' +
      `${email}&api_key=${environment.mailgunPublicKey}&callback=JSONP_CALLBACK`, 'JSONP_CALLBACK');
  }

}

export class RegistrationUserModel {
  email: string;
  name: string;
  password: string;
  phone: number;
}
