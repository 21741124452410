import de from '../../../assets/i18n/de.json';
import en from '../../../assets/i18n/en.json';
import it from '../../../assets/i18n/it.json';
import es from '../../../assets/i18n/es.json';

// feature/FF-4405-newAnnotationTypes
// must be an Object to sort the value in the current language alphabetical
const antennaDe = de.ANNOTATION.INSPECTION.antenna;
const antennaEn = en.ANNOTATION.INSPECTION.antenna;
const antennaIt = it.ANNOTATION.INSPECTION.antenna;
const antennaEs = es.ANNOTATION.INSPECTION.antenna;

const antennaInventoryDe = de.ANNOTATION.INSPECTION.antennaInventory;
const antennaInventoryEn = en.ANNOTATION.INSPECTION.antennaInventory;
const antennaInventoryIt = it.ANNOTATION.INSPECTION.antennaInventory;
const antennaInventoryEs = es.ANNOTATION.INSPECTION.antennaInventory;

const powerlineDe = de.ANNOTATION.INSPECTION.powerline
const powerlineEn = en.ANNOTATION.INSPECTION.powerline
const powerlineIt = it.ANNOTATION.INSPECTION.powerline
const powerlineEs = es.ANNOTATION.INSPECTION.powerline

const propertyDe = de.ANNOTATION.INSPECTION.property
const propertyEn = en.ANNOTATION.INSPECTION.property
const propertyIt = it.ANNOTATION.INSPECTION.property
const propertyEs = es.ANNOTATION.INSPECTION.property

const pvDe = de.ANNOTATION.INSPECTION.pv
const pvEn = en.ANNOTATION.INSPECTION.pv
const pvIt = it.ANNOTATION.INSPECTION.pv
const pvEs = es.ANNOTATION.INSPECTION.pv

const oldDe = de.ANNOTATION.INSPECTION.old
const oldEn = en.ANNOTATION.INSPECTION.old
const oldIt = it.ANNOTATION.INSPECTION.old
const oldEs = es.ANNOTATION.INSPECTION.old

const generalDe = de.ANNOTATION.INSPECTION.general
const generalEn = en.ANNOTATION.INSPECTION.general
const generalIt = it.ANNOTATION.INSPECTION.general
const generalEs = es.ANNOTATION.INSPECTION.general

const stateDimensionDE = de.ANNOTATION.INSPECTION.AnnotationStateDimensionObj
const stateDimensionEn = en.ANNOTATION.INSPECTION.AnnotationStateDimensionObj
const stateDimensionIt = it.ANNOTATION.INSPECTION.AnnotationStateDimensionObj
const stateDimensionEs = es.ANNOTATION.INSPECTION.AnnotationStateDimensionObj

const imageTypeDE = de.FILE.IMAGETYPE
const imageTypeEn = en.FILE.IMAGETYPE
const imageTypeIt = it.FILE.IMAGETYPE
const imageTypeEs = es.FILE.IMAGETYPE

const serialNumberDE = de.ANNOTATION.INSPECTION.serialNumber;
const serialNumberEn = en.ANNOTATION.INSPECTION.serialNumber;
const serialNumberIt = it.ANNOTATION.INSPECTION.serialNumber;
const serialNumberEs = es.ANNOTATION.INSPECTION.serialNumber;

export const AnnotationTypesObj = {
  antenna: {
    corrosion: { de: antennaDe.corrosion, en: antennaEn.corrosion, it: antennaIt.corrosion, es: antennaEs.corrosion },
    cotter_pin_issue: { de: antennaDe.cotter_pin_issue, en: antennaEn.cotter_pin_issue, it: antennaIt.cotter_pin_issue, es: antennaEs.cotter_pin_issue },
    crack: { de: antennaDe.crack, en: antennaEn.crack, it: antennaIt.crack, es: antennaEs.crack },
    hail_damage: { de: antennaDe.hail_damage, en: antennaEn.hail_damage, it: antennaIt.hail_damage, es: antennaEs.hail_damage },
    lightning_strike: { de: antennaDe.lightning_strike, en: antennaEn.lightning_strike, it: antennaIt.lightning_strike, es: antennaEs.lightning_strike },
    vegetation: { de: antennaDe.vegetation, en: antennaEn.vegetation, it: antennaIt.vegetation, es: antennaEs.vegetation },
    wear_and_tear: { de: antennaDe.wear_and_tear, en: antennaEn.wear_and_tear, it: antennaIt.wear_and_tear, es: antennaEs.wear_and_tear },
    wire_issue: { de: antennaDe.wire_issue, en: antennaEn.wire_issue, it: antennaIt.wire_issue, es: antennaEs.wire_issue }
  },
  antennaInventory: {
    huaweiAHP4518R5v06: { de: antennaInventoryDe.huaweiAHP4518R5v06, en: antennaInventoryEn.huaweiAHP4518R5v06, it: antennaInventoryIt.huaweiAHP4518R5v06, es: antennaInventoryEs.huaweiAHP4518R5v06 },
    huawei5GmassiveMIMO: { de: antennaInventoryDe.huawei5GmassiveMIMO, en: antennaInventoryEn.huawei5GmassiveMIMO, it: antennaInventoryIt.huawei5GmassiveMIMO, es: antennaInventoryEs.huawei5GmassiveMIMO },
    labelDeutscheTelekom: { de: antennaInventoryDe.labelDeutscheTelekom, en: antennaInventoryEn.labelDeutscheTelekom, it: antennaInventoryIt.labelDeutscheTelekom, es: antennaInventoryEs.labelDeutscheTelekom },
    labelTelefonica: { de: antennaInventoryDe.labelTelefonica, en: antennaInventoryEn.labelTelefonica, it: antennaInventoryIt.labelTelefonica, es: antennaInventoryEs.labelTelefonica },
    labelVodafone: { de: antennaInventoryDe.labelVodafone, en: antennaInventoryEn.labelVodafone, it: antennaInventoryIt.labelVodafone, es: antennaInventoryEs.labelVodafone },
    microwaveDish: { de: antennaInventoryDe.microwaveDish, en: antennaInventoryEn.microwaveDish, it: antennaInventoryIt.microwaveDish, es: antennaInventoryEs.microwaveDish }
  },
  powerline: {
    aerial_marker_ball: { de: powerlineDe.aerial_marker_ball, en: powerlineEn.aerial_marker_ball, it: powerlineIt.aerial_marker_ball, es: powerlineEs.aerial_marker_ball },
    conductor_issue: { de: powerlineDe.conductor_issue, en: powerlineEn.conductor_issue, it: powerlineIt.conductor_issue, es: powerlineEs.conductor_issue },
    corona_discharge: { de: powerlineDe.corona_discharge, en: powerlineEn.corona_discharge, it: powerlineIt.corona_discharge, es: powerlineEs.corona_discharge },
    corrosion: { de: powerlineDe.corrosion, en: powerlineEn.corrosion, it: powerlineIt.corrosion, es: powerlineEs.corrosion },
    cotter_pin_issue: { de: powerlineDe.cotter_pin_issue, en: powerlineEn.cotter_pin_issue, it: powerlineIt.cotter_pin_issue, es: powerlineEs.cotter_pin_issue },
    crack: { de: powerlineDe.crack, en: powerlineEn.crack, it: powerlineIt.crack, es: powerlineEs.crack },
    insulator_issue: { de: powerlineDe.insulator_issue, en: powerlineEn.insulator_issue, it: powerlineIt.insulator_issue, es: powerlineEs.insulator_issue },
    lightning_strike: { de: powerlineDe.lightning_strike, en: powerlineEn.lightning_strike, it: powerlineIt.lightning_strike, es: powerlineEs.lightning_strike },
    vegetation: { de: powerlineDe.vegetation, en: powerlineEn.vegetation, it: powerlineIt.vegetation, es: powerlineEs.vegetation },
    wear_and_tear: { de: powerlineDe.wear_and_tear, en: powerlineEn.wear_and_tear, it: powerlineIt.wear_and_tear, es: powerlineEs.wear_and_tear },
    damage_base_foundation: { de: powerlineDe.damage_base_foundation, en: powerlineEn.damage_base_foundation, it: powerlineIt.damage_base_foundation, es: powerlineEs.damage_base_foundation},
    damage_grounding_connections: { de: powerlineDe.damage_grounding_connections, en: powerlineEn.damage_grounding_connections, it: powerlineIt.damage_grounding_connections, es: powerlineEs.damage_grounding_connections},
    inclined_pole: { de: powerlineDe.inclined_pole, en: powerlineEn.inclined_pole, it: powerlineIt.inclined_pole, es: powerlineEs.inclined_pole},
    bent_structure: { de: powerlineDe.bent_structure, en: powerlineEn.bent_structure, it: powerlineIt.bent_structure, es: powerlineEs.bent_structure},
    damage_mast: { de: powerlineDe.damage_mast, en: powerlineEn.damage_mast, it: powerlineIt.damage_mast, es: powerlineEs.damage_mast},
    objects_pole: { de: powerlineDe.objects_pole, en: powerlineEn.objects_pole, it: powerlineIt.objects_pole, es: powerlineEs.objects_pole},
    lose_screw: { de: powerlineDe.lose_screw, en: powerlineEn.lose_screw, it: powerlineIt.lose_screw, es: powerlineEs.lose_screw},
    missing_component: { de: powerlineDe.missing_component, en: powerlineEn.missing_component, it: powerlineIt.missing_component, es: powerlineEs.missing_component},
    carbonization_traces: { de: powerlineDe.carbonization_traces, en: powerlineEn.carbonization_traces, it: powerlineIt.carbonization_traces, es: powerlineEs.carbonization_traces},
    hole: { de: powerlineDe.hole, en: powerlineEn.hole, it: powerlineIt.hole, es: powerlineEs.hole},
    repaired_spot: { de: powerlineDe.repaired_spot, en: powerlineEn.repaired_spot, it: powerlineIt.repaired_spot, es: powerlineEs.repaired_spot},
    defective_rings: { de: powerlineDe.defective_rings, en: powerlineEn.defective_rings, it: powerlineIt.defective_rings, es: powerlineEs.defective_rings},
    angled_cable: { de: powerlineDe.angled_cable, en: powerlineEn.angled_cable, it: powerlineIt.angled_cable, es: powerlineEs.angled_cable},
    non_compliant_mounting: { de: powerlineDe.non_compliant_mounting, en: powerlineEn.non_compliant_mounting, it: powerlineIt.non_compliant_mounting, es: powerlineEs.non_compliant_mounting},
    damage_ascender: { de: powerlineDe.damage_ascender, en: powerlineEn.damage_ascender, it: powerlineIt.damage_ascender, es: powerlineEs.damage_ascender},
    damage_fall_protection: { de: powerlineDe.damage_fall_protection, en: powerlineEn.damage_fall_protection, it: powerlineIt.damage_fall_protection, es: powerlineEs.damage_fall_protection},
    mast_sign_issue: { de: powerlineDe.mast_sign_issue, en: powerlineEn.mast_sign_issue, it: powerlineIt.mast_sign_issue, es: powerlineEs.mast_sign_issue},
    damage_cable: { de: powerlineDe.damage_cable, en: powerlineEn.damage_cable, it: powerlineIt.damage_cable, es: powerlineEs.damage_cable},
    press_connector_span: { de: powerlineDe.press_connector_span, en: powerlineEn.press_connector_span, it: powerlineIt.press_connector_span, es: powerlineEs.press_connector_span},
    alien_object_cable: { de: powerlineDe.alien_object_cable, en: powerlineEn.alien_object_cable, it: powerlineIt.alien_object_cable, es: powerlineEs.alien_object_cable},
    damage_power_line_loop: { de: powerlineDe.damage_power_line_loop, en: powerlineEn.damage_power_line_loop, it: powerlineIt.damage_power_line_loop, es: powerlineEs.damage_power_line_loop},
    vibration_damper_issue: { de: powerlineDe.vibration_damper_issue, en: powerlineEn.vibration_damper_issue, it: powerlineIt.vibration_damper_issue, es: powerlineEs.vibration_damper_issue},
    spacer_issue: { de: powerlineDe.spacer_issue, en: powerlineEn.spacer_issue, it: powerlineIt.spacer_issue, es: powerlineEs.spacer_issue}
  },
  property: {
    corrosion: { de: propertyDe.corrosion, en: propertyEn.corrosion, it: propertyIt.corrosion, es: propertyEs.corrosion },
    crack: { de: propertyDe.crack, en: propertyEn.crack, it: propertyIt.crack, es: propertyEs.crack },
    defective_skylight: { de: propertyDe.defective_skylight, en: propertyEn.defective_skylight, it: propertyIt.defective_skylight, es: propertyEs.defective_skylight },
    defective_tile: { de: propertyDe.defective_tile, en: propertyEn.defective_tile, it: propertyIt.defective_tile, es: propertyEs.defective_tile },
    defective_window: { de: propertyDe.defective_window, en: propertyEn.defective_window, it: propertyIt.defective_window, es: propertyEs.defective_window },
    felt_defect: { de: propertyDe.felt_defect, en: propertyEn.felt_defect, it: propertyIt.felt_defect, es: propertyEs.felt_defect },
    gutter_issue: { de: propertyDe.gutter_issue, en: propertyEn.gutter_issue, it: propertyIt.gutter_issue, es: propertyEs.gutter_issue },
    hail_damage: { de: propertyDe.hail_damage, en: propertyEn.hail_damage, it: propertyIt.hail_damage, es: propertyEs.hail_damage },
    missing_tile: { de: propertyDe.missing_tile, en: propertyEn.missing_tile, it: propertyIt.missing_tile, es: propertyEs.missing_tile },
    wear_and_tear: { de: propertyDe.wear_and_tear, en: propertyEn.wear_and_tear, it: propertyIt.wear_and_tear, es: propertyEs.wear_and_tear }
  },
  pv: {
    bypass_diode: { de: pvDe.bypass_diode, en: pvEn.bypass_diode, it: pvIt.bypass_diode, es: pvEs.bypass_diode },
    corrosion: { de: pvDe.corrosion, en: pvEn.corrosion, it: pvIt.corrosion, es: pvEs.corrosion },
    diode_issue: { de: pvDe.diode_issue, en: pvEn.diode_issue, it: pvIt.diode_issue, es: pvEs.diode_issue },
    glass_breakage: { de: pvDe.glass_breakage, en: pvEn.glass_breakage, it: pvIt.glass_breakage, es: pvEs.glass_breakage },
    hot_spot_cell: { de: pvDe.hot_spot_cell, en: pvEn.hot_spot_cell, it: pvIt.hot_spot_cell, es: pvEs.hot_spot_cell },
    reverted_polarity: { de: pvDe.reverted_polarity, en: pvEn.reverted_polarity, it: pvIt.reverted_polarity, es: pvEs.reverted_polarity },
    missing_module: { de: pvDe.missing_module, en: pvEn.missing_module, it: pvIt.missing_module, es: pvEs.missing_module },
    module_issue: { de: pvDe.module_issue, en: pvEn.module_issue, it: pvIt.module_issue, es: pvEs.module_issue },
    Multiple_bypass_diode: { de: pvDe.Multiple_bypass_diode, en: pvEn.Multiple_bypass_diode, it: pvIt.Multiple_bypass_diode, es: pvEs.Multiple_bypass_diode },
    multiple_hot_spot_cell : { de: pvDe.multiple_hot_spot_cell, en: pvEn.multiple_hot_spot_cell, it: pvIt.multiple_hot_spot_cell, es: pvEs.multiple_hot_spot_cell },
    possible_pid : { de: pvDe.possible_pid, en: pvEn.possible_pid, it: pvIt.possible_pid, es: pvEs.possible_pid },
    shading: { de: pvDe.shading, en: pvEn.shading, it: pvIt.shading, es: pvEs.shading },
    soiling: { de: pvDe.soiling, en: pvEn.soiling, it: pvIt.soiling, es: pvEs.soiling },
    string_issue: { de: pvDe.string_issue, en: pvEn.string_issue, it: pvIt.string_issue, es: pvEs.string_issue }
  },
  serialNumber: {
    serial_number: { de: serialNumberDE.serial_number, en: serialNumberEn.serial_number, it: serialNumberIt.serial_number, es: serialNumberEs.serial_number },
  },
  old: {
    broken_tile: { de: oldDe.broken_tile, en: oldEn.broken_tile, it: oldIt.broken_tile, es: oldEs.broken_tile },
    missing_tile: { de: oldDe.missing_tile, en: oldEn.missing_tile, it: oldIt.missing_tile, es: oldEs.missing_tile },
    loose_tile: { de: oldDe.loose_tile, en: oldEn.loose_tile, it: oldIt.loose_tile, es: oldEs.loose_tile },
    corrosion: { de: oldDe.corrosion, en: oldEn.corrosion, it: oldIt.corrosion, es: oldEs.corrosion },
    debris: { de: oldDe.debris, en: oldEn.debris, it: oldIt.debris, es: oldEs.debris },
    vegetation: { de: oldDe.vegetation, en: oldEn.vegetation, it: oldIt.vegetation, es: oldEs.vegetation },
    leak: { de: oldDe.leak, en: oldEn.leak, it: oldIt.leak, es: oldEs.leak },
    water: { de: oldDe.water, en: oldEn.water, it: oldIt.water, es: oldEs.water },
    missing_object: { de: oldDe.missing_object, en: oldEn.missing_object, it: oldIt.missing_object, es: oldEs.missing_object },
    damage: { de: oldDe.damage, en: oldEn.damage, it: oldIt.damage, es: oldEs.damage },
    crack: { de: oldDe.crack, en: oldEn.crack, it: oldIt.crack, es: oldEs.crack },
    rust: { de: oldDe.rust, en: oldEn.rust, it: oldIt.rust, es: oldEs.rust },
    hole: { de: oldDe.hole, en: oldEn.hole, it: oldIt.hole, es: oldEs.hole },
    fire_damage: { de: oldDe.fire_damage, en: oldEn.fire_damage, it: oldIt.fire_damage, es: oldEs.fire_damage }
  },
  general: {
    advisory_note: { de: generalDe.advisory_note, en:generalEn.advisory_note, it: generalIt.advisory_note, es: generalEs.advisory_note },
    alien_object: { de: generalDe.alien_object, en:generalEn.alien_object, it: generalIt.alien_object, es: generalEs.alien_object },
    damage: { de: generalDe.damage, en:generalEn.damage, it: generalIt.damage, es: generalEs.damage },
    defect_caused_by_animal: { de: generalDe.defect_caused_by_animal, en:generalEn.defect_caused_by_animal, it: generalIt.defect_caused_by_animal, es: generalEs.defect_caused_by_animal },
    fire_damage: { de: generalDe.fire_damage, en:generalEn.fire_damage, it: generalIt.fire_damage, es: generalEs.fire_damage },
    loose_object: { de: generalDe.loose_object, en:generalEn.loose_object, it: generalIt.loose_object, es: generalEs.loose_object },
    missing_object: { de: generalDe.missing_object, en:generalEn.missing_object, it: generalIt.missing_object, es: generalEs.missing_object },
    soiling: { de: generalDe.soiling, en:generalEn.soiling, it: generalIt.soiling, es: generalEs.soiling },
    vandalism: { de: generalDe.vandalism, en:generalEn.vandalism, it: generalIt.vandalism, es: generalEs.vandalism },
    vegetation: { de: generalDe.vegetation, en:generalEn.vegetation, it: generalIt.vegetation, es: generalEs.vegetation },
    water_ponding: { de: generalDe.water_ponding, en:generalEn.water_ponding, it: generalIt.water_ponding, es: generalEs.water_ponding }
  },
};

export const AnnotationStateDimensionObj = {
  advisory: { value: 20, de: stateDimensionDE.advisory, en: stateDimensionEn.advisory, it: stateDimensionIt.advisory, es: stateDimensionEs.advisory },
  low: { value: 40, de: stateDimensionDE.low, en: stateDimensionEn.low, it: stateDimensionIt.low, es: stateDimensionEs.low },
  middle: { value: 60, de: stateDimensionDE.middle, en: stateDimensionEn.middle, it: stateDimensionIt.middle, es: stateDimensionEs.middle },
  high: { value: 80, de: stateDimensionDE.high, en: stateDimensionEn.high, it: stateDimensionIt.high, es: stateDimensionEs.high },
  urgent: { value: 100, de: stateDimensionDE.urgent, en: stateDimensionEn.urgent, it: stateDimensionIt.urgent, es: stateDimensionEs.urgent },
};

export const ImageTypeObj = {
  thermal: { de: imageTypeDE.THERMAL, en: imageTypeEn.THERMAL, it: imageTypeIt.THERMAL, es: imageTypeEs.THERMAL },
  zoomed: { de: imageTypeDE.ZOOMED, en: imageTypeEn.ZOOMED, it: imageTypeIt.ZOOMED, es: imageTypeEs.ZOOMED },
  wide: { de: imageTypeDE.WIDE, en: imageTypeEn.WIDE, it: imageTypeIt.WIDE, es: imageTypeEs.WIDE },
};

export function SortArray(
  annotationTypes: Array<any>,
  currentLang: string
): Array<any> {
  if (currentLang && annotationTypes && annotationTypes.length > 0) {
    return annotationTypes.sort((x, y) => {
      if (x[currentLang].toLowerCase() < y[currentLang].toLowerCase()) {
        return -1;
      }
      if (x[currentLang].toLowerCase() > y[currentLang].toLowerCase()) {
        return 1;
      }
      return 0;
    });
  } else {
    return [];
  }
}
