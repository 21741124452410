import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Collaboration } from './collaboration.model';
import { environment } from 'src/environments/environment';
import { searchToFilter, userSearchFilter } from 'src/app/shared/helpers/data-helpers';
import { UsersService } from 'src/app/pages/users/users.service';
import { LoginStateService } from 'src/app/pages/login/login-state.service';


@Injectable({
  providedIn: 'root'
})
export class CollaborationsService {
  newCollaborations$: BehaviorSubject<Collaboration[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient,
    private usersService: UsersService,
    private loginStateService: LoginStateService
  ) {} // HttpClient is injected here

  constructFilter(resourceType?: string, resourceId?: string, subjectId?: string, sharedById?: string, subjectType?: string): any {
    let filter: any = {};

    if (resourceId || resourceType) {
        filter.resource = {};
        if (resourceType) filter.resource.type = resourceType;
        if (resourceId) filter.resource.id = resourceId;
    }

    if (sharedById) {
        filter.sharedBy = sharedById;
    }

    if (subjectId || subjectType) {
      filter.subject = {};
      if (subjectType) filter.subject.type = subjectType;
      if (subjectId) filter.subject.id = subjectId;
    }

    return filter;
  }

  enrichCollaborationsWithUsersData(collaborations: Collaboration[]): Collaboration[] {
    if (!collaborations) {
      return [];
    }
    // Extract user IDs
    const subjectUserIds = collaborations
      .filter(collab => collab.subject?.type === 'user')
      .map(collab => collab.subject?.id);

    const sharedByUserIds = collaborations
    .map(collab => collab.sharedBy);

    const combinedUserIds = [...subjectUserIds, ...sharedByUserIds];

    // Remove duplicates
    const userIds = 'id:' + [...new Set(combinedUserIds)].join(',');
    const filter = userSearchFilter(userIds);
    const fields = { avatarIconURL: 1, email: 1, name: 1, surname:1};
    if (userIds.length) {
      // Fetch user data for the IDs
      this.usersService.findMany(filter, null, null, null, fields).subscribe(
        (users) => {
          if (users.data) {
            // Enrich 'subject' with user info
            collaborations.forEach(collab => {
              if(collab.subject?.type === 'user') {
                const userInfo = users.data.find(user => user._id === collab.subject?.id);
                if (userInfo) {
                  collab.subject['email'] = userInfo.email;
                  collab.subject['name'] = userInfo.name;
                  collab.subject['surname'] = userInfo.surname;
                  collab.subject['avatarIconURL'] = userInfo.avatarIconURL;
                }
              }

              // Enrich 'sharedBy' with user info
              const sharedUserInfo = users.data.find(user => user._id === collab.sharedBy);
              if (sharedUserInfo) {
                collab.sharedBy = {
                  _id: sharedUserInfo._id,
                  label: sharedUserInfo._id === this.loginStateService.loggedUser$.value._id ? "You" : `${sharedUserInfo.name} ${sharedUserInfo.surname}`
                };
              }
            });
          }
        },
        (error) => {
          console.error('Failed to fetch user data', error);
        }
      );
    }

   return collaborations
  }

  // Fech collaborator for this specific resource
  getCollaborations(filter: Object = {}): Observable<any> {
    const filterString = JSON.stringify(filter);
    const params = new HttpParams().set('filter', filterString);
    return this.http.get<Collaboration[]>(`${environment.apiPath}collaborations`, { params });
  }

  deleteOne(id: string): Observable<any> {
    return this.http.delete<Collaboration>(`${environment.apiPath}collaborations/${id}`);
  }

  updateOne(id: string): Observable<any> {
    return this.http.patch<Collaboration>(`${environment.apiPath}collaborations/${id}`, {});
  }

  //it can be insertOne or Many, it depends on how many emails you pass
  insert(collaborationReqBody) {
    return this.http.post<Collaboration>(`${environment.apiPath}collaborations`, collaborationReqBody);
  }

  pushNewCollaborations(newCollaborations: Collaboration[]) {
    this.newCollaborations$.next(newCollaborations)
  }

}
