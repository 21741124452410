<form class="login-form">

  <mat-form-field>
    <mat-label>{{ 'EMAIL' | translate }}</mat-label>
    <input
        autocomplete="email"
        [disabled]="fetching"
        matInput
        name="login"
        [(ngModel)]="credentials.email"
        type="email">
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
    <input
        autocomplete="current-password"
        [disabled]="fetching"
        matInput
        [(ngModel)]="credentials.password"
        name="password"
        type="{{ showPassword ? 'text' : 'password' }}">

    <button
        (click)="toggleShowPassword($event)"
        [disabled]="fetching"
        mat-icon-button
        matSuffix
        title="{{ (showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD') | translate }}"
        type="button">
      <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>
  </mat-form-field>

  <button
      (click)="login()"
      color="accent"
      [disabled]="fetching"
      mat-raised-button>{{ 'LOGIN' | translate }}</button>

<!--   <button
      [disabled]="fetching"
      mat-stroked-button
      routerLink="/register"
      type="button">{{ 'REGISTER' | translate }}</button>

  <button
      [disabled]="fetching"
      mat-button
      routerLink="/forgot-password"
      type="button">{{ 'FORGOT_PASSWORD' | translate }}</button> -->

  <a
      [disabled]="fetching"
      draggable="false"
      mat-stroked-button
      href="https://fairfleet360.com/client/signup">{{ 'REGISTER' | translate }}</a>

  <a
      [disabled]="fetching"
      draggable="false"
      mat-button
      href="https://fairfleet360.com/client/password/reset">{{ 'FORGOT_PASSWORD' | translate }}</a>

  <div class="divider">
    <hr>
    <span>{{ 'OR' | translate }}</span>
    <hr>
  </div>

  <a
      [disabled]="fetching"
      draggable="false"
      mat-stroked-button
      (click)="openDialog()">{{ 'LOGIN_OPTIONS' | translate }}</a>
</form>
