// src/app/tasks/tasks.service.ts

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Task } from './task/task.model'; 
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TasksService {
  private baseUrl = `${environment.apiPath}tasks`;

  constructor(private http: HttpClient) {}

  insertOne(task: Task): Observable<Task> {
    return this.http.post<{ data: Task }>(`${this.baseUrl}`, task).pipe(
      map(response => response.data)
    );
  }

  insertMany(tasks: Task[]): Observable<Task[]> {
    return this.http.post<Task[]>(`${this.baseUrl}/create/many`, { tasks });
  }

  findOne(taskId: string): Observable<Task> {
    return this.http.get<{ data: Task }>(`${this.baseUrl}/${taskId}`).pipe(
      map(response => response.data)
    );
  }

  findMany(): Observable<Task[]> {
    return this.http.get<{data: Task[]}>(`${this.baseUrl}`).pipe(
      map(response => response.data)
    );
  }

  updateOne(taskId: string, updatedTask: Partial<Task>): Observable<Task> {
    return this.http.patch<Task>(`${this.baseUrl}/${taskId}`, updatedTask);
  }

  deleteOne(taskId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${taskId}`);
  }

  deleteMany(taskIds: string[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/delete/many`, { taskIds });
  }

  archiveOne(taskId: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${taskId}/archive`, {});
  }

  archiveMany(taskIds: string[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/archive/many`, { taskIds });
  }
}
