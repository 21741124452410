import { Injectable } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';

@Injectable()
export class SidenavContentService {

  nativeElement: HTMLElement;

  private sidenav: MatSidenavContent;

  /**
   * Setter for sidenav.
   */
  setSidenav(sidenav: any): void {
    this.sidenav = sidenav;
    this.nativeElement = sidenav.elementRef.nativeElement;
  }
}
