// src/app/tasks/task.model.ts

import { Site } from "src/app/shared/portfolio/portfolio.model";
import { Annotation } from "../../files/file/sidebar/annotations/annotation.model";
import { Measurement } from "../../files/file/sidebar/measurements/measurement.model";
import { FileModel } from "../../files/file/file.model";
import { Order } from "src/app/shared/orders/orders.model";
import { User } from "../../users/models/user.model";

export type LinkedResourceType = 'annotation' | 'measurement' | 'file' | 'order' | 'site';
export type UrgencyType = 'high' | 'medium' | 'low';
export type StatusType = 'to_do' | 'in_progress' | 'review' | 'done';

export interface LinkedResource {
  type?: LinkedResourceType;
  resource?: Site | Annotation | Measurement | FileModel | Order | string | undefined
}

export const urgencies: UrgencyType[] = ['high', 'medium', 'low']
export const statuses: StatusType[] = ['to_do', 'in_progress', 'review', 'done']

export interface Task {
  _id?: string;
  title?: string;
  description?: string;
  linkedResources?: LinkedResource[];
  assigneeEmail?: string;
  assigneeId?: string;
  assignee?: User;
  creator?: User;
  creatorId?: string;
  urgency?: UrgencyType;
  status?: StatusType;
  createdAt?: Date;
  modifiedAt?: Date;
  deadline?: string;
  archived?: boolean;
  orderId?: string;
}
