<div
    class="not-found"
    [class.dark]="this.theme && this.theme.includes('dark')">
  <div alt="FairFleet broken drone" class="broken-logo"></div>
  <h1>{{ '404.TITLE' | translate }}</h1>
  <h2>{{ '404.P1' | translate }}</h2>
  {{ '404.P2' | translate }}
  <ul>
    <li><a href="https://fairfleet360.com/">{{ '404.LINKS.HOME' | translate }}</a></li>
    <li><a href="https://fairfleet360.com/client/signin">{{ '404.LINKS.CLIENT-SIGNIN' | translate }}</a></li>
    <li><a href="https://fairfleet360.com/pilot/signin">{{ '404.LINKS.PILOT-SIGNIN' | translate }}</a></li>
    <li><a routerLink="/faq">{{ '404.LINKS.FAQ' | translate }}</a></li>
    <li><a href="https://fairfleet360.com/">{{ '404.LINKS.ORDER' | translate }}</a></li>
  </ul>
</div>
