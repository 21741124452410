import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { Subscription } from 'rxjs';

import { environment } from './../../../environments/environment.prod'; // This should be prod only here
import { StorageService } from './../helpers/storage.service';
import { ThemeService } from './theme.service';

export interface AppTheme {
  accent: string;
  isDark?: boolean;
  isDefault?: boolean;
  name: string;
  primary: string;
}

@Component({
  selector: 'app-theme',
  templateUrl: 'theme.component.html',
  styleUrls: ['theme.component.scss']
})
export class ThemeComponent implements OnInit {
  currentTheme;
  subscription: Subscription;

  themes = [
    { primary: '#484848', accent: '#2979FF', name: 'light-grey-blue', isDark: false, isDefault: true },
    { primary: '#484848', accent: '#2979FF', name: 'dark-grey-blue', isDark: true }
  ];

  constructor(
      public themeService: ThemeService,
      private breakpointObserver: BreakpointObserver,
      private iconRegistry: MatIconRegistry,
      @Inject(PLATFORM_ID) private platformId: Object,
      private sanitizer: DomSanitizer,
      private storageService: StorageService
  ) {
    // For SSR, full URL is needed in order to retrieve svg icons.
    // https://github.com/angular/components/issues/9728
    const path = (isPlatformBrowser(this.platformId)) ?
      '/assets/images/icons/' :
      `${environment.host}a/webassets/svg_assets/`;
      
    this.iconRegistry
    .addSvgIcon('360-degree-rotate-icon',this.sanitizer.bypassSecurityTrustResourceUrl(`${path}360-degree-rotate-icon.svg`))
    .addSvgIcon('360', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}360.svg`))
    .addSvgIcon('editor', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}editor.svg`))
    .addSvgIcon('viewer', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}viewer.svg`))
    .addSvgIcon('coordinator', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}coordinator.svg`))
    .addSvgIcon('volume', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}volume.svg`))
    .addSvgIcon('comparison', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}arrows-out-line-horizontal.svg`))
    .addSvgIcon('portfolio', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}portfolio.svg`))
    .addSvgIcon('tasks', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}tasks.svg`))
    
    .addSvgIcon('to_do', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}to_do.svg`))
    .addSvgIcon('review', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}review.svg`))
    .addSvgIcon('in_progress', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}in_progress.svg`))
    .addSvgIcon('done', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}done.svg`))

    .addSvgIcon('measure_area', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}measure_area.svg`))
    .addSvgIcon('length', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}length.svg`))
    .addSvgIcon('palette', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}palette.svg`))
    .addSvgIcon('opacity', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}opacity.svg`))
    .addSvgIcon('rectangle', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}rectangle.svg`))
    .addSvgIcon('target', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}target.svg`))
    .addSvgIcon('IR', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}IR.svg`))
    .addSvgIcon('isotherm', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}isotherm.svg`))
    .addSvgIcon('zoomin', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}zoomin.svg`))
    .addSvgIcon('zoomout', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}zoomout.svg`))
    .addSvgIcon('add', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}add.svg`))
    .addSvgIcon('360_new', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}360_new.svg`))
    .addSvgIcon('screen', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}screen.svg`))
    .addSvgIcon('reset', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}reset.svg`))
    .addSvgIcon('bentley_logo', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}bentley_logo.svg`))
    .addSvgIcon('fairfleet_inspect', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}fairfleet-inspect.svg`))
    .addSvgIcon('file_upload', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}file_upload.svg`))
    .addSvgIcon('folder_upload', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}folder_upload.svg`))
    .addSvgIcon('imogent_logo', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}imogent_logo.svg`))
    .addSvgIcon('layers_clear_outline', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}layers_clear_outline.svg`))
    .addSvgIcon('layers_outline', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}layers_outline.svg`))
    .addSvgIcon('layers_twotone', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}layers_twotone.svg`))
    .addSvgIcon('logo', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}logo.svg`))
    .addSvgIcon('raptor_maps_logo', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}raptor_maps_logo.svg`))
    .addSvgIcon('resize_handle_horizontal', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}resize_handle_horizontal.svg`))
    .addSvgIcon('resize_handle_vertical', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}resize_handle_vertical.svg`))
    .addSvgIcon('resize_handle_vertical_arrows', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}resize_handle_vertical_arrows.svg`))
    .addSvgIcon('show_hide_camera', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}show_hide_camera.svg`))
    .addSvgIcon('roadmap_3d', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}roadmap_3d.svg`))
    .addSvgIcon('roadmap', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}roadmap.svg`))
    .addSvgIcon('satellite_3d', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}satellite_3d.svg`))
    .addSvgIcon('satellite', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}satellite.svg`))
    .addSvgIcon('terrain', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}terrain.svg`))
    .addSvgIcon('wireframe_3d', this.sanitizer.bypassSecurityTrustResourceUrl(`${path}wireframe_3d.svg`));
  }

  installTheme(name: string, store: boolean): void {
    this.currentTheme = this.getThemeByName(name);

    if (this.currentTheme?.isDefault) {
      this.themeService.removeStyle('theme');
    } else {
      this.themeService.setStyle('theme', `${name}.css`);
    }

    // User has clicked the theme button, so we store his option
    if (this.currentTheme && store) {
      this.storageService.setItem(`${environment.storage}-theme`, this.currentTheme.name);
      // Unsubscribe from prefers-color-scheme
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }

  ngOnInit(): void {
    // Check if a theme is already saved in storage
    const storedThemeName = this.storageService.getItem(`${environment.storage}-theme`);
    if (storedThemeName) {
      this.installTheme(storedThemeName, false);
    } else {
      // At first load try to get the theme from the prefers-color-scheme
      if (isPlatformBrowser(this.platformId)) {
        this.subscription = this.breakpointObserver
        .observe(['(prefers-color-scheme: dark)'])
        .subscribe((state: BreakpointState) => {
          const preferredTheme = this.themes.find(theme => state.matches ? theme.isDark : !theme.isDark).name;
          this.installTheme(preferredTheme, false);
        });
      } else {
        // On server get the default theme (light)
        this.currentTheme = this.themes.find(theme => theme.isDefault);
      }
    }
  }

  private getThemeByName(name: string): AppTheme {
    return this.themes.find(theme => theme.name === name);
  }
}
