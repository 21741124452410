import { LineBase } from './LineBase';
import { SvgHelper } from './SvgHelper';

export class Arrow extends LineBase {
  private readonly ARROW_SIZE = 6;

  constructor() {
    super();
    this.type = 'Arrow';
  }

  static draw = (): LineBase => {
    const arrow = new Arrow();
    arrow.setup();

    return arrow;
  };

  protected setup(): void {
    super.setup();
    SvgHelper.setAttributes(this.visual, [['class', 'drawing arrow']]);

    const tip = SvgHelper.createPolygon(`0,0 ${this.ARROW_SIZE},${this.ARROW_SIZE / 2} 0,${this.ARROW_SIZE}`,
      [['class', 'arrow-tip']]);
    this.defs.push(SvgHelper.createMarker('arrow-head', 'auto',
      this.ARROW_SIZE, this.ARROW_SIZE, this.ARROW_SIZE - 1, this.ARROW_SIZE / 2, tip));

    // For Safari we need to override the relative base path used for angular with the absolute path from document.location
    this.line.setAttribute('marker-end', `url('${document.location}#arrow-head')`);
  }
}
