import { AfterViewInit, Component, HostListener, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTree } from '@angular/material/tree';
import { Platform } from '@angular/cdk/platform';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { DialogService } from './../dialog/dialog.service';
import { FileModel } from './../../pages/files/file/file.model';
import { FilesService } from './../../pages/files/files.service';
import { Folder } from './folder.model';
import { FoldersService } from './folders.service';
import { isRootFolderEditable } from './../../config';
import { SidenavNavigationService } from './../sidenav/sidenav-navigation/sidenav-navigation.service';
import { StorageService } from './../helpers/storage.service';
import { UploadService } from './../upload/upload.service';

@Component({
  selector: 'app-folders',
  templateUrl: 'folders.component.html',
  styleUrls: ['folders.component.scss']
})
export class FoldersComponent implements AfterViewInit, OnDestroy {
  activeFolderID: string;
  dataSource: Array<Folder> = [];
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isRootFolderEditable = isRootFolderEditable;
  ngDestroy$ = new Subject();
  pageIndex = 0;
  pageSize = 20;
  relatedTarget: HTMLElement; // Safari fix for https://bugs.webkit.org/show_bug.cgi?id=66547#c10
  @ViewChild('tree') tree: MatTree<any>;
  treeControl = new FlatTreeControl<Folder>(folder => folder.level, folder => folder.childrenCount > 0);

  constructor(
      private dialogService: DialogService,
      private filesService: FilesService,
      private foldersService: FoldersService,
      private platform: Platform,
      private renderer: Renderer2,
      private sidenavNavigationService: SidenavNavigationService,
      private snackBar: MatSnackBar,
      private storageService: StorageService,
      private translate: TranslateService,
      private uploadService: UploadService
  ) {
    this.foldersService.setTreeControl(this.treeControl);

    // Get sorted data from local storage
    const sortedData = this.storageService.getItem('folders-sorted-data');
    if (sortedData) {
      this.foldersService.sortedData = sortedData;
    }

    /** Handle expand/collapse behaviors */
    this.treeControl.expansionModel.changed.subscribe(change => {
      // Expand
      if (change.added.length) {
        change.added.forEach(added => {
          this.expandFolder(added, false, false);
        });
      }
      // Collapse
      if (change.removed.length) {
        this.foldersService.collapseFolder(change.removed[0]);
      }
    });
  }

  closeSidenavNavigation(): void {
    if (this.sidenavNavigationService.isMobile || this.sidenavNavigationService.isXSmall) {
      this.sidenavNavigationService.close();
    }
  }

  expandFolder(folder: Folder, isFirstTime: boolean, loadMore: boolean): void {
    let folderID = String(folder._id);

    if (loadMore) {
      folderID = String(folder.folderID);
      folder = this.foldersService.data$.value.find(f => f._id === folderID);

      const firstChildren = this.foldersService.loadedChildren(folder, true) - (loadMore ? 1 : 0);
      this.pageIndex = Math.floor(firstChildren / this.pageSize);
    } else {
      this.pageIndex = 0;
    }

    const filter = {
      isFolder: true,
      folderID: folderID && folderID.length === 24 ? { $oid: folderID } : { $exists: false }
    };

    let sort = {}; // To preserve the order of the sort fields we use Object.assign
    if (this.sortedData.active && this.sortedData.direction !== '') {
      sort = Object.assign(sort, { [this.sortedData.active]: this.sortedData.direction === 'desc' ? -1 : 1 }); // Sort by selected column
    }

    sort = Object.assign(sort, { created: -1 }); // Sort also by created at the end to ensure correct pagination without getting duplicates

    folder.fetching = true;

    // Get data from the server
    this.foldersService.findMany(filter, sort, this.pageIndex, isFirstTime ? 1 : this.pageSize)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {
        const data = this.foldersService.data$.value;
        const index = data.indexOf(folder);

        folder.fetching = false;

        if (folderID === 'my-files') {
          data[index].childrenCount = response.pagination ? response.pagination.totalItems : 0; // Update count on root folder
        }

        if (!isFirstTime) {
          // Set the first children count
          const firstChildren = this.foldersService.loadedChildren(folder, true) - (loadMore ? 1 : 0);

          if (response.data) {
            const subFolders = (response.data as Array<Folder>)
              .filter(sf => !data.some(d => d._id === sf._id)); // Check if already loaded
            subFolders.forEach(subFolder => {
              subFolder.level = folder.level + 1; // Set the level
            });

            // If not all loaded add a load more node
            if (firstChildren + subFolders.length < folder.childrenCount &&
                folder.childrenCount > this.pageSize) {

              subFolders.push({
                _id: `LOAD_MORE_${folder._id}`,
                childrenCount: 0,
                level: folder.level + 1,
                folderID: folder._id,
                name: 'LOAD_MORE'
              });
            }

            const start = index + (loadMore ? this.foldersService.loadedChildren(folder, false) : 1);
            data.splice(start, (loadMore ? 1 : 0), ...subFolders);

          } else { // Update count if no more children available
            data[index].childrenCount = firstChildren;
          }
        }
        this.foldersService.data$.next(data);
      },
      error => {
        folder.fetching = false;
        this.dialogService.showDialog('FILES.GETTING_FAILED', error.status, error.url, error.error);
      }
    );
  }

  get sortedData(): Sort { return this.foldersService.sortedData; }

  hasChild = (_: number, folder: Folder) => folder.childrenCount > 0;

  /** Check if folder can be moved (dropped) on the client-side */
  isDroppable(dragIDs: Array<string>, dragParentID: string, dropID: string): boolean {
    if ((!dragParentID && !dropID) || dragParentID === dropID) {
      this.translate.get('FOLDERS.CANNOT_DROP_INTO_SAME_PARENT').subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 3000 });
      });

      return false;
    }

    if (dragIDs.includes(dropID)) {
      this.translate.get('FOLDERS.CANNOT_DROP_INTO_ITSELF').subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 3000 });
      });

      return false;
    }

    // Check that a folder is not dropped into its children
    if (dropID) {
      const index = this.dataSource.findIndex(f => f._id === dropID); // Drop folder index
      let folderID = this.dataSource[index].folderID;

      // Iterate thru parent folders of the drop target
      for (let i = index - 1; i > 0; i--) {
        if (this.dataSource[i]._id === folderID) { // Parent found
          if (dragIDs.includes(String(this.dataSource[i]._id))) { // Dragged folder found in the drop folder's parents
            this.translate.get('FOLDERS.CANNOT_DROP_INTO_CHILD').subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });

            return false;
          }
          folderID = this.dataSource[i].folderID; // Set new parent to be found
        }
      }
    }

    return true;
  }

  isLoadMore = (_: number, folder: Folder) => this.foldersService.isLoadMore(folder);

  ngAfterViewInit(): void {
    this.foldersService.data$.next([this.foldersService._myFiles]);
    this.expandFolder(this.foldersService._myFiles, true, false);

    this.foldersService.activeFolderID$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(activeFolderID => {
      Promise.resolve().then(() => {
        this.activeFolderID = activeFolderID;
      });
    });

    this.foldersService.data$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      Promise.resolve().then(() => {
        this.dataSource = data;
        this.tree.renderNodeChanges(data);
      });
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  // Remove dragging class from all
  onDragEnd(event: any): void {
    const target = event.target as HTMLElement;

    if (target.className.indexOf('dragging') > -1) { // Single
      this.renderer.removeClass(target, 'dragging');
    }

    Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) =>
      this.renderer.removeClass(element, 'drop-zone')); // Remove highlight from all drop-zones
  }

  @HostListener('dragenter.out-zone', ['$event', '$event.target'])
  onDragEnter(event: any, target: HTMLElement): void {
    this.onDragOver(event, target);

    if (target.nodeType === Node.ELEMENT_NODE) {
      const dropZone = target.closest('.droppable') as HTMLElement;
      if (this.platform.SAFARI) {
        this.relatedTarget = dropZone; // Safari fix that doesn't yet support event.relatedTarget
      }
      if (dropZone && dropZone.className.indexOf('dragging') === -1 && dropZone.className.indexOf('drop-zone') === -1) {
        Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) =>
          this.renderer.removeClass(element, 'drop-zone')); // Remove highlight from other drop-zones (fix for Safari)

        this.renderer.addClass(dropZone, 'drop-zone'); // Highlight drop-zone element
      }
    }
  }

  @HostListener('dragleave.out-zone', ['$event', '$event.relatedTarget', '$event.target'])
  onDragLeave(event: any, relatedTarget: HTMLElement, target: HTMLElement): void {
    // The element the cursor just entered
    let enter = relatedTarget && relatedTarget.closest('.droppable') as HTMLElement;
    if (this.platform.SAFARI && !enter) {
      enter = this.relatedTarget;
    }
    // The element the cursor just left
    const leave = target && target.closest('.droppable') as HTMLElement;

    if (enter !== leave) { // Different elements
      // Remove highlight from other drop-zones
      Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) => {
        if (!enter || // Leave browser
          (!enter.attributes['draggable'] && enter.className.indexOf('droppable') === -1) || // Leave drop zones
          (enter.attributes['draggable'] && enter.className.indexOf('droppable') > -1 && element !== enter) || // Not entered folder
          (leave && leave.attributes['draggable'] && leave.className.indexOf('drop-zone') > -1 && element === leave) // Leave row/thumbnail
        ) {
          this.renderer.removeClass(element, 'drop-zone');
        }
      });
    }
  }

  @HostListener('dragover.out-zone', ['$event', '$event.target'])
  onDragOver(event: any, target: HTMLElement): void {
    target = target.closest('.droppable') as HTMLElement;
    // Firefox fires ondragover events on text nodes
    if (target && target.nodeType === Node.ELEMENT_NODE) {
      event.dataTransfer.dropEffect = target.classList.contains('dragging') ? 'none' : 'copy';
      // Prevent default behavior (Prevent file from being opened)
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onDragStart(event: any): void {
    const target = event.target as HTMLElement;
    this.relatedTarget = null; // Safari fix that doesn't yet support event.relatedTarget

    this.renderer.addClass(event.target, 'dragging');
    const folder = this.dataSource.find(f => f._id === target.id); // Destination folder

    event.dataTransfer.setData('text/plain', JSON.stringify({ // 'text/plain' is supported by all browsers
      fileIDs: [folder._id],  // Dragged ids
      folderID: folder.folderID // Source parent folderID
    }));
  }

  @HostListener('drop', ['$event', '$event.target'])
  onDrop(event: any, target: HTMLElement): void {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)

    const dropZone = target.closest('.droppable') as HTMLElement; // The drop zone
    const dropID = dropZone.id && dropZone.id.length === 24 ? dropZone.id : null; // Destination folder id

    this.renderer.removeClass(dropZone, 'drop-zone');

    // BUG: Safari doesn't get data from Chrome and Opera, always empty
    // https://github.com/ProjectMirador/mirador/issues/1433
    const text = event.dataTransfer.getData('text/plain');
    const data = text ? JSON.parse(text) : null;

    // Move internal files
    if (data && data.fileIDs) {

      const droppable = this.isDroppable(data.fileIDs, data.folderID, dropID);
      if (droppable) { // Avoid dropping a folder inside itself or one of it's children, this would create a cyclical reference

        const fileUpdates: FileModel = {
          _id: data.fileIDs.join(','),
          folderID: dropID ? { $oid: dropID } : ''
        };

        // Update one or many
        const update$ = data.fileIDs.length === 1 ? this.filesService.updateOne(fileUpdates) : this.filesService.updateMany(fileUpdates);
        update$.subscribe(
          () => {
            this.filesService.refreshed.emit('refreshed');
            this.foldersService.refresh();

            this.translate.get('FILES.SUCCESSFULLY_MOVED').subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });
          },
          error => {
            this.translate.get(error.error.errors[0]).subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });
          }
        );
      }
    } else {
      // Upload multiple files or a folder
      if (event.dataTransfer.items && event.dataTransfer.items.length) {
        const files: Array<File> = [];

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          const item = event.dataTransfer.items[i];
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            const entry = item.webkitGetAsEntry();
            if (entry.isFile) {
              const file = item.getAsFile();
              files.push(file);
            } else if (entry.isDirectory) {
              // listing = scanFiles(entry); // TODO: implement folder upload
              this.dialogService.showDialog('UPLOAD.FOLDERS_NOT_SUPPORTED', null, 'UPLOAD.CREATE_NEW_FOLDERS');
            }
          }
        }
        if (files.length) {
          this.uploadService.handleFilesInput(files, dropID);
        }
      } else if (event.dataTransfer.files && event.dataTransfer.files.length) {
        // Use DataTransfer interface to access the file(s)
        this.uploadService.handleFilesInput(event.dataTransfer.files, dropID);
      }
    }
  }

  sortData(sort: Sort): void {
    // Save sorted data in local storage
    if (JSON.stringify(this.sortedData) !== JSON.stringify(sort)) {
      this.storageService.setItem('folders-sorted-data', sort);
    }
    this.foldersService.sortedData = sort;

    // Refresh tree if expanded
    if (this.treeControl.isExpanded(this.dataSource[0])) {
      this.foldersService.collapseFolder(this.dataSource[0]);
      this.expandFolder(this.dataSource[0], false, false);
    }
  }
}
