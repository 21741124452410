import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { clearQueueScrollLocks, disablePageScroll, enablePageScroll } from 'scroll-lock';
import { Direction } from '@angular/cdk/bidi';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ResizeEvent } from 'angular-resizable-element';
import { ViewportRuler } from '@angular/cdk/overlay';

import { LanguageService } from './shared/i18n/language.service';
import { SidenavContentService } from './shared/sidenav/sidenav-content/sidenav-content.service';
import { SidenavDetailService } from './shared/sidenav/sidenav-detail/sidenav-detail.service';
import { SidenavNavigationService } from './shared/sidenav/sidenav-navigation/sidenav-navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {

  dir: Direction;
  fullScreen = false;
  fullScreenDetail = false;
  fullScreenPrimary = false;
  hideDetailHandle = false;
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isSmall = false;
  isXSmall = false;
  @ViewChild('sidenavContent', { static: true }) sidenavContent: any;
  @ViewChild('sidenavDetail', { static: true }) sidenavDetail: any;
  sidenavDetailOpened: boolean;
  @ViewChild('sidenavNavigation', { static: true }) sidenavNavigation: any;

  constructor(
      private breakpointObserver: BreakpointObserver,
      private languageService: LanguageService,
      private platform: Platform,
      @Inject(PLATFORM_ID) private platformId: Object,
      private renderer: Renderer2,
      private route: ActivatedRoute,
      private router: Router,
      private sidenavContentService: SidenavContentService,
      private sidenavDetailService: SidenavDetailService,
      private sidenavNavigationService: SidenavNavigationService,
      private viewportRuler: ViewportRuler
  ) {
    this.sidenavNavigationService.renderer = this.renderer;

    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        this.fullScreen = this.route.snapshot.firstChild.data.realFullScreen;
        this.fullScreenPrimary = this.route.snapshot.firstChild.data.fullScreen;
        const secondChild = this.route.snapshot.children[1];
        this.hideDetailHandle = data.url.includes('/inspection') || data.url.includes('/viewer/')
        this.fullScreenDetail = secondChild && secondChild.outlet === 'detail' ? secondChild.params['view'] === 'fullscreen' : null;

        this.sidenavNavigationService.opened = !this.route.snapshot.firstChild.data.sidenavNavigationClosed;
        if (!this.sidenavNavigationService.opened) {
          this.sidenavNavigationService.close();
        }
      }
    });

    this.languageService.dir$.subscribe(dir => this.dir = dir);
  }

  ngOnDestroy(): void {
    // Prevent memory leak when component destroyed
    this.languageService.dir$.unsubscribe();
    if (isPlatformBrowser(this.platformId)) {
      clearQueueScrollLocks();
    }
  }

  ngOnInit(): void {
    this.sidenavContentService.setSidenav(this.sidenavContent);
    this.sidenavNavigationService.setSidenav(this.sidenavNavigation);
    this.sidenavDetailService.setSidenav(this.sidenavDetail);

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ]).subscribe(() => {
      Promise.resolve().then(() => {
        this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
        this.isXSmall = !this.isSmall && this.breakpointObserver.isMatched(Breakpoints.XSmall);

        this.sidenavNavigationService.isMobile = this.isMobile;
        this.sidenavNavigationService.isXSmall = this.isXSmall;

        if (isPlatformBrowser(this.platformId)) {
          if (this.isMobile || this.isXSmall) {
            this.sidenavNavigationService.close().then(res => res === 'close' ? this.sidenavNavigationService.unfold() : false);
          } else if (this.sidenavNavigationService.opened) {
            this.sidenavNavigationService.open();
          }

          if (!this.isMobile) {
            if (this.isSmall) { // tablet
              this.sidenavNavigationService.fold();
            } else if (!this.isXSmall) { // desktop
              this.sidenavNavigationService.unfold();
            }
          }

          // Disable scrolling on body when sidenavDetail is opened
          const scrollable = document.querySelector('.scrollable-section');
          clearQueueScrollLocks();
          if ((this.fullScreenDetail || this.isXSmall) && this.sidenavDetailService.opened$.value) {
            disablePageScroll(scrollable);
          } else {
            enablePageScroll(scrollable);
          }
        }
      });
    });

    this.viewportRuler.change()
    .subscribe(() => {
      // Snap to viewportWidth - navWidth - 72px
      const detailWidth = this.sidenavDetailService.resized$.value;
      const navWidth = this.sidenavNavigationService.resized$.value;
      const viewportWidth = this.viewportRuler.getViewportSize().width;
      if (detailWidth > viewportWidth - navWidth - 72 && detailWidth > 360 && viewportWidth > 600) {
        this.sidenavDetailResize(viewportWidth - navWidth - 72);
      }
    });
  }

  sidenavDetailOpenedChange(opened: boolean): void {
    this.sidenavDetailService.opened$.next(opened);

    // disable scrolling on body when sidenavDetail is opened
    if ((this.fullScreenDetail || this.isXSmall) && isPlatformBrowser(this.platformId)) {
      const scrollable = document.querySelector('.scrollable-section');
      clearQueueScrollLocks();
      if (opened) {
        disablePageScroll(scrollable);
      } else {
        enablePageScroll(scrollable);
      }
    }

    // Reset to default width in case it was closed with a resize event
    if (!opened && this.sidenavDetailService.resized$.value < 360) {
      this.renderer.setStyle(this.sidenavDetailService.nativeElement, 'width', '360px');
      this.sidenavDetailService.resized(360);
    }
  }
  
  sidenavDetailOpenedStart(opened: boolean): void {
    this.sidenavDetailOpened = opened;
  }

  sidenavDetailResize(width: number): void {
    this.renderer.setStyle(this.sidenavDetailService.nativeElement, 'width', `${width}px`);

    // Broadcast the change
    this.sidenavDetailService.resized(width);
  }

  sidenavDetailResizeEnd(event: ResizeEvent): void {
    // Put noClickPDF behind PDF - Ticket ff4262
    Array.from(document.getElementsByClassName('noClickPDF')).forEach((element: HTMLElement) =>
      element.style.zIndex = '-1'
    );
    const width = event.rectangle.width;

    if (width < 256) {
      this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' })
      .then((confirm: boolean) => {
        if (!confirm) {
          this.sidenavDetailResize(360); // Revert at 360px if cancelled
        }
      }); // Close if smaller than 340px
    }
  }

  sidenavDetailResizeStart(): void {
    // Put noClickPDF infront PDF - Ticket ff4262
    Array.from(document.getElementsByClassName('noClickPDF')).forEach((element: HTMLElement) =>
      element.style.zIndex = '10'
    );
  }

  sidenavDetailResizing(event: ResizeEvent): void {
    const viewportWidth = this.viewportRuler.getViewportSize().width;
    const width = event.rectangle.width;

    if (width < 256) {
      this.sidenavDetailResize(width); // Resize normally
      // Sidenav is about to be closed
    } else if (width < 380) {
      this.sidenavDetailResize(360); // Snap at 360px
    } else if (width > viewportWidth - this.sidenavNavigationService.resized$.value - 72) {
      // Snap at viewportWidth - navbar - 72px
      this.sidenavDetailResize(viewportWidth - this.sidenavNavigationService.resized$.value - 72);
    } else {
      this.sidenavDetailResize(width); // Resize normally
    }
  }

  sidenavNavigationOpenedChange(opened: boolean): void {
    this.sidenavNavigationService.opened$.next(opened);

    // Disable scrolling on body when sidenavNavigation is opened
    if ((this.isXSmall) && isPlatformBrowser(this.platformId)) {
      const scrollable = document.querySelector('.sidenav-navigation').childNodes;
      clearQueueScrollLocks();
      if (opened) {
        disablePageScroll(scrollable);
      } else {
        enablePageScroll(scrollable);
      }
    }
  }

  sidenavNavigationResize(width: number): void {
    this.renderer.setStyle(this.sidenavNavigationService.nativeElement, 'width', `${width}px`);

    // Broadcast the change
    this.sidenavNavigationService.resized(width);
  }

  sidenavNavigationResizeEnd(event: ResizeEvent): void {
    const width = event.rectangle.width;

    if (width < 56) {
      // Close if smaller than 56px
      this.sidenavNavigationService.close().then(res => res === 'close' ? this.sidenavNavigationService.unfold() : false);
    } else if (width < 236) {
      this.sidenavNavigationService.fold(); // Fold if smaller than 200px
    } else if (width < 276) {
      this.sidenavNavigationService.unfold(); // Unfold if smaller than 276px
    }
  }

  sidenavNavigationResizing(event: ResizeEvent): void {
    const viewportWidth = this.viewportRuler.getViewportSize().width;
    const width = event.rectangle.width;

    if (width < 236) {
      this.sidenavNavigationResize(width); // Resize normally
      // sidenav is about to be closed
    } else if (width < 276) {
      this.sidenavNavigationResize(256); // Snap at 256px
    } else if (width > viewportWidth - this.sidenavDetailService.resized$.value - 72) {
      // Snap at viewportWidth - detail - 72px
      this.sidenavNavigationResize(viewportWidth - this.sidenavDetailService.resized$.value - 72);
    } else {
      this.sidenavNavigationResize(width); // Resize normally
    }
  }
}
