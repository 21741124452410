import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export class MapOptions {
  features?: number;
  mapTypeId: string | google.maps.MapTypeId;
}

@Injectable()
export class MapsService {

  public isLoaded = false;

  mapOptions$: BehaviorSubject<MapOptions> = new BehaviorSubject({
    features: 3,
    mapTypeId: 'hybrid'
  });

  constructor() {
    window['gmCallback'] = () => {
      this.isLoaded = true;
    };
  }

}
