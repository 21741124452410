import { Arrow } from './Arrow';
import { Colors, Config } from './Config';
import { Ellipse } from './Ellipse';
import { Line } from './Line';
import { Base } from './Base';
import { Rectangle } from './Rectangle';
import { Renderer } from './Renderer';
import { SvgHelper } from './SvgHelper';
import { Text } from './Text';

export class DrawingArea {
  activeElement: Base;
  drawings: Array<Base>;
  private cancelCallback: () => void;
  private colors: Colors;
  private completeCallback: (dataUrl: string) => void;
  private defs: SVGDefsElement;
  private drawingSVG: SVGSVGElement;
  private height: number;
  private renderAtNaturalSize: boolean;
  private renderDrawingsOnly?: boolean;
  private renderImageQuality?: number;
  private renderImageType?: string;
  private scale = 1.0;
  private strokeWidth: number;
  private target: HTMLImageElement;
  private targetRoot: HTMLElement;
  private width: number;

  constructor(target: HTMLImageElement, config?: Config) {
    this.target = target;
    this.targetRoot = config && config.targetRoot ? config.targetRoot : document.body;
    this.renderAtNaturalSize = config && config.renderAtNaturalSize !== undefined ? config.renderAtNaturalSize : false;

    this.colors = {
      mainColor: config && config.colors && config.colors.mainColor ? config.colors.mainColor : '#ffffff',
      highlightColor: config && config.colors && config.colors.highlightColor ?
        config.colors.highlightColor : '#ffff00',
      coverColor: config && config.colors && config.colors.coverColor ? config.colors.coverColor : '#000000'
    };

    this.strokeWidth = 3;

    if (config) {
      if (config.renderImageType) {
        this.renderImageType = config.renderImageType;
      }
      if (config.renderImageQuality) {
        this.renderImageQuality = config.renderImageQuality;
      }
      if (config.renderDrawingsOnly) {
        this.renderDrawingsOnly = config.renderDrawingsOnly;
      }
      if (config.strokeWidth) {
        this.strokeWidth = config.strokeWidth;
      }
    }

    const rect = this.target.getBoundingClientRect();
    this.width = rect.width;
    this.height = rect.height;

    this.drawings = [];
    this.activeElement = null;
  }

  create = () => {
    this.initDrawingCanvas();
    this.attachEvents();
    this.setStyles();
  };

  delete = (drawing: Base) => {
    this.drawingSVG.removeChild(drawing.visual);
    if (this.activeElement === drawing) {
      this.activeElement = null;
    }
    this.drawings.splice(this.drawings.indexOf(drawing), 1);
  };

  deleteActive = () => {
    if (this.activeElement) {
      this.delete(this.activeElement);
    }
  };

  destroy = () => {
    if (this.drawingSVG) {
      this.targetRoot.removeChild(this.drawingSVG);
    }
  };

  draw = (
      shape: string,
      geoJSON?: GeoJSON.Feature
  ): Base => {
    let type: typeof Base;
    switch (shape) {
      case 'Arrow': type = Arrow; break;
      case 'Ellipse': type = Ellipse; break;
      case 'Line': type = Line; break;
      case 'Rectangle': type = Rectangle; break;
      case 'Text': type = Text; break;
      default:
    }
    const drawing = type.draw();
    drawing.onSelected = this.select;
    drawing.target = this.target;

    if (drawing.defs && drawing.defs.length > 0) {
      for (const d of drawing.defs) {
        if (d.id && !this.drawingSVG.getElementById(d.id)) {
          this.defs.appendChild(d);
        }
      }
    }

    this.drawings.push(drawing);

    this.drawingSVG.appendChild(drawing.visual);

    const bbox = drawing.visual.getBBox();
    const x = this.width / 2 - bbox.width / 2;
    const y = this.height / 2 - bbox.height / 2;

    const translate = drawing.visual.transform.baseVal.getItem(0);
    translate.setMatrix(translate.matrix.translate(x, y));
    drawing.visual.transform.baseVal.replaceItem(translate, 0);

    if (geoJSON) {
      drawing.geoJSON = geoJSON;
      drawing.stateFromGeoJSON();
    } else {
      drawing.stateToGeoJSON();
      drawing.select();
    }

    return drawing;
  };

  render = (completeCallback: (dataUrl: string) => void, cancelCallback?: () => void) => {
    this.completeCallback = completeCallback;
    this.cancelCallback = cancelCallback;

    this.select(null);
    this.startRender(this.renderFinished);
  };

  show = (completeCallback: (dataUrl: string) => void, cancelCallback?: () => void) => {
    this.completeCallback = completeCallback;
    this.cancelCallback = cancelCallback;

    this.create();
  };

  updateSize = (scale?: number) => {
    const rect = this.target.getBoundingClientRect();
    this.width = rect.width;
    this.height = rect.height;

    this.scale = scale || 1;
    this.drawingSVG.setAttribute('width', this.width.toString());
    this.drawingSVG.setAttribute('height', this.height.toString());
    this.drawingSVG.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.drawings.length; i++) {
      this.drawings[i].stateFromGeoJSON();
    }
  };

  private attachEvents = () => {
    this.drawingSVG.addEventListener('mousedown', this.mouseDown);
    this.drawingSVG.addEventListener('mousemove', this.mouseMove);
    this.drawingSVG.addEventListener('mouseup', this.mouseUp);
    this.drawingSVG.addEventListener('touchstart', this.onTouch, { passive: false });
    this.drawingSVG.addEventListener('touchend', this.onTouch, { passive: false });
    this.drawingSVG.addEventListener('touchmove', this.onTouch, { passive: false });
  };

  private complete = () => {
    this.select(null);
    this.startRender(this.renderFinishedDestroy);
  };

  private initDrawingCanvas = () => {
    this.drawingSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.drawingSVG.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    this.drawingSVG.setAttribute('class', 'drawing-svg');
    this.drawingSVG.setAttribute('width', `${this.width}`);
    this.drawingSVG.setAttribute('height', `${this.height}`);
    this.drawingSVG.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);

    this.defs = SvgHelper.createDefs();
    this.drawingSVG.appendChild(this.defs);

    this.targetRoot.appendChild(this.drawingSVG);
  };

  private mouseDown = (ev: MouseEvent) => {
    const target = ev.target as SVGElement;
    if (target === this.drawingSVG) {
      /* eslint-disable no-bitwise */
      if (this.activeElement && (ev.buttons & 1) > 0) {
        this.activeElement.deselect();
        this.activeElement = null;
      }
    } else {
      const visual = target.closest('.drawing');
      const activeElement = this.drawings.find(drawing => drawing.visual === visual);
      if (activeElement) {
        if (target.classList.contains('grip')) {
          activeElement.gripMouseDown(ev);
        } else {
          activeElement.mouseDown(ev);
        }
      }
    }
  };

  private mouseMove = (ev: MouseEvent) => {
    /* eslint-disable no-bitwise */
    if (this.activeElement && (ev.buttons & 1) > 0) {
      this.activeElement.manipulate(ev);
    }
  };

  private mouseUp = (ev: MouseEvent) => {
    if (this.activeElement) {
      this.activeElement.endManipulation();
    }
  };

  private onTouch = (ev: TouchEvent) => {
    ev.preventDefault();
    const touch = ev.changedTouches[0];
    let type = null;

    switch (ev.type) {
      case 'touchstart':
        type = 'mousedown';
        break;
      case 'touchmove':
        type = 'mousemove';
        break;
      case 'touchend':
        type = 'mouseup';
        break;
      default:
    }

    const mouseEvent = new MouseEvent(type, {
      bubbles: true,
      buttons: 1,
      cancelable: true,
      clientX: touch.clientX,
      clientY: touch.clientY,
      screenX: touch.screenX,
      screenY: touch.screenY,
      view: window
    });

    ev.target.dispatchEvent(mouseEvent);
  };

  private renderFinished = (dataUrl: string) => {
    this.completeCallback(dataUrl);
  };

  private renderFinishedDestroy = (dataUrl: string) => {
    this.destroy();
    this.completeCallback(dataUrl);
  };

  private select = (drawing: Base) => {
    if (this.activeElement && this.activeElement !== drawing) {
        this.activeElement.deselect();
    }
    this.activeElement = drawing;

    // Bring activeElement to front by removing it and appending it as lastChild
    if (drawing && this.drawingSVG.lastChild !== drawing.visual) {
      this.drawingSVG.removeChild(drawing.visual);
      this.drawingSVG.appendChild(drawing.visual);
    }
  };

  private setStyles = () => {
    const editorStyleSheet = document.createElementNS('http://www.w3.org/2000/svg', 'style');
    editorStyleSheet.innerHTML = `
.arrow .render-visual {
  stroke: ${this.colors.mainColor};
  stroke-width: ${this.strokeWidth};
  fill: transparent;
}
.arrow-tip {
  stroke-width: 0;
  fill: ${this.colors.mainColor};
}
.drawing-rect-control-box .drawing-rect-control-rect {
  cursor: pointer;
  stroke: black;
  stroke-width: 1;
  stroke-opacity: 0.5;
  stroke-dasharray: 3, 2;
  fill: transparent;
}
.grip {
  cursor: pointer;
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 1;
}
.grip.nw-resize,
.grip.se-resize {
  cursor: nwse-resize;
}
.grip.ne-resize,
.grip.sw-resize {
  cursor: nesw-resize;
}
.grip.n-resize,
.grip.s-resize {
  cursor: ns-resize;
}
.grip.e-resize,
.grip.w-resize {
  cursor: ew-resize;
}
.ellipse .render-visual {
  stroke: ${this.colors.mainColor};
  stroke-width: ${this.strokeWidth};
  fill: transparent;
}
.line .render-visual {
  stroke: ${this.colors.mainColor};
  stroke-width: ${this.strokeWidth};
  fill: transparent;
}
.rectangle .render-visual {
  stroke: ${this.colors.mainColor};
  stroke-width: ${this.strokeWidth};
  fill: transparent;
}
.text text {
  fill: ${this.colors.mainColor};
  font-family: Roboto, Helvetica, 'Noto Naskh Arabic UI', sans-serif;
}
.render-visual {
  cursor: pointer;
}`;

    this.drawingSVG.appendChild(editorStyleSheet);
  };

  private startRender = (done: (dataUrl: string) => void) => {
    const renderer = new Renderer();
    renderer.rasterize(this.target, this.drawingSVG, done,
      this.renderAtNaturalSize, this.renderImageType, this.renderImageQuality, this.renderDrawingsOnly);
  };
}
