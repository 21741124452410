import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Map } from 'ol';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OlMapComparisonService {

  constructor(
    private http: HttpClient,
  ) { }

  getOrthos(siteId: string): Observable<any[]> {
    return this.http.get<any>(`${environment.apiPath}sites/${siteId}/comparison`);
  }

  getDSMLayerByLegacyId(map: Map, legacyId: number): WebGLTileLayer{
    map.getAllLayers().forEach((layer)=>{
      if ( layer.get('legacyId') === legacyId ) {
        return layer
      }
    })
    return undefined
  }
  
}
