import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSameDay'
})
export class IsSameDayPipe implements PipeTransform {

  transform(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) {
      return false;
    }

    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return d1.getDate() === d2.getDate() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getFullYear() === d2.getFullYear();
  }

}
