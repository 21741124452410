import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { folderCategory, folderCategoryFilterGroup } from './folder-categories';
import { LoginStateService } from './../../pages/login/login-state.service';
import { AccountType, UserRole } from '../../pages/users/models/user.model';


@Component({
  selector: 'app-folder-dialog',
  templateUrl: './folder-dialog.component.html',
  styleUrls: ['./folder-dialog.component.scss']
})
export class FolderDialogComponent implements OnDestroy {
  formGroup: FormGroup;
  stateGroupOptions: Observable<folderCategory[]>;
  userRole = UserRole;
  accountType = AccountType
  ngDestroy$ = new Subject();

  constructor(
      @Inject(FormBuilder)
      private formBuilder: FormBuilder,
      private loginStateService: LoginStateService,
      private translate: TranslateService,
      public dialogRef: MatDialogRef<FolderDialogComponent>
  ) {
    this.formGroup = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(256),
        // Safe Characters https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
        Validators.pattern(/^[^:\\|\/{}<>´`"~^%#]+$/)
      ])
    });

    this.loginStateService.login$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(login => {
      if (login) {
        Promise.resolve(undefined).then(() => {
          if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(login?.accountType) &&
              (this.translate.currentLang === 'de' || this.translate.currentLang === 'en')
          ) {
            this.stateGroupOptions = this.formGroup.get('name')!.valueChanges.pipe(
              startWith(''),
              map(value => folderCategoryFilterGroup(value, this.translate.currentLang)),
            );
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  get name(): AbstractControl { return this.formGroup.get('name'); }
}
