import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

export interface DialogData {
  header: string;
  content: string;
}

@Component({
  selector: 'app-long-text-dialog',
  templateUrl: './long-text-dialog.component.html',
  styleUrls: ['./long-text-dialog.component.scss']
})
export class LongTextDialogComponent {
  formGroup: FormGroup;

  features = [
    {value: 'moss', viewValue: 'Moos'},
    {value: 'corrosion', viewValue: 'Korrosion'},
    {value: 'rubble', viewValue: 'Schutt'},
    {value: 'hail', viewValue: 'Hagel'}
  ];

  constructor(
      public dialogRef: MatDialogRef<LongTextDialogComponent>,
      @Inject(FormBuilder)
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute
  ) {
    this.formGroup = this.formBuilder.group({
      content: new FormControl(data.content),
    });
  }

  get content(): AbstractControl { return this.formGroup.get('content'); }
}
