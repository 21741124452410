import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input, OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  E_USER_DOCUMENT_TYPES,
  E_USER_ID_TYPE,
  IDocument,
  IIdType,
  IUserType
} from '../models/interfaces/user.interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { USER_DOCUMENTS_TYPES, USER_ID_TYPES } from '../models/constants/user.constants';
import { UploadService } from '../../../shared/upload/upload.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { FileModel } from '../../files/file/file.model';
import { FilesService } from '../../files/files.service';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss']
})
export class UserDocumentsComponent implements AfterViewInit, OnDestroy {

  public isDocumentButtonClicked: boolean = false;
  public activeDocumentType: E_USER_DOCUMENT_TYPES | null = null;
  public activeIndex: number = -1;
  public documentForm!: FormGroup;
  public attachmentId: string | object = '';
  public documents: IDocument[] = [];
  public ngDestroy$ = new Subject<void>()
  public files: FileModel[] = [];

  @Input()
  public set documentsSetter(documents: IDocument[]) {
    this.documents = documents;
    this.getFiles();
  }
  @Output() documentsChange = new EventEmitter<IDocument[]>();

  @ViewChild('idDocument') idDocumentRef: TemplateRef<Document>;
  @ViewChild('insurance') insuranceRef: TemplateRef<Document>;
  @ViewChild('permit') permitRef: TemplateRef<Document>;
  @ViewChild('operator') operatorRef: TemplateRef<Document>;

  private documentTemplateFactory!: Record<E_USER_DOCUMENT_TYPES, TemplateRef<Document>>;
  constructor(
    @Inject(FormBuilder)
    private formBuilder: FormBuilder,
    private readonly uploadService: UploadService,
    private readonly fileService: FilesService
  ) { }

  private uploadFile(files: FileList): Observable<string | object> {
    const file = files.item(0);
    let fileModel: FileModel;

    return this.uploadService.presignPutURLs([this.uploadService.getKey(file)], null, 1440)
      .pipe(mergeMap(presignResponse => {
        const _id = { $oid: presignResponse.data[0].key.split('/')[1] }; // Get generated id
        const name = presignResponse.data[0].key.split('/')[2]; // Get renamed file name using pattern
        const fileURL = presignResponse.data[0].getURL.split('?')[0]; // Remove pre-signed query
        fileModel = {
          _id, // Use the same file id in DB and s3
          folderID: null,
          fileURL,
          mimeType: file.type || 'application/octet-stream',
          name, // Use the renamed by pattern filename
          size: file.size
        };
          return combineLatest(this.fileService.insertMany([fileModel]),
            this.uploadService.uploadFile(presignResponse.data[0].putURL, file)
          )
      }),
        map(value => fileModel._id))
  }

  public uploadFileHandler(files: FileList): void {
    this.uploadFile(files)
      .subscribe(value => {
        this.attachmentId = value['$oid'];
      })
  }

  private initFactory(): void {
    this.documentTemplateFactory = {
      [E_USER_DOCUMENT_TYPES.ID_DOCUMENT]: this.idDocumentRef,
      [E_USER_DOCUMENT_TYPES.INSURANCE]: this.insuranceRef,
      [E_USER_DOCUMENT_TYPES.OPERATOR]: this.operatorRef,
      [E_USER_DOCUMENT_TYPES.PERMIT]: this.permitRef
    }
  }

  public isDocumentEditing(document: IDocument, index: number): boolean {
    return !document.type || index === this.activeIndex
  }

  public toggleDocumentAdd(): void {
    this.isDocumentButtonClicked = !this.isDocumentButtonClicked;
  }

  public get documentTypes(): IUserType[] {
    return USER_DOCUMENTS_TYPES;
  }

  public get documentIdTypes(): IIdType[] {
    return USER_ID_TYPES;
  }

  public selectDocumentType(type: E_USER_DOCUMENT_TYPES): void {
    this.activeDocumentType = type;
    this.buildDocumentForm(type);
  }

  public documentCardTemplate(type: E_USER_DOCUMENT_TYPES): TemplateRef<Document> {
    return this.documentTemplateFactory[type];
  }

  public getDocumentCardLabel(document: IDocument): string {
    if (!document.type) {
      return USER_DOCUMENTS_TYPES.find(other => other.type === this.activeDocumentType).label;
    }
    return USER_DOCUMENTS_TYPES.find(other => other.type === document.type).label;
  }

  private buildDocumentForm(type: E_USER_DOCUMENT_TYPES): void {
    let fields = { }

    if (type === E_USER_DOCUMENT_TYPES.ID_DOCUMENT) {
      fields = {
        idType: [''],
        country: ['', Validators.required],
        expirationDate: ['', Validators.required],
        number: [0, Validators.required]
      }
    }

    if (type === E_USER_DOCUMENT_TYPES.OPERATOR) {
      fields = {
        issuingAuthority: ['', Validators.required],
        expirationDate: ['', Validators.required],
        comment: ['']
      }
    }

    if (type === E_USER_DOCUMENT_TYPES.PERMIT) {
      fields = {
        company: ['', Validators.required],
        numberLicense: ['', Validators.required],
        region: ['', Validators.required],
        expirationDate: ['', Validators.required],
        comment: ['']
      }
    }

    if (type === E_USER_DOCUMENT_TYPES.INSURANCE) {
      fields = {
        company: ['', Validators.required],
        number: ['', Validators.required],
        totalInsured: ['', Validators.required],
        expirationDate: [0, Validators.required]
      }
    }

    this.documentForm = this.formBuilder.group(fields);
  }

  public getFiles(): void {
    if (!this.documents?.length) return;
    const filter = { _id :{ $in: this.documents
          .filter(d => !!d.file)
          .map(d => ({ $oid: d.file })) } }
    ;
    this.fileService.findMany(filter, {}, 0 ,this.documents.length, { "path":0 })
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(response => this.files = response.data || [])
  }

  public getFileLink(fileId: string): string {
    return this.files.find(file => file._id === fileId)?.webViewLink || '';
  }

  public addDocument(document: IDocument): void {
    if (!this.attachmentId) return;
    let newDocument: IDocument = this.documentForm.value;

    newDocument = {
      ...newDocument,
      file: this.attachmentId,
      type: document.type || this.activeDocumentType
    };

    let newDocuments;

    if (document.type) {
      newDocument.id = this.documents[this.activeIndex].id;
      newDocuments = this.documents.map((document, index) => {
        if (index === this.activeIndex) {
          return newDocument;
        }
        return document;
      })
    } else {
      newDocuments = [
        newDocument,
        ...this.documents,
      ]
    }

    this.documentsChange.emit(newDocuments);

    this.activeDocumentType = null;
    this.attachmentId = null;
    this.isDocumentButtonClicked = false;
    this.activeIndex = -1;
  }

  public deleteDocument(id: string): void {
    this.documentsChange.emit(
      this.documents.filter((document) => document.id !== id)
    )
  }

  public getTypeIdLabel(idType: E_USER_ID_TYPE): string {
    return USER_ID_TYPES.find(other => other.type === idType)?.label || idType;
  }

  public openEditor(index: number, document: IDocument): void {
    this.activeIndex = index;
    this.buildDocumentForm(document.type);

    this.documentForm.patchValue(document);

    this.attachmentId = document.file;
  }
  public cancelEditing(): void {
    this.activeIndex = -1;
    this.activeDocumentType = null;
    this.isDocumentButtonClicked = false;
    this.attachmentId = null;
  }

  public ngAfterViewInit(): void {
    this.initFactory();

    this.buildDocumentForm(E_USER_DOCUMENT_TYPES.ID_DOCUMENT)
  }

  public ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }
}
