import { BehaviorSubject } from 'rxjs';
import { Injectable, Renderer2 } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SidenavNavigationService {

  folded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMobile = false;
  isXSmall = false;
  nativeElement: HTMLElement;
  opened = !this.isMobile; // Default closed on mobile and opened on non-mobile
  opened$: BehaviorSubject<boolean> = new BehaviorSubject(this.opened);
  renderer: Renderer2;
  resized$: BehaviorSubject<number> = new BehaviorSubject(256);

  private sidenav: MatSidenav;

  /**
   * Close this sidenav, and return a Promise that will resolve when it's fully closed (or get rejected if it didn't).
   *
   * @returns Promise<MatDrawerToggleResult>
   */
  close(): Promise<MatDrawerToggleResult> {
    this.opened$.next(false);

    return this.sidenav.close();
  }

  /**
   * Fold this sidenav
   */
  fold(): void {
    this.folded$.next(true);

    this.renderer.addClass(this.nativeElement, 'folded');
    this.renderer.setStyle(this.nativeElement, 'width', '72px');
    // Broadcast the change
    this.resized(72);
  }

  /**
   * Open this sidenav, and return a Promise that will resolve when it's fully opened (or get rejected if it didn't).
   *
   * @returns Promise<MatDrawerToggleResult>
   */
  open(): Promise<MatDrawerToggleResult> {
    this.opened$.next(true);

    return this.sidenav.open();
  }

  /**
   * Called when sidenav was resized.
   */
  resized(width: number): void {
    this.resized$.next(width);
  }

  /**
   * Setter for sidenav.
   */
  setSidenav(sidenav: any): void {
    this.sidenav = sidenav;
    this.nativeElement = sidenav._elementRef.nativeElement;
  }

  /**
   * Toggle fold/unfold this sidenav with fold(), unfold()
   */
  toggleFold(): void {
    if (this.folded$.value) {
      this.unfold();
    } else {
      this.fold();
    }
  }

  /**
   * Toggle open/close this sidenav with open(), close() and return a promise with the result
   */
  toggleOpen(): Promise<MatDrawerToggleResult> {
    return this.sidenav.toggle(!this.opened$.value);
  }

  /**
   * Unfold this sidenav
   */
  unfold(): void {
    this.folded$.next(false);

    this.renderer.removeClass(this.nativeElement, 'folded');
    this.renderer.setStyle(this.nativeElement, 'width', '256px');
    // Broadcast the change
    this.resized(256);
  }

}
