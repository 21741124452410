import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent {

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: {
    confirm?: boolean,
    error?: any,
    inform?: boolean,
    message?: string,
    status?: string,
    title?: string
  }) { }

}
