import { OrdersService } from './../../shared/orders/orders.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { LoginStateService } from './login-state.service';

@Injectable()
export class LoginService {

  constructor(
      private http: HttpClient,
      private loginStateService: LoginStateService,
      private ordersService: OrdersService
  ) { }

  login(email: string, password: string): Observable<Object> {
    return this.http.post(`${environment.apiPath}login`, JSON.stringify({ email, password }));
  }

  logout(): void {
    this.loginStateService.removeLogin(); // it will remove login and loggedUser from the local storage
    this.ordersService.removeOrders(); // it will remove ordersCache from the local storage
  }

  authenticateWithGasoidcMercedes() {
    return this.http.get(`${environment.apiPath}gasoidc/link`);
  }
}
