<header [class.folded]="folded" [dir]="dir">
  <mat-toolbar
      class="header-toolbar"
      role="toolbar">

    <button
        *ngIf="showMenuButton"
        aria-label="Menu"
        class="toggle-navbar"
        (click)="toggleNavbar()"
        mat-icon-button
        title="{{ 'MENU' | translate }}"
        type="button">
      <mat-icon aria-label="Menu">menu</mat-icon>
    </button>

    <a
        *ngIf="showLogo"
        class="logo"
        draggable="false"
        mat-button
        [routerLink]="[{ outlets: { primary: 'home', detail: null }}]">
      <mat-icon aria-label="logo" svgIcon="logo"></mat-icon>
      <span aria-label="title" class="title">
        <span class="bold-logo">FAIR</span>
        <span class="regular-logo">FLEET</span>
      </span>
    </a>

    <button
        *ngIf="showBackButton"
        class="back-button"
        (click)="goBack()"
        mat-icon-button
        title="{{ 'BACK' | translate }}"
        type="button">
      <mat-icon class="rtl-mirror">arrow_back</mat-icon>
    </button>

    <button
        *ngIf="showCancelButton"
        class="cancel-button"
        (click)="cancel()"
        mat-icon-button
        title="{{ 'CANCEL' | translate }}"
        type="button">
      <mat-icon>cancel</mat-icon>
    </button>

    <nav
        *ngIf="!searchOpened && !selectedCount"
        class="header-title droppable"
        role="navigation">

      <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
        <ng-container *ngIf="!isXSmall || (isXSmall && i === breadcrumbs.length - 1)">
          <a
            draggable="false"
            mat-button
            [attr.fileId]="breadcrumb.id"
            [routerLink]="breadcrumb.link"
            (click)="breadcrumb.link ? UpdateSidenavDetail(breadcrumb.id,breadcrumb.label,breadcrumb.link) : reloadPage()"
            type="button"
            class="droppable">{{ i ? breadcrumb.label : (breadcrumb.label | translate) }}</a>

          <mat-icon
            *ngIf="i < breadcrumbs.length - 1"
            class="rtl-mirror">chevron_right</mat-icon>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="viewMode === 'map'">
        <mat-icon class="rtl-mirror">chevron_right</mat-icon>

        <a
          draggable="false"
          mat-button
          type="button">
          <mat-select [ngModel]="mapMode" (ngModelChange)="setMapMode($event)">
            <mat-option [value]="'empty'">
              <span>{{ 'MAPS.PLAIN' | translate }}</span>
            </mat-option>
            <mat-option [value]="'orders'">
              <span>{{ 'MAPS.ORDERS' | translate }}</span>
            </mat-option>
            <mat-option [value]="'pilots'" *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(login?.accountType) || isCemsUser()">
              <span>{{ 'MAPS.PILOTS' | translate }}</span>
            </mat-option>
          </mat-select>
        </a>

<!--        <a-->
<!--          draggable="false"-->
<!--          mat-button-->
<!--          [routerLink]="breadcrumb.link"-->
<!--          (click)="breadcrumb.link ? null : reloadPage()"-->
<!--          type="button">{{ i ? breadcrumb.label : (breadcrumb.label | translate) }}</a>-->

<!--        <mat-select>-->
<!--          <mat-option>-->
<!--            <span>None</span>-->
<!--            <span>Orders</span>-->
<!--            <span>Pilots</span>-->
<!--          </mat-option>-->
<!--        </mat-select>-->
      </ng-container>
    </nav>

    <div *ngIf="selectedCount" class="header-title">
      {{ (selectedCount === 1 ? 'ITEM' : 'ITEMS') | translate: { count: selectedCount } }}
    </div>

    <div class="fill-remaining"></div>

    <ng-container *ngIf="showSearch">
      <button
          *ngIf="!searchOpened && !isInspection"
          (click)="toggleSearch($event, searchAutocompleteTrigger)"
          mat-icon-button
          title="{{ 'SEARCH' | translate }}"
          type="button">
        <mat-icon>search</mat-icon>
      </button>

      <mat-form-field
          *ngIf="searchOpened"
          class="search-field"
          [ngStyle]="{ 'width': ordersService.activeOrder$.value ? 'calc(100% - 610px)' : '100%' }"
          color="default">
        <mat-label>{{ 'SEARCH' | translate }}</mat-label>
        <input
            #searchInput
            #searchAutocompleteTrigger="matAutocompleteTrigger"
            [attr.tabindex]="searchOpened ? null : -1"
            (keyup.enter)="search(searchString, true)"
            [matAutocomplete]="searchAuto"
            matInput
            [(ngModel)]="searchString"
            (ngModelChange)="search(searchString)">

        <button
            aria-label="Clear"
            [attr.tabindex]="searchOpened ? null : -1"
            class="clear-button"
            (click)="toggleSearch($event, searchAutocompleteTrigger)"
            mat-icon-button
            matSuffix
            title="{{ 'HIDE_SEARCH' | translate }}"
            type="button">
          <mat-icon>clear</mat-icon>
        </button>

        <mat-autocomplete
            #searchAuto="matAutocomplete"
            class="search-autocomplete"
            mdDropdownItems
            (optionSelected)="searchAutocompleteSelected($event)">
          <ng-container *ngIf="searchAutocomplete && !searchString">
            <ng-container *ngFor="let opt of searchAutocomplete">

              <mat-optgroup *ngIf="opt.options; else option; let options" [label]="opt.label | translate">
                <mat-option *ngFor="let option of options" [value]="option.value">
                  <mat-icon>{{ option.icon }}</mat-icon>
                  <span>{{ option.label | translate }}</span>
                </mat-option>
              </mat-optgroup>

              <ng-template #option>
                <mat-option [value]="opt.value">
                  <mat-icon>{{ opt.icon }}</mat-icon>
                  <span>{{ opt.label | translate }}</span>
                </mat-option>
              </ng-template>

            </ng-container>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <!-- *ngIf="showShareButton" -->
    <button
      *ngIf="showShareButton"
      (click)="share()"
      mat-icon-button
      title="{{ (selectedCount > 1 ? 'SHARING.SHARE_FILES' : 'SHARING.SHARE_FILE') | translate }}"
      type="button">
      <mat-icon [style.color]="'#2979ff'">ios_share</mat-icon>
    </button>



    <button
        *ngIf="showDownloadButton"
        (click)="download()"
        mat-icon-button
        title="{{ (selectedCount > 1 ? 'FILES.DOWNLOAD' : 'FILE.DOWNLOAD') | translate }}"
        type="button">
      <mat-icon [style.color]="'#2979ff'">cloud_download</mat-icon>
    </button>

    <button
        *ngIf="showDownloadButton && permissionsService.permissions$.value?.canHide.files"
        (click)="hide(true)"
        mat-icon-button
        title="Hide files"
        type="button">
      <mat-icon [style.color]="'#2979ff'">visibility_off</mat-icon>
    </button>

    <button
        *ngIf="showDownloadButton && permissionsService.permissions$.value?.canHide.files"
        (click)="hide(false)"
        mat-icon-button
        title="Unhide files"
        type="button">
      <mat-icon [style.color]="'#2979ff'">visibility</mat-icon>
    </button>

    <button
        *ngIf="showDeleteButton && permissionsService.permissions$.value?.canDelete.files"
        (click)="delete()"
        mat-icon-button
        title="{{ (selectedCount > 1 ? 'FILES.DELETE' : 'FILE.DELETE') | translate }}"
        type="button">
      <mat-icon>delete</mat-icon>
    </button>

    <button
        *ngIf="(showZipButton || showRegenerateThumbnailsButton || showColumnsButton || showFilterButton) && !isInspection"
        class="more-button"
        mat-icon-button
        [matMenuTriggerFor]="moreMenu"
        title="{{ 'MORE' | translate }}"
        type="button">
      <mat-icon *ngIf="!showFilterButton">more_vert</mat-icon>
      <mat-icon *ngIf="showFilterButton">filter_alt</mat-icon>
    </button>

    <mat-menu #moreMenu="matMenu">
      <button
          *ngIf="showZipButton"
          (click)="createZip()"
          mat-menu-item
          type="button">
        <mat-icon>archive</mat-icon>
        <span>{{ 'FILES.CREATE_ZIP' | translate }}</span>
      </button>

      <button
          *ngIf="showConvert2JPGButton"
          (click)="convert2JPG()"
          [matTooltipDisabled]="showConvert2JPGTooltip"
          mat-menu-item
          matTooltip="{{selectedConvertibleCount}} / {{selectedCount}}&#13;{{ 'FILES.CONVERTIBLE' | translate }} / {{ 'FILES.SELECTED' | translate }}"
          matTooltipClass="tooltip-multiline"
          type="button">
        <mat-icon>photo_library</mat-icon>
        <span>{{ 'FILES.CONVERT_TO_JPG' | translate }}</span>
      </button>

      <button
          *ngIf="!isProduction && showWatermarkButton"
          (click)="watermark()"
          [matTooltipDisabled]="showRegenerateThumbnailsTooltip"
          mat-menu-item
          matTooltip="{{selectedRegenerableCount}} / {{selectedCount}}&#13;{{ 'FILES.IMPRINTABLE' | translate }} / {{ 'FILES.SELECTED' | translate }}"
          matTooltipClass="tooltip-multiline"
          type="button">
        <mat-icon>branding_watermark</mat-icon>
        <span>{{ 'FILES.IMPRINT_WATERMARK' | translate }}</span>
      </button>

      <button
          *ngIf="showRegenerateThumbnailsButton"
          (click)="regenerateThumbnails()"
          [matTooltipDisabled]="showRegenerateThumbnailsTooltip"
          mat-menu-item
          matTooltip="{{selectedRegenerableCount}} / {{selectedCount}}&#13;{{ 'FILES.REGENERABLE' | translate }} / {{ 'FILES.SELECTED' | translate }}"
          matTooltipClass="tooltip-multiline"
          type="button">
        <mat-icon>photo_size_select_large</mat-icon>
        <span>{{ 'FILES.REGENERATE_THUMBNAILS' | translate }}</span>
      </button>

      <button
      *ngIf="showBentleyButton || showImogentButton || showRaptorMapsButton"
          mat-menu-item
          [matMenuTriggerFor]="partnersMenu"
          type="button">
        <mat-icon>view_column</mat-icon>
        <span>partners</span>
      </button>

      <button
          *ngIf="showColumnsButton"
          mat-menu-item
          [matMenuTriggerFor]="columnsMenu"
          type="button">
        <mat-icon>view_column</mat-icon>
        <span>{{ 'SHOW_COLUMNS' | translate }}</span>
      </button>

      <button
        *ngIf="showFilterButton && mapMode === 'orders'"
        mat-menu-item
        [matMenuTriggerFor]="filterMenu"
        type="button">
        <mat-icon>filter_alt</mat-icon>
        <span>{{ 'FILTER_ORDERS' | translate }}</span>
      </button>

      <button
        *ngIf="showFilterButton && mapMode === 'orders'"
        mat-menu-item
        [matMenuTriggerFor]="verifyMenu"
        type="button">
        <mat-icon>filter_alt</mat-icon>
        <span>{{ 'FILTER_VERIFY' | translate }}</span>
      </button>

      <button
        *ngIf="showFilterButton && mapMode === 'pilots'"
        mat-menu-item
        [matMenuTriggerFor]="pilotTestFilterMenu"
        type="button">
        <mat-icon>filter_alt</mat-icon>
        <span>{{ 'PILOTS.FILTER_TEST' | translate }}</span>
      </button>

      <button
        *ngIf="showFilterButton && mapMode === 'pilots'"
        mat-menu-item
        [matMenuTriggerFor]="pilotTagFilterMenu"
        type="button">
        <mat-icon>filter_alt</mat-icon>
        <span>{{ 'PILOTS.FILTER_TAG' | translate }}</span>
      </button>

      <mat-divider *ngIf="showCheckDataButton"></mat-divider>

      <button
          *ngIf="showCheckDataButton && activeFolder!=='my-files '&& hasSubFolder === false"
          (click)="matchData()"
          mat-menu-item
          type="button">
        <mat-icon>join_inner</mat-icon>
        <span>{{ 'MATCH_DATA.TITLE' | translate }}</span>
      </button>
      <mat-divider *ngIf="showCheckDataButton && activeFolder!=='my-files' && hasSubFolder === false"></mat-divider>
      <button
          *ngIf="showCheckDataButton"
          (click)="checkData()"
          mat-menu-item
          type="button">
        <mat-icon>fact_check</mat-icon>
        <span>{{ 'CHECK_DATA.TITLE' | translate }}</span>
      </button>
    </mat-menu>

    <mat-menu #partnersMenu="matMenu">

      <button
          *ngIf="showBentleyButton"
          (click)="bentleyGetEquipment()"
          mat-menu-item
          type="button">
        <mat-icon svgIcon="bentley_logo"></mat-icon>
        <span>{{ 'FILES.BENTLEY_GETEQUIPMENT' | translate }}</span>
      </button>

      <button
          *ngIf="showImogentButton"
          (click)="sendToImogent()"
          mat-menu-item
          type="button">
        <mat-icon svgIcon="imogent_logo"></mat-icon>
        <span>{{ 'FILES.SEND_TO_IMOGENT' | translate }}</span>
      </button>

      <button
          *ngIf="showRaptorMapsButton"
          (click)="sendToRaptorMaps()"
          mat-menu-item
          type="button">
        <mat-icon svgIcon="raptor_maps_logo"></mat-icon>
        <span>{{ 'FILES.SEND_TO_RAPTOR_MAPS' | translate }}</span>
      </button>
    </mat-menu>

    <mat-menu #columnsMenu="matMenu">
      <ng-template matMenuContent> <!-- Use lazy rendering for fix Mobile Safari bug -->
        <button
            *ngFor="let column of columns"
            [disabled]="column.mayNotBeHidden"
            mat-menu-item
            (click)="toggleColumn($event, column.value)">
          <mat-icon>{{ displayedColumns.indexOf(column.value) > -1 ? 'check' : '' }}</mat-icon>
          {{ column.label | translate }}
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #filterMenu="matMenu">
      <ng-template matMenuContent> <!-- Use lazy rendering for fix Mobile Safari bug -->
        <button
          (click)="filterOrders($event, undefined)"
          mat-menu-item>
          <mat-icon>{{ !filterStatus ? 'check' : '' }}</mat-icon>
          {{ 'ORDERS.GROUP_ALL' | translate }}
        </button>
        <button
          *ngFor="let group of statusGroups"
          (click)="filterOrders($event, group)"
          mat-menu-item>
          <mat-icon>{{ filterStatus === group ? 'check' : '' }}</mat-icon>
          {{ 'ORDERS.GROUP_' + group.toUpperCase() | translate }}
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #verifyMenu="matMenu">
      <ng-template matMenuContent> <!-- Use lazy rendering for fix Mobile Safari bug -->
        <button
          (click)="verifyOrders($event, 3)"
          mat-menu-item>
          <mat-icon>{{ verifyStatus === 3 ? 'check' : '' }}</mat-icon>
          {{ 'ORDERS.FILTER_3_MONTHS' | translate }}
        </button>
        <button
          (click)="verifyOrders($event, 6)"
          mat-menu-item>
          <mat-icon>{{ verifyStatus === 6 ? 'check' : '' }}</mat-icon>
          {{ 'ORDERS.FILTER_6_MONTHS' | translate }}
        </button>
        <button
          (click)="verifyOrders($event, undefined)"
          mat-menu-item>
          <mat-icon>{{ !verifyStatus ? 'check' : '' }}</mat-icon>
          {{ 'ORDERS.FILTER_ANY_TIME' | translate }}
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #pilotTestFilterMenu="matMenu">
      <ng-template matMenuContent> <!-- Use lazy rendering for fix Mobile Safari bug -->
        <button
          (click)="$event.stopImmediatePropagation(); pilots.toggleTestFilter(false)"
          mat-menu-item>
          <mat-icon>{{ pilots.hasTestFilter(false) ? 'check' : '' }}</mat-icon>
          {{ 'PILOTS.FILTER_TEST_FALSE' | translate }}
        </button>
        <button
          (click)="$event.stopImmediatePropagation(); pilots.toggleTestFilter(true)"
          mat-menu-item>
          <mat-icon>{{ pilots.hasTestFilter(true) ? 'check' : '' }}</mat-icon>
          {{ 'PILOTS.FILTER_TEST_TRUE' | translate }}
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #pilotTagFilterMenu="matMenu">
      <ng-template matMenuContent> <!-- Use lazy rendering for fix Mobile Safari bug -->
        <button *ngFor="let tag of pilots.tags"
          (click)="$event.stopImmediatePropagation(); pilots.toggleTagFilter(tag)"
          mat-menu-item>
          <mat-icon>{{ pilots.hasTagFilter(tag) ? 'check' : '' }}</mat-icon>
          {{ tag.toUpperCase() }}
        </button>
      </ng-template>
    </mat-menu>

    <mat-divider *ngIf="ordersService.activeOrder$.value && permissionsService.permissions$.value?.canCollaborateOn.orders && permissionsService.permissions$.value?.canCreate.collaborations" vertical="true"></mat-divider>
    <button mat-raised-button
        *ngIf="ordersService.activeOrder$.value && permissionsService.permissions$.value?.canCollaborateOn.orders && permissionsService.permissions$.value?.canCreate.collaborations"
        color="accent"
        class="primary-button" (click)="toggleCollaborationDialog()">
          <mat-icon class="primary-button-icon">person_add</mat-icon>
          {{ 'COLLABORATION.ACTION.COLLABORATE' | translate | uppercase }}
    </button>

    <mat-divider *ngIf="(viewMode && viewMode !== 'map' || showToggleInfoButton) && !isInspection" vertical="true"></mat-divider>

    <button
        *ngIf="viewMode && headerService.showRegenerateThumbnailsButton && !isInspection"
        (click)="setViewMode(viewMode === 'grid' ? 'list' : 'grid')"
        mat-icon-button
        title="{{ (viewMode === 'grid' ? 'FILES.LIST_VIEW' : 'FILES.GRID_VIEW') | translate }}"
        type="button">
      <mat-icon *ngIf="viewMode === 'grid'">view_list</mat-icon>
      <mat-icon *ngIf="viewMode === 'list'">view_module</mat-icon>
    </button>

    <button
        *ngIf="showToggleInfoButton && !isInspection"
        class="info-button"
        (click)="toggleInfo()"
        mat-icon-button
        title="{{ 'INFO' | translate }}"
        type="button">
      <mat-icon>info</mat-icon>
    </button>

    <!-- <a
      *ngIf="showNewOrderButton"
      href="https://fairfleet360.com/map-selection"
      color="accent"
      mat-raised-button>
      {{ 'NEW_ORDER' | translate }}
    </a> -->
  </mat-toolbar>
</header>
