<div #wrapper class="wrapper">
  <div *ngIf="noImage" class="noImage">
    {{ 'INSPECTION.UI.NO-IMAGE' | translate }}
  </div>
    <div class="toolBar">
      <div class="UI vertical-toolbar IR"  *ngIf="permissionsService.permissions$.value?.canCreate.annotations">
        <div (click)="setNewComment()" class="annotationButton" [ngClass]="{ 'active': addingNormalAnnotation }" matTooltip="{{'FILE.ANNOTATIONS.NEW_ANNOTATIONS' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>

      <div class="UI vertical-toolbar IR" *ngIf="permissionsService.permissions$.value?.canRead.files">
        <div (click)="displayPanoPopup()" matTooltip="{{'PANO.VIEW' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon svgIcon="360"></mat-icon>
        </div>
      </div>


      <div class="UI vertical-toolbar IR" *ngIf="permissionsService.permissions$.value?.canRead.files">
        <div *ngIf=" fileModel?.thermalSubFileModel || fileModel?.type === 'Thermal'" (click)="ExtendToolbar()" [ngClass]="{ 'active': showToolBar }" matTooltip="{{'TOOLTIP.ACTIONS.ACTIVATE_THERMAL_TOOLS' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon svgIcon="IR"></mat-icon>
        </div>
        <div *ngIf="showToolBar && (fileModel?.thermalSubFileModel || fileModel?.type === 'Thermal')" [ngClass]="{ 'active': measureSpotActive}" (click)="measure()" matTooltip="{{'TOOLTIP.ACTIONS.MEASURE_A_SPOT' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon svgIcon="target"></mat-icon>
        </div>
        <div *ngIf="showToolBar && (fileModel?.thermalSubFileModel || fileModel?.type === 'Thermal')" [ngClass]="{ 'active': measureAreaActive}"  (click)="measureArea()" matTooltip="{{'TOOLTIP.ACTIONS.MEASURE_AN_AREA' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon svgIcon="rectangle"></mat-icon>
        </div>
        <div class="toolWithSlider" *ngIf="showToolBar && fileModel?.thermalSubFileModel" [ngClass]="{ 'active': showOpacity }" [matTooltip]="showOpacity ? '' : 'TOOLTIP.ACTIONS.OPACITY'| translate" [matTooltipPosition]="'left'">
          <mat-icon (click)="extendOpacityBar()" svgIcon="opacity"></mat-icon>
          <div class="bar " *ngIf="showOpacity">
            <mat-slider class="slider"
              max="100"
              min="0"
              step="1"
              [(ngModel)]="value"
              (ngModelChange)="onSliderChange($event)">
              <input matsliderthumb>
            </mat-slider>
          </div>
        </div>
        <div class="toolWithSlider" *ngIf="showToolBar" [ngClass]="{ 'active': showToolPallets }" [matTooltip]="showToolPallets ? '' : 'TOOLTIP.ACTIONS.CHANGE_THERMAL_PALETTE'| translate" [matTooltipPosition]="'left'" >
          <mat-icon (click)="extendPalettesBar()" svgIcon="palette"></mat-icon>
            <div class="bar-2" *ngIf="showToolPallets">
              <div class="pallet" (click)="convertPalette('arctic')" [ngClass]="{ 'activeBlue': actualPalette ==='arctic'}" >
                <img alt="Arctic"
                  src="/assets/images/icons/Arctic.svg"
                  matTooltip="Arctic" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('fulgurite')" [ngClass]="{ 'activeBlue': actualPalette ==='fulgurite'}">
                <img alt="Fulgurite"
                  src="/assets/images/icons/Fulgurite.svg"
                  matTooltip="Fulgurite" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('hotiron')" [ngClass]="{ 'activeBlue': actualPalette ==='hotiron'}">
                <img alt="Hot Iron"
                  src="/assets/images/icons/Hot Iron.svg"
                  matTooltip="Hot Iron" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('ironed')" [ngClass]="{ 'activeBlue': actualPalette ==='ironed'}">
                <img alt="Ironed"
                  src="/assets/images/icons/Iron Red.svg"
                  matTooltip="Ironed" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('medical')" [ngClass]="{ 'activeBlue': actualPalette ==='medical'}">
                <img alt="Medical"
                  src="/assets/images/icons/Medical.svg"
                  matTooltip="Medical" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('rainbow1')" [ngClass]="{ 'activeBlue': actualPalette ==='rainbow1'}">
                <img alt="Rainbow 1"
                  src="/assets/images/icons/Rainbow 1.svg"
                  matTooltip="Rainbow 1" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('rainbow2')" [ngClass]="{ 'activeBlue': actualPalette ==='rainbow2'}">
                <img alt="Rainbow 2"
                  src="/assets/images/icons/Rainbow 2.svg"
                  matTooltip="Rainbow 2" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('tint')" [ngClass]="{ 'activeBlue': actualPalette ==='tint'}">
                <img alt="Tint"
                  src="/assets/images/icons/Tint.svg"
                  matTooltip="Tint" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('whitehot')" [ngClass]="{ 'activeBlue': actualPalette ==='whitehot'}">
                <img alt="White Hot"
                  src="/assets/images/icons/White Hot.svg"
                  matTooltip="White Hot" [matTooltipPosition]="'below'">
              </div>
              <div class="pallet" (click)="convertPalette('blackhot')" [ngClass]="{ 'activeBlue': actualPalette ==='blackhot'}">
                <img alt="Black Hot"
                  src="/assets/images/icons/Black Hot.svg"
                  matTooltip="Black Hot" [matTooltipPosition]="'below'">
              </div>
            </div>
            <div class="bar-3" *ngIf=" showToolPallets && temperatureSliderInitialized">
              <mat-label> {{limitMin}}°C</mat-label>
              <ngx-slider
              class="slider"
              [(value)]="minTemperature"
              [(highValue)]="maxTemperature"
              [options]="options"
              (userChangeEnd)="onTemperatureSliderChange()">
              </ngx-slider>
              <mat-label> {{limitMax}}°C</mat-label>
            </div>
        </div>
        <div class="toolWithSlider" *ngIf="showToolBar" [ngClass]="{ 'active': showIsotherm }" [matTooltip]="showIsotherm ? '' : 'Isotherm'" [matTooltipPosition]="'left'">
          <mat-icon (click)="extendIsothermBar()" svgIcon="isotherm"></mat-icon>
          <div class="isotherm-bar" *ngIf=" showIsotherm && temperatureSliderInitialized">
            <mat-label> {{minFocus}}°C</mat-label>
            <ngx-slider
            class="slider"
            [(value)]="minFocus"
            [(highValue)]="maxFocus"
            [options]="optionsIsotherm"
            (userChangeEnd)="onTemperatureFocusSliderChange()">
            </ngx-slider>
            <mat-label> {{maxFocus}}°C</mat-label>
            <mat-checkbox [(ngModel)]="isothermActivation" (change)="onChangeIsothermActivation()"></mat-checkbox>
          </div>
        </div>
      </div>
      <div class="UI vertical-toolbar">
        <div *ngIf="!isFullscreen" matTooltip="{{'TOOLTIP.ACTIONS.FULL_SCREEN' | translate}}" [matTooltipPosition]="'left'">
        <mat-icon (click)="toggleFullScreen()" svgIcon="screen"></mat-icon>
      </div>
      <div *ngIf="isFullscreen" matTooltip="Exit full screen" [matTooltipPosition]="'left'">
        <mat-icon (click)="toggleFullScreen()" fontSet="material-icons-outlined">fullscreen_exit</mat-icon>
      </div>
        <div matTooltip="{{'TOOLTIP.ACTIONS.RESET_TO_INITIAL_POSITION' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon (click)="resetView()" svgIcon="reset"></mat-icon>
        </div>
        <div matTooltip="{{'TOOLTIP.ACTIONS.ZOOM_IN' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon (click)="changeZoom(+1)" svgIcon="zoomin" ></mat-icon>
        </div>
        <div matTooltip="{{'TOOLTIP.ACTIONS.ZOOM_OUT' | translate}}" [matTooltipPosition]="'left'">
          <mat-icon (click)="changeZoom(-1)" svgIcon="zoomout"></mat-icon>
        </div>
      </div>
    </div>
    <div>
    </div>
  <div #imageMap class="map-container">
  </div>
  <mat-progress-bar
      *ngIf="loading"
      color="accent"
      mode="indeterminate" ></mat-progress-bar>
</div>
