<div
    [class.dark]="this.theme && this.theme.includes('dark')"
    class="header mat-toolbar-single-row">
    <app-inspection-filter></app-inspection-filter>
</div>
<div
    #wrapper
    [class.dark]="this.theme && this.theme.includes('dark')"
    class="wrapper">
  <div
      #resizableHorizontal="mwlResizable"
      [style.width.px]="horizontal"
      (resizing)="resizingHorizontal($event)"
      class="viewerRapper"
      mwlResizable>

    <div class="verticalWrapper">
      <div *ngComponentOutlet="viewer3d"></div>
      <div class="horizontalBorder">
        <mat-icon
            [resizableContainer]="resizableHorizontal"
            [resizeEdges]="{right: true}"
            class="handleVertical"
            mwlResizeHandle
            svgIcon="resize_handle_vertical_arrows"></mat-icon>
      </div>
    </div>
  </div>

  <div
      #resizableHorizontal="mwlResizable"
      (resizing)="resizingVertical($event)"
      [style.height.px]="vertical"
      [style.left.px]="horizontal"
      class="imageWrapper"
      mwlResizable>
    <app-ol-image></app-ol-image>
    <div class="verticalBorder">
        <mat-icon *ngIf="files.viewMode === 'grid'" (click)="filesViewChange()">view_module</mat-icon>
        <mat-icon *ngIf="files.viewMode === 'list'" (click)="filesViewChange()">view_list</mat-icon>
        <span (click)="filesViewChange()">{{ 'INSPECTION.VIEW.CONTENT' | translate }}</span>
        <span class="small" *ngIf="files.viewMode === 'list'" (click)="filesViewChange()">(Grid view)</span>
        <span class="small" *ngIf="files.viewMode === 'grid'" (click)="filesViewChange()">(List view)</span>
      <mat-icon
          [resizableContainer]="resizableHorizontal"
          [resizeEdges]="{bottom: true}"
          class="handleHorizontal"
          mwlResizeHandle
          svgIcon="resize_handle_vertical_arrows"></mat-icon>
    </div>
  </div>

  <div
      [style.left.px]="horizontal"
      [style.top.px]="vertical"
      class="filesWrapper">
    <app-files #files></app-files>
  </div>
</div>
