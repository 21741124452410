import { EventManager, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class CustomEventManager extends EventManager {

  constructor(
      private platform: Platform,
      @Inject(EVENT_MANAGER_PLUGINS) plugins: Array<any>,
      private zone: NgZone
  ) {
    super(plugins, zone);
  }

  // CustomEventManager doesn't catch notations like 'document:eventName.not-mobile'
  // Be sure to implement a check inside the HostListener function like this
  //
  // @HostListener('document:keydown', ['$event', '$event.target'])
  // onKeyDown(event: KeyboardEvent, target: HTMLElement): void {
  //   if (!target || this.isMobile) {
  //     return;
  //   }
  // }
  addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
    // Attach event if not on mobile
    if (eventName.indexOf('.not-mobile') > -1) {
      if (!this.platform.ANDROID && !this.platform.IOS) {
        eventName = eventName.replace('.not-mobile', '');
      } else {
        return () => false;
      }
    }

    // Attach event only on mobile
    if (eventName.indexOf('.is-mobile') > -1) {
      if (this.platform.ANDROID || this.platform.IOS) {
        eventName = eventName.replace('.is-mobile', '');
      } else {
        return () => false;
      }
    }

    // Use .out-zone flag to dramatically increase performance for certain events
    // Trigger eventual rendering using Renderer2 or ChangeDetectorRef
    // Examples: (dragover.out-zone), (mousemove.out-zone), (scroll.out-zone)
    if (eventName.indexOf('.out-zone') > -1) {
      eventName = eventName.replace('.out-zone', '');

      return this.zone.runOutsideAngular(() => {
        // Event handler will be executed outside of the Angular context.
        return super.addEventListener(element, eventName, handler);
      });
    }

    return super.addEventListener(element, eventName, handler); // Default behavior
  }
}
