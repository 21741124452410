import { FilesService } from './../../pages/files/files.service';
import { FileModel } from './../../pages/files/file/file.model';
import { Injectable } from '@angular/core';
import { SeverityToColor } from './../ol-image/ol-image.service';
import { Annotation, AnnotationsStateDimension } from './../../pages/files/file/sidebar/annotations/annotation.model';

/* OpenLayers */
import { Circle as CircleStyle, Icon, Fill, Stroke, Style, Text } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer, WebGLTile } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Feature from 'ol/Feature';
import GeoTIFF from 'ol/source/GeoTIFF';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import TileLayer from 'ol/layer/WebGLTile';
import { LineString } from 'ol/geom';
import { getWidth } from 'ol/extent';

@Injectable()
export class OLLayerService {

  constructor(public filesService: FilesService) {}
  
  extent: [number, number, number, number];
  styles: { [name: string]: Style } = {};//Style for Annotations

  addImages(map: Map, images: FileModel[], zoomToImages = false, labelImages = false): void {
    this.styles.positionStyle = this.createPositionStyle(SeverityToColor['not_assessed'] + "66");
    this.styles.headingStyle = this.createHeadingStyle(SeverityToColor['not_assessed']);

    this.styles.positionStyleSelected = this.createPositionStyle(SeverityToColor['selected'])
    this.styles.headingStyleSelected = this.createHeadingStyle(SeverityToColor['selected'])

    this.styles.positionStyleAdvisory = this.createPositionStyle(SeverityToColor['advisory'])
    this.styles.headingStyleAdvisory = this.createHeadingStyle(SeverityToColor['advisory'])

    this.styles.positionStyleLow = this.createPositionStyle(SeverityToColor['low'])
    this.styles.headingStyleLow = this.createHeadingStyle(SeverityToColor['low'])

    this.styles.positionStyleMiddle = this.createPositionStyle(SeverityToColor['middle'])
    this.styles.headingStyleMiddle = this.createHeadingStyle(SeverityToColor['middle'])

    this.styles.positionStyleHigh = this.createPositionStyle(SeverityToColor['high'])
    this.styles.headingStyleHigh = this.createHeadingStyle(SeverityToColor['high'])

    this.styles.positionStyleUrgent = this.createPositionStyle(SeverityToColor['urgent'])
    this.styles.headingStyleUrgent = this.createHeadingStyle(SeverityToColor['urgent'])

     // Marker
     const vectorSource = new VectorSource({
      features: []
    })

    images.map((file) => {
      if (file.geo) {
        const stateDimension = this.getStateDimension(file.annotations);
        let label = '';
        if (file.inspection.fileID) {
          label = String(file.inspection.fileID);
        }
        if (file.yaw) {
          const newFeature = new Feature({
            geometry: new Point(fromLonLat([file.geo.coordinates[0], file.geo.coordinates[1] ])),
            id: file._id,
            label: label,
            rotation: this.deg2rad(this.filesService.getImageYaw(file)),
            stateDimension: stateDimension
          })
          newFeature.setId(String(file._id))
          vectorSource.addFeature(newFeature)
        } else {
          const newFeature = new Feature({
            geometry: new Point(fromLonLat([file.geo.coordinates[0], file.geo.coordinates[1] ])),
            id: file._id,
            label: label,
            stateDimension: stateDimension
          });
          newFeature.setId(String(file._id));
          vectorSource.addFeature(newFeature);
        }
      }
    });
    let text = new Style();

    map.addLayer(new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        if (labelImages) {
          text.setText(new Text({
            textAlign: 'center',
            text: feature.get('label'),
            font: '16px Calibri,sans-serif',
            fill: new Fill({
              color: '#000'
            }),
            stroke: new Stroke({
              color: '#FFF',
              width: 3
            }),
            offsetY: -18,
            padding: [2,0,2,2]
          }));
        }
        //labelStyle.getText().setText(feature.get('altitude'));
        if ( feature.get('selected') === true) {
          this.styles.headingStyleSelected.getImage().setRotation(feature.get('rotation'));
          return [this.styles.positionStyleSelected, this.styles.headingStyleSelected, text];
        } else {
          if (feature.get('stateDimension') === 'advisory') {
            this.styles.headingStyleAdvisory.getImage().setRotation(feature.get('rotation'));
            return [this.styles.positionStyleAdvisory, this.styles.headingStyleAdvisory, text];
          } else if (feature.get('stateDimension') === 'low') {
            this.styles.headingStyleLow.getImage().setRotation(feature.get('rotation'));
            return [this.styles.positionStyleLow, this.styles.headingStyleLow, text];
          } else if (feature.get('stateDimension') === 'middle') {
            this.styles.headingStyleMiddle.getImage().setRotation(feature.get('rotation'));
            return [this.styles.positionStyleMiddle, this.styles.headingStyleMiddle, text];
          } else if (feature.get('stateDimension') === 'high') {
            this.styles.headingStyleHigh.getImage().setRotation(feature.get('rotation'));
            return [this.styles.positionStyleHigh, this.styles.headingStyleHigh, text];
          } else if (feature.get('stateDimension') === 'urgent') {
            this.styles.headingStyleUrgent.getImage().setRotation(feature.get('rotation'));
            return [this.styles.positionStyleUrgent, this.styles.headingStyleUrgent, text];
          }
          this.styles.headingStyle.getImage().setRotation(feature.get('rotation'));
          return [this.styles.positionStyle, this.styles.headingStyle, text];
        }
      },
      zIndex: 105
    }));

    if(zoomToImages) {
      if(vectorSource.getFeatures().length > 0) {
        map.getView().fit(vectorSource.getExtent(), {padding:[25, 25, 25, 25], maxZoom:20})
      }
    }
  }

  addDem(map, orthoModel, demValues) {
    if (orthoModel.tags?.includes('dem') && orthoModel.mimeType === 'image/tiff') {
        const stepValue = (demValues.max - demValues.min) / 4;

        // Define the color style for elevation data visualization
        const styleDSM = {
            color: [
                'case',
                ['==', ['band', 2], 0],
                '#00000000',
                [
                    'interpolate',
                    ['linear'],
                    ['band', 1],
                    0, [0, 0, 0, 0],
                    demValues.min, [43, 131, 186],
                    demValues.min + stepValue * 1, [171, 221, 164],
                    demValues.min + stepValue * 2, [255, 255, 191],
                    demValues.min + stepValue * 3, [253, 174, 97],
                    demValues.max, [215, 25, 28],
                ]
            ]
        };

        // Set up GeoTIFF source with options directly
        const geoTiffSource = new GeoTIFF({
            normalize: false,  // Applying normalize at the root level
            sources: [
                {
                    url: orthoModel.webViewLink,
                },
            ],
        });

        // Create a layer using the GeoTIFF source
        const geoTiffLayer = new TileLayer({
            source: geoTiffSource,
            style: styleDSM,
            zIndex: 49,
            opacity: 0,
            className: "GeoTiff Ortho DSM"
        });

        // Fit map view to the GeoTIFF layer extent
        geoTiffSource.getView().then((viewOptions) => {
            map.getView().fit(viewOptions.extent);
        });

        map.addLayer(geoTiffLayer);
        return geoTiffSource;
    }
}

  addOrtho(map: Map, orthoModel: FileModel): GeoTIFF {
    if (orthoModel.tags?.includes('orthomosaic') && orthoModel.mimeType === 'image/tiff') {
      const geoTiffSource = new GeoTIFF({
        sources: [
          {
            url: orthoModel.webViewLink,
          },
        ],
      });
      const geoTiffLayer = new TileLayer({
        source: geoTiffSource,
        zIndex: 50,
        className: "GeoTiff Ortho"
      })
      geoTiffSource.getView().then((viewOptions) => map.getView().fit(viewOptions.extent));
      map.addLayer(geoTiffLayer);
      return geoTiffSource;
    }
  }

  getDemLayers(map: Map): any[]{
    let results=[]
    map.getAllLayers().forEach((layer)=>{
      if (layer.getClassName()=='GeoTiff Ortho DSM'){
        results.push(layer)
      }
    })
    return results
  }

  addOrthoWebGLTileLayer(map: Map, orthoModel: FileModel, layerName: string): TileLayer {
    if (orthoModel.tags?.includes('orthomosaic') && orthoModel.mimeType === 'image/tiff') {
      const geoTiffSource = new GeoTIFF({
        sources: [
          {
            url: orthoModel.webViewLink,
          },
        ],
      });

      const geoTiffLayer = new TileLayer({
        source: geoTiffSource,
        zIndex: 1,
        className: layerName
      });
      geoTiffLayer.set('legacyId', orthoModel.orderID )
      map.addLayer(geoTiffLayer);
      return geoTiffLayer;
    }
    return null;
  }

  addDSMWebGLTileLayer(map: Map, orthoModel: FileModel, layerName: string): TileLayer {
    if (orthoModel.tags?.includes('dem') && orthoModel.mimeType === 'image/tiff') {
      const stepValue = (orthoModel.dem.max-orthoModel.dem.min)/4
      const styleDSM = {
        color: [
          'case',
          ['==', ['band', 2], 0],
          '#00000000',
          [
            'interpolate',
            ['linear'],
            ['band', 1],
            // elevationValues with RGB(A) colorValues
            0,
            [0, 0, 0, 0],
            orthoModel.dem.min,
            [43, 131, 186],
            orthoModel.dem.min + stepValue * 1,
            [171, 221, 164],
            orthoModel.dem.min + stepValue * 2,
            [255, 255, 191],
            orthoModel.dem.min + stepValue * 3,
            [253, 174, 97],
            orthoModel.dem.max,
            [215, 25, 28],
          ]
        ],
      };
      const geoTiffSource = new GeoTIFF({
        normalize: false,
        sources: [
          {
            url: orthoModel.webViewLink,
          },
        ],
      });
      const geoTiffLayer = new TileLayer({
        source: geoTiffSource,
        style: styleDSM,
        zIndex: 0,
        className: layerName
      })
      geoTiffLayer.set('legacyId', orthoModel.orderID )
      map.addLayer(geoTiffLayer);
      return geoTiffLayer;
    }
    return null
  }

  public setLocation(measureFeature: Feature) {
    let location: any = {}
      switch (measureFeature.getGeometry().getType()) {
        case 'Point':
          location.coordinates = (measureFeature.getGeometry() as Point).getCoordinates();
          location.type = 'Point'
          break;
        case 'LineString':
          location.coordinates = (measureFeature.getGeometry() as LineString).getCoordinates();
          location.type = 'LineString'
          break;
        case 'Polygon':
          location.coordinates = (measureFeature.getGeometry() as Polygon).getCoordinates(); 
          location.type = 'Polygon'
          break;
      }
    return location
  }

  updateDSMWebGLTileLayerSource(DSMLayer: WebGLTile, url:string): void{
    DSMLayer.setSource(new GeoTIFF({
      normalize: false,
      sources: [
        {
          url: url,
        },
      ],
    }));
  }

  updateWebGLTileLayerSource(Layer: WebGLTile, url:string): void{
    Layer.setSource(new GeoTIFF({
      sources: [
        {
          url: url,
        },
      ],
    }));
  }

  extractInteriorPixelsOfExtent(map: Map, poly: Polygon, geoTiffLayer: WebGLTile) {

    const interiorPixels = [];
    const extent = poly.getExtent();
    const fixedResolution = 0.1; // Your desired resolution

  
    // Get world width for modulo calculation
    const view = map.getView();
    const worldWidth = getWidth(view.getProjection().getExtent()) / fixedResolution;

    let totalCells = 0
    let count = 0

    for (let y = extent[3]; y >= extent[1]; y -= fixedResolution) {
        const row = [];
        for (let x = extent[0]; x <= extent[2]; x += fixedResolution) {
            const pixelCoord = [x, y];
            totalCells++

            // Apply modulo calculation for consistent x-coordinate wrapping
            const pixel = map.getPixelFromCoordinate(pixelCoord);
            const pixelX = ((pixel[0] % worldWidth) + worldWidth) % worldWidth;
            const pixelY = pixel[1];

            const correctedPixelCoord = [pixelX, pixelY];

            if (poly.intersectsCoordinate(pixelCoord)) {
                // Fetch elevation data using corrected pixel coordinates

                count++
                const elevation = geoTiffLayer.getData(correctedPixelCoord)?.[0];
                row.push({ coordinates: correctedPixelCoord, elevation });
            } else {
                row.push(null);
            }
        }
        interiorPixels.push(row);
    }
    console.log('total cells: ', totalCells)
    console.log('number of pixels with elevation: ', count)
    return interiorPixels;
  }



  gatherAllPixels(map: Map, interiorPixels: any, geoTiffLayer: WebGLTile): any[] {
    let line = []
    interiorPixels.forEach((coordinate)=>{
      if (coordinate){
        
        const pixel = map.getPixelFromCoordinate(coordinate);
        line.push({ coordinates: coordinate, elevation: geoTiffLayer.getData(pixel)?.[0] });
      } else {
        line.push(undefined);
      }
    })
    return line
  }

  extractElevations(pixels): number[][] {
    return pixels.map(row => this.extractElevationsLine(row));
  }

  extractElevationsLine(row): number[] {
    return row.map(pixel => (pixel ? pixel.elevation : undefined));
  }
  
  getAnnotationExtent(annotation: Annotation): number[] {
    if (annotation.coordinates) {
      const poly = new Feature({
        geometry: new Polygon(annotation.coordinates),
      });
      const colorPolygon = SeverityToColor[annotation.stateDimension] || 'blue';
      poly.setStyle(new Style({
        fill: new Fill({ color: 'transparent' }),
        stroke: new Stroke({ color: colorPolygon, width: 3 })
      }));
      return poly.getGeometry().getExtent()
    }
  }

  getStateDimension(annotations: Annotation[]): string {
    let stateDimension = '';
    if (annotations) {
      if (annotations.some(annotation => annotation.stateDimension === AnnotationsStateDimension['urgent'])) {
        stateDimension = 'urgent'
      } else if (annotations.some(annotation => annotation.stateDimension === AnnotationsStateDimension['high'])) {
        stateDimension = 'high'
      } else if (annotations.some(annotation => annotation.stateDimension === AnnotationsStateDimension['middle'])) {
        stateDimension = 'middle'
      } else if (annotations.some(annotation => annotation.stateDimension === AnnotationsStateDimension['advisory'])) {
        stateDimension = 'advisory'
      } else if (annotations.some(annotation => annotation.stateDimension === AnnotationsStateDimension['low'])) {
        stateDimension = 'low'
      }
    }
    return stateDimension
  }

  createPositionStyle(fillColor: string): Style {
    return new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: fillColor}),
        stroke: new Stroke({
          color: 'black',
          width: 1,
        }),
      }),
    });
  }

  createHeadingStyle(fillColor: string): Style {
    return new Style({
      image: new Icon({
        anchor: [12, 26],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        color: fillColor,
        imgSize: [24, 24],
        src: '/assets/images/icons/marker_direction.svg',
        scale: 0.75
      })
    });
  }

  private deg2rad(deg){
    return deg * Math.PI / 180;
  }
}
