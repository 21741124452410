import { RectangularBase } from './RectangularBase';
import { SvgHelper } from './SvgHelper';
import { TextState } from './TextState';

export class Text extends RectangularBase {

  protected readonly MIN_SIZE = 50;
  private inDoubleTap = false;
  private targetRoot: HTMLElement;
  private textarea: HTMLTextAreaElement;
  private textElement: SVGTextElement;

  constructor() {
    super();
    this.type = 'Text';
  }

  static draw = (): Text => {
    const text = new Text();
    text.setup();

    return text;
  };

  deselect(): void {
    super.deselect();
    this.editSet();
  }

  getState(): TextState {
    const state: TextState = Object.assign(
      { text: this.text }, super.getState());

    return state;
  }

  renderText = () => {
    const LINE_SIZE = '1.2em';

    while (this.textElement.lastChild) {
      this.textElement.removeChild(this.textElement.lastChild);
    }

    const lines = this.text.split(/\r\n|[\n\v\f\r\x85\u2028\u2029]/);
    for (let line of lines) {
      if (line.trim() === '') {
        line = ' '; // workaround for swallowed empty lines
      }
      this.textElement.appendChild(SvgHelper.createTSpan(line, [['x', '0'], ['dy', LINE_SIZE]]));
    }

    setTimeout(this.sizeText, 10);
  };

  restoreState(state: TextState): void {
    this.text = state.text;
    super.restoreState(state);
    this.renderText();
  }

  protected resize(x: number, y: number): void {
    super.resize(x, y);
    this.sizeText();
  }

  protected setup(): void {
    super.setup();
    this.textElement = SvgHelper.createText();
    this.addToRenderVisual(this.textElement);
    SvgHelper.setAttributes(this.visual, [['class', 'drawing text']]);

    this.textElement.transform.baseVal.appendItem(SvgHelper.createTransform()); // translate transform
    this.textElement.transform.baseVal.appendItem(SvgHelper.createTransform()); // scale transform

    this.renderText();

    this.visual.addEventListener('dblclick', this.onDblClick);
    this.visual.addEventListener('touchstart', this.onTap);
  }

  private editClose = () => {
    this.visual.style.removeProperty('display');
    this.targetRoot.removeChild(this.textarea);
    this.textarea = null;
  };

  private editSet = () => {
    if (this.textarea) {
      this.text = this.textarea.value.trim() ? this.textarea.value : this.DEFAULT_TEXT;
      this.renderText();
      this.editClose();
      this.stateToGeoJSON();
    }
  };

  private editText = () => {
    if (!this.textarea) {
      this.targetRoot = this.visual.closest('div');

      this.textarea = document.createElement('textarea');
      if (this.text !== this.DEFAULT_TEXT) {
          this.textarea.value = this.text;
      }

      const rectRoot = this.targetRoot.getBoundingClientRect();
      const rectVisual = this.visual.getBoundingClientRect();
      this.textarea.style.setProperty('position', 'absolute');
      this.textarea.style.setProperty('top', `${rectVisual.top - rectRoot.top + 5}px`);
      this.textarea.style.setProperty('left', `${rectVisual.left - rectRoot.left + 5}px`);
      this.textarea.style.setProperty('width', `${rectVisual.width - 15}px`);
      this.textarea.style.setProperty('height', `${rectVisual.height - 15}px`);

      this.textarea.style.setProperty('background', 'none');
      this.textarea.style.setProperty('border', 'none');
      this.textarea.style.setProperty('color', 'white');
      this.textarea.style.setProperty('font-size', '1.2em');
      this.textarea.style.setProperty('text-align', 'center');

      this.textarea.addEventListener('keydown', this.onKeyDown);

      this.targetRoot.appendChild(this.textarea);

      this.visual.style.setProperty('display', 'none');
      this.textarea.focus();
    }
  };

  private onDblClick = (ev: MouseEvent) => {
    this.editText();
  };

  private onKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();

      this.editClose();
    }
  };

  private onTap = (ev: TouchEvent) => {
    if (this.inDoubleTap) {
      this.inDoubleTap = false;
      this.editText();
    } else {
      this.inDoubleTap = true;
      setTimeout(() => { this.inDoubleTap = false; }, 300);
    }
  };

  private sizeText = () => {
    const textSize = this.textElement.getBBox();
    let x = 0;
    let y = 0;
    let scale = 1.0;
    if (textSize.width > 0 && textSize.height > 0) {
      const xScale = this.width * 1.0 / textSize.width;
      const yScale = this.height * 1.0 / textSize.height;
      scale = Math.min(xScale, yScale);

      x = (this.width - textSize.width * scale) / 2;
      y = (this.height - textSize.height * scale) / 2;
    }

    this.textElement.transform.baseVal.getItem(0).setTranslate(x, y);
    this.textElement.transform.baseVal.getItem(1).setScale(scale, scale);
  };
}
