import { Inspection3dService } from './../../pages/files/inspection-3d/inspection-3d.service';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-antenna-table',
  templateUrl: './antenna-table.component.html',
  styleUrls: ['./antenna-table.component.scss']
})
export class AntennaTableComponent {
  data = {
    'Antenna': [],
    'Micro Wave': [],
    'RRU': []
  }
  displayedColumns: string[] = ['name', 'manufacturer', 'model', 'face', 'azimuth', 'tilt', 'size'];
  

 private ngDestroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AntennaTableComponent>,
    private inspection3dService: Inspection3dService
  ) {
    this.inspection3dService.dataAntenna$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.data['Antenna'] = data
    });
    
    this.inspection3dService.dataMicroWave$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.data['Micro Wave'] = data
    });
    
    this.inspection3dService.dataRRU$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      this.data['RRU'] = data
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  calcLength(inputValue: number): number {
    return inputValue/39.37;
  }

}
