import { Component, Inject, Input, OnDestroy, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { FileModel } from '../../file.model';
import { FilesService } from '../../../files.service';
import { SidebarService } from '../sidebar.service';
import { ThemeService } from '../../../../../shared/theme/theme.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/shared/dialog/dialog.service';

@Component({
  selector: 'app-sidebar-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit,OnChanges, OnDestroy {

  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input() fileModel: FileModel;
  formShare: FormGroup;
  overlay = false;
  passwortSet = false;
  poiWithImages = false;
  popupIFrame = false;
  popupLink = false;
  shared = false;
  theme: string;
  private ngDestroy$ = new Subject();

  constructor(
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    public filesService: FilesService,
    private sidebarService: SidebarService,
    private themeService: ThemeService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.formShare = this.formBuilder.group({
      downloadable: new FormControl(false),
      public: new FormControl(false),     
      legalCheckbox: new FormControl(false),
      legalCheckboxPoi: new FormControl(false),
    });

  }

  get downloadable(): AbstractControl { return this.formShare.get('downloadable'); }
  get public(): AbstractControl { return this.formShare.get('public'); }
  get legalCheckbox(): AbstractControl { return this.formShare.get('legalCheckbox'); }
  get legalCheckboxPoi(): AbstractControl { return this.formShare.get('legalCheckboxPoi'); }
  get publicLink(): string { return `${environment.host}shared/${this.fileModel._id}`; }

  checkPoiImagePermissions(): void {
    this.fileModel.pano?.hotspots?.forEach(hotspot => {
      if (hotspot.imageID) {
        this.filesService.findOne(hotspot.imageID.toString())
        .subscribe(
        response => {
          if (response) {
            const fileModel = response.data
            if (!fileModel.public) {
              fileModel.public = true
              const fileUpdates = {
                "_id": fileModel._id,
                "public": fileModel.public,
              }
              this.makePublic(fileUpdates);   
            }
          }
        })
      }
    })
  }

  disableShareButton(): boolean {
    return !this.formShare.value.legalCheckbox
    // Enable Button when both checkboxes are toggled
    if (this.poiWithImages) {
      return !(this.formShare.value.legalCheckbox && this.formShare.value.legalCheckboxPoi)
    } else {
      return !this.formShare.value.legalCheckbox
    }
  }

  downloadIframe(): void {
    this.setState('popupIFrame', true);

    const a = document.createElement('a');
    const htmlData = `<div style="max-width: 100%; padding-bottom: 56.25%; position: relative; height: 0; overflow: hidden;"> <iframe webkitAllowFullScreen mozallowfullscreen allowFullScreen frameborder="0" style="position: absolute; top: 0; left:0; width: 100%; height:100%" src="${this.publicLink}"> </iframe></div>`;
    const dataURI = `data:text/html;base64,${btoa(htmlData)}`;
    a.href = dataURI;
    a['download'] = `iframe_${this.fileModel.name}.html`;
    const e = document.createEvent('MouseEvents');
    // Use of deprecated function to satisfy TypeScript.
    e.initMouseEvent('click', true, false,
        document.defaultView, 0, 0, 0, 0, 0,
        false, false, false, false, 0, null);
    a.dispatchEvent(e);
  }

  ngOnChanges(): void {
    this.setFile();
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  openURL(url): void {
    window.open(url);
  }

  removePublic(): void {
    this.setState('shared', false);
    this.setState('passwortSet', false);

    this.fileModel.public = false
    const fileUpdates = {
      "_id": this.fileModel._id,
      "public": this.fileModel.public,
    }
    this.makePublic(fileUpdates);
  }

  setFile(): void {
    this.poiWithImages = false;
    this.fileModel.pano?.hotspots?.forEach(hotspot => {
      if (hotspot.imageID) {
        this.poiWithImages = true;
      }
    })

    this.formShare?.reset({
      downloadable: this.fileModel.downloadable? this.fileModel.downloadable : false,
      public: this.fileModel.public? this.fileModel.public : false ,
      legalCheckbox: this.fileModel.public? this.fileModel.public : false,
      legalCheckboxPoi: this.fileModel.downloadable? this.fileModel.downloadable : false,
    });
      this.overlay = false;
      this.popupLink = false;
      this.popupIFrame = false;
      this.shared = false;
  }

  toggleMakePublic(): void {
    this.setState('shared', this.public.value)
    if (!this.public.value) {
      this.setState('passwortSet', false)
    }
    this.fileModel.public = this.public.value
    
    const fileUpdates = {
      "_id": this.fileModel._id,
      "public": this.fileModel.public,
    }
    this.makePublic(fileUpdates);
    this.checkPoiImagePermissions();
  }

  toggleMakeDownloadable(){
    this.fileModel.downloadable = this.downloadable.value
    const fileUpdates = {
      "_id": this.fileModel._id,
      "public": this.fileModel.public,
    }
    this.makeDownloadable(fileUpdates);
  }

  setState(state: string, value: boolean): void {
    switch (state) {

      case 'shared': {
        this.shared = value;
        this.downloadable.setValue(false);
        //this.legalCheckbox.setValue(false);
        this.legalCheckboxPoi.setValue(false);
        break;
      }
      case 'popupLink': {
        this.overlay = value;
        this.popupLink = value;
        break;
      }
      case 'popupIFrame': {
        this.overlay = value;
        this.popupIFrame = value;
        break;
      }
      case 'popupClose': {
        this.overlay = value;
        this.popupLink = value;
        this.popupIFrame = value;
        break;
      }
      default: {
        break;
      }
    }

  }

  makeDownloadable(fileUpdates): void {
    this.fetchingChange$.next(true);
    this.filesService.makeDownloadable(this.fileModel._id.toString(), this.downloadable.value)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe( () => {
      this.fileModel.downloadable = this.downloadable.value;
      this.fileModel = {
        ...this.fileModel,
        ...{ modified: new Date() }
      };
      this.filesService.updateItem(this.fileModel);
      this.fetchingChange$.next(false);
    })
  }

  makePublic(fileUpdates: FileModel): void {
    this.fetchingChange$.next(true);

    this.filesService.makePublic(fileUpdates._id.toString(),fileUpdates.public)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      () => {
          // update data models
          this.fileModel.public = fileUpdates.public
          this.fileModel = {
            ...this.fileModel,
            ...{ modified: new Date() }
          };
          this.filesService.updateItem(this.fileModel);
          this.fetchingChange$.next(false);
      },
      error => {
        this.fetchingChange$.next(false);
      }
    );
  }

  hide(fileId,value) {
    this.filesService.hideFiles([fileId],value)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      (response) => {
        this.fileModel.hidden = false;
        if (value) {
          this.dialogService.showDialog("share.Component: File was hidden Successfully", null, null,null,null,true);
        } else {
          this.dialogService.showDialog("share.Component: File was unhidden Successfully", null, null,null,null,true);
        }
      },
      (error) => {
        this.snackBar.open(this.translate.instant('FILES.FILE_HIDE_ERROR'), this.translate.instant('FILES.CLOSE'), {
          duration: 2000,
        });
      }
    );
  }

}
