import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {Pilot} from "../../../shared/pilots/pilots.model";
import {ActivatedRoute, Router} from "@angular/router";
import {SidenavDetailService} from "../../../shared/sidenav/sidenav-detail/sidenav-detail.service";
import {LazyLoadService} from "../../../shared/helpers/lazy-load.service";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer} from "@angular/platform-browser";
import {takeUntil} from "rxjs/operators";
import {PilotsService} from "../../../shared/pilots/pilots.service";

@Component({
  selector: 'app-pilot',
  templateUrl: './pilot.component.html',
  styleUrls: ['./pilot.component.scss']
})
export class PilotComponent implements OnInit {

  public fetching = true;
  private ngDestroy$ = new Subject();
  public pilot?: Pilot;
  public pilotID?: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private pilots: PilotsService,
              private sidenavDetailService: SidenavDetailService,
              private lazyLoadService: LazyLoadService,
              public translate: TranslateService,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(params => {
        if (params) {
          const pilotID = params['pilotID'];
          if (pilotID && this.pilot?.id !== pilotID) {
            this.getPilot(pilotID);
          }
        }
      });
  }

  private async getPilot(id) {
    this.fetching = true;
    this.pilot = await this.pilots.getPilot(id);
    this.pilotID = id;
    this.fetching = false;
  }

  public close() {
    if (this.sidenavDetailService.opened$.value) {
      this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([{ outlets: { primary: 'files', detail: null }}], { queryParamsHandling: 'merge' });
    }
  }

}
