<h2>{{ 'SITE.MODAL.LINK' | translate }}:</h2>
<form (submit)="submit()">
  <mat-form-field [class.mat-form-field-invalid]="tagsInput.invalid">
    <mat-label>{{ 'SITE.MODAL.ORDER_ID' | translate }}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let tag of tags; let i = index;"
        [removable]="removable"
        [selectable]="selectable"
        (removed)="tagsRemove(i)">
        {{tag}}
<!--        TODO: Add later -->
<!--        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
      </mat-chip>

      <input
        #tagsInputField
        [formControl]="tagsInput"
        [matChipInputFor]="chipList"
        (keydown.enter)="tagsSelected($event)"
        matInput>
    </mat-chip-list>
  </mat-form-field>
  <div class="controls">
    <button type="button" mat-stroked-button color="accent" (click)="close()">{{ 'CANCEL' | translate }}</button>
    <button
      type="submit"
      mat-raised-button
      color="accent"
      [disabled]="tagsInput.invalid"
    >{{ 'SAVE' | translate }}</button>
  </div>
</form>



