import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent {

  public shareType: 'link' | 'email' = 'link';
  public emails = [];
  public text = '';
  public link?;
  public copied = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public dialogRef: MatDialogRef<ShareLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {files: string[], project: string, address: string, orderId: string},
    private http: HttpClient,
    private clipboard: Clipboard,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  remove(index: number) {
    this.emails.splice(index, 1);
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emails.push(value);
    }

    if (input) {
      input.value = '';
    }
  }

  async share() {
    const url = `${environment.apiHost}sharing/share`;

    const resp = await this.http.post<any>(url, {
      emails: this.emails,
      files: this.data.files,
      text: this.text,
      language: this.translate.currentLang,
      project: this.data.project,
      address: this.data.address,
      orderId: this.data.orderId,
    }).toPromise();

    this.link = resp.data.link;
    this.copied = false;

    if (this.shareType === 'email') {
      this.snackBar.open(await this.translate.get('SHARING.TOASTS.SENT').toPromise(), undefined, {
        duration: 2500,
      });
      this.dialogRef.close();
    }
  }

  async copy() {
    this.clipboard.copy(this.link);
    this.copied = true;
    this.snackBar.open(await this.translate.get('SHARING.TOASTS.COPIED').toPromise(), undefined, {
      duration: 2500,
    });
  }

  emailsValid(): boolean {
    if (this.shareType !== 'email') {
      return true;
    }
    if (!this.emails || this.emails.length === 0) {
      return false;
    }
    for (const email of this.emails) {
      if (email.indexOf('@') === -1) {
        return false;
      }
    }
    return true;
  }

}
