<h2>{{ 'PORTFOLIO.MODAL.CREATE_PORTFOLIO' | translate }}:</h2>
<form [formGroup]="form" (submit)="submit()">
  <mat-form-field>
    <mat-label>{{ 'PORTFOLIO.MODAL.PORTFOLIO_NAME' | translate }}</mat-label>
    <input formControlName="name" matInput >
  </mat-form-field>

  <div class="controls">
    <button type="button" (click)="close()" mat-stroked-button color="accent">{{ 'CANCEL' | translate }}</button>
    <button type="submit" mat-raised-button color="accent">{{ 'SAVE' | translate }}</button>
  </div>
</form>


