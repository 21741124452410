import { Permission } from './../../pages/files/file/permission.model';

export class Folder {
  _id?: string | object;
  created?: Date | any;
  folderID?: string | object;
  isFolder?: boolean;
  mimeType?: string;
  modified?: Date | any | boolean;
  name?: string;
  orderID?: number;
  pattern?: string;
  tags?: Array<string>;

  // Computed read-only fields
  /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  children?: Array<Folder>;
  childrenCount?: number;
  parents?: Array<Folder>;

  // Flat node editable fields
  fetching?: boolean;
  level?: number;
}

export class Update {
  $currentDate?: Folder;
  $set?: Folder;
  $unset?: Folder;
}
