<div class="header">
  <h2>{{ 'PANO.VIEW' | translate }}</h2>
  <mat-icon (click)="close()">close</mat-icon>
</div>
<div class="body">
  <div class="image" id="container" [ngStyle]="{ 'width': data.panoList.length > 1 ? '84%' : '100%' }"></div>
  <div class="list" *ngIf="data.panoList.length > 1">
    <mat-table
    #table
    [dataSource]="data.panoList">
    <ng-container matColumnDef="imageColumn">
      <mat-cell *matCellDef="let row; let i = index"
      [ngStyle]="{ 'opacity': i === selectedRowIndex ? '1' : '0.5', 'cursor': i !== selectedRowIndex ? 'pointer' : 'default' }">
        <img
          class="mat-thumbnail-img checkerboard-background"
          draggable="false"
          src="{{row.thumbnailLink}}"
          alt="Image"
          (click)="getPano(row,i)">
      </mat-cell>
    </ng-container>
    <mat-row *matRowDef="let row; columns: ['imageColumn']"></mat-row>
  </mat-table>
  </div>
</div>
