<!-- Files container -->
<div
    #wrapperFiles
    id="files"
    cdkScrollable
    class="files"
    [class.dark]="theme && theme.includes('dark')"
    [class.scroll-disabled]="fetching"
    (contextmenu)="onContextMenu($event, null)"
    [dir]="dir"
    tabindex="0">

  <!-- Cards view -->
  <section
      #cards
      *ngIf="dataSourceCards.length !== 0"
      class="cards">

    <mat-card
        *ngFor="let file of dataSourceCards; let i = index;"
        [id]="file._id"
        [class.active]="file._id === lastIndexID"
        [class.dragging]="file.dragging"
        [class.droppable]="file.isFolder"
        [class.selected]="file.selected"
        [class.info]="!file.thumbnailLink || isMobile"
        [ngStyle]="{'width': 'calc( ' + 100 / cardsInRow + '% - 48px)'}"
        class="card"
        (contextmenu)="onContextMenu($event, file)"
        (dblclick.not-mobile)="onDblClick($event, file, i)"
        (mouseup.not-mobile)="onMouseUp($event, file, i)"
        [draggable]="!isMobile || file.dragging"
        (dragend)="onDragEnd($event)"
        (dragstart)="onDragStart($event)"
        (click)="onDblClick($event, file, i)">
        <div *ngIf="file.hidden && [accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType)" class="hidden-badge" data-text="Hidden" (click)="hide($event, file, false)"></div>
        <div
            *ngIf="file.thumbnailLink && !file?.mimeType?.startsWith('video')"
            [ngStyle]="{'background-image': 'url(' + file.thumbnailLink + ')'}"
            class="image">
            <mat-icon *ngIf="file.tags?.includes('360_stitched')" class="overlayIcon" disabled> 360 </mat-icon>
            <mat-icon *ngIf="file.tags?.includes('inspection')" class="overlayIcon inspect" svgIcon="fairfleet_inspect"></mat-icon>
        </div>

        <div
            *ngIf="file?.mimeType?.startsWith('video')"
            class="videoWrapper">
          <video
              [muted]="'muted'"
              crossorigin
              playsinline
              loop
              preload="auto"
              onmouseover="this.play()"
              onmouseout="this.pause();"
              onloadstart="this.currentTime = 1;"
              src="{{ file?.webViewLink }}">
          </video>
          <mat-icon class="overlayIcon" disabled> play_circle </mat-icon>
        </div>

        <div *ngIf="!file.thumbnailLink && !file?.mimeType?.startsWith('video')"
            class="icon">
          <mat-icon *ngIf="!file.tags?.includes('inspection')" disabled> {{ filesService.getFileIcon(file) }} </mat-icon>
          <mat-icon *ngIf="file.tags?.includes('inspection')" class="overlayIcon inspect darkColor" svgIcon="fairfleet_inspect"></mat-icon>
        </div>

        <mat-checkbox
            *ngIf="!isMobile && location === 'files'"
            [checked]="file.selected"
            (change)="$event ? toggleSelect(file) : null"
            (click)="$event.stopPropagation()"></mat-checkbox>

        <mat-card-header>
          <mat-card-title>
            {{ file.card?.name ? file.card?.name : file.name }}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <p>
            <span *ngIf="file?.size">{{(file?.size | size)}}</span>
            <span *ngIf="file?.width && file?.height"> | {{file?.width + 'x' + file?.height + ' px'}}</span>
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button
              mat-button
              (click)="showDetailsStopEvent($event, false, file)">
              {{ 'EDIT' | translate }}
          </button>
        </mat-card-actions>
    </mat-card>

  </section>

  <!-- Table view -->
  <mat-table
      #table
      [dataSource]="dataSource"
      matSort
      matSortActive="taken"
      matSortDirection="asc"
      matSortDisableClear
      [ngClass]="'c' + getDisplayedColumns.length"
      [class.hideTable]="hideTable"
      [style.display]="viewMode === 'list' ? 'block' : 'none'"
      [trackBy]="trackByIndex">

    <ng-container matColumnDef="avatar-checkbox">
      <mat-header-cell *matHeaderCellDef class="center">
        <mat-checkbox
            *ngIf="dataSource"
            (change)="$event ? toggleSelectAll() : null"
            [checked]="isLoadedSelected"
            [indeterminate]="isAnySelected"
            title="{{ (dataSource.length === totalItems ?
              (isTotalSelected ? 'DESELECT_ALL' : 'SELECT_ALL_TOTAL') :
              (isLoadedSelected ? 'DESELECT_RANGE' : 'SELECT_RANGE')) |
              translate: {
                count: dataSource.length,
                total: totalItems + (dataSource.length < totalItems && totalItems >= totalLimit && totalItems % pageSize === 0 && '+')
              }
            }}"></mat-checkbox>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        <mat-icon
            *ngIf="!filesService.canViewImage(row)"
            class="avatar-icon">{{ filesService.getFileIcon(row) }}</mat-icon>

        <img
            *ngIf="filesService.canViewImage(row)"
            alt="{{ row.name }}"
            class="mat-thumbnail-img checkerboard-background"
            draggable="false"
            src="{{ row.iconLink }}">

            <div
            *ngIf="filesService.canViewImage(row) && row.thermalSubFileModel"
            class="icon-container"
            draggable="false">
              <mat-icon class="x" fontSet="material-icons-outlined" [style.fontSize.px]="18">thermostat</mat-icon>
            </div>

        <mat-checkbox
            *ngIf="!isMobile"
            [checked]="row.selected"
            (change)="$event ? toggleSelect(row) : null"
            (click)="$event.stopPropagation()"
            tabindex="-1"></mat-checkbox>

        <mat-icon
            *ngIf="isMobile && row.selected"
            class="check-circle"
            color="accent">check_circle</mat-icon>
      </mat-cell>

      <mat-footer-cell *matFooterCellDef class="center">
        {{ 'PAGINATOR_RANGE' | translate: {
          start: 1,
          end: dataSource?.length,
          total: totalItems + (dataSource?.length < totalItems && totalItems >= totalLimit && totalItems % pageSize === 0 && '+')
        } }}
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="_id">
      <mat-header-cell
          *matHeaderCellDef
          class="x5"
          mat-sort-header
          start="desc">{{ 'ID' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row" class="x5">
        <span>{{ row._id }}</span>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell
          *matHeaderCellDef
          class="x5"
          dir="auto"
          mat-sort-header>{{ 'NAME' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x5"
          dir="auto">

        <a
            *ngIf="row?.isFolder && !(row && row.tags && (row.tags?.includes('inspection') || row.tags?.includes('inspection3d') || row.tags?.includes('viewer')))"
            (click)="navigateToFolder(row._id)"
            draggable="false"
            [routerLink]="['', { outlets: { primary: ['files', row._id], detail: null}}]"
            tabindex="-1"
            title="{{ 'OPEN_FOLDER'| translate }}">{{ row.name }}</a>
        <a
            *ngIf="!(folderModel && folderModel.tags && (folderModel.tags?.includes('inspection') || folderModel.tags?.includes('inspection3d'))) && !row?.isFolder"
            draggable="false"
            [routerLink]="['', { outlets: { detail: ['file', row._id, { view: 'fullscreen' }]}}]"
            tabindex="-1"
            title="{{ 'OPEN_FILE'| translate }}"> {{ row.name }}</a>
        <!-- URL for inspection-->
        <a
            *ngIf="folderModel && folderModel.tags && (folderModel.tags?.includes('inspection') || folderModel.tags?.includes('inspection3d')) && !row?.isFolder && location!=='inspection'"
            class="pointer"
            draggable="false"
            tabindex="-1"
            title="{{ 'OPEN_FILE'| translate }}"
            (click)="openInspection(row)">{{ row.name }}</a>
        <a
            *ngIf="folderModel && folderModel.tags && (folderModel.tags?.includes('inspection') || folderModel.tags?.includes('inspection3d')) && !row?.isFolder && location==='inspection'"
            class="pointer"
            draggable="false"
            tabindex="-1"
            title="{{ 'OPEN_FILE'| translate }}"
            (click)="openInspection(row)">{{ row.name | shortName }}</a>
        <a
            *ngIf="row?.isFolder && row && row.tags && row.tags?.includes('inspection')"
            class="pointer"
            draggable="false"
            tabindex="-1"
            title="{{ 'OPEN_FILE'| translate }}"
            (click)="openInspectionFolder(row)">{{ row.name }}</a>
        <a
            *ngIf="row?.isFolder && row && row.tags && row.tags?.includes('inspection3d')"
            class="pointer"
            draggable="false"
            tabindex="-1"
            title="{{ 'OPEN_FILE'| translate }}"
            (click)="openInspection3dFolder(row)">{{ row.name }}</a>
        <a
            *ngIf="row?.isFolder && row && row.tags && row.tags?.includes('viewer')"
            class="pointer"
            draggable="false"
            tabindex="-1"
            title="{{ 'OPEN_FILE'| translate }}"
            (click)="openViewer(row)">{{ row.name }}</a>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="inspection.fileID">
      <mat-header-cell
          *matHeaderCellDef
          class="x2"
          dir="auto"
          mat-sort-header>{{ 'FILE.ID' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x2"
          dir="auto">
        <span *ngIf="row.inspection && row.inspection.fileID">
          {{ row.inspection.fileID }}
        </span>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="hidden">
      <mat-header-cell
          *matHeaderCellDef
          class="x1"
          dir="auto"
          start="desc">{{ 'ORDER.VISIBILITY' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x1"
          dir="auto">
          <mat-icon>{{ row.hidden ? 'visibility_off' : '' }}</mat-icon>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x1"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="orderAddress">
      <mat-header-cell
          *matHeaderCellDef
          class="x5"
          dir="auto"
          start="desc">{{ 'ORDER.ADDRESS/PROJECT_NAME' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x5"
          dir="auto">{{ getAddress(row.orderID) }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="path">
      <mat-header-cell
          *matHeaderCellDef
          class="x3"
          dir="auto">{{ 'LOCATION' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x3"
          dir="auto">
        <a
            (click)="navigateToFolder(row.folderID || 'my-files')"
            draggable="false"
            [routerLink]="['', { outlets: { primary: ['files', row.folderID || 'my-files' ], detail: null}}]"
            tabindex="-1"
            title="{{ 'SHOW_PARENT_FOLDER'| translate }}">{{ row.path || ('FILES.MY_FILES' | translate) }}</a>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x3"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="mimeType">
      <mat-header-cell
          *matHeaderCellDef
          class="x3"
          dir="auto"
          mat-sort-header>{{ 'FILE.TYPE' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x3"
          dir="auto">{{ row.mimeType }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x3"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell
          *matHeaderCellDef
          class="right x1"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'FILE.SIZE' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="right x1"
          dir="auto">
        <span
            *ngIf="row.size"
            title="{{ (row.size | number) + ' B (' + (row.size | size:2:'binary') + ')' }}">{{ row.size | size }}</span>
      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="right x1"
          dir="auto">
        <span
            *ngIf="sumSize"
            title="{{ (sumSize | number) + ' B (' + (sumSize | size:2:'binary') + ')' }}">{{ sumSize | size }}</span>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="width">
      <mat-header-cell
          *matHeaderCellDef
          class="right x1"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'FILE.WIDTH' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="right x1"
          dir="auto">{{ row.width >= 0 ? row.width + ' px' : '' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="right x1"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="height">
      <mat-header-cell
          *matHeaderCellDef
          class="right x1"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'FILE.HEIGHT' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="right x1"
          dir="auto">{{ row.height >= 0 ? row.height + ' px' : '' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="right x1"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="duration">
      <mat-header-cell
          *matHeaderCellDef
          class="right x1"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'FILE.DURATION' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="right x1"
          dir="auto">{{ row.duration }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="right x1"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="tags">
      <mat-header-cell
          *matHeaderCellDef
          class="x3"
          dir="auto"
          mat-sort-header>{{ 'FILE.TAGS' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x3"
          dir="auto">{{ row.tags }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x3"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="orderID">
      <mat-header-cell
          *matHeaderCellDef
          class="right x1"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'ORDER.ID' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="right x1"
          dir="auto">{{ row.orderID }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="right x1"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="pattern">
      <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          class="x5"
          dir="auto">{{ 'FILE.PATTERN' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x5"
          dir="ltr">{{ row.pattern }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x5"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="taken">
      <mat-header-cell
          *matHeaderCellDef
          class="x2"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'FILE.TAKEN' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x2"
          dir="auto">{{ row.taken | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell
          *matHeaderCellDef
          class="x2"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'CREATED' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x2"
          dir="auto">{{ row.created | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="modified">
      <mat-header-cell
          *matHeaderCellDef
          class="x2"
          dir="auto"
          mat-sort-header
          start="desc">{{ 'MODIFIED' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x2"
          dir="auto">{{ row.modified | date:'dd.MM.yyyy HH:mm:ss' }}</mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="annotations.stateDimension">
      <mat-header-cell
          *matHeaderCellDef
          class="x4"
          dir="auto"
          start="desc"
          mat-sort-header>{{ 'FILE.ANNOTATIONS.ANNOTATIONS' | translate }}</mat-header-cell>

      <mat-cell
          *matCellDef="let row"
          class="x4"
          dir="auto">
          <span class="annotation advisory" *ngIf="row.annotationStats.advisory > 0">{{ formatAnnotations("advisory", row) }}</span>
          <span class="annotation low" *ngIf="row.annotationStats.low > 0">{{ formatAnnotations("low", row) }}</span>
          <span class="annotation middle" *ngIf="row.annotationStats.medium > 0">{{ formatAnnotations("medium", row) }}</span>
          <span class="annotation high" *ngIf="row.annotationStats.high > 0">{{ formatAnnotations("high", row) }}</span>
          <span class="annotation urgent" *ngIf="row.annotationStats.urgent > 0">{{ formatAnnotations("urgent", row) }}</span>

      </mat-cell>

      <mat-footer-cell
          *matFooterCellDef
          class="x2"
          dir="auto"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="more">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button
            *ngIf="!selectedCount"
            class="more-button"
            [disabled]="fetching"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{file: row}"
            tabindex="-1"
            title="{{ 'ACTIONS'| translate }}"
            type="button">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-icon
            *ngIf="!isMobile || row.selected"
            class="drag-handle"
            (mousedown.not-mobile)="$event.button === 0 && row.dragging = true"
            (mouseup.not-mobile)="$event.button === 0 && row.dragging = false"
            (touchstart.is-mobile)="row.dragging = true"
            (touchend.is-mobile)="row.dragging = false">drag_indicator</mat-icon>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="getDisplayedColumns; sticky: true"></mat-header-row>
    <mat-row
        *matRowDef="let row; let i = index; columns: getDisplayedColumns;"
        #matRow
        [id]="row._id"
        [class.active]="row._id === lastIndexID"
        [class.dragging]="row.dragging"
        [class.droppable]="row.isFolder"
        [class.selected]="row.selected"
        [class.hide]="row.tags?.includes('card')"
        (contextmenu)="onContextMenu($event, row)"
        (dblclick.not-mobile)="onDblClick($event, row, i)"
        [draggable]="row.dragging"
        (dragend)="onDragEnd($event)"
        (dragstart)="onDragStart($event)"
        (mousedown.not-mobile)="onMouseDown($event, row, i)"
        (mouseup.not-mobile)="onMouseUp($event, row, i)"
        (press.is-mobile)="onPress($event, row, i)"
        (tap.is-mobile)="onTap($event, row, i)"></mat-row>

    <mat-footer-row
        *matFooterRowDef="getDisplayedColumns"
        [class.hide]="!dataSource?.length"></mat-footer-row>
  </mat-table>

  <!-- Grid view -->
  <section
      *ngIf="viewMode === 'grid' && dataSource?.length"
      class="grid">

    <div
        *ngFor="let file of dataSourceNoCards; let i = index;"
        #matThumbnail
        [id]="file._id"
        [class.active]="file._id === lastIndexID"
        [class.dragging]="file.dragging"
        [class.droppable]="file.isFolder"
        [class.selected]="file.selected"
        [class.info]="!file.thumbnailLink || isMobile"
        class="thumbnail"
        (contextmenu)="onContextMenu($event, file)"
        (dblclick.not-mobile)="onDblClick($event, file, i)"
        [draggable]="!isMobile || file.dragging"
        (dragend)="onDragEnd($event)"
        (dragstart)="onDragStart($event)"
        (mousedown.not-mobile)="onMouseDown($event, file, i)"
        (mouseup.not-mobile)="onMouseUp($event, file, i)"
        (press.is-mobile)="onPress($event, file, i)"
        (tap.is-mobile)="onTap($event, file, i)"
        [style.flex-grow]="getGridWidth(file)"
        [style.width]="(getGridWidth(file)) + 'px'">

        <i [style.padding-bottom]="((file.height / file.width * 100) || 100) + '%'"></i>

        <mat-icon
            *ngIf="!filesService.canViewImage(file)"
            class="avatar-icon">{{ filesService.getFileIcon(file) }}</mat-icon>

        <img
            *ngIf="filesService.canViewImage(file)"
            alt="{{ file.name }}"
            class="checkerboard-background"
            draggable="false"
            src="{{ file.thumbnailLink }}">
        <div
            *ngIf="filesService.canViewImage(file) && file.thermalSubFileModel"
            class="icon-container thermal"
            draggable="false">
            <mat-icon class="x" fontSet="material-icons-outlined" [style.fontSize.px]="18">thermostat</mat-icon>
        </div>

        <mat-checkbox
            *ngIf="!isMobile && location === 'files'"
            [checked]="file.selected"
            (change)="$event ? toggleSelect(file) : null"
            (click)="$event.stopPropagation()"
            tabindex="-1"></mat-checkbox>



        <mat-icon
            *ngIf="isMobile && file.selected"
            class="check-circle"
            color="accent">check_circle</mat-icon>

        <p class="title-scrim">

          <a
              *ngIf="file?.isFolder"
              (click)="navigateToFolder(file._id)"
              draggable="false"
              [routerLink]="['', { outlets: { primary: ['files', file._id], detail: null}}]"
              tabindex="-1"
              title="{{ 'OPEN_FOLDER'| translate }}">{{ file.name }}</a>
          <a
              *ngIf="!file?.isFolder && !(folderModel?.tags?.includes('inspection') || folderModel?.tags?.includes('inspection3d'))"
              draggable="false"
              [routerLink]="['', { outlets: { detail: ['file', file._id, { view: 'fullscreen' }]}}]"
              tabindex="-1"
              title="{{ 'OPEN_FILE'| translate }}">{{ file.name }}</a>
          <a
              *ngIf="!file?.isFolder && (folderModel?.tags?.includes('inspection') || folderModel?.tags?.includes('inspection3d'))"
              draggable="false"
              [routerLink]="['', { outlets: { primary: [locationURL, folderModel._id], detail: ['file', file._id]}}]"
              tabindex="-1"
              title="{{ 'OPEN_FILE'| translate }}">{{ file.name }}</a>

        </p>
        <div class="annotations" *ngIf="folderModel && (folderModel.tags?.includes('inspection') || folderModel.tags?.includes('inspection3d'))">
          <div class="annotation advisory" *ngIf="file.annotationStats.advisory > 0">{{ formatAnnotations("advisory", file) }}</div>
          <div class="annotation low" *ngIf="file.annotationStats.low > 0">{{ formatAnnotations("low", file) }}</div>
          <div class="annotation middle" *ngIf="file.annotationStats.medium > 0">{{ formatAnnotations("medium", file)  }}</div>
          <div class="annotation high" *ngIf="file.annotationStats.high > 0">{{ formatAnnotations("high", file) }}</div>
          <div class="annotation urgent" *ngIf="file.annotationStats.urgent">{{ formatAnnotations("urgent", file) }}</div>
        </div>

        <button
            *ngIf="!selectedCount && location === 'files'"
            class="more-button"
            [disabled]="fetching"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ file: file}"
            tabindex="-1"
            type="button">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-icon
            *ngIf="isMobile && file.selected"
            class="drag-handle"
            (touchstart.is-mobile)="file.dragging = true"
            (touchend.is-mobile)="file.dragging = false">drag_indicator</mat-icon>
      </div>
  </section>

  <section
      *ngIf="!fetching && !dataSource"
      class="empty-state-grid">
    <div class="thumbnail">
      <i></i>
      <div class="content">

        <ng-container *ngIf="!searchQuery$.value; else noMatchesFound">
          <mat-icon disabled>{{ canEditActiveFolder ? 'add_photo_alternate' : 'photo' }}</mat-icon>

          <p>{{ 'FILES.NO_FILES_HERE' | translate }}</p>

          <span class="mat-small">{{ (canEditActiveFolder ?
            'FILES.' + (isMobile ? 'ADD' : 'DROP') + '_FILES_OR_CREATE_NEW_FOLDER' :
            'FILES.YOU_CAN_ONLY_VIEW_FILES_HERE' ) | translate }}</span>
        </ng-container>

        <ng-template #noMatchesFound>
          <mat-icon disabled>image_search</mat-icon>

          <p>{{ 'NO_MATCHES_FOUND' | translate }}</p>

          <span class="mat-small">{{ 'TRY_ANOTHER_SEARCH' | translate }}</span>
        </ng-template>

      </div>
    </div>
  </section>
</div>

<!-- Button to display table if Card is present -->
<button mat-raised-button
    *ngIf="hideTable"
    (click)="showTable()"
    class="showTableUI"
    color="accent">
  <mat-icon class="rtl-mirror">visibility</mat-icon>
  {{ 'FILES.SHOW_RAW_MATERIAL' | translate }}
</button>

<!-- Spinner overlay -->
<app-spinner [fetching]="fetching"></app-spinner>

<!-- Download iframe helper -->
<iframe name="downloadIframe" class="download-iframe"></iframe>

<!-- Context menu -->
<div
    class="context-menu-trigger"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ id: 'contextMenuTrigger' }">
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-file="file">

    <button *ngIf="selectedCount === 0"
        (click)="download(file)" mat-menu-item>
      <mat-icon aria-label="info">cloud_download</mat-icon>
      <span>{{ (selectedCount > 1 ? 'FILES.DOWNLOAD' : 'FILE.DOWNLOAD') | translate }}</span>
    </button>
    <button *ngIf="selectedCount !== 0"
        (click)="download()" mat-menu-item>
      <mat-icon aria-label="info">cloud_download</mat-icon>
      <span>{{ (selectedCount > 1 ? 'FILES.DOWNLOAD' : 'FILE.DOWNLOAD') | translate }}</span>
    </button>

    <button
        *ngIf="location === 'files'"
        (click)="showDetails(false, file)"
        mat-menu-item>
      <mat-icon aria-label="info">info</mat-icon>
      <span>{{ 'INFO' | translate }}</span>
    </button>

    <button
        *ngIf="showOnMapIsSupported(file) && location === 'files'"
        (click)="showOnMap(file)"
        mat-menu-item>
      <mat-icon>location_on</mat-icon>
      <span>{{ 'SHOW_ON_MAP' | translate }}</span>
    </button>

    <ng-container *ngIf="selectedOwner || permissionsService.permissions$.value?.canDelete.files">

      <mat-divider *ngIf="permissionsService.permissions$.value?.canDelete.files"></mat-divider>

      <button
          *ngIf="permissionsService.permissions$.value?.canDelete.files"
          (click)="delete(selectedCount > 1 ? null : file._id)"
          mat-menu-item>
        <mat-icon color="warn">delete</mat-icon>
        <span>{{ (selectedCount > 1 ? 'FILES.DELETE' : 'FILE.DELETE') | translate }}</span>
      </button>
    </ng-container>

    <ng-container>
      <button
        *ngIf="[accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType) && filesService.canUnzip(file)"
        (click)="filesService.unzip(file)"
        mat-menu-item>
        <mat-icon>unarchive</mat-icon>
        <span>{{ 'FILES.UNZIP' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<ng-container *ngIf="canEditActiveFolder">
  <!-- Floating Action Button -->
  <input
      #filesInput
      (change)="handleFilesInput($event.target.files)"
      class="files-input"
      multiple
      type="file">

  <input
      #folderInput
      (change)="handleFolderInput($event.target.files)"
      class="files-input"
      webkitdirectory
      multiple
      type="file">

  <button
      *ngIf="showFAB"
      @popAnimation
      color="accent"
      mat-fab
      [matMenuTriggerFor]="fabMenu">
    <mat-icon>add</mat-icon>
  </button>

  <button
      *ngIf="showFAB"
      @popAnimation
      color="accent"
      mat-mini-fab
      [matMenuTriggerFor]="fabMenu">
    <mat-icon>add</mat-icon>
  </button>

  <mat-menu #fabMenu="matMenu" overlapTrigger="true">
    <button
        (click)="newFolderDialog()"
        mat-menu-item>
      <mat-icon>create_new_folder</mat-icon>
      <span>{{ 'FILES.CREATE_NEW_FOLDER' | translate }}</span>
    </button>
    <mat-divider></mat-divider>
    <button
        (click)="filesInput.value = ''; filesInput.click()"
        mat-menu-item>
      <mat-icon svgIcon="file_upload"></mat-icon>
      <span>{{ 'UPLOAD.FILE' | translate }}</span>
    </button>
    <!-- <button
        *ngIf="!isMobile"
        (click)="folderInput.value = ''; folderInput.click()"
        mat-menu-item>
      <mat-icon svgIcon="folder_upload"></mat-icon>
      <span>{{ 'UPLOAD.FOLDER' | translate }}</span>
    </button> -->
  </mat-menu>
</ng-container>
