<h2 mat-dialog-title *ngIf="data?.title">{{ data?.title | translate }}</h2>

<mat-dialog-content>
  <span *ngIf="data?.message" [innerHTML]="(data?.message | translate)"></span>
  <span *ngIf="data?.status">&nbsp;{{ data?.status }}</span><br><br>
  <span *ngIf="data?.error">{{ data?.error?.errors || data?.error }}</span>
</mat-dialog-content>

<div mat-dialog-actions *ngIf="data?.confirm || data?.inform">
  <button *ngIf="!data?.inform"
      mat-button
      [mat-dialog-close]="false">{{ 'CANCEL' | translate | uppercase }}</button>

  <button
      cdkFocusInitial
      color="accent"
      [mat-dialog-close]="true"
      mat-raised-button>{{ 'OK' | translate | uppercase }}</button>
</div>
