import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(private http: HttpClient) { }
  
  sendNotification(pdfInspectionURL: string, clientEmail: string): Observable<any> {
    const body = {
      pdfInspectionURL: pdfInspectionURL,
      userEmail: clientEmail
    };
    return this.http.post<any>(`${environment.apiPath}internal/notify`, body);
  }
}
