import { RectangularBase } from './RectangularBase';
import { SvgHelper } from './SvgHelper';

export class RectangleBase extends RectangularBase {
  private rectElement: SVGRectElement;

  static draw = (): RectangleBase => {
    const rectangleBase = new RectangleBase();
    rectangleBase.setup();

    return rectangleBase;
  };

  protected resize(x: number, y: number): void {
    super.resize(x, y);
    this.rectElement.setAttribute('width', this.width.toString());
    this.rectElement.setAttribute('height', this.height.toString());
  }

  protected setup(): void {
    super.setup();

    this.rectElement = SvgHelper.createRect(this.width, this.height);
    this.addToRenderVisual(this.rectElement);
  }
}
