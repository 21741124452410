import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfEditorServiceService {
  private _subject = new Subject<any>();
  public getPDFConfiguration$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  public PDFConfiguration = {}

  newEvent(event) {
    this._subject.next(event);
  }

  get events$ () {
    return this._subject.asObservable();
  }

  generatePDF(): Observable<any> {
    return this.http.post<any>(`${environment.apiPath}pdf/inspection`, this.PDFConfiguration);
  }

  constructor(
    private http: HttpClient,
  ) { }
}
