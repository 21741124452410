import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewPermissions, PermissionsInitializer } from 'src/app/pages/files/file/permission.model';
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { Login } from 'src/app/pages/login/login.model';
import { AccountType } from 'src/app/pages/users/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService implements OnDestroy {
  permissions$: BehaviorSubject<NewPermissions> = new BehaviorSubject<NewPermissions>(undefined);
  private loginStateService: LoginStateService;
  ngDestroy$ = new Subject();
  login: Login

  constructor(private injector: Injector) {
    // Lazy load the LoginStateService to avoid the circular dependency issue
    setTimeout(() => {
      this.loginStateService = this.injector.get(LoginStateService);
      this.loginStateService.login$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(login => {
        if (login) {
          Promise.resolve(undefined).then(() => {
            this.login = login;
            this.resetPermissions();        
          });
        }
      });
    },10);
  }


  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  // this will set the original permissions of the user
  resetPermissions() {
    if (this.login) {
      if (this.login.accountType === 'admin') {
        this.setPermissions('admin')
      }
      else if (this.login.accountType === 'superAdmin') {
        this.setPermissions('superAdmin')
      } else if (this.login.accountType === 'client') {
        this.setPermissions('owner')
      } else if (this.login.accountType === 'editor') {
        this.setPermissions('editor')
      } else if (this.login.accountType === 'pilot') {
        this.setPermissions('pilot')
      }
    }
  }

  // this will set the permissions of the user 
  setPermissions(role: string) {
    let permissions: NewPermissions = new PermissionsInitializer();
    switch (role) {
        case 'superAdmin':
            permissions.canRead = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canCreate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canUpdate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDelete = {'portfolios':true, 'sites':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':true}
            permissions.canCollaborateOn = {'portfolios':true, 'sites':true, 'orders':true, 'files':true}
            permissions.canZip = {'files':true}
            permissions.canHide = {'files':true}

            break;
        case 'admin':
            permissions.canRead = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canCreate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canUpdate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDelete = {'portfolios':true, 'sites':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':true}
            permissions.canCollaborateOn = {'portfolios':true, 'sites':true, 'orders':true, 'files':true}
            permissions.canZip = {'files':true}
            permissions.canHide = {'files':true}
            break;
        case 'owner':
            permissions.canRead = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canCreate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canUpdate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDelete = {'portfolios':false, 'sites':false, 'files':false, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':true}
            permissions.canCollaborateOn = {'portfolios':true, 'sites':true, 'orders':true, 'files':true}
            permissions.canZip = {'files':true}
            permissions.canHide = {'files':false}
            break;
        case 'pilot':
            permissions.canRead = {'portfolios':false, 'sites':false, 'orders':true, 'files':true, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canCreate = {'portfolios':false, 'sites':false, 'orders':false, 'files':true, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canUpdate = {'portfolios':false, 'sites':false, 'orders':false, 'files':true, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canDelete = {'portfolios':false, 'sites':false, 'files':false, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':false}
            permissions.canCollaborateOn = {'portfolios':false, 'sites':false, 'orders':false, 'files':false}
            permissions.canZip = {'files':false}
            permissions.canHide = {'files':false}
            break;
        case 'coordinator':
            permissions.canRead = {'portfolios':false, 'sites':false, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canCreate = {'portfolios':false, 'sites':false, 'orders':false, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canUpdate = {'portfolios':true, 'sites':true, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDelete = {'portfolios':false, 'sites':false, 'files':false, 'annotations':true, 'measurements':true, 'collaborations': true}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':true}
            permissions.canCollaborateOn = {'portfolios':true, 'sites':true, 'orders':true, 'files':true}
            permissions.canZip = {'files':true}
            permissions.canHide = {'files':false}
            break;
        case 'editor':
            permissions.canRead = {'portfolios':false, 'sites':false, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': false}
            permissions.canCreate = {'portfolios':false, 'sites':false, 'orders':false, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': false}
            permissions.canUpdate = {'portfolios':false, 'sites':false, 'orders':false, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': false}
            permissions.canDelete = {'portfolios':false, 'sites':false, 'files':false, 'annotations':true, 'measurements':true, 'collaborations': false}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':true}
            permissions.canCollaborateOn = {'portfolios':false, 'sites':false, 'orders':false, 'files':false}
            permissions.canZip = {'files':true}
            permissions.canHide = {'files':false}
            break;
        case 'viewer':
            permissions.canRead = {'portfolios':false, 'sites':false, 'orders':true, 'files':true, 'annotations':true, 'measurements':true, 'collaborations': false}
            permissions.canCreate = {'portfolios':false, 'sites':false, 'orders':false, 'files':false, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canUpdate = {'portfolios':false, 'sites':false, 'orders':false, 'files':false, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canDelete = {'portfolios':false, 'sites':false, 'files':false, 'annotations':false, 'measurements':false, 'collaborations': false}
            permissions.canDownload = {'files':true}
            permissions.canGeneratePDF = {'files':true}
            permissions.canCollaborateOn = {'portfolios':false, 'sites':false, 'orders':false, 'files':false}
            permissions.canZip = {'files':false}
            permissions.canHide = {'files':false}
            break;
        default:
            console.error('Invalid role specified');
    }
    console.log("New Permission as",role ,": ",permissions)
    this.permissions$.next(permissions);
  }

}
