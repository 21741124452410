<header
    *ngIf="!isInspection"
    [dir]="dir">
  <mat-toolbar
      class="file-toolbar"
      role="toolbar">

    <button
        *ngIf="!showContextualHeader && view === 'fullscreen'"
        class="back-button"
        (click)="goBack()"
        mat-icon-button
        title="{{ 'BACK' | translate }}"
        type="button">
      <mat-icon class="rtl-mirror">arrow_back</mat-icon>
    </button>

    <button
        *ngIf="!showContextualHeader && view !== 'fullscreen'"
        class="close-button"
        (click)="closeSidenavDetail()"
        mat-icon-button
        title="{{ 'CLOSE' | translate }}"
        type="button">
      <mat-icon class="rtl-mirror">close</mat-icon>
    </button>

    <button
        *ngIf="showContextualHeader"
        class="cancel-button"
        (click)="discardChanges()"
        [disabled]="fetching"
        mat-icon-button
        title="{{ 'CANCEL' | translate }}"
        type="button">
      <mat-icon>cancel</mat-icon>
    </button>

    <div class="header-title">
      <ng-container *ngIf="selectedCount <= 1; else selectedTitle">
        {{ fileModel?.name }}
      </ng-container>
      <ng-template #selectedTitle>
        {{ (selectedCount === 1 ? 'ITEM' : 'ITEMS') | translate: { count: selectedCount } }}
      </ng-template>
    </div>

    <span class="fill-remaining"></span>

    <button
        *ngIf="formGroup?.dirty && formGroup?.valid && permissionsService.permissions$.value?.canUpdate.files"
        (click)="updateFile()"
        [disabled]="fetching"
        mat-icon-button
        title="{{ 'SAVE' | translate }}"
        type="button">
      <mat-icon>done</mat-icon>
    </button>

    <button *ngIf="false"
        class="more-button"
        [disabled]="fetching"
        mat-icon-button
        [matMenuTriggerFor]="moreMenu"
        title="{{ 'MORE' | translate }}"
        type="button">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #moreMenu="matMenu">
      <button
          *ngIf="fileModel"
          (click)="download()"
          mat-menu-item>
        <mat-icon>cloud_download</mat-icon>
        <span>{{ (selectedCount > 1 ? 'FILES.DOWNLOAD' : 'FILE.DOWNLOAD') | translate }}</span>
      </button>

      <button
          *ngIf="fileModel && !fileModel?.hidden && permissionsService.permissions$.value?.canHide.files"
          (click)="hide(fileModel._id,true)"
          mat-menu-item>
        <mat-icon>visibility_off</mat-icon>
        <span>{{ 'FILE.HIDE_FILE' | translate }}</span>
      </button>

      <button
          *ngIf="fileModel && fileModel?.hidden && permissionsService.permissions$.value?.canHide.files"
          (click)="hide(fileModel._id,false)"
          mat-menu-item>
        <mat-icon>visibility</mat-icon>
        <span>{{ 'FILE.MAKE_FILE_VISIBLE' | translate }}</span>
      </button>

      <button
        *ngIf="loginStateService.loggedUser$.value && permissionsService.permissions$.value?.canZip.files && filesService.canUnzip(fileModel)"
        (click)="filesService.unzip(fileModel)"
        mat-menu-item>
        <mat-icon>unarchive</mat-icon>
        <span>{{ 'FILES.UNZIP' | translate }}</span>
      </button>

      <button
          *ngIf="fileModel && drawingArea"
          (click)="export()"
          mat-menu-item>
        <mat-icon>save_alt</mat-icon>
        <span>{{ 'FILE.EXPORT' | translate }}</span>
      </button>

      <button
          *ngIf="fileModel && (fileModel.isFolder || fileModel.geo || fileModel.tags?.includes('orthomosaic') || fileModel.tags?.includes('point_cloud'))"
          [queryParams]="{ query: (fileModel.isFolder ? 'folderID:' : '') + fileModel._id }"
          [routerLink]="['', { outlets: { primary: ['maps', 'search' ], detail: ['file', fileModel._id ]}}]"
          mat-menu-item>
        <mat-icon>location_on</mat-icon>
        <span>{{ 'SHOW_ON_MAP' | translate }}</span>
      </button>

      <mat-divider *ngIf="permissionsService.permissions$.value?.canDelete.files"></mat-divider>

      <button
          *ngIf="fileModel && permissionsService.permissions$.value?.canDelete.files"
          (click)="removeFile()"
          mat-menu-item>
        <mat-icon color="warn">delete</mat-icon>
        <span>{{ (selectedCount > 1 ? 'FILES.DELETE' : 'FILE.DELETE') | translate }}</span>
      </button>
    </mat-menu>

  </mat-toolbar>
</header>

<section
  class="scrollable-section"
  [class.inspection]="isInspection"
  [dir]="dir"
  (wheel)="swiperWheel($event)">
  <div *ngIf="view"
    class="file-thumbnail"
    [class.file-thumbnail-max]="view !== 'fullscreen'">
    <div
        *ngIf="selectedCount === 1"
        class="swiper-container"
        [disabled]="false"
        [performance]="false"
        (indexChange)="swiperIndexChange($event)"
        [swiper]="swiperConfig"
        (zoomChange)="swiperZoomChange($event)">

        <!-- Map for orthomosaic -->
        <div
          class="mapWrapper"
          *ngIf="onMap || onViewer">
          <div *ngComponentOutlet="currentComponent"></div>
        </div>

      <div class="swiper-wrapper">
        <div
            *ngFor="let slide of virtualData.slides; index as i;"
            class="swiper-slide"
            [attr.data-index]="virtualData.from + i"
            [ngStyle]="{ 'left': virtualData.offset + 'px' }">

          <!-- File icon -->
          <a
              *ngIf="!filesService.canViewImage(slide) && !slide?.mimeType?.startsWith('video') && slide?.mimeType !== 'application/pdf' && !(onMap || onViewer)"
              [attr.download]="webContentLink && slide.name"
              [attr.href]="webContentLink"
              draggable="false">
            <mat-icon class="file-icon">{{ filesService.getFileIcon(slide) }}</mat-icon>
          </a>

          <!-- PDF iframe -->
          <div *ngIf="slide?.mimeType === 'application/pdf' && !isMobile"
              class="noClickPDF">
          </div>

          <iframe *ngIf="slide?.mimeType === 'application/pdf' && !isMobile"
              type="application/pdf"
              (load)="onPdfLoad($event, slide?.webViewLink)"
              src="">
          </iframe>

          <!-- PDF Mobile -->
          <a
              *ngIf="slide?.mimeType === 'application/pdf' && isMobile"
              [attr.href]="slide?.webViewLink"
              target="_blank"
              rel="noopener"
              draggable="false">
            <mat-icon
                class="file-icon">
              {{ filesService.getFileIcon(slide) }}
              <span>{{ 'FILE.OPEN_NEW_TAB' | translate }}</span>
            </mat-icon>
          </a>

          <!-- Video player -->
          <video
              *ngIf="slide?.mimeType?.startsWith('video')"
              controls
              crossorigin
              playsinline
              preload="auto"
              src="{{ slide?.webViewLink }}">
          </video>

          <!-- File thumbnail, show original image when resized, zoomed or in fullscreen view -->
          <div *ngIf="filesService.canViewImage(slide)"
              [id]="'id-' + slide?._id"
              class="swiper-zoom-container">
              <div *ngIf="panoHView"
                  class="hView"> {{ panoHView }} </div>
            <img *ngIf="!slide?.tags?.includes('360_stitched')"
                alt="{{ slide?.name }}"
                crossorigin="anonymous"
                draggable="false"
                (load)="onImageLoad()"
                src="{{
                  (sidenavDetailService.resized$ | async) > 360 || swiperZoomed || view === 'fullscreen' ?
                  slide?.webViewLink :
                  slide?.thumbnailLink
                }}">
          </div>
        </div>
      </div>

      <div [hidden]="selectedCount > 1">
        <div [hidden]="(fileModel?.mimeType?.startsWith('video') ||
          (fileModel?.mimeType === 'application/pdf' && !isMobile)) ||
          isPano"
          class="swiper-pagination"></div>

        <button
            class="swiper-button-prev"
            mat-icon-button
            title="next"
            type="button">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
            class="swiper-button-next"
            mat-icon-button
            title="next"
            type="button">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <!-- selected multiple elements -->
    <div
        [hidden]="selectedCount === 1"
        class="file-thumbnail">
      <div class="pagination">{{ selectedCount }}</div>
      <mat-icon class="file-icon selected-icon">playlist_add_check</mat-icon>
    </div>
  </div>

  <form
      #form="ngForm"
      class="scrollable-tab"
      autocomplete="off"
      [formGroup]="formGroup"
      novalidate>
    <mat-drawer-container class="edit-container" autosize>
      <mat-tab-group
          #matTabGroup
          mat-stretch-tabs
          [(selectedIndex)]="tabIndex"
          animationDuration="0ms"
          (animationDone)="tabChanged()">

        <!-- Matrics-Tower -->
        <mat-tab name="matricsTowerTab" *ngIf="isInspection3d && fileModel.orderID === 9 ">
          <ng-template mat-tab-label>
            <mat-icon>view_in_ar</mat-icon>
            <p class="mat-tab-label-text">{{ 'SIDEBAR.ANALYTICS.ANALYTICS' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content borderless">
            <app-sidebar-analytics-tower></app-sidebar-analytics-tower>
          </div>
        </mat-tab>
        <!-- tiled3d -->
        <mat-tab name="tile3dTab" *ngIf="onViewer && [accountType.ADMIN,accountType.SUPERADMIN].includes(login.accountType)">
          <ng-template mat-tab-label>
            <mat-icon>view_in_ar</mat-icon>
            <p class="mat-tab-label-text">{{ 'SIDEBAR.MODEL_PLACEMENT.MODEL_PLACEMENT' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content borderless">
            <app-sidebar-tiled3d [fileModel]="fileModel"></app-sidebar-tiled3d>
          </div>
        </mat-tab>

        <!-- annotations -->
        <mat-tab *ngIf="selectedCount === 1 && !isPano && fileModel?.width && fileModel?.height && folderModel?.tags && (folderModel?.tags.includes('inspection') || folderModel?.tags.includes('inspection3d'))">
          <ng-template mat-tab-label>
            <mat-icon>comment</mat-icon>
            <p class="mat-tab-label-text">{{ 'FILE.ANNOTATIONS.ANNOTATIONS' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content borderless">
            <app-sidebar-annotations *ngIf="isInspection3d || isInspection"
                #annotationSidebar
                [login]="login"
                [isSiteURL]="isSiteURL"
                [fileModel]="fileModel">
            </app-sidebar-annotations>
          </div>
        </mat-tab>

        <!-- Measurements -->
        <mat-tab *ngIf="selectedCount === 1 && fileModel?.tags?.includes('orthomosaic') && fileModel?.mimeType === 'image/tiff'">
          <ng-template mat-tab-label>
            <mat-icon>square_foot</mat-icon>
            <p class="mat-tab-label-text">{{ 'MEASURE.MEASUREMENTS' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content borderless">
            <app-measurements
                #annotationSidebar
                [login]="login"
                [theme]="theme"
                [isSiteURL]="isSiteURL"
                [fileModel]="fileModel">
            </app-measurements>
          </div>
        </mat-tab>

        <!-- annotations Pano -->
        <mat-tab name="annotationsPanoTab"  *ngIf="selectedCount === 1 && isPano">
          <ng-template mat-tab-label>
            <mat-icon>comment</mat-icon>
            <p class="mat-tab-label-text">{{ 'FILE.ANNOTATIONS.ANNOTATIONS' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content borderless">
            <app-sidebar-annotations-pano
                [fileModel]="fileModel"
                [login]="login"
                [view]="view"></app-sidebar-annotations-pano>
          </div>
        </mat-tab>

        <!-- COLLABORATORS -->
        <mat-tab name="collaboratorsTab" *ngIf="permissionsService.permissions$.value?.canRead.orders && permissionsService.permissions$.value?.canCollaborateOn.orders && permissionsService.permissions$.value?.canRead.collaborations">
          <div *ngIf="fileModel?.hidden" class="overlay"></div>
          <div *ngIf="fileModel?.hidden" class="file-hidden-notice-wrapper">
            <h1 class="notice">{{ 'COLLABORATION.SIDEBAR.ITEM_HIDDEN' | translate }} {{ fileModel?.isFolder? 'folder': 'file' }} {{ fileModel?.isFolder? '(' + fileModel.name + ')' : '' }}</h1>
            <button mat-raised-button
            color="accent"
            class="primary-button" (click)="hide(fileModel._id,false)">
            <mat-icon class="primary-button-icon">visibility</mat-icon>
              {{ fileModel?.isFolder ? ('COLLABORATION.SIDEBAR.MAKE_FOLDER_VISIBLE' | translate) : ('COLLABORATION.SIDEBAR.MAKE_FILE_VISIBLE' | translate) }}
            </button>
          </div>
          <ng-template mat-tab-label>
            <mat-icon>person_add</mat-icon>
            <p class="mat-tab-label-text">{{ 'COLLABORATION.USER.PLURAL' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content space-between">

            <!-- Collaborations -->
            <app-collaborations [fileModel]="fileModel" [collaborations]="collaborations">
            </app-collaborations>
          </div>
        </mat-tab>

        <!-- Settings -->
        <mat-tab name="settingsTab">
          <ng-template mat-tab-label>
            <mat-icon>settings</mat-icon>
            <p class="mat-tab-label-text">{{ 'SIDEBAR.SETTINGS.SETTINGS' | translate | uppercase }}</p>
          </ng-template>
          <div class="mat-tab-content borderless">
            <app-sidebar-settings *ngIf="fileModel" [fileModel]="fileModel" [login]="login"></app-sidebar-settings>
          </div>
        </mat-tab>

      </mat-tab-group>
    </mat-drawer-container>
  </form>
</section>

<app-spinner [fetching]="fetching"></app-spinner>
