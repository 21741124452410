import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, Legend } from 'chart.js';
import { ThemeService } from '../theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
Chart.register(Legend)

export interface Record {
  color: string,
  data: number
}

export interface InputData {
  labels: string[];
  records: Record[];
}

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements AfterViewInit {
  private chart!: Chart;

  @ViewChild('chart') chartRef: ElementRef<HTMLCanvasElement>;
  @Input() showLegend = true;
  @Input() value?: number;
  @Input()
  set dataSetter(data: InputData) {
    this.data = data;
    this.updateCHart();
  }

  public data: InputData= {
    labels: ['Green', 'Red', 'Yellow'],
    records: [
      {
        data: 10,
        color: 'green'
      },
      {
        data: 8,
        color: 'red'
      },
      {
        data: 5,
        color: 'yellow'
      }
    ]
  };

  public dataPie: ChartData<'doughnut', number[], string> = {
    labels: ['Green', 'Red', 'Yellow'],
    datasets: [{
      data: [10,8,5],
      backgroundColor: ['green', 'red', 'yellow'],
      borderWidth: 0
    }]
  };
  private ngDestroy$ = new Subject();
  theme: string= this.themeService.changed$.value;

  public configPie: ChartConfiguration<'doughnut', number[], string> = {
    type: 'doughnut',
    data: this.dataPie,
    options: {
      cutout: '70%',
      responsive: true,
      plugins: {
        legend: {
          display: true,
          title: {
            display: true,
            padding: 0,
          },
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'rectRounded',
            color: this.theme.includes('dark')?"#e0e0e0":"gray",
          }
        },
        datalabels: {
          display: false
        }
      }
    },
  };


  constructor(
    private  themeService:ThemeService
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
      this.configPie.options.plugins.legend.labels.color= theme.includes('dark')?"#e0e0e0":"gray";
      this.updateCHart()
    });
  }

  private updateCHart(): void {
    if (!this.data || !this.chartRef) return;

    this.dataPie.labels = this.data.labels;
    this.configPie.options.plugins.legend.display = this.showLegend;
    this.dataPie.datasets[0].data = this.data.records.map(record => record.data);
    this.dataPie.datasets[0].backgroundColor = this.data.records.map(record => record.color)
    if (!this.chart) {
      this.chart = new Chart(this.chartRef.nativeElement, this.configPie);
    } else {
      this.chart.update();
    }
  }

  public ngAfterViewInit(): void {
    this.updateCHart();
  }

}
