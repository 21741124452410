export class Post {
  _id?: string | object;
  avatar?: string
  contentDE?: string;
  contentEN?: string;
  created?: Date | any;
  modified?: Date | any | boolean;
  public?: boolean;
  subtitleDE?: string;
  subtitleEN?: string;
  tags?: Array<string>;
  titleDE?: string;
  titleEN?: string;
  /* eslint-disable @typescript-eslint/member-ordering */
  fileURL?: string;
  thumbnailURL?: string;
  URL?: string;
}

export class Update {
  $currentDate?: Post;
  $set?: Post;
  $unset?: Post;
}
