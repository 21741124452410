import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Coordinate } from 'ol/coordinate';
import { DrawType } from './../../pages/files/file/sidebar/annotations/annotation.model';
import { FileModel } from 'src/app/pages/files/file/file.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WebSocketService } from 'src/shared/websocket/websocket.service';

export const SeverityToColor = {
  advisory: '#d5e5fe',
  urgent: '#8837B4',
  high: '#fd261c',
  middle: '#fece2f',
  low: '#38d23c',
  own: '#9b9b9b',
  not_assessed: '#9b9b9b',
  selected: '#2979ff'
};

@Injectable({
  providedIn: 'root'
})
export class OlImageService {
  editing$: BehaviorSubject<number> = new BehaviorSubject(null); // ToDo change to numbers
  type$: BehaviorSubject<DrawType> = new BehaviorSubject('Box');
  finished$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  coords$: BehaviorSubject<Array<Array<Coordinate>>> = new BehaviorSubject([[]]);
  status$: BehaviorSubject<string> = new BehaviorSubject('');
  xTransNew$: BehaviorSubject<number> = new BehaviorSubject(0);
  yTransNew$: BehaviorSubject<number> = new BehaviorSubject(0);
  drawInteraction$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  setEditing(editing: number){
    this.editing$.next(editing);
  }

  setInteraction(status){
    this.drawInteraction$.next(status)
  }
  setType(type: DrawType){
    this.type$.next(type);
  }
  setFinished(finished: boolean) {
    this.finished$.next(finished);
  }
  setCoords(coords: Array<Array<Coordinate>>) {
    this.coords$.next(coords);
  }
  setStatus(status: string) {
    this.status$.next(status);
  }
  setxTransNew(newValue){
    this.xTransNew$.next(newValue)
  }
  setyTransNew(newValue){
    this.yTransNew$.next(newValue)
  }

  getPalettes(): Observable<any>{
    return this.http.get<any>(`${environment.apiPath}thermal/palettes`);
  }

  getActualPalette (fileID): Observable<any>{
    return this.http.get<any>(`${environment.apiPath}thermal/palette/${fileID}`);
  }

  convertPalette(fileID,newPalette,minTemperature,maxTemperature,minFocus,maxFocus,isothermActivation){
    const payload = {
      type: "convert-palette",
      data: {
          image: fileID,
          palette: newPalette,
          colorBar: {
            "manual": true, //this enables and disables changing palettes using min and max
            "max": maxTemperature,
            "min": minTemperature
          },
          isotherm: {
            "manual": isothermActivation,
            "max": maxFocus,
            "min": minFocus
          },

      }
    };
    this.webSocketService.send(payload)
  }

  measureSpot(fileID,topL,bottomR,fraction){
    const payload = {
      type: "measure-temperature",
      data: {
          image: fileID.toString(),
          topLeft: {
            x: Math.floor(topL[0]/fraction),  //topL[0]
            y: Math.floor(topL[1]/fraction)   //topL[1]
          },
          bottomRight: {
            x: Math.floor(bottomR[0]/fraction),  //x: bottomR[0],  
            y: Math.floor(bottomR[1]/fraction)   //y: bottomR[1]
          }
      }
    };
    this.webSocketService.send(payload)
  }

  constructor(private http: HttpClient,
    private webSocketService: WebSocketService) { }
}
