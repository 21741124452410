import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes a precision argument that defaults to 2.
 * Takes a units argument that defaults to 'decimal'.
 *
 * Usage:
 *   bytes | size:precision:units
 *
 * Example:
 *   {{ 1000 | size }} => formats to: 1 KB (default)
 *   {{ 1000 | size:2:'binary' }} => formats to: 0.97 KiB
*/
@Pipe({ name: 'size' })
export class SizePipe implements PipeTransform {

  private binaryPower = 1024; // https://en.wikipedia.org/wiki/IEEE_1541-2002
  private binaryUnits = [
    'B',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB'
  ];

  private decimalPower = 1000; // https://en.wikipedia.org/wiki/ISO/IEC_80000
  private decimalUnits = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB'
  ];

  transform(bytes: any, precision = 2, units = 'decimal'): string {
    if (bytes === null || bytes === undefined || isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '';
    }

    let unit = 0;

    if (units === 'binary') {
      while (bytes >= this.binaryPower) {
        bytes /= this.binaryPower;
        unit++;
      }
    } else {
      while (bytes >= this.decimalPower) {
        bytes /= this.decimalPower;
        unit++;
      }
    }

    return `${bytes.toFixed(unit ? +precision : 0)} ${units === 'binary' ? this.binaryUnits[unit] : this.decimalUnits[unit]}`;
  }
}
