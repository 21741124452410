import { BehaviorSubject } from 'rxjs';
import { FileModel } from './../../files/file/file.model';
import { Injectable } from '@angular/core';

/* OpenLayers */
import { Map, View} from 'ol';
import { fromLonLat } from 'ol/proj';
import { WebGLTile as TileLayer } from 'ol/layer';
import { OSM, GeoTIFF } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

export class WaterStyleStep {
  value?: number;
  color: string;
  edit?: boolean;
}

export class WaterStyle {
  name: string;
  steps?: Array<WaterStyleStep>
}

@Injectable({
  providedIn: 'root'
})
export class Viewer2dService {
  style$: BehaviorSubject<WaterStyle> = new BehaviorSubject({name:'water'});
  spotsAction$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor() { }
  
  addOrtho(map: Map, orthoModel: FileModel): TileLayer {
    if (orthoModel.tags?.includes('orthomosaic') && orthoModel.mimeType === 'image/tiff') {
      const geoTiffSource = new GeoTIFF({
        sources: [
          {
            url: orthoModel.webViewLink,
          },
        ],
      });
      const geoTiffLayer = new TileLayer({
        source: geoTiffSource,
        zIndex: 50
      })
      geoTiffSource.getView().then((viewOptions) => map.getView().fit(viewOptions.extent));
      map.addLayer(geoTiffLayer);
      return geoTiffLayer;
    }
  }

  setSpotAction (action) {
     this.spotsAction$.next(action)
  }

  addWaterVisualization(map: Map, dem: FileModel, demValue: number): TileLayer {
    const cogDEM = new TileLayer({
      source: new GeoTIFF({
        normalize: false,
        sources: [
          {
            url: dem.webViewLink,
          },
        ],
      }),
      zIndex: 60
    });
    map.addLayer(cogDEM);
    return cogDEM
  }

  addWaterHeightVector(map: Map): VectorSource {
    if(map){
      const vectorSource = new VectorSource({
        features: [],
      })

      map.addLayer(new VectorLayer({
        source: vectorSource,
        zIndex: 105
      }));

      return vectorSource;
    }
    return null;
  }

  getWaterStepsStyle(demValue:number, minValue: number, waterStyle: WaterStyle) {
    const values = []
    waterStyle.steps.slice().reverse().forEach(element => {
      values.push(['between', ['band', 1], minValue, (element.value) ? demValue - element.value : demValue])
      values.push(element.color)
      minValue = demValue - element.value
    });
    return {
      color: [
        'case',
        ['==', ['band', 2], 0],
        '#00000000',
        ...values,
        [0, 0, 0, 0],
      ]
    }
  }

  getWaterStyle(demValue:number, minValue: number) {
    return {
      color: [
        'case',
        ['==', ['band', 2], 0],
        '#00000000',
        ['between', ['band', 1], minValue, demValue],
        '#d4f1f9',
        [0, 0, 0, 0]
      ],
    }
  }

  initMap(options: {
    target: HTMLElement
  }): Map {
    let mapOptions = {
      controls: [],
      view: new View({
        center: fromLonLat([0, 0 ]),
        zoom: 2,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: options.target
    };

    const map = new Map(mapOptions);
    return map
  }
}