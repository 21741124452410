import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';

import {ApiService} from './../../shared/interfaces/api-service.interface';
import {environment} from './../../../environments/environment';
import {CreateUserDocumentRequest, DocumentRequestFields, Update, User} from './models/user.model';
import {
  E_USER_DOCUMENT_TYPES,
  IDocument,
  IDocumentId,
  IDocumentInsurance,
  IDocumentOperator,
  IDocumentPermit,
  PilotSpecialtiesResponse,
  ResponseData,
  SpecialtiesResponse
} from './models/interfaces/user.interfaces';
import { map } from 'rxjs/operators';
import {
  StatisticResponse
} from './models/interfaces/stats.interfaces';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';

@Injectable()
export class UsersService implements ApiService<User> {

  data$: BehaviorSubject<Array<User>> = new BehaviorSubject([]);
  refreshed: EventEmitter<any> = new EventEmitter();
  totalItems$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private http: HttpClient,
              private permissionsService: PermissionsService
  ) {}

  deleteMany(filter: object): Observable<any> {
    return this.http.request('delete', `${environment.apiPath}users`, { body: filter, observe: 'response' });
  }

  deleteOne(userID: string): Observable<any> {
    return this.http.delete(`${environment.apiPath}users/${userID}`, { observe: 'response' });
  }

  findMany(
      filter?: object,
      sort?: object,
      cursor?: number,
      pageSize?: number,
      fields?: object,
  ): Observable<any> {
    let params = new HttpParams();
    if (filter && Object.keys(filter).length) {
      params = params.append('filter', JSON.stringify(filter));
    }
    if (sort && Object.keys(sort).length) {
      params = params.append('sort', JSON.stringify(sort));
    }
    if (cursor) {
      params = params.append('cursor', cursor.toString());
    }
    if (pageSize) {
      params = params.append('pageSize', pageSize.toString());
    }
    if (fields && Object.keys(fields).length) {
      params = params.append('fields', JSON.stringify(fields));
    }

    return this.http.get<any>(`${environment.apiPath}users`, { params });
  }

  public getStatistic(userID:string): Observable<ResponseData<StatisticResponse>> {
    return this.http.get<ResponseData<StatisticResponse>>(`${environment.apiPath}statistics/${userID}`);
  }

  public getDocuments(userID:string, pageSize: number = 50000): Observable<ResponseData<IDocument[]>> {
    return this.http.get<ResponseData<CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES>[]>>(`${environment.apiPath}documents/user/${userID}`, {
      params: {
        pageSize
      }
    })
      .pipe(map(value => {
        const documents = value.data?.map(value => this.responseToDocument(value))

        return {
          data: documents,
          pagination: value.pagination
        }
      }))
  }

  public getSpecialities(): Observable<ResponseData<SpecialtiesResponse[]>> {
    return this.http.get<ResponseData<SpecialtiesResponse[]>>(`${environment.apiPath}specialties`)
  }

  public getPilotSpecialities(userId: string): Observable<ResponseData<PilotSpecialtiesResponse[]>> {
    return this.http.get<ResponseData<PilotSpecialtiesResponse[]>>(`${environment.apiPath}specialties/${userId}`)
  }

  public addSpeciality(userID:string, speciality: number): Observable<void> {
    return this.http.post<void>(`${environment.apiPath}specialties/${userID}/${speciality}`, {})
  }

  public removeSpeciality(userID:string, speciality: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiPath}specialties/${userID}/${speciality}`)
  }

  public createDocument(userID: string, document: IDocument): Observable<CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES>> {
    const request= this.requestDocumentFabric(document);
    return this.http.post<CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES>>(`${environment.apiPath}documents/user/${userID}`, request);
  }

  public updateDocument(id: string, document: IDocument): Observable<CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES>> {
    const request= this.requestDocumentFabric(document);
    return this.http.patch<CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES>>(`${environment.apiPath}documents/${id}`, request);
  }

  public deleteDocuments(ids: string[]): Observable<void> {
    return this.http.delete<void>(`${environment.apiPath}documents`, {
      body: ids
    });
  }

  findOne(userID: string, fields?: object): Observable<any> {
    let params = new HttpParams();
    if (fields && Object.keys(fields).length) {
      params = params.append('fields', JSON.stringify(fields));
    }

    return this.http.get<any>(`${environment.apiPath}users/${userID}`, { params });
  }

  getAddressURL(address: string): string {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  }

  insertMany(users: Array<User>): Observable<any> {
    return this.http.post(`${environment.apiPath}users`, users, { observe: 'response' });
  }

  private requestDocumentFabric(document: IDocument): CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES> {
    if (document.type === E_USER_DOCUMENT_TYPES.ID_DOCUMENT) {
      const factualDocument = document as IDocumentId;
      return this.createRequestDocument(E_USER_DOCUMENT_TYPES.ID_DOCUMENT, document, {
        type: factualDocument.idType,
        number: factualDocument.number.toString(),
        issuingCountry: factualDocument.country
      })
    }

    if (document.type === E_USER_DOCUMENT_TYPES.INSURANCE) {
      const factualDocument = document as IDocumentInsurance;
      return this.createRequestDocument(E_USER_DOCUMENT_TYPES.INSURANCE, document, {
        company: factualDocument.company,
        policyNumber: factualDocument.number,
        totalSumInsured: factualDocument.totalInsured
      })
    }
    if (document.type === E_USER_DOCUMENT_TYPES.OPERATOR) {
      const factualDocument = document as IDocumentOperator;
      return this.createRequestDocument(E_USER_DOCUMENT_TYPES.OPERATOR, document, {
        comment: factualDocument.comment,
        issuingAuthority: factualDocument.issuingAuthority
      })
    }
    const factualDocument = document as IDocumentPermit;

    return this.createRequestDocument(E_USER_DOCUMENT_TYPES.PERMIT, document, {
      comment: factualDocument.comment,
      number: factualDocument.numberLicense,
      country: factualDocument.region,
      category: factualDocument.company
    })
  }

  private createRequestDocument<T extends E_USER_DOCUMENT_TYPES>(type: T, document: IDocument, fields: DocumentRequestFields<T>): CreateUserDocumentRequest<T> {
    return {
      type,
      description: {
        attachmentFileId: document.file,
        expirationDate: new Date(document.expirationDate).toISOString()
      },
      fields
    }
  }

  private responseToDocument<T extends E_USER_DOCUMENT_TYPES>(response: CreateUserDocumentRequest<T>): IDocument {
    const baseDocument: IDocument = {
      expirationDate: response.description.expirationDate,
      file: response.description.attachmentFileId,
      type: response.type,
      id: response._id
    }

    if (response.type === E_USER_DOCUMENT_TYPES.ID_DOCUMENT) {
      const factual = response as CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES.ID_DOCUMENT>
      return {
        ...baseDocument,
        country: factual.fields.issuingCountry,
        number: factual.fields.number,
        idType: factual.fields.type
      } as IDocumentId;
    }

    if (response.type === E_USER_DOCUMENT_TYPES.INSURANCE) {
      const factual = response as CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES.INSURANCE>
      return {
        ...baseDocument,
        number: factual.fields.policyNumber,
        company: factual.fields.company,
        totalInsured: factual.fields.totalSumInsured
      } as IDocumentInsurance
    }

    if (response.type === E_USER_DOCUMENT_TYPES.OPERATOR) {
      const factual = response as CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES.OPERATOR>
      return {
        ...baseDocument,
        comment: factual.fields.comment,
        issuingAuthority: factual.fields.issuingAuthority
      } as IDocumentOperator;
    }

    if (response.type === E_USER_DOCUMENT_TYPES.PERMIT) {
      const factual = response as CreateUserDocumentRequest<E_USER_DOCUMENT_TYPES.PERMIT>
      return {
        ...baseDocument,
        comment: factual.fields.comment,
        company: factual.fields.category,
        region: factual.fields.country,
        numberLicense: factual.fields.number
      } as IDocumentPermit;
    }
  }

  removeItem(userID: string): void {
    const index = this.data$.value.findIndex(item => item._id === userID);
    if (index > -1) {
      this.data$.value.splice(index, 1);
      this.data$.next(this.data$.value);
      this.totalItems$.next(this.totalItems$.value - 1);
    }
  }

  updateItem(user: User): void {
    const index = this.data$.value.findIndex(item => item._id === user._id);
    if (index > -1) {
      this.data$.value[index] = user;
      this.data$.next(this.data$.value);
    }
  }

  updateOne(userUpdates: User): Observable<any> {
    return this.http.patch(`${environment.apiPath}users/${userUpdates._id}`, userUpdates, { observe: 'response' });
  }

  upsertItem(user: User): void {
    const index = this.data$.value.findIndex(item => item._id === user._id);
    if (index > -1) {
      this.data$.value[index] = user;
    } else {
      this.data$.value.unshift(user);
      this.totalItems$.next(this.totalItems$.value + 1);
    }
    this.data$.next(this.data$.value);
  }
}
