/* eslint-disable import/export */
export class Permission {
  avatarIconLink?: string;
  downloadable?: boolean;
  email?: string;
  name?: string;
  surname?: string;
  password?: string;
  role?: PermissionRole;
  scope?: PermissionScope;
  userID?: string | object;
}

export interface NewPermissions {
  canRead: {
      files: boolean;
      orders: boolean;
      sites: boolean;
      portfolios: boolean;
      annotations: boolean;
      measurements: boolean;
      collaborations: boolean;
  };
  canUpdate: {
      files: boolean;
      orders: boolean;
      sites: boolean;
      portfolios: boolean;
      annotations: boolean;
      measurements: boolean;
      collaborations: boolean;
  };
  canCreate: {
      files: boolean;
      orders: boolean;
      sites: boolean;
      portfolios: boolean;
      annotations: boolean;
      measurements: boolean;
      collaborations: boolean;
  };
  canDelete: {
      files: boolean;
      sites: boolean;
      portfolios: boolean;
      annotations: boolean;
      measurements: boolean;
      collaborations: boolean;
  };
  canDownload: {
      files: boolean;
  };
  canGeneratePDF: {
      files: boolean;
  };
  canCollaborateOn: {
      files: boolean;
      sites: boolean;
      orders: boolean;
      portfolios: boolean;
  };
  canZip: {
    files: boolean;
  };
  canHide: {
    files: boolean;
  };
}

export class PermissionsInitializer implements NewPermissions {
  canRead = {
      files: false,
      orders: false,
      sites: false,
      portfolios: false,
      annotations: false,
      measurements: false,
      collaborations: false
  };
  canUpdate = {
      files: false,
      orders: false,
      sites: false,
      portfolios: false,
      annotations: false,
      measurements: false,
      collaborations: false
  };
  canCreate = {
      files: false,
      orders: false,
      sites: false,
      portfolios: false,
      annotations: false,
      measurements: false,
      collaborations: false
  };
  canDelete = {
      files: false,
      sites: false,
      portfolios: false,
      annotations: false,
      measurements: false,
      collaborations: false
  };
  canDownload = {
      files: false
  };
  canGeneratePDF = {
      files: false
  };
  canCollaborateOn = {
      files: false,
      sites: false,
      orders: false,
      portfolios: false
  };
  canZip = {
    files: false
  };
  canHide = {
    files: false
  };

  constructor() {}
}

export enum PermissionRole {
  // Maximum = 100
  OWNER = 40, // Can view, edit and delete the file. A single owner must always exist for each file
  EDIT = 30, // Can view and edit the file
  VIEW = 20, // Can only view the file
  UNKNOWN = 0
  // Minimum = 0
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PermissionRole {
  export function getIcon(role: PermissionRole): string {
      switch (role) {
        case PermissionRole.OWNER:
          return 'person_outline';
        case PermissionRole.EDIT:
          return 'edit';
        case PermissionRole.VIEW:
          return 'visibility';
        default:
          return 'error';
      }
  }

  export function getLabel(role: PermissionRole): string {
    switch (role) {
      case PermissionRole.OWNER:
        return 'FILE.ROLE.OWNER';
      case PermissionRole.EDIT:
        return 'FILE.ROLE.EDIT';
      case PermissionRole.VIEW:
        return 'FILE.ROLE.VIEW';
      default:
        return String(role);
    }
  }

  export function getValue(role: string): PermissionRole {
    switch (role.toUpperCase()) {
      case 'OWNER':
        return PermissionRole.OWNER;
      case 'EDIT':
        return PermissionRole.EDIT;
      case 'VIEW':
        return PermissionRole.VIEW;
      default:
        return PermissionRole.UNKNOWN;
    }
  }
}

export enum PermissionScope {
  // Maximum = 100
  PUBLIC = 80, // Anyone on the internet can find and access. No login required
  ANYONE = 60, // Anyone who has the link can access. No login required
  DOMAIN = 40, // Anyone with the domain can access
  USER = 20, // Shared with specific people
  UNKNOWN = 0
  // Minimum = 0
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PermissionScope {
  export function getIcon(scope: PermissionScope): string {
      switch (scope) {
        case PermissionScope.PUBLIC:
          return 'public';
        case PermissionScope.ANYONE:
          return 'link';
        case PermissionScope.DOMAIN:
          return 'business';
        case PermissionScope.USER:
          return 'person';
        default:
          return 'error';
      }
  }

  export function getLabel(scope: PermissionScope): string {
    switch (scope) {
      case PermissionScope.PUBLIC:
        return 'FILE.SCOPE.PUBLIC';
      case PermissionScope.ANYONE:
        return 'FILE.SCOPE.ANYONE';
      case PermissionScope.DOMAIN:
        return 'FILE.SCOPE.DOMAIN';
      case PermissionScope.USER:
        return 'FILE.SCOPE.USER';
      default:
        return String(scope);
    }
  }

  export function getValue(scope: string): PermissionScope {
    switch (scope.toUpperCase()) {
      case 'PUBLIC':
        return PermissionScope.PUBLIC;
      case 'ANYONE':
        return PermissionScope.ANYONE;
      case 'DOMAIN':
        return PermissionScope.DOMAIN;
      case 'USER':
        return PermissionScope.USER;
      default:
        return PermissionScope.UNKNOWN;
    }
  }
}
