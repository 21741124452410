
<div class="wrapper">
  <ng-form [formGroup]="formTiled3d">

    <!-- Pano Perspektive -->
    <div class="perspective">
      {{ 'SIDEBAR.MODEL_PLACEMENT.INIT_PERSPECTIVE' | translate }}
      <div class="minor">
      <button mat-raised-button color="accent" (click)="save3dView()">
        <mat-icon class="white rtl-mirror">push_pin</mat-icon>
        {{ 'SIDEBAR.MODEL_PLACEMENT.SET' | translate }}
      </button>
      <button
          class="right"
          color="accent"
          mat-raised-button
          (click)="set3dView()">
      <mat-icon class="white rtl-mirror">preview</mat-icon> {{ 'SIDEBAR.MODEL_PLACEMENT.INGEST' | translate }}
      </button>
      </div>
    </div>

    <!-- location/rotation -->
    <div class="locationSection">
      <p class="interaction" (click)="toggleLocation()">
        <mat-icon class="rtl-mirror">description</mat-icon>
        {{ 'SIDEBAR.MODEL_PLACEMENT.ADJUST_PLACEMENT' | translate }}
        <mat-icon class="rtl-mirror right">{{expandLocation ? 'expand_less' : 'expand_more'}}</mat-icon>
      </p>
      <div *ngIf="expandLocation">
        {{ 'SIDEBAR.MODEL_PLACEMENT.POSITION' | translate }}:

        <!-- positionX -->
        <div class="hiddenInput">
          <div>
            X:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                (change)="updateLivePosition()"
                formControlName="positionX">
          </mat-form-field>
        </div>

        <!-- positionY -->
        <div class="hiddenInput">
          <div>
            Y:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                (change)="updateLivePosition()"
                formControlName="positionY">
          </mat-form-field>
        </div>

        <!-- positionZ -->
        <div class="hiddenInput">
          <div>
            Z:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                (change)="updateLivePosition()"
                formControlName="positionZ">
          </mat-form-field>
        </div>

        {{ 'SIDEBAR.MODEL_PLACEMENT.ROTATION' | translate }}:

        <!-- rotationX -->
        <div class="hiddenInput">
          <div>
            X:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                step="0.1"
                (change)="updateLiveRotation()"
                formControlName="rotationX">
          </mat-form-field>
        </div>

        <!-- rotationY -->
        <div class="hiddenInput">
          <div>
            Y:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                step="0.1"
                (change)="updateLiveRotation()"
                formControlName="rotationY">
          </mat-form-field>
        </div>

        <!-- rotationZ -->
        <div class="hiddenInput">
          <div>
            Z:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                step="0.1"
                (change)="updateLiveRotation()"
                formControlName="rotationZ">
          </mat-form-field>
        </div>

        <!-- scale -->
        <div class="hiddenInput">
          <div>
            {{ 'SIDEBAR.MODEL_PLACEMENT.SCALE' | translate }}:
          </div>

          <mat-form-field>
            <input matInput
                step="0.01"
                type="number"
                (change)="updateLiveScale()"
                formControlName="scale">
          </mat-form-field>
        </div>

        <!-- maximumScreenSpaceError -->
        <div class="hiddenInput">
          <div>
            maximumScreenSpaceError:
          </div>

          <mat-form-field>
            <input matInput
                type="number"
                step="1"
                formControlName="maximumScreenSpaceError">
          </mat-form-field>
        </div>

        <div class="right">
          <button
              mat-button
              color="accent"
              class="confirm"
              (click)="toggleLocation()">{{ 'CANCEL' | translate }}</button>
          <button
              mat-button
              color="accent"
              class="confirm"
              (click)="resetPosition()">Reset</button>
          <button
              mat-button
              color="accent"
              class="confirm"
              (click)="setLocation()">{{ 'SAVE' | translate }}</button>
        </div>

      </div>
    </div>
  </ng-form>
</div>
