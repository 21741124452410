import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysLeft'
})
export class DaysLeftPipe implements PipeTransform {

  transform(value: Date | string): string {
    if (!value) {
      return '-';
    }

    // Convert string to Date if needed
    const targetDate = typeof value === 'string' ? new Date(value) : value;
    const today = new Date();

    // Calculate the difference in time
    const timeDiff = targetDate.getTime() - today.getTime();

    // Convert the time difference to days
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysLeft < 0) {
      const daysPassed = Math.abs(daysLeft);
      return `${daysPassed} days ago`;
    } else if (daysLeft === 0) {
      return 'Today';
    } else if (daysLeft === 1) {
      return '1 day left';
    } else {
      return `${daysLeft} days left`;
    }
  }
}
