import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder,  FormGroup,Validators } from '@angular/forms';
import { Site, SITE_TYPE_LABELS, SITE_TYPES } from '../portfolio/portfolio.model';
import { environment } from '../../../environments/environment';
import { LazyLoadService } from '../helpers/lazy-load.service';

@Component({
  selector: 'app-site-adder-modal',
  templateUrl: './site-adder-modal.component.html',
  styleUrls: ['./site-adder-modal.component.scss']
})
export class SiteAdderModalComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public site: Site;
  public types = SITE_TYPES;
  public labels = SITE_TYPE_LABELS;
  public placesAutocomplete: google.maps.places.Autocomplete;
  public googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries=drawing,places`;

  @ViewChild('addressInput') addressAutocomplete: ElementRef<HTMLInputElement>;

  constructor(
    private readonly modal: MatDialogRef<SiteAdderModalComponent>,
    private readonly fb: FormBuilder,
    private readonly lazyLoadService: LazyLoadService,
    private ngZone: NgZone,
    ) { }

  public close(): void {
    this.modal.close();
  }

  public submit(): void {
    this.modal.close({
      ...this.form.value,
      type: 'building'
    })
  }

  private buildForm(): void {
    this.form = this.fb.group({
      type: ['building', Validators.required],
      name: ['', Validators.required],
      address: ['', [Validators.required]],
    })
  }

  private initForm(): void {
    this.form.setValue({
      name: this.site.name,
      type: this.site.type,
      address: this.site.address
    })
  }

  public ngOnInit(): void {
    this.buildForm();

    if (this.site) {
      this.initForm()
    }
  }

  public ngAfterViewInit(): void {
    this.lazyLoadService.loadScript(this.googleMapsURL).subscribe(_ => {
      this.placesAutocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement, {
        types: ['address']
      });
      this.placesAutocomplete.setFields(['formatted_address']);
      this.placesAutocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = this.placesAutocomplete.getPlace();

          this.form.controls.address.setValue((place && place.formatted_address) || this.addressAutocomplete.nativeElement.value,
              { onlySelf: false });
          this.form.controls.address.markAsDirty();
        });
      });

    });
  }
}
