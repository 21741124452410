import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CollaborationsService } from './collaborations.service';
import { Collaboration, CollaborationRole } from './collaboration.model';
import { FileModel } from '../../file.model';
import { skip, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { UsersService } from 'src/app/pages/users/users.service';
import { searchToFilter } from 'src/app/shared/helpers/data-helpers'; 
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { ThemeService } from 'src/app/shared/theme/theme.service';


@Component({
  selector: 'app-collaborations',
  templateUrl: './collaborations.component.html',
  styleUrls: ['./collaborations.component.scss']
})
export class CollaborationsComponent implements OnInit, OnDestroy {
  @Input() fileModel: FileModel;
  @Input() collaborations: Collaboration[];
  ngDestroy$ = new Subject();
  theme: string;

  constructor(private collaborationsService: CollaborationsService,
              private usersService: UsersService,
              private loginStateService: LoginStateService,
              private dialogService: DialogService,
              private themeService: ThemeService,
  ) {}
 
  ngOnInit(): void {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.collaborationsService.newCollaborations$.pipe(skip(1),
      takeUntil(this.ngDestroy$)
    ).subscribe(
      (collaborations: Collaboration[]) => {
        this.collaborations = [...this.collaborations || [], ...this.collaborationsService.enrichCollaborationsWithUsersData(collaborations)]
      }
    )
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  onDelete(collaborationId: string): void {
    const dialogRef = this.dialogService.showDialog(
      'Delete Confirmation',
      null,
      'Are you sure you want to delete this collaboration?',
      null,
      true,
      false) as Observable<boolean>;

    dialogRef.subscribe((confirm) => {
      if (confirm) {
            this.collaborationsService
              .deleteOne(collaborationId.toString())
              .pipe(takeUntil(this.ngDestroy$))
              .subscribe(
                () => {
                  const removeIndex = this.collaborations.findIndex((collaboration) => {
                    return collaboration._id.toString() == collaborationId;
                  });                 
                  if(removeIndex > -1) {
                    this.collaborations.splice(removeIndex, 1)
                  }
                },
                (error) => {
                  this.dialogService.showDialog(
                    "FILE.DELETE",
                    error.status,
                    error.url,
                    error.error
                  );
                }
              );
           
        }
      });

  }

  getIcon(role: CollaborationRole): string {
    return Collaboration.getIcon(role);
  }
}
