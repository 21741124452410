<button *ngIf="fileModel?.downloadable && !(fileModel?.mimeType === 'application/pdf' || fileModel?.mimeType === 'application/pdf')"
    class="button-download"
    (click)="download()"
    mat-fab>
  <mat-icon>cloud_download</mat-icon>
</button>

<section
    (contextmenu)="disableContext()"
    class="scrollable-section"
    (wheel)="swiperWheel($event)">

  <div
      class="swiper-container"
      [disabled]="false"
      [performance]="false"
      (indexChange)="swiperIndexChange($event)"
      [swiper]="swiperConfig"
      (zoomChange)="swiperZoomChange($event)">

    <div class="swiper-wrapper">
      <div class="swiper-slide">

        <!-- File icon -->
        <a
            *ngIf="!filesService.canViewImage(fileModel) && !fileModel?.mimeType?.startsWith('video') && fileModel?.mimeType !== 'application/pdf'"
            [attr.download]="webContentLink && fileModel.name"
            [attr.href]="webContentLink"
            draggable="false">
          <mat-icon class="file-icon">
            <span class="file-name">{{ fileModel?.name }}</span>
            {{ filesService.getFileIcon(fileModel) }}
            <span>{{ 'FILE.DOWNLOAD' | translate }}</span>
          </mat-icon>
        </a>
        <!-- PDF iframe -->
        <div *ngIf="fileModel?.mimeType === 'application/pdf' && !isMobile"
            class="noClickPDF">
        </div>

        <iframe *ngIf="fileModel?.mimeType === 'application/pdf' && !isMobile"
            type="application/pdf"
            (load)="onPdfLoad($event, fileModel?.webViewLink)"
            src="">
        </iframe>

        <!-- PDF Mobile -->
        <a
            *ngIf="fileModel?.mimeType === 'application/pdf' && isMobile"
            [attr.href]="fileModel?.webViewLink"
            target="_blank"
            rel="noopener"
            draggable="false">
          <mat-icon class="file-icon">
            {{ filesService.getFileIcon(fileModel) }}
            <span>{{ 'FILE.OPEN_NEW_TAB' | translate }}</span>
          </mat-icon>
        </a>

        <!-- Video player -->
        <video
            *ngIf="fileModel?.mimeType?.startsWith('video')"
            controls
            crossorigin
            playsinline
            controlsList="{{ fileModel?.downloadable ? '' : 'nodownload' }}"
            preload="auto"
            src="{{ fileModel?.webViewLink }}">
        </video>

        <!-- File thumbnail, show original image when resized, zoomed or in fullscreen view -->
        <div *ngIf="filesService.canViewImage(fileModel)"
            [id]="'id-' + fileModel?._id "
            class="swiper-zoom-container">
          <img  *ngIf="!fileModel?.tags?.includes('360_stitched')"
              alt="{{ fileModel?.name }}"
              crossorigin="anonymous"
              draggable="false"
              src="{{
                fileModel?.webViewLink
              }}">
        </div>

      </div>
    </div>
  </div>
</section>
