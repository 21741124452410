import { FormControl } from '@angular/forms';
import { UserRole, UsersFilter, AccountType } from '../../pages/users/models/user.model';
import { Order } from '../orders/orders.model';

export function getPathValue(object: object, path: string): any {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : undefined;
  }, object);
}

export function getNestedValue(object: object, key: string | RegExp): any {
  let value: any;
  Object.keys(object).some(k => {
      if (key instanceof RegExp ? (key as RegExp).test(k) : k === key) {
          value = object[k];

          return true;
      }
      if (object[k] && typeof object[k] === 'object') {
          value = getNestedValue(object[k], key);

          return value !== undefined;
      }
  });

  return value;
}

export function isEmptyJSON(obj: any): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function customEmailValidator(control: FormControl) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;
  if (!control.value) {
    return null; // Return null if control is empty to handle required validator separately
  }
  return emailPattern.test(control.value) ? null : { email: true };
}

export function quotesToRegex(s: string): string {
  if (s.startsWith('"') && s.endsWith('"')) { // If between double quotes
    return s.replace(/"/g, ''); // then search exact phrase
  } else if (s.startsWith("'") && s.endsWith("'")) { // If between single quotes
    return s.replace(/'/g, ''); // then search exact phrase
  } else {
    return s
    .replace(/ /g, '.*') // if spaces then find containing words
    .replace(',', '|'); // if commas then find any words
  }
}

export function userSearchFilter(search: string): UsersFilter {
  const filter: UsersFilter = {};
  
  if (search) {
    // Normalize search string by replacing known aliases with a comma
    search = search
      .replace(' OR ', ',')
      .replace('||', ',')
      .replace('|', ',')
      .replace('id:', '_id:')
      .replace('type:', 'mimeType:');
      console.log('search: ',search)

    // Match individual tokens in the search string
    const matches = search.match(/[^\s"']+|"([^"]*)"|'([^']*)'/g);

    if (matches) {
      matches.forEach(token => {
        let [key, value] = token.split(':').map(part => part.trim().replace(/['"]+/g, ''));

        switch (key.toLowerCase()) {
          case '_id':
          case 'id':
            // Check if it's a single ID or multiple IDs
            if (/^[0-9a-f]{24}$/.test(value)) {
              filter.id = value;
            } else if (/^(?:(^|,)[0-9a-f]{24})+$/.test(value)) {
              filter.ids = value.split(',').map(id => id.trim());
            }
            break;
          case 'email':
            if (value.includes(',')) {
              filter.emails = value.split(',').map(email => email.trim());
            } else {
              filter.emails = [value];
            }
            break;
          case 'query':
            filter.query = '*'+value+'*';
          break;
          case 'phone':
            filter.phone = value;
            break;
          case 'address':
            filter.address = value;
            break;
          case 'accounttype':
            filter.accountType = value as AccountType;  // Assuming AccountType is an enum or similar
            break;
          default:
            // Handle any unspecified keys or create a generic search mechanism if necessary
            break;
        }
      });
    }
  }

  return filter;
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

// Helper function for delaying execution
export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function searchToFilter(search: string, fields = ['name']): object {
  let filter = {};

  if (search) {
    // The comma, is the default separator to search in an array of values
    // Aliases ' OR ', '||', '|' can also be used by the user, but we replace them here with a comma
    search = search
    .replace(' OR ', ',')
    .replace('||', ',')
    .replace('|', ',')
    .replace('id:', '_id:')
    .replace('type:', 'mimeType:');

    if (/^[0-9a-f]{24}$/.test(search)) {
      // Search single ObjectID
      filter['_id'] = { $oid: search };

    } else if (/^(?:(^| ?, ?)[0-9a-f]{24})+$/.test(search)) {
      // Search multiple ObjectIDs
      filter['_id'] = { $in: search.split(',').map(id => ({ $oid: id.trim() })) };

    } else {
      // Match all values, key:value, key:"exact phrase"
      const parts = [];
      const matches = search.match(/[^\s"']+|"([^"]*)"|'([^']*)'/g);

      if (matches) {
        filter['$and'] = [];

        // Populate a parts array containing all the distinct search terms:
        //
        // search = 'key:value key:"exact phrase" value "exact phrase"'
        // will be converted to:
        // parts = ['key:value', 'key:"exact phrase"', 'value', "exact phrase"]
        //
        matches.forEach((s, i) => {
          if (i && String(parts[i - 1]).endsWith(':')) {
            // If previous part is "key:" then append current value to it
            parts[i - 1] += s;
          } else {
            // If not then push a new search string
            parts.push(s);
          }
        });

        // Iterate the search parts to build the filter
        // eslint-disable-next-line complexity
        parts.forEach((s: string) => {

          if (s.includes(':')) {
            // Search key:value
            const ss = s.split(':');
            const key = ss[0].trim();
            let val: any = ss[1].trim();

            if (val === 'false' || val === 'true') {
              if (key === 'shared') {
                if (val === 'false') {
                  // Not shared
                  val = { _permissions: { $size : 1 }};
                } else {
                  // Shared with others
                  val = { '_permissions.1': { $exists: true }};
                }
              } else {
                // Search if field exists
                val = { $exists: !!val };
              }

            } else if (key === '_id' || key === 'folderID') {
              if (/^[0-9a-f]{24}$/.test(val)) {
                // Search single key:oid
                val = { $oid: val };
              } else if (/^(?:(^|,)[0-9a-f]{24})+$/.test(val)) {
                // Search multiple key:oid_1,oid_2,...,oid_n
                val = { $in: val.split(',').map((v: string) => ({ $oid: v.trim() })) };
              } else {
                // Invalid id
                val = '';
              }

            } else if (key === 'orderID') {
              if (val.includes(',')) {
                // Search multiple key:v_1,v_2,...,v_n
                val = { $in: val.split(',').map((v: string) => {
                  const numericString = v.trim().replace(/\D/g, ''); // Remove non-numeric characters
                  return Number(numericString);
                }) };
              } else {
                const numericString = val.trim().replace(/\D/g, '');
                val = Number(numericString);
              }              
            } else if (key === 'tags') {
              if (val.includes(',')) {
                // Search multiple key:v_1,v_2,...,v_n
                val = { $in: val.split(',').map((v: string) => v.trim()) };
              }
            } else if (key === 'mimeType' && val === 'folder') {
              // Search exact application/vnd.folder
              val = 'application/vnd.folder';

            } else if (key === 'mimeType' && (val === 'image' || val === 'video')) {
              // Starts with image or video
              val = { $regex: `^${val}` };

            } else if (key === 'role') {
              // Search user role
              val = UserRole.getValue(val);

            } else {
              // Search key:value or key:"exact phrase"
              val = { $regex: quotesToRegex(val), $options: 'i' };
            }
            if (key !== '' && val !== '') {
              // Search key:value
              filter['$and'].push({ [key]: val });
            }

          } else {
            const searchFields = fields.reduce((acc, field) => {
              if (field === 'fullName') {
                acc.push({
                  "$expr": {
                    "$regexMatch": {
                      "input": { "$concat": ["$name", " ", "$surname"] },
                      "regex": quotesToRegex(s),
                      "options": "i"
                    }
                  }
                });
              } else {
                acc.push({ [field]: { $regex: quotesToRegex(s), $options: 'i' }});
              }
              return acc;
            }, []);
            if (searchFields.length > 1) {
              filter['$and'].push({ $or: searchFields }); // Search in multiple fields
            } else {
              filter['$and'].push(searchFields[0]); // Search in single field
            }
          }
        });

        if (!filter['$and'].length) {
          filter = {};
        }
      }
    }
  }

  return filter;
}


