import { animate, style, transition, trigger } from '@angular/animations';

// animation for popping f.g. a button
// this approach is used here:
// https://material.io/guidelines/components/buttons-floating-action-button.html#buttons-floating-action-button-behavior
// to animate floating action button
export const popAnimation = trigger('popAnimation', [
  transition(':enter', [
    style({ transform: 'scale(0.5) rotate(-30deg)', opacity: 0 }),
    animate('0.6s cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({ transform: 'scale(1) rotate(0deg)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1 }),
    animate('0.6s cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({ transform: 'scale(0.5)', opacity: 0 }))
  ])
]);
